import React, {useState} from 'react';
import {Button, Form, Modal, Grid} from "semantic-ui-react";

interface AdjustmentCodesFormProps {
    initialValues: any,
    cancelForm: any,
    saveForm: any
}

const AdjustmentListForm = (props: AdjustmentCodesFormProps) => {
    const [state, setStates] = useState(props.initialValues);

    const onChange = (_e: any, element: any) => {
        const obj = {...state}
        obj[element.name] = element.name === "adjustmentsCode" ? element.value : element.checked;
        setStates(obj);
    };

    const saveForm = () => {
        props.saveForm(state);
    };

    const {initialValues, cancelForm} = props;
    return (

        <Modal
            onClose={() => cancelForm}
            open={true}
            size="small"
            trigger={<Button>Show Modal</Button>}
        >
            <Modal.Header>{initialValues.id && initialValues.id > 0 ? "Edit " : "Create "} Adjustments
                Code</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form>
                        <Form.Input
                            fluid={true}
                            label='Adjustments Code'
                            placeholder='Adjustments Code'
                            defaultValue={state.adjustmentsCode}
                            name={'adjustmentsCode'}
                            onChange={onChange}
                        />
                        <Form.Checkbox
                            fluid={true}
                            label='Remove From Dr Pay'
                            placeholder='Remove From Dr Pay'
                            checked={state.removeFromDrPay}
                            name={'removeFromDrPay'}
                            onChange={onChange}
                        />
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary={true} onClick={cancelForm}>Cancel</Button>
                <Button primary={true} onClick={saveForm}>Save</Button>
            </Modal.Actions>
        </Modal>

    );
}

export default AdjustmentListForm;
