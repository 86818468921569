import React, {useEffect, useState} from "react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as ReportActions from "../redux/actions/goxProcedureCode"
import {useActions} from "../redux/actions";
import {Button, Form, Grid, Icon, Search, Table} from "semantic-ui-react";
import _, { trim } from "lodash";
import GOXProcedureCodeListForm from "./GOXProcedureCodeListForm";
import HelpMark from "./HelpMark";
import DeleteModal from "./DeleteProcedureCodeModal";


interface WriteOffListProps {
    writeOffList: any
    match: any
}

let initialState: any = {
    loading: false,
    cronStatus: 'All',
    goxProcedureCodeList: [],
    isEdit: false,
    initialValues: {procedureCode: '', description: '', id: 0},
    orderBy: null,
    order: true,
    search: "",
    showDelConfirm: false,
    deleteId: null,
}

const GOXProcedureCodeList = (props: WriteOffListProps) => {
    const reportActions = useActions(ReportActions);
    let prefix:string = '';
    if(props.match.path === '/admin/setup/gox-procedure-code-list') {
        prefix = 'GOX';
    } else if(props.match.path === '/admin/setup/goz-procedure-code-list') {
        prefix = 'GOZ';
    }
    const [state, setStates] = useState(initialState);
    

    useEffect(() => {
        reportActions.getGOXProcedureCodes(prefix)
            .then((res: any) => {
                setStates({...state, goxProcedureCodeList: res});
            });
    }, []);

    const onSearch = (e: any, element: any) => {
        const obj = {...state}
        obj.search = element.value;
        setStates(obj);
    };
    const getSortRows = (sortColumn: string, order: string) => {
        const {goxProcedureCodeList} = state;
        const orderUpdated = !order;
        const goxProcedureCodeListTemp = _.orderBy(goxProcedureCodeList, [sortColumn], [order ? 'asc' : 'desc'])
        setStates({...state, goxProcedureCodeList: goxProcedureCodeListTemp, orderBy: sortColumn, order: orderUpdated});
    };


    const edit = (obj: any) => {
        const initialValues = {
            ...obj
        };
        setStates({...state, isEdit: true, initialValues});
    };

    const onDelete = (id: number) => {
        setStates({...state, showDelConfirm: true, deleteId: id});
    }

    const onDeleteAction = () => {
        const {deleteId} = state;
        setStates({...state, showDelConfirm: false});
        reportActions.deleteGOXProcedureCodes(deleteId).then((res: any) => {
            if (res) {
                reportActions.getGOXProcedureCodes(prefix)
                    .then((res: any) => {
                        setStates({...state, goxProcedureCodeList: res, deleteId: null, showDelConfirm: false});
                    });
            }
        });
    }

    const onShowHide = () => {
        setStates({...state, showDelConfirm: false, deleteId: null});
    }

    const cancelForm = () => {
        setStates({...state, isEdit: false, initialValues: {procedureCode: '', description: '', id: 0}});
    };

    const updateGOXCodes = (data: any) => {
        const func = data.id ? "updateGOXProcedureCodes" : 'saveGOXProcedureCodes'
        reportActions[func](data).then((data: any) => {
            if (data) {
                reportActions.getGOXProcedureCodes(prefix)
                    .then((res: any) => {
                        setStates({
                            ...state,
                            goxProcedureCodeList: res,
                            isEdit: false,
                            initialValues: {procedureCode: '', description: '', id: 0}
                        });
                    });
            }

        })
    };

    const {goxProcedureCodeList, search, orderBy, order, isEdit, showDelConfirm} = state;
    const direction = order ? 'sort down' : 'sort up';
    return (
        <Template activeLink={props.match.path.replace('/admin/setup/', '')}>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">{prefix} Procedure Codes <HelpMark pageId='87'/></h2>
                    <p ><h2  className="float-center "><span style={{'color':"red"}}>{prefix.toLowerCase().includes('goz')?  'This list is inclusive of GOX code list' :''}</span> </h2></p>

                    <div className="topFilters flex items-end flex-wrap">

                        <Button
                            primary onClick={() => edit({procedureCode: '', description: ''})}>Add New </Button>
                        <Search
                            placeholder='Search...'
                            onSearchChange={onSearch}
                            name={'search'}
                        />
                    </div>
                </div>
            </div>


            {isEdit &&
                <>
                    <GOXProcedureCodeListForm
                        cancelForm={() => cancelForm()}
                        saveForm={(data: any) => updateGOXCodes(data)}
                        initialValues={state.initialValues}
                        prefix={prefix}
                    />
                </>
            }

            {showDelConfirm &&
                <DeleteModal
                    showDelConfirm={showDelConfirm}
                    onShowHide={() => onShowHide()}
                    onDelete={() => onDeleteAction()}
                />
            }

            <div className="ui card">
                <div className="content">
                    <div className="FreezeTable">
                        <Table className="ui table table-striped table-hover celled unstackable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'procedureCode' ? 'active' : ''}`}
                                        onClick={() => getSortRows('procedureCode', order)}
                                    >
                                        <div>PROCEDURE CODE
                                            <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/></div>
                                    </Table.HeaderCell>

                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'description' ? 'active' : ''}`}
                                        onClick={() => getSortRows('description', order)}
                                    >
                                        <div>{prefix} PROCEDURE CODE DESCRIPTION
                                            <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/></div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Action</div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {goxProcedureCodeList.length &&
                                    goxProcedureCodeList.filter((item: any, index: any) => {
                                        if (!search) {
                                            return true
                                        } else {
                                            const values = Object.values(item).join(" ")
                                            return values.toLowerCase().includes(search.toLowerCase())
                                        }
                                    })
                                        .map((item: any, index: any) => {
                                            return (
                                                <Table.Row key={index}>
                                                    <Table.Cell>{item.procedureCode}</Table.Cell>
                                                    <Table.Cell>{item.description}</Table.Cell>
                                                    <Table.Cell>
                                                        <Icon className="cursorPointer" name="edit"
                                                              onClick={() => edit(item)}/>
                                                        <Icon className="cursorPointer ml10" name="trash alternate"
                                                              onClick={() => onDelete(item.id)}/>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>

        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        goxProcedureCodeList: state.goxProcedureCodeList,
    };
}

export default connect(mapStateToProps)(GOXProcedureCodeList);
