import * as React from 'react';

export interface PMDUnitItemProps {
  value: any,
  description: any
}

export interface PMDUnitItemState {
  value: any,
  description: any
}

class PMDUnitItem extends React.Component<PMDUnitItemProps,PMDUnitItemState> {  
  constructor(props: PMDUnitItemProps) {
    super(props);
    this.state={
      value : props.value,
      description: props.description
    }
  }

  componentWillReceiveProps(nextProps: PMDUnitItemProps) {
    this.setState({
      value: nextProps.value,
      description: nextProps.description
    })
  }

  render() {
    return (
      <table className="pmd_tbl_no_border">
      <thead>
            <tr>
              <td className="header pmd_numbers_above">{this.props.value}</td>
            </tr>
            <tr>
              <td className="pmd_numbers_below">{this.props.description}</td>
            </tr>
          </thead>
      </table>  
)
  }
}

export default PMDUnitItem;