import React, {useEffect, useState} from "react";
import {Button, Modal, Comment, Header, TextArea, Form} from "semantic-ui-react";
import {useFormik} from "formik"
import moment from "moment";
import {useActions} from "../redux/actions";
import * as SpotActions from "../redux/actions/spot";

const NotesModal:React.FC<any>=(props)=>{
    const spotAction = useActions(SpotActions);
    const [notes, setNotes] = useState([]);
    const formik=useFormik(
        {
            initialValues:{
                notes:''
            },
            onSubmit:values => {
                props.submitNotes(props.item, values.notes)
            }
        }
    )
    useEffect(() => {
        spotAction.getNotesList({
            subscriberid : props.item.subscriberid,
            patNumber : props.item.PT_Num,
            practiceId : props.item.practiceId
        })
            .then((res: any) => {
                setNotes(res)
            })
    }, []);


    return (
        <Modal
            open={true}
            onClose={()=>props.setShowNotesHistory(false)}
            centered={false}
            size='tiny'
            closeIcon
        >
            <Header content='Claim Notes'/>
            <Modal.Content>
                <Comment.Group>
                    {!notes.length && <p>No History!</p>}
                    {notes && notes.map((item: any) => {
                        if (item.notes) {
                            return(
                                <Comment>
                                    <Comment.Content>
                                        <Comment.Author>{item.user ? item.user.firstname + ' ' + item.user.lastname: ''}</Comment.Author>
                                        <Comment.Metadata>
                                            <div>{moment(item.notestimestamp).fromNow()}{}</div>
                                        </Comment.Metadata>
                                        <Comment.Text>{item.notes}</Comment.Text>
                                    </Comment.Content>
                                </Comment>
                            )
                        }
                    })}

                </Comment.Group>
                <Form className="formStyle">
                    <label>Add Note</label>
                    <TextArea rows={6} fluid name='notes' placeholder='Notes' onChange={formik.handleChange} value={formik.values.notes}/>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={()=>props.setShowNotesHistory(false)}>
                    Cancel
                </Button>
                <Button className='ui primary button' onClick={formik.submitForm}>
                    Submit
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default NotesModal