import * as React from 'react';
import { numberFormat } from './numberFormat';

export const renderPTGoalTableHeader=(MTDCalculations:any) => {
  let header = Object.keys(MTDCalculations);
  return header.map((key, index) => {
     return <th key={index}>{key.toUpperCase()}</th>
  })
}

export const renderPTGoalTableData=(MTDCalculations:any)=> {
    return MTDCalculations.map((mtdCalculation: { date: any; shifts: any; goalByDay: any; PTCount: any; MTDGoal: any; MTDPTCount: any; MTDGoal_To_Act_PTCount: any; }, index: any) => {
       const { date, shifts, goalByDay, PTCount, MTDGoal, MTDPTCount, MTDGoal_To_Act_PTCount} = mtdCalculation
       return (
          <tr key={date}>
             <td>{date}</td>
             <td>{shifts}</td>
             <td>{goalByDay}</td>
             <td>{PTCount}</td>
             <td>{MTDGoal}</td>
             <td>{MTDPTCount}</td>
             <td>{MTDGoal_To_Act_PTCount>=0 ? MTDGoal_To_Act_PTCount : '('+Math.abs(MTDGoal_To_Act_PTCount)+')'}</td>
          </tr>
       )
    })
 }

export const patientGoalDialog= (practice:any,todaysgoal:any,totalbudget:any,TodayDate:any,MTDGoal:any,MTDCalculationsObj:any,workdaysTotal: any,workdaysLeft: any,
  totalshifts:any,totalsShiftsLeft: any,mtdptcount:any,MTDGoal_To_Act_ptcount:any
  ,daysComplete:any,general_shifts_completed:any,hygienists_shifts_completed:any,
  general_shifts_left:any,hygienists_shifts_left:any,
  FvPt:any,
  RcPt:any,
  TxPt:any) =>{  
    return (
      <div>
      
        <p>
          <div className="visible content">
            <table className="ui very basic collapsing celled table">
              <thead>
                <tr><th>PT Goal Today</th>
                <th>{parseFloat(todaysgoal).toFixed(2)||0}</th>
              </tr></thead>
              <tbody>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      Goal
                        <div className="sub header">Total Value
                      </div>
                    </div>
                  </h4>
                  </td>
                  <td>
                  {parseFloat(totalbudget).toFixed(2)||0}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      MTD Goal - {TodayDate}
                        <div className="sub header">Total Value
                      </div>
                    </div>
                  </h4></td>
                  <td>
                  {parseFloat(MTDGoal).toFixed(2)||0}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      MTD PT Count - {TodayDate}
                        <div className="sub header">Total Value
                      </div>
                    </div>
                  </h4></td>
                  <td>
                  {parseFloat(mtdptcount).toFixed(2)||0}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      MTD Goal To Actual PTCount
                        <div className="sub header">MTD PT Count - MTD Goal 
                      </div>
                    </div>
                  </h4></td>
                  <td>
                  {(parseFloat(mtdptcount)-parseFloat(MTDGoal)).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      Shifts
                        <div className="sub header">Total Shifts
                      </div>
                    </div>
                  </h4></td>
                  <td>
                    {general_shifts_completed+hygienists_shifts_completed+general_shifts_left+hygienists_shifts_left}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      Shifts Left
                        <div className="sub header">Shifts Left Only
                      </div>
                    </div>
                  </h4></td>
                  <td>
                    {general_shifts_left+hygienists_shifts_left}/{general_shifts_completed+hygienists_shifts_completed+general_shifts_left+hygienists_shifts_left}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </p> 
        
            <table className="ui celled structured table">
              <thead>
                <tr>
                  <th rowSpan={1}>Goal</th>
                  <th rowSpan={1}>Type</th>
                  <th rowSpan={1}>PT Visit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3}>Goal</td>
                  <td>FV</td>
                  <td >{FvPt}</td>
                </tr>
                <tr>
                  <td>RC</td>
                  <td >{RcPt}</td>
                </tr>
                <tr>
                  <td>TX</td>
                  <td >{TxPt}</td>
                </tr>
                <tr>
                  <td rowSpan={1}>Total</td>
                  <td></td>
                  <td >{FvPt+RcPt+TxPt}</td>
                </tr>
              </tbody>
            </table>
            
            <div>
            <table className="ui celled structured table">
              <thead>
                <tr>
                  <th >Days</th>
                  <th >Practice</th>
                  <th >Complete</th>
                  <th >Left</th>
                  <th >Total</th>
                </tr>
                <tr>
                  <td rowSpan={1}>Days</td>
                  <td rowSpan={1}>{practice}</td>
                  <td rowSpan={1}>{daysComplete}</td>
                  <td rowSpan={1}>{workdaysLeft}</td>
                  <td rowSpan={1}>{daysComplete+workdaysLeft}</td>
                </tr>
              </thead>
            </table>
            </div>

            <table className="ui celled structured table">
              <thead>
                <tr>
                  <th rowSpan={1}>Shifts</th>
                  <th rowSpan={1}>Type</th>
                  <th rowSpan={1}>Shifts Complete</th>
                  <th rowSpan={1}>Shifts Left</th>
                  <th rowSpan={1}>Total Shifts</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3}>Shifts</td>
                  <td className="left aligned">General Shifts</td>
                  <td >{general_shifts_completed}</td>
                  <td >{general_shifts_left}</td>
                  <td >{general_shifts_completed+general_shifts_left}</td>
                </tr>
                <tr>
                  <td className="left aligned">Hygiene Shifts</td>
                  <td >{hygienists_shifts_completed}</td>
                  <td >{hygienists_shifts_left}</td>
                  <td >{hygienists_shifts_completed+hygienists_shifts_left}</td>
                </tr>
              </tbody>
            </table>

            <div>
            <table className="ui striped table" id='mtdCalculations'>
              <thead>
                <tr>{renderPTGoalTableHeader(MTDCalculationsObj[0])}</tr>
              </thead>
              <tbody>                    
                {renderPTGoalTableData(MTDCalculationsObj)}
              </tbody>
            </table>
            </div>  
        
      </div>
    )
}
