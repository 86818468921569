import { ActionType } from "../../model/actionTypes";
import { errorHandler } from "./auth";

export const getProviderAccounts = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });

	try {
		const response = await api.get(`patientValidation/providerAccounts`);
		dispatch({ type: ActionType.PROVIDER_ACCOUNTS, payload: response  });
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.PROVIDER_ACCOUNTS, payload: []  });
		return dispatch(errorHandler(err));
	}
};

export const getProviders = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({type: ActionType.LOADER, payload: true});
	try {
		const results = await api.get(`admin/providerAccounts`);
		dispatch({type: ActionType.PROVIDER_ACCOUNTS, payload: results});
		dispatch({ type: ActionType.LOADER, payload: false });
		return results;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch(errorHandler(err))
		return [];
	}
};

export const deleteProvider =(data:{id:number})=>async(dispatch: Function, getState: Function, api: any)=>{
	dispatch({type: ActionType.LOADER, payload: true});
	try {
		const results = await api.delete(`admin/providerAccounts/deleteProvider`,data);
		dispatch({type: ActionType.DELETE_PROVIDER_SUCCESS, payload: false});
		dispatch({ type: ActionType.LOADER, payload: false });
		return results;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch(errorHandler(err))
		return [];
	}
}


export const saveProvider =(data:any)=>async(dispatch: Function, getState: Function, api: any)=>{
	dispatch({type: ActionType.LOADER, payload: true});
	try {
		let results;
		if(data.masterAccount){
			data.brand = data.brand && data.brand.toString();
			data.mco = data.mco=="MCNA"?data.mco + '-masterAccount':data.mco;
			delete data.masterAccount;
			results = await api.post(`admin/providerAccounts/saveMasterProvider`,data);
		} else {
			results = await api.post(`admin/providerAccounts/saveProvider`,data);
		}
		dispatch({type: ActionType.SAVE_PROVIDER, payload: false});
		dispatch({ type: ActionType.LOADER, payload: false });
		return results;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch(errorHandler(err))
		return [];
	}
}