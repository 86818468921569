import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";
import axios from 'axios';
import {API_URL} from "../../constants";


export const getPcrBudget = (filters: any) => async (dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const url = filters ? `budget/pcr/${JSON.stringify(filters)}` : `budget/pcr/all`
    const resp = await api.get(url);
    dispatch({ type: ActionType.LOAD_PCR_BUDGET, payload: { pcr :  resp }});
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};

export const getOralSurgeryBudget = (filters: any) => async (dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const url = filters ? `budget/${JSON.stringify(filters)}` : `budget/all`
    const resp = await api.get(url);
    dispatch({ type: ActionType.LOAD_ORAL_BUDGET, payload: { oral :  resp }});
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};
export const updateOSBudgetValues = (object: any) => async (dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const url = `budget/update`;
    const resp = await api.post(url, object);
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};

export const updateValuePCR = (object: any) => async (dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const url = `budget/pcr/update`;
    const resp = await api.post(url, object);
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};


export const importPCRbudget = (data: any, parent:string, accessToken: string) => async (dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    let url = '';
    if (parent === "PcrBudget") {
      url = '/budget/pcr/import';
    }
    if(parent === 'OralSurgeryBudget') {
      url = '/budget/os/import';
    }
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    const resp =  axios.post(API_URL+url, data, config )
    // const resp = await api.post(url, data);
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};

