import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Template from "./Template";
import {User} from "../model/user";
import {RootState} from "../model";
import {Practice} from "../model/practice";
import {useActions} from "../redux/actions";
import * as spotActions from "../redux/actions/spot";
import * as practiceActions from "../redux/actions/practice";
import {Button, Dimmer, Dropdown, Header, Loader, Modal, Table} from "semantic-ui-react";
import {setPractices} from "../utils/common";
import * as reportActions from "../redux/actions/report";
import moment from "moment";

interface Props {
    auth: User,
    practice: Practice,
    retentionReport : any
}

function SpotPatientRetention(props: Props) {
    const spotAction = useActions(spotActions);
    const practiceAction = useActions(practiceActions);
    const reportAction = useActions(reportActions);
    const initialFilters: any = {
        practice: []
    };
    const initialState: any = {
        practiceOpts: [],
        spotDetailsModal : false,
        spotDetails : [],
        detailsLocation : '',
        emailConfirm : false
    };
    const [loader, setLoader] = useState(false);
    const [filters, setFilters] = useState(initialFilters);
    const [state, setState] = useState(initialState);


    useEffect(() => {
        practiceAction.fetchUdaPractices().then((practices : any) => {
            const practiceOpts = setPractices(practices, false)
            setState({...state, practiceOpts: practiceOpts})
            loadData(filters);
        })
    }, [filters]);

    const loadData = async (filters: any) => {
        await spotAction.getSpotPatientRetention(filters)
    }

    const handleDetails = async (location: string, duration: string) => {
        const details = await spotAction.getSpotRetentionDetails({location, duration});
        setState({...state, spotDetails: details, spotDetailsModal : true, detailsLocation: location})
    }

    const handleSendEmail = () => {
        const { spotDetails, detailsLocation } = state;
        const data :any = [];
        spotDetails && spotDetails.forEach((item : any, key: number)  => {
            data.push({
                Practice: detailsLocation.toUpperCase(),
                PatNum: item.PatNum,
                'First Name':item.FName,
                'Last Name':item.LName,
                Email:item.Email,
                LastVisit: item.LastVisit ? moment(item.LastVisit).format('YYYY-MM-DD') : '',
                Next_Apt: item.Next_Apt ? moment(item.Next_Apt).format('YYYY-MM-DD') : '',
            })
        });
        reportAction.sendEmailWithCsv({title: 'SPOT patient retention details', csvData: data });
        setState({...state, spotDetailsModal: false, emailConfirm: true});
    }
    const setDetailsModal = (value: boolean) => {
        setState({...state, spotDetailsModal: value})
    }

    const setEmailConfirm = (value: boolean) => {
        setState({...state, emailConfirm: value})
    }

    const getTableData = () => {
        const { retentionReport } = props;

        const tableRows: any = [];
        const tableHeader = [
            'Practice', 'Patients had Visit < 2yrs', 'Patients w/o future appt', '2 yrs retention %',
            'Patients had visit < 6 months', 'Patients w/o future appt', '6 months retention %'
        ];

        if(!retentionReport || !Object.keys(retentionReport).length){

            tableRows.push(
                <Table.Row className={'mt20'} key={0}>
                    <Table.Cell colSpan={10} className={'text-center'}> <b>No records found!</b></Table.Cell>
                </Table.Row>
            );

        } else {
            let total730 = 0;
            let total180 = 0;
            let total730_appt = 0;
            let total180_appt = 0;

            retentionReport && Object.keys(retentionReport).forEach((practice: any, key: number) => {
                if(!retentionReport[practice] || !retentionReport[practice][0])
                {
                    return;
                }

                const item = retentionReport[practice] && retentionReport[practice][0];
                const percentage730 = ((item.count_2years-item.count_2years_noAppt)/item.count_2years) * 100;
                const percentage180 = ((item.count_6months-item.count_6months_noAppt)/item.count_6months) * 100;

                total730 += item.count_2years;
                total730_appt += item.count_2years_noAppt;
                total180 += item.count_6months;
                total180_appt += item.count_6months_noAppt;
                tableRows.push(
                    <Table.Row key={key}>
                        <Table.Cell>{practice.toUpperCase()}</Table.Cell>
                        <Table.Cell className={'clickable'} onClick={()=> handleDetails(practice, 'count_2years') } >{item.count_2years}</Table.Cell>
                        <Table.Cell className={'clickable'} onClick={()=> handleDetails(practice, 'count_2years_noAppt') }>{item.count_2years_noAppt}</Table.Cell>
                        <Table.Cell>{percentage730.toFixed(2)}%</Table.Cell>
                        <Table.Cell className={'clickable'} onClick={()=> handleDetails(practice, 'count_6months') }>{item.count_6months}</Table.Cell>
                        <Table.Cell className={'clickable'} onClick={()=> handleDetails(practice, 'count_6months_noAppt') }>{item.count_6months_noAppt}</Table.Cell>
                        <Table.Cell>{percentage180.toFixed(2)}%</Table.Cell>
                    </Table.Row>
                )
            });
            const per730 = ((total730-total730_appt)/total730) * 100;
            const per180 = ((total180-total180_appt)/total180) * 100;
            tableRows.push(
                <Table.Row key={-1}>
                    <Table.Cell>{'Total'}</Table.Cell>
                    <Table.Cell>{total730}</Table.Cell>
                    <Table.Cell>{total730_appt}</Table.Cell>
                    <Table.Cell>{per730.toFixed(2)}%</Table.Cell>
                    <Table.Cell>{total180}</Table.Cell>
                    <Table.Cell>{total180_appt}</Table.Cell>
                    <Table.Cell>{per180.toFixed(2)}%</Table.Cell>
                </Table.Row>
            )

        }

        return <>
            <div className="FreezeTable">
                <Table className="ui table table-striped table-hover celled unstackable no-wrap">
                    <Table.Header>
                        <Table.Row>
                            {tableHeader.map((header, index) => <Table.HeaderCell
                               style={{ color: "#d16f26" }}
                               key={`${header}_`} 
                               >
                                    <div>{header}</div>
                               </Table.HeaderCell>)
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {tableRows}
                    </Table.Body>
                </Table>
            </div>
        </>;
    }

    const getDetailsModal = () => {
        const { spotDetails, detailsLocation } = state;

        const tableRows: any = [];
        const tableHeader = [
            'Practice', 'PatNum', 'First Name', 'Last Name','Email','Last Visit', 'Next Appt',
        ];

        if(!spotDetails || !spotDetails.length){
            tableRows.push(
                <Table.Row className={'mt20'} key={0}>
                    <Table.Cell colSpan={10} className={'text-center'}> <b>No records found!</b></Table.Cell>
                </Table.Row>
            );

        } else {
            spotDetails.forEach((item : any, key: number)  => {
                tableRows.push(
                    <Table.Row key={key}>
                        <Table.Cell>{detailsLocation.toUpperCase()}</Table.Cell>
                        <Table.Cell>{item.PatNum}</Table.Cell>
                        <Table.Cell>{item.FName}</Table.Cell>
                        <Table.Cell>{item.LName}</Table.Cell>
                        <Table.Cell>{item.Email}</Table.Cell>
                        <Table.Cell>{item.LastVisit}</Table.Cell>
                        <Table.Cell>{item.Next_Apt}</Table.Cell>
                    </Table.Row>
                )
            });
        }

        return <>
            <div className="FreezeTable">
                <Table className="ui table table-striped table-hover celled unstackable no-wrap">
                    <Table.Header>
                        <Table.Row>
                            {tableHeader.map((header, index) => <Table.HeaderCell
                                style={{ color: "#d16f26" }}
                                key={`${header}_`}
                            >
                                <div>{header}</div>
                            </Table.HeaderCell>)
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {tableRows}
                    </Table.Body>
                </Table>
            </div>
        </>;
    }


    return (
        <Template activeLink='spot-patient-retention'>
            <Modal
                closeIcon={true}
                open={state.emailConfirm}
                onClose={() => setEmailConfirm(false)}
            >
                <Header content='SPOT Patient Retention Details' />
                <Modal.Content>
                    <p>An email has been sent! Please check your inbox. Thanks!</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button positive={true} onClick={() => setEmailConfirm(false)}>
                        OK
                    </Button>
                </Modal.Actions>
            </Modal>

             <Modal
                closeIcon={true}
                open={state.spotDetailsModal}
                onClose={() => setDetailsModal(false)}
                 >
                <Header content='SPOT Patient Retention Details' />
                <Modal.Content>
                    {getDetailsModal()}
                </Modal.Content>
                <Modal.Actions>
                    <Button negative={true} onClick={() => setDetailsModal(false)}>
                        Close
                    </Button>
                    <Button positive={true} onClick={() => handleSendEmail()}>
                        Send Email
                    </Button>
                </Modal.Actions>
            </Modal>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">SPOT Patient Retention Report</h2>

                    <div className="topFilters">
                        <Dropdown
                            placeholder='Select Practice'
                            options={state.practiceOpts}
                            selection={true}
                            search={true}
                            multiple={true}
                            value={filters.practice}
                            onChange={(_:any,{value}:any) => setFilters({...filters, practice : value})}
                            name="practice"
                        />

                    </div>
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    {!loader && getTableData()}

                </div>
            </div>

        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
        practice: state.practice,
        retentionReport : state.spot.patientRetention
    };
}

export default connect(mapStateToProps)(SpotPatientRetention)