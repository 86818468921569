import { Action, ActionType } from "../../model/actionTypes";
import { PMDTodaysGoalAll } from "../../model/pmdtodaygoalall";
import createReducer from "./createReducer";
const initialState:PMDTodaysGoalAll = {
	pmdTodaysGoalAll: []
};
export const pmdtodaygoalall = createReducer(initialState, {	
	[ActionType.PMD_TODAY_GOAL_ALL](state: PMDTodaysGoalAll, action: Action<PMDTodaysGoalAll>) {
		return Object.assign({...state}, action.payload);
	},
});
