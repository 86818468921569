import moment from 'moment'
import React from 'react'
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
    Label,
} from 'semantic-ui-react'

interface props {
    data: any
}
const AptoodTable = ({ data }: props) => {
    return (
        <>
            <Table striped compact='very'>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Practice</TableHeaderCell>
                        <TableHeaderCell>Url</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {
                        data.map((logs: any, index: number) =>
                            <TableRow key={index}>
                                <TableCell>{logs?.practice?.practice}</TableCell>
                                <TableCell>{logs.url}</TableCell>
                                <TableCell>{logs.error ? <Label style={{width:'70%'}} size='massive' color='red' horizontal></Label> : <Label style={{width:'70%'}} size='massive' color='green' horizontal></Label>}
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </>

    )
}

export default AptoodTable