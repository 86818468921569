import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import ReactDOMServer from 'react-dom/server';
import {connect} from 'react-redux';
import {Button, Dimmer, Icon, Label, Loader, Modal, Table} from 'semantic-ui-react';

import {
    dataNotLoadedPMD,
    fvgoal,
    PCR_PopupTableHeardings,
    pttodaygoal,
    rcgoal, schedulefvgoal, schedulercgoal,
    scheduletodaygoal, scheduletxgoal,
    todaygoal,
    txgoal, projectedcare, projectedcareDr, todaygoalcss, totalProjectedcare
} from '../constants';
import {RootState} from '../model';
import {DynamicTable} from '../model/dynamicTable';
import {useActions} from '../redux/actions';
import * as patientsActions from '../redux/actions/patients';
import * as pmdActions from '../redux/actions/pmd';
import * as reportActions from '../redux/actions/report';
import {projectedRevenueDialog, projectedRevenueDrDialog, todaysGoalDialog} from './MTDCalculations';
import {patientGoalDialog} from './PatientGoalCalculations';
import PcrSplashBoardInfo from './pcrSplashboardInfo';
import PriceFormat from './PriceFormat';
import ReportHelp from './ReportHelp';

function DynamicHtmlTable(props: DynamicTable) {
    const dataState: any = {
        data: [],
        popupTitle: "",
        practiceName: "",
        practiceDBName: "",
        columnX: null,
        directionX: null,
        uicode: "",
        dataCount: 0,
        bCareData: 0,
        cpvCareData: 0,
        col1Name: "",
        col1Key: "",
        col2Name: "",
        col2Key: "",
        col3Name: "",
        col3Key: "",
        col4Name: "",
        col4Key: "",
        col5Name: "",
        col5Key: "",
        col6Name: "",
        col6Key: "",
        col7Name: "",
        col7Key: "",
        col8Name: "",
        col8Key: "",
        col9Name: "",
        col9Key: "",
        col10Name: "",
        col10Key: "",
        col11Name: "",
        col11Key: "",
        completedApt: 0,
        allApts: 0,
        showRate: "",
        title: "",
    };

    const todayGoalStructure: any = null;
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [data, setData] = useState(dataState);
    const patientsAction = useActions(patientsActions);
    const [todayGoalModalOpen, setTodayGoalModalOpen] = React.useState(false);
    const [projectRevTotal, setPRTotalModalOpen] = React.useState(false);
    const [todayScheduleGoalModalOpen, setScheduleTodayGoalModalOpen] = React.useState(false);
    const reportAction = useActions(reportActions);
    const pmdAction = useActions(pmdActions);
    const [loader, setLoader] = useState(false);
    const [todayGoalCalculations, setTodayGoalCalculations] = useState(todayGoalStructure);
    const [todayScheduleGoalCalculations, setScheduleTodayGoalCalculations] = useState(todayGoalStructure);
    const [ptTodayGoalModalOpen, setPtTodayGoalModalOpen] = React.useState(false);
    const [ptTodayGoalCalculations, setPtTodayGoalCalculations] = useState(todayGoalStructure);
    const [projectRevData, setProjectRevData] = useState(todayGoalStructure);
    const [isOpen, setOpen] = useState(false);
    const [getMDMInfoVal, setMDMInfoVal] = useState('')
    const handleAddClick = async (item: any) => {

        if (meetsConditions(item.uicode)) {
            setData({
                ...data, popupTitle: item.popupTitle, practiceName: item.practiceName,
                uicode: item.uicode, data: item.data
            });
            setLoader(true)
            let stringifyParams = '';
            switch (item.uicode) {
                case todaygoal:
                    setTodayGoalModalOpen(true);
                    stringifyParams = '/' + JSON.stringify({
                        practice: item.practiceName,
                        practiceDBName: item.practiceName
                    });
                    return await loadTodayGoal(stringifyParams);

                case fvgoal:
                    setTodayGoalModalOpen(true);
                    stringifyParams = '/' + JSON.stringify({
                        practice: item.practiceName,
                        practiceDBName: item.practiceName,
                        fv: true
                    });
                    return await loadTodayGoal(stringifyParams);

                case rcgoal:
                    setTodayGoalModalOpen(true);
                    stringifyParams = '/' + JSON.stringify({
                        practice: item.practiceName,
                        practiceDBName: item.practiceName,
                        rc: true
                    });
                    return await loadTodayGoal(stringifyParams);

                case txgoal:
                    setTodayGoalModalOpen(true);
                    stringifyParams = '/' + JSON.stringify({
                        practice: item.practiceName,
                        practiceDBName: item.practiceName,
                        tx: true
                    });
                    return await loadTodayGoal(stringifyParams);

                case scheduletodaygoal:
                    setScheduleTodayGoalModalOpen(true);
                    return await loadScheduleTodayGoal(item.practiceName, item.practiceDBName, scheduletodaygoal);

                case schedulefvgoal:
                    setScheduleTodayGoalModalOpen(true);
                    return await loadScheduleTodayGoal(item.practiceName, item.practiceDBName, schedulefvgoal);

                case schedulercgoal:
                    setScheduleTodayGoalModalOpen(true);
                    return await loadScheduleTodayGoal(item.practiceName, item.practiceDBName, schedulercgoal);

                case scheduletxgoal:
                    setScheduleTodayGoalModalOpen(true);
                    return await loadScheduleTodayGoal(item.practiceName, item.practiceDBName, scheduletxgoal);

                case projectedcare:
                    setTodayGoalModalOpen(true);
                    stringifyParams = item.practiceName ? '/' + JSON.stringify({
                        practice: item.practiceName,
                        practiceDBName: item.practiceName
                    }) : '';
                    return await loadTodayGoal(stringifyParams);

                case totalProjectedcare:
                    setPRTotalModalOpen(true);
                    stringifyParams = '';
                    return await loadTodayGoal(stringifyParams);
            }
        }
        if (item.uicode === pttodaygoal) {
            setData({
                ...data, popupTitle: item.popupTitle, practiceName: item.practiceName,
                uicode: item.uicode, data: item.data
            });
            setLoader(true)
            return await loadPtTodayGoal(item.practiceName, item.practiceDBName);
        }
        if (item.uicode === projectedcareDr) {
            setData({
                ...data, popupTitle: item.popupTitle, practiceName: item.practiceName,
                uicode: item.uicode, data: item.data
            });
            setTodayGoalModalOpen(true);
            return loadProjectedCareDr(item.data);
        } else {
            setAddModalOpen(true);

            setData({
                data: item.data,
                popupTitle: item.popupTitle,
                practiceName: item.practiceName ? capitalizeFirstLetter(item.practiceName) : '',
                title: item.title,
                uicode: item.uicode,
                dataCount: item.dataCount,
                bCareData: item.bCareData,
                showRate: item.showRate,
                allApts: item.allApts,
                completedApt: item.completedApt,
                cpvCareData: item.cpvCareData,
                col1Name: pcrPopupTableheardings(item.uicode).col1.name,
                col1Key: pcrPopupTableheardings(item.uicode).col1.key,
                col2Name: pcrPopupTableheardings(item.uicode).col2.name,
                col2Key: pcrPopupTableheardings(item.uicode).col2.key,
                col3Name: pcrPopupTableheardings(item.uicode).col3.name,
                col3Key: pcrPopupTableheardings(item.uicode).col3.key,
                col4Name: pcrPopupTableheardings(item.uicode).col4.name,
                col4Key: pcrPopupTableheardings(item.uicode).col4.key,
                col5Name: pcrPopupTableheardings(item.uicode).col5.name,
                col5Key: pcrPopupTableheardings(item.uicode).col5.key,
                col6Name: pcrPopupTableheardings(item.uicode).col6.name,
                col6Key: pcrPopupTableheardings(item.uicode).col6.key,
                col7Name: pcrPopupTableheardings(item.uicode).col7.name,
                col7Key: pcrPopupTableheardings(item.uicode).col7.key,
                col8Name: pcrPopupTableheardings(item.uicode).col8.name,
                col8Key: pcrPopupTableheardings(item.uicode).col8.key,
                col9Name: pcrPopupTableheardings(item.uicode).col9.name,
                col9Key: pcrPopupTableheardings(item.uicode).col9.key,
                col10Name: pcrPopupTableheardings(item.uicode).col10.name,
                col10Key: pcrPopupTableheardings(item.uicode).col10.key,
                col11Name: pcrPopupTableheardings(item.uicode).col11.name,
                col11Key: pcrPopupTableheardings(item.uicode).col11.key,
            })
        }
    };

    const meetsConditions = (uicode: string) => {
        if ((uicode === todaygoal) ||
            (uicode === projectedcare) ||
            (uicode === fvgoal) ||
            (uicode === rcgoal) ||
            (uicode === txgoal) ||
            (uicode === scheduletodaygoal) ||
            (uicode === schedulefvgoal) ||
            (uicode === schedulercgoal) ||
            (uicode === totalProjectedcare) ||
            (uicode === scheduletxgoal)) {
            return true;
        }

        return false;
    }

    const handleClose = () => {
        setAddModalOpen(false);
        setTodayGoalModalOpen(false);
        setScheduleTodayGoalModalOpen(false);
        setPtTodayGoalModalOpen(false);
        setPRTotalModalOpen(false);
    }

    const initialState: any = {
        TableHead: [],
        TableData: [],
    };
    let [states, setState] = useState(initialState);

    useEffect(() => {
        const getTableHeadings = () => {
            let returnArray: any = [];
            if (props.tableHead.length) {
                props.tableHead.forEach((item, key) => {
                    let index = -1;
                    const title: any = item.title;
                    if (props.excludeSorting) {
                        index = props.excludeSorting.findIndex((head: string) => head.toLowerCase() === title.toLowerCase())
                    }
                    const icon = props.onHeadClick ? (props.sortedColumn === (item.key || key) ?
                        <Icon name={props.direction}/> : <Icon name={'sort'}/>) : null;
                    returnArray.push(
                        <th
                            className={item.className}
                            id={item.id}
                            style={item.style}
                            key={item.key || key}
                            onClick={props.onHeadClick ? props.onHeadClick(item.key || key) : void (0)}
                        >
                            {/*<div>{item.title}  {icon} </div>*/}
                            {index === -1 && <div>{item.title} {icon} </div>}
                            {index > -1 && <div>{item.title} </div>}
                        </th>)
                })
            }
            return (<thead>
            <tr>{returnArray}</tr>
            </thead>)
        };
        const onGetInfo = (item: any) => {
            setOpen(true);
            setMDMInfoVal(item.title)
        };

        const getTableBody = () => {
            let tableRows: any = [];
            if (props.tableData.length) {
                props.tableData.forEach((itemArray, key) => {
                    if (itemArray.length) {
                        let tableColumns: any = [];
                        itemArray.forEach((item, key2) => {
                            let rowClassname: any = item.className;
                            if (item.popupTitle === "ACT ALL PT MTD" ||
                                item.popupTitle === "ACT FV PT MTD" ||
                                item.popupTitle === "ACT RC PT MTD" ||
                                item.popupTitle === "ACT TX PT MTD" ||
                                item.popupTitle === "RC Completed Patients" ||
                                item.popupTitle === "FV Completed Patients"
                                || item.popupTitle === "TX Completed Patients" ||
                                item.popupTitle === "ALL Completed Patients") {
                                item.title = item.dataCount;
                            }
                            if (rowClassname ? rowClassname.localeCompare("pcr_tbl_numbers_clickable") == 0 : false) {
                                tableColumns.push(<td className={item.className} id={item.id} style={item.style}
                                                      key={item.key || key2}><span
                                    onClick={() => handleAddClick(item)}>{item.title == "" ? 0 : item.title}</span>
                                </td>)
                            } else {
                                // @ts-ignore
                                props && props.className.includes('by-practice') && key2 === 0 ?
                                    tableColumns.push(
                                        <td className={'font-weight-bold'} key={item.key || key2}>
                                            {item.title}
                                            <Icon
                                                onClick={() => onGetInfo(item)}
                                                className="cursorPointer"
                                                name="info"
                                            />
                                            {
                                                props.mdm_report && props.mdm_report[item.title] &&
                                                <ReportHelp data={props.mdm_report[item.title]}/>
                                            }
                                        </td>
                                    ) :
                                    tableColumns.push(
                                        <td className={item.className} id={item.id} style={item.style}
                                            key={item.key || key2}>
                                            {item.title}
                                            {rowClassname === 'font-weight-bold' &&
                                                <Icon onClick={() => onGetInfo(item)} className="cursorPointer"
                                                      name="info"/>}
                                            {props.mdm_report && props.mdm_report[item.title] &&
                                                <ReportHelp data={props.mdm_report[item.title]}/>}
                                        </td>
                                    )
                            }
                        });
                        tableRows.push(<tr key={key}>{tableColumns}</tr>)
                    }
                })
            }
            return (<tbody>{tableRows}</tbody>)
        };

        const TableData = getTableBody();
        const TableHead = getTableHeadings();
        setState({TableData, TableHead})
    }, [props.tableData])

    const handleSortPtSeenTodayDetail = (clickedColumn: any) => {
        let {columnX, dataCopy, directionX} = data;
        if (columnX !== clickedColumn) {
            columnX = clickedColumn;
            dataCopy = _.sortBy(data.data, o => o[clickedColumn]);
            directionX = 'ascending';
            setData({...data, data: dataCopy, directionX: directionX, columnX: columnX});
            return;
        }
        dataCopy = data.data.reverse();
        directionX = directionX === 'ascending' ? 'descending' : 'ascending';
        setData({...data, data: dataCopy, directionX: directionX, columnX: columnX});
    };

    const handleSendMailAction = () => {

        let headCheck = data.col5Key;
        if (data.col5Key === 'price') {
            headCheck = 'care';
        }
        if (data.uicode === 'adjustment') {
            const filterData = data.data.filter((item: any) => item.abbr !== 'TOTAL');

            patientsAction.sendMailPCRPopupDetail({
                data: filterData, practicename: data.practiceName ? data.practiceName : '', popupTitle: data.popupTitle,
                col2Name: data.col2Name,
                col2Key: data.col2Key,
                col3Name: data.col3Name,
                col3Key: data.col3Key,
                col4Name: data.col4Name,
                col4Key: data.col4Key,
                col5Name: data.col5Name,
                col5Key: headCheck,
                col7Name: data.col7Name,
                col7Key: data.col7Key,
            });
        } else {
            patientsAction.sendMailPCRPopupDetail({
                data: data.data, practicename: data.practiceName ? data.practiceName : '', popupTitle: data.popupTitle,
                col1Name: data.col1Name,
                col1Key: data.col1Key,
                col2Name: data.col2Name,
                col2Key: data.col2Key,
                col3Name: data.col3Name,
                col3Key: data.col3Key,
                col4Name: data.col4Name,
                col4Key: data.col4Key,
                col5Name: data.col5Name,
                col5Key: headCheck,
                col6Name: data.col6Name,
                col6Key: data.col6Key,
                col7Name: data.col7Name,
                col7Key: data.col7Key,
                col8Name: data.col8Name,
                col8Key: data.col8Key,
                col9Name: data.col9Name,
                col9Key: data.col9Key,
                col10Name: data.col10Name,
                col10Key: data.col10Key,
                col11Name: data.col11Name,
                col11Key: data.col11Key,
            });
        }

        patientsAction.sendMailPCRPopupDetail({
            data: data.data, practicename: data.practiceName ? data.practiceName : '', popupTitle: data.popupTitle,
            showRate: data.showRate,
            allApts: data.allApts,
            completedApt: data.completedApt,
            col1Name: data.col1Name,
            col1Key: data.col1Key,
            col2Name: data.col2Name,
            col2Key: data.col2Key,
            col3Name: data.col3Name,
            col3Key: data.col3Key,
            col4Name: data.col4Name,
            col4Key: data.col4Key,
            col5Name: data.col5Name,
            col5Key: headCheck,
            col6Name: data.col6Name,
            col6Key: data.col6Key,
            col7Name: data.col7Name,
            col7Key: data.col7Key,
            col8Name: data.col8Name,
            col8Key: data.col8Key,
            col9Name: data.col9Name,
            col9Key: data.col9Key,
            col10Name: data.col10Name,
            col10Key: data.col10Key,
            col11Name: data.col11Name,
            col11Key: data.col11Key,
        });
        handleClose();
    };

    const pcrPopupTableheardings = (uicode: any) => {
        const pcrPopupTableheardingsObj = PCR_PopupTableHeardings.filter(obj => {
            return (obj.uicodekey.localeCompare(uicode) === 0)
        })
        return pcrPopupTableheardingsObj[0] ? pcrPopupTableheardingsObj[0].tableheardings : {
            col1: {name: "", key: ""},
            col2: {name: "", key: ""},
            col3: {name: "", key: ""},
            col4: {name: "", key: ""},
            col5: {name: "", key: ""},
            col6: {name: "", key: ""},
            col7: {name: "", key: ""},
            col8: {name: "", key: ""},
            col9: {name: "", key: ""},
            col10: {name: "", key: ""},
            col11: {name: "", key: ""},
        };
    };

    const capitalizeFirstLetter = (string: any) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const todayGoalDialogContent = (uicode?: string) => {
        if ((todayGoalCalculations && (todayGoalCalculations.length !== 0)
            && (typeof todayGoalCalculations !== 'undefined') && (todayGoalCalculations !== null) && todayGoalCalculations[0]) || uicode === projectedcareDr) {
            switch (uicode) {
                case projectedcare:
                    return projectedRevenueDialog(
                        todayGoalCalculations[0].MTDCalculationsObj,
                        todayGoalCalculations[0].general_shifts_completed,
                        todayGoalCalculations[0].hygienists_shifts_completed,
                        todayGoalCalculations[0].general_shifts_left,
                        todayGoalCalculations[0].hygienists_shifts_left
                    );
                case projectedcareDr:
                    return projectedRevenueDrDialog(
                        todayGoalCalculations);
                default:
                    return todaysGoalDialog(
                        todayGoalCalculations[0].MTDCalculationsObj,
                        todayGoalCalculations[0].todaysgoal,
                        todayGoalCalculations[0].TodayDate,
                        todayGoalCalculations[0].MTDGoal,
                        todayGoalCalculations[0].workdaysLeft,
                        todayGoalCalculations[0].daysComplete,
                        todayGoalCalculations[0].general_shifts_completed,
                        todayGoalCalculations[0].hygienists_shifts_completed,
                        todayGoalCalculations[0].general_shifts_left,
                        todayGoalCalculations[0].hygienists_shifts_left,
                        todayGoalCalculations[0].FvPt,
                        todayGoalCalculations[0].FvCPV,
                        todayGoalCalculations[0].RcPt,
                        todayGoalCalculations[0].RcCPV,
                        todayGoalCalculations[0].TxPt,
                        todayGoalCalculations[0].TxCPV,
                        todayGoalCalculations[0].practice
                    );
            }
        } else {
            return dataNotLoadedPMD;
        }
    };

    const ptTodayGoalDialogContent = () => {
        if (ptTodayGoalCalculations && (ptTodayGoalCalculations.length !== 0)
            && (typeof ptTodayGoalCalculations !== 'undefined') && (ptTodayGoalCalculations !== null) && ptTodayGoalCalculations[0]) {
            return patientGoalDialog(
                data.practiceName,
                ptTodayGoalCalculations[0].todaysgoal,
                ptTodayGoalCalculations[0].totalbudget,
                ptTodayGoalCalculations[0].TodayDate,
                ptTodayGoalCalculations[0].MTDGoal,
                ptTodayGoalCalculations[0].MTDCalculationsObj,
                ptTodayGoalCalculations[0].workdaysTotal,
                ptTodayGoalCalculations[0].workdaysLeft,
                ptTodayGoalCalculations[0].totalshifts,
                ptTodayGoalCalculations[0].totalsShiftsLeft,
                ptTodayGoalCalculations[0].mtdptcount,
                ptTodayGoalCalculations[0].MTDGoal_To_Act_ptcount,
                ptTodayGoalCalculations[0].daysComplete,
                ptTodayGoalCalculations[0].general_shifts_completed,
                ptTodayGoalCalculations[0].hygienists_shifts_completed,
                ptTodayGoalCalculations[0].general_shifts_left,
                ptTodayGoalCalculations[0].hygienists_shifts_left,
                ptTodayGoalCalculations[0].FvPt,
                ptTodayGoalCalculations[0].RcPt,
                ptTodayGoalCalculations[0].TxPt
            );
        } else {
            return dataNotLoadedPMD;
        }
    };

    const ptScheduleTodayGoalDialogContent = () => {
        if (todayScheduleGoalCalculations && (todayScheduleGoalCalculations.length !== 0)
            && (typeof todayScheduleGoalCalculations !== 'undefined') && (todayScheduleGoalCalculations !== null) && todayScheduleGoalCalculations[0]) {
            return patientGoalDialog(
                data.practiceName,
                todayScheduleGoalCalculations[0].todaysgoal,
                todayScheduleGoalCalculations[0].totalbudget,
                todayScheduleGoalCalculations[0].TodayDate,
                todayScheduleGoalCalculations[0].MTDGoal,
                todayScheduleGoalCalculations[0].MTDCalculationsObj,
                todayScheduleGoalCalculations[0].workdaysTotal,
                todayScheduleGoalCalculations[0].workdaysLeft,
                todayScheduleGoalCalculations[0].totalshifts,
                todayScheduleGoalCalculations[0].totalsShiftsLeft,
                todayScheduleGoalCalculations[0].mtdptcount,
                todayScheduleGoalCalculations[0].MTDGoal_To_Act_ptcount,
                todayScheduleGoalCalculations[0].daysComplete,
                todayScheduleGoalCalculations[0].general_shifts_completed,
                todayScheduleGoalCalculations[0].hygienists_shifts_completed,
                todayScheduleGoalCalculations[0].general_shifts_left,
                todayScheduleGoalCalculations[0].hygienists_shifts_left,
                todayScheduleGoalCalculations[0].FvPt,
                todayScheduleGoalCalculations[0].RcPt,
                todayScheduleGoalCalculations[0].TxPt);
        } else {
            return dataNotLoadedPMD;
        }
    };

    const loadProjectedCareDr = async (dataVal: []) => {
        setTodayGoalCalculations(dataVal);
        setTodayGoalModalOpen(true);
        setLoader(false)
    };

    const loadTodayGoal = async (stringifyParams: any) => {
        setLoader(true);
        setTodayGoalModalOpen(false);
        return await pmdAction.getPMDTodaysGoalPCRPopUp(stringifyParams).then((result: any) => {
            if (stringifyParams) {
                setTodayGoalCalculations(result);
                setLoader(false)
                setTodayGoalModalOpen(true);
                return result;
            }

            setProjectRevData(result[0]);
            setLoader(false);
        });
    };

    const loadScheduleTodayGoal = async (practicename: any, practiceDBNameValue: any, type: any) => {
        setScheduleTodayGoalModalOpen(false);
        return await pmdAction.getPTTodayGoalSinglePractice(
            {
                practice: practicename,
                practiceDBName: practiceDBNameValue,
                type: type ? type.replace('schedule', '').trim() : ''
            }
        ).then((result: any) => {
            setScheduleTodayGoalCalculations(result);
            setLoader(false)
            setScheduleTodayGoalModalOpen(true);
            return result;
        });
    };

    const loadPtTodayGoal = async (practicename: any, practiceDBNameValue: any) => {
        setPtTodayGoalModalOpen(false);
        return await pmdAction.getPTTodayGoalSinglePractice(
            {practice: practicename, practiceDBName: practiceDBNameValue}
        ).then((result: any) => {
            setPtTodayGoalCalculations(result);
            setLoader(false)
            setPtTodayGoalModalOpen(true);
            return result;
        });
    };

    const sendTodayGoalPDFEmail = (uicode: any, _title: any) => {
        let divStyle = {
            fontSize: '10px !important;',
            zoom: '75%',
            maxWidth: '2000px'
        };
        let todaysGoalDialogString: string = "";
        switch (true) {
            case uicode === todaygoal :
            case uicode === fvgoal :
            case uicode === rcgoal :
            case uicode === txgoal :
            case uicode === pttodaygoal :
                todaysGoalDialogString = ReactDOMServer.renderToString((
                    <div style={divStyle}>{todayGoalDialogContent()}</div>)).replace(/<td/g, todaygoalcss);
                break;
            case uicode === scheduletodaygoal :
            case uicode === schedulefvgoal :
            case uicode === schedulercgoal :
            case uicode === scheduletxgoal :
                todaysGoalDialogString = ReactDOMServer.renderToString((
                    <div style={divStyle}>{ptScheduleTodayGoalDialogContent()}</div>)).replace(/<td/g, todaygoalcss);
                break;
            case uicode === projectedcare:
                todaysGoalDialogString = ReactDOMServer.renderToString((
                    <div style={divStyle}>{todayGoalDialogContent(projectedcare)}</div>)).replace(/<td/g, todaygoalcss);
                break;

            case uicode === projectedcareDr:
                todaysGoalDialogString = ReactDOMServer.renderToString((<div
                    style={divStyle}>{todayGoalDialogContent(projectedcareDr)}</div>)).replace(/<td/g, todaygoalcss);
                break;
        }
        reportAction.sendMailTodaysGoal({
            pdf: todaysGoalDialogString,
            practicename: data.practiceName.toLocaleUpperCase()
        });
        handleClose()
    };

    const getDataLength = () => {
        try {
            return data ?
                data.data ?
                    data.data.length : 0
                : 0;
        } catch (e) {
            return 0;
        }
    };

    const getTodayGoalDisplayName = (uicode: any) => {
        switch (true) {
            case uicode === todaygoal :
            case uicode === scheduletodaygoal :
                return "Today Goal";
            case uicode === fvgoal :
            case uicode === schedulefvgoal :
                return "FV Goal";
            case uicode === rcgoal :
            case uicode === schedulercgoal :
                return "RC Goal";
            case uicode === txgoal :
            case uicode === scheduletxgoal :
                return "TX Goal";
            case uicode === projectedcare :
            case uicode === projectedcareDr :
                return "Projected Care";
        }
    };

    const todaysScheduleGoalModalShow = () => {
        return (
            <Modal
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={todayScheduleGoalModalOpen}
                onClose={handleClose}
            >
                <Modal.Header><b>{getTodayGoalDisplayName(data.uicode)}</b> <a
                    className="ui orange label">{data && data.practiceName ? data.practiceName : "Total"}</a>
                    <div className="ui top right attached label production_right_label">
                        <Button onClick={() => handleClose()} inverted>
                            Close
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {data.uicode === scheduletodaygoal && todayScheduleGoalCalculations && ptScheduleTodayGoalDialogContent()}
                        {data.uicode === schedulefvgoal && todayScheduleGoalCalculations && ptScheduleTodayGoalDialogContent()}
                        {data.uicode === schedulercgoal && todayScheduleGoalCalculations && ptScheduleTodayGoalDialogContent()}
                        {data.uicode === scheduletxgoal && todayScheduleGoalCalculations && ptScheduleTodayGoalDialogContent()}
                    </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={() => sendTodayGoalPDFEmail(scheduletodaygoal, data.title)} inverted
                            color='orange'>
                        Send Mail
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    };

    const todaysGoalModalShow = () => {
        return (
            <Modal
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={todayGoalModalOpen}
                onClose={handleClose}
            >
                <Modal.Header><b>{getTodayGoalDisplayName(data.uicode)}</b> <a
                    className="ui orange label">{data && data.practiceName ? data.practiceName : "Total"}</a>
                    <div className="ui top right attached label production_right_label">
                        <Button onClick={() => handleClose()} inverted>
                            Close
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {data.uicode === todaygoal && todayGoalCalculations && todayGoalDialogContent()}
                        {data.uicode === fvgoal && todayGoalCalculations && todayGoalDialogContent()}
                        {data.uicode === rcgoal && todayGoalCalculations && todayGoalDialogContent()}
                        {data.uicode === txgoal && todayGoalCalculations && todayGoalDialogContent()}
                        {data.uicode === projectedcare && todayGoalCalculations && todayGoalDialogContent(projectedcare)}
                        {data.uicode === projectedcareDr && todayGoalCalculations && todayGoalDialogContent(projectedcareDr)}
                    </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={() => sendTodayGoalPDFEmail(data.uicode, data.title)} inverted color='orange'>
                        Send Mail
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    };

    const ptTodaysGoalModalShow = () => {
        return (
            <Modal
                closeOnEscape={false}
                closeOnDimmerClick={false}
                open={ptTodayGoalModalOpen}
                onClose={() => handleClose()}
            >
                <Modal.Header><b>PT Todays Goal</b> <a className="ui orange label">{data ? data.practiceName : ""}</a>
                    <div className="ui top right attached label production_right_label">
                        <Button onClick={() => handleClose()} inverted>
                            Close
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {data.uicode === pttodaygoal && ptTodayGoalCalculations && ptTodayGoalDialogContent()}
                    </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                    <Button onClick={() => sendTodayGoalPDFEmail(pttodaygoal, data.title)} inverted color='orange'>
                        Send Mail
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    };

    let total = 0;
    const totalProjectedcareModalShow = () => {
        return (
            <>
                {loader ? <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer> :
                    <Modal
                        closeOnEscape={false}
                        closeOnDimmerClick={false}
                        open={projectRevTotal}
                        onClose={handleClose}
                    >
                        <Modal.Header><b>Projected Care</b> <a className="ui orange label">Total</a>
                            <div className="ui top right attached label production_right_label">
                                <Button onClick={() => handleClose()} inverted>
                                    Close
                                </Button>
                            </div>
                        </Modal.Header>
                        <Modal.Content scrolling>
                            <Modal.Description>
                                <Table>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Practice</Table.HeaderCell>
                                            <Table.HeaderCell>Actual Care MTD</Table.HeaderCell>
                                            <Table.HeaderCell>Total Completed Shifts</Table.HeaderCell>
                                            <Table.HeaderCell>Total Shifts</Table.HeaderCell>
                                            <Table.HeaderCell>Projected Care</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {projectRevData && projectRevData.length > 0 ? projectRevData.map((i: any) => {
                                            total += (i.projectedCare && !isNaN(i.projectedCare)) ? parseInt(i.projectedCare) : 0;
                                            return (<Table.Row>
                                                    <Table.Cell>{i.practiceName}</Table.Cell>
                                                    <Table.Cell>{Number(Math.round(i.mtdcare))}</Table.Cell>
                                                    <Table.Cell>{i.general_shifts_completed}</Table.Cell>
                                                    <Table.Cell>{i.totalshifts}</Table.Cell>
                                                    <Table.Cell>{i.projectedCare && !isNaN(i.projectedCare) ? Number(Math.round(i.projectedCare)) : 0}</Table.Cell>
                                                </Table.Row>
                                            )
                                        }) : null}
                                    </Table.Body>
                                </Table>
                            </Modal.Description>
                        </Modal.Content>
                    </Modal>}
            </>
        )
    }
    return (<>
        {loader && (!todayGoalModalOpen || !ptTodayGoalModalOpen || !todayScheduleGoalModalOpen) &&
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
        }

        <>
            {props.heading &&
            <h5>{props.heading}</h5>
            }
            <div className="FreezeTable">
                <table style={props.style}
                       className={`${props.className ? `ui celled single line table unstackable tableStyle ${props.className}` : "ui celled single line table unstackable tableStyle"}`}>
                    {states.TableHead}
                    {states.TableData}
                </table>
            </div>
        </>
        <Modal
            closeOnEscape={false}
            closeOnDimmerClick={false}
            open={addModalOpen}
            onClose={handleClose}
            size={data.uicode == 'INSURANCE'
            || data.uicode == 'AGE MIX' || data.uicode == 'ACT PT MTD' || data.uicode == 'COMPLETED PATIENTS'
            || data.uicode == 'InsuranceMix' || data.uicode == 'ACT ALL PT MTD' ? 'fullscreen' : 'large'}>
            <Modal.Header><b>{data.popupTitle}</b>
                {data.practiceName ? <a className="ui orange label">{capitalizeFirstLetter(data.practiceName)}</a> : ''}
                {data.uicode == 'SHOW RATE' &&
                    <Label className="ui orange label"> Completed Apt:{data.completedApt}</Label>}
                {data.uicode == 'SHOW RATE' && <Label className="ui orange label"> All Apt:{data.allApts}</Label>}
                {data.uicode == 'SHOW RATE' && <Label className="ui orange label"> Show Rate:{data.showRate}</Label>}
                {data.uicode == 'INSURANCE' && <Label
                    className="ui orange label"> PT-Visit:{data.dataCount ? data.dataCount : data.data.length}</Label>}
                {data.uicode == 'INSURANCE' && <Label
                    className="ui orange label"> CPV:{data.cpvCareData ? '$' + PriceFormat(data.cpvCareData) : '$' + PriceFormat(0)}</Label>}
                {data.uicode == 'INSURANCE' && <Label
                    className="ui orange label"> BCare:{data.bCareData ? '$' + PriceFormat(data.bCareData) : '$' + PriceFormat(0)} </Label>}
                {data.uicode == 'ACT PT MTD' && <Label
                    className="ui orange label"> PT-Visit:{data.dataCount ? data.dataCount : data.data.length}</Label>}
                {data.uicode == 'ACT PT MTD' && <Label
                    className="ui orange label"> CPV:{data.cpvCareData ? '$' + PriceFormat(data.cpvCareData) : '$' + PriceFormat(0)}</Label>}
                {data.uicode == 'ACT PT MTD' && <Label
                    className="ui orange label"> BCare:{data.bCareData ? '$' + PriceFormat(data.bCareData) : '$' + PriceFormat(0)} </Label>}
                {data.uicode == 'AGE MIX' && <Label
                    className="ui orange label"> PT-Visit:{data.dataCount ? data.dataCount : data.data.length}</Label>}
                {data.uicode == 'AGE MIX' && <Label
                    className="ui orange label"> CPV:{data.cpvCareData ? '$' + PriceFormat(data.cpvCareData) : '$' + PriceFormat(0)}</Label>}
                {data.uicode == 'AGE MIX' && <Label
                    className="ui orange label"> BCare:{data.bCareData ? '$' + PriceFormat(data.bCareData) : '$' + PriceFormat(0)} </Label>}
                {data.uicode == 'COMPLETED PATIENTS' && <Label
                    className="ui orange label"> PT-Visit:{data.dataCount ? data.dataCount : data.data.length}</Label>}
                {data.uicode == 'COMPLETED PATIENTS' && <Label
                    className="ui orange label"> CPV:{data.cpvCareData ? '$' + PriceFormat(data.cpvCareData) : '$' + PriceFormat(0)}</Label>}
                {data.uicode == 'COMPLETED PATIENTS' && <Label
                    className="ui orange label"> BCare:{data.bCareData ? '$' + PriceFormat(data.bCareData) : '$' + PriceFormat(0)} </Label>}
                {data.uicode == 'InsuranceMix' && <Label
                    className="ui orange label"> PT-Visit:{data.dataCount ? data.dataCount : data.data.length}</Label>}
                {data.uicode == 'InsuranceMix' && <Label
                    className="ui orange label"> CPV:{data.cpvCareData ? '$' + PriceFormat(data.cpvCareData) : '$' + PriceFormat(0)}</Label>}
                {data.uicode == 'InsuranceMix' && <Label
                    className="ui orange label"> BCare:{data.bCareData ? '$' + PriceFormat(data.bCareData) : '$' + PriceFormat(0)} </Label>}
                {data.uicode == 'ACT ALL PT MTD' && <Label
                    className="ui orange label"> PT-Visit:{data.dataCount ? data.dataCount : data.data.length}</Label>}
                {data.uicode == 'ACT ALL PT MTD' && <Label
                    className="ui orange label"> CPV:{data.cpvCareData ? '$' + PriceFormat(data.cpvCareData) : '$' + PriceFormat(0)}</Label>}
                {data.uicode == 'ACT ALL PT MTD' && <Label
                    className="ui orange label"> BCare:{data.bCareData ? '$' + PriceFormat(data.bCareData) : '$' + PriceFormat(0)} </Label>}

                <Label color={((data.uicode === todaygoal) || (data.uicode === fvgoal) ||
                (data.uicode === rcgoal) || (data.uicode === txgoal) || (data.uicode === scheduletodaygoal) || (data.uicode === schedulefvgoal) ||
                (data.uicode === schedulercgoal) || (data.uicode === scheduletxgoal) || (data.uicode === projectedcare) || (data.uicode === projectedcareDr) ? 0 : getDataLength) > 0 ? 'green' : 'red'}>
                    {data.dataCount ? data.dataCount : data.data.length}
                </Label>
                {(data.popupTitle === 'Office Discounts' || data.popupTitle === 'LWD Production') && data.title &&
                    <Label>{data.title}</Label>}

                <div className="ui top right attached label production_right_label">
                    <Button onClick={handleClose} inverted>
                        Close
                    </Button>
                </div>
            </Modal.Header>
            <Modal.Content scrolling>
                <Table className="table table-striped table-hover my-0 FreezeTable sortable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            <div className="tbl-header">
                                {data.uicode === 'Work Day' ? 'Location' : 'Practice'}</div>
                        </Table.HeaderCell>
                        {data.uicode !== 'adjustment' && <Table.HeaderCell
                            sorted={data.columnX === data.col1Key ? data.directionX : null}
                            onClick={() => handleSortPtSeenTodayDetail(data.col1Key)}>
                            <div className="tbl-header">{data.col1Name}</div>
                        </Table.HeaderCell>}
                        {data.col2Key &&
                            <Table.HeaderCell sorted={data.columnX === data.col2Key ? data.directionX : null}
                                              onClick={() => handleSortPtSeenTodayDetail(data.col2Key)}
                            >
                                <div className="tbl-header">{data.col2Name}</div>
                            </Table.HeaderCell>}
                        {data.col3Key &&
                            <Table.HeaderCell sorted={data.columnX === data.col3Key ? data.directionX : null}
                                              onClick={() => handleSortPtSeenTodayDetail(data.col3Key)}
                            >
                                <div className="tbl-header">{data.col3Name}</div>
                            </Table.HeaderCell>}
                        {data.col4Key &&
                            <Table.HeaderCell sorted={data.columnX === data.col4Key ? data.directionX : null}
                                              onClick={() => handleSortPtSeenTodayDetail(data.col4Key)}
                            >
                                <div className="tbl-header">{data.col4Name}</div>
                            </Table.HeaderCell>}
                        {data.col5Key &&
                            <Table.HeaderCell sorted={data.columnX === data.col5Key ? data.directionX : null}
                                              onClick={() => handleSortPtSeenTodayDetail(data.col5Key)}
                            >
                                <div className="tbl-header">{data.col5Name}</div>
                            </Table.HeaderCell>}
                        {data.col6Key &&
                            <Table.HeaderCell sorted={data.columnX === data.col6Key ? data.directionX : null}
                                              onClick={() => handleSortPtSeenTodayDetail(data.col6Key)}
                            >
                                <div className="tbl-header">{data.col6Name}</div>
                            </Table.HeaderCell>}
                        {data.col7Key &&
                            <Table.HeaderCell sorted={data.columnX === data.col7Key ? data.directionX : null}
                                              onClick={() => handleSortPtSeenTodayDetail(data.col7Key)}
                            >
                                <div className="tbl-header">{data.col7Name}</div>
                            </Table.HeaderCell>}
                        {data.col8Key && data.uicode !== 'adjustment' &&
                            <Table.HeaderCell sorted={data.columnX === data.col8Key ? data.directionX : null}
                                              onClick={() => handleSortPtSeenTodayDetail(data.col8Key)}
                            >
                                <div className="tbl-header">{data.col8Name}</div>
                            </Table.HeaderCell>}
                        {data.col9Key &&
                            <Table.HeaderCell sorted={data.columnX === data.col9Key ? data.directionX : null}
                                              onClick={() => handleSortPtSeenTodayDetail(data.col9Key)}
                            >
                                <div className="tbl-header">{data.col9Name}</div>
                            </Table.HeaderCell>}
                        {data.col10Key &&
                            <Table.HeaderCell sorted={data.columnX === data.col10Key ? data.directionX : null}
                                              onClick={() => handleSortPtSeenTodayDetail(data.col10Key)}
                            >
                                <div className="tbl-header">{data.col10Name}</div>
                            </Table.HeaderCell>}
                        {data.col11Key &&
                            <Table.HeaderCell sorted={data.columnX === data.col11Key ? data.directionX : null}
                                              onClick={() => handleSortPtSeenTodayDetail(data.col11Key)}
                            >
                                <div className="tbl-header">{data.col11Name}</div>
                            </Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {data.data.length && data.data.map((result: any) => {
                            const practiceName = data.practiceName ? capitalizeFirstLetter(data.practiceName) : result['practice'] ?
                                capitalizeFirstLetter(result['practice']) : '';
                            return (
                                result['comment'] || result['adjustment'] ?
                                    <Table.Row>
                                        <Table.Cell>{practiceName}</Table.Cell>
                                        {data.col2Key && <Table.Cell>{result[data.col2Key]}</Table.Cell>}
                                        {data.col3Key && <Table.Cell>{result[data.col3Key]}</Table.Cell>}
                                        {data.col4Key && <Table.Cell>{result[data.col4Key]}</Table.Cell>}
                                        {data.col5Key && <Table.Cell>{result[data.col5Key]}</Table.Cell>}
                                        {data.col7Key && <Table.Cell>{result[data.col7Key]}</Table.Cell>}
                                    </Table.Row> :
                                    <Table.Row>
                                        <Table.Cell>{practiceName}</Table.Cell>
                                        {data.col1Key && <Table.Cell>{result[data.col1Key]}</Table.Cell>}
                                        {data.col2Key && <Table.Cell>{result[data.col2Key]}</Table.Cell>}
                                        {data.col3Key && <Table.Cell>{result[data.col3Key]}</Table.Cell>}
                                        {data.col4Key && <Table.Cell>{result[data.col4Key]}</Table.Cell>}
                                        {data.col5Key && <Table.Cell>{result[data.col5Key]}</Table.Cell>}
                                        {data.col6Key && <Table.Cell>{result[data.col6Key]}</Table.Cell>}
                                        {data.col7Key && <Table.Cell>{result[data.col7Key]}</Table.Cell>}
                                        {data.col8Key && <Table.Cell>{result[data.col8Key]}</Table.Cell>}
                                        {data.col9Key && <Table.Cell>{result[data.col9Key]}</Table.Cell>}
                                        {data.col10Key && <Table.Cell>{result[data.col10Key]}</Table.Cell>}
                                        {data.col11Key && <Table.Cell>{result[data.col11Key]}</Table.Cell>}
                                    </Table.Row>
                            );
                        }
                    )}
                </Table.Body>

            </Table>
            </Modal.Content>

            <Modal.Actions>
                <Button
                    onClick={(data.uicode !== todaygoal || data.uicode !== projectedcare || data.uicode !== projectedcareDr || data.uicode !== scheduletodaygoal) ? data.uicode !== "" ? handleSendMailAction : handleClose : handleClose}
                    inverted color='orange'>
                    Send Mail
                </Button>
            </Modal.Actions>
        </Modal>
        {data && data.uicode === todaygoal && todayGoalCalculations && todaysGoalModalShow()}
        {data && data.uicode === pttodaygoal && ptTodayGoalCalculations && ptTodaysGoalModalShow()}
        {data && data.uicode === fvgoal && todayGoalCalculations && todaysGoalModalShow()}
        {data && data.uicode === rcgoal && todayGoalCalculations && todaysGoalModalShow()}
        {data && data.uicode === txgoal && todayGoalCalculations && todaysGoalModalShow()}
        {data && data.uicode === schedulefvgoal && todayScheduleGoalCalculations && todaysScheduleGoalModalShow()}
        {data && data.uicode === schedulercgoal && todayScheduleGoalCalculations && todaysScheduleGoalModalShow()}
        {data && data.uicode === scheduletxgoal && todayScheduleGoalCalculations && todaysScheduleGoalModalShow()}
        {data && data.uicode === scheduletodaygoal && todayScheduleGoalCalculations && todaysScheduleGoalModalShow()}
        {data && data.uicode === projectedcare && todayGoalCalculations && todaysGoalModalShow()}
        {data && data.uicode === projectedcareDr && todayGoalCalculations && todaysGoalModalShow()}
        {data && data.uicode === totalProjectedcare && totalProjectedcareModalShow()}

        {!!isOpen &&
            <PcrSplashBoardInfo
                handleClose={() => setOpen(false)}
                items={getMDMInfoVal}
            />
        }
    </>)
};

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        practice: state.practice,
        patients: state.patients,
        loginVia: state.report.loginVia,
        report: state.report,
        mdm_report: state.mdm.mdm_report,
    };
};

export default connect(mapStateToProps)(DynamicHtmlTable);