import React from "react";
import Template from "../components/Template";
import ManageHelp from "../components/ManageHelp";

function Dashboard() {

    return (
        <Template activeLink='manageHelp'>
            <ManageHelp/>
        </Template>
    );

}

export default Dashboard;