import React, { useEffect, useState } from 'react';
import { AccordionTitle, AccordionContent, Icon, Grid, GridRow, GridColumn, Loader, Progress, Label, ButtonGroup, Button } from 'semantic-ui-react'
import * as HealthStatusActions from "../../../redux/actions/healthStatus";
import { useActions } from '../../../redux/actions';
import OpenDentalStatusTable from './OpenDentalStatusTable';
import ProgressBar from '../ProgressBar';

function OpenDentalHealth() {
    const initialState = { loading: false, panel: '', data: [] as any[], response: [] as any, counts: { failed: 0, success: 0, pending: 0 } };
    const healthStatusActions = useActions(HealthStatusActions);
    const [state, setState] = useState(initialState);
    const { loading, panel, data, response, counts } = state;
    const prefix = 'openDental'

    useEffect(() => {
        setState({ ...state, loading: true });
        healthStatusActions.getOpenDentalConnection()
            .then((response: any) => {
                sortInfo(response)
            }).catch(() => setState({ ...state, loading: false }))
    }, [])

    const sortInfo = (data: any[]) => {
        let reachable: any[] = [];
        let notReachable: any[] = [];
        let failed = 0;
        data.forEach(item => item.isReachable ? reachable.push(item) : (notReachable.push(item), failed++));
        const counts = { success: data.length - failed, failed: failed, pending: 0 };
        healthStatusActions.addToCount(counts).then();
        setState({ ...state, response: data, counts, data: [...notReachable, ...reachable], loading: false });
    }

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }

    const view = (passed: boolean) => {
        if (passed)
            setState({ ...state, data: response.filter((o: any) => o.isReachable) });
        else
            setState({ ...state, data: response.filter((o: any) => !o.isReachable) });
    }

    return (
        <>
            <AccordionTitle active={state.panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Open Dental Connections</span>
                            {loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={counts.failed} pending={counts.pending} success={counts.success} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === prefix}>
                {!loading && <Grid>
                    <GridRow>
                        <GridColumn width={16}>
                            <ButtonGroup >
                                <Button onClick={() => view(true)} icon='folder outline' color='green' content={`Passed ${(counts.success)}`} />
                                <Button onClick={() => view(false)} labelPosition='right' icon='folder outline' color='red' content={`Failed ${(counts.failed)}`} />
                            </ButtonGroup>
                            {state.data && <OpenDentalStatusTable records={data} />}
                        </GridColumn>
                    </GridRow>
                </Grid>}
            </AccordionContent>
        </>
    );
}

export default OpenDentalHealth;