import React, {useState, useEffect} from "react";
import {connect} from 'react-redux';
import ReactDOMServer from "react-dom/server";
import Template from "../../components/Template";
import FilterSection from "../../components/FilterSection";
import ReportMain from "../../components/ReportMain";
import {exportPcrCSV} from "../../components/ExportCsv";
import {sendPCREmail} from "../../components/sendEmail";
import {useActions} from "../../redux/actions";
import * as reportActions from "../../redux/actions/report";
import * as appActions from "../../redux/actions/app";
import {RootState} from "../../model";
import {ReportProps, SendEmail} from "../../model/report";
import {ApplyFilter, OnChangeFilter} from "../../model/common";
import {reportInitialState, reportPageNames} from "../../constants";
import {getFilteredData, practiceRecords} from "./Filters/Common";
import RenderHtml from "./RenderHtml";
import DynamicHtmlTableMail from "../../components/DynamicHtmlTableMail";
import * as userSettingsActions from "../../redux/actions/userSettings";
import {Dimmer, Loader} from "semantic-ui-react";
import * as practiceActions from "../../redux/actions/practice";
import Loading from "../../Loading";

function ByInsuranceMix(props: ReportProps) {
	const reportAction = useActions(reportActions);
	const appAction = useActions(appActions);
	const userSettingsAction = useActions(userSettingsActions);
	const [state, setStates] = useState(reportInitialState);
	const data = state.data, page = reportPageNames.insuranceMix;
	const [loader, setLoader] = useState(false);
	const practiceAction = useActions(practiceActions);

	useEffect(() => { getPraticesRecords()},  [props]);

	const getPraticesRecords = async() => {
		const propVal = !!props.report.reportNewData ? props.report.reportNewData : props.reportTN.reportTimeAndName[0];
		if(propVal) {
			const stateObj = await practiceRecords(props, state, appAction, practiceAction, userSettingsAction, page, 'byPCRInsuranceMixReport')
			setStates(stateObj);
		}
		await reportAction.filterByValue(reportInitialState.filters);
	}

	const onChangeFilter:OnChangeFilter = async (filters, callApi=false) => {
		filters.month && await reportAction.resetReportData();
		reportAction.filterByValue(filters);
		applyFilter(filters);
		setLoader(true);
		await reportAction.filterByValue(reportInitialState.filters);
		setLoader(false);
	};

	const applyFilter:ApplyFilter = (filters) => {
		const propsData=JSON.parse(JSON.stringify(props));
		const [selectedPractices, data] = getFilteredData(filters, propsData, page, 'byPCRInsuranceMixReport');
		appAction.UpdatePracticesOptions(selectedPractices)
		setStates({...state, filters, data, selectedPractices});
	};

	const sendEmail:SendEmail = (dataArray) => {
		if(data && data.tableData && Object.keys(data.tableData).length) {
			const pdf = Object.keys(data.tableData).map( key => (
				ReactDOMServer.renderToString(
					<DynamicHtmlTableMail
						key={key}
						className={`by-age-mix ${key}`}
						tableHead={data ? data.tableHead : []}
						heading={data ? data.tableData[key].heading : ""}
						tableData={data ? data.tableData[key].tableData : []}
					/>)
			));
			reportAction.sendReportData({ filters: state.filters, page: page, data: dataArray, pdf: pdf.join("") });
		}
	};

	return (
		<Template activeLink={`pcr-${page}`}>
			{loader && <Dimmer active={true} inverted={true}>
				<Loading />
			</Dimmer>
			}
			<ReportMain>
				<FilterSection page={page} heading="PCR By Insurance Mix" filters={state.filters} onChangeFilter={onChangeFilter} exportCSV={()=>exportPcrCSV(data, page)} sendEmail={()=>sendPCREmail(data, page, sendEmail)} />
				<RenderHtml data={data} page={page} />
			</ReportMain>
		</Template>
	);

}

function mapStateToProps(state: RootState) {
	return {
		report: state.report,
		practice: state.practice,
		reportTN: state.reportTimeAndName
	};
}

export default connect(mapStateToProps)(ByInsuranceMix);