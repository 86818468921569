import React, {useEffect, useState} from 'react';
import {Button, Label, Modal} from 'semantic-ui-react'
import {useActions} from "../../redux/actions";
import * as ProfileActions from "../../redux/actions/provider";

interface ProviderRampThresholdModalProps {
    openProviderRampThresholdModal: boolean
    userId: number
    DocData: any,
    showOpenProviderRampThresholdModal: any,
    initialValues: any
}

const ProviderRampThresholdModal = (props: ProviderRampThresholdModalProps) => {
    const doctorRampThreshold: Array<any> = []
    const [state, setState] = useState({
        loaded: 0,
        Location: null,
        value: null,
        name: null,
        index: null,
        doctorRampThreshold: doctorRampThreshold
    })

    const providerActions = useActions(ProfileActions);


    useEffect(() => {
        const fetchData = async () => {
            const {userId} = props;
            const doctorRampThreshold = await providerActions.getDoctorRampThreshold(userId);
            if (doctorRampThreshold) {
                setState({
                    ...state,
                    doctorRampThreshold: doctorRampThreshold
                })
            }
        }

        fetchData();
    }, []);

    const handleOptionsWithAll = (e: string, values: { key: any; index: any; data?: any; }) => {
        const {key, index} = values
        handleOptions(e, key, index)
    };


    const displayRow = (data: any[], key: number) => {
        let rows: JSX.Element[] = []
        data && Object.keys(data).forEach((threshold, index) => {
            const value = data[index]
            rows.push(
                <td key={index}>
                    <div className="form-group">
                        <input key={index}
                               style={{border: '1px solid #1b1c1d'}}
                               type="text"
                               name={'threshold' + index + key}
                               value={value}
                               onChange={(e) => handleOptionsWithAll(e.target.value, {
                                   key,
                                   index,
                                   data
                               })}
                        />
                    </div>
                </td>
            )
        })
        return rows
    }

    const handleOptions = (value: string, key: number, index: React.Key) => {
        let {doctorRampThreshold} = state;
        doctorRampThreshold[key].reduced_practice_threshold[index] = value;
        setState({...state, doctorRampThreshold});
    };

    const showPatientVisitHeaders = () => {
        let headers = []
        for (let i = 0; i <= 15; i++) {
            headers.push(
                <th key={i}>
                    <div>
                        {i} visits
                    </div>
                </th>
            )
        }
        return headers
    }

    const saveRampThreshold = async () => {
        const {doctorRampThreshold} = state;
        const {userId, showOpenProviderRampThresholdModal} = props;
        const result = await providerActions.updateRampThreshold(doctorRampThreshold, userId);
        if(result){
            alert('Ramp threshold change(s) successfully updated.');
            showOpenProviderRampThresholdModal(false);
        } else {
            alert('Failed to update ramp threshold changes.');
        }
    };

    return (
        <Modal
            open={props.openProviderRampThresholdModal}
            aria-labelledby="ModalHeader"
            backdrop={false}
            className="show"
            size={"large"}
            dialogClassName="modal-90w"
            closeIcon
            onClose={() => props.showOpenProviderRampThresholdModal(false)}
        >
            <Modal.Header>
                <div className="row">
                    <Modal.Content id='ModalHeader'
                                   style={{
                                       display: "flex",
                                       justifyContent: "center",
                                       alignItems: "center",
                                   }}>
                        Ramp ThreshHold for  &nbsp; <Label color='grey'>
                        Dr &nbsp;&nbsp;{props.DocData.firstName || ''} {" " + props.DocData.lastName || ''}
                    </Label>  &nbsp;&nbsp;
                        <Modal.Content id='ModalHeader'>
                            | &nbsp;&nbsp;<span>  Status:</span> <Label
                            color={props.DocData.status ? 'teal' : 'red'}>{props.DocData.status ? 'Active' : 'DeActive'}</Label>
                        </Modal.Content>
                    </Modal.Content>
                </div>
            </Modal.Header>
            <Modal.Content style={{maxHeight: '500px', overflowY: 'scroll'}}>
                <div className="ui ">
                    <div className="content">
                        <div>
                            <table className="table mb12">
                                <thead>
                                <tr>
                                    <th>

                                    </th>
                                    <th colSpan={16} className={`FreezeTable-first-child `}>
                                        <div>
                                            Threshold
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th className={`FreezeTable-first-child `}>
                                        <div>
                                            Practice
                                        </div>
                                    </th>
                                    {showPatientVisitHeaders()}
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    state.doctorRampThreshold && state.doctorRampThreshold.map((data: any, key) => {
                                        return <tr className="form-group" key={key}>
                                            <td> {data.practiceName}</td>
                                            {displayRow(data.reduced_practice_threshold, key)}
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Close"
                    secondary
                    labelPosition='right'
                    icon='close'
                    onClick={() => props.showOpenProviderRampThresholdModal(false)}
                    negative
                />
                <Button
                    content="Save"
                    primary
                    labelPosition='right'
                    icon='save'
                    onClick={() => saveRampThreshold()}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

export default ProviderRampThresholdModal
