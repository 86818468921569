import React, {SyntheticEvent, useEffect, useState} from "react";
import Template from "../../components/Template";
import {useActions} from "../../redux/actions";
import * as reportActions from "../../redux/actions/report";
import {App, RootState} from "../../model";
import {connect} from "react-redux";
import * as practiceActions from "../../redux/actions/practice";
import * as patientValidationActions from "../../redux/actions/patients";
import {DropDownOptions, Practice, Regions, UdaPracticeInfo} from "../../model/practice";
import {patientValidationReport,} from '../../model/report'
import {
    exportPatientValidationActionReport,
    exportPDFPVAReport,
} from "../../components/ExportCsv";

import {Dropdown, Button, Icon, Grid} from "semantic-ui-react";
import moment from 'moment';
import {
    enabledcategoryList,
    statusList,
    finalInsurancecategoryList, enabledAptStatusList, brands
} from '../../constants'
import {Report} from "../../model/report";
import {Patients} from "../../model/patients";
import * as userSetupActions from "../../redux/actions/userSettings";
import {SetupFields} from "../../enums/userSetupField";
import {SetupAppName} from "../../enums/userSetupAppName";
import HelpMark from "../../components/HelpMark";



interface Props {
    report: Report,
    practice: Practice,
    patients: Patients,
    app: App,
}

function PatientValidationActionReport(props: Props) {
    const statusCategoryOptions: Array<object> = [{key: 'none', value: null, text: 'Select Status'}];
    const aptStatusCategoryOptions: Array<object> = [{key: 'none', value: null, text: 'Select Apt Status'}];

    const checkinfo:any  = {info :[]};

    const initialState = {
        filters: {
            practice: [],
            status: '',
            aptStatus : '',
            practiceOptions: [],
            statusCategoryOptions: statusCategoryOptions,
            aptStatusCategoryOptions:aptStatusCategoryOptions,
            region:[],
            brands:[]

        },
        practiceProps:{},
        report: {},
        column: "all",
        direction: "sort",
        color: '',
    };
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(true);
    const [carrierInfo, setCarrier] = useState([]);
    const [defaultPracticeData, setDefaultPracticeData] = useState({practices:[]});
    const [carrierList, setCarrierList] = useState([]);
    const [excel, setExcel] = useState(true);

    enabledcategoryList && enabledcategoryList.forEach((item: any, index: number) => {
        statusCategoryOptions.push(
            {text: item, value: item, key: index}
        )
    });
    enabledAptStatusList && enabledAptStatusList.forEach((item: any, index: number) => {
        aptStatusCategoryOptions.push(
            {text: item, value: item, key: index}
        )
    });

    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const patientValidationAction = useActions(patientValidationActions);
    const practiceAction = useActions(practiceActions);
    const reportAction = useActions(reportActions);
    const userSetup = useActions(userSetupActions);
    const refinePracticeArray = (response: any) => {
        let practiceNames: Array<string> = ['all'];
        let practiceInfo: any = {};
        let practiceOptions = [{key: 'none', value: 'all', text: 'All Practices',lowerName:''}];
        if (response) {
            response.forEach((item: any) => {
                practiceOptions.push({text: item.practice, value: item.practice, key: item.id.toString(),lowerName: item.practice.toLowerCase()});
                practiceInfo[item.practice.toLowerCase()] = item;
            })
        }
        let userParams = { field: SetupFields.Practice, appId: SetupAppName.CDP_MY };
        userSetup.getSetup(userParams).then((infoData:any) => {
            let defaultPractices:any = infoData.value.split(",")
            let practices:any = []
            defaultPractices.forEach((defaultPractice:any)=>{
                if(practiceInfo[defaultPractice] && defaultPractice !== 'all') {
                    let practiceData:any = practiceInfo[defaultPractice]
                    practices.push(practiceData.practice)
                }
                else practices.push(defaultPractice)
            })
            setDefaultPracticeData({practices: practices});
            const filters = Object.assign(state.filters, {practice:practiceNames,practiceOptions: practiceOptions});
            setState({...state, filters});
        })

        return { practiceOptions: practiceOptions, practiceInfo: practiceInfo}
    };

    useEffect(() => {
        const promiseArr = new Array();
        promiseArr.push(practiceAction.fetchRegions(refineRegionsArray));
        practiceAction.fetchPractices(refinePracticeArray);
        practiceAction.fetchPracticeUda();
        if (!props.patients.defaultPractice) {
            let userParams = { field: SetupFields.Practice, appId: SetupAppName.CDP_MY };
            userSetup.getSetup(userParams)
        }

        const info = {date: startDate, practice: 'all'}; // '2021-02-01'  // '2021-02-08'
        patientValidationAction.getCarrierIdentity().then((data: any) => {
            setCarrier(data);
            reportAction.getPatientValidationReport(info).then((report: patientValidationReport) => {
                setState({...state, report});
            })
        });

    }, []);

    const refineRegionsArray = (regionList:Regions[]) => {
        let regionListOptions:DropDownOptions[] = [{ key: '0', value: 0, text: 'Select Region' }];
        if(regionList.length) {
            regionList.forEach(item => {
                regionListOptions.push({ text: item.name, value: item.id, key: item.id })
            });
        }
        return regionListOptions;
    };

    const handleSortClick = (insuranceInfo: any, clickedColor: any, directionInfo: any) => {
        const direction = state.direction === "sort" ? "sortByAsc" : directionInfo === "sortByAsc" ? "sortByDesc" : "sortByAsc";
        setState({...state, direction, column: insuranceInfo, color: clickedColor});
    }

    const getTableHeadings = () => {
        let returnArray: React.ReactElement[] = [];
        let returnArrayList: React.ReactElement[] = [];
        let returnArrayListIns: React.ReactElement[] = [];
        let carrierListInfo: any = carrierList.length > 0 ? carrierList : enabledcategoryList;
        const dir: any = state.direction;
        const columnName: any = state.column;
        {
            carrierListInfo.length > 3 && carrierListInfo.shift()
        }

        carrierListInfo.forEach((item: any, key: any) => {
            {
                carrierListInfo.length === 1 ?
                    returnArray.push(<th key={key + item} colSpan={3} style={{textAlign: "center"}}>{item}
                        {dir === "sortByAsc" ? <Icon color="yellow" name={'sort amount up'}/> : ''}
                        {dir === "sortByDesc" ? <Icon color="red" name={'sort amount down'}/> : ''}</th>):
                    returnArray.push(<th key={key + item} colSpan={3} style={{textAlign: "center"}}>
                        {item}{item === "Error" && columnName === "Error" && dir === "sortByAsc" ?
                        <Icon color="yellow" name={'sort amount up'}/> : item === "Verified" && columnName === "Verified" && dir === "sortByAsc" ?
                            <Icon color="yellow" name={'sort amount up'}/> : item === "Main Dental Home" && columnName === "Main Dental Home" && dir === "sortByAsc" ?
                                <Icon color="yellow" name={'sort amount up'}/> : ''}

                        {item === "Error" && columnName === "Error" && dir === "sortByDesc" ?
                            <Icon color="red" name={'sort amount down'}/> :item === "Verified" && columnName === "Verified" && dir === "sortByDesc" ?
                                <Icon name={'sort amount down'}/> : item === "Main Dental Home" && columnName === "Main Dental Home" && dir === "sortByDesc" ?
                                    <Icon color="red" name={'sort amount down'}/>: ''}
                    </th>)
            }

        });

        {
            carrierListInfo.length === 2 && carrierListInfo.shift()
        }



        {
            carrierListInfo.length === 1 ? finalInsurancecategoryList.forEach((item: any, key: any) => {

                    returnArrayListIns.push(<th key={key + 1} style={{textAlign: "center"}}>
                        {key === 0 ?
                            <p><Icon name='check circle' color='grey'/><Icon name={'sort'} className="valSort" onClick={() => handleSortClick(item.ins, item.color[key], dir)}/>
                            </p> : key === 1 ? <p><Icon name='check circle' color='green'/><Icon name={'sort'} className="valSort" onClick={() => handleSortClick(item.ins, item.color[key], dir)}/>
                            </p> :<p><Icon name='check circle' color='red'/><Icon name={'sort'} className="valSort" onClick={() => handleSortClick(item.ins, item.color[key], dir)}/>
                            </p> }</th>)
                }) :

                finalInsurancecategoryList.forEach((item: any, key: number) => {
                    returnArrayList.push(
                        <th key={key+1+item.ins} style={{textAlign: "center"}}>
                            <Icon className="ins" name='check circle' color='grey'/>
                            <Icon className="valSort" name={'sort'} onClick={() => handleSortClick(item.ins, key == 0 ? item.color[key] :
                                key === 1 ? item.color[key - 1] :  item.color[key - 2] , dir)}/>
                        </th>);
                    returnArrayList.push(<th key={key+2+item.ins} style={{textAlign: "center"}}>
                        <Icon className="ins" name='check circle' color='green'/>
                        <Icon className="valSort" name={'sort'} onClick={() => handleSortClick(item.ins, key == 0 ?
                            item.color[key + 1] : key === 1 ? item.color[key] :  item.color[key - 1] , dir)}/>
                    </th>);
                    returnArrayList.push(<th key={key+3+item.ins} style={{textAlign: "center"}}>
                        <Icon className="ins" name='check circle' color='red'/>
                        <Icon className="valSort" name={'sort'} onClick={() => handleSortClick(item.ins, key == 0 ?
                            item.color[key + 2] : key === 1 ? item.color[key + 1] : item.color[key], dir)}/>
                    </th>);
                })
        }

        return (<thead>
        <tr key="firstHead">
            <th key={7}>&nbsp;</th>
            {returnArray}</tr>
        <tr key="secondHead">
            <th key={7}>Practice</th>
            {carrierListInfo.length === 1 ? returnArrayListIns : returnArrayList}
        </tr>
        </thead>)

    };

    const brandsOptions=()=>{
        const brandOptions:string[]=[]
        const brandsObject:{[key:string]:string[]}={}
        brands.forEach((brand) => {
            const res = props.practice.udaPracticeInfo.filter(
                (item: UdaPracticeInfo) => {
                    if (item.brand === brand.value) {
                        return true
                    }
                }
            ).map(item => item.practiceName)
            brandsObject[brand.value] = [...res.map(i => i.toLocaleLowerCase())]
        });
        if(state.filters.region){
            const tempSelectedPracsPerRegion:{[key:string]:string[]}={}
            state.filters.region.forEach((item)=>{
                if(props.practice.regionPractices[item]){
                    tempSelectedPracsPerRegion[item]=
                        props.practice.regionPractices[item] as []

                }
            })
            return (brandOptions.length>0)?brandOptions.map(b=>{
                    return {
                        value:b,
                        key:b,
                        text:b
                    }
                }):
                brands
        }

    }

    const onChangeFilter = (e: SyntheticEvent, element: { [key: string]: any }) => {
        switch (element.name) {
            case 'status': {
                onStatusSelect(element);
                break;
            }
            case 'practice': {
                onPracticeSelect(element);
                break;
            }
            case 'aptStatus': {
                onAptStatusSelect(element);
                break;
            }
            case 'region': {
                onRegionSelect(element);
                break;
            }
            case 'brands': {
                onBrandsSelect(element);
                break;
            }

        }
    };

    const fetchRecords = (info: any, practiceInfo: any,) => {
        setStartDate(info);
        const data = {date: info, practice: practiceInfo};
        reportAction.getPatientValidationReport(data).then((report: any) => {
            setState({...state, direction: "sort", column: "all", color: '', report});
        });
    }

    const onPracticeSelect = (element: { [key: string]: any }) => {
        const patientValidationData: patientValidationReport = state.report ? state.report : props.report.patientValidationData;
        const infoPatientValidationData: any = props.report.patientValidationData;
        const report = {};
        let practices = element.value;
        if (element.value.length >= 0 && element.value.indexOf('all') === element.value.length -1) {
            practices = ['all'];
            setDefaultPracticeData({practices: practices});
            setState({...state, report: props.report.patientValidationData});

        } else if (element.value.length > 1 && element.value.indexOf('all') === 0) {
            element.value.splice(0, 1);
            setDefaultPracticeData({practices: element.value});
            Object.assign(report, {
                        [element.value]: patientValidationData[element.value] ?
                            patientValidationData[element.value] : infoPatientValidationData[element.value]
                    });
            setState({...state, report});
        }else{
            practices = element.value;
            let filters:any = {
                practices: practices,
                status: '',
                aptStatus : '',
                practiceOptions: state.filters.practiceOptions,
                statusCategoryOptions: statusCategoryOptions,
                aptStatusCategoryOptions:aptStatusCategoryOptions,
                region:'',
                brands:''
            };
            state.report = props.report.patientValidationData;
            Object.assign(checkinfo,{info:practices});
            setDefaultPracticeData({practices: practices});
            filters = Object.assign(state.filters, {practice:practices});
            setState({...state, filters});
        }
    };

    const onAptStatusSelect = (element: { [key: string]: any }) => {
       const infoPatientValidationData: any = props.report.patientValidationData;
        const value:any = element.value;
        let filters = Object.assign(state.filters, {aptStatus: element.value,
            practice: state.filters.practice,status:state.filters.status,region:state.filters.region,brands:state.filters.brands});

        if (element.value) {
           const practicesInfo = Object.keys(infoPatientValidationData);
           let objDataNew: any = {};

            practicesInfo.forEach((practiceName: any) => {
                const data = infoPatientValidationData[practiceName];
                const result = data.filter((word:any) => word['APT_Status'] && word['APT_Status'].toLowerCase() === value.toLowerCase());
                if(result && result.length > 0){
                    Object.assign(objDataNew, {[practiceName]: result});
                }

            });
            setState({...state, report: objDataNew, filters});
        }

        if (!element.value) {

            const filters = Object.assign(state.filters, {practice: state.filters.practice,status:state.filters.status,region:state.filters.region,brands:state.filters.brands});

            setState({...state, report: props.report.patientValidationData, filters});
            return;
        }
    }

    const onStatusSelect = (element: { [key: string]: any }) => {
        const options: any = enabledcategoryList;
        const carrierList: any = [];
        let filters = Object.assign(state.filters, {status: element.value, practice: state.filters.practice,
            aptStatus:state.filters.aptStatus,region:state.filters.region,brands:state.filters.brands});
        if (!element.value) {
             Object.assign(state.filters, {practice: '',aptStatus:''});
            setState({...state, report: props.report.patientValidationData, filters});
            setCarrierList(options);
            return;
        }
        carrierList.push(element.value);
        setCarrierList(carrierList);

    };

    const onBrandsSelect = (element: { [key: string]: any }) => {
        const filters = state.filters;
        filters.brands = element.value;
        let selectedPractices:string[] = [];
        const tempBrandFilterPractices:any=[];
        if (element.value.length == 0) {
            filters.brands = [];
            setState({...state, report: props.report.patientValidationData, filters});
            return;
        }
        filters.brands.forEach((brand:any)=>{
            const res= props.practice.udaPracticeInfo.filter(
                (item:UdaPracticeInfo)=>{
                    if(item.brand===brand){
                        return true
                    }
                }
            )
            tempBrandFilterPractices.push(
                ...res.map(prac=>{ return prac.practiceName})
            )
        })
        selectedPractices=Array.from(new Set([...selectedPractices,...tempBrandFilterPractices])).sort();
        const report = {};
        const patientValidationData: patientValidationReport = props.report.patientValidationData;
        selectedPractices.forEach((practiceName: string) => {
            Object.assign(report, {
                [practiceName]:patientValidationData[practiceName]
            });
        });
        setState({...state, report, filters});
    };

    const onRegionSelect = (element: { [key: string]: any }) => {
        const values: any = [];
        let practices: any;
        let filters = state.filters;
        if (element.value.length == 0) {
            practices = [];
            Object.assign(checkinfo,{info:practices});
            setDefaultPracticeData({practices: practices});
            filters = Object.assign(state.filters, {practice:practices, region:[]});
            setState({...state, report: props.report.patientValidationData, filters});
            return;
        }
        element.value.forEach((item: any) => {
            const info: any = props.practice.regionPractices[item];
            info && info.forEach((inf: any) => {
                values.push(inf);
            });
        });
        const patientValidationData: patientValidationReport = props.report.patientValidationData;
        const report = {};
        const practiceOptions:any =[];
        values.forEach((practiceName: string) => {
            if (props.practice.practiceOptions.filter(a => a.lowerName == practiceName).length > 0) {
                practiceOptions.push(props.practice.practiceOptions.filter(a => a.lowerName == practiceName)[0].text);
                Object.assign(report, {
                    [props.practice.practiceOptions.filter(a => a.lowerName == practiceName)[0].text]:
                        patientValidationData[props.practice.practiceOptions.filter(a => a.lowerName == practiceName)[0].text]
                });
            }
        });
        Object.assign(checkinfo,{info:practiceOptions});
        setDefaultPracticeData({practices: practiceOptions});
        filters = Object.assign(state.filters, {practice:practiceOptions, region:element.value});
        setState({...state, report, filters});
    };


    const handlePrevMonth = () => {
        setLoading(true);
        const info = moment(startDate).subtract(1, 'days').format('YYYY-MM-DD');

        const practiceInfo: any = state.filters.practice ? state.filters.practice : ['all'];
        fetchRecords(info, practiceInfo)

    };


    const handleNextMonth = () => {
        setLoading(true);
        const info: any = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
        const practiceInfo: any = state.filters.practice ? state.filters.practice : ['all'];
        fetchRecords(info, practiceInfo)
    };

    const getTableRows = (direction:any,column:any,color:any,defaultPractice:any) => {

        const patientStatusReport: patientValidationReport = state.report ? state.report : props.report.patientValidationData;
        if (patientStatusReport && Object.keys(patientStatusReport).length > 0) {
            const practices = Object.keys(patientStatusReport);
            const practiceInfo:any = state.filters.practice;
            let reportRows: React.ReactElement[] = [];
            let objectInfoData:any  = {};
            let defaultPractices:any = [];

            if(practiceInfo && practiceInfo.length > 0){
                practiceInfo.forEach((practiceName: any, index: any) => {
                    const infoFilter = practices.filter((data:any) => data === practiceName);
                    if(infoFilter.length > 0){
                        defaultPractices.push(practiceName);
                    }
                });
            }
            const checkDefaultPractice:any = defaultPracticeData && defaultPracticeData.practices.length > 0 ? defaultPracticeData.practices : defaultPractice ;
            const finalPractices = checkDefaultPractice && checkDefaultPractice.length > 0 ?  checkDefaultPractice[0] ==='all'? practices:
                checkDefaultPractice : practices;

           finalPractices.forEach((practiceName: any, index: any) => {
                const data: any = patientStatusReport[practiceName];
                const objData: any = {[practiceName]: statusList};
                Object.assign(objectInfoData, {[practiceName]: []})
                if (state.filters.status) {
                    Object.assign(objData, {[practiceName]: statusList[state.filters.status]})
                }
                if (data && data.length > 0) {
                    let [greyE, greenE, redE,
                        greyV, greenV, redV,
                        greyH, greenH, redH]: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, ];

                    data.forEach((item: any, key: any) => {

                        switch (item.Details.statusE) {
                            case 0: {
                                redE++;
                                break;
                            }
                            case 1: {
                                greenE++;
                                break;
                            }
                            case 2: {
                                greyE++;
                                break;
                            }
                        }

                        switch (item.Details.statusV) {
                            case 1: {
                                greyV++;
                                break;
                            }
                            case 2: {
                                greenV++;
                                break;
                            }
                            case 3: {
                                redV++;
                                break;
                            }
                        }

                        switch (item.Details.statusH) {
                            case 0: {
                                redH++;
                                break;
                            }
                            case 1: {
                                greenH++;
                                break;
                            }
                            case 2: {
                                greyH++;
                                break;
                            }
                        }
                    });

                    switch (state.filters.status) {
                        case 'Error': {
                            Object.assign(objData[practiceName], {
                                "Error": {grey: greyE, green: greenE, red: redE},
                            });
                            break;
                        }
                        case 'Verified': {
                            Object.assign(objData[practiceName], {
                                "Verified": {grey: greyV, green: greenV, red: redV},
                            });
                            break;
                        }
                        case 'Main Dental Home': {
                            Object.assign(objData[practiceName], {
                                "Main Dental Home": {grey: greyH, green: greenH, red: redH},
                            });
                            break;
                        }
                        default: {
                            Object.assign(objData[practiceName], {
                                "Error": {grey: greyE, green: greenE, red: redE},
                                "Verified": {grey: greyV, green: greenV, red: redV},
                                "Main Dental Home": {grey: greyH, green: greenH, red: redH},
                            });
                        }
                    }

                    Object.keys(objData).forEach((key1, dataRecords) => {
                        const data = Object.values(objData);
                        const records: any = data[dataRecords];
                        Object.keys(records).forEach((key2) => {
                            enabledcategoryList.forEach((dataValue, key3) => {
                                if (key2 === dataValue) {
                                    objectInfoData[practiceName].push(records[dataValue])
                                }
                            });
                        });
                    });

                }
            });

            const practicesInfo = Object.keys(objectInfoData);

            let checkInfoASC: any = [];
            let checkInfoDESC: any = [];
            let objDataNew: any = [];
            let objDataSort: any = {};

            if (direction !== "sort" && column) {
                practicesInfo.forEach((practiceName: any) => {
                    const data = objectInfoData[practiceName];
                    if (data.length > 0 && data) {

                        const finalSortCond = column === enabledcategoryList[0] ? data.length === 1 ? data[0][color] : data[0][color] : column === enabledcategoryList[1] ?
                            data.length === 1 ? data[0][color] : data[1][color] : column === enabledcategoryList[2] ? data.length === 1 ? data[0][color] :
                                data[2][color] : data.length === 1 ? data[0][color] : data[3][color];
                        switch (direction) {
                            case 'sortByAsc': {
                                finalSortCond && checkInfoASC.push({
                                    practice: practiceName,
                                    value: column === enabledcategoryList[0] ? finalSortCond : column === enabledcategoryList[1]
                                        ? finalSortCond : column === enabledcategoryList[2] ? finalSortCond : finalSortCond
                                });
                                break;
                            }
                            case 'sortByDesc': {
                                finalSortCond && checkInfoDESC.push({
                                    practice: practiceName,
                                    value: column === enabledcategoryList[0] ? finalSortCond : column === enabledcategoryList[1] ?
                                        finalSortCond : column === enabledcategoryList[2] ? finalSortCond : finalSortCond
                                });
                                break;
                            }
                        }
                    }
                });

                switch (direction) {
                    case 'sortByAsc': {
                        checkInfoASC.sort(function (a: any, b: any) {
                            return b.value - a.value
                        });
                        checkInfoASC.forEach((info: any, key: any) => {
                            const dataASC: any = objectInfoData[info.practice];
                            objDataNew.push({[info.practice]: dataASC})
                            Object.assign(objDataSort, {[info.practice]: dataASC});
                        });
                        break;
                    }
                    case 'sortByDesc': {
                        checkInfoDESC.sort(function (a: any, b: any) {
                            return a.value - b.value
                        });
                        checkInfoDESC.forEach((info: any, key: any) => {
                            const dataDESC: any = objectInfoData[info.practice];
                            objDataNew.push({[info.practice]: dataDESC})
                            Object.assign(objDataSort, {[info.practice]: dataDESC});
                        });
                        break;
                    }
                }
            }


            const practicesInfoFinal = Object.keys(objDataSort);
            const practiceResponse = direction !== 'sort' ? practicesInfoFinal && practicesInfoFinal.length > 0 ?
                practicesInfoFinal : practicesInfo : practicesInfo;

            let [greyETotal, greenETotal, redETotal,
                greyVTotal, greenVTotal, redVTotal,
                greyHTotal, greenHTotal, redHTotal]: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, ];

            practiceResponse.forEach((practiceName: any, index: any) => {
                let returnArrayData: React.ReactElement[] = [];
                const data = direction !== "sort" && Object.keys(objDataSort).length > 0 ? objDataSort[practiceName] :
                    objectInfoData[practiceName];
                let checkData: any = false;



                data.forEach((dataValue: any, key3: any) => {

                    checkData = data[key3] && true;

                    switch (direction) {
                        case 'sortByAsc': {
                            checkInfoASC.sort(function (a: any, b: any) {
                                return b.value - a.value
                            });
                            checkInfoASC.forEach((info: any, key: any) => {
                                const dataASC: any = objectInfoData[info.practice];
                                objDataNew.push({[info.practice]: dataASC})
                                Object.assign(objDataSort, {[info.practice]: dataASC});
                            });
                            break;
                        }
                        case 'sortByDesc': {
                            checkInfoDESC.sort(function (a: any, b: any) {
                                return a.value - b.value
                            });
                            checkInfoDESC.forEach((info: any, key: any) => {
                                const dataDESC: any = objectInfoData[info.practice];
                                objDataNew.push({[info.practice]: dataDESC})
                                Object.assign(objDataSort, {[info.practice]: dataDESC});
                            });
                            break;
                        }
                    }
                    switch (key3) {
                        case 0: {
                            greyETotal = greyETotal + dataValue.grey;
                            greenETotal = greenETotal + dataValue.green;
                            redETotal = redETotal + dataValue.red;
                            break;
                        }
                        case 1: {
                            greyVTotal = greyVTotal + dataValue.grey;
                            greenVTotal = greenVTotal + dataValue.green;
                            redVTotal = redVTotal + dataValue.red;
                            break;
                        }
                        case 2: {
                            greyHTotal = greyHTotal + dataValue.grey;
                            greenHTotal = greenHTotal + dataValue.green;
                            redHTotal = redHTotal + dataValue.red;
                            break;
                        }
                    }

                    returnArrayData.push(<td style={{textAlign: "center"}}
                                             key={index + key3 + 'grey'}>{dataValue.grey}</td>);
                    returnArrayData.push(<td style={{textAlign: "center"}}
                                             key={index + key3 + 'green'}>{dataValue.green}</td>);
                    returnArrayData.push(<td style={{textAlign: "center"}}
                                             key={index + key3 + 'red'}>{dataValue.red}</td>);
                });


                reportRows.push(<tr key={practiceName}>
                    <td>{practiceName}</td>
                    {checkData && data && data.length > 0 ? returnArrayData :
                        <td colSpan={17}>No records Found</td>}
                </tr>)
            });
            reportRows.push(<tr>
                <td>Total</td>
                <td style={{textAlign: "center"}}>{greyETotal}</td>
                <td style={{textAlign: "center"}}>{greenETotal}</td>
                <td style={{textAlign: "center"}}>{redETotal}</td>
                <td style={{textAlign: "center"}}>{greyVTotal}</td>
                <td style={{textAlign: "center"}}>{greenVTotal}</td>
                <td style={{textAlign: "center"}}>{redVTotal}</td>
                <td style={{textAlign: "center"}}>{greyHTotal}</td>
                <td style={{textAlign: "center"}}>{greenHTotal}</td>
                <td style={{textAlign: "center"}}>{redHTotal}</td>
            </tr>);

            return <tbody>{reportRows}</tbody>;
        }
    };


    const infoDataExcel: any = state.report ? state.report : props.report.patientValidationData;
    const csvHeaderInfo: any = carrierList.length > 0 ? carrierList : enabledcategoryList;
    const insuranceInfo: any = state.filters.status;

    const sendPVSEmail = (startDate: any, csvHeaderInfo: any, infoDataExcel: any, carrierInfo: any, insuranceInfo: any, excel: any,defaultPractice:any) => {

        const pdfDataReturn: any = exportPDFPVAReport(startDate, csvHeaderInfo, infoDataExcel, carrierInfo, insuranceInfo, defaultPractice);
        const csvDataReturn: any = exportPatientValidationActionReport(startDate, csvHeaderInfo, infoDataExcel, carrierInfo, insuranceInfo, !excel,defaultPractice);
        reportAction.sendMailPVA({data: csvDataReturn, pdf: pdfDataReturn});
        setExcel(true);
    };

    const defaultPractice = defaultPracticeData.practices;


    return (
        <Template activeLink='patient-validation-action-report'>

            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">Patient Validation Action Reports <HelpMark pageId='6'/></h2>
                                <div className="topFilters float-right">
                                    <a className="link mr30"
                                       onClick={() => sendPVSEmail(startDate, csvHeaderInfo, infoDataExcel, carrierInfo, insuranceInfo, excel,defaultPractice)}><Icon
                                        name="send"/> Send</a>
                                    <a className="link"
                                       onClick={() => exportPatientValidationActionReport(startDate, csvHeaderInfo, infoDataExcel, carrierInfo, insuranceInfo, excel,defaultPractice)}><Icon
                                        name="file alternate"/>Export</a>

                                    <Dropdown
                                        search={true}
                                        className='mr10 mb15'
                                        name="aptStatus"
                                        placeholder="APT Status"
                                        selection={true}
                                        options={state.filters.aptStatusCategoryOptions}
                                        onChange={onChangeFilter}
                                        value={state.filters.aptStatus}
                                    />

                                    <Dropdown
                                        search className='mr10 mb15'
                                        name="practice"
                                        placeholder="Practice"
                                        selection={true}
                                        multiple={true}
                                        options={state.filters.practiceOptions}
                                        onChange={onChangeFilter}
                                        value={defaultPractice.length > 0 ? defaultPracticeData.practices:state.filters.practice}
                                    />

                                    <Dropdown
                                        search={true}
                                        className='mr10 mb15'
                                        name="status"
                                        placeholder="status"
                                        selection
                                        options={state.filters.statusCategoryOptions}
                                        onChange={onChangeFilter}
                                        value={state.filters.status}
                                    />
                                    <Dropdown
                                        search={true}
                                        className='mr10 mb15'
                                        name="region"
                                        placeholder="Region"
                                        selection={true}
                                        multiple={true}
                                        options={props.practice.regionOptions}
                                        onChange={onChangeFilter}
                                        value={state.filters.region}
                                    />
                                    <Dropdown
                                        search={true}
                                        className='mr10 mb15'
                                        name="brands"
                                        placeholder="Brands"
                                        selection={true}
                                        multiple={true}
                                        options={brandsOptions()}
                                        onChange={onChangeFilter}
                                        value={state.filters.brands}
                                    />

                                </div>
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    <Button.Group className="btnNextPre mr20 float-left">
                        <Button icon='left chevron' onClick={handlePrevMonth}/>
                        <Button icon='right chevron' onClick={handleNextMonth}/>
                    </Button.Group>
                    <h3 className="float-left mt5 ml10">Date:{moment(startDate).format('YYYY-MM-DD (dddd)')}</h3>
                    <div className="clearfix"/>
                    <div className="table-responsive FreezeTable">
                        <table className="ui celled single line table unstackable tableStyle PVS">
                            {getTableHeadings()}
                            {getTableRows(state.direction, state.column, state.color, props.patients.defaultPractice)}
                        </table>
                    </div>
                </div>
            </div>
        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        patients: state.patients,
        app: state.app,
    };
}


export default connect(mapStateToProps)(PatientValidationActionReport);

