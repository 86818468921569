import React, {SyntheticEvent, useEffect, useState} from "react";
import Template from "../../components/Template";
import {useActions} from "../../redux/actions";
import * as reportActions from "../../redux/actions/report";
import {App, RootState} from "../../model";
import {connect} from "react-redux";
import * as practiceActions from "../../redux/actions/practice";
import * as patientValidationActions from "../../redux/actions/patients";
import {DropDownOptions, Practice, Regions, UdaPracticeInfo} from "../../model/practice";
import {patientValidationReport,} from '../../model/report'
import {
    exportPatientValidationStatusReport,
    exportPDFPVSReport,
} from "../../components/ExportCsv";

import {Dropdown, Button, Icon, Grid} from "semantic-ui-react";
import moment from 'moment';
import {
    enabledCarrierList,
    insuranceList,
    finalInsurance, aptStatusOptions, brands, reportInitialState, reportPageNames
} from '../../constants'
import {Report} from "../../model/report";
import {Patients} from "../../model/patients";
import _ from "lodash";
import * as userSetupActions from "../../redux/actions/userSettings";
import {SetupFields} from "../../enums/userSetupField";
import {SetupAppName} from "../../enums/userSetupAppName";
import HelpMark from "../../components/HelpMark";

interface Props {
    report: Report,
    practice: Practice,
    app: App,
    patients: Patients,
}

function PatientValidationStatusReport(props: Props) {
    const insuranceCategoryOptions: Array<object> = [{key: 'none', value: null, text: 'Select Insurance'}];
    const initialState = {
        filters: {
            practice: [],
            insurance: '',
            practiceOptions: [],
            insuranceCategoryOptions: insuranceCategoryOptions,
            aptStatus: '',
            region: [],
            brands: []
        },
        report: {},
        column: "all",
        direction: "sort",
        color: '',
    };
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(true);
    const [carrierInfo, setCarrier] = useState([]);
    const [carrierList, setCarrierList] = useState([]);
    const [excel, setExcel] = useState(true);
    enabledCarrierList && enabledCarrierList.forEach((item: any, index: number) => {
        insuranceCategoryOptions.push(
            {text: item, value: item, key: index}
        )
    });
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const patientValidationAction = useActions(patientValidationActions);
    const practiceAction = useActions(practiceActions);
    const reportAction = useActions(reportActions);
    const userSetup = useActions(userSetupActions);

    const refinePracticeArray = (response: []) => {
        let practiceAbbr: { [key: string]: string } = {};
        let practiceData: { [key: string]: object } = {};
        let practiceDataInfo: { [key: string]: object } = {};
        let practiceOptions: Array<{ key: string, value: null | string, text: string }> =
            [{key: 'none', value: 'all', text: 'Select Practice'}];
        if (response) {
            response.forEach((item: { id: number, practice: string, practiceabbr: string, practiceDBName: string }) => {
                practiceAbbr[item.practice.toLowerCase()] = item.practiceabbr;
                practiceOptions.push({text: item.practice, value: item.practice, key: item.id.toString()});
                practiceData[item.practice.toLowerCase()] = {
                    practice: item.practice,
                    practiceDBName: item.practiceDBName
                }
                practiceDataInfo[item.practiceDBName.toLowerCase()] = {
                    practice: item.practice,
                }
            })
        }
        const practice = {practiceAbbr: practiceAbbr, practiceOptions: practiceOptions, practiceData};
        let filters = Object.assign(state.filters, {practiceOptions: practiceOptions});
        const info = {date: startDate, practice: 'all'};
        let dbPractices: any = [];
        patientValidationAction.getCarrierIdentity().then((data: any) => {
            setCarrier(data);
            reportAction.getPatientValidationReport(info).then((report: patientValidationReport) => {
                let userParams = {field: SetupFields.Practice, appId: SetupAppName.CDP_MY};
                userSetup.getSetup(userParams).then((res: any) => {
                    let reportData = {};
                    let practices: any = []
                    if (!props.patients.defaultPractice) {
                        dbPractices = res.value.split(',');
                    } else {
                        dbPractices = props.patients.defaultPractice.value.split(',');
                    }
                    if (dbPractices.length > 0 && !dbPractices.includes('all')) {
                        dbPractices.forEach((dbPractice: any) => {
                            if (practiceData[dbPractice]) {
                                let practiceValue: any = practiceData[dbPractice];
                                practices.push(practiceValue.practice);
                            }
                        });
                    } else {
                        practices = ['all']
                    }
                    filters.practice = practices;
                    if (practices.includes('all') || practices.length < 1) {
                        reportData = report;
                    } else {
                        practices.forEach((practiceName: string) => {
                            Object.assign(reportData, {
                                [practiceName]: report[practiceName]
                            });
                        })
                    }
                    setState({...state, report: reportData, filters});
                });
            })
        });
        return practice
    };

    useEffect(() => {
        const promiseArr = new Array();
        practiceAction.fetchPracticeUda();
        promiseArr.push(practiceAction.fetchRegions(refineRegionsArray));
        practiceAction.fetchPractices(refinePracticeArray);
    }, []);


    const refineRegionsArray = (regionList: Regions[]) => {
        let regionListOptions: DropDownOptions[] = [{key: '0', value: 0, text: 'Select Region'}];
        if (regionList.length) {
            regionList.forEach(item => {
                regionListOptions.push({text: item.name, value: item.id, key: item.id})
            });
        }
        return regionListOptions;
    }

    const handleSortClick = (insuranceInfo: any, clickedColor: any, directionInfo: any) => {
        const direction = state.direction === "sort" ? "sortByAsc" : directionInfo === "sortByAsc" ? "sortByDesc" : "sortByAsc";
        setState({...state, direction, column: insuranceInfo, color: clickedColor});
    }

    const getTableHeadings = () => {
        let returnArray: React.ReactElement[] = [];
        let returnArrayList: React.ReactElement[] = [];
        let returnArrayListIns: React.ReactElement[] = [];
        let carrierListInfo: any = carrierList.length > 0 ? carrierList : enabledCarrierList;
        const dir: any = state.direction;
        const columnName: any = state.column;
        {
            carrierListInfo.length > 4 && carrierListInfo.shift()
        }
        carrierListInfo.forEach((item: any, key: any) => {
            {
                carrierListInfo.length === 1 ?
                    returnArray.push(<th key={key + item} colSpan={4} style={{textAlign: "center"}}>{item}
                        {dir === "sortByAsc" ? <Icon color="yellow" name={'sort amount up'}/> : ''}
                        {dir === "sortByDesc" ? <Icon color="red" name={'sort amount down'}/> : ''}</th>) :
                    returnArray.push(<th key={key + item} colSpan={4} style={{textAlign: "center"}}>
                        {item}{item === "MCNA" && columnName === "MCNA" && dir === "sortByAsc" ?
                        <Icon color="yellow"
                              name={'sort amount up'}/> : item === "DentaQuest" && columnName === "DentaQuest" && dir === "sortByAsc" ?
                            <Icon color="yellow"
                                  name={'sort amount up'}/> : item === "TMHP" && columnName === "TMHP" && dir === "sortByAsc" ?
                                <Icon color="yellow"
                                      name={'sort amount up'}/> : item === "UHC Medicaid" && columnName === "UHC Medicaid" && dir === "sortByAsc" ?
                                    <Icon color="yellow" name={'sort amount up'}/> : ''}
                        {item === "MCNA" && columnName === "MCNA" && dir === "sortByDesc" ?
                            <Icon color="red"
                                  name={'sort amount down'}/> : item === "DentaQuest" && columnName === "DentaQuest" && dir === "sortByDesc" ?
                                <Icon
                                    name={'sort amount down'}/> : item === "TMHP" && columnName === "TMHP" && dir === "sortByDesc" ?
                                    <Icon color="red"
                                          name={'sort amount down'}/> : item === "UHC Medicaid" && columnName === "UHC Medicaid" && dir === "sortByDesc" ?
                                        <Icon color="red" name={'sort amount down'}/> : ''}
                    </th>)
            }

        });

        {
            carrierListInfo.length === 2 && carrierListInfo.shift()
        }

        {
            carrierListInfo.length === 1 ? finalInsurance.forEach((item: any, key: any) => {
                    returnArrayListIns.push(<th key={key + 1} style={{textAlign: "center"}}>
                        {key === 0 ?
                            <p><Icon name='check circle' color='green'/><Icon name={'sort'} className="valSort"
                                                                              onClick={() => handleSortClick(item.ins, item.color[key], dir)}/>
                            </p> : key === 1 ? <p><Icon name='font' color='red'/><Icon name={'sort'} className="valSort"
                                                                                       onClick={() => handleSortClick(item.ins, item.color[key], dir)}/>
                            </p> : key === 2 ? <p><Icon name='delete' color='red'/><Icon name={'sort'} className="valSort"
                                                                                         onClick={() => handleSortClick(item.ins, item.color[key], dir)}/>
                            </p> : <p><Icon name='question circle' color='yellow'/><Icon name={'sort'} className="valSort"
                                                                                         onClick={() => handleSortClick(item.ins, item.color[key], dir)}/>
                            </p>}</th>)
                }) :

                finalInsurance.forEach((item: any, key: number) => {
                    returnArrayList.push(
                        <th key={key + 1 + item.ins} style={{textAlign: "center"}}>
                            <Icon className="ins" name='check circle' color='green'/>
                            <Icon
                                className="valSort"
                                name={'sort'}
                                onClick={() => handleSortClick(item.ins, key == 0 ? item.color[key] :
                                    key === 1 ? item.color[key - 1] : key === 2 ? item.color[key - 2] : item.color[key - 3], dir)}
                            />
                        </th>);
                    returnArrayList.push(<th key={key + 2 + item.ins} style={{textAlign: "center"}}>
                        <Icon className="ins" name='font' color='red'/>
                        <Icon
                            className="valSort"
                            name={'sort'}
                            onClick={() => handleSortClick(item.ins, key == 0 ?
                                item.color[key + 1] : key === 1 ? item.color[key] : key === 2 ? item.color[key - 1] : item.color[key - 2], dir)}
                        />
                    </th>);
                    returnArrayList.push(<th key={key + 3 + item.ins} style={{textAlign: "center"}}>
                        <Icon className="ins" name='delete' color='red'/>
                        <Icon
                            className="valSort"
                            name={'sort'}
                            onClick={() => handleSortClick(item.ins, key == 0 ?
                                item.color[key + 2] : key === 1 ? item.color[key + 1] : key === 2 ? item.color[key] : item.color[key - 1], dir)}
                        />
                    </th>);
                    returnArrayList.push(<th key={key + 4 + item.ins} style={{textAlign: "center"}}>
                        <Icon className="ins" name='question circle' color='yellow'/>
                        <Icon
                            className="valSort"
                            name={'sort'}
                            onClick={() => handleSortClick(item.ins, key == 0 ?
                                item.color[key + 3] : key === 1 ? item.color[key + 2] : key === 2 ? item.color[key + 1] : item.color[key], dir)}
                        />
                    </th>);
                })
        }


        return (<thead>
        <tr key="firstHead">
            <th key={7}>&nbsp;</th>
            {returnArray}</tr>
        <tr key="secondHead">
            <th key={7}>Practice</th>
            {carrierListInfo.length === 1 ? returnArrayListIns : returnArrayList}
        </tr>
        </thead>)

    };

    const brandsOptions = () => {
        const brandOptions: string[] = []
        const brandsObject: { [key: string]: string[] } = {}
        brands.forEach((brand) => {
            const res = props.practice.udaPracticeInfo.filter(
                (item: UdaPracticeInfo) => {
                    if (item.brand === brand.value) {
                        return true
                    }
                }
            ).map(item => item.practiceName)
            brandsObject[brand.value] = [...res.map(i => i.toLocaleLowerCase())]
        })
        if (state.filters.region) {
            const tempSelectedPracsPerRegion: { [key: string]: string[] } = {}
            state.filters.region.forEach((item) => {
                if (props.practice.regionPractices[item]) {
                    tempSelectedPracsPerRegion[item] =
                        props.practice.regionPractices[item] as []

                }
            })
            Object.keys(tempSelectedPracsPerRegion).forEach(region => {
                Object.keys(brandsObject).forEach(brand => {
                    const commonPracs = _.intersection(brandsObject[brand], tempSelectedPracsPerRegion[region])
                    if (commonPracs.length > 0) {
                        brandOptions.push(brand)
                    }
                })
            })
            return (brandOptions.length > 0) ? brandOptions.map(b => {
                    return {
                        value: b,
                        key: b,
                        text: b
                    }
                }) :
                brands
        }

    }

    const onChangeFilter = (e: SyntheticEvent, element: { [key: string]: any }) => {
        switch (element.name) {
            case 'insurance': {
                onInsuranceSelect(element);
                break;
            }
            case 'practice': {
                onPracticeSelect(element);
                break;
            }
            case 'aptStatus': {
                onAptStatusSelect(element);
                break;
            }
            case 'region': {
                onRegionSelect(element);
                break;
            }
            case 'brands': {
                onBrandsSelect(element);
                break;
            }
            default:

        }
    };

    const fetchRecords = (info: any, practiceInfo: any,) => {
        setStartDate(info);
        const data = {date: info, practice: practiceInfo};
        reportAction.getPatientValidationReport(data).then((report: any) => {
            setState({...state, direction: "sort", column: "all", color: '', report});
        });
    }

    const onPracticeSelect = (element: { [key: string]: any }) => {
        const patientValidationData: patientValidationReport = props.report.patientValidationData;
        const values = element.value;
        let practices = values
        if (values.length >= 0 && values.indexOf('all') === values.length - 1) {
            practices = ['all'];
        } else if (values.length > 1 && values.indexOf('all') === 0) {
            values.splice(0, 1);
            practices = values;
        }
        const filters = state.filters;
        filters.practice = practices

        const report = {};
        if (practices.indexOf('all') === 0) {
            setState({...state, report: props.report.patientValidationData, filters});
            return;
        }
        practices.forEach((practiceName: string) => {
            Object.assign(report, {
                [practiceName]: patientValidationData[practiceName]
            });
        })
        setState({...state, report, filters})
    };

    const onAptStatusSelect = (element: { [key: string]: any }) => {
        const patientValidationData: patientValidationReport = props.report.patientValidationData;
        const filters = state.filters;
        const practices: Array<string> = filters.practice;
        let practiceList: Array<string> = Object.keys(patientValidationData);
        if (!practices.includes('all')) {
            practices.forEach((practice) => {
                practiceList.push(props.practice.practiceOptions.filter(a => a.value == practice)[0].text)
            });
        }
        filters.aptStatus = element.value;
        const value = element.value;
        let report = {};
        practiceList.forEach((name: string) => {
            let dataArray: any = [];
            const data = patientValidationData[name];
            data.forEach((item: any) => {
                if (value === 'all') {
                    dataArray.push(item);
                } else if (item.APT_Status.toLowerCase().trim() === value.trim()) {
                    dataArray.push(item);
                }
            })
            Object.assign(report, {
                [name]: dataArray
            });
        })
        setState({...state, report, filters})
    }

    const onInsuranceSelect = (element: { [key: string]: any }) => {
        const options: any = enabledCarrierList;
        const carrierList: any = [];
        let filters = Object.assign(state.filters, {insurance: element.value});
        if (!element.value) {
            filters = Object.assign(state.filters, {practice: ''});
            setState({...state, report: props.report.patientValidationData, filters});
            setCarrierList(options);
            return;
        }

        carrierList.push(element.value);
        setCarrierList(carrierList);
    };

    const onRegionSelect = (element: { [key: string]: any }) => {
        const values: any = [];
        let practices: any;
        const filters = state.filters;
        if (element.value.length == 0) {
            filters.practice = [];
            filters.region = [];
            setState({...state, report: props.report.patientValidationData, filters});
            return;
        }
        element.value.forEach((item: any) => {
            const info: any = props.practice.regionPractices[item];
            info && info.forEach((inf: any) => {
                values.push(inf)
            });
        });
        practices = values;
        const patientValidationData: patientValidationReport = props.report.patientValidationData;
        filters.practice = practices;
        filters.region = element.value;
        const report = {};
        practices.forEach((practiceName: string) => {
            if (props.practice.practiceOptions.filter(a => a.value == practiceName).length > 0)
                Object.assign(report, {
                    [props.practice.practiceOptions.filter(a => a.value == practiceName)[0].text]:
                        patientValidationData[props.practice.practiceOptions.filter(a => a.value == practiceName)[0].text]
                });
        });
        setState({...state, report, filters});
    }

    const onBrandsSelect = (element: { [key: string]: any }) => {
        const filters = state.filters;
        filters.brands = element.value;
        let selectedPractices: string[] = [];
        const tempBrandFilterPractices: any = [];
        if (element.value.length == 0) {
            filters.brands = [];
            setState({...state, report: props.report.patientValidationData, filters});
            return;
        }
        filters.brands.forEach((brand: any) => {
            const res = props.practice.udaPracticeInfo.filter(
                (item: UdaPracticeInfo) => {
                    if (item.brand === brand) {
                        return true
                    }
                }
            )
            tempBrandFilterPractices.push(
                ...res.map(prac => {
                    return prac.practiceName
                })
            )
        })
        selectedPractices = Array.from(new Set([...selectedPractices, ...tempBrandFilterPractices])).sort();
        const report = {};
        const patientValidationData: patientValidationReport = props.report.patientValidationData;
        selectedPractices.forEach((practiceName: string) => {
            Object.assign(report, {
                [practiceName]: patientValidationData[practiceName]
            });
        });
        setState({...state, report, filters});
    }

    const handlePrevMonth = () => {
        setLoading(true);
        const info = moment(startDate).subtract(1, 'days').format('YYYY-MM-DD');

        const practiceInfo: any = state.filters.practice ? state.filters.practice : 'all';
        fetchRecords(info, practiceInfo)

    };

    const handleNextMonth = () => {
        setLoading(true);
        const info: any = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
        const practiceInfo: any = state.filters.practice ? state.filters.practice : 'all';
        fetchRecords(info, practiceInfo)
    };

    const getTableRows = (direction: any, column: any, color: any) => {
        const patientStatusReport: patientValidationReport = state.report ? state.report : props.report.patientValidationData;
        let totalMCNAGreen = 0, totalDentaGreen = 0, totalTMHPGreen = 0, totalUHCGreen = 0, totalMCNARedA = 0,
            totalDentaRedA = 0,
            totalTMHPRedA = 0, totalUHCRedA = 0, totalMCNARedX = 0, totalDentaRedX = 0, totalTMHPRedX = 0,
            totalUHCRedX = 0, totalMCNANoImage = 0,
            totalDentaNoImage = 0, totalTMHPNoImage = 0, totalUHCNoImage = 0;
        if (patientStatusReport && Object.keys(patientStatusReport).length > 0) {
            const practices = Object.keys(patientStatusReport);
            const carrierList: any = carrierInfo;
            let reportRows: React.ReactElement[] = [];
            let objectInfoData: any = {};
            let totalArray: any = [];
            practices.forEach((practiceName: any, index: any) => {
                const data: any = patientStatusReport[practiceName];
                const objData: any = {[practiceName]: insuranceList};
                Object.assign(objectInfoData, {[practiceName]: []})
                if (state.filters.insurance) {
                    Object.assign(objData, {[practiceName]: insuranceList[state.filters.insurance]})
                }
                let statusItem: any = false;

                if (data && data.length > 0) {
                    carrierList.forEach((item: any, key: any) => {
                        const filterCarrierCheck = data.filter((word: any) => word.CarrierName === item.modifyCarrierName || word.CarrierName === item.carrierName);

                        if (filterCarrierCheck && filterCarrierCheck.length > 0) {
                            statusItem = true;
                        }
                    });

                    let [greenM, redXM, redAM, noImageM,
                        greenD, redXD, redAD, noImageD,
                        greenT, redXT, redAT, noImageT,
                        greenU, redXU, redAU, noImageU]: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

                    data.forEach((item: any, key: any) => {
                        const isCarrierExist = carrierList.filter((carrier: any) => carrier.carrierName === item.CarrierName || carrier.modifyCarrierName === item.CarrierName);
                        const objectInfo = isCarrierExist && isCarrierExist.length > 0;
                        if (objectInfo) {
                            let name: any = isCarrierExist[0].modifyCarrierName;
                            switch (item.status) {
                                case 'PT Assigned To Practice Active Insurance': {
                                    switch (name) {
                                        case 'MCNA': {
                                            greenM++;
                                            break;
                                        }
                                        case 'DentaQuest': {
                                            greenD++;
                                            break;
                                        }
                                        case 'TMHP': {
                                            greenT++;
                                            break;
                                        }
                                        case 'UHC Medicaid': {
                                            greenU++;
                                            break;
                                        }

                                    }
                                    break;
                                }
                                case 'PT Does NOT Have Active Insurance': {
                                    switch (name) {
                                        case 'MCNA': {
                                            redXM++;
                                            break;
                                        }
                                        case 'DentaQuest': {
                                            redXD++;
                                            break;
                                        }
                                        case 'TMHP': {
                                            redXT++;
                                            break;
                                        }
                                        case 'UHC Medicaid': {
                                            redXU++;
                                            break;
                                        }

                                    }
                                    break;
                                }
                                case 'PT Has Active Insurance But Not Assigned To Practice': {
                                    switch (name) {
                                        case 'MCNA': {
                                            redAM++;
                                            break;
                                        }
                                        case 'DentaQuest': {
                                            redAD++;
                                            break;
                                        }
                                        case 'TMHP': {
                                            redAT++;
                                            break;
                                        }
                                        case 'UHC Medicaid': {
                                            redAU++;
                                            break;
                                        }

                                    }
                                    break;
                                }
                                default : {
                                    switch (name) {
                                        case 'MCNA': {
                                            noImageM++;
                                            break;
                                        }
                                        case 'DentaQuest': {
                                            noImageD++;
                                            break;
                                        }
                                        case 'TMHP': {
                                            noImageT++;
                                            break;
                                        }
                                        case 'UHC Medicaid': {
                                            noImageU++;
                                            break;
                                        }

                                    }
                                    break;

                                }
                            }
                        }

                    });
                    totalDentaGreen += greenD;
                    totalMCNAGreen += greenM;
                    totalTMHPGreen += greenT;
                    totalUHCGreen += greenU;

                    totalDentaRedA += redAD;
                    totalMCNARedA += redAM;
                    totalTMHPRedA += redAT;
                    totalUHCRedA += redAU;

                    totalDentaRedX += redXD;
                    totalMCNARedX += redXM;
                    totalTMHPRedX += redXT;
                    totalUHCRedX += redXU;

                    totalDentaNoImage += noImageD;
                    totalMCNANoImage += noImageM;
                    totalTMHPNoImage += noImageT;
                    totalUHCNoImage += noImageU;

                    switch (state.filters.insurance) {
                        case 'MCNA': {
                            Object.assign(objData[practiceName], {
                                "MCNA": {green: greenM, redA: redAM, redX: redXM, noImage: noImageM},
                            });
                            totalArray = [
                                {
                                    green: totalMCNAGreen,
                                    redA: totalMCNARedA,
                                    redX: totalMCNARedX,
                                    noImage: totalMCNANoImage
                                }
                            ]
                            break;
                        }
                        case 'DentaQuest': {
                            Object.assign(objData[practiceName], {
                                "DentaQuest": {green: greenD, redA: redAD, redX: redXD, noImage: noImageD},
                            });
                            totalArray = [
                                {
                                    green: totalDentaGreen,
                                    redA: totalDentaRedA,
                                    redX: totalDentaRedX,
                                    noImage: totalDentaNoImage
                                },
                            ]
                            break;
                        }
                        case 'TMHP': {
                            Object.assign(objData[practiceName], {
                                "TMHP": {green: greenT, redA: redAT, redX: redXT, noImage: noImageT},
                            });
                            totalArray = [
                                {
                                    green: totalTMHPGreen,
                                    redA: totalTMHPRedA,
                                    redX: totalTMHPRedX,
                                    noImage: totalTMHPNoImage
                                }
                            ]
                            break;
                        }
                        case 'UHC Medicaid': {
                            Object.assign(objData[practiceName], {
                                "UHC Medicaid": {green: greenU, redA: redAU, redX: redXU, noImage: noImageU},

                            });
                            totalArray = [
                                {green: totalUHCGreen, redA: totalUHCRedA, redX: totalUHCRedX, noImage: totalUHCNoImage}
                            ]
                            break;
                        }
                        default: {
                            Object.assign(objData[practiceName], {
                                "MCNA": {green: greenM, redA: redAM, redX: redXM, noImage: noImageM},
                                "DentaQuest": {green: greenD, redA: redAD, redX: redXD, noImage: noImageD},
                                "TMHP": {green: greenT, redA: redAT, redX: redXT, noImage: noImageT,},
                                "UHC Medicaid": {green: greenU, redA: redAU, redX: redXU, noImage: noImageU},
                            });
                            totalArray = [
                                {
                                    green: totalMCNAGreen,
                                    redA: totalMCNARedA,
                                    redX: totalMCNARedX,
                                    noImage: totalMCNANoImage
                                },
                                {
                                    green: totalDentaGreen,
                                    redA: totalDentaRedA,
                                    redX: totalDentaRedX,
                                    noImage: totalDentaNoImage
                                },
                                {
                                    green: totalTMHPGreen,
                                    redA: totalTMHPRedA,
                                    redX: totalTMHPRedX,
                                    noImage: totalTMHPNoImage
                                },
                                {green: totalUHCGreen, redA: totalUHCRedA, redX: totalUHCRedX, noImage: totalUHCNoImage}
                            ]
                        }
                    }
                    Object.keys(objData).forEach((key1, dataRecords) => {
                        const data = Object.values(objData);
                        const records: any = data[dataRecords];
                        Object.keys(records).forEach((key2) => {
                            enabledCarrierList.forEach((dataValue, key3) => {
                                if (key2 === dataValue) {
                                    objectInfoData[practiceName].push(statusItem && records[dataValue])
                                }
                            });
                        });
                    });
                }
            });

            objectInfoData = Object.assign(objectInfoData, {'Total': totalArray})
            const practicesInfo = Object.keys(objectInfoData);
            let checkInfoASC: any = [];
            let checkInfoDESC: any = [];
            let objDataNew: any = [];
            let objDataSort: any = {};

            if (direction !== "sort" && column) {
                practicesInfo.forEach((practiceName: any) => {
                    const data = objectInfoData[practiceName];
                    if (data.length > 0 && data) {
                        const finalSortCond = column === enabledCarrierList[0] ? data.length === 1 ? data[0][color] : data[0][color] : column === enabledCarrierList[1] ?
                            data.length === 1 ? data[0][color] : data[1][color] : column === enabledCarrierList[2] ? data.length === 1 ? data[0][color] :
                                data[2][color] : data.length === 1 ? data[0][color] : data[3][color];
                        switch (direction) {
                            case 'sortByAsc': {
                                finalSortCond && checkInfoASC.push({
                                    practice: practiceName,
                                    value: column === enabledCarrierList[0] ? finalSortCond : column === enabledCarrierList[1]
                                        ? finalSortCond : column === enabledCarrierList[2] ? finalSortCond : finalSortCond
                                });
                                break;
                            }
                            case 'sortByDesc': {
                                finalSortCond && checkInfoDESC.push({
                                    practice: practiceName,
                                    value: column === enabledCarrierList[0] ? finalSortCond : column === enabledCarrierList[1] ?
                                        finalSortCond : column === enabledCarrierList[2] ? finalSortCond : finalSortCond
                                });
                                break;
                            }
                        }
                    }
                });
                switch (direction) {
                    case 'sortByAsc': {
                        checkInfoASC.sort(function (a: any, b: any) {
                            return b.value - a.value
                        });
                        checkInfoASC.forEach((info: any, key: any) => {
                            const dataASC: any = objectInfoData[info.practice];
                            objDataNew.push({[info.practice]: dataASC})
                            Object.assign(objDataSort, {[info.practice]: dataASC});
                        });
                        break;
                    }
                    case 'sortByDesc': {
                        checkInfoDESC.sort(function (a: any, b: any) {
                            return a.value - b.value
                        });
                        checkInfoDESC.forEach((info: any, key: any) => {
                            const dataDESC: any = objectInfoData[info.practice];
                            objDataNew.push({[info.practice]: dataDESC})
                            Object.assign(objDataSort, {[info.practice]: dataDESC});
                        });
                        break;
                    }
                }
            }

            const practicesInfoFinal = Object.keys(objDataSort);
            const practiceResponse = direction !== 'sort' ? practicesInfoFinal && practicesInfoFinal.length > 0 ?
                practicesInfoFinal : practicesInfo : practicesInfo;
            practiceResponse.forEach((practiceName: any, index: any) => {
                let returnArrayData: React.ReactElement[] = [];
                const data = direction !== "sort" && Object.keys(objDataSort).length > 0 ? objDataSort[practiceName] :
                    objectInfoData[practiceName];
                let checkData: any = false;
                data.forEach((dataValue: any, key3: any) => {
                    checkData = data[key3] && true;
                    returnArrayData.push(<td style={{textAlign: "center"}}
                                             key={index + key3 + 'green'}>{dataValue.green}</td>);
                    returnArrayData.push(<td style={{textAlign: "center"}}
                                             key={index + key3 + 'redA'}>{dataValue.redA}</td>);
                    returnArrayData.push(<td style={{textAlign: "center"}}
                                             key={index + key3 + 'redX'}>{dataValue.redX}</td>);
                    returnArrayData.push(<td style={{textAlign: "center", borderRight: '1px solid black'}}
                                             key={index + key3 + 'noImage'}>{dataValue.noImage}</td>);
                });
                reportRows.push(<tr key={practiceName}>
                    <td>{practiceName}</td>
                    {checkData && data && data.length > 0 ? returnArrayData :
                        <td colSpan={17}>No records Found</td>}
                </tr>)
            });

            return <tbody>{reportRows}</tbody>;
        }
    };

    const infoDataExcel: any = state.report ? state.report : props.report.patientValidationData;
    const csvHeaderInfo: any = carrierList.length > 0 ? carrierList : enabledCarrierList;
    const insuranceInfo: any = state.filters.insurance;

    const sendPVSEmail = (startDate: any, csvHeaderInfo: any, infoDataExcel: any, carrierInfo: any, insuranceInfo: any, excel: any) => {
        const pdfDataReturn: any = exportPDFPVSReport(startDate, csvHeaderInfo, infoDataExcel, carrierInfo, insuranceInfo);
        const csvDataReturn: any = exportPatientValidationStatusReport(startDate, csvHeaderInfo, infoDataExcel, carrierInfo, insuranceInfo, !excel);
        reportAction.sendMailPVS({data: csvDataReturn, pdf: pdfDataReturn});
        setExcel(true);
    };

    return (
        <Template activeLink='patient-validation-status-report'>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">
                        Patient Validation Status Reports <HelpMark pageId='5'/>
                    </h2>
                    <div className="topFilters float-right">
                        <a className="link mr30"
                           onClick={() => sendPVSEmail(startDate, csvHeaderInfo, infoDataExcel, carrierInfo, insuranceInfo, excel)}><Icon
                            name="send"/> Send</a>
                        <a className="link"
                           onClick={() => exportPatientValidationStatusReport(startDate, csvHeaderInfo, infoDataExcel, carrierInfo, insuranceInfo, excel)}><Icon
                            name="file alternate"/>Export</a>
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="aptStatus"
                            placeholder="APT Status"
                            selection={true}
                            options={aptStatusOptions}
                            onChange={onChangeFilter}
                            value={state.filters.aptStatus}
                        />
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="practice"
                            placeholder="Practice"
                            multiple={true}
                            selection={true}
                            options={state.filters.practiceOptions}
                            onChange={onChangeFilter}
                            value={state.filters.practice}
                        />

                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="insurance"
                            placeholder="insurance"
                            selection={true}
                            options={state.filters.insuranceCategoryOptions}
                            onChange={onChangeFilter}
                            value={state.filters.insurance}
                        />

                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="region"
                            placeholder="Region"
                            selection={true}
                            multiple={true}
                            options={props.practice.regionOptions}
                            onChange={onChangeFilter}
                            value={state.filters.region}
                        />
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="brands"
                            placeholder="Brands"
                            selection={true}
                            multiple={true}
                            options={brandsOptions()}
                            onChange={onChangeFilter}
                            value={state.filters.brands}
                        />

                    </div>
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    <Button.Group className="btnNextPre mb10 float-left">
                        <Button icon='left chevron' onClick={handlePrevMonth}/>
                        <Button icon='right chevron' onClick={handleNextMonth}/>
                    </Button.Group>
                    <h3 className="float-left mt5 ml10">Date:{startDate}({moment(startDate).format('dddd')})</h3>
                    <div className="clearfix"/>
                    <div className="table-responsive FreezeTable">
                        <table className="ui celled single line table unstackable tableStyle PVS">
                            {getTableHeadings()}
                            {getTableRows(state.direction, state.column, state.color)}
                        </table>
                    </div>
                </div>
            </div>
        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app,
        patients: state.patients
    };
}

export default connect(mapStateToProps)(PatientValidationStatusReport);

