import {Button, Modal, Table} from "semantic-ui-react";
import {practiceReportField} from "../../model/report";
import React from "react";


const PatientPracticeReportFieldsModal = (props: any) => {

  return (
      <>
        {
          <Modal
              closeOnEscape={true}
              closeOnDimmerClick={true}
              open={props.modalOpen}
              onClose={props.handleModalClose}

          >
            <Modal.Header><b>{props.practice}</b> <a className="ui orange label">Total: {props.modalData.length}</a>
              <div className="ui top right attached label production_right_label">
                <Button onClick={() => props.handleModalClose()} inverted={true}>
                  Close
                </Button>
              </div>
            </Modal.Header>
            <Modal.Content scrolling={true}>
              <Modal.Description>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>NAME</Table.HeaderCell>
                      <Table.HeaderCell>EMAIL</Table.HeaderCell>
                      <Table.HeaderCell>LAST LOGIN DATE</Table.HeaderCell>
                      <Table.HeaderCell>TOTAL ACCESS</Table.HeaderCell>
                      <Table.HeaderCell>MOBILE COUNT</Table.HeaderCell>
                      <Table.HeaderCell>DESKTOP COUNT</Table.HeaderCell>
                      <Table.HeaderCell>RECENT MOBILE OS</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {props.modalData && props.modalData.length > 0 ? props.modalData.map((i: practiceReportField, index: number) => {
                      return (<Table.Row key={index}>
                            <Table.Cell>{i.firstName + ' ' + i.lastName}</Table.Cell>
                            <Table.Cell>{i.email}</Table.Cell>
                            <Table.Cell>{i.Date}</Table.Cell>
                            <Table.Cell>{i.total}</Table.Cell>
                            <Table.Cell>{i.Cell}</Table.Cell>
                            <Table.Cell>{i.Desktop}</Table.Cell>
                            <Table.Cell>{i.deviceOS}</Table.Cell>
                          </Table.Row>
                      )
                    }) : null}
                  </Table.Body>
                </Table>
              </Modal.Description>
            </Modal.Content>
          </Modal>}
      </>
  )
}

export default PatientPracticeReportFieldsModal;