import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const getHygienistBracketSystem = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const results = await api.get(`bracketSystem/hygienistBracketSystem`);
        dispatch({ type: ActionType.LOAD_BRACKET_SYSTEM, payload: { HygienistBracketSystem: results } });
        dispatch({type: ActionType.LOADER, payload: false});
        return results;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
};

export const saveHygienistBracketSystem = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        dispatch({type: ActionType.CREATE_BRACKET_SYSTEM, payload: []});
         await api.post(`bracketSystem/create`,data)
            .then((results:any)=>{
                getHygienistBracketSystem({})
                dispatch({type: ActionType.LOADER, payload: false});
                return results;
            })

    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
};
export const deleteHygienistBracketSystem = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const results = await api.post(`bracketSystem/delete`,{id:id});
        dispatch({type: ActionType.DELETE_BRACKET_SYSTEM, payload: results});
        dispatch({ type: ActionType.LOADER, payload: false });
        return results;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
};
export const updateHygienistBracketSystem = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        dispatch({type: ActionType.CREATE_BRACKET_SYSTEM, payload: []});
        await api.post(`bracketSystem/update`,data)
            .then((results:any) =>{
                getHygienistBracketSystem({})
                dispatch({type: ActionType.LOADER, payload: false});
                return results;
            })
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
};
