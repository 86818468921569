import React, { useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { RootState } from './model';
import { connect } from 'react-redux';

const Overlay = (props: any) => {
  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowOverlay(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {showOverlay && (
        <Dimmer active inverted>
          <Loader></Loader>
        </Dimmer>
      )}
    </div>
  );
};

function mapStateToProps(state: RootState) {
  return {
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(Overlay);