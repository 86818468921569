import { ReportTN } from "../../model/report";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";
const initialState:ReportTN = {
	reportTimeAndName: []
};

export const reportTimeAndName = createReducer(initialState, {
	[ActionType.PRESERVE_REPORT_TIME_NAME](state: ReportTN, action: Action<ReportTN>) {
		return Object.assign({...state}, action.payload);
	}
});
