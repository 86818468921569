
import React from 'react'
import { HealthScore } from '../../model/healthScore';

const ProgressBar = (healthScore: HealthScore) => {
    const { success, pending, failed } = healthScore;
    const total = success + pending + failed;
    const good = (success / total) * 100;
    const inProgress = (pending / total) * 100;
    const fail = (failed / total) * 100;

    return (
        <>
            <div className="progress-container">
                <div className="progress-bar">
                    <div className="progress-bar-stage" style={{ width: `${good}%`, backgroundColor: '#21BA45' }}>
                    </div>
                    <div className="progress-bar-stage" style={{ width: `${inProgress}%`, backgroundColor: '#f8bb27' }}>
                    </div>
                    <div className="progress-bar-stage" style={{ width: `${fail}%`, backgroundColor: 'red' }}>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProgressBar;