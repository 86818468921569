import React, {useState,useEffect} from 'react'
interface TimerPros {
    refreshTimeLeft:any
    refreshTimerEnd:any

}

const Timer:React.FC<TimerPros>=(props)=>{
    const [seconds,setSeconds]=useState(props.refreshTimeLeft)
    const [reSyncEnable,setReSyncEnable]=useState(false)

    function refreshTimerEnd(){
        setReSyncEnable(false)
        props.refreshTimerEnd();
      }
    useEffect(()=>{
    let interval :any= null;
     if (!reSyncEnable) {
      interval = setInterval(() => {
        if(seconds==0 && !reSyncEnable){
            if(window.confirm("Do you want to Resync Data.")){
                setReSyncEnable(false)
                props.refreshTimerEnd()
            }else{
                setReSyncEnable(true)
            }
        }
        setSeconds((seconds:any)=> seconds - 1);
      }, 1000);
    } else if (!reSyncEnable && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    },[reSyncEnable,seconds])

     function formatTime(time:any){
        return new Date(time * 1000).toISOString().slice(11, 19)
    }
    return <h3>
        {!reSyncEnable && <strong>ReSync: <span>{formatTime(seconds)}</span></strong>}
        {reSyncEnable&& <button className='btn PrimaryBtn' onClick={refreshTimerEnd}>Resync</button>}
    </h3>
}


export default Timer

