import { Action, ActionType } from "../../model/actionTypes";
import { HealthScore } from "../../model/healthScore";
import createReducer from "./createReducer";

const initialState: HealthScore =
{
    success: 0,
    pending: 0,
    failed: 0
};
export const healthScore = createReducer(initialState, {
    [ActionType.HEALTH_STATUS_ADD_TO_COUNT](state: HealthScore, action:Action<HealthScore>) {
        return {
                success: state.success + action.payload.success,
                pending: state.pending + action.payload.pending,
                failed: state.failed + action.payload.failed
        };
    }
});
