import createReducer from "./createReducer";
import {Action, ActionType} from "../../model/actionTypes";
import { ProviderAccount} from "../../model/providers";

const initialState: Array<ProviderAccount> = [];


export const providerAccountList = createReducer(initialState, {
    [ActionType.PROVIDER_ACCOUNTS](state: Array<ProviderAccount>, action: Action<Array<ProviderAccount>>) {
        return Object.assign({...state}, action.payload);
    },
})