/* tslint:disable:jsx-alignment */
import React, {useEffect, useState} from 'react';
import {resultDropDown} from './constants';
import ReactTooltip from 'react-tooltip';
import Sorting from './Sorting';
import moment from 'moment';
import {
    Dropdown,
    Form,
    Grid,
    Header,
    Icon,
    Label,
    Message,
    Popup,
    Table
} from "semantic-ui-react";
import * as SpotActions from "../../redux/actions/spot";

import NotesModal from "../../components/NotesModal";
import ResultsModal from "../../components/ResultsModal";
import {useActions} from "../../redux/actions";

function ListBroken(props: any) {
    const [showNotesHistory , setShowNotesHistory] = useState(false)
    const [showResultHistory , setShowResultHistory] = useState(false)
    const [notes, setNotes] = useState<any>([]);
    const [state, setState] = useState({
        id: null,
        practiceId: null,
        note: null,
        Guarantor: null,
        LastVisit: null,
        Last_Apt: null,
        Days_Since_Last_Apt: null as any,
        Days_Since_Last_Visit: null as any,
        pager: {} as any,
        commlogCheckBox: {} as any,
        exportModal: false,
        exportCount: 100,
        location: null as any,
        editedItems: {} as any,
        filterExpanded: props.filtersExpanded
    } as any);

    const onClickAddNote = (data: any) => {
        setState({
            ...state,
            practiceId: data.practiceId,
            id: data.PT_Num,
            notes: data.notes,
            Guarantor: data.Guarantor,
            LastVisit: data.LastVisit,
            Last_Apt: data.Last_Apt,
            Days_Since_Last_Apt: data.Days_Since_Last_Apt,
            Days_Since_Last_Visit: data.Days_Since_Last_Visit
        });
    };

    const {
        result,
        brokens,
        location,
        handleClick,
        orderBy,
        removeSorting
    } = props;
    const spotAction = useActions(SpotActions);

    const fetchNotes = () => {
        const patients: any = []
        props.brokens.forEach((item: any) => {
            patients.push({subscriberid: item.subscriberid, patNumber: item.PT_Num, practiceId: item.practiceId})
        })

        spotAction.getCollectiveNotesList(patients).then((res: any) => {
            setNotes(res)
        })
    }

    useEffect(() => {
        setNotes([]);
        fetchNotes()
    }, [props.brokens])

    const saveNotes = (item: any, value: any) => {
        if (item.notes === value) {
            setState({
                ...state,
                id: null,
                practiceId: null,
                notes: null,
                Guarantor: null,
                LastVisit: null,
                Last_Apt: null,
                Days_Since_Last_Apt: null,
                Days_Since_Last_Visit: null
            })
            return
        }
        item.notes = value;
        item.notesUpdatedAt = new Date();
        setShowNotesHistory(false)
        setShowResultHistory(false)
        props.saveNotes(item);
    };
    const saveResult = (data: any) => {
        setState({
            ...state,
            id: null,
            practiceId: null,
            notes: null,
            Guarantor: null,
            LastVisit: null,
            Last_Apt: null,
            Days_Since_Last_Apt: null,
            Days_Since_Last_Visit: null
        })
        setShowNotesHistory(false)
        setShowResultHistory(false)
        data.addLocation = 'claims';
        props.saveResults(data);
    }

    const changeResultBlank = (index: number, patNum: any) => {
        props.handleResultChange({target: {id: index, value: 'blank', commlogSearch: true}});
        let {commlogCheckBox} = state
        commlogCheckBox[props.location] = patNum;
        setState({...state, commlogCheckBox})
    }

    const trimString = (str: string, len: number, addDots: boolean) => {
        return (
            (str && str.length > len) ?
                (addDots ?
                    str.substring(0, len - 3) :
                    str.substring(0, len - 3) + '...') :
                str);
    }

    const getPractice = (location: string) => {
        return props.dbListNamePracticesReverse[location];
    }

    const setHideColumns = () => {
        const {selectedColumns, tableColumns} = props
        const style = {display: 'none'}
        let returnArray: any[] = [style];
        tableColumns.forEach((item: any) => {
            if (selectedColumns.indexOf(item.value) >= 0)
                returnArray.push(style);
            else
                returnArray.push(null);
        });
        return returnArray;
    }

    const PhoneNumbersString = (PatNum: string, Guarantor: any, lastPhoneStatusParam: any, phoneArray: string[]) => {
        const savePhoneArray: string[] = [];
        const newArray = phoneArray.filter((item) => {
            if (item && item != '' && !savePhoneArray.includes(item.trim())) {
                savePhoneArray.push(item.trim());
                return true;
            }
        });
        let response: any[] = [];
        if (newArray.length) {
            newArray.forEach((phoneNumber, key) => {
                if (phoneNumber) {
                    phoneNumber = phoneNumber.trim();
                    const lastPhoneStatus = (lastPhoneStatusParam && Object.keys(lastPhoneStatusParam).length) ? JSON.parse(lastPhoneStatusParam) : {};
                    let phoneStatusState = state[`phoneStatus-${PatNum}`] || lastPhoneStatus;
                    const bellStatus = (!(phoneStatusState[phoneNumber] && phoneStatusState[phoneNumber][1]));
                    const messageStatus = (!(phoneStatusState[phoneNumber] && phoneStatusState[phoneNumber][2]));
                    const phoneStatus = (!(phoneStatusState[phoneNumber] && phoneStatusState[phoneNumber][3]));
                    const headStatus = (!(phoneStatusState[phoneNumber] && phoneStatusState[phoneNumber][4]));

                    const iconStyle = {cursor: "pointer", paddingRight: "5px"};

                    response.push(<div key={key}>
                        <Popup
                            trigger={<Icon name='bell outline' color={!bellStatus ? 'green' : 'grey'} style={iconStyle}
                                           onClick={() => phoneClick(PatNum, Guarantor, phoneNumber, 1, bellStatus, phoneStatusState)}
                            />} content="The phone rang" basic/>

                        <Popup
                            trigger={<Icon name="envelope open outline" color={!messageStatus ? 'green' : 'grey'}
                                           style={iconStyle}
                                           onClick={() => phoneClick(PatNum, Guarantor, phoneNumber, 2, messageStatus, phoneStatusState)}
                            />}
                            content="They left a message"
                            basic
                        />

                        <Popup
                            trigger={<Icon name="phone volume" color={!phoneStatus ? 'red' : 'grey'} style={iconStyle}
                                           onClick={() => phoneClick(PatNum, Guarantor, phoneNumber, 3, phoneStatus, phoneStatusState)}
                            />}
                            content="The phone number did not work"
                            basic
                        />

                        <Popup
                            trigger={<Icon name='user outline' color={!headStatus ? 'red' : 'grey'} style={iconStyle}
                                           onClick={() => phoneClick(PatNum, Guarantor, phoneNumber, 4, headStatus, phoneStatusState)}
                            />}
                            content="They talked to a representative."
                            basic
                        />
                        <Popup style={iconStyle}
                               trigger={<small> ..{phoneNumber.substring(10, 13)}</small>}
                               content={phoneNumber}
                        />

                    </div>);
                }
            });
        }
        return response;
    }

    const phoneClick = (PatNum: string, Guarantor: any, phoneNumber: string, statusCode: number, status: any, phoneStatusState: string) => {
        let obj: any = phoneStatusState;
        const stateKey = `phoneStatus-${PatNum}`;

        if (!obj[phoneNumber])
            obj[phoneNumber] = {}

        obj[phoneNumber] = {[statusCode]: status};
        phoneStatusChange({PatNum, Guarantor, phoneStatusObj: obj});
        setState({...state, [stateKey]: obj});
    };


    const phoneStatusChange = (obj: any) => {
        const postObj = {
            patientId: obj.PatNum,
            phoneStatus: JSON.stringify(obj.phoneStatusObj),
            practice: props.location,
            Guarantor: obj.Guarantor
        };
        props.saveBrokenResult(postObj);
    }

    const phoneTolTip = (PatNum: any) => {
        let flag = 'true';
        if (state['phoneTolTip'] && state['phoneTolTip'][PatNum] == 'true')
            flag = 'false';
        setState({...state, ['phoneTolTip']: {[PatNum]: flag}});
    };
    const customStyle = setHideColumns();


    const extractNotesTimeStamp = (subscriberid: any, patNumber: any) => {
        let patientNotes: any = [];

        notes.forEach((item: any) => {
            if (patNumber === item.patNumber) {
                patientNotes.push(item)
            }
        })
        return patientNotes.length && patientNotes[patientNotes.length - 1].notestimestamp ? moment(patientNotes[patientNotes.length - 1].notestimestamp).format('DD MMM YYYY hh:mm') : '';
    }

    return (
        <div>
            {showNotesHistory && <NotesModal
                setShowNotesHistory={setShowNotesHistory}
                submitNotes={saveNotes}
                item={showNotesHistory}
            />}
            {showResultHistory && <ResultsModal
                hideModal={setShowResultHistory}
                saveResult={saveResult}
                item={showResultHistory}
            />}
            <div className="table-responsive" style={{'height': props.filtersExpanded === 0 ? '60vh' : '32vh'}}>
                <Table striped={true} className="table tableCustomStyle mb0">
                    <thead>
                    <tr className="tr-nowrap">
                        {(result && result == '*DNC') ? <th>Remove From CommSearch</th> : ""}
                        <th style={customStyle[1] || {minWidth: '70px'}}>GuarPN
                            <Sorting
                                order={orderBy['Guarantor'] ? orderBy['Guarantor'] : 'ASC'}
                                handleClick={handleClick}
                                removeSort={removeSorting}
                                id={`Guarantor`}
                                orderBy={orderBy['Guarantor'] ? 'Guarantor' : ''}
                            />
                        </th>
                        <th style={customStyle[2] || {minWidth: '70px'}}>PatNum
                            <Sorting
                                order={orderBy['PatNum'] ? orderBy['PatNum'] : 'ASC'}
                                handleClick={handleClick}
                                removeSort={removeSorting}
                                id={`PatNum`}
                                orderBy={orderBy['PatNum'] ? 'PatNum' : ''}
                            />
                        </th>
                        <th style={customStyle[3]}>Name <Sorting order={orderBy['LName'] ? orderBy['LName'] : 'ASC'}
                                                                 removeSort={removeSorting}
                                                                 handleClick={handleClick} id={`LName`}
                                                                 orderBy={orderBy['LName'] ? 'LName' : ''}/></th>
                        <th style={customStyle[4]}>Email</th>
                        <th style={customStyle[5]}>Practice</th>
                        <th style={customStyle[6]}>Address</th>
                        <th style={customStyle[7]}>Phone Number <Sorting
                            order={orderBy['HmPhone'] ? orderBy['HmPhone'] : 'ASC'} handleClick={handleClick}
                            removeSort={removeSorting}
                            id={`HmPhone`}
                            orderBy={orderBy['HmPhone'] ? 'HmPhone' : ''}/></th>
                        <th style={customStyle[8] || {minWidth: '90px'}}>Birthdate <Sorting
                            removeSort={removeSorting}
                            order={orderBy['BirthDate'] ? orderBy['BirthDate'] : 'ASC'} handleClick={handleClick}
                            id={`BirthDate`} orderBy={orderBy['BirthDate'] ? 'BirthDate' : ''}/></th>

                        <th style={customStyle[9] || {minWidth: '50px'}}>Age <Sorting
                            order={orderBy['age'] ? orderBy['age'] : 'ASC'}
                            removeSort={removeSorting}
                            handleClick={handleClick} id={`age`} orderBy={orderBy['age'] ? 'age' : ''}/>
                        </th>
                        <th style={customStyle[10] || {minWidth: '85px'}}>Show Rate(%)<Sorting
                            removeSort={removeSorting}
                            order={orderBy['show_rate'] ? orderBy['show_rate'] : 'ASC'} handleClick={handleClick}
                            id={`show_rate`} orderBy={orderBy['show_rate'] ? 'show_rate' : ''}/></th>

                        <th style={customStyle[19] || {minWidth: '80px'}}>Last Apt <Sorting
                            removeSort={removeSorting}
                            order={orderBy['Last_Apt'] ? orderBy['Last_Apt'] : 'ASC'}
                            handleClick={handleClick}
                            id={`Last_Apt`}
                            orderBy={orderBy['Last_Apt'] ? 'Last_Apt' : ''}/></th>
                        <th style={customStyle[11] || {minWidth: '90px'}}>Last Apt <Sorting
                            removeSort={removeSorting}
                            order={orderBy['Days_Since_Last_Apt'] ? orderBy['Days_Since_Last_Apt'] : 'ASC'}
                            handleClick={handleClick} id={`Days_Since_Last_Apt`}
                            orderBy={orderBy['Days_Since_Last_Apt'] ? 'Days_Since_Last_Apt' : ''}/></th>
                        <th style={customStyle[20] || {minWidth: '85px'}}>Last Visit <Sorting
                            removeSort={removeSorting}
                            order={orderBy['LastVisit'] ? orderBy['LastVisit'] : 'ASC'}
                            handleClick={handleClick}
                            id={`LastVisit`}
                            orderBy={orderBy['LastVisit'] ? 'LastVisit' : ''}/></th>
                        <th style={customStyle[21] || {minWidth: '85px'}}>Next Appt <Sorting
                            removeSort={removeSorting}
                            order={orderBy['Next_Apt'] ? orderBy['Next_Apt'] : 'ASC'} handleClick={handleClick}
                            id={`Next_Apt`} orderBy={orderBy['Next_Apt'] ? 'Next_Apt' : ''}/></th>
                        <th style={customStyle[12] || {minWidth: '90px'}}>Last Visit<Sorting
                            removeSort={removeSorting}
                            order={orderBy['Days_Since_Last_Visit'] ? orderBy['Days_Since_Last_Visit'] : 'ASC'}
                            handleClick={handleClick} id={`Days_Since_Last_Visit`}
                            orderBy={orderBy['Days_Since_Last_Visit'] ? 'Days_Since_Last_Visit' : ''}/></th>

                        <th style={customStyle[15]}>Comm Log</th>
                        <th style={customStyle[16] || {minWidth: '105px'}}>Comm Log Timestamp<Sorting
                            removeSort={removeSorting}
                            order={orderBy['CommDateTime'] ? orderBy['CommDateTime'] : 'ASC'} handleClick={handleClick}
                            id={`CommDateTime`} orderBy={orderBy['CommDateTime'] ? 'CommDateTime' : ''}/></th>
                        <th style={customStyle[17] || {minWidth: '70px'}}>Comm Log £<Sorting
                            removeSort={removeSorting}
                            order={orderBy['commLogCount'] ? orderBy['commLogCount'] : 'ASC'} handleClick={handleClick}
                            id={`commLogCount`} orderBy={orderBy['commLogCount'] ? 'commLogCount' : ''}/></th>
                        <th style={customStyle[18] || {minWidth: '130px'}}>Result</th>
                        <th style={customStyle[22] || {minWidth: '105px'}}>Result Timestamp<Sorting
                            removeSort={removeSorting}
                            order={orderBy['resultUpdatedAt'] ? orderBy['resultUpdatedAt'] : 'ASC'} handleClick={handleClick}
                            id={`resultUpdatedAt`} orderBy={orderBy['resultUpdatedAt'] ? 'resultUpdatedAt' : ''}/></th>
                        <th style={customStyle[13] || {minWidth: '50px'}}>Note</th>
                        <th style={customStyle[14] || {minWidth: '90px'}}>Notes Timestamp<Sorting
                            removeSort={removeSorting}
                            order={orderBy['notesUpdatedAt'] ? orderBy['notesUpdatedAt'] : 'ASC'}
                            handleClick={handleClick}
                            id={`notesUpdatedAt`} orderBy={orderBy['notesUpdatedAt'] ? 'notesUpdatedAt' : ''}/></th>
                        <th style={customStyle[24] || {minWidth: '80px'}}>Carrier</th>
                        <th style={customStyle[23] || {minWidth: '130px'}}>Ins Verification Date<Sorting
                            removeSort={removeSorting}
                            order={orderBy['dateLastVerified'] ? orderBy['dateLastVerified'] : 'ASC'}
                            handleClick={handleClick}
                            id={`dateLastVerified`}
                            orderBy={orderBy['dateLastVerified'] ? 'dateLastVerified' : ''}/>
                        </th>
                        <th style={customStyle[25] || {minWidth: '130px'}}>ML score<Sorting
                            removeSort={removeSorting}
                            order={orderBy['prediction'] ? orderBy['prediction'] : 'DESC'}
                            handleClick={handleClick}
                            id={`prediction`}
                            orderBy={orderBy['prediction'] ? 'prediction' : ''}/>
                        </th>
                        <th style={customStyle[26]}>SubscriberID</th>
                    </tr>
                    </thead>

                    <tbody>
                    {brokens && brokens.map((i: any, index: number) =>
                        <tr key={index}>
                            {(result && result == '*DNC') ? <td><input type="checkbox"
                                                                       checked={state.commlogCheckBox[location] == i.PT_Num}
                                                                       onChange={() => changeResultBlank(index, i.PT_Num)}/>
                            </td> : ""}
                            <td style={customStyle[1]} className="cur-pointer color-secondary">
                                <a href={`spot-details/${location}/${i.Guarantor}${window.location.search}`}>{i.Guarantor}</a>
                            </td>
                            <td
                                style={customStyle[2]}
                                className="cur-pointer color-secondary"
                            >
                                <Popup
                                    content={<>
                                        <tr>{"Age: " + i.age}</tr>
                                        <tr>{"Address: " + i.Address}</tr>
                                    </>}
                                    key={i.PT_Num}
                                    header={i.PT_Name}
                                    trigger={
                                        <a href={`spot-details/${location}/${i.PT_Num}${window.location.search}`}>
                                            <Label
                                                color={(i.DateLastVerified && moment(i.DateLastVerified).format('MM-D-YY') == moment().format('MM-D-YY')) ? 'green' : 'red'}>{i.PT_Num}</Label>
                                        </a>

                                    }
                                />
                            </td>
                            <td style={customStyle[3]}>
                                <Popup
                                    content={i.Address}
                                    key={i.PT_Name}
                                    header={i.PT_Name}
                                    trigger={<b>{i.PT_Name}</b>}
                                />
                            </td>
                            <td style={customStyle[4]}>{i.Email}</td>
                            <td style={customStyle[5]}>{getPractice(i.location)}</td>
                            <td style={customStyle[6]}>{JSON.parse(i.patientDetails)['Address'] || ''}</td>
                            <td style={customStyle[7]}>{(
                                i.HmPhone ||
                                i.WkPhone ||
                                i.WirelessPhone ||
                                i.phone ||
                                JSON.parse(i.patientDetails)['HmPhone'] ||
                                JSON.parse(i.patientDetails)['WkPhone'] ||
                                JSON.parse(i.patientDetails)['WirelessPhone'] ||
                                JSON.parse(i.patientDetails)['phone']
                            ) ?
                                <div className="phoneTolTipOuter">
                                    <a onClick={() => phoneTolTip(i.PT_Num)}>
                                        {
                                            i.HmPhone ||
                                            i.WkPhone ||
                                            i.WirelessPhone ||
                                            i.phone ||
                                            JSON.parse(i.patientDetails)['HmPhone'] ||
                                            JSON.parse(i.patientDetails)['WkPhone'] ||
                                            JSON.parse(i.patientDetails)['WirelessPhone'] ||
                                            JSON.parse(i.patientDetails)['phone']
                                        }
                                    </a>
                                    {state['phoneTolTip'] && state['phoneTolTip'][i.PT_Num] == 'true' &&
                                        <div className="phoneTolTip">
                                            {PhoneNumbersString(i.PT_Num, i.Guarantor, i.phoneStatus, [i.HmPhone, i.WkPhone, i.WirelessPhone, i.phone, JSON.parse(i.patientDetails)['HmPhone'], JSON.parse(i.patientDetails)['WkPhone'], JSON.parse(i.patientDetails)['WirelessPhone'], JSON.parse(i.patientDetails)['phone']])}
                                        </div>
                                    }
                                </div> : ''}</td>
                            <td style={customStyle[8]}>{(i.BirthDate && moment(i.BirthDate).isValid()) ? moment(i.BirthDate).format('DD MMM YYYY') : i.BirthDate}</td>
                            <td style={customStyle[9]} className="center">
                                <Popup
                                    content={(i.BirthDate && moment(i.BirthDate).isValid()) ? moment(i.BirthDate).format('DD MMM YYYY') : i.BirthDate}
                                    key={"birth_" + i.PT_Num}
                                    header="Birthdate"
                                    trigger={<b className="cursorPointer">{i.age}</b>}
                                />
                            </td>
                            <td style={customStyle[10]} className="center">{i.show_rate > 100 ? 100 : i.show_rate}</td>
                            <td style={customStyle[19]}>
                                <Popup
                                    content={(i.Days_Since_Last_Apt) ? i.Days_Since_Last_Apt : '0'}
                                    key={"days_lats_apt_" + i.PT_Num}
                                    header="Days Since Last Apt"
                                    trigger={<b
                                        className="cursorPointer">{(i.Last_Apt && moment(i.Last_Apt).isValid()) ? moment(i.Last_Apt).utc().format('DD MMM YYYY') : i.Last_Apt}</b>}
                                />
                            </td>
                            <td style={customStyle[11]}>{(i.Days_Since_Last_Apt) ? i.Days_Since_Last_Apt : '0'} days</td>
                            <td style={customStyle[20]}>
                                <Popup
                                    content={(i.Days_Since_Last_Visit) ? i.Days_Since_Last_Visit : '0'}
                                    key={"days_lats_visit_" + i.PT_Num}
                                    header="Days Since Last Visit"
                                    trigger={<b
                                        className="cursorPointer">{(i.LastVisit && moment(i.LastVisit).isValid()) ? moment(i.LastVisit).utc().format('DD MMM YYYY') : i.LastVisit}</b>}
                                />
                            </td>
                            <td style={customStyle[21]}>{i.Next_Apt ? moment(i.Next_Apt).utc().format('DD MMM YYYY') : ''}</td>
                            <td style={customStyle[12]}>{(i.Days_Since_Last_Visit) ? i.Days_Since_Last_Visit : '0'} days</td>

                            <td style={customStyle[15]}>{i.Note ?
                                <div>
                                    <a data-for={`notes${index}`}>
                                        <Popup
                                            content={<>
                                                <tr>{"CL timestamp: " + i.CommDateTime ? moment(i.CommDateTime).format("DD MMM YYYY hh:mm") : ''}</tr>
                                                <tr>{"Comm Log £: " + i.commLogCount}</tr>
                                                <tr>{i.Note}</tr>
                                            </>}
                                            key={"note_" + i.Note}
                                            header=""
                                            trigger={<b className="cursorPointer">{trimString(i.Note, 15, false)}</b>}
                                        />
                                    </a>
                                    <ReactTooltip
                                        id={`notes${index}`}
                                        place="right"
                                        type="light"
                                        effect="float"
                                        border={true}
                                    >
                                        {i.Note}
                                    </ReactTooltip>
                                </div> : ''}</td>
                            <td style={customStyle[16]}>{i.CommDateTime ? moment(i.CommDateTime).format("DD MMM YYYY hh:mm") : ''}</td>
                            <td style={customStyle[17]}>{i.commLogCount}</td>
                            <td className="no-wrap" style={customStyle[18]}>
                                <Icon
                                    name="list"
                                    className='cursorPointer'
                                    onClick={() => setShowResultHistory(i)}
                                />
                            </td>
                            <td style={customStyle[22]}>{(i.resultUpdatedAt) ? moment(i.resultUpdatedAt).format("MMM/DD/YYYY") : ''}</td>
                            <td style={customStyle[13]}>
                                <b className="cursorPointer">
                                    <p onClick={() => setShowNotesHistory(i)}><Icon
                                        name="edit outline"
                                        className='cursorPointer'
                                    />
                                    </p>
                                </b>
                            </td>
                            <td style={customStyle[14]}>{notes.length ? extractNotesTimeStamp(i.subscriberid, i.PT_Num) : ''}</td>
                            <td style={customStyle[24]}>{JSON.parse(i.patientDetails)['insurance'] ? JSON.parse(i.patientDetails)['insurance'] : ''}</td>
                            <td style={customStyle[23]}
                                className="center">{(i.DateLastVerified && moment(i.DateLastVerified).isValid()) ? moment(i.DateLastVerified).format('DD MMM YYYY') : i.DateLastVerified}</td>
                            <td style={customStyle[25]}>{parseFloat(i.prediction).toFixed(4)}</td>
                            <td style={customStyle[26]}>{i.subscriberid}</td>
                        </tr>
                    )
                    }
                    </tbody>
                </Table>
                {brokens.length === 0 && 
                    <Message negative> 
                        <p className='textCenter'>No Record Found</p> 
                    </Message>
                }
            </div>
            <br/>

        </div>
    );
}


export default ListBroken
