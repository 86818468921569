import React,{useState} from "react";
import {FilterTypes} from "../model/practice";
import {Dropdown, Icon} from "semantic-ui-react";
import {connect} from 'react-redux';
import {RootState} from "../model";
import "react-datepicker/dist/react-datepicker.css";
import {drListOptions, reportPageNames,brands} from '../constants';
import {OnChange} from "../model/common";
import {getMonthsOption} from "./Common";
import HelpMark from "./HelpMark";
import _ from "lodash";
import {allPracticeOptions} from "../container/Reports/Filters/Common";

function FilterSection(props: FilterTypes) {
    const info:any =props.practice.practiceNamesMap;
    let allPracticesOptions = allPracticeOptions(info);
    allPracticesOptions.shift();
    let practiceListBrands:any = [];
    for (const key in info) {
        practiceListBrands.push({practiceName:info[key].practice,brand:info[key].brand})
    }

    const data:any = Object.values(info).filter((data: any) => data.PCR === 1);

    const onChangeFilter:OnChange = (e, element) => {
        let filters: any = props.filters;
        filters[element.name] = element.value;
        if(element.name === 'month') {
            filters.date = null; //Null date field if month selected
            props.onChangeFilter(filters, true);
        } else {
            props.onChangeFilter(filters);
        }
    };
    const [selectedDoctors,setSelectedDoctors]=useState([])
    const [selectedPractice,setSelectedPractice]=useState([])
    const [selectedRegion,setSelectedRegion]=useState([])
    const [selectedBrands,setSelectedBrands]=useState([])

    const getPracticeOptionsList = () => {
        const practices = getPracticeOptions();
        return practices.map((practice: any) => {
            delete practice.lowerName;
            return practice;
        });
    }

    const getPracticeOptions = () => {
        if (props.filters['drType'].length > 0
            || props.filters['region'].length > 0
            || props.filters['brands'].length > 0) {
            return props.practiceOptions ? props.practiceOptions : allPracticesOptions;
        } else {
            return allPracticesOptions
        }
    }

    const brandsOptions=()=>{
        const brandOptions:string[]=[]
        const brandsObject:{[key:string]:string[]}={}
        brands.forEach((brand)=>{
            const res= practiceListBrands && practiceListBrands.filter(
                (item:any)=>{
                  if(item.brand===brand.value){
                    return true
                  }
                }
               ).map((item:any)=>item.practiceName)
            brandsObject[brand.value]=[...res.map((i:any)=>i.toLocaleLowerCase())]
        })
        if(props.filters['region']){
          const tempSelectedPracsPerRegion:{[key:string]:string[]}={}
          props.filters.region.forEach((item)=>{
              if(props.practice.regionPractices[item]){
                tempSelectedPracsPerRegion[item]=
                  props.practice.regionPractices[item] as []

              }
          })
          Object.keys(tempSelectedPracsPerRegion).forEach(region=>{
              Object.keys(brandsObject).forEach(brand=>{
                  const commonPracs=_.intersection(brandsObject[brand],tempSelectedPracsPerRegion[region])
                  if(commonPracs.length>0){
                      brandOptions.push(brand)
                  }
              })
          })
          return (brandOptions.length>0)?brandOptions.map(b=>{
              return {
                  value:b,
                  key:b,
                  text:b
              }
          }):
          brands
        }

    }
    const onChangeDoctorMultiple=(_:any,{value}:any)=>{
        if(selectedDoctors.length>value.length){
            selectedDoctors.filter(
                x=>!value.includes(x)
            )
        }
        setSelectedDoctors(value)
        let filters=props.filters;
        filters['drType']=value
        props.onChangeFilter(filters);
    }

    const onChangePracticeMultiple=(_:any,{value}:any)=>{
        if(selectedPractice.length>value.length){
            selectedPractice.filter(
                x=>!value.includes(x)
            )
        }
        setSelectedPractice(value)
        let filters=props.filters
        filters['practice']=value
        props.onChangeFilter(filters);
    }

    const onChangeRegionMultiple=(_:any,{value}:any)=>{
        if(selectedPractice.length>value.length){
            selectedRegion.filter(
                x=>!value.includes(x)
            )
        }
        setSelectedRegion(value)
        let filters=props.filters
        filters['region']=value
        props.onChangeFilter(filters);
    }

    const onChangeBrandsMultiple=(_:any,{value}:any)=>{
        if(selectedBrands.length>value.length){
            selectedBrands.filter(
                x=>!value.includes(x)
            )
        }
        setSelectedBrands(value)
        let filters=props.filters
        filters['brands']=value
        props.onChangeFilter(filters);
    }
    const practiceList = props.filters.practice;
    const drType = props.practice.drPractices;
    const brandsInfo =props.practice.udaPracticeInfo;
    const regionsInfo = props.practice.regionPractices;


    let drTypeValue:any = [];
    practiceList && practiceList.length > 0  && practiceList.forEach((item)=>{
    Object.values(drType).forEach((dataItem,key) => {

          let check:any = false;
          if(item){
            check = dataItem.length > 0 && dataItem.filter(data => data === item.toLowerCase());
          }

          if(check && check.length > 0){
            drTypeValue.push(key+1);
        }

      })
    });

    let drListOptionsUpdatedInfo:any = [];
    drListOptions.forEach((info:any)=>{
        drTypeValue.forEach((dataItem:any) => {
            if(info.value == dataItem){
                drListOptionsUpdatedInfo.push(info)
            }
        });
    });

    let regionOptionsUpdatedInfo:any = [];
    practiceList && practiceList.length > 0  && practiceList.forEach((item)=>{
        Object.entries(regionsInfo).forEach(([key, value]) => {
            const info:any = regionsInfo[parseInt(key)];
            const checkInfoRegion = info.length > 0 &&  info.filter((info:any) => info === (item!=null?item.toLowerCase():""));
            if(checkInfoRegion.length > 0){
                props.practice.regionOptions.forEach((dataItem:any) => {
                    if(dataItem.value === parseInt(key)){
                        regionOptionsUpdatedInfo.push(dataItem)
                    }
                });

            }
        });
    });


    let brandTypeValue:any = [];
    practiceList && practiceList.length > 0  && practiceList.forEach((item)=>{
        Object.values(brandsInfo).forEach((dataItem,key) => {
                if(item === dataItem.practiceName ){
                    brandTypeValue.push(dataItem.brand)
                }
        })
    });

    let brandOptionsUpdatedInfo:any = [];
    brands.forEach((info:any)=>{
        brandTypeValue.forEach((dataItem:any) => {
            if(info.value == dataItem){
                brandOptionsUpdatedInfo.push(info)
            }
        });
    });

    drListOptionsUpdatedInfo = drListOptionsUpdatedInfo.filter((v:any,i:any,a:any)=>a.findIndex((v2:any)=>(v2.value===v.value))===i)
    brandOptionsUpdatedInfo = brandOptionsUpdatedInfo.filter((v:any,i:any,a:any)=>a.findIndex((v2:any)=>(v2.value===v.value))===i)
    regionOptionsUpdatedInfo = regionOptionsUpdatedInfo.filter((v:any,i:any,a:any)=>a.findIndex((v2:any)=>(v2.value===v.value))===i)

    const checkInfo:any = getPracticeOptionsList();

    let practicePCRAll =  checkInfo && checkInfo.filter((item1:any) => {
            return data.find((item2:any) => item1.value.toLowerCase() === item2.practice.toLowerCase())
        });

    practicePCRAll.unshift({key: 'none', value: null, text: 'Select Practice'});

    const checkInfo2 = Object.values(reportPageNames).filter((pageName:any) => pageName === props.page);
    return (
        <div className="ui card">
            <div className="content pb0">
                <h2 className="float-left mr10 mb10">{props.heading} <HelpMark pageId="47" appIsDown={{status:props.appIsDown?props.appIsDown.status:false, appsDown:props.appIsDown?props.appIsDown.appsDown:[""]}}/></h2>
                <div className="topFilters">
                    {props.exportCSV && <a className="link" onClick={() => props.exportCSV && props.exportCSV()}><Icon name="file alternate"/>Export</a>}
                    {props.sendEmail && <a className="link" onClick={() => props.sendEmail && props.sendEmail()}><Icon name="send" />Send</a>}
                    { !props.page &&
                        (<Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="month"
                            placeholder="Select Month"
                            selection={true}
                            options={getMonthsOption()}
                            onChange={onChangeFilter}
                            value={props.filters.month}
                        />)
                    }
                    <Dropdown
                        search={true}
                        className='mr10 mb15'
                        name="practice"
                        placeholder="Practice"
                        selection={true}
                        multiple={true}
                        options={checkInfo2 && checkInfo2.length > 0 ? practicePCRAll ? practicePCRAll : data :getPracticeOptionsList()}
                        onChange={onChangePracticeMultiple}
                        value={props.filters.practice}
                    />

                    {props.page !== reportPageNames.practice &&
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="drType"
                            placeholder="Doctor Type"
                            selection={true}
                            multiple={true}
                            options={drTypeValue && drTypeValue.length > 0 ? drListOptionsUpdatedInfo:drListOptions}
                            onChange={onChangeDoctorMultiple}
                            value={props.filters.drType}
                        />
                    }
                    <Dropdown
                        search={true}
                        className='mr10 mb15'
                        name="region"
                        placeholder="Region"
                        selection={true}
                        multiple={true}
                        options={regionOptionsUpdatedInfo.length > 0 ? regionOptionsUpdatedInfo :
                            props.practice.regionOptions}
                        onChange={onChangeRegionMultiple}
                        value={props.filters.region}
                    />
                    <Dropdown
                        search={true}
                        className='mr10 mb15'
                        name="brands"
                        placeholder="Brands"
                        selection={true}
                        multiple={true}
                        options={Object.keys(brandOptionsUpdatedInfo).length > 0 ? brandOptionsUpdatedInfo :brandsOptions()}
                        onChange={onChangeBrandsMultiple}
                        value={props.filters.brands}
                    />
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state: RootState) {
    return {
        practice: state.practice,
        practiceOptions:state.app.practiceOptions

    };
}

export default connect(mapStateToProps)(FilterSection)
