import * as React from 'react';
import {Grid,Loader,Dimmer} from "semantic-ui-react";
import {Report} from '../model/report';
import Moment from 'moment';
import { numberFormat } from './numberFormat';
import PMDUnitItem from './PMDUnitItem';
import { Practice } from '../model/practice';

export interface PMDProductionCPVProps {
  practicename: string,
  report: Report,
  bdppp: bigint,
  actppp: bigint,
  deltappp: any,
  ppp: boolean,
  FV_PT: any,
  FV_CPV: any,
  RC_PT: any,
  RC_CPV: any,
  TX_PT: any,
  TX_CPV: any,
  scheduleToday30DayApts: any,
  scheduleToday30DayCompletd: any,
  schAptToday: any,
  procFeeSum: any,
  practice: Practice
}

export interface PMDProductionCPVState {
  practicename: string,
  bdppp: bigint,
  actppp: bigint,
  deltappp: any,
  ppp: boolean,
  scheduleToday30DayApts: any,
  scheduleToday30DayCompletd: any,
  schAptToday: any,
  procFeeSum: any,
  practice: Practice
}

class PMDProductionCPV extends React.Component<PMDProductionCPVProps,PMDProductionCPVState> {
  budgetData = this.props.report.budgetData;
  bCareData = this.props.report.reportData;

  constructor(props: PMDProductionCPVProps) {
    super(props);
    this.state={
      practicename : props.practicename,
      bdppp: props.bdppp,
      actppp: props.actppp,
      deltappp:props.deltappp,
      ppp:props.ppp,
      scheduleToday30DayApts: props.scheduleToday30DayApts,
      scheduleToday30DayCompletd: props.scheduleToday30DayCompletd,
      schAptToday: props.schAptToday,
      procFeeSum: props.procFeeSum,
      practice: props.practice
    }
    this.setPpp=this.setPpp.bind(this);
    this.setBdppp=this.setBdppp.bind(this);
    this.setActppp=this.setActppp.bind(this);
    this.setDeltappp=this.setDeltappp.bind(this);       
    this.setPracticeName=this.setPracticeName.bind(this);
    Moment.locale('en');
    this.budgetData = this.props.report.budgetData;
    this.bCareData = this.props.report.reportData;
  }

  setPpp(value: boolean) {
    this.setState({
      ppp : value
    })
  }

  setDeltappp(value: any) {
    this.setState({
      deltappp : value
    })
  }

  setBdppp(value: bigint) {
    this.setState({
      bdppp : value
    })
  }

  setActppp(value: bigint) {
    this.setState({
      actppp : value
    })
  }

  setPracticeName(value: string) {
    this.setState({
      practicename : value
    })
  }

  componentWillReceiveProps(nextProps: PMDProductionCPVProps) {
    this.setState({
      practicename: nextProps.practicename,
      bdppp: nextProps.bdppp,
      actppp: nextProps.actppp
    })
  }

  practiceDBName() {
    const practiceData: any = this.props.practice.practiceInfo;
            if (practiceData[this.props.practicename.toLowerCase()]){
              return practiceData[this.props.practicename.toLowerCase()].practiceDBName.toLowerCase();
            }else{
              return this.props.practicename.toLowerCase()
            }     
  }

  getBudget() {
    const FV_CPV:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].FvCPV :0.00:0.00:0.00;
    const FV_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].FvPt :0.00:0.00:0.00;
    const RC_CPV:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].RcCPV :0.00:0.00:0.00;
    const RC_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].RcPt :0.00:0.00:0.00;
    const TX_CPV:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].TxCPV :0.00:0.00:0.00;
    const TX_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].TxPt :0.00:0.00:0.00;
    return (((FV_CPV)*(FV_PT))+((RC_CPV)*(RC_PT))+((TX_CPV)*(TX_PT)));
  };

  getTotalPt() {
    const FV_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].FvPt :0.00:0.00:0.00;
    const RC_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].RcPt :0.00:0.00:0.00;
    const TX_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].TxPt :0.00:0.00:0.00;
    return FV_PT+RC_PT+TX_PT;
  };


  calculateBDPPP() {
    const BDPPP:any = this.getBudget()/this. getTotalPt();
    return BDPPP? BDPPP.toFixed(2) : 0;
  }

  calculateACTPPP() {
    try{
    if(this.props.report.reportData){
      const bCare_perpractice:any  = this.props.report ? this.props.report.reportData? this.props.report.reportData.byPractice? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['bCare'] ? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['bCare'] : 0.00:0.00:0.00:0.00;
      const bCare_totalVisit:any  = this.props.report ? this.props.report.reportData? this.props.report.reportData.byPractice? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['totalVisit'] ? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['totalVisit']  :0.00:0.00:0.00:0.00;
      const bCare_adjustments:any  = this.props.report ? this.props.report.reportData? this.props.report.reportData.byPractice? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['adjustments'] ? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['adjustments']  :0.00:0.00:0.00:0.00;
      var n_bCare_perpractice:any = (isNaN(bCare_perpractice) ? 0 : ((bCare_perpractice*1.1)-bCare_adjustments));
      var n_bCare_totalVisit:any= (isNaN(bCare_totalVisit) ? 0 : bCare_totalVisit);
      const actppp:any = Math.round(parseInt((n_bCare_perpractice))/parseInt(n_bCare_totalVisit)).toFixed(2);
      var n_actppp = (isNaN(actppp) ? 0 : actppp);
      return n_actppp;
    }else{
      return 0.00;
    }
  } catch (ex) {
    return 0.00;
    }
  }

  calculateDeltaPPP() {
    try{
      var deltappp:any = this.calculateACTPPP() - this.calculateBDPPP();
      var n_deltappp:any = (isNaN(deltappp) ? 0 : deltappp.toFixed(2));
      var deltappp_display:string = n_deltappp>=0 ? numberFormat(n_deltappp) : '('+numberFormat(Math.abs(n_deltappp))+')';
      return deltappp_display;
  } catch (ex) {
    var deltappp_display:string = '($0.00)';
    return deltappp_display;
    }
  }

  checkValues(){
    return (!(typeof this.props.report === 'object' && this.props.report !== null &&
    typeof this.props.report.reportData === 'object' && this.props.report.reportData !== null && 
    typeof this.props.report.budgetData === 'object' && this.props.report.budgetData !== null) && <Dimmer active={true}>
     <Loader>Calculating</Loader>
   </Dimmer>)
  }

  getExpectedShow():any {
    let show_rate = this.props.scheduleToday30DayCompletd/this.props.scheduleToday30DayApts;
    return (Math.round(show_rate*this.props.schAptToday));
  };

  getScheduledProduction():any {
    return (Math.round(this.getExpectedShow()*this.calculateACTPPP()));
  };

  render() {    
    var reportdate = this.props.report ? this.props.report.reportData? this.props.report.reportData.byPractice? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['lastDayDate']? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['lastDayDate'] : new Date(): new Date() : new Date() : new Date() : new Date();
    var lwdProd:number = this.props.report ? this.props.report.reportData? this.props.report.reportData.byPractice? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['lastDayBCare']? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['lastDayBCare'] : 0: 0.0 : 0.00 : 0.000 : 0.0000;
    var lwdPtCount:number = this.props.report ? this.props.report.reportData? this.props.report.reportData.byPractice? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['lastDayPtCount']? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['lastDayPtCount'] : 0: 0.0 : 0.00 : 0.000 : 0.0000;
        
    return (
      <Grid columns='two'>
        <Grid.Row>
            <Grid.Column>
            <PMDUnitItem value={numberFormat(this.calculateBDPPP())} description="GL CPV"/>
            </Grid.Column>
            <Grid.Column>
            <PMDUnitItem value={numberFormat(this.calculateACTPPP())} description="ACT CPV"/>
            </Grid.Column>
        </Grid.Row>

        <Grid.Row>
            <Grid.Column>
            <PMDUnitItem value={this.calculateDeltaPPP()} description="Delta CPV"/>
            </Grid.Column>
            <Grid.Column>
            <PMDUnitItem value={numberFormat(this.getScheduledProduction() > 0 ? this.getScheduledProduction() : 0)} description="Scheduled Prod" />
          </Grid.Column>
        </Grid.Row>
    </Grid>
    )
  }
}

export default PMDProductionCPV;