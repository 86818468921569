import moment from "moment";
import React, { ReactElement, useState } from "react";
import { Button, Header, Icon, Modal, Table } from "semantic-ui-react";
import { useActions } from "../../redux/actions";
import * as issuesActions from "../../redux/actions/downTimeTracker";
import CreateNotesForm from "./CreateNote";

type EditCreateNoteFormModalProps = {
    saveForm: any;
    item: any;
    action: any;
    content: any;
    closeForm: any;
};
const EditCreateNoteFormModal = (props: EditCreateNoteFormModalProps) => {
    return (
        <Modal
            closeIcon
            open={true}
            onClose={props.closeForm}
        >
            <Header content={props.content}/>
            <Modal.Content>
                <CreateNotesForm
                    action={props.action}
                    saveForm={props.saveForm}
                    closeForm={props.closeForm}
                    item={props.item}
                />
            </Modal.Content>
        </Modal>
    )
}

const ViewNotes = (props: any): ReactElement => {
    const issuesAction = useActions(issuesActions);
    const [open, setOpen] = useState(false);
    const [editNote, setEditNote] = useState(false);
    const [createNote, setCreateNote] = useState(false);
    const [logs, setLogs] = useState([]);
    const loadLogs = async () => {
        const logs = await issuesAction.getNotes(props.item.id);
        setLogs(logs);
        setOpen(true)
    }

    const closeForm = () => {
        if (editNote) {
            setEditNote(false)
        }
        if (createNote) {
            setCreateNote(false)
        }
    }

    const submitForm = async (payload: any, action: any) => {
        payload.downTimeIssueId=props.item.id;
        await issuesAction.saveNote(payload, action);
        await loadLogs();
    }

    return (
        <>
            <Modal
                closeIcon
                trigger={<Button style={{padding: '20px 15px'}} secondary={true}> <Icon name="book"/> Notes</Button>}
                onClose={() => setOpen(false)}
                onOpen={loadLogs}
                open={open}
            >
                <Modal.Header>
                    <h2 className="text-center"><span className="text-uppercase">{props.item.name} </span>Notes</h2>
                    <Button className="float-right"
                                primary={true} onClick={() => setCreateNote(true)}>New Note</Button>
                </Modal.Header>
                <Modal.Content scrolling={true}>
                    <Table basic={true}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell content="Description"/>
                                <Table.HeaderCell content="User"/>
                                <Table.HeaderCell content="TimeStamp"/>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                logs.map((log: any, key) => {
                                    return <Table.Row key={'log_' + key}>
                                        <Table.Cell content={log.description}/>
                                        <Table.Cell content={log.user}/>
                                        <Table.Cell content={moment(log.createdAt).format('DD MMM YYYY :HH:mm')}/>
                                    </Table.Row>
                                })
                            }

                            {
                                !logs || !logs.length && <Table.Row>
                                    <Table.Cell>
                                        <p>No Notes Found</p>
                                    </Table.Cell>
                                </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                    {editNote && (<EditCreateNoteFormModal
                content="Edit Note"
                action={editNote ? "editForm" : ""}
                saveForm={submitForm}
                closeForm={closeForm}
                item={{}}
            />)}
            {createNote && (<EditCreateNoteFormModal
                content="Create Note"
                action={createNote ? "createForm" : ""}
                saveForm={submitForm}
                closeForm={closeForm}
                item={{}}
            />)}
                </Modal.Content>
                <Modal.Actions>                
                    <Button secondary={true} onClick={() => setOpen(false)}>Close</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
        ;
};
export default ViewNotes;
