import React, {useEffect, useState} from 'react';
import {useActions} from "../redux/actions";
import * as Actions from "../redux/actions/partners";
import * as reportActions from "../redux/actions/report";
import { myPartners, cardAppsMenu, cardPagesMenu, myCDPApplicationList } from '../constants';
import ResponsiveCardMenu from './ResponsiveCardMenu';
import HelpMark from "./HelpMark";
import * as issuesActions from "../redux/actions/downTimeTracker";

function DentalPartners() {
    const [partnerActions, reportAction] = useActions([Actions, reportActions]);
    const [partners, setPartners] = useState({sites: '', schedule: '', alerts: '',recruit:'',leads:'',dentist:'',
        credentialing:'',call_center: "",tools:"", scheduler: "",billing:'',tab32Queries:'',APTOOD:''});
    const [favUsage, setFavUsage] = useState([]);
    const [state, setStates] = useState({appIsDown:false});
    const [cardPagesMenuFilter, setCardPagesMenuFilter] = useState(cardPagesMenu);
    const [cardAppsMenuFilter, setCardAppsMenuFilter] = useState(cardAppsMenu);
    const issuesAction = useActions(issuesActions);

    useEffect(() => {
        checkIssues()
        partnerActions.getDentalPartners().then((res: any) => {
            res&& setPartners(res.partners_uri);
        });
        partnerActions.getMyFavourite().then((res: any) => {
            const icons: any = cardAppsMenu.map(a => ({...a}));
            const iconArr = icons.concat(cardPagesMenu);
            const names=res && Array.isArray(res)?res.map((r:any)=>r.partnerName):[];
            const pagesFiler :any = iconArr.filter( (o: { name: any; }) => names.includes(o.name) ) ;
            setFavUsage(pagesFiler);
        });
        reportAction.resetVal();
    }, []);

    const checkIssues = async() => {
		const result: any = await issuesAction.fetchOpenIssuesList(myCDPApplicationList);
        setStates({
            ...state,
            appIsDown: result.length > 0
        });
	}

    const addToFav=(item:any)=>{
        const favUsagearr:any=[...favUsage]
        const names=favUsagearr.map((r:any)=>r.name);
        if(!names.includes(item.name)){
            favUsagearr.push(item);
            partnerActions.saveMyFavourite(item.name);
            setFavUsage(favUsagearr);
        }
    }

    const removeFav=(item:any)=>{
        const favUsagearr=favUsage.filter((r:any)=>r.name!=item.name);
        partnerActions.removeMyFavourite(item.name);
        setFavUsage(favUsagearr);
    }


    const removeFavorite=()=>{
        // This is intentional
    }
    const addToFavourite=()=>{
        // This is intentional
    }

    const savePartnerUsage = (name: string) => {
        partnerActions.saveDentalPartnerUsage(name);
    };

    const redirectToCDP = (name: string) => {
        if (name.includes('sites')) {
            partnerActions.saveDentalPartnerUsage(myPartners.SITES);
            window.location.href = partners.sites.valueOf();
        } else if (name.includes('alerts')) {
            partnerActions.saveDentalPartnerUsage(myPartners.ALERTS);
            window.location.href = partners.alerts.valueOf();
        } else if (name === 'schedule') {
            partnerActions.saveDentalPartnerUsage(myPartners.SCHEDULE);
            window.location.href = partners.schedule.valueOf();
        } else if (name.includes('recruit')) {
            partnerActions.saveDentalPartnerUsage(myPartners.RECRUIT);
            window.location.href = partners.recruit.valueOf();
        } else if (name.includes('leads')) {
            partnerActions.saveDentalPartnerUsage(myPartners.LEADS);
            window.location.href = partners.leads.valueOf();
        } else if (name.includes('dentist')) {
            partnerActions.saveDentalPartnerUsage(myPartners.DENTIST);
            window.location.href = partners.dentist.valueOf();
        } else if (name.includes('credentialing')) {
            window.location.href = partners.credentialing.valueOf();
        } else if (name.includes('call_center')) {
            partnerActions.saveDentalPartnerUsage(myPartners.CALL_CENTER);
            window.location.href = partners.call_center.valueOf();
        } else if (name.includes('tools')) {
            partnerActions.saveDentalPartnerUsage(myPartners.DCRM);
            window.location.href = partners.tools.valueOf();
        } else if (name === 'scheduler') {
            partnerActions.saveDentalPartnerUsage(myPartners.SCHEDULER);
            window.location.href = partners.scheduler.valueOf();
        } else if (name === 'billing') {
            partnerActions.saveDentalPartnerUsage(myPartners.CLAIMS_TEAM);
            window.location.href = partners.billing.valueOf();
        } else if (name === 'members') {
            partnerActions.saveDentalPartnerUsage(myPartners.MEMBERS);
            window.location.href = partners.call_center.valueOf() + "&page=members";
        } else if (name === 'patient finance') {
            window.location.href = partners.billing.valueOf();
        } else if (name === 'tab32Queries') {
            partnerActions.saveDentalPartnerUsage(myPartners.TAB32_QUERIES);
            window.location.href = partners.tab32Queries.valueOf();
        } else if (name === 'APTOOD') {
            partnerActions.saveDentalPartnerUsage(myPartners.APTOOD);
            window.location.href = partners.APTOOD.valueOf();
        } else {
            window.location.href = name;
        }
    };

     const handleChange = (e: any) => {
      if(e.target.value) {
        const appsFiler :any = cardAppsMenu && cardAppsMenu.filter(o => o.name.toLowerCase().includes(e.target.value.toLowerCase()) );
        setCardAppsMenuFilter(appsFiler);
        //@ts-ignore
        const pagesFiler :any = cardPagesMenu && cardPagesMenu.filter(o => o.name.toLowerCase().includes(e.target.value.toLowerCase()) );
        setCardPagesMenuFilter(pagesFiler);
      } else {
        setCardAppsMenuFilter(cardAppsMenu);
        setCardPagesMenuFilter(cardPagesMenu);
      }
    }
    const notGrey="star-not-grey";
     const grey="star-grey";
    return (
        <div className="landing">
            <div className="ui fluid card">
                <div className="content">
                    <h2 className="float-left mr10 mb10">Welcome to Community Dental Partners <HelpMark pageId='20' appIsDown={{status:state.appIsDown, appsDown:myCDPApplicationList}} /></h2>
                    <div className="search-bar float-right">
                        <section className="section">
                          <div>
                            <div className="ui icon input">
                              <input type="text" className="form-control" placeholder="Search Menu Option..." onChange={handleChange} />
                              <i className="inverted search link icon"></i>
                            </div>
                          </div>
                        </section>
                    </div>
                </div>
            </div>

          <h3 className="mt0 mb20">My Favorites</h3>
          <ResponsiveCardMenu iconHoder={notGrey} fields={favUsage} addToFav={addToFavourite} removeFav={removeFav} savePartnerUsageAction={savePartnerUsage} redirectToCDPAction={redirectToCDP}/>

          <h3 className="mt20 mb20">My Apps</h3>
          <ResponsiveCardMenu iconHoder={grey} fields={cardAppsMenuFilter} addToFav={addToFav} removeFav={removeFavorite} savePartnerUsageAction={savePartnerUsage} redirectToCDPAction={redirectToCDP}/>

          <h3 className="mt20 mb20">My Reports </h3>
          <ResponsiveCardMenu  iconHoder={grey} fields={cardPagesMenuFilter} addToFav={addToFav} removeFav={removeFavorite} savePartnerUsageAction={savePartnerUsage} redirectToCDPAction={redirectToCDP}/>

    </div>
    )
}

export default DentalPartners;