import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../model';
import { Account } from '../model/user';
import { Practice } from '../model/practice';
import { useActions } from '../redux/actions';
import * as UKGActions from '../redux/actions/UKGServices';
import Template from '../components/Template';
import { Tree } from 'react-d3-tree';
import 'react-tree-graph/dist/style.css';

interface Props {
  user: Account;
  practice: Practice;
}

function OrganizationalChart(props: Props) {
  const UKGAction = useActions(UKGActions);
  const emptyChart = {
    name: 'Employee managers not found',
    attributes: {
      Position: '',
      Location: ''
    },
    children: [
      {
        name: 'no data for employees',
        attributes: {
          Position: '',
          Location: ''
        },
        children: []
      }
    ]
  };
  const [chartData, setChartData] = useState<any>(emptyChart);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const { user } = props;
    const userDetails = user.firstname + ' ' + user.lastname;
    const response = await UKGAction.getEmployee(userDetails);
  
    const data = response?.data;
  
    const chart = data && data.chart && Object.keys(data.chart).length !== 0 ? data.chart : emptyChart;
    setChartData(chart);
  };

  return (
    <Template activeLink="monday app">
      <div style={{ height: '500px', width: '100%' }}>
        <Tree
          data={chartData}
          orientation="vertical"
          translate={{
            x: 800,
            y: 100
          }}
          nodeSize={{ x: 150, y: 200 }}
          transitionDuration={500}
        />
      </div>
    </Template>
  );
}

function mapStateToProps(state: RootState) {
  return {
    user: state.auth.user,
    practice: state.practice
  };
}

export default connect(mapStateToProps)(OrganizationalChart);