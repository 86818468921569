import {Five9ListProps} from "../../model/five9List";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";
const initialState:Five9ListProps = {
	callCenterPractice: [],
	practiceName:[],
	practiceOptions:[],
	five9Lists:[],
	five9PracticeList:[],
	five9ListOptions:[],
	five9counts:{}

};
export const five9List = createReducer(initialState, {
	[ActionType.FETCH_CALL_CENTER_PRACTICES](state: Five9ListProps, action: Action<Five9ListProps>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_FIVE9_LIST](state: Five9ListProps, action: Action<Five9ListProps>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_FIVE9_PRACTICE_LIST](state: Five9ListProps, action: Action<Five9ListProps>) {
		return Object.assign({...state}, action.payload);
	},
    [ActionType.FETCH_CLINICAL_SCHEDULE](state: Five9ListProps, action: Action<Five9ListProps>) {
        return Object.assign({...state}, action.payload);
    }
});
