import React, { useEffect, useState } from 'react';
import { AccordionTitle, AccordionContent, Icon, Grid, GridRow, GridColumn, Loader, Progress } from 'semantic-ui-react'
import { useActions } from '../../../redux/actions';
import DentalXChangeTable from './DentalXChangeTable';
import * as PracticesActions from '../../../redux/actions/practice';
import * as HealthStatusActions from "../../../redux/actions/healthStatus";
import ProgressBar from '../ProgressBar';

interface props {
    data: any,
    loading: boolean
}

function DentalXChangeHealth({ data, loading }: props) {
    const initialState = {
        loading: false, panel: '', practices: [] as any, records: [] as any, counts: {
            green: 0,
            red: 0,
            yellow: 0,
            null: 0
        }
    };
    const [state, setState] = useState(initialState);
    const healthStatusActions = useActions(HealthStatusActions);
    const practicesActions = useActions(PracticesActions);
    const { panel, records } = state;
    const prefix = 'dentalXChange'

    useEffect(() => { getData() }, [data]);


    const getData = async () => {
        const practices = await practicesActions.getDentaHubPractices();
        const info = data.records.filter((o: any) => o.brand === 'SMD' || o.brand === 'FSD');
        const count = getPercentage(info);
        healthStatusActions.addToCount({ success: count.green, failed: count.red, pending: count.null + count.yellow }).then();
        setState({ ...state, records: info, practices, counts: count });
    }

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }

    const getPercentage = (data: any) => {
        const properties = [
            "uhc_dentalXChange",
            "denta_dentalXChange",
            "mcna_dentalXChange",
            "tmhp_dentalXChange",
            "pop_dentalXChange"
        ];

        const counts = {
            green: 0,
            red: 0,
            yellow: 0,
            null: 0
        };

        data.forEach((item: any) => {
            properties.forEach(property => {
                const value = item[property];
                if (value === "green") {
                    counts.green++;
                } else if (value === "red") {
                    counts.red++;
                } else if (value === "yellow") {
                    counts.yellow++;
                } else if (value === null) {
                    counts.null++;
                }
            });
        });
        return counts;
    }


    return (
        <>
            <AccordionTitle active={panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Insurance (DentalXChange)</span>
                            {loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar success={state.counts.green} pending={state.counts.null + state.counts.yellow} failed={state.counts.red} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === prefix}>
                {!loading && <DentalXChangeTable data={records} />}
            </AccordionContent>
        </>
    );
}

export default DentalXChangeHealth;