import React, {useEffect, useState} from "react";
import Template from "./Template";
import * as five9Action from "../redux/actions/five9List";
import {useActions} from "../redux/actions";
import {Five9Lists, Five9, Five9Details, PracticeList, Five9ListDetails} from "../model/five9List";
import {RootState} from "../model";
import {connect} from "react-redux";
import {Button, Icon, DropdownItemProps} from "semantic-ui-react";
import DynamicHtmlTable from "./DynamicHtmlTable";
import AddFive9List from "./AddFive9List";
import PopUpModal from "./PopUpModal";
import HelpMark from "./HelpMark";


function Five9List(props: Five9Lists) {
    const five9ListAction = useActions(five9Action);
    const initialState: Five9 = {
        TableData: [[{title: 'No Records Found'}]],
        TableHead: [{title: ''}]
    };
    const initialFormState:Five9Details ={practice: '', five9ListName: '', countLimit: 0, leadStatus: '', priority: ''};
    const [state, setStates] = useState(initialState);
    const [formState, setFormStates] = useState({showForm:false, id:0});
    const [initialValue, setFormInitialStates] = useState(initialFormState);
    const [popUpState, setPopUpState]= useState({showPopUp:false, five9ListName:''});
    const [deletePopUpState, setDeletePopUpState]= useState({showPopUp:false, id:0});

    useEffect(() => {
       getPracticeDetails();
    }, []);

    const getPracticeDetails=()=>{
        five9ListAction.fetchCallCenterPractices(refinePracticeArray);
        five9ListAction.getFive9PracticeList(refineFive9ListArray)
            .then((five9Counts:{}) => {
                getFive9Details(five9Counts);
            });
    };

    const getFive9Details = (five9Counts:{[key:string]: number}) => {
        five9ListAction.fetchFive9List().then((res:[]) => {
            if(res && res.length) {
                const TableData = pushTableData(res, five9Counts);
                const TableHead = TableHeadData();
                setStates({...state, TableData, TableHead});
            }
        });
    };

    const refineFive9ListArray = (five9List: Five9ListDetails[]) => {
        let five9ListOptions:DropdownItemProps = [];
        let five9counts:{[key:string]: number} ={};
        if (five9List.length) {
            five9List.forEach((item) => {
                five9ListOptions.push({text: item.name, value: item.name, key: item.name});
                five9counts[item.name]=item.size;
            });
        }
        return {five9ListOptions, five9counts};
    };

    const refinePracticeArray = (response:PracticeList[]) => {
        let practiceNames: Array<string> = [];
        let practiceOptions:DropdownItemProps = [];
        if (response) {
            response.forEach((item) => {
                practiceOptions.push({text: item.practiceName, value: item.practiceName, key: item.id.toString()});
                practiceNames.push(item.practiceName);
            })
        }
        return {practiceNames: practiceNames, practiceOptions: practiceOptions}
    };

    const onCancel = ()=>{
        setFormStates( {showForm:false, id:0});
        setFormInitialStates(initialFormState);
    };

    const saveFive9Details = (values:Five9Details)=>{
        if(formState.id) {
            values.id=formState.id;
            five9ListAction.updateFive9Details(values).then((res:[])=> {
                getFive9Details(props.five9List.five9counts);
            });
        } else {
            five9ListAction.saveFive9Details(values).then((res:[])=>{
                getFive9Details(props.five9List.five9counts);
            });
        }
    };

    const purgeList = (five9ListName:string, showPopUp:boolean)=>{
        setPopUpState({showPopUp:true, five9ListName:five9ListName});
    };

    const editList = (item:Five9Details)=>{
        setFormStates({showForm:true, id:item.id||0});
        const leadStatus= typeof item.leadStatus === 'string'? item.leadStatus.split(','): item.leadStatus;
        const priority= typeof item.priority === 'string'? item.priority.split(','):item.priority;
        setFormInitialStates({practice: item.practice, five9ListName: item.five9ListName, countLimit: item.countLimit, leadStatus: leadStatus, priority: priority})
    };

    const importList = async(item:Five9Details) =>{
        const five9Details = {practice:item.practice, five9ListName: item.five9ListName, countLimit: item.countLimit, leadStatus:item.leadStatus, priority:item.priority};
        await five9ListAction.importDataInFive9List(five9Details);
        getPracticeDetails();
    };

    const deleteList = (id:number)=>{
        setDeletePopUpState({showPopUp:true, id:id});
    };
    const purgePopUpHandler = ()=>{
        five9ListAction.purgeFive9Details({five9ListName:popUpState.five9ListName}).then((res:[])=>{
            getPracticeDetails();
        });
        setPopUpState({showPopUp:false, five9ListName:''});
    };
    const deletePopUpHandler = ()=>{
        five9ListAction.deleteFive9Details({id:deletePopUpState.id}).then((res:[])=> {
            getFive9Details(props.five9List.five9counts);
        });
        setDeletePopUpState({showPopUp:false, id:0});
    };

    const TableHeadData = () => {
        const TableHead = new Array();
        TableHead[0] = {title: 'Practice'};
        TableHead[1] = {title: 'Five9Lists Lists'};
        TableHead[2] = {title: 'Lead Id Count'};
        TableHead[3] = {title: 'Limit Record Count'};
        TableHead[4] = {title: 'Lead Status'};
        TableHead[5] = {title: 'Priority'};
        TableHead[6] = {title: 'Aging Records'};
        TableHead[7] = {title: 'Purge'};
        TableHead[8] = {title: ''};
        TableHead[9] = {title: 'Attempts'};
        TableHead[10] = {title: ''};
        return TableHead;
    };

    const pushTableData = (five9Details:[], five9Counts:{[key:string]: number}) => {
        const TableData = new Array();
            five9Details.forEach((item: any) => {
                const leadCount= five9Counts[item.five9ListName];
                const purgeButton=<Button primary onClick={()=>purgeList(item.five9ListName, true)}>Purge List</Button>;
                const bulkImportButton=<Button primary onClick={()=>importList(item)}>Import</Button>;
                const editButton=<><Icon onClick={()=>editList(item)} className='mr10' name='edit' /><Icon onClick={()=>deleteList(item.id)} name='delete' /></>;
                TableData.push([
                    {title: item.practice},
                    {title: item.five9ListName},
                    {title: leadCount},
                    {title: item.countLimit},
                    {title: item.leadStatus},
                    {title: item.priority},
                    {title: ''},
                    {title: purgeButton},
                    {title: bulkImportButton},
                    {title: ''},
                    {title: editButton}
                ])
            });
        return TableData;
    };
    return (
        <Template activeLink='Five9List'>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10"> Five9 List <HelpMark pageId="43"/> </h2>
                    <p/>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    { !formState.showForm &&
                        <Button primary onClick={() => setFormStates({...formState, showForm:true})}>Add New</Button>
                    }
                    {(props.five9List.practiceOptions && props.five9List.practiceOptions.length && formState.showForm) &&
                        <AddFive9List practices={props.five9List.practiceOptions} five9List={props.five9List.five9ListOptions} initialValue={initialValue}
                                      onCancel={onCancel} saveFive9Details={saveFive9Details}/>
                    }
                </div>
            </div>
            <div className="table-adjAcknowledgement">

            <DynamicHtmlTable
                key={0}
                className={'five9'}
                tableHead={state.TableHead}
                heading={""}
                tableData={state.TableData}
            />
            </div>
            { popUpState.showPopUp &&
                <PopUpModal headerText="Purge Five9 List" message="Are you sure you want to purge five9 list?" size='mini' hidePopUpModal={()=>setPopUpState({showPopUp:false, five9ListName:''})} deletePopUpHandler={purgePopUpHandler} showLabel={true} action="delete"/>
            }
            { deletePopUpState.showPopUp &&
                <PopUpModal headerText="Delete Selected Record" message="Are you sure you want to delete?" size='mini' hidePopUpModal={()=>setDeletePopUpState({showPopUp:false, id:0})} deletePopUpHandler={deletePopUpHandler} showLabel={true} action='delete'/>
            }
        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        five9List: state.five9List
    };
}
export default connect(mapStateToProps)(Five9List);

