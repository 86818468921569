import * as React from 'react';
import {Button, Icon} from 'semantic-ui-react';

export interface ResponsiveCardMenuProps {
  fields: any[],
  addToFav (item: any) : void,
  savePartnerUsageAction(name: any) : void,
  redirectToCDPAction(name: any) : void,
  removeFav  (name: any) : void,
  iconHoder:any,
}

export interface ResponsiveCardMenuState {
  fields: string[]
}

class ResponsiveCardMenu extends React.Component<ResponsiveCardMenuProps,ResponsiveCardMenuState> {
  constructor(props: ResponsiveCardMenuProps) {
    super(props);
    this.state={
      fields: []
    }
  }

  componentDidMount() {
    this.setState({
      fields: this.props.fields

    })
  }

  render() {
    const { fields, addToFav, removeFav,iconHoder} = this.props;
     const responsiveMenu = fields && fields.map((card) =>
      <div className="card" key={card.key}>
          <Icon name="star" className={iconHoder} onClick={() => {
              addToFav(card);
              removeFav(card);
          }}/>
              <Button
                as={card.button.as}
                to={card.button.to}
                href={card.button.href}
                target={card.button.target}
                onClick={() => {
                    this.props.savePartnerUsageAction(card.button.savePartnerUsage);
                    if (card.redirect) {
                        if(card.name==="Tab32"){
                          window.open(card.button.redirect,'_blank','noopener,noreferrer')
                        }else{
                          this.props.redirectToCDPAction(card.button.redirect)
                        }
                    }
                }
                }>
                <div className="innerContent">
                <div className={card.button.grey ? 'iconHoldergrey' : 'iconHolder'}>
                    { card.customIcon ? <i aria-hidden="true" className={`${card.customIcon}  icon`}></i> :  <Icon name={card.icon} />}
                  </div>
                  {card.name}

                  {card.subName &&<div className="sub-name">({card.subName})</div>}
                </div>
              </Button>
          </div>
  );

  return  (
    <>
     <div className="ui nine doubling cards appIcons">
       {responsiveMenu}
     </div>
      {fields && fields.length === 0 && <p>No Records Found</p>}
    </>
  )
  }
}

export default ResponsiveCardMenu;