import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";




export const fetchTableResult=()=>async(dispatch:Function, getState:Function,api:any) => {
      const url = `educator/notification`
      dispatch({ type: ActionType.LOADER, payload: true });
      
      try {
          const resp=await api.get(url)
          dispatch({type:ActionType.LOAD_EDUCATOR_NOTIFICATION,payload:{notifications:resp}})
          dispatch({ type: ActionType.LOADER, payload: false });
          return resp;
      }catch(err){
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
      }
}


export const deleteRecord=(id:number) =>async(dispatch:Function, getState:Function,api:any) => {
      const url = `educator/notification/${id}`
      dispatch({ type: ActionType.LOADER, payload: true });
      try {
          const resp=await api.delete(url)
          const educatorNotification=getState().educatorNotification.notifications
          const filteredNotifications=educatorNotification.filter((elem:any)=>elem.id!=id)
          dispatch({type:ActionType.LOAD_EDUCATOR_NOTIFICATION,payload:{notifications:filteredNotifications}})
          dispatch({ type: ActionType.LOADER, payload: false });
          return filteredNotifications;
      }catch(err){
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
      }
  }

export const saveForm=(postObj:any, action:any) => async(dispatch:Function, getState:Function,api:any) => {
        dispatch({ type: ActionType.LOADER, payload: true });
        try {
            const resp=await api.post(`educator/notification`,postObj)
            dispatch({type:ActionType.LOAD_EDUCATOR_NOTIFICATION_SAVE, payload: { createForm: false }})
            dispatch(fetchTableResult())
            dispatch({ type: ActionType.LOADER, payload: false });
            return resp;
        } catch(err) {
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(err));
        }
}
