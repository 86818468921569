import React, {useEffect, useState} from "react";
import Template from "./Template";
import * as reportActions from "../redux/actions/report";
import {useActions} from "../redux/actions";
import {RootState} from "../model";
import {connect} from "react-redux";
import DynamicHtmlTable from "./DynamicHtmlTable";
import {UsageData} from "../model/report";
import _ from "lodash";
import {allowDeleteUserId, brands} from '../constants' ;
import {Button, Checkbox, Dropdown, Input} from "semantic-ui-react";
import {User} from "../model/user";
import {exportUsagePTVReport} from "./ExportCsv";
import {Patients} from "../model/patients";
import {Practice} from "../model/practice";
import * as practiceActions from "../redux/actions/practice";
import {SetupFields} from "../enums/userSetupField";
import {SetupAppName} from "../enums/userSetupAppName";
import * as userSetupActions from "../redux/actions/userSettings";
import HelpMark from "./HelpMark";
import {refinePracticeArray} from "../utils/common";

interface Props {
    auth: User,
    patients: Patients,
    practice: Practice,
}

function UsageReportPTV(props: Props) {
    const reportAction = useActions(reportActions);
    const practiceAction = useActions(practiceActions);
    const userSetup = useActions(userSetupActions);
    const initialState: UsageData = {
        locations: [],
        practices: [],
        direction: 'sort',
        TableData: [[{title: 'No Records Found'}]],
        TableHead: [{title: ''}],
        actualData: [],
        showHidden: false,
        column: -1,
        searchKeyword: '',
    };
    let timeout: any = 0;
    const [state, setStates] = useState(initialState);
    const user = props.auth.user;
    const allowDelete = (user && allowDeleteUserId.indexOf(user.id) >= 0)
    const info = props.patients.defaultPractice;
    const [selectedBrands, setSelectedBrands] = useState([])
    useEffect(() => {
        if (!props.practice.practiceNames.length) {
            practiceAction.fetchPractices(refinePracticeArray);
        }
        if (!props.patients.defaultPractice) {
            let userParams = {field: SetupFields.Practice, appId: SetupAppName.CDP_MY};
            userSetup.getSetup(userParams)
        }
        getUsageData(info);

    }, [props]);

    const getUsageData = (info: any) => {
        reportAction.fetchUsagePTV()
            .then((res: any) => {
                const actualData = res;
                const TableData = pushTableData(res, state.showHidden, info);
                const TableHead = TableHeadData();
                setStates({...state, TableHead, TableData, actualData});
            });
    }
    const TableHeadData = () => {
        const TableHead = new Array();
        TableHead[0] = {title: 'Hide'};
        TableHead[1] = {title: 'NAME'};
        TableHead[2] = {title: 'EMAIL'};
        TableHead[3] = {title: 'PRACTICE'};
        TableHead[4] = {title: 'LAST LOGIN DATE'};
        TableHead[5] = {title: 'TOTAL ACCESS'};
        TableHead[6] = {title: 'MOBILE COUNT'};
        TableHead[7] = {title: 'DESKTOP COUNT'};
        TableHead[8] = {title: 'RECENT MOBILE OS'};
        return TableHead;
    };

    const handleCheckBox = (hideUsage: any, userId: number) => {
        reportAction.hideUsagePTV(userId, +hideUsage)
            .then((res: any) => {
                getUsageData(info);
            });
    };


    const onSearchChange = (e: any) => {
        const searchKeyword = e.target.value;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            const data = state.actualData && state.actualData.filter((element: any) => {
                if (element.firstName !== null) {
                    return element.firstName.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0;
                }
                if (element.lastName !== null) {
                    return element.lastName.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0;
                }
            });
            const TableData = pushTableData(data, state.showHidden, info);
            setStates({...state, searchKeyword: searchKeyword, TableData: TableData});
            clearTimeout(timeout);
        }, 500);
    };
    const onChangeLocation = (e: any, element: any) => {
        const values = element.value;
        let practiceNames: any = props.practice.practiceNames;
        let locations = values;
        let practices = values
        if (values.length >= 0 && values.indexOf('all') === values.length - 1) {
            practiceNames.push('all')
            locations = practiceNames;
            practices = [];
            setSelectedBrands([])
        } else if (values.length > 1 && values.indexOf('all') === 0) {
            values.splice(0, 1);
            locations = values;
            practices = values;
        }
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            let data = state.actualData && state.actualData.filter((element: any) => {
                for (let location of locations) {
                    if (element.practices.toLowerCase().indexOf(location.toLowerCase()) >= 0) return true;
                }
            });
            const TableData = pushTableData(data, state.showHidden, info);
            setStates({...state, practices: practices, TableData: TableData});
            clearTimeout(timeout);
        }, 500);
    };
    const onChangeBrandsMultiple = (e: any, {value}: any) => {
        const practiceNamesMap: any = props.practice.practiceNamesMap;
        let practices: any = [];

        if (selectedBrands.length > value.length) {
            selectedBrands.filter(
                x => !value.includes(x)
            )
        }
        setSelectedBrands(value)
        practices.value = Object.values(practiceNamesMap).filter((prac: any) => value.includes(prac.brand)).map((prac: any) => prac.practice)

        onChangeLocation(e, practices)
    }

    const pushTableData = (res: any, showHidden: any = false, info: any) => {
        const TableData = new Array();

        const test = info && info.userId ? info.userId : user.id;

        const result = res && res.filter((data: any) => data.userId === test);
        let fetchPracticeUser = result[0] && result[0].practices ? result[0].practices : 'all';
        let finalRes: any = fetchPracticeUser.split(',');
        let returnArray: any = [];
        finalRes.forEach((data: any, key: any) => {
            const checkInfo: any = res.filter((item: any) => item.practices !== null && item.practices.indexOf(data) !== -1);
            returnArray.push(checkInfo)
        });

        let combined: any = [];
        returnArray.forEach((data: any, key: any) => {
            const info = returnArray[key];
            combined = combined.concat(info);
        });
        combined = combined.filter((value: any, index: any, self: any) => self.indexOf(value) === index);
        const finalInfo = fetchPracticeUser !== "all" ? combined : res;
        finalInfo && finalInfo.map((item: any) => {
            if (item.hideUsage && !showHidden) {
                return true;
            }
            TableData.push([
                {
                    title: <Checkbox
                        onChange={(e, {checked}) => handleCheckBox(checked, item.userId)}
                        checked={!!item.hideUsage}
                    />
                },
                {title: item.firstName + ' ' + item.lastName},
                {title: item.email},
                {title: item.practices ? item.practices : 'all'},
                {title: item.Date},
                {title: item.total},
                {title: item.Cell},
                {title: item.Desktop},
                {title: item.deviceOS},
            ])
        })
        return TableData;
    };

    const showHiddenReports = (showHidden: any) => {
        const TableData = pushTableData(state.actualData, showHidden, info);
        setStates({...state, TableData, showHidden: showHidden});
    }

    const flushData = () => {
        if (window.confirm("Are you sure you want to clear all usage data? Action will not undo.")) {
            const {id} = user;
            reportAction.flushUsageDataPTV(id)
                .then((res: any) => {
                    getUsageData(info);
                });
        }
    };

    const handleSort = (clickedColumn: number) => () => {
        let {column, TableData, direction} = state;
        if (column !== clickedColumn) {
            column = clickedColumn;
            TableData = _.sortBy(TableData, o => o[clickedColumn].title);
            direction = 'sort ascending';
            setStates({...state, TableData: TableData.slice(), column, direction});
            return;
        }
        TableData = TableData.reverse();
        direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
        setStates({...state, TableData: TableData.slice(), direction});
    };

    const downloadUsage = () => {
        let header = TableHeadData();
        header.splice(0, 1);
        let data: (string | number)[][] = [];
        state.TableData.forEach((itemArray) => {
            itemArray.splice(0, 1);
            let rows: (string | number)[] = itemArray.map((item) => {
                let title = item.title;
                if (typeof item.title === 'string' && item.title.includes(',')) {
                    title = item.title.replace(',', ';')
                }
                return title;
            });
            data.push(rows);
        })
        exportUsagePTVReport({header, data: data})
    }


    return (
        <Template activeLink='usage-report'>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10"> Usage Report Patient Validation
                        ({state.TableData.length === 1 && state.TableData[0][0]['title'] === 'No Records Found' ? 0 : state.TableData.length}) <HelpMark
                            pageId='0'/></h2>
                    <div className="topFilters">
                        {allowDelete &&
                            <Checkbox
                                label={'Show Hidden Reports'}
                                className='mt10 mr10'
                                onChange={(e, {checked}) => showHiddenReports(checked)}
                                checked={state.showHidden}
                            />
                        }
                      <Button primary={true} onClick={downloadUsage}>Download</Button>
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="locations"
                            multiple={true}
                            placeholder="Practice"
                            selection={true}
                            options={props.practice.practiceOptions}
                            onChange={onChangeLocation}
                            value={state.practices ? state.practices : []}
                        />
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="brands"
                            placeholder="Brands"
                            selection={true}
                            multiple={true}
                            options={brands}
                            onChange={onChangeBrandsMultiple}
                            value={selectedBrands}
                        />

                        {allowDelete && <Button primary={true} onClick={flushData}>Flush Data</Button>}
                        <Input className='mb10' icon='search' placeholder="Search" onChange={onSearchChange}/>
                    </div>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    <DynamicHtmlTable
                        key={0}
                        className={'adjustment mb20'}
                        tableHead={state.TableHead}
                        heading={""}
                        tableData={state.TableData}
                        onHeadClick={handleSort}
                        sortedColumn={state.column}
                        direction={state.direction}
                    />
                </div>
            </div>
        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
        patients: state.patients,
        practice: state.practice,
    };
}

export default connect(mapStateToProps)(UsageReportPTV);

