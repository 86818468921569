import moment from "moment";
import React, { FC, ReactElement, useEffect, useState } from "react";
import {Dimmer, Header, Icon, Loader, Modal, Table} from "semantic-ui-react";
import { useActions } from "../../redux/actions";
import * as issuesActions from "../../redux/actions/downTimeTracker";
import _ from 'lodash'
import customMoment from 'moment';

type DownTimeIssuesPopUpProps = {
    applicationList:string[],
    hideModal : any
};

const DownTimeIssuesPopUp: FC<DownTimeIssuesPopUpProps> = ({
    applicationList,
    hideModal
}): ReactElement => {
    const issuesAction = useActions(issuesActions);
    
    const [state, setState] = useState({
        issuesList: [],
        data: {},
        loading:true,
        selected: null,
        orderBy: '',
        order: true
    });

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const result: any = await issuesAction.fetchOpenIssuesList(applicationList);
        const data: object = groupData(result)

        setState({
            ...state,
            issuesList: result,
            data: data,
            selected: getFirstIssue(data),
            loading: false,
        });
    }
    const groupData = (result: any) => {
        let obj: any = [];
        if (result.length) {
            obj = _.groupBy(result, 'application')
        }
        return obj;
    }

    const getSelectedDataObj = () => {
        const { data, selected} = state;
        let object: any = {}
        if(Object.keys(data).length) {
            Object.keys(data).forEach((item: any, key: number) => {
                // @ts-ignore
                data[item].forEach((issue: any, key2: number) => {
                    if (issue.id === selected) {
                        object = issue;
                    }
                })
            })
        }
        return object
    }

    const changeIssue = (id: any) => {
        setState({ ...state, selected: id })
    }

    const getFirstIssue = (data: any) => {
        let firstSelected = null;
        if(Object.keys(data).length) {
            Object.keys(data).forEach((item: any, key: number) => {
                if (key === 0) {
                    data[item].forEach((issue: any, key2: number) => {

                        if (key2 === 0) {
                            firstSelected = issue.id
                        }
                    })
                }
            })
        }
        return firstSelected
    }

    const orderRows = (sortColumn: string, orderLocal: boolean) => {
        const orderUpdated = !orderLocal;
        const { issuesList } = state
        let data: any;

        if (sortColumn === 'application') {
            data = _.orderBy(issuesList, (item: any) => item.application.toLowerCase(), [orderLocal ? 'asc' : 'desc'])
            return setState({...state, issuesList: data, orderBy: sortColumn, order: orderUpdated});
        }
        if (sortColumn === 'downTimeStart') {
            data = _.orderBy(issuesList, (item: any) => item.downTimeStart.toLowerCase(), [orderLocal ? 'asc' : 'desc'])
            return setState({...state, issuesList: data, orderBy: sortColumn, order: orderUpdated});
        }
    }

    const getBody = () => {
        const {issuesList} = state
        let rows: any = [];

        issuesList.forEach((list: any, key2: number) => {
            rows.push(
                <Table.Row key={'data_' + key2}>
                    <Table.Cell>{list.application}</Table.Cell>
                    <Table.Cell>{customMoment(list.downTimeStart).format('DD MMMM YYYY hh:mm A')}</Table.Cell>
                    <Table.Cell>{list.whatIsDown}</Table.Cell>
                    <Table.Cell>{list.whatToDoMeanTime}</Table.Cell>
                </Table.Row>
            )
        })
        return rows
    }

    const { data, selected, orderBy, order} = state;
    const direction = order ? 'sort down' : 'sort up';

    return (
        <>
            <Modal
                closeIcon
                open={true}
                onClose={hideModal}
                centered={true}
                size='fullscreen'
                className='downtime'
                style={{left : "unset !important"}}
            >
                <Header content="Application Status Tracker"/>
                {
                    state.loading && <Dimmer active={true} inverted={true}>
                        <Loader inverted={true}>Loading</Loader>
                    </Dimmer>
                }

                {
                    Object.keys(data).length && Object.keys(getSelectedDataObj()).length ?
                    <Modal.Content scrolling={true} className=''>
                        <Table className={'table table-striped table-hover my-0 FreezeTable'}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'application' ? 'active' : ''}`}
                                        onClick={() => orderRows('application', order)}
                                    >
                                        <div>
                                            Application
                                            <Icon className={orderBy === 'application' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'downTimeStart' ? 'active' : ''}`}
                                        onClick={() => orderRows('downTimeStart', order)}
                                    >
                                        <div>
                                            Down Time Start
                                            <Icon className={orderBy === 'downTimeStart' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        What Is Down
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        What Do I Do in The Meantime
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    getBody()
                                }
                            </Table.Body>
                        </Table>
                    </Modal.Content> :
                    <Modal.Content className='modal-downtime-content'>No Data...</Modal.Content>
                }
            </Modal>
        </>
    );
};
export default DownTimeIssuesPopUp;
