import React, {useEffect, useState} from "react";
import Template from "../Template";
import * as regActions from "../../redux/actions/regions";
import {useActions} from "../../redux/actions";
import {RootState} from "../../model";
import {connect} from "react-redux";
import {Button,Input, Modal, Table} from "semantic-ui-react";
import HelpMark from "../HelpMark";

function Regions(props:any){
    const regionsActions = useActions(regActions);
    const initialState = {
       regions:[],
       showForm:false,
        name : '',
        id: 0,
        value: '',
        showError: false,
        showDelConfirm: false,
        deleteEntryId:0,
        allowDelete: !!(props.user && props.user.isAdmin),
    }

    const [state, setStates] = useState(initialState);

    useEffect(() => {
         getRegionsList();
    },[]);

    const getRegionsList =  ()=>{
         regionsActions.getRegions().then((res:any)=>{
            setStates({...state,regions:res});
        })

    }

    const showAddForm = ()=>{
        setStates({...state,showForm: true})
    }

    const handleRegionCell=(e:{target:{value:string}})=>{
        setStates({...state,name:e.target.value})
    }

   const handleSubmit =  (e:any) => {
        e.preventDefault();
        if(state.name){
            let obj = {
                name : state.name
            }
            regionsActions.createRegion(obj)
                .then(() => {
                    regionsActions.getRegions()
                        .then(() => {
                            hideAddForm();
                        })
                });
        } else {
            setStates({...state, showError : true });
        }
    }


   const hideAddForm = () => {
        setStates ({...state, showForm : false, name :'' });
    }

    const handleDelete = (id:number) => {
        setStates({...state, deleteEntryId : id,showDelConfirm : true  });
    }

   const onClickInput = (id:number, value:string) => {
        setStates({...state,id:id, value:value});
    };

    const onInlineChange = (e:any) => {
        setStates({...state,value: e.target.value});
    }

    const saveChanges = () => {
        regionsActions.updateRegions({id:state.id,name:state.value})
            .then(() => {
                regionsActions.getRegions();
            });
        setStates({...state, id:0, value: '' });
    }

    const deleteRegion = () => {
        regionsActions.deleteRegions(state.deleteEntryId)
            .then(() => {
                setStates({...state, deleteEntryId : 0});
                handleCloseDelete();
                regionsActions.getRegions();
            });
    }

    const handleCloseDelete = () => {
        setStates({...state, showDelConfirm : false });
    }

    return (
     <Template activeLink={"regions"}>
         <div className="ui card">
             <div className="content">
                 <h2 className="float-left mr10"> Regions<HelpMark pageId="0"/></h2>
             </div>
         </div>
         <div className="ui card">
             <div className="content">
                <div>
                    <Button className="mb10" primary={true} onClick={() => showAddForm()}>Add New Region</Button>
                    { state.showForm &&
                        <div className='card'>
                            <div className="row">
                                <div className="col-xs-12">
                                    <form>

                                        <div className='form-group'>
                                            <Input name='name' type='text' value={state.name} className='form-control mb15' placeholder='Region Name' onChange={(e)=>handleRegionCell(e)} />
                                        </div>

                                        {state.showError && <div className='form-group text-red'>All fields are required!</div>}
                                        <div className='form-group'>
                                            <Button primary={true}  onClick={(e)=>handleSubmit(e)}>Save</Button>
                                            <Button className='btn btn-cncl' onClick={()=>hideAddForm()}>Cancel</Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <h2>Regions List</h2>
                <Table striped={true}>
                    <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                        <Table.HeaderCell>Region Name</Table.HeaderCell>
                    </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    { state.regions && state.regions.map((region:{id:number,name:string}) =>
                        <Table.Row key={region.id}>
                            <Table.Cell style={{width: '100px'}}><Button className='btn btn-primary' onClick={() => handleDelete(region.id)}>Delete</Button></Table.Cell>
                            <Table.Cell>{(region.id === state.id) ? <Input className='form-group' type="text" name="name" onKeyPress={(e:object)=>onInlineChange(e)} onChange={(e)=>onInlineChange(e)} value={state.value} onBlur={()=>saveChanges()} /> : <p onClick={() => onClickInput(region.id, region.name)}>{region.name}</p>}</Table.Cell>
                        </Table.Row>
                    )
                    }
                    </Table.Body>
                </Table>
                {state.showDelConfirm &&state.allowDelete&&
                    <Modal open={true} onClose={()=>handleCloseDelete()} centered={true} closeIcon={true} size={"small"}>
                        <Modal.Header>
                            <h2 className="text-danger">Delete!</h2>
                        </Modal.Header>
                        <Modal.Content>
                            <div className="row">
                                <div className="form-group">
                                    <p className="text-danger ml20">Are you sure you want to delete it?</p>
                                </div>
                            </div>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button className='btn btn-primary' onClick={()=>handleCloseDelete()}>
                                Cancel
                            </Button>
                            <Button className='btn btn-primary' onClick={()=>deleteRegion()}>
                                Yes
                            </Button>
                        </Modal.Actions>
                    </Modal>
                }
             </div>
         </div>
    </Template>
    )
}

function mapStateToProps(state:RootState) {
    return {
        data: state.regions,
        user: state.auth.user,
    }
}

export default connect(mapStateToProps)(Regions)

