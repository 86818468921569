import React, {  useState, useEffect } from "react";
import {
    Grid,
    Table,
    Button, Icon, Dropdown, Input,Modal,Form
} from "semantic-ui-react";
import Template from "./Template";
import { RootState } from "../model";
import { connect } from "react-redux";
import * as NotificationsActions from "../redux/actions/educatorNotifications";
import * as ProdecureCodeChange from "../redux/actions/procedureCodeChange"
import * as PracticeActions from "../redux/actions/practice"
import { useActions } from "../redux/actions";
import _ from "lodash";
import HelpMark from "./HelpMark";
import {render} from "react-dom";

interface ProcedureCodeChangeProps {
    id: number,
    procedureCode: string,
    abbrDesc: string,
    description: string,
    'OD ProcCat': string,
    isDeleted: boolean,
    createdAt: string,
    updatedAt:string,
    ProcedureCode_GroupData:Array<Object>
}
type notificationScema={
    practice:string
}

type procedureCodeSchema ={
    formData: any,
    group:any
}


const ProcedureCodeChangerList = (props: any) => {
    const initialState: procedureCodeSchema = {

        formData: props.formData,
        group: []
    }

    const [state,setStates] = useState(initialState);
    const     onChangeRowData = (e:any) => {
        const groupOption = state.group;
        let name = e.target.name;
        let value = e.target.value;
        const nameArr = name.split('-');
        const groupId = nameArr[1];
        const index = groupOption.findIndex((item:any) => parseInt(item.groupId) === parseInt(groupId));
        if (index >= 0) {
            const obj = {
                groupId: parseInt(groupId),
                optionId: parseInt(value)
            }
            groupOption.splice(index, 1, obj);
        } else {
            groupOption.push({
                groupId: parseInt(groupId),
                optionId: parseInt(value)
            })
        }
        setStates({...state,[`${e.target.name}`]: parseInt(e.target.value), group: groupOption})
    }
   const groupRowData = () => {
        const { procedureCodeGroups, formData } = props;
        const rowId = parseInt(formData.id)
        const rowItem =  formData && formData.ProcedureCode_GroupData;
        const groupList = procedureCodeGroups.map((item:any) => {
            const groupId = parseInt(item.id);
            let value = 0;
            let listOptions = [{key: 0, value: 0, text: 'Select Group'}];
            const groupOptions = item.ProcedureCode_GroupOptions ? item.ProcedureCode_GroupOptions : [];
            groupOptions.forEach((option:any) => {
                listOptions.push({
                    key: parseInt(option.id),
                    value: parseInt(option.id),
                    text: option.name
                });
                const isExist = rowItem.find((row:any) => row.groupId === groupId);
                if (isExist) {
                    value = isExist.optionId
                }
            })

            return (
                <Form.Field>
                <div className="col-md-3 col-xs-6 dentist-list">
                    <label>{item.name}</label>
                    <Dropdown
                        className="mr10 mb15"
                        options={listOptions}
                        placeholder="Select Group"
                        onChange={onChangeRowData}
                        value={value}
                        name={`${rowId}-${groupId}`}
                    />
                </div>
                </Form.Field>
            )
        })
        return groupList;
    }
   const getFormState = () => {
        let formState = props.formData;
        return formState
    }

    const onChange = (e:any) => {
        let formData = getFormState();
        formData[e.target.name] = e.target.value;
        setStates({...state,formData:formData});
    };

    const onChangeSelect = (e:any, name:any) => {
        let formData = getFormState();
        formData[name] = e.value;
        setStates({...state,formData:formData});
    }

   const  saveForm = () => {
        const { formData, group } = state;
        let obj = {
            id: formData.id,
            abbrDesc: formData.abbrDesc,
            description: formData.description,
            group: group
        }
        props.saveForm(obj);
    };
    const { cancelForm, header, showForm, hideForm, editFormError, addFormError  } = props;
    const fields = state.formData;
    return (
        <>
            <Modal
                className={'modalCenter'}
                open={true}
                onClose={hideForm}
                dialogClassName="carrierWidth"
            >
                <Modal.Header >
                    <p>{header}</p>
                </Modal.Header>
                <Modal.Content>
                    {editFormError && (<p className="text-danger">{editFormError}</p>)}
                    {addFormError && (<p className="text-danger">{addFormError}</p>)}

                    <Form className="formStyle" >
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Procedure Code</label>
                                <input
                                    type="text"
                                    value={fields.procedureCode || ""}
                                    name="procedureCode"
                                />
                            </Form.Field>
                            <Form.Field>
                                <div className="col-md-3 col-xs-6">
                                    <label>Abbr Desc</label>
                                    <input
                                        type="text"
                                        value={fields.abbrDesc || ''}
                                        name="abbrDesc"
                                        onChange={onChange}
                                    />
                                </div>
                            </Form.Field>
                            <Form.Field>
                                <div className="col-md-3 col-xs-6 dentist-list">
                                    <label>Description</label>
                                    <textarea  value={fields.description || ""} name="description" onChange={onChange} />
                                </div>

                            </Form.Field>
                            <Form.Field>
                                <div className="col-md-3 col-xs-6 dentist-list">
                                    <label>OD ProcCat</label>
                                    <textarea  value={fields['OD ProcCat'] || "" } name="OD ProcCat" />
                                </div>
                            </Form.Field>

                        </Form.Group>
                        <Form.Group>
                            {groupRowData()}
                        </Form.Group>
                    </Form>


                </Modal.Content>
                <Modal.Actions>
                    <Button className="btn PrimaryBtn width120 ml10" onClick={cancelForm}>Cancel</Button>
                    <Button className="btn PrimaryBtn width120" onClick={saveForm}>Save</Button>
                </Modal.Actions>
            </Modal>

        </>
    );
};

function mapStateToProps(state: RootState) {
    return {


    };
}

export default connect(mapStateToProps)(ProcedureCodeChangerList);