import React from "react";
import {Button, Modal} from "semantic-ui-react";
function DeleteSiteModal(props: any) {
    return(
        <Modal className='modalCenter' size="large" onClose={() => props.showDeleteSitesModalHandler()} open={true}>
            <Modal.Header>
                <b className="ui">Are You Sure You Want To Delete This Record?</b>
                <div className="ui top right attached label production_right_label">
                    <Button onClick={() => props.showDeleteSitesModalHandler()} inverted>
                        Close
                    </Button>
                </div>
            </Modal.Header>
            <Modal.Content>
                <p>Caution! This action is not reversible</p>
                <Button onClick={() => props.deleteSiteHandler()} className='bg-danger primaryColor'>DELETE</Button>
            </Modal.Content>
        </Modal>
    )
}
export default DeleteSiteModal