import React, { useEffect, useState } from 'react';
import { AccordionTitle, AccordionContent, Icon, Grid, GridRow, GridColumn, Loader, Progress } from 'semantic-ui-react'
import * as HealthStatusActions from "../../redux/actions/healthStatus";
import { useActions } from '../../redux/actions';
import PasswordCheckerTable from './PasswordCheckerTable';
import ProgressBar from './ProgressBar';

function PasswordCheckerHealth() {
    const initialState = { loading: false, data: {} as any, panel: '', counts: { failed: 0, success: 0, pending: 0 }, records: [] };
    const healthStatusActions = useActions(HealthStatusActions);
    const [state, setState] = useState(initialState);
    const { data, loading, panel, counts } = state;
    const currentPanel = 'passwordChecker'

    useEffect(() => {
        setState({ ...state, loading: true })
        healthStatusActions.getPasswordCheckerHealth().then((data: any) => {
            const counts = getProgress(data.providers);
            healthStatusActions.addToCount(counts).then();
            setState({ ...state, data: data, counts: counts, loading: false });
        })
    }, [])

    const setGrouped = () => {
        const dentaXchangeAccount = data.providers ?  data.providers.filter((m: any) => m.mco === 'DentalXChange') : [];
        return { 'Dental Xchange': dentaXchangeAccount, ...data.groupedPractices }
    }

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }

    const getProgress = (info: any) => {
        const practices = info ? info : [];
        let failed = 0;
        practices.forEach((item: any) => { if (item.status === 'red') failed++; });
        return {success: practices.length - failed , failed: failed, pending: 0 };
    }

    return (
        <>
            <AccordionTitle active={panel === currentPanel} onClick={() => onSelect(currentPanel)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Password Checker</span>
                            {loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                          <ProgressBar failed={counts.failed} pending={counts.pending} success={counts.success} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === currentPanel}>
                {<Grid>
                    <GridRow>
                        <GridColumn width={16}>
                            {data && <PasswordCheckerTable records={data.providers} groupedData={setGrouped()} />}
                        </GridColumn>
                    </GridRow>
                </Grid>}
            </AccordionContent>
        </>
    );
}

export default PasswordCheckerHealth;