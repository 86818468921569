import React, {useEffect, useState} from 'react';
import Template from "../components/Template";
import {Button, Card, Dropdown, Form, Grid, GridRow, Icon, Label, Message, Table, TextArea} from "semantic-ui-react";
import {Account} from "../model/user";
import {Practice} from "../model/practice";
import {connect} from "react-redux";
import {RootState} from "../model";
import {useActions} from "../redux/actions";
import * as mondayActions from "../redux/actions/mondayCom";
import {ionStatusMapping} from "../utils/ionStatusMapping";

interface Props {
    user: Account,
    practice: Practice
}

function MondayApp(props: Props) {
    const mondayAction = useActions(mondayActions);
    const [state, setStates] = useState({
        date: new Date(),
        user: {} as any,
        items: [],
        value: '',
        status: 0,
        message: null as any
    });

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async (params: any = {}) => {
        const data = await mondayAction.getMondayTasks({});
        setStates({...state, ...data, ...params});
    }

    const sendData = async () => {
        const data = await mondayAction.saveMondayTask({name: state.value});
        const message = (data.queryData && data.queryData.error_message)
            ? {content: data.queryData.error_message, error: true}
            : {content: 'Your note has been sent to Monday.com!', success: true};
        await loadData({message, value: ''});
    }

    const getStatus = (index: string) => {
        const color = ionStatusMapping.labels_colors[`${index}`].color;
        const status = ionStatusMapping.labels[`${index}`];
        return <Table.Cell style={{backgroundColor: color}}>{status}</Table.Cell>;
    }

    const statusOptions = Object.keys(ionStatusMapping.labels).map(key => ({
        text: ionStatusMapping.labels[key],
        value: +key,
        key: `status_drp_${key}`
    }));

    return (
        <Template activeLink="monday app">
            <Card>
                <Card.Content>
                    <h1 className="text-center textCenter"><Icon name='edit'/></h1>
                    <Grid>
                        {
                            state.message && <Grid.Row>
                                <Grid.Column width="6"/>
                                <Grid.Column width="4">
                                    <Message
                                        icon="check"
                                        compact={true}
                                        onDismiss={() => setStates({...state, message: null})}
                                        success={state.message.success}
                                        error={state.message.error}
                                        header='Saved'
                                        content={state.message.content}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        }
                        <Grid.Row>
                            <Grid.Column mobile="1" computer="4"/>
                            <Grid.Column mobile="16" computer="8">
                                <Form>
                                    <Form.Field>
                                        <TextArea
                                            placeholder='Enter Notes'
                                            value={state.value || ''}
                                            onChange={(d, v) => setStates({...state, value: v.value as any})}
                                        />
                                    </Form.Field>
                                    <Button
                                        primary={true}
                                        icon="check"
                                        disabled={!state.value || !state.value.length}
                                        onClick={() => sendData()}
                                    >
                                        Save to Monday
                                    </Button>
                                </Form>
                            </Grid.Column>
                            <Grid.Column mobile="1" computer="4"/>
                        </Grid.Row>
                    </Grid>

                    <Form>
                        <Form.Group className='float-right'>
                            <Form.Field>
                                <Dropdown
                                    className='mr10 mb10'
                                    placeholder='Status'
                                    search={true}
                                    selection={true}
                                    onChange={(a, b) => setStates({...state, status: b.value} as any)}
                                    options={statusOptions}
                                    value={state.status}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    <br/>

                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell/>
                                <Table.HeaderCell content="Status"/>
                                <Table.HeaderCell content="Owner"/>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                state.items && state.items
                                    .filter((item: any) => item.status && item.status.index == state.status)
                                    .map((item: any, index) => <Table.Row key={'row_' + index}>
                                        <Table.Cell>{item.name}</Table.Cell>
                                        {item.status && getStatus(item.status.index)}
                                        <Table.Cell><Icon name="user"/>&nbsp;{state.user.name}</Table.Cell>
                                    </Table.Row>)
                            }
                        </Table.Body>
                    </Table>
                </Card.Content>
            </Card>
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        practice: state.practice
    };
}

export default connect(mapStateToProps)(MondayApp);
