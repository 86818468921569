import { ProcedureCodeChange ,ProcedureCodeChanger} from "../../model/procedureCodeChange";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";
const initialState:ProcedureCodeChange = {
    ProcedureCodeChanger:[]

}
const initialProcedureCode: ProcedureCodeChanger ={
    id: 0,
    procedureCode: '',
    abbrDesc: '',
    description: '',
    'OD ProcCat': '',
    isDeleted: false,
    createdAt: '',
    updatedAt:'',
    ProcedureCode_GroupData:[]
}
export const procedure = createReducer(initialState, {
    [ActionType.FETCH_PROCEDURE_CODE_CHANGE_SUCCESS](state: ProcedureCodeChange, action: Action<ProcedureCodeChange>) {
        return Object.assign({...state}, action.payload);
    }
});
