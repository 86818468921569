import * as React from 'react';

export interface PMDUnitItemClickableProps {
  value: any,
  description: any
}

export interface PMDUnitItemClickableState {
  value: any,
  description: any
}

class PMDUnitItemClickable extends React.Component<PMDUnitItemClickableProps,PMDUnitItemClickableState> {  
  constructor(props: PMDUnitItemClickableProps) {
    super(props);
    this.state={
      value : props.value,
      description: props.description
    }
  }

  componentWillReceiveProps(nextProps: PMDUnitItemClickableProps) {
    this.setState({
      value: nextProps.value,
      description: nextProps.description
    })
  }

  render() {
    return (
      <table className="pmd_tbl_no_border">
      <thead>
            <tr>
              <td className="pmd_numbers_clickable"><b><u>{this.props.value}</u></b></td>
            </tr>
            <tr>
              <td className="pmd_numbers_below">{this.props.description}</td>
            </tr>
          </thead>
      </table>  
)
  }
}

export default PMDUnitItemClickable;