import React, {useEffect, useState} from "react";
import {Button, Modal} from "semantic-ui-react";
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';

const initState: any = {
    lighthouseResults: [],
}
function ShowLighthouseScores(props: any) {
    const [state, setState] = useState(initState)
    useEffect(() => {
        props.lighthouseResults.forEach((item: any) => {
            if (item.siteUrl === 'https://' + props.selectedSiteData.liveSite.split('.com')[0] + '.com') {
                setState({...state, lighthouseResults: JSON.parse(item.reportData)})
            }
        })
    }, [])

    const getColorCode = (percentage: any) => {
        let text = "#fff";
        let background = "#3e98c7";
        let path = '#ffffff'

        if (percentage < 51) {
            text = 'rgb(255, 0, 0)'
            background = `rgba(255, 0, 0, ${percentage / 100})`
            path = 'rgb(255, 0, 0)'
        }

        if (percentage > 50 && percentage <= 89) {
            text = 'rgb(255, 165, 0)'
            background = 'rgba(255, 165, 0, .3)'
            path = 'rgb(255, 165, 0)'
        }

        if (percentage >= 90 && percentage <= 100) {
            text = 'rgb(0, 163, 108)'
            background = 'rgba(0, 163, 108, .3)'
            path = 'rgb(0, 163, 108)'
        }
        return {text, background, path}
    }

    return(
        <>
            {
                state.lighthouseResults &&
                state.lighthouseResults.desktop &&
                state.lighthouseResults.desktop.statusCode !== 500 ?
                <Modal className='modalCenter' size="large" onClose={() => props.showLighthouseScoresModalHandler()} open={true}>
                    <Modal.Header>
                        <b>{props.selectedSiteData.liveSite.split('.com')[0] + '.com'} - Lighthouse Results</b>
                        <div className="ui top right attached label production_right_label">
                            <Button onClick={() => props.showLighthouseScoresModalHandler()} inverted>
                                Close
                            </Button>
                        </div>
                    </Modal.Header>
                    <Modal.Content scrolling={true}>

                        <div className="displayScores mb20">
                            <div className="col-md-6 container">
                                <h1 className="platform-title">Mobile</h1>
                            </div>
                            <div className="col-md-6 container">
                                <h1 className="platform-title">Desktop</h1>
                            </div>
                        </div>
                        <div className='scoresResults'>
                            <div className="score-item-big">
                                <h3 className="title">Performance</h3>
                                <div style={{ width: 200, height: 200 }}>
                                    <CircularProgressbar
                                        value={state.lighthouseResults.mobile.body.Performance * 100}
                                        text={`${state.lighthouseResults.mobile.body.Performance * 100}`}
                                        background={true}
                                        backgroundPadding={6}
                                        styles={buildStyles({
                                            backgroundColor: `${getColorCode(state.lighthouseResults.mobile.body.Performance * 100).background}`,
                                            textColor: `${getColorCode(state.lighthouseResults.mobile.body.Performance * 100).text}`,
                                            pathColor: `${getColorCode(state.lighthouseResults.mobile.body.Performance * 100).path}`,
                                            trailColor: "transparent",
                                            strokeLinecap: 'round'
                                        })}
                                    />
                                </div>
                            </div>
                            <div className="scoresResults">
                                <div className="score-item-small">
                                    <h3 className="title">Accessibility</h3>
                                    <div style={{ width: 100, height: 100 }}>
                                        <CircularProgressbar
                                            value={state.lighthouseResults.mobile.body.Accessibility * 100}
                                            text={`${state.lighthouseResults.mobile.body.Accessibility * 100}`}
                                            background={true}
                                            backgroundPadding={6}
                                            styles={buildStyles({
                                                backgroundColor: `${getColorCode(state.lighthouseResults.mobile.body.Accessibility * 100).background}`,
                                                textColor: `${getColorCode(state.lighthouseResults.mobile.body.Accessibility * 100).text}`,
                                                pathColor: `${getColorCode(state.lighthouseResults.mobile.body.Accessibility * 100).path}`,
                                                trailColor: "transparent",
                                                strokeLinecap: 'round'
                                            })}
                                        />
                                    </div>
                                    <h3 className="title">Best Practices</h3>
                                    <div style={{ width: 100, height: 100 }}>
                                        <CircularProgressbar
                                            value={state.lighthouseResults.mobile.body.BestPractices * 100}
                                            text={`${state.lighthouseResults.mobile.body.BestPractices * 100}`}
                                            background={true}
                                            backgroundPadding={6}
                                            styles={buildStyles({
                                                backgroundColor: `${getColorCode(state.lighthouseResults.mobile.body.BestPractices * 100).background}`,
                                                textColor: `${getColorCode(state.lighthouseResults.mobile.body.BestPractices * 100).text}`,
                                                pathColor: `${getColorCode(state.lighthouseResults.mobile.body.BestPractices * 100).path}`,
                                                trailColor: "transparent",
                                                strokeLinecap: 'round'
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="scoresResults">
                                <div className="score-item-small">
                                    <h3 className="title">SEO</h3>
                                    <div style={{ width: 100, height: 100 }}>
                                        <CircularProgressbar
                                            value={state.lighthouseResults.mobile.body.SEO * 100}
                                            text={`${state.lighthouseResults.mobile.body.SEO * 100}`}
                                            background={true}
                                            backgroundPadding={6}
                                            styles={buildStyles({
                                                backgroundColor: `${getColorCode(state.lighthouseResults.mobile.body.SEO * 100).background}`,
                                                textColor: `${getColorCode(state.lighthouseResults.mobile.body.SEO * 100).text}`,
                                                pathColor: `${getColorCode(state.lighthouseResults.mobile.body.SEO * 100).path}`,
                                                trailColor: "transparent",
                                                strokeLinecap: 'round'
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="seperator"></div>
                            <div className="scoresResults">
                                <div className="score-item-big">
                                    <h3 className="title">Performance</h3>
                                    <div style={{ width: 200, height: 200 }}>
                                        <CircularProgressbar
                                            value={state.lighthouseResults.desktop.body.Performance * 100}
                                            text={`${state.lighthouseResults.desktop.body.Performance * 100}`}
                                            background={true}
                                            backgroundPadding={6}
                                            styles={buildStyles({
                                                backgroundColor: `${getColorCode(state.lighthouseResults.desktop.body.Performance * 100).background}`,
                                                textColor: `${getColorCode(state.lighthouseResults.desktop.body.Performance * 100).text}`,
                                                pathColor: `${getColorCode(state.lighthouseResults.desktop.body.Performance * 100).path}`,
                                                trailColor: "transparent",
                                                strokeLinecap: 'round'
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="scoresResults">
                                <div className="score-item-small">
                                    <h3 className="title">Accessibility</h3>
                                    <div style={{ width: 100, height: 100 }}>
                                        <CircularProgressbar
                                            value={state.lighthouseResults.desktop.body.Accessibility * 100}
                                            text={`${state.lighthouseResults.desktop.body.Accessibility * 100}`}
                                            background={true}
                                            backgroundPadding={6}
                                            styles={buildStyles({
                                                backgroundColor: `${getColorCode(state.lighthouseResults.desktop.body.Accessibility * 100).background}`,
                                                textColor: `${getColorCode(state.lighthouseResults.desktop.body.Accessibility * 100).text}`,
                                                pathColor: `${getColorCode(state.lighthouseResults.desktop.body.Accessibility * 100).path}`,
                                                trailColor: "transparent",
                                                strokeLinecap: 'round'
                                            })}
                                        />
                                    </div>
                                    <h3 className="title">Best Practices</h3>
                                    <div style={{ width: 100, height: 100 }}>
                                        <CircularProgressbar
                                            value={state.lighthouseResults.desktop.body.BestPractices * 100}
                                            text={`${state.lighthouseResults.desktop.body.BestPractices * 100}`}
                                            background={true}
                                            backgroundPadding={6}
                                            styles={buildStyles({
                                                backgroundColor: `${getColorCode(state.lighthouseResults.desktop.body.BestPractices * 100).background}`,
                                                textColor: `${getColorCode(state.lighthouseResults.desktop.body.BestPractices * 100).text}`,
                                                pathColor: `${getColorCode(state.lighthouseResults.desktop.body.BestPractices * 100).path}`,
                                                trailColor: "transparent",
                                                strokeLinecap: 'round'
                                            })}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="scoresResults">
                                <div className="score-item-small">
                                    <h3 className="title">SEO</h3>
                                    <div style={{ width: 100, height: 100 }}>
                                        <CircularProgressbar
                                            value={state.lighthouseResults.desktop.body.SEO * 100}
                                            text={`${state.lighthouseResults.desktop.body.SEO * 100}`}
                                            background={true}
                                            backgroundPadding={6}
                                            styles={buildStyles({
                                                backgroundColor: `${getColorCode(state.lighthouseResults.desktop.body.SEO * 100).background}`,
                                                textColor: `${getColorCode(state.lighthouseResults.desktop.body.SEO * 100).text}`,
                                                pathColor: `${getColorCode(state.lighthouseResults.desktop.body.SEO * 100).path}`,
                                                trailColor: "transparent",
                                                strokeLinecap: 'round'
                                            })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Content>
                </Modal> : <Modal className='modalCenter' size="large" onClose={() => props.showLighthouseScoresModalHandler()} open={true}>
                    <Modal.Header>
                        <b>{props.selectedSiteData.liveSite.split('.com')[0] + '.com'} - Lighthouse Results</b>
                        <div className="ui top right attached label production_right_label">
                            <Button onClick={() => props.showLighthouseScoresModalHandler()} inverted>
                                Close
                            </Button>
                        </div>
                    </Modal.Header>
                    <Modal.Content scrolling={true}><p>No Lighthouse results to show</p></Modal.Content>
                </Modal>
            }

        </>
    )
}

export default ShowLighthouseScores