import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const fetchProcedureCodeChanger = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
       const results = await api.get('procedureCodeChanger/get');
        dispatch({ type: ActionType.FETCH_PROCEDURE_CODE_CHANGE_SUCCESS, payload: results });

        return results;
    } catch (err) {
        dispatch({ type: ActionType.FETCH_PROCEDURE_CODE_CHANGE_FAILURE, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getProcedureCodeGroup = (data:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const results = await api.get('procedureCodeChanger/getGroup',data);
        dispatch({ type: ActionType.LOAD_PROCEDURE_CODE_GROUP_SUCCESS, payload: results });
        return results;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_PROCEDURE_CODE_GROUP_FAILURE, payload: false });
        return dispatch(errorHandler(err));
    }
}
export const updateProcedureCodeChanger = (data:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const results = await api.put('procedureCodeChanger/update',data);
        dispatch({ type: ActionType.UPDATE_PROCEDURE_CODE_CHANGER_SUCCESS, payload: results });
        dispatch({ type: ActionType.LOADER, payload: false });
        return results;
    } catch (err) {
        dispatch({ type: ActionType.UPDATE_PROCEDURE_CODE_CHANGER_FAILURE, payload: false });
        return dispatch(errorHandler(err));
    }
}
export const deleteProcedureCodeChangerList = (id:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const results = await api.delete(`procedureCodeChanger/delete/${id}`);
        dispatch({ type: ActionType.DELETE_PROCEDURE_CODE_CHANGER_SUCCESS, payload: results });
        dispatch({ type: ActionType.LOADER, payload: false });
        return results;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_FAIL, payload: false });
        return dispatch(errorHandler(err));
    }
}
export const importProcedureCode = (data:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const results = await api.get('procedureCodeChanger/import',data);
        dispatch({ type: ActionType.IMPORT_PROCEDURE_CODE_CHANGE_SUCCESS, payload: results });
        dispatch({ type: ActionType.LOADER, payload: false });
        return results;
    } catch (err) {
        dispatch({ type: ActionType.IMPORT_PROCEDURE_CODE_CHANGE_FAILURE, payload: false });
        return dispatch(errorHandler(err));
    }
}
export const createProcedureCodeGroup = (data:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const results = await api.post('procedureCodeChanger/createGroup',data);

        dispatch({ type: ActionType.CREATE_PROCEDURE_CODE_CHANGE_SUCCESS, payload: results });
        dispatch({ type: ActionType.LOADER, payload: false });
        return results.data;
    } catch (err) {
        dispatch({ type: ActionType.CREATE_PROCEDURE_CODE_CHANGE_FAILURE, payload: false });
        return dispatch(errorHandler(err));
    }
}
export const createProcedureCodeGroupOptions = (data:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const results = await api.post('procedureCodeChanger/createGroupOptions',data);

        dispatch({ type: ActionType.LOAD_PROCEDURE_CODE_GROUP_OPTIONS, payload: results });
        dispatch({ type: ActionType.LOADER, payload: false });
        return results.data;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_FAIL, payload: err });
        return dispatch(errorHandler(err));
    }
}
export const updateProcedureCodeGroup = (data:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const results = await api.put('procedureCodeChanger/updateGroup',data);

        dispatch({ type: ActionType.CREATE_PROCEDURE_CODE_GROUP, payload: results });
        dispatch({ type: ActionType.LOADER, payload: false });
        return results.data;
    } catch (err) {
        dispatch({ type: ActionType.LOAD_FAIL, payload: false });
        return dispatch(errorHandler(err));
    }
}
