import React, {useEffect, useState} from "react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import {Button, Dropdown, Grid, Icon, Table} from "semantic-ui-react";
import * as BudgetActions from "../redux/actions/budget"
import {useActions} from "../redux/actions";
import _ from "lodash";
import {budgetFilters, isSafari, Months, monthsArray, oralSurgeryBudgetHeader} from "../constants";
import * as practiceActions from "../redux/actions/practice";
import ImportModal from "./ImportModal";
import HelpMark from "./HelpMark";

interface OralSurgeryBudgetProps {
    budgetList: any
    practice: any
    accessToken: string
}

const initialState: any = {
    editValue: null,
    openModel: false,
    budgetData: [],
    orderBy: null,
    order: true
}

const initialFilters: any = {
    practice: null,
    years: null,
    months: null
};

const OralSurgeryBudget = (props: OralSurgeryBudgetProps) => {
    const budgetActions = useActions(BudgetActions);
    const practiceAction = useActions(practiceActions);
    const [state, setStates] = useState(initialState);
    const [filters, setFilters] = useState(initialFilters);


    useEffect(() => {
        getData();
    }, [filters.years, filters.months]);


    const getData = () => {
        budgetActions.getOralSurgeryBudget(filters)
            .then((res: any) => {
                setStates({...state, budgetData: res});
            });
        if (!props.practice.practiceOptions.length) {
            practiceAction.fetchPracticesOptions();
        }
    }

    const getSortRows = (sortColumn: string, order: string) => {
        const {budgetData} = state;
        const orderUpdated = !order;
        const budgetDataSort = _.orderBy(budgetData, [sortColumn], [order ? 'asc' : 'desc'])
        setStates({...state, budgetData: budgetDataSort, orderBy: sortColumn, order: orderUpdated});
    };

    const editValues = (val: string) => {
        setStates({...state, editValue: val});
    };

    const updateValues = (e: any) => {
        const splitName = e.target.name.split(":");
        const postObj = {
            value: parseFloat(e.target.value),
            key: splitName[2],
            practice: splitName[0],
            monthYear: splitName[1]
        };
        setStates({...state, editValue: null});
        budgetActions.updateOSBudgetValues(postObj)
            .then(() => {
                getData();
            })
    };

    const onFiltersChange = (e: any, element: any) => {
        const keyword = element.name;
        const value = element.value;
        filters[keyword] = value;
        setFilters({...filters});
    }

    const changeOnFilterPractice = (e: any, element: any) => {
        const {budgetList} = props;
        const keyword = element.name;
        const value = element.value;
        let data = budgetList;
        if (value && value !== 'all') {
            data = data.filter((element: any) => {
                return element[keyword] === value;
            })
        }
        setStates({...state, budgetData: data})
        setFilters({...filters, practice: value})
    };

    const exportCsv = () => {
        const {budgetList} = props;
        let csvData: any = [];
        let filename = 'Oral Surgery Goal.csv';
        const header: any = [];
        oralSurgeryBudgetHeader.forEach((item: { title: string; }) => {
            header.push(item.title);
        });
        csvData.push(header);
        budgetList.forEach((item: any) => {
            let rows = [];

            const year = item.monthYear.split("-")[0];
            const monthIndex: any = item.monthYear.split("-")[1];
            const month = monthsArray[monthIndex - 1];
            let budgetCare: any = 0;
            let CPV = '';
            let PTVisits: any = '';
            if (item.oralSurgeryDays > 0) {
                CPV = item.CPV ? `$ ${parseFloat(item.CPV).toFixed(2)}` : '';
                PTVisits = item.PTVisits ? item.PTVisits : '';
                budgetCare = item.PTVisits && item.CPV ?
                    '$' + (item.oralSurgeryDays * item.PTVisits * item.CPV).toFixed(2) : 0;
            }
            rows.push(item.practice, `${month}-${year}`, item.oralSurgeryDays, PTVisits, CPV, budgetCare);
            csvData.push(rows);
        });
        csvData = csvData.join('\r\n');
        const dataType = isSafari() ? 'application/csv' : 'text/csv';
        // @ts-ignore
        const blob: any = new Blob(['', csvData], {dataType});
        const URL = window.URL || window.webkitURL;
        const url = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    };

    const hideModal = () => {
        setStates({...state, openModel: false});
    }

    const openModel = () => {
        setStates({...state, openModel: true});
    }
    const searchFilter = () => {
        setStates({...state, search: true})
    }

    const {accessToken} = props;
    const {budgetData, orderBy, order, editValue} = state;
    const direction = order ? 'sort down' : 'sort up';
    return (
        <Template activeLink="budget-list">
            {state.openModel &&
                <ImportModal hideModal={hideModal} searchFilter={searchFilter} parent={'OralSurgeryBudget'}
                             accessToken={accessToken}/>}

            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left">Oral Surgery Goal <HelpMark pageId='59'/></h2>
                    <div className="topFilters">
                        <Button
                            primary
                            onClick={openModel}
                        >
                            Import
                        </Button>
                        <Button primary onClick={exportCsv}>Export</Button>

                        <Dropdown
                            search={true}
                            className='mr10'
                            name="practice"
                            placeholder="Select Practice"
                            selection={true}
                            options={props.practice.practiceOptions}
                            onChange={changeOnFilterPractice}
                            defaultValue={filters.practice}
                        />
                        <Dropdown
                            search={true}
                            className='mr10'
                            name="months"
                            placeholder="Select Month"
                            selection={true}
                            options={budgetFilters.months}
                            onChange={onFiltersChange}
                            defaultValue={filters.months}
                        />
                        <Dropdown
                            search={true}
                            className='mr10'
                            name="years"
                            placeholder="Select Year"
                            selection={true}
                            options={budgetFilters.years}
                            onChange={onFiltersChange}
                            defaultValue={filters.years}
                        />
                    </div>
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    <div className="FreezeTable">
                        <Table singleLine={true} className="ui table table-striped celled table-hover unstackable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        style={{color: "#d16f26"}}
                                        className={`cursorPointer ${orderBy === 'practice' ? 'active' : ''}`}
                                        onClick={() => getSortRows('practice', order)}
                                    >
                                        <div>PRACTICE <Icon className={orderBy === 'practice' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        style={{color: "#d16f26"}}
                                        className={`cursorPointer ${orderBy === 'monthYear' ? 'active' : ''}`}
                                        onClick={() => getSortRows('monthYear', order)}
                                    >
                                        <div>MONTH YEAR <Icon className={orderBy === 'monthYear' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        style={{color: "#d16f26"}}
                                        className={`cursorPointer ${orderBy === 'oralSurgeryDays' ? 'active' : ''}`}
                                        onClick={() => getSortRows('oralSurgeryDays', order)}
                                    >
                                        <div>ORAL SURGERY DAYS <Icon
                                            className={orderBy === 'oralSurgeryDays' ? direction : 'sort'}/></div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        style={{color: "#d16f26"}}
                                        className={`cursorPointer ${orderBy === 'PTVisits' ? 'active' : ''}`}
                                        onClick={() => getSortRows('PTVisits', order)}
                                    >
                                        <div>PT VISITS <Icon className={orderBy === 'PTVisits' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        style={{color: "#d16f26"}}
                                        className={`cursorPointer ${orderBy === 'CPV' ? 'active' : ''}`}
                                        onClick={() => getSortRows('CPV', order)}
                                    >
                                        <div>CPV <Icon className={orderBy === 'CPV' ? direction : 'sort'}/></div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell style={{color: "#d16f26"}}>
                                        <div>BUDGET CARE</div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {budgetData.length &&
                                    budgetData.map((item: any, index: any) => {
                                        let budgetCare = '';
                                        let CPV = '';
                                        let PTVisits = '';
                                        if (item.oralSurgeryDays > 0) {
                                            CPV = item.CPV ? `$ ${parseFloat(item.CPV).toFixed(2)}` : '';
                                            PTVisits = item.PTVisits ? item.PTVisits : '';
                                            budgetCare = item.PTVisits && item.CPV ? `$ ${parseFloat(String(item.oralSurgeryDays * item.PTVisits * item.CPV)).toFixed(2)}` : '';
                                        }
                                        const key = `${item.practice}:${item.monthYear}`;
                                        const year = item.monthYear.split("-")[0];
                                        const monthIndex = item.monthYear.split("-")[1];
                                        const month = Months[monthIndex - 1];
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell>{item.practice}</Table.Cell>
                                                <Table.Cell>{month}-{year}</Table.Cell>
                                                <Table.Cell>{item.oralSurgeryDays}</Table.Cell>
                                                <Table.Cell>
                                                    {(state.editValue && state.editValue === `${key}:PTVisits`) ?
                                                        <input className="width80" name={`${key}:PTVisits`}
                                                               onBlur={updateValues} defaultValue={item.PTVisits}/>
                                                        :
                                                        <span className="cursorPointer"
                                                              onClick={() => editValues(`${key}:PTVisits`)}>
                            {PTVisits}
                          </span>}

                                                </Table.Cell>
                                                <Table.Cell>
                                                    {(editValue && editValue === `${key}:CPV`) ?
                                                        <input
                                                            className="width80"
                                                            name={`${key}:CPV`}
                                                            onBlur={updateValues}
                                                            defaultValue={parseFloat(item.CPV).toFixed(2)}
                                                        />
                                                        :
                                                        <span className="cursorPointer"
                                                              onClick={() => editValues(`${key}:CPV`)}>
                           {CPV}
                          </span>}
                                                </Table.Cell>
                                                <Table.Cell>{budgetCare}</Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        budgetList: state.budget.oral,
        practice: state.practice,
        accessToken: state.auth.accessToken
    };
}

export default connect(mapStateToProps)(OralSurgeryBudget);
