import React, { useEffect, useState } from 'react';
import {Dimmer, Loader} from 'semantic-ui-react';
import {RootState} from "./model";
import {connect} from "react-redux";

const Loading = (props: any) => {

    const [state, setState] = useState({timer : 0})


    const startCounting = () => {
        setInterval(() => {
            setState((prevState) => ({
                timer: prevState.timer + 1,
            }));
        }, 1000);
    }

    useEffect(() => {
        startCounting();
    }, [])

    const time = () => {
        const timer = state.timer;
        const minutes = Math.floor(timer / 60);
        const seconds = timer % 60;

        return minutes === 0
            ? `${seconds}s`
            : `${minutes}m ${seconds}s`;
    }

    return (
        <div>
            <Dimmer active inverted>
                <Loader size='massive'> {time()} </Loader>
            </Dimmer>
        </div>
    );

};


function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user
    };
}

export default connect(mapStateToProps)(Loading);
