import React, {useEffect, useState} from "react";
import Template from "./Template";
import * as practiceAction from "../redux/actions/practiceAdmin";
import {RootState} from "../model";
import {connect} from "react-redux";
import {Search, Button} from 'semantic-ui-react';
import {useActions} from "../redux/actions";
import PracticeAdminDynamicTable from "./PracticeAdminDynamicTable";
import HelpMark from "./HelpMark";
import AddNewPracticeModal from "./AddNewPracticeModal";

interface PracticeAdminListProps {
    practiceAdminList: any,
    data: Data[];
}

interface Data {
    [key: string]: any;
}

interface PracticeFields {
    practice: string,
    practiceabbr: string,
    brand: string,
    practiceDBName: string,
    orthoLocation: string,
    commonName: string,
    tab32clinicID: string,
    uss: boolean,
    ortho: boolean,
    campaign: boolean,
    recruitment: boolean,
    alerts: boolean,
    xrayService: boolean,
    patientFinance: boolean,
    enableDB: boolean,
    PCR: boolean,
    TAB32: boolean,
    credentialing: boolean,
    ptCareCountUploader: boolean,
    schedule: boolean,
}

const initialState: any = {
    loading: false,
    practiceAdminList: [],
    isEdit: false,
    orderBy: null,
    order: true,
    search: ""
}

const PracticeAdminList = (props: PracticeAdminListProps) => {
    const practiceActions = useActions(practiceAction);
    const [state, setStates] = useState(initialState);
    const [openAddPracticeModal, setOpenAddPracticeModal] = useState(false)
    const [searchTerm, setSearchTerm] = useState<string>('');
    const omitPractices = ['Victoria Kids', 'Crossroads', 'DAL NW HWY']

    useEffect(() => {
        loadData();
    }, []);

    const loadData  = () => {
        practiceActions.fetchPractices()
            .then((res: any) => {
                res = res.filter((item: any) => !omitPractices.includes(item.practice));
                setStates({...state, practiceAdminList: res});
            });
    }
    const handleSearch = (event: any) => {
        setSearchTerm(event.target.value);
    };

    const createPractice = (fields: PracticeFields) => {
        practiceActions.createPractice(fields).then(() => {
            closeModal()
            loadData()
        })
    }

    const openModal = () => {
        setOpenAddPracticeModal(true);
    }
    const closeModal = () => {
        setOpenAddPracticeModal(false);
    }


    const {practiceAdminList} = state;
    const filteredData = practiceAdminList.filter((row: any) => {
        return Object.values(row).some((value) => {
            if (typeof value === 'string') {
                return value.toLowerCase().includes(searchTerm.toLowerCase());
            } else if (Array.isArray(value)) {
                return value.some((item) =>
                    item.brand.toLowerCase().includes(searchTerm.toLowerCase())
                );
            } else {
                return false;
            }
        });
    });

    return (
        <Template activeLink='practice-admin-list'>
            {
                openAddPracticeModal &&
                <AddNewPracticeModal
                    savePractice={(data: PracticeFields) => createPractice(data)}
                    handleClose={closeModal}
                />
            }
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">Practice Admin <HelpMark pageId='68'/></h2>
                    <div className='topFilters d-flex'>

                        <Search
                            className="float-right"
                            placeholder='Search...'
                            onSearchChange={handleSearch}
                            name={'search'}
                            value={searchTerm}
                        />
                    </div>
                    <Button className="float-right mr10" primary={true} onClick={() => openModal()}> Add New
                        Practice </Button>
                </div>
            </div>


            <div className="ui card">
                <div className="content">

                    {practiceAdminList && <PracticeAdminDynamicTable reLoadData={loadData} data={filteredData}/>}
                </div>
            </div>
        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        practiceAdminList: state.practiceAdminList,
    };
}

export default connect(mapStateToProps)(PracticeAdminList);