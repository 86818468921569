import React from "react";
import {
    Button, Icon, Modal
} from "semantic-ui-react";
import { connect } from "react-redux";

const DeleteModal = (props: any) => {
    const { showDelConfirm, onShowHide, onDelete } = props;


    return (
        <>
            <Modal
                show={showDelConfirm}
                className={'modalCenter'}
                open={true}
                onClose={onShowHide}
                dialogClassName="carrierWidth"
            >
                <Modal.Header className="content-center">
                    <h3 className="text-danger-modal textCenter">Caution <Icon name="warning sign"/></h3>
                </Modal.Header>
                <Modal.Content>
                    <div className="row content-center">
                            <h4 className="text-modal textCenter ml20">Are you sure you want to delete the procedure code?</h4>
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary color="blue" onClick={onShowHide}>
                        Cancel
                    </Button>
                    <Button primary color="blue" onClick={onDelete}>
                        Yes
                    </Button>
                </Modal.Actions>
            </Modal>

        </>
    );
};


function mapStateToProps() {
    return { };
}

export default connect(mapStateToProps)(DeleteModal);