import React, {useEffect} from "react";
import {Icon, Label} from 'semantic-ui-react';
import Logo from "../styles/images/logoMain.png";
function LoginImageSlider(props: any) {
    const [mainImage, setMainImage] = React.useState('');
    const [practice, setPractice] = React.useState('');
    const [imageKey, setImageKey] = React.useState(-1);

    let changeSlideSec = 10;

    useEffect(() => {
        if (props.practiceDetails.length > 0) {
            let index = imageKey;
            if (index === -1) {
              index = Math.floor(Math.random() * Math.floor(props.practiceDetails.length -1));
              setImageKey(index);
            }
            setPractice(props.practiceDetails[index].practice);
            setMainImage((props.practiceDetails[index].officeImage && props.practiceDetails[index].officeImage.indexOf(",") > 0)
                ? props.practiceDetails[index].officeImage.split(",")[0] : props.practiceDetails[index].officeImage);
            const interval = setTimeout(() => {
                handleNextButton('next')
            }, changeSlideSec *1000);
            return () => clearInterval(interval);

        }


    }, [props.practiceDetails, imageKey]);



    const handleNextButton = (action: string) => {
      let index = -1;
      if (action === 'prev') {
        index = imageKey - 1;
        if (imageKey === 0) {
            index = props.practiceDetails.length - 1;
        }
      } else if (action === 'next'){
        index = imageKey + 1;
        if (imageKey === props.practiceDetails.length - 1) {
            index = 0;
        }
      }
      setImageKey(index);
    };

    return (
        <div className="practiceSlider">
            <span className="prev-btn" onClick={() => handleNextButton('prev')}><Icon name="angle left"/></span>
           <img
               className="practiceImage rounded"
               src={mainImage}
               alt={Logo}
               width='100%'
               height='100%'
           />

           <span className="next-btn" onClick={() => handleNextButton('next')}><Icon name="angle right"/> </span>
           <Label>{practice}</Label>
        </div>
    );
}


export default LoginImageSlider;
