import {ActionType} from "../../model/actionTypes";
import {XrayServiceStatus} from "../../model/xrayServiceStatus";

export const fetchXrayStatus = () => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const response: XrayServiceStatus[] = await api.get("xray-service/status");
        dispatch({type: ActionType.XRAY_STATUS, payload: response});
        return response;
    } catch (err) {
        return dispatch({type: ActionType.XRAY_STATUS, payload: {xrayStatus: []}});
    }
};
