import React, {useEffect, useState} from 'react';
import Template from '../../components/Template';
import {useActions} from "../../redux/actions";
import * as reportActions from "../../redux/actions/report";
import {Practice} from "../../model/practice";
import { Report} from "../../model/report";
import {App, RootState} from "../../model";
import {connect} from 'react-redux';
import {Dropdown, Icon} from "semantic-ui-react";
import {getMonthsOption} from "../../components/Common";
import NewOSReport from "../../components/NewOSReport"
import {OnChange} from "../../model/common";
import moment from "moment";
import {Button } from "semantic-ui-react";
import {exportOpenSurgeryReport,htmlOpenSurgeryReport} from "../../components/ExportCsv";

interface Props {
    report: Report,
    practice: Practice,
    app: App,
}

function ReferralDataFutureOsOffices(props: Props) {
    return (
        <Template  activeLink="Oral Surgery Dashboard Patient Care Report">
            <div><h1>Referral Data  Future OS Offices</h1></div>
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app,
    };
}

export default connect(mapStateToProps)(ReferralDataFutureOsOffices);