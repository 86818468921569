import React, { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";

function SingleRoadMapModal(props: any) {
    return (
        <>
            {
                <Modal className='modalCenter' size="large" onClose={() => props.onIndividualRoadmapModalClose()} open={true}>
                    <Modal.Header>
                        <b>CDP Website Roadmap - [{props.selectedSiteData.liveSite}]</b>
                        <div className="ui top right attached label production_right_label">
                            <Button onClick={() => props.onIndividualRoadmapModalClose()} inverted>
                                Close
                            </Button>
                        </div>
                    </Modal.Header>
                    <Modal.Content scrolling={true}>
                    <div><pre>{props.selectedSiteData.roadMap || "No roadmap defined"}</pre></div>
                    </Modal.Content>
                </Modal>
            }

        </>
    )
}

export default SingleRoadMapModal