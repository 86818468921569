import React, {ChangeEvent, useEffect, useState} from "react";
import {Button, Modal, Input, Header, TextArea, Form, Dropdown} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import {useActions} from "../redux/actions";
import * as practiceActions from "../redux/actions/practice";
import {SetupFields} from "../enums/userSetupField";
import {SetupAppName} from "../enums/userSetupAppName";
import * as spotActions from "../redux/actions/spot";
import * as Actions from "../redux/actions/userSettings";

function FeedbackModal(props: any) {
    const initialState = {
        practiceOptions: [] as any,
        formData: {} as any
    };
    const [state, setStates] = useState(initialState);
    const [defaultPractice, setDefaultPractice] = useState('');
    const practiceAction = useActions(practiceActions);
    const spotAction = useActions(spotActions);
    const userSettingsActions = useActions(Actions);

    useEffect(() => {
        practiceAction.fetchPractices(loadPractices);
    }, []);

    const loadPractices = async (practices: any[]) => {

        const params = {field: SetupFields.Practice, appId: SetupAppName.CDP_MY};
        const result = await Promise.all([spotAction.fetchPractices(), userSettingsActions.getSetup(params)])
        const defaultPractices = result[1] ? result[1].value.split(",") : [];

        let practiceOptions = practices.map((practice, key) => ({
            text: practice.practice,
            value: practice.practice,
            key
        }));

        // Set the defaultPractice to the first practice option
        if (practiceOptions.length > 0 && defaultPractices.length > 0) {
            const firstDefaultPractice: string[] = [];

            practiceOptions.forEach((practice) => {
                const matchedValue = defaultPractices.slice(1)
                .find((value: string) => value === practice.value.toLowerCase());
                if (matchedValue) {
                    firstDefaultPractice.push(practice.value);
                }
            });
            setDefaultPractice(firstDefaultPractice[0]);
        }
        practiceOptions = [
            {text: 'Support', value: 'Support', key: practiceOptions.length + 1},
            ...practiceOptions
        ]
        setStates({...state, practiceOptions})
    }

    const onChange = (e: any, data: any) => {
        let formData = state.formData;
        formData[data.name] = data.value;
        setStates({...state, formData});
        props.onChangeFeedback(formData);
    }

    const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        if(!e.target || !e.target.files) return;
        const value = await fileToBase64(e.target.files[0]);
        onChange(null, {name: 'file', value});
    }

    const fileToBase64 = async (file: any) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (e) => reject(e)
        })

    return (
        <Modal
            open={true}
            onClose={props.hideFeedbackModal}
            centered={false}
            size='tiny'
            closeIcon={true}
        >
            <Header content='Feedback'/>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Practice/Support</label>
                        <Dropdown
                            search={true}
                            name="practice"
                            placeholder="Practice"
                            selection={true}
                            options={state.practiceOptions}
                            onChange={onChange}
                            value={defaultPractice}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Subject</label>
                        <Input fluid={true} name='subject' placeholder='Subject' onChange={onChange}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Message</label>
                        <TextArea rows={6} fluid={true} name='message' onChange={onChange}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Attachments</label>
                        <input
                            name='file'
                            type="file"
                            onChange={onFileChange}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary={true} onClick={props.hideFeedbackModal}>
                    Cancel
                </Button>
                <Button className='ui primary button' onClick={props.onSubmitForm}>
                    Submit
                </Button>
            </Modal.Actions>
        </Modal>
    );
}


function mapStateToProps(state: RootState) {
    return {
        practice: state.practice
    };
}

export default connect(mapStateToProps)(FeedbackModal)
