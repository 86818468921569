import * as React from 'react';
import {Loader,Dimmer} from "semantic-ui-react";

export interface PMDRMPMProps {
  mgrFirstName : string, mgrLastName : string, regMgrFirstName: string, regMgrLastName : string,  
}

export interface PMDRMPMState {
  mgrFirstName : string, mgrLastName : string, regMgrFirstName: string, regMgrLastName : string,  
}

class PMDRMPM extends React.Component<PMDRMPMProps,PMDRMPMState> {  
  constructor(props: PMDRMPMProps) {
    super(props);
    this.state={
      mgrFirstName : props.mgrFirstName,
      mgrLastName : props.mgrLastName,
      regMgrFirstName : props.regMgrFirstName,
      regMgrLastName : props.regMgrLastName,
    }
    this.setMgrFirstName=this.setMgrFirstName.bind(this);
    this.setMgrLastName=this.setMgrLastName.bind(this);
    this.setRegMgrFirstName=this.setRegMgrFirstName.bind(this);
    this.setRegMgrLastName=this.setRegMgrLastName.bind(this);
  }

  setMgrFirstName(value: string) {
    this.setState({
      mgrFirstName : value
    })
  }

  setMgrLastName(value: string) {
    this.setState({
      mgrLastName : value
    })
  }
  
  setRegMgrFirstName(value: string) {
    this.setState({
      regMgrFirstName : value
    })
  }

  setRegMgrLastName(value: string) {
    this.setState({
      regMgrLastName : value
    })
  }

  componentWillReceiveProps(nextProps: PMDRMPMProps) {
    this.setState({
      mgrFirstName : nextProps.mgrFirstName,
      mgrLastName : nextProps.mgrLastName,
      regMgrFirstName : nextProps.regMgrFirstName,
      regMgrLastName : nextProps.regMgrLastName,
    })
  }

  checkValues(){
    return (!(this.props.mgrFirstName !== null ||
    this.props.mgrLastName !== null ||
    this.props.regMgrFirstName !== null ||
    this.props.regMgrLastName !== null) && <Dimmer active={true}>
     <Loader>Loading</Loader>
   </Dimmer>)
  }

  render() {
    return (
        <table className="pmd_tbl_no_border pm">
      <thead>
            <tr>
              <td><a className="ui blue image label"><span className="pmrm">{this.props.mgrFirstName+' '+this.props.mgrLastName}</span><div className="detail mb5"><span className="pmrm">PM</span></div>
      {this.checkValues()}</a></td>
              <td><a className="ui yellow image label"><span className="pmrm">{this.props.regMgrFirstName+' '+this.props.regMgrLastName}</span><div className="detail mb5"><span className="pmrm">RM</span></div>
      {this.checkValues()}</a></td>
            </tr>
          </thead>
      </table> 
    )
  }
}

export default PMDRMPM;