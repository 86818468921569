import { ActionType } from "../../model/actionTypes";
import {SageAccount} from "../../model/sageAccountList";
import {errorHandler} from "./auth";

export const saveSageAccount = (obj: SageAccount) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("sage-accounts/createSageAccount", obj);
        dispatch({ type: ActionType.SAVE_SAGE_ACCOUNT_SCUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const updateSageAccount = (obj:SageAccount) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("sage-accounts/update", obj);
        dispatch({ type: ActionType.UPDATE_SAGE_ACCOUNT_SCUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const getSageAccounts= (id:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        id=id||'all';
        const resp =  await api.get("sage-accounts/"+id);
        dispatch({ type: ActionType.FETCH_SAGE_ACCOUNT_SCUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const deleteSageAccounts= (data:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("sage-accounts/deleteSageAccounts/"+data.id);
        dispatch({ type: ActionType.FETCH_SAGE_ACCOUNT_SCUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};