import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const getMondayTasks = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const results = await api.get(`mondayApi/tasks`);
        dispatch({type: ActionType.LOADER, payload: false});
        return results;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
};

export const saveMondayTask = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const results = await api.post(`mondayApi/tasks`, data);
        dispatch({type: ActionType.LOADER, payload: false});
        return results;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
};