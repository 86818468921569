import PriceFormat from '../../../components/PriceFormat';
import { ReportFilterObject } from '../../../model/report'
import { isEmpty } from '../../../utils/common';
import { getReportData } from './Common';

/**
 * Returns data table array based on filtered data that is required to generate front end table
 * @param props (props holds all report data ex: billing, schedule, budget)
 * @param selectedPractices (it contains selected practices names if filter is applied for practice)
 */

export const filterObject: ReportFilterObject = (props, selectedPractices = [], reportName?: string) => {
    const { report, reportTN } = props;
    let returnObj: any = report && !isEmpty(report.reportNewData) ? report.reportNewData[0].reportData : reportTN.reportTimeAndName.length > 0 ? getReportData(reportTN, reportName) : [];

    returnObj=JSON.parse(JSON.stringify(returnObj));
    const practices = selectedPractices.map((r: any) => r.toLowerCase());
    if (practices.length == 0) {
        return returnObj
    }
    let practicesObj: any = [];
    returnObj.tableHead = returnObj && returnObj.tableHead && returnObj.tableHead.filter((r: any, i: number) => r.title == "" || i == returnObj.tableHead.length - 1 || practices.length == 0 || practices.includes(r.title.toLowerCase()));
    let arr = ["table1", "table2", "table3", "table4", "table5", "table6", "table7", "table8", "table9"];
    (returnObj && returnObj.tableData) && Object.keys(returnObj.tableData).forEach((key: any) => {
        if (arr.includes(key)) {
            returnObj.tableData[key].tableData = returnObj.tableData[key].tableData.map((r: any) => {
                let er = r.filter((ele: any, i: number) => {
                    if (!practicesObj[i] && ele.practiceName) {
                        practicesObj[i] = ele.practiceName.toLowerCase();
                    }
                    return i == 0 || i == r.length - 1 || practices.length == 0 || ele.practiceName && practices.includes(ele.practiceName.toLowerCase()) || practices.includes(practicesObj[i])
                })
                let total: any = 0;
                let count = 1;
                let CPVcount = 1;
                let cpvValue: any = 0;
                let percentTotal:any = 0;
                let cpvTotal:any = 0;
                er = er.map((ele: any, i: number) => {
                    const arrChar = [')', '(', ",", "$","%","-"];
                    let num: any = ele.title + "";
                    if (num && i != er.length - 1 && i != 0) {
                        arrChar.forEach((item: any) => {
                            if (num.includes(item)) {
                                num = num.split(item).join("");
                            }
                        })
                        num = isNaN(num) ? 0 : Number(num);
                        if((ele.title + "").includes("%")){
                            percentTotal += ((ele.title + "").includes("(") || (ele.title + "").includes("-")) ? -1 * num : num;
                            total = percentTotal/count;
                            total = Number(total.toFixed(2));
                            if((total + "").includes("-")){
                                total = "("+total+")"
                            }
                            total = total+"%";
                        }
                        else if (ele.popupTitle&&(ele.popupTitle+ "").includes("CPV") && !(ele.title + "").includes("%")) {
                            cpvValue += ((ele.title + "").includes("(") || (ele.title + "").includes("-")) ? -1 * num : num;
                            if(!(ele.title + "").includes("no schedule")&& num!==0){
                                cpvTotal = cpvValue/CPVcount;
                                CPVcount++;
                            }
                            cpvTotal = Math.round(cpvTotal.toFixed(2));

                            if((cpvTotal + "").includes("-")){
                                cpvTotal = "("+cpvTotal+")"
                            }
                            total = cpvTotal
                        }
                        else{
                            total += ((ele.title + "").includes("(") || (ele.title + "").includes("-")) ? -1 * num : num;
                        }
                        count++;
                    } else if (ele.title && i == er.length - 1 && practicesObj.length != practices.length) {
                        if((ele.title + "").includes("%")){
                            total = total < 0 ? '(' + total + ')' : total.toString();
                            total = total.split("-").join("");
                            let percIndex:number = total.indexOf("%")
                            total = total.substring(0,percIndex+1)
                        }
                        else{
                            total=PriceFormat(total);
                        }
                        ele.title = num.includes("$") ? '$' + total: total;

                        if(key === 'table3' && ele.className && ele.className !== 'pcr_tbl_numbers_clickable') {
                            let delta: any = 0;
                            if (ele.title.includes('(')) {
                                delta = ele.title.substring(2, ele.title.length - 1)
                                delta = delta / (i - 1)
                                ele.title = '$(' + Math.round(delta) + ")"
                            }
                            else {
                                delta = ele.title.substring(1, ele.title.length)
                                delta = delta / (i - 1)
                                ele.title = '$' + Math.round(delta)
                            }
                        }
                    }
                    return ele;
                })

                return er
            });
        }
    });
    return returnObj;
}