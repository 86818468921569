import React from "react";
import {Button, Modal} from "semantic-ui-react";

interface DeleteModalProps {
    cancel: () => void,
    confirm: Function
}
function DeleteConfirm (props: DeleteModalProps) {

    const {confirm, cancel} = props;
    return (

        <Modal
            onClose={() => cancel}
            open={true}
            size="small"
        >
            <Modal.Header><h4>Are you sure to delete?</h4></Modal.Header>
            <Modal.Actions>
                <Button primary={true} onClick={()=>confirm()}>Yes</Button>
                <Button secondary={true} onClick={cancel}>No</Button>
            </Modal.Actions>
        </Modal>

    );
}
export default DeleteConfirm