import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";

const initialState:any = {
	successMsg: null
};

export const message = createReducer(initialState , {
	[ActionType.MESSAGE](_store: any, action: Action<any>) {
		return action.payload;
	}
});
