import React, {SyntheticEvent, useEffect, useState} from 'react';
import Template from '../../components/Template';
import {useActions} from "../../redux/actions";
import * as reportActions from "../../redux/actions/report";
import {Practice, UdaPracticeInfo} from "../../model/practice";
import {ClinicalDataReport, ClinicalReport, Report} from "../../model/report";
import {App, RootState} from "../../model";
import {connect} from 'react-redux';
import * as practiceActions from "../../redux/actions/practice";
import {brands, CSRGraphOptions, csrHeader} from '../../constants';
import {sortBy} from '../../utils/common';
import {Bar} from 'react-chartjs-2'
import {Checkbox, Dimmer, Dropdown, Icon, Loader, Pagination} from "semantic-ui-react";
import {exportClinicalReport} from "../../components/ExportCsv";
import HelpMark from "../../components/HelpMark";
import {SetupFields} from '../../enums/userSetupField';
import {SetupAppName} from '../../enums/userSetupAppName';
import * as userSetupActions from "../../redux/actions/userSettings";
import * as spotActions from "../../redux/actions/spot";
import Loading from '../../Loading';

interface Props {
  report: Report,
  practice: Practice,
  app: App,
  totalRecords: number,
}


interface Option {
  value: string;
  text: string;
  key: string;
}

interface FilterOptions {
  practice: string[];
  region: string;
  brands: string;
  practiceOptions: Option[];
  regionOptions: Option[];
  isCallCenter: boolean;
}

interface State {
  filters: FilterOptions;
  report: ClinicalReport;
  page: number,
  pageSize: number,
  practices: any,
  totalRecords: number,
  isPaginatingOnBackEnd: boolean,
  loading: boolean,
}


let filtersInUse: any;
function ClinicalSchedulingReport(props: Props) {

  const initialState = {
    filters: {
      practice: [], 
      region: '', 
      brands: '', 
      practiceOptions: [{key: 'all', value: 'all', text: 'All Practices'}], 
      regionOptions: [], 
      isCallCenter: false
    },
    page: 1,
    pageSize: 5, 
    totalRecords: 0,
    isPaginatingOnBackEnd: false,
    report: {},
    practices: [],
    loading: false,
  };
  const reportAction = useActions(reportActions);
  const practiceAction = useActions(practiceActions);
  const userSetup = useActions(userSetupActions);
  const spotAction = useActions(spotActions);
  const [state, setState] = useState<State>(initialState);

  const refinePracticeArray = (response: []) => {
    let practiceAbbr: {[key: string]: string} = {};
    let practiceData: {[key: string]: object} = {};
    let practiceOptions: Array<Option> =
        [...state.filters.practiceOptions];
    sortBy(response, 'regionId');
    if (response) {
      response.forEach((item: {id: number, practice: string, practiceabbr: string, callCenter: boolean, practiceDBName: string}) => {
        practiceAbbr[item.practice.toLowerCase()] = item.practiceabbr;
        practiceOptions.push({text: item.practice, value: item.practice, key: item.id.toString()});
        practiceData[item.practice.toLowerCase()] = { practice: item.practice, callCenter: item.callCenter, practiceDBName: item.practiceDBName }
      })
    }
    const practice = {practiceAbbr: practiceAbbr, practiceOptions: practiceOptions, practiceData};
    const filters = Object.assign(state.filters, {practiceOptions: practiceOptions});
    setState({...state, filters, practices: response});
    return practice
  };

  const refineRegionsArray = (regionList: []) => {
    let regionListOptions: Array<{text: string, value: null|number, key: number}> =
        [{key: 0, value: null, text: 'Select Region'}];
    if (regionList.length) {
      regionList.forEach((item: {name: string, id: number}) => {
        regionListOptions.push({text: item.name, value: item.id, key: item.id})
      });
    }
    const filters = Object.assign(state.filters, {regionOptions: regionListOptions});
    setState({...state, filters});
    return regionListOptions;
  };


  useEffect( () => {
    let userParams = { field: SetupFields.Practice, appId: SetupAppName.CDP_MY };
    let practices: any = [];

    userSetup.getSetup(userParams).then((result: any) => {

      const defaultPractices: string[] = result && result.value ? result.value.split(",") || [] : [];
      const filterdDefaultPractices = defaultPractices.filter((item: string) => item !== 'all')

      practiceAction.fetchRegions(refineRegionsArray);
      practiceAction.fetchPracticesAll(refinePracticeArray)
        .then(async (practicesResponse: any) => {

          
        spotAction.fetchPractices()
        .then(async (spotPractices: any) => {

          if(defaultPractices.length == 1 || defaultPractices.length == 0) {

            practices = defaultPractices;
          } else {

            const practiceOptions = state.filters.practiceOptions;
            filterdDefaultPractices.forEach(fDP => {

              const spotPractice = spotPractices.find((sP: any) => sP.practiceDBName === fDP);
              if(spotPractice !== undefined) {
                const practiceOption = practiceOptions.find((p: any) => p.key == spotPractice.id);
                if(practiceOption !== undefined) {
                  practices.push(practiceOption.value);
                }
              }
            });
          }

          practices = practices.length ? practices : ['all'];
          const isPaginating = practices.length == 1 && practices.includes('all') ? true : false;
          setState({ ...state, loading:true })
  
          const reports: ClinicalReport[] = await Promise.all(practices.map((option: any) => {
              if(isPaginating == true){
  
                return reportAction.fetchClinicalScheduleData(option.toLowerCase(), state.page, state.pageSize);
  
              } else {
  
                return reportAction.fetchClinicalScheduleData(option.toLowerCase());
              }
            }
          ));
          
          const report: ClinicalReport = reports.reduce((mergedReport, currentReport) => {
            return { ...mergedReport, ...currentReport };
          }, {});
          
          filtersInUse = {name: 'brands', value: 'All'};
          const filters = { ...state.filters, region: '', practice: practices };
          setState({ ...state, 
            isPaginatingOnBackEnd: isPaginating,
            report,
            loading: false, 
            filters 
          });

        });
      });
    });
  }, []);

  const brandsOptions=()=> {
    const brandOptions: string[] = []
    const brandsObject: { [key: string]: string[] } = {}
    brands.forEach((brand) => {
      const res = props.practice.udaPracticeInfo.filter(
          (item: UdaPracticeInfo) => {
            if (item.brand === brand.value) {
              return true
            }
          }
      ).map(item => item.practiceName)
      brandsObject[brand.value] = [...res.map(i => i.toLocaleLowerCase())]
    });
    //if (state.filters.region) {
    return (brandOptions.length > 0) ? brandOptions.map(b => {
          return {
            value: b,
            key: b,
            text: b
          }
        }) :
        brands
    //}
  }


  function filterRoutes(element: { [p: string]: any }) {
    switch (element.name) {
      case 'region': {
        onRegionSelect(1, element);
        break;
      }
      case 'practice': {
        onPracticeSelect(1, element);
        break;
      }
      case 'brands': {
        onBrandsSelect(1, element);
        break;
      }
      default:
        onCallCenterChange(element)
    }
  }

  const calculateLastIndex = (limit: number, page: number) => {
    const startIndex = calculateStartIndex(page);
    const lastIndex = startIndex + limit - 1;

    return lastIndex;
  }

  const calculateStartIndex = (page: number) => {
    const startIndex = Math.max(0, page - 1) * 5;
    return startIndex;
  }

  const onChangeFilter = (e: SyntheticEvent, element: { [key: string]: any }) => {
    filtersInUse = element;
    filterRoutes(element);
  };

  const onBrandsSelect = async (page: number, element: { [key: string]: any }) => {
    let filters = state.filters;
    filters.brands = element.value;
    let selectedPractices: string[] = [];
    const tempBrandFilterPractices: any = [];

    let practiceListBrands=[];
    let info:any =  props.practice.practiceNamesMap;
    for (const key in info) {
      practiceListBrands.push({practiceName:info[key].practice,
        brand:info[key].brand})
    }
    const res = practiceListBrands.filter(
        (item:any) => {
          if (item.brand === element.value) {
            return true
          }
        }
    )
    tempBrandFilterPractices.push(
        ...res.map(prac => {
          return prac.practiceName
        })
    )
    selectedPractices = Array.from(new Set([...tempBrandFilterPractices])).sort();
    setState({ ...state, loading:true })
    
    const startIndex = calculateStartIndex(page);
    const lastIndex = calculateLastIndex(state.pageSize, page);
    
    state.totalRecords = selectedPractices.length;
    selectedPractices = selectedPractices.length 
      ? selectedPractices
      : ['all'];
      
    const isPaginatingOnBackEnd = selectedPractices.length == 1 && selectedPractices.includes('all') ? true : false;
    const practicesToUse = isPaginatingOnBackEnd ? selectedPractices : selectedPractices.filter(i => selectedPractices.indexOf(i) >= startIndex && selectedPractices.indexOf(i) <= lastIndex);

    const reports: ClinicalReport[] = await Promise.all(practicesToUse.map((option: any) => {
      if(isPaginatingOnBackEnd == true){

        return reportAction.fetchClinicalScheduleData(option.toLowerCase(), state.page, state.pageSize);

      } else {

        return reportAction.fetchClinicalScheduleData(option.toLowerCase());
      }
    }));

    const report: ClinicalReport = reports.reduce((mergedReport, currentReport) => {
      return { ...mergedReport, ...currentReport };
    }, {});

    setState({ ...state, 
        page: page,
        isPaginatingOnBackEnd: isPaginatingOnBackEnd,
        report, 
        loading: false, 
        filters: { ...state.filters, region: '', brands: element.value, practice: selectedPractices.length ? selectedPractices : ['all'] } 
      });
  };

  const onCallCenterChange = (element: {[key: string]: any}) => {
    const clinicalScheduleReport: ClinicalReport = props.report.clinicalScheduleData;
    const practiceData: any = props.practice.practiceData;
    const report = {};
    const filter = Object.assign(state.filters, {isCallCenter: element.checked});
    const { region } = state.filters;
    if (!region) {
      const reportKeys = Object.keys(clinicalScheduleReport);
      reportKeys.forEach((item: string) => {
        const practice = practiceData[item];
        if(!element.checked)
          Object.assign(report, {[item]: clinicalScheduleReport[item]});

        if (element.checked && practice && Boolean(practice.callCenter) === element.checked) {
          Object.assign(report, {[item]: clinicalScheduleReport[item]});
        }
      });
      setState({...state, filters: filter, report });
      return;
    }
    const regionKeys: [] = props.practice.regionPractices[parseInt(state.filters.region)];
    regionKeys.forEach((practice: string) => {
      const practiceInfo = practiceData[practice];
      if (practiceInfo && Boolean(practiceInfo.callCenter) === element.checked) {
        Object.assign(report, {[practice]: clinicalScheduleReport[practice]});
      }
    });
    setState({...state, filters: filter, report })
  };

  const getGraphData = (dataObj: {[key: string]: number}) => {
    let data: { labels: number[], datasets: [{ backgroundColor: string[], data: number[] }] } = {
      labels: [],
      datasets: [{
        backgroundColor: [],
        data: []
      }]
    };
    const startingHour = 7;
    const endHour = 18;
    const maxNum = Math.max.apply(null, Object.values(dataObj));
    for (let key = startingHour; key <= endHour; key++) {
      const numbers = dataObj[key] ? dataObj[key] : 0;
      data.labels.push(key);
      data.datasets[0]['data'].push(numbers);
      if (numbers === maxNum) {
        data.datasets[0]['backgroundColor'].push('#B30400');
      } else {
        data.datasets[0]['backgroundColor'].push('#47628C');
      }
    }
    return data;
  };

  const getTableHeadings = () => {
    let returnArray: React.ReactElement[] = [];
    if (csrHeader.length) {
      csrHeader.forEach((item, key) => {
        returnArray.push(<th key={item.key || key}><div>{item.title}</div></th>)
      })
    }
    return (<thead>
    <tr>{returnArray}</tr>
    </thead>)
  };

  const getTableRows = () => {
    const report: ClinicalReport = state.report;

    const toCurrency = (value: number) => `$${(+(value || 0)).toFixed(2)}`;

    if (report) {
      let reportRows: React.ReactElement[] = [];
      const practices = Object.keys(report);

      practices.forEach((practiceName: string, index: any) => {
        const data = report[practiceName];
        if (data && data.length > 0) {
          reportRows.push(<tr  key={index + practiceName}>
            <td className="bgLightGray">{practiceName.toUpperCase()}</td>
            <td className="bgLightGray" colSpan={9} style={{borderLeft: 'none'}}/>
          </tr>);
          data.forEach((
              detail: ClinicalDataReport, index: number) => {
            let tableRow = [];
            let style = {}, confirmedStyle = {};
            if (parseFloat(detail.ptsPerDentist) > 20 && parseFloat(detail.ptsPerDentist) < 25) {
              style = {backgroundColor: 'yellow'}
            } else if (parseFloat(detail.ptsPerDentist) >= 25) {
              style = {backgroundColor: 'red', color: 'white'}
            }
            if (detail.drCount > 0 && detail.allAptConfirmed > parseInt(detail.ptsPerDentist)) {
              if (detail.allAptConfirmed >= 10 && detail.allAptConfirmed <= 20) {
                confirmedStyle = {backgroundColor: 'green', color: 'white'}
              } else if (detail.allAptConfirmed > 20 && detail.allAptConfirmed < 25) {
                confirmedStyle = {backgroundColor: 'yellow'}
              } else if (detail.allAptConfirmed >= 25) {
                confirmedStyle = {backgroundColor: 'red', color: 'white'}
              }

            }
            tableRow.push(<td key={index + detail.aptDate}>{detail.aptDate}-{detail.day}</td>,
                <td key={index + 'aptCount'}>{detail.aptCount}</td>,
                <td key={index + 'show'}>{detail.show > 0 ? detail.show : ''}</td>,
                <td key={index + 'drCount'}>{detail.drCount}</td>,
                <td style={style} key={index + 'ptsPerDentist'}>{detail.ptsPerDentist}</td>,
                <td key={index + 'tscare'}>{toCurrency(detail.totalScheduledCare)}</td>,
                <td key={index + 'escare'}>{toCurrency(detail.expectedScheduledCare)}</td>,
                <td key={index + detail.broken}>{detail.broken}</td>,
                <td style={confirmedStyle} key={index + '_allAptConfirmed'}>{detail.allAptConfirmed ? detail.allAptConfirmed : ''}</td>,
                <td key={index + '_txAptsConfirmed'}>{detail.txAptsConfirmed ? detail.txAptsConfirmed : ''}</td>,
                <td key={index + '_confirmedDigitally'}>{detail.confirmedDigitally ? detail.confirmedDigitally : ''}</td>
            );
            tableRow.push(<td style={{width: '150px', paddingTop: '0', paddingBottom: '0'}} key={index + '_totalHours'}>
              <Bar data={getGraphData(detail.totalHours)} width={14} height={40} options={CSRGraphOptions}/>
            </td>);
            tableRow.push(<td style={{width: '150px', paddingTop: '0', paddingBottom: '0'}} key={index + '_confirmedHours'}>
              <Bar data={getGraphData(detail.confirmedHours)} width={14} height={40} options={CSRGraphOptions}/>
            </td>);
            reportRows.push(<tr>{tableRow}</tr>)
          });
        } else if (practices.length === 1) {
          reportRows.push(<tr  key={index + 'no_records'}>
            <td colSpan={10}>No record found</td>
          </tr>)
        }
      });
      return <tbody>{reportRows}</tbody>;
    }
  };

  const sendCSREmail = () => {
    const allPractices: string[] = Object.keys(state.report);
    const practiceName = allPractices.join(',').toLowerCase();
    reportAction.sendMailCSR(practiceName);
  };

  const onClickPage = async (page: any) => {
    switch (filtersInUse.name) {
      case 'region': {
        onRegionSelect(page, filtersInUse);
        break
      }
      case 'practice': {
        onPracticeSelect(page, filtersInUse);
        break;
      }
      case 'brands': {
        onBrandsSelect(page, filtersInUse);
        break;
      }
      default:
        break;
    }
  }

  const onRegionSelect = async (page: number, element: {[key: string]: any}) => {

    setState({ ...state, loading:true });

    if (!element.value) {
      const reports: ClinicalReport[] = await Promise.all(['all'].map((option: any) =>
          reportAction.fetchClinicalScheduleData(option.toLowerCase(), 1, state.pageSize)
      ));
      const report: ClinicalReport = reports.reduce((mergedReport, currentReport) => {
        return { ...mergedReport, ...currentReport };
      }, {});
      setState({ ...state, isPaginatingOnBackEnd: true, page: 1, report, loading: false, filters: { ...state.filters, practice: [], region: '' } });

      return;
    }

    const regionKeys: [] = props.practice.regionPractices[element.value];
    state.totalRecords = regionKeys.length;

    const startIndex = calculateStartIndex(page);
    const lastIndex = calculateLastIndex(state.pageSize, page);

    const reports: ClinicalReport[] = await Promise.all(
      regionKeys.filter(i => regionKeys.indexOf(i) >= startIndex && regionKeys.indexOf(i) <= lastIndex)
        .map((option: any) => reportAction.fetchClinicalScheduleData(option.toLowerCase())
    ));

    const report: ClinicalReport = reports.reduce((mergedReport, currentReport) => {
      return { ...mergedReport, ...currentReport };
    }, {});

    setState({ ...state, 
      isPaginatingOnBackEnd: false, 
      report, 
      loading: false, 
      filters: { ...state.filters, practice: [], region: element.value } });
    return;
  };

  const onPracticeSelect = async (page: number, element: { [key: string]: any }) => {
    const practiceData: any = props.practice.practiceData;
    const { isCallCenter } = state.filters;
    let selectedPractices = Array.isArray(element.value) ? element.value : [element.value]; // Get selected practices as an array
    setState({ ...state, loading:true })

    if (selectedPractices.length === 0 || (selectedPractices.length === 1 && selectedPractices[0] === 'all') || (selectedPractices.includes('all') && selectedPractices.length > 2)) {

      const reports: ClinicalReport[] = await Promise.all(['all'].map((option: any) =>
          reportAction.fetchClinicalScheduleData(option.toLowerCase(), page, state.pageSize)
      ));

      const report: ClinicalReport = reports.reduce((mergedReport, currentReport) => {
        return { ...mergedReport, ...currentReport };
      }, {});

      setState({ ...state, 
        isPaginatingOnBackEnd: true, 
        page: page,
        report, 
        loading: false, 
        filters: { ...state.filters, region: '', brands: '', practice: ['all'] } });
      return;

    } else {

      const startIndex = calculateStartIndex(page);
      const lastIndex = calculateLastIndex(state.pageSize, page);

      selectedPractices = selectedPractices.filter((item: string) => item !== 'all');
      const filtersToUse = selectedPractices.filter((item: string) => selectedPractices.indexOf(item) >= startIndex && selectedPractices.indexOf(item) <= lastIndex);

      const reports: ClinicalReport[] = await Promise.all(filtersToUse.map((option: any) => {
            const practiceInfo = practiceData[option];
            if (!isCallCenter || (practiceInfo && Boolean(practiceInfo.callCenter) === isCallCenter)) {
              return reportAction.fetchClinicalScheduleData(option.toLowerCase())
            }
          })
      );

      const report: ClinicalReport = reports.reduce((mergedReport, currentReport) => {
        return { ...mergedReport, ...currentReport };
      }, {});

      setState({ ...state, 
        isPaginatingOnBackEnd: false, 
        page: page,
        report, 
        loading:false, 
        totalRecords: selectedPractices.filter((item: string) => item !== 'all').length, 
        filters: { ...state.filters, region: '', brands: '', practice: selectedPractices } 
      });
    }
  };


  return (
      <Template activeLink="clinical scheduling report">
        {state.loading && <Dimmer active={true} inverted={true}>
          <Loading />
        </Dimmer>
        }
        <div className="ui card">
          <div className="content pb0">
            <h2 className="float-left mr10 mb10">CDP Clinical Scheduling Report <HelpMark pageId="46"/></h2>
            <div className="topFilters">
              <Checkbox
                  className="mr30"
                  name="Call Center"
                  label='Call Center Supported'
                  onClick={onChangeFilter}
                  checked={state.filters.isCallCenter}
              />
              <a className="link mr30" onClick={sendCSREmail}><Icon name="send"/> Send</a>
              <a className="link" onClick={() => exportClinicalReport(state.report)}><Icon name="file alternate"/>Export</a>
              <Dropdown
                  search={true}
                  className='mr10 mb15'
                  name="region"
                  placeholder="Region"
                  selection={true}
                  options={state.filters.regionOptions}
                  onChange={onChangeFilter}
                  value={state.filters.region}
              />
              <Dropdown
                  search={true}
                  className='mr10 mb15'
                  name="practice"
                  placeholder="Practice"
                  multiple={true}
                  selection={true}
                  options={state.filters.practiceOptions}
                  onChange={onChangeFilter}
                  value={state.filters.practice}
              />
              <Dropdown
                  search={true}
                  className='mr10 mb15'
                  name="brands"
                  placeholder="Brands"
                  selection={true}
                  options={brandsOptions()}
                  onChange={onChangeFilter}
                  value={state.filters.brands}
              />
            </div>
          </div>
        </div>

        <div className="ui card">
          <div className="content">
            <div className="table-responsive FreezeTable">
              <table className="ui celled single line table unstackable tableStyle CSR">
                {getTableHeadings()}
                {getTableRows()}
              </table>
            </div>
            { 
              <Pagination onPageChange={(_e, page: any) => onClickPage(page.activePage)}
                          activePage={state.page} 
                          totalPages={Math.ceil((props.totalRecords ? props.totalRecords : state.totalRecords) /state.pageSize)}
                        />
            }
          </div>
        </div>
      </Template>
  )
}

function mapStateToProps(state: RootState) {

  return {
    report: state.report,
    practice: state.practice,
    app: state.app,
    totalRecords: state.report.totalRecords
  };
}

export default connect(mapStateToProps)(ClinicalSchedulingReport);
