import { validArr } from "../../../utils/common";

/**
 * Check filter has valid array value or not
 * @param _fil 
 * @returns Boolean Value
 */

export const getActiveFillterStatus = (_fil: any) => {
    const {practice, drType, region, brands} = _fil;
    if (!validArr(practice) && !validArr(drType) && !validArr(region) && !validArr(brands)) {
        return true;
    }

    return false;
};
