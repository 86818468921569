import React, { useEffect, useState } from 'react'
import { TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, Label } from 'semantic-ui-react'
import { dentalXChangeFields } from '../healthStatusUtils';
import ProgressBar from '../ProgressBar';

interface props {
    data: any,
}
const DentalXChangeTable = ({ data }: props) => {
    const count = { pending: 0, failed: 0, success: 0 }
    const initialState = { uhc: count, mcna: count, denta: count, tmhp: count, pop: count };
    const [state, setState] = useState({ view: false, insurance: initialState });
    const { insurance, view } = state
    useEffect(() => countStatuses(), [data])

    const countStatuses = () => {
        const categories = ['uhc', 'denta', 'mcna', 'tmhp', 'pop'];
        let result: any = {};

        categories.forEach((category: any) => {
            result[category] = { pending: 0, failed: 0, success: 0 };
        });

        data.forEach((item: any) => {
            dentalXChangeFields.forEach(field => {
                const category = field.name.split('_')[0];
                const value = item[field.name];

                if (value === 'green') {
                    result[category].success += 1;
                } else if (value === 'red') {
                    result[category].failed += 1;
                } else if (value === 'yellow' || value === null) {
                    result[category].pending += 1;
                }
            });
        });

        setState({ ...state, insurance: result })
    };

    const toggleView = (view: boolean) => {
        setState({ ...state, view: view })
    }

    return (
        <>
            <Table striped selectable compact='very'>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Brand</TableHeaderCell>
                        <TableHeaderCell>Practice</TableHeaderCell>
                        <TableHeaderCell>Abbr</TableHeaderCell>
                        <TableHeaderCell>UHC</TableHeaderCell>
                        <TableHeaderCell>DENTA</TableHeaderCell>
                        <TableHeaderCell>MCNA</TableHeaderCell>
                        <TableHeaderCell>THMP</TableHeaderCell>
                        <TableHeaderCell>PPO</TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    <Table.Row title='Click to view more ...' onClick={() => toggleView(!view)} style={{cursor: 'pointer'}} error>
                        <TableCell ><strong>All</strong></TableCell>
                        <TableCell><strong>All</strong></TableCell>
                        <TableCell><strong>All</strong></TableCell>
                        <TableCell>
                            <ProgressBar success={insurance.uhc.success} pending={insurance.uhc.pending} failed={insurance.uhc.failed} />
                        </TableCell>
                        <TableCell>
                            <ProgressBar success={insurance.denta.success} pending={insurance.denta.pending} failed={insurance.denta.failed} />
                        </TableCell>
                        <TableCell>
                            <ProgressBar success={insurance.mcna.success} pending={insurance.mcna.pending} failed={insurance.mcna.failed} />
                        </TableCell>
                        <TableCell>
                            <ProgressBar success={insurance.tmhp.success} pending={insurance.tmhp.pending} failed={insurance.tmhp.failed} />
                        </TableCell>
                        <TableCell>
                            <ProgressBar success={insurance.pop.success} pending={insurance.pop.pending} failed={insurance.pop.failed} />
                        </TableCell>
                    </Table.Row>


                    {
                     view &&   data.map((logs: any, index: number) =>
                            <TableRow key={index}>
                                <TableCell>{logs.brand}</TableCell>
                                <TableCell>{logs.practice}</TableCell>
                                <TableCell>{logs.abbr}</TableCell>
                                <TableCell>
                                    <Label style={{ width: '100%' }} size='big' color={logs.uhc_dentalXChange || 'yellow'} horizontal></Label>
                                </TableCell>
                                <TableCell>
                                    <Label style={{ width: '100%' }} size='big' color={logs.denta_dentalXChange || 'yellow'} horizontal></Label>
                                </TableCell>
                                <TableCell>
                                    <Label style={{ width: '100%' }} size='big' color={logs.mcna_dentalXChange || 'yellow'} horizontal></Label>
                                </TableCell>
                                <TableCell>
                                    <Label style={{ width: '100%' }} size='big' color={logs.tmhp_dentalXChange || 'yellow'} horizontal></Label>
                                </TableCell>
                                <TableCell>
                                    <Label style={{ width: '100%' }} size='big' color={logs.pop_dentalXChange || 'yellow'} horizontal></Label>
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </>
    )
}


export default DentalXChangeTable