import { Action, ActionType } from "../../model/actionTypes";
import { EducatorNotification } from "../../model/notifications";
import createReducer from "./createReducer";


const initialState = {  
    notifications: []
  };
export const educatorNotification = createReducer(initialState, {
  [ActionType.LOAD_EDUCATOR_NOTIFICATION](state: EducatorNotification, action: Action<EducatorNotification>) {
    return Object.assign({...state}, action.payload);
  },
  [ActionType.LOAD_EDUCATOR_NOTIFICATION_SAVE](state: EducatorNotification, action: Action<EducatorNotification>) {
    return Object.assign({...state}, action.payload);
  }
 
});