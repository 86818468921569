import {connect} from "react-redux";
import {User} from "../../../model/user";
import {useActions} from "../../../redux/actions";
import Template from "../../../components/Template";
import React, {useState, useEffect} from "react";
import * as awsHostedSitesActions from "../../../redux/actions/awsHostedSites";
import * as practiceActions from "../../../redux/actions/practice";
import {HostedSite, PracticeData} from "./interfaces";
import AddSiteModal from "./modals/AddSiteModal";
import {SitesDetails} from "../../../model/awsHostedSites";
import * as sitesActions from "../../../redux/actions/awsHostedSites";
import DeleteSiteModal from "./modals/DeleteSiteModal";

const initialState: {
    lighthouseScoreList: Array<{}>,
    externalHostedList: Array<HostedSite>,
    cdpHostedList: Array<HostedSite>,
    practiceList: Array<PracticeData>,
    actualData: any[],
    rawHostedSiteList: HostedSite[]
} = {
    cdpHostedList: [],
    externalHostedList: [],
    lighthouseScoreList: [],
    practiceList: [],
    actualData: [],
    rawHostedSiteList: []
}

interface Props {
    auth: User
}

function AWSWebsites(props: Props) {
    const practiceAction = useActions(practiceActions)
    const awsHostedSitesAction = useActions(awsHostedSitesActions)

    const [activeTab, setActiveTab] = useState('cdp')
    const [state, setState] = useState(initialState)

    const [showAddSites, setShowAddSites] = useState(false);
    const [showDeleteSite, setShowDeleteSite] = useState(false);
    const [showEditPopupModal, setShowEditPopupModal] = useState(false);
    const sitesAction = useActions(sitesActions)

    const [searchTerm, setSearchTerm] = useState('');


    const showAddSitesModal = () => {
        setShowAddSites(!showAddSites);
    }

    const showDeleteSitesModal = () => {
        setShowDeleteSite(!showDeleteSite);
    }

    useEffect(() => {
        practiceAction.fetchWebsitesPracticesAlt().then((practiceList: PracticeData[]) => {

            console.log({practiceList})

            // @ts-ignore
            practiceList.push({id: 0, practice: 'CORP', brand: 'CDP'});

            setState({
                ...state,
                practiceList
            })

            awsHostedSitesAction.getHostedSites().then((hostedSites: HostedSite[]) => {
                const removedDuplicates: HostedSite[] = hostedSites.reduce((accumulator: any, current: any) => {
                    if (!accumulator.find((item: any) => item.practice === current.practice)) {
                        accumulator.push(current)
                    }
                    return accumulator;
                }, [])

                const populatedHostedSites: HostedSite[] = removedDuplicates.map((site: HostedSite) => {
                    site.practice = site.practice.split(',')[0].trim()
                    site.rawPracticeList = site.practice.split(',').map((item) => item.trim())
                    
                    const practiceInformation: undefined | PracticeData = practiceList.find(
                        (item: PracticeData) => {

                            const itemName = (item.practice || item.practiceName) || "Name Not Specified";
                            const siteName = site.practice || "Name Not Specified";

                            return itemName.trim().toLowerCase() === siteName.trim().toLowerCase()
                        }
                    );

                    site.brand = practiceInformation ? practiceInformation.brand : "No Brand";

                    return site;
                })

                awsHostedSitesAction.getLighthouseScores().then((lighthouseResults: any) => {
                    setState({
                        ...state,
                        cdpHostedList: populatedHostedSites.filter((item: HostedSite) => item.contentManager.toLowerCase() === 'cdp'),
                        externalHostedList: populatedHostedSites.filter((item: HostedSite) => item.contentManager.toLowerCase() !== 'cdp'),
                        lighthouseScoreList: lighthouseResults,
                        rawHostedSiteList: populatedHostedSites
                    })
                })
            })
        })
    }, []);

    function switchTab(tab: string) {
        setActiveTab(tab);
    }

    const onSearchTermChanged = (term: string) => {
        setSearchTerm(term)

        const searchMatchingItems = (item: HostedSite) => 
            item?.brand?.toLowerCase().includes(term.toLowerCase()) || 
            item.practice.toLowerCase().includes(term.toLowerCase()) || 
            item.liveSite.toLowerCase().includes(term.toLowerCase());

        setState({
            ...state,
            cdpHostedList: state.rawHostedSiteList.filter((item: HostedSite) => searchMatchingItems(item) && item.contentManager.toLowerCase() === 'cdp'),
            externalHostedList: state.rawHostedSiteList.filter((item: HostedSite) => searchMatchingItems(item) && item.contentManager.toLowerCase() !== 'cdp'),
        })
    }

    return (
        <Template activeLink='aws websites'>
            {showAddSites && <AddSiteModal id={'modal'} showAddSitesModalHandler={showAddSitesModal} />}
            {showDeleteSite && <DeleteSiteModal id={'delete-modal'} hideDeleteSiteModal={showDeleteSitesModal} />}

            <main className='aws-websites-root poppins-regular'>
                <div className="websites-header">
                    <div className="card">
                        <div className="card-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64"/>
                            </svg>
                        </div>
                        <div className="card-content">
                            <h3 className="card-title">Online Websites</h3>

                            <h2 className="card-number">
                                {state.rawHostedSiteList.length}
                            </h2>
                        </div>
                    </div>

                    <div className="card" onClick={() => setActiveTab('external')}>
                        <div className="card-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M2.25 15a4.5 4.5 0 0 0 4.5 4.5H18a3.75 3.75 0 0 0 1.332-7.257 3 3 0 0 0-3.758-3.848 5.25 5.25 0 0 0-10.233 2.33A4.502 4.502 0 0 0 2.25 15Z"/>
                            </svg>
                        </div>
                        <div className="card-content">
                            <h3 className="card-title">External Hosted Websites</h3>

                            <h2 className="card-number">
                                {state.rawHostedSiteList.filter(i => i.contentManager.toLocaleLowerCase() !== 'cdp').length}
                            </h2>
                        </div>
                    </div>

                    <div className="card" onClick={() => setActiveTab('cdp')}>
                        <div className="card-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Zm0 3h.008v.008h-.008v-.008Z"/>
                            </svg>
                        </div>
                        <div className="card-content">
                            <h3 className="card-title">CDP Hosted Sites</h3>

                            <h2 className="card-number">
                            {state.rawHostedSiteList.filter(i => i.contentManager.toLocaleLowerCase() === 'cdp').length}
                            </h2>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                 stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"/>
                            </svg>
                        </div>
                        <div className="card-content">
                            <h3 className="card-title">Reported Incidents</h3>

                            <h2 className="card-number">
                                8
                                <span className="down">
                               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                    stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round"
        d="M2.25 6 9 12.75l4.286-4.286a11.948 11.948 0 0 1 4.306 6.43l.776 2.898m0 0 3.182-5.511m-3.182 5.51-5.511-3.181"/>
</svg>
                            </span></h2>
                        </div>
                    </div>
                </div>

                <div className="websites-data-tables-section">

                    <h1 className={'poppins-black'}>
                        Websites List
                    </h1>

                    <div className="table-top-actions">
                        <a href={'/aws/websites/legacy'}>Use Legacy Dashboard</a>

                        <a href="/aws/websites/reports">Reports</a>

                        <a className={'highlighted'} onClick={(e) => showAddSitesModal()} href="javascript:void(0)">Add New Website</a>
                    </div>


                    <div className="table-selector">
                        <ul className="table-selector-list">
                            <li className={`${activeTab === 'cdp' ? 'active' : ''}`}>
                                <a onClick={e => switchTab('cdp')} href="javascript:void(0)">CDP Hosted Sites</a>
                            </li>
                            <li className={`${activeTab === 'external' ? 'active' : ''}`}>
                                <a onClick={(e) => switchTab('external')} href="javascript:void(0)">External Hosted Websites</a>
                            </li>
                        </ul>
                    </div>

                    <div className="table-search">
                        <input onChange={(e) => onSearchTermChanged(e.target.value)} type="text" className="search" placeholder="Enter search term..." />
                    </div>


                    {activeTab === 'cdp' && <div className="cdp-table website-stats-table">
                        <table className="table table-striped mt-4">
                            <thead>
                            <tr>
                                <th>Brand</th>
                                <th>Practice Name</th>
                                <th scope="col">Domain Name</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                            </thead>

                            <tbody>
                            {state.cdpHostedList.map((item, i) => (
                                <tr key={i} className="">
                                    <td className={'poppins-bold'}>{item.brand}</td>
                                    <td>{item.practice}{item.rawPracticeList.length > 1 && <span title="View all practices ">+ {item.rawPracticeList.length - 1} more</span>}</td>
                                    <td>{item.liveSite}</td>
                                    <td className={'table-status-online status'}
                                        title={"This is website is currently online"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                        </svg>
                                    </td>
                                    <td className={'actions'}>
                                        <a href={`/aws/websites/view/${item.id}`}>
                                            Manage Website
                                        </a>
                                        <a className={'is-danger'} href='javascript:void(0);' onClick={showDeleteSitesModal}>
                                            Delete Website
                                        </a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>}

                    {activeTab === 'external' && <div className="external-table website-stats-table">
                        <table className="table table-striped mt-4">
                            <thead>
                            <tr>
                                <th>Brand</th>
                                <th>Practice Name</th>
                                <th scope="col">Domain Name</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                            </thead>

                            <tbody>
                            {state.externalHostedList.map((item, i) => (
                                <tr className="" key={i}>
                                    <td className={'poppins-bold'}>{item.brand}</td>
                                    <td>{item.practice}{item.rawPracticeList.length > 1 && <span title="View all practices ">+ {item.rawPracticeList.length - 1} more</span>}</td>
                                    <td>{item.liveSite}</td>
                                    <td className={'table-status-online status'}
                                        title={"This is website is currently online"}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                        </svg>
                                    </td>
                                    <td className={'actions'}>
                                        <a href={`/aws/websites/view/${item.id}`}>
                                            Manage Website
                                        </a>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </main>
        </Template>
    )
}

function mapStateToProps(state: any) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(AWSWebsites)