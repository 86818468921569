import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const getSpecialityLogs = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const params = JSON.stringify(data);
        const results = await api.get(`speciality/logs?params=${params}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return results;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
};

export const sendMail = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        await api.post(`speciality/email`, data);
        dispatch({type: ActionType.LOADER, payload: false});
        return true;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
};
