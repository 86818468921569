import React, {FC, ReactElement, useState} from "react";
import DatePicker from "react-datepicker";
import {
    Button,
    Form,
    Icon,
} from "semantic-ui-react";
import { applicationList, brands as brandsDropdown, downTimeTrackerStatusList, errorSourceList, howWasItRepoted, notificationGroupList } from "../../constants";

type CreateIssuesFormProps = {
    saveForm: any;
    item: any;
    action: string;
    closeForm: any;
    practicesList:any;
};


const CreateIssuesForm: FC<CreateIssuesFormProps> = ({
                                                           saveForm,
                                                           action,
                                                           closeForm,
                                                           item,practicesList
                                                       }): ReactElement => {
    const initialState:any = {
        id: item ? item["id"] : null,
        status: item ? item["status"] : "Open",
        application: item ? item["application"] : "",
        downTimeStart: item["downTimeStart"]?new Date(item["downTimeStart"]):new Date(),
        brands: item ? item["brands"] : "All",
        practices: item ? item["practices"] : "All",
        whatIsDown: item ? item["whatIsDown"] : "",
        reasonForBeingDown: item ? item["reasonForBeingDown"] : "",
        whatToDoMeanTime: item ? item["whatToDoMeanTime"] : "",
        resolutionDateTime: item["resolutionDateTime"]?new Date(item["resolutionDateTime"]):null,
        timeDown: null,
        rootCause: item ? item["rootCause"] : "",
        prevention: item ? item["prevention"] : "",
        errorSource: item ? item["errorSource"] : "",
        howWasItReported: item ? item["howWasItReported"] : "",
        notificationGroup: item ? item["notificationGroup"] : "",
        action: action,
        supportTicketNumber: item ? item['supportTicketNumber'] : "",
        assignee: item ? item['assignee'] : "",
        jiraStoryNumber: item ? item['jiraStoryNumber'] : "",
    }

    const [state, setState] = useState(initialState);
    const [validApplication, setValidApplication] = useState(true);
    const [validAssignee, setValidAssignee] = useState(true);
    const [validBrands, setValidBrands] = useState(true);
    const [validDownTimeStart, setValidDownTimeStart] = useState(true);
    const [validErrorSource, setValidErrorSource] = useState(true);
    const [validHowItWasReported, setValidHowItWasReported] = useState(true);
    const [validNotificationGroup, setValidNotificationGroup] = useState(true);
    const [validPractices, setValidPractices] = useState(true);
    const [validResolutionDateTime, setValidResolutionDateTime] = useState(true);
    const [validReasonForBeingDown, setValidReasonForBeingDown] = useState(true);
    const [validStatus, setValidStatus] = useState(true);
    const [validWhatIsDown, setValidWhatIsDown] = useState(true);
    const [validWhatToDoMeanTime, setValidWhatToDoMeanTime] = useState(true);

    const submitForm = async () => {
        let error = false;
    
        const showResolutionDate = state.action ==="editForm";
        const fieldsToValidate = [
            { value: status, setter: setValidStatus },
            { value: application, setter: setValidApplication },
            { value: downTimeStart, setter: setValidDownTimeStart },
            { value: brands, setter: setValidBrands },
            { value: practices, setter: setValidPractices },
            { value: whatIsDown, setter: setValidWhatIsDown },
            { value: reasonForBeingDown, setter: setValidReasonForBeingDown },
            { value: whatToDoMeanTime, setter: setValidWhatToDoMeanTime },
            { value: errorSource, setter: setValidErrorSource },
            { value: howWasItReported, setter: setValidHowItWasReported },
            { value: assignee, setter: setValidAssignee },
            { value: notificationGroup, setter: setValidNotificationGroup }
        ];
        
        if(showResolutionDate === true){
            fieldsToValidate.push(
                { value: resolutionDateTime, setter: setValidResolutionDateTime });
        }

        for (const field of fieldsToValidate) {
            if (field.value === '' || field.value === undefined) {
                field.setter(false);
                error = true;
            }
        }

        if(error == false){

            brands = Array.isArray(brands)?brands.join(","):brands;
            practices = Array.isArray(practices)?practices.join(","):practices;
            errorSource = Array.isArray(errorSource)?errorSource.join(","):errorSource;
            howWasItReported = Array.isArray(howWasItReported)?howWasItReported.join(","):howWasItReported;
            notificationGroup = Array.isArray(notificationGroup)?notificationGroup.join(","):notificationGroup;
            
            saveForm({
                id,status,application,
            downTimeStart,brands,practices,whatIsDown,reasonForBeingDown,whatToDoMeanTime,
            resolutionDateTime,timeDown,rootCause, prevention, errorSource,howWasItReported,notificationGroup, supportTicketNumber, assignee, jiraStoryNumber
            }, action)
            closeForm()
        }
    }
    const handleDateChange = (date: any) => {
        setState({...state, downTimeStart: date});
    };

    const handleResolveDateChange = (date: any) => {
        setState({...state, resolutionDateTime: date, status:"Closed"});
    };

    const handleChange = (value: any, name: string) => {
        state[name] = value;
        setState({...state});
     }

    const returnArray = (data: any) => {
        if(data&&data.includes(","))
        return data.split(",").map((s: string) => s.trim());
        return data;
    };

    let {
        id, status,application,
downTimeStart,brands,practices,whatIsDown,reasonForBeingDown,whatToDoMeanTime,
resolutionDateTime,timeDown, rootCause, prevention,errorSource,howWasItReported,notificationGroup, supportTicketNumber, assignee, jiraStoryNumber
    } = state
    const showResolutionDate = state.action ==="editForm";
    return (
        <>
            <Form className={'widthFluid'}>
                <Form.Group widths='equal'>
                    <Form.Field error={validStatus ? false : {
                        content: 'Required',
                        pointing: 'below'
                    }}>
                        <Form.Dropdown
                            label='Status'
                            options={downTimeTrackerStatusList}
                            fluid={true}
                            selection={true}
                            placeholder={"Select Status"}
                            required={true}
                            onChange={(_e, d) => {
                                setState({...state, status: d.value as string});
                                setValidStatus(d.value as string != '');
                            }}
                            value={status}
                        />
                    </Form.Field>
                    <Form.Field error={validApplication ? false : {
                        content: 'Required',
                        pointing: 'below'
                    }}>
                        <Form.Dropdown
                            label='Application'
                            options={applicationList}
                            fluid={true}
                            selection={true}
                            placeholder={"Select Application"}
                            required={true}
                            search={true}
                            onChange={(_e, d) => {
                                setState({...state, application: d.value as string});
                                setValidApplication(d.value as string != '');
                            }}
                            value={application}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field error={false}>
                        <Form.Input 
                            label='Jira Story Number'
                            placeholder='Jira Story Number'
                            defaultValue={jiraStoryNumber}
                            onChange={(e: any) => {
                                handleChange(e.target.value, 'jiraStoryNumber');
                            }}
                        />
                    </Form.Field>
                    <Form.Field error={false}>
                        <Form.Input  
                            label='Support Ticket Number'
                            placeholder='Support Ticket Number'
                            defaultValue={supportTicketNumber}
                            onChange={(e: any) =>  {
                                handleChange(e.target.value, 'supportTicketNumber');
                            }}
                        />
                    </Form.Field>
                    <Form.Field error={validAssignee ? false : {
                            content: 'Required',
                            pointing: 'below'
                        }}>
                        <Form.Input  
                            label='Assignee'
                            placeholder='Assignee'
                            required={true}
                            defaultValue={assignee}
                            onChange={(e: any) => { 
                                handleChange(e.target.value, 'assignee'); 
                                setValidAssignee(e.target.value != '');
                            }}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field error={validDownTimeStart ? false : {
                        content: 'Required',
                        pointing: 'below'
                    }}>
                        <div className="required field">
                            <label>Down Time Start</label>
                            <div className="datePicker widthFluid">
                                <DatePicker
                                    required={true}
                                    selected={state.downTimeStart}
                                    onChange={(e: any) => { 
                                        handleDateChange(e);
                                        setValidDownTimeStart(e as string != '');
                                    }}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="Time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                                <Icon name="calendar alternate"/>
                            </div>
                        </div>
                    </Form.Field>

                    <Form.Field error={validBrands ? false : {
                        content: 'Required',
                        pointing: 'below'
                    }}>
                        <Form.Dropdown multiple 
                            label='Brands'
                            required={true}
                            options={brandsDropdown}
                            fluid={true}
                            selection={true}
                            placeholder={"Select Brand"}
                            onChange={(_e, d) => {
                                setState({...state, brands: d.value as string});
                                setValidBrands(d.value as string != '');
                            }}
                            value={returnArray(brands)||[]}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field error={validHowItWasReported ? false : {
                        content: 'Required',
                        pointing: 'below'
                    }}>
                        <Form.Dropdown multiple
                            label='How was it reported'
                            required={true}
                            options={howWasItRepoted}
                            fluid={true}
                            selection={true}
                            placeholder={"Select Option"}
                            onChange={(_e, d) => {
                                setState({...state, howWasItReported: d.value as string});
                                setValidHowItWasReported(d.value as string != '');
                            }}
                            value={returnArray(howWasItReported)||[]}
                        />
                    </Form.Field>
                    <Form.Field error={validNotificationGroup ? false : {
                        content: 'Required',
                        pointing: 'below'
                    }}>
                        <Form.Dropdown multiple
                            label='Notification Group'
                            required={true}
                            options={notificationGroupList}
                            fluid={true}
                            selection={true}
                            placeholder={"Select Group"}
                            onChange={(_e, d) => {
                                setState({...state, notificationGroup: d.value as string});
                                setValidNotificationGroup(d.value as string != '');
                            }}
                            value={returnArray(notificationGroup)||[]}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field error={validPractices ? false : {
                        content: 'Required',
                        pointing: 'below'
                    }}>
                        <Form.Dropdown multiple
                            label='Practices'
                            required={true}
                            options={practicesList}
                            fluid={true}
                            selection={true}
                            placeholder={"Select Group"}
                            search={true}
                            onChange={(_e, d) => {
                                setState({...state, practices: d.value as string});
                                setValidPractices(d.value as string != '');
                            }}
                            value={returnArray(practices)||[]}
                        />
                    </Form.Field>
                    <Form.Field error={validErrorSource ? false : {
                        content: 'Required',
                        pointing: 'below'
                    }}>
                        <Form.Dropdown multiple
                            label='Error Source'
                            required={true}
                            options={errorSourceList}
                            fluid={true}
                            selection={true}
                            placeholder={"Select Source"}
                            onChange={(_e, d) => {
                                setState({...state, errorSource: d.value as string});
                                setValidErrorSource(d.value as string != '');
                            }}
                            value={returnArray(errorSource)||[]}
                        />
                    </Form.Field>             
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field error={validWhatToDoMeanTime ? false : {
                        content: 'Required',
                        pointing: 'below'
                    }}>
                        <Form.TextArea
                            required={true}
                            fluid={"true"}
                            label='What to do in mean time'
                            placeholder='What to do in mean time'
                            defaultValue={whatToDoMeanTime}
                            onBlur={(e: any) => {
                                handleChange(e.target.value, 'whatToDoMeanTime');
                                setValidWhatToDoMeanTime(e.target.value != '');
                            }}
                            />
                        </Form.Field>
                        {showResolutionDate && <Form.Field error={validResolutionDateTime ? false : {
                            content: 'Required',
                            pointing: 'below'
                        }}>
                            <div className="required field">
                                <label>Project Resolution Date and Time</label>
                                <div className="datePicker widthFluid">
                                    <DatePicker
                                        selected={state.resolutionDateTime}
                                        onChange={(e: any) => { 
                                            handleResolveDateChange(e);
                                            setValidResolutionDateTime(e as string != '');
                                        }}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                    />
                                    <Icon name="calendar alternate"/>
                                </div>
                            </div>
                    </Form.Field>}
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field error={validWhatIsDown ? false : {
                        content: 'Required',
                        pointing: 'below'
                    }}>
                        <Form.TextArea
                            required={true}
                            fluid={"true"}
                            label='What Is Down'
                            placeholder='What Is Down'
                            defaultValue={whatIsDown}
                            onBlur={(e: any) => {
                                handleChange(e.target.value, 'whatIsDown');
                                setValidWhatIsDown(e.target.value as string != '');
                            }}
                        />
                    </Form.Field>   
                    <Form.Field error={validReasonForBeingDown ? false : {
                        content: 'Required',
                        pointing: 'below'
                    }}>
                        <Form.TextArea
                            required={true}
                            fluid={"true"}
                            label='Reason For Being Down'
                            placeholder='Reason For Being Down'
                            defaultValue={reasonForBeingDown}
                            onBlur={(e: any) => {
                                handleChange(e.target.value, 'reasonForBeingDown');
                                setValidReasonForBeingDown(e.target.value != '');
                            }}
                        />
                    </Form.Field>                
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field error={false}>
                        <Form.TextArea
                            fluid={"true"}
                            label='What Is The Root Cause'
                            placeholder='What Is The Root Cause'
                            defaultValue={rootCause}
                            onBlur={(e: any) => {
                                handleChange(e.target.value, 'rootCause');
                            }}
                        />
                    </Form.Field>
                    <Form.Field error={false}>
                        <Form.TextArea
                            fluid={"true"}
                            label='How To Prevent It'
                            placeholder='How To Prevent It'
                            defaultValue={prevention}
                            onBlur={(e: any) => {
                                handleChange(e.target.value, 'prevention');
                                setValidResolutionDateTime(e.target.value != '');
                            }}
                        />
                    </Form.Field>

                </Form.Group>

                <Form.Group widths='equal' className="float-right">
                <Form.Field>
                    <Button secondary={true} onClick={closeForm}>
                        Cancel
                    </Button>
                    <Button
                        className='ui primary button'
                        onClick={(event) => {
                            event.preventDefault()
                            submitForm()
                        }}
                    >
                        Save
                    </Button>
                </Form.Field>
                </Form.Group>
            </Form>
        </>
    );
};
export default CreateIssuesForm;
