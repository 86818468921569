import React,{useState} from "react";
import {Filters, FilterTypes, Practice, PracticeOptions, UdaPracticeInfo} from "../model/practice";
import {Dropdown, Grid, GridColumn, Icon} from "semantic-ui-react";
import {connect} from 'react-redux';
import {RootState} from "../model";
import "react-datepicker/dist/react-datepicker.css";
import {drListOptions, reportPageNames,brands} from '../constants';
import {OnChange, OnChangeFilter} from "../model/common";
import {getMonthsOption} from "./Common";
import HelpMark from "./HelpMark";
import _, { values } from "lodash";
import { Report } from "../model/report";
import moment from "moment";
import Logo from "../styles/images/cdpsmiles.png"
import { options } from "superagent";
import DatePicker from "react-datepicker";

interface Props {
    brandOptions: [];
    practice: Practice,
    practiceOptions:PracticeOptions[],
    report:Report,
    page: string,
    heading: string,
    onChangeFilter: OnChangeFilter,
    filters: Filters,
    exportCSV?: Function,
    sendEmail?: Function,
    currentPracticesKeys:String[]
    regionOptions:[]
    handleDateChange:(date: Date | null, event: React.SyntheticEvent<any> | undefined)=>void
    filterDate:any
}

function FilterSectionOrtho(props: Props) {
    const {currentPracticesKeys}=props
    const onChangeFilter:OnChange = (e, element) => {
        let filters: any = props.filters;
        filters[element.name] = element.value;
        if(element.name === 'month') {
            filters.date = null; //Null date field if month selected
            props.onChangeFilter(filters, true);
        } else {
            props.onChangeFilter(filters);
        }
    }
    const [selectedPractice,setSelectedPractice]=useState([])
    const [selectedRegion,setSelectedRegion]=useState([])
    const [selectedBrands,setSelectedBrands]=useState([])


    const getPracticeOptionsList=()=>{
        if(props.filters['region'].length > 0
             || props.filters['brands'].length > 0){
                 let orthoPractices=props.practiceOptions.filter((prac:any)=>currentPracticesKeys.indexOf(prac.value)!==-1)
                 return orthoPractices
             }else {
                 const keys=currentPracticesKeys.map(key=>key.toLowerCase())
                 const pracOptions:{value:string,text:string,key:string | number}[]=[]
                 keys.forEach((key:any)=>{
                    const practice=props.practice.practiceNamesMap![key] as Record<string,any>
                    if(practice&&practice.practice){
                        pracOptions.push({
                            value:practice.practice,
                            text:practice.practice,
                            key:practice.id,
                        })
                    }
                 })
                 return pracOptions
             }
    }
    const brandsOptions=()=>{
        const brandOptions:string[]=[]
        const brandsObject:{[key:string]:string[]}={}
        brands.forEach((brand)=>{
            const res= props.practice.udaPracticeInfo.filter(
                (item:UdaPracticeInfo)=>{
                  if(item.brand===brand.value){
                    return true
                  }
                }
               ).map(item=>item.practiceName)
            brandsObject[brand.value]=[...res.map(i=>i.toLocaleLowerCase())]
        })
        if(props.filters['region']){
          const tempSelectedPracsPerRegion:{[key:string]:string[]}={}
          props.filters.region.forEach((item)=>{
              if(props.regionOptions[item]){
                tempSelectedPracsPerRegion[item]=
                  props.practice.regionPractices[item] as []
                
              }
          })
          Object.keys(tempSelectedPracsPerRegion).forEach(region=>{
              Object.keys(brandsObject).forEach(brand=>{
                  const commonPracs=_.intersection(brandsObject[brand],tempSelectedPracsPerRegion[region])
                  if(commonPracs.length>0){
                      brandOptions.push(brand)
                  }
              })
          })
          return (brandOptions.length>0)?brandOptions.map(b=>{
              return {
                  value:b,
                  key:Math.random()+b,
                  text:b
              }
          }):
          brands
        }

    }
    const onChangePracticeMultiple=(_:any,{value}:any)=>{
        if(selectedPractice.length>value.length){
            selectedPractice.filter(
                x=>!value.includes(x)
            )
        }
        setSelectedPractice(value)
        let filters=props.filters
        filters['practice']=value
        props.onChangeFilter(filters);
    }

    const onChangeRegionMultiple=(_:any,{value}:any)=>{
        if(selectedPractice.length>value.length){
            selectedRegion.filter(
                x=>!value.includes(x)
            )
        }
        setSelectedRegion(value)
        let filters=props.filters
        filters['region']=value
        props.onChangeFilter(filters);
    }

    const onChangeBrandsMultiple=(_:any,{value}:any)=>{
        if(selectedBrands.length>value.length){
            selectedBrands.filter(
                x=>!value.includes(x)
            )
        }
        setSelectedBrands(value)
        let filters=props.filters
        filters['brands']=value
        props.onChangeFilter(filters);
    }
    return (
        <div className="ui card">            
            <div className="content pb0">
            <img src={Logo} className="logo-ortho float-left mr10 mb10" alt="Logo"/>
            <h2 className="float-left mr10 mb10">{props.heading} <HelpMark pageId="47"/></h2>
            <h2 className="float-left mr10 mb10">Mount Date: {
            props.report.mountDateData[0] &&
            moment(props.report.mountDateData[0].backup_finish_date).utc().format('MM-DD-YYYY')
            }</h2>
            <div className="topFilters">
                    <Dropdown
                        search={true}
                        className='mr10'
                        name="practice"
                        placeholder="Practice"
                        selection={true}
                        multiple={true}
                        options={                          
                            getPracticeOptionsList()
                            .sort((a:any,b:any)=>{
                                let valueA=a.value.toLowerCase()
                                let valueB=b.value.toLowerCase()
                                return (valueA < valueB)?-1:(valueA > valueB)? 1:0
                            })
                        }
                        onChange={onChangePracticeMultiple}
                        value={props.filters.practice}
                    />
                    <Dropdown
                        search={true}
                        className='mr10'
                        name="region"
                        placeholder="Region"
                        selection={true}
                        multiple={true}
                        options={props.regionOptions.length> 0?props.regionOptions:props.practice.regionOptions}
                        onChange={onChangeRegionMultiple}
                        value={props.filters.region}
                    />
                    <Dropdown
                        search={true}
                        className='mr10'
                        name="brands"
                        placeholder="Brands"
                        selection={true}
                        multiple={true}
                        options={props.brandOptions.length >0 ?props.brandOptions:brandsOptions()}
                        onChange={onChangeBrandsMultiple}
                        value={props.filters.brands}
                    />
                    <div className="month-year-picker">
                        <DatePicker
                        name="date"
                        className='ui reports-custom-date-input cursorPointer mr10'
                        dateFormat="MMM yyyy"
                        showMonthYearPicker={true}
                        onChange={props.handleDateChange}
                        selected={props.filterDate}
                        placeholderText={'Select Month'}
                                />
                        <Icon className="calendar-icon cursorPointer" name="calendar alternate outline" />
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state: RootState) {
    return {
        practice: state.practice,
        practiceOptions:state.app.practiceOptions,
        report: state.report,
        regionOptions:state.app.regionOptions,
        brandOptions:state.app.brandOptions

    };
}

export default connect(mapStateToProps)(FilterSectionOrtho)
