import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";
import { Filters } from "../../model/practice";

const initialState:Filters = {
	brands: [],
	date: null,
	drType: [],
	month: "",
	practice: [],
	region: []
};

export const reportFilVal = createReducer(initialState, {
	[ActionType.STORE_FILTER_INFO](state: Filters, action: Action<Filters>) {
		return Object.assign({...state}, action.payload);
	},
});
