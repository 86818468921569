import { ActionType } from "../../model/actionTypes";
import {AdjustmentSetup, AdjustmentEmailObj, AdjustmentFilters} from "../../model/adjustment";
import {errorHandler} from "./auth";


export const saveSetup = (obj: AdjustmentSetup) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("adjustment/setup", obj);
        dispatch({ type: ActionType.ADJUSTMENT_SAVE_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const updateEmailConfirmation = (obj:AdjustmentEmailObj) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("adjustment/confirm", obj);
        dispatch({ type: ActionType.ADJUSTMENT_SAVE_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const getSetup = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("adjustment/setup");
        dispatch({ type: ActionType.ADJUSTMENT_SAVE_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const loadAcknolegdement = (params: AdjustmentFilters) => async (dispatch: Function, getState: Function, api: any) => {
    let stringifyParams;
    if(params){
        stringifyParams = '/'+JSON.stringify(params)
    }
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("adjustment/acknowledgement"+stringifyParams);
        dispatch({ type: ActionType.ACKNOLEGDEMENT, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};