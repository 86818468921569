import React from "react";
import {Button, Modal, Icon} from "semantic-ui-react";

function PracticeModal (props: any) {

  return (
              <Modal className={'modalCenter'}
               onClose={() => props.closePopUp()}
               open={true}
        >
          <Modal.Header>Missing Practice</Modal.Header>
          <Modal.Content >
            <Modal.Description>
              <p>
                Please select practice or alternatively add your default practice.
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
                content="OK"
                primary={true}
                onClick={() => props.closePopUp()}
            />
          </Modal.Actions>
        </Modal>
    );
}

export default PracticeModal;
