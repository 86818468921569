import * as React from 'react';
import {Dimmer, Grid, Loader} from "semantic-ui-react";
import PMDUnitItem from './PMDUnitItem';

export interface PMDMCOListProps {
  practicename: string,
  npo: string,
  rcn: string
}

export interface PMDMCOListState {
  practicename: string,
  npo: string,
  rcn: string
}

class PMDMCOList extends React.Component<PMDMCOListProps,PMDMCOListState> {  
  constructor(props: PMDMCOListProps) {
    super(props);
    this.state={
      practicename : props.practicename,
      npo: props.npo,
      rcn: props.rcn
    }
    this.setPracticeName=this.setPracticeName.bind(this);
  }

  setPracticeName(value: string) {
    this.setState({
      practicename : value
    })
  }

  componentWillReceiveProps(nextProps: PMDMCOListProps) {
    this.setState({
      practicename: nextProps.practicename,
      npo: nextProps.npo,
      rcn: nextProps.rcn
    })
  }

  checkValues(){
    return (
        !(
            this.props.rcn !== '' &&
            this.props.npo !== ''
        ) &&
        <Dimmer active={true}>
          <Loader>Calculating</Loader>
        </Dimmer>
    )
  }

  render() {
    return (
    <Grid columns='two'>
      <Grid.Row>
          <Grid.Column>
          <PMDUnitItem value={this.props.npo} description="NP Work Now"/>
          </Grid.Column>
          <Grid.Column>
          <PMDUnitItem value={this.props.rcn} description="Rc No Future Work"/>
          </Grid.Column>
      </Grid.Row>
  </Grid>  
)
  }
}

export default PMDMCOList;