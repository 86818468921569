import React, {useState} from "react";
import {Button, Form, Modal} from "semantic-ui-react";

const initState: any = {
    practice: '',
    practiceabbr: '',
    brand: '',
    practiceDBName: '',
    orthoLocation: '',
    commonName: '',
    tab32clinicID: '',
    uss: false,
    ortho: false,
    campaign: false,
    recruitment: false,
    alerts: false,
    xrayService: false,
    patientFinance: false,
    enableDB: false,
    PCR: false,
    TAB32: false,
    credentialing: false,
    ptCareCountUploader: false,
    schedule: false,
}
function AddNewPracticeModal (props: any) {
    const [state, setStates] = useState({
        error: {
            practice: false,
            practiceabbr: false,
            brand: false
        },
        data: initState
    });

    const formValidation = (obj: any) => {
        let formErrors: any = {
            practice: false,
            practiceabbr: false,
            brand: false
        };
        Object.keys(obj).forEach((key) => {
            if (key == 'practice' && (!obj[key] || obj[key] == '')) {
                formErrors[key] = true;
            }

            if(key === 'practiceabbr' && (!obj[key] || obj[key] == '')) {
                formErrors[key] = true;
            }
            if(key == 'brand' && (!obj[key] || obj[key] == '')) {
                formErrors[key] = true;
            }
        });
        return formErrors;
    };

    const onSubmit = () => {
        const fields = state.data;
        const formErrors = formValidation(fields);

        if (Object.values(formErrors).includes(true)) {
            setStates({...state, error: formErrors })
            return;
        }
        const payload = state.data;
        props.savePractice(payload);
    };

    const handleChange = (e:any, name: string) => {
        const data = state.data;

        switch(name) {
            case 'uss':
            case 'ortho':
            case 'campaign':
            case 'recruitment':
            case 'alerts':
            case 'xrayService':
            case 'patientFinance':
            case 'enableDB':
            case 'PCR':
            case 'TAB32':
            case 'credentialing':
            case 'ptCareCountUploader':
            case 'schedule':
               data[name] = e.checked;
               break;
            default:
                data[name] = e.target.value
        }

        const { error } = state;

        if (name === 'practice' && e.target.value !== '') {
            error.practice = false
            setStates({...state, error })
        }

        if (name === 'practiceabbr' && e.target.value !== '') {
            error.practiceabbr = false
            setStates({...state, error })
        }

        if (name === 'brand' && e.target.value !== '') {
            error.brand = false
            setStates({...state, error })
        }

        setStates({...state, data})
    };

    return (
        <Modal
            className={'modalCenter'}
            onClose={() => props.handleClose()}
            open={true}
        >
            <Modal.Header>
                <a className="ui blue label ml10">Practice Details</a>
            </Modal.Header>
            <Modal.Content>
                <Form className="formStyle">
                    <Form.Group widths='equal'>
                        <Form.Input
                            fluid={true}
                            label='Practice'
                            placeholder='Practice Name'
                            required={true}
                            value={state.data.practice}
                            error={state.error.practice}
                            onChange={(e) => handleChange(e, 'practice')}
                        />

                        <Form.Input
                            fluid={true}
                            label='Practice Abbreviation'
                            placeholder='Practice Abbreviation'
                            required={true}
                            value={state.data.practiceabbr}
                            error={state.error.practiceabbr}
                            onChange={(e) => handleChange(e, 'practiceabbr')}
                        />
                        <Form.Input
                            fluid={true}
                            label='Brand'
                            placeholder='Brand'
                            required={true}
                            value={state.data.brand}
                            error={state.error.brand}
                            onChange={(e) => handleChange(e, 'brand')}
                        />
                    </Form.Group>
                    
                    <Form.Group widths='equal'>
                        <Form.Input
                            fluid={true}
                            label='Practice DBName'
                            placeholder='Database Name'
                            value={state.data.practiceDBName}
                            onChange={(e) => handleChange(e, 'practiceDBName')}
                        />
                        <Form.Input
                            fluid={true}
                            label='Ortho Location'
                            placeholder='Ortho Location'
                            value={state.data.orthoLocation}
                            onChange={(e) => handleChange(e, 'orthoLocation')}
                        />
                        <Form.Input
                            fluid={true}
                            label='Alias'
                            placeholder='Alias'
                            value={state.data.commonName}
                            onChange={(e) => handleChange(e, 'commonName')}
                        />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Input
                            fluid={true}
                            label='Tab32 Clinic ID'
                            placeholder='Tab32 Clinic ID'
                            value={state.data.tab32clinicID}
                            onChange={(e) => handleChange(e, 'tab32clinicID')}
                        />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Checkbox
                            fluid={true}
                            label='USS'
                            value={state.data.uss}
                            onChange={(e, value) => handleChange(value, 'uss')}
                        />
                        <Form.Checkbox
                            fluid={true}
                            label='Ortho'
                            value={state.data.ortho}
                            onChange={(e,value) => handleChange(value, 'ortho')}
                        />

                        <Form.Checkbox
                            fluid={true}
                            label='CAMPAIGN'
                            value={state.data.campaign}
                            onChange={(e,value) => handleChange(value, 'campaign')}
                        />

                        <Form.Checkbox
                            fluid={true}
                            label='Recruitment'
                            value={state.data.recruitment}
                            onChange={(e,value) => handleChange(value, 'recruitment')}
                        />

                    </Form.Group>

                    <Form.Group widths='equal'>

                        <Form.Checkbox
                            fluid={true}
                            label='Alerts'
                            value={state.data.alerts}
                            onChange={(e,value) => handleChange(value, 'alerts')}
                        />
                        <Form.Checkbox
                            fluid={true}
                            label='XRay Service'
                            value={state.data.xrayService}
                            onChange={(e,value) => handleChange(value, 'xrayService')}
                        />
                        <Form.Checkbox
                            fluid={true}
                            label='Patient Finance'
                            value={state.data.patientFinance}
                            onChange={(e,value) => handleChange(value, 'patientFinance')}
                        />
                        <Form.Checkbox
                            fluid={true}
                            label='Enable DB for OTD/3CD'
                            value={state.data.enableDB}
                            onChange={(e,value) => handleChange(value, 'enableDB')}
                        />

                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Checkbox
                            fluid={true}
                            label='PCR'
                            value={state.data.PCR}
                            onChange={(e,value) => handleChange(value, 'PCR')}
                        />
                        <Form.Checkbox
                            fluid={true}
                            label='TAB32'
                            value={state.data.TAB32}
                            onChange={(e,value) => handleChange(value, 'TAB32')}
                        />
                        <Form.Checkbox
                            fluid={true}
                            label='Credentialing'
                            value={state.data.enableDB}
                            onChange={(e,value) => handleChange(value, 'credentialing')}
                        />
                        <Form.Checkbox
                            fluid={true}
                            label='Patient Count & Care Uploader'
                            value={state.data.ptCareCountUploader}
                            onChange={(e,value) => handleChange(value, 'ptCareCountUploader')}
                        />
                    </Form.Group>
                    <Form.Group widths='4'>
                        <Form.Checkbox
                            label='Schedule'
                            value={state.data.schedule}
                            onChange={(e,value) => handleChange(value, 'schedule')}
                        />
                    </Form.Group>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Discard"
                    secondary={true}
                    onClick={() => props.handleClose()}
                />
                <Button
                    content="Create"
                    primary={true}
                    onClick={() => onSubmit()}
                />
            </Modal.Actions>
        </Modal>);
}
export default AddNewPracticeModal;