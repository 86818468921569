import React, { useEffect, useState } from 'react';
import { HEADERS } from './constants';
import { Icon, Table } from "semantic-ui-react";
import { Input } from 'semantic-ui-react'
import * as patientCountCareActions from "../../../redux/actions/patientCountCare";
import { useActions } from '../../../redux/actions';
import moment from 'moment';

interface DisplayTableProps {
    date?:any,
    data: any[],
    allData?: any[],
    autoSave: boolean;
}

function DisplayTable(props: DisplayTableProps) {
    const [state, setState] = useState({ edit: false, careList: [] as any[], selected: {} as any });
    const patientCountCareAction = useActions(patientCountCareActions) as typeof patientCountCareActions;

    useEffect(() => {
        setState({ ...state, careList: populateZerosOnEmptyDate() })
    }, [props.data,props.allData])

    const onSelect = (practice: string, header: any, count: number) => {
        setState({ ...state, selected: { ...header, count: count, practice: practice } })
    }

    const getType = (name: string): string => {
        return name === 'Total Care' ? 'care' : 'count'
    }

    const getTotalField = (type: string) => {
        return type === 'count' ? 'ttl_pt_count' : 'ttl_care'
    }

    const populateZerosOnEmptyDate = () =>{
        let dateHasData:boolean =(props.data!=null)&&props.data.length>0;
        let hasAllData:boolean =(props.allData!=null)&&props.allData.length>0;
        let responseData: any[] =[];
        let practiceIds: string[] =[];
        if(props.allData&&hasAllData&& !dateHasData && ((props.date!="") || (props.date!=null))){

            props.allData.filter((r=>{
                if(!props.date){
                    return true;
                }else{
                    return moment(r.date).format('YYYY-MM-DD')===moment(props.date).format('YYYY-MM-DD')
                }
            })).forEach((item: any)  => {
                item.date =  moment(item.date).format('YYYY-MM-DD');
                item.fv_pt_count = 0;
                item.rc_pt_count = 0;
                item.tx_pt_count = 0;
                item.ttl_pt_count = 0;
                item.fv_care = 0;
                item.rc_care = 0;
                item.tx_care = 0;
                item.ttl_care = 0;
                if(!practiceIds.includes(item.id)){
                    practiceIds.push(item.id);
                    responseData.push(item);
                }
                
            })
            return responseData;
        }
        return props.data;
        
    }

    const onChanges = (details: any, data: any, header: any) => {
        const { careList } = state;
        const index = careList.findIndex(c => c.practice === details.practice);
        const value = (header.input) ? data.value : parseInt(data.value || 0);


        if (header.input) {
            careList[index] = { ...careList[index], [header.value]: value };
            setState({ ...state, careList: careList, selected: { ...state.selected, count: value } });
            return;
        }


        careList[index] = { ...careList[index], [header.value]: value };

        let info: any = careList[index];

        if (header.value === 'ttl_pt_count' || header.value === 'ttl_care') {
            let details = {}
            HEADERS.filter(h => h.type === getType(header.name)).forEach(h => { details = { ...details, [h.value]: 0 } })
            careList[index] = { ...careList[index], ...details, [header.value]: value };
            setState({ ...state, careList: careList, selected: { ...state.selected, count: value } });
            return;
        }


        let count = {}
        HEADERS.filter(h => h.type === header.type).forEach(h => { count = { ...count, [h.value]: parseInt(info[h.value]) } });
        const sum = Object.values(count).reduce((a: any, b: any) => a + b);
        careList[index] = { ...careList[index], [getTotalField(header.type)]: sum };
        setState({ ...state, careList: careList, selected: { ...state.selected, count: value } });
    }

    const updatePTCountCare = async () => {
        const { careList, selected } = state;
        const { autoSave } = props;
        let data = careList[careList.findIndex(c => c.practice === selected.practice)];
        delete data.createdAt;
        delete data.updatedAt;
        delete data.Practice;
        if (autoSave) await patientCountCareAction.updatePTCount(data);
        setState({ ...state, selected: {} })
    }

    return (
        <div className="FreezeTable">
            <Table className="ui table table-striped celled table-hover unstackable">
                <Table.Header>
                    <Table.Row>
                        {
                            HEADERS.map((header, i) =>
                                <Table.HeaderCell key={`h_` + i}><div>{header.name}</div></Table.HeaderCell>)
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        state.careList.map((patientsDetail, i) =>
                            <Table.Row key={`row` + i}>
                                {
                                    HEADERS.map((header, j) =>
                                        <Table.Cell className='row-edit' onClick={() => onSelect(patientsDetail.practice, header, patientsDetail[header.value])} key={`_${i}_${j}`}>

                                            {header.edit && state.selected.value === header.value && state.selected.practice === patientsDetail.practice ?
                                                (<Input type={header.input ? 'date' : 'number'} min={header.input ? null : "0"} size='mini' value={state.selected.count}
                                                    onChange={(_e, data) => onChanges(patientsDetail, data, header)}
                                                    onBlur={updatePTCountCare} />)
                                                : (<span> {header.formatter ? header.formatter(patientsDetail[header.value]) : patientsDetail[header.value]}</span>)}
                                            {header.edit && <span className='row-icon'><Icon size='small' name='pencil' /></span>}
                                        </Table.Cell>)
                                }
                            </Table.Row>
                        )
                    }
                </Table.Body>
            </Table>
        </div>
    );
}



export default DisplayTable