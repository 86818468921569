import React, {useEffect, useState} from "react";
import {Button, Modal, Comment, Header, Dropdown} from "semantic-ui-react";
import moment from "moment";
import {useActions} from "../redux/actions";
import * as SpotActions from "../redux/actions/spot";
import {resultDropDown} from "../container/Spot/constants";

const ResultsModal:React.FC<any>=(props)=>{
    const spotActions = useActions(SpotActions);
    const [results, setResults] = useState([]);
    const resultNewDropDown = resultDropDown.slice();
    const [resultValue, setResultValue] = useState('blank')
    resultNewDropDown.unshift({text: '--Select Result--', value: 'blank'}); // Add element on 0 index
    resultNewDropDown.splice(1, 2); // Remove element from array
    resultNewDropDown.splice(8, 2); // Remove element from

    useEffect(() => {
        spotActions.getResultList({
            sid : props.item.subscriberid,
            patnum : props.item.PT_Num,
            practiceId : props.item.practiceId
        })
            .then((res: any) => {
                if (res.length) {
                    const isResultAvailable = resultNewDropDown.find((o: any) => o.value === res[res.length - 1].Result)
                    setResultValue(isResultAvailable ? res[res.length - 1].Result : 'blank')
                }
                setResults(res)
            })
    }, []);

    const handleResultChange = (value: any) => {
        const obj = {
            sid : props.item.subscriberid,
            patnum : props.item.PT_Num,
            practiceId : props.item.practiceId,
            Result : value
        }
        props.saveResult(obj)
    };


    return (
        <Modal
            open={true}
            onClose={() => props.hideModal(false)}
            centered={false}
            size='tiny'
            closeIcon
        >
            <Header content='Member Results History'/>
            <Modal.Content>
                <Comment.Group>
                    {!results.length && <p>No History!</p>}
                    {results && results.map((item: any) => {
                        return(
                            <Comment>
                                <Comment.Content>
                                    <Comment.Author>{item.user ? item.user.firstname + ' ' + item.user.lastname: ''}</Comment.Author>
                                    <Comment.Metadata>
                                        <div>{moment(item.createdAt).fromNow()}{}</div>
                                    </Comment.Metadata>
                                    <Comment.Text>Changed result as {item.Result}</Comment.Text>
                                </Comment.Content>
                            </Comment>
                        )
                    })}

                </Comment.Group>
                <Dropdown
                    style={{width: "225px"}}
                    options={resultNewDropDown}
                    onChange={(e, d) => handleResultChange(d.value)}
                    value={resultValue}
                    selection={true}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button secondary onClick={() => props.hideModal(false)}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

export default ResultsModal