import React from "react";
import Template from "../components/Template";
import DentalPartners from "../components/DentalPartners";

function Dashboard() {

  return (
    <Template activeLink='dashboard'>
      <DentalPartners/>
    </Template>
  );

}

export default Dashboard;