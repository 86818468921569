
import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";
export const getDistrictsList = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("districts/getDistricts");
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const deleteDistrict = (id:number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("districts/deletedDistrict/"+id);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const createDistrict = (name:string) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("districts/createDistrict/"+name);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}