import React, {useEffect} from "react";
import {Icon} from "semantic-ui-react";
import {useActions} from "../redux/actions";
import * as adjustmentActions from "../redux/actions/adjustment";
function AdjustmentConfirm(props: any) {
  const adjustmentAction = useActions(adjustmentActions);

  useEffect(() => {
    const params = props.match.params;
    if(params.token){
      const obj = {
        token : params.token
      };
       adjustmentAction.updateEmailConfirmation(obj);
    }
  }, []);


  return (
    <div className={'acknowlegeConfirm'}>
       <Icon name={'checkmark'} size='huge'/>
      <h2> Thank you </h2>
      <p>Your acknowledgement has been recorded. We will keep you updated for new entries.</p>
    </div>

  );

}

export default AdjustmentConfirm;
