import * as React from "react";
import { useState} from "react";
import {HelpPages} from "../constants";
import {Header, Icon, Modal} from "semantic-ui-react";
import ViewFileModal from "./ViewFileModal";
import ReactTooltip from "react-tooltip";
import {RootState} from "../model";
import {connect} from "react-redux";

function ReportHelp(props: any) {

	const initialState : any = {
		openFileModal: false,
		showDetails: false,
		files: null,
		data : null,
		fileType : ''
	}

	const [state, setStates] = useState(initialState);

	const openFileModal = (type: string, files: any) => {
		setStates({...state, openFileModal: true, fileType: type, files: files});
	}

	const closeFilesModal = () => {
		setStates({...state, openFileModal: false, files: null, fileType: ''});
	}

	const showDetails = () => {
		setStates({...state, showDetails: true});
	}

	const hideDetails = () => {
		setStates({...state, showDetails: false});
	}
	const data = props.data;
	return (
		<div className="report_help">
			<Modal
				open={state.showDetails}
				onClose={hideDetails}
				centered={false}
				size='large'
				closeIcon={true}
			>
				<Header content={`Information about ${data.field}`}/>
				<Modal.Content>
					<div className="row">
						<div className='col-12'>
							<div className="helpList mb-0">
										<p><strong>Hex Code:</strong><br/> {data.hexCode}</p>
										<p className="mb-5"> <strong>Short Description :</strong><br/> {data.short_desc}</p>
										<p className="mb-5"> <strong>Description :</strong><br/> {data.description}</p>
										<p className="mb-5"> <strong>Formula :</strong><br/> {data.formula}</p>
										<p className="mb-5"> <strong>Code : </strong><br/>{data.code}</p>

													{ (data.fileLink && data.fileLink.toLowerCase().indexOf('.mp4') > 0) &&
													<span
														onClick={() => openFileModal('Video', data.fileLink)}
														className="cursorPointer mr20"
													>
															<Icon
																className="primaryColor"
																name="play circle"
															/>
															<span className="linkText">Watch Video</span>
													</span> }
													{ (data.fileLink && data.fileLink.toLowerCase().indexOf('.pdf') > 0) &&
													<span
														onClick={() => openFileModal('PDF', data.fileLink)}
														className="cursorPointer"
													>
														<Icon
															className="primaryColor"
															name="file pdf"
														/>
														<span className="linkText">View PDF</span>
													</span> }

							</div>
						</div>
					</div>
				</Modal.Content>
				{state.openFileModal && <ViewFileModal closeModal={closeFilesModal} files={state.files} type={state.fileType}/>}

			</Modal>
			{data.short_desc && <a data-tip={data.short_desc}> <Icon name={'info circle'} onClick={showDetails}/> </a>}
			<ReactTooltip place="right" type="light" effect="float"/>
		</div>
	);
}

function mapStateToProps(state: RootState) {
	return {
		mdm_report: state.mdm.mdm_report,
	};
}

export default connect(mapStateToProps)(ReportHelp);

