import React from 'react';
import { Modal, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { getStatus } from './healthStatusUtils';

interface Props {
    data: { practice: string, records: any[] }
    show: boolean
    onClick: (view: boolean) => void
}

function ViewPatientValidationData({ data, show, onClick }: Props) {

    return (
        <>
            <Modal
                onClose={() => onClick(false)}
                open={show}
                size='large'
            >
                <h2 style={{ margin: '5px' }}>Patient Validation: {data.practice}</h2>
                {(data.records.length > 0) && <Table style={{ padding: '5px' }} compact celled>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>Status</TableHeaderCell>
                            <TableHeaderCell>Practice</TableHeaderCell>
                            <TableHeaderCell>PatNum</TableHeaderCell>
                            <TableHeaderCell>Date</TableHeaderCell>
                            <TableHeaderCell>Time</TableHeaderCell>
                            <TableHeaderCell>APT Status</TableHeaderCell>
                            <TableHeaderCell>Carrier</TableHeaderCell>
                            <TableHeaderCell>Employer Name</TableHeaderCell>
                            <TableHeaderCell>BirthDate</TableHeaderCell>
                            <TableHeaderCell>Subscriber ID</TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {
                            data.records.length > 0 && data.records.map((o, i) => (
                                <TableRow key={i}>
                                    <TableCell>{getStatus(o)}</TableCell>
                                    <TableCell>{data.practice}</TableCell>
                                    <TableCell>{o.PatNum}</TableCell>
                                    <TableCell>{o.Apt_Date}</TableCell>
                                    <TableCell>{o.Apt_Time}</TableCell>
                                    <TableCell>{o.APT_Status}</TableCell>
                                    <TableCell>{o.CarrierName}</TableCell>
                                    <TableCell>{o.EmpName}</TableCell>
                                    <TableCell>{o.BirthDate}</TableCell>
                                    <TableCell><strong>{o.SubscriberID}</strong></TableCell>
                                </TableRow>))
                        }

                    </TableBody>
                </Table>}
                {
                    !(data.records.length > 0) && <h1 className='text-center'>No  patients data</h1>
                }
            </Modal>
        </>
    );
}

export default ViewPatientValidationData;
