import React, {useState} from 'react';
import {Button, Form, Modal, Grid} from "semantic-ui-react";

interface AdjustmentCodesFormProps {
    initialValues: { id: any; writeoffCode: any; procedureCode: any; writeoffDesc: any; addToDrPay: any; },
    cancelForm: any,
    saveForm: any
}

const AdjustmentCodesListForm = (props: AdjustmentCodesFormProps) => {
    const [state, setStates] = useState({
        id: props.initialValues.id,
        writeoffCode: props.initialValues.writeoffCode,
        procedureCode: props.initialValues.procedureCode ? props.initialValues.procedureCode : '',
        writeoffDesc: props.initialValues.writeoffDesc ? props.initialValues.writeoffDesc : '',
        addToDrPay: props.initialValues.addToDrPay ? props.initialValues.addToDrPay : false
    });

    const onChange = (e: any, element: any) => {
        const obj: any = {...state}
        obj[element.name] = element.name == "addToDrPay" ? element.checked : element.value;
        setStates(obj);
    };

    const saveForm = () => {
        props.saveForm(state);
    };

    const {initialValues, cancelForm} = props;
    return (

        <Modal
            onClose={() => cancelForm}
            open={true}
            size="small"
        >
            <Modal.Header>{initialValues.id && initialValues.id > 0 ? "Edit " : "Create "} UDA Write Off
                Codes</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form>
                        <Form.Input
                            fluid={true}
                            label='WRITE OFF CODE'
                            placeholder='WRITE OFF CODE'
                            defaultValue={state.writeoffCode}
                            name={'writeoffCode'}
                            onChange={onChange}
                        />
                        <Form.Input
                            fluid={true}
                            label='PROCEDURE CODE'
                            placeholder='PROCEDURE CODE'
                            defaultValue={state.procedureCode ? state.procedureCode : ''}
                            name={'procedureCode'}
                            onChange={onChange}
                        />
                        <Form.Input
                            fluid={true}
                            label='WRITE OFF DESCRIPTION'
                            placeholder='WRITE OFF DESCRIPTION'
                            defaultValue={state.writeoffDesc ? state.writeoffDesc : ''}
                            name={'writeoffDesc'}
                            onChange={onChange}
                        />

                        <Form.Checkbox
                            fluid={true}
                            label='ADD TO DR PAY'
                            placeholder='ADD TO DR PAY'
                            checked={state.addToDrPay}
                            name={'addToDrPay'}
                            onChange={onChange}
                        />
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button secondary={true} onClick={cancelForm}>Cancel</Button>
                <Button primary={true} onClick={saveForm}>Save</Button> &nbsp;
            </Modal.Actions>
        </Modal>

    );
}

export default AdjustmentCodesListForm;
