import React from "react";
import { connect } from "react-redux";
import { Button, Modal, Grid, Form, Loader, Dimmer, Table } from "semantic-ui-react";
import { RootState } from "../model";
import { MDM_BoardInfo } from "../model/mdm";
import * as mdmInfo from '../redux/actions/mdm';
import { useActions } from '../redux/actions';
import { validObj } from "../utils/common";

interface Props {
    handleClose: Function;
    items: string;
    mdmBoardInfo: MDM_BoardInfo;
}

const TableExampleFixedLine: React.FC<{mdmBoardInfo: MDM_BoardInfo}> = ({ mdmBoardInfo }) => (
    <Table ui celled padded table>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Formula</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Code</Table.HeaderCell>
                <Table.HeaderCell>Hex Code</Table.HeaderCell>                
            </Table.Row>
        </Table.Header>

        <Table.Body>
            <Table.Row>
                <Table.Cell>{mdmBoardInfo.field}</Table.Cell>
                <Table.Cell title={mdmBoardInfo.formula}>
                    {mdmBoardInfo.formula}
                </Table.Cell>
                <Table.Cell title={mdmBoardInfo.description}>
                    {mdmBoardInfo.description}
                </Table.Cell>
                <Table.Cell>{mdmBoardInfo.code}</Table.Cell>
                <Table.Cell>{mdmBoardInfo.hexCode}</Table.Cell>                
            </Table.Row>
        </Table.Body>
    </Table>
)

const PcrSplashBoardInfo = (props: Props) => {

    const { items, handleClose, mdmBoardInfo } = props;
    const [loading, setLoading] = React.useState(false);

    const mdmAction = useActions(mdmInfo);
    const fetchMdmInfo = () => {
        setLoading(true);
        mdmAction.getPcrSplashboardMdmInfo(items as string)
            .then(() => {
                setLoading(false);
            }).catch((err: any) => {
                setLoading(false);
            });
    };


    React.useEffect(fetchMdmInfo, []);
    return (
        <Modal className={'modalCenter'}
            onClose={() => handleClose()}
            open={true}
            size="large"
        >
            <Modal.Header content={`${items} Info`} />
            {loading &&
                <Dimmer active inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
            }
            {validObj(mdmBoardInfo) ?
                <Modal.Content>
                    <Form className="formStyle mt10">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <TableExampleFixedLine mdmBoardInfo={mdmBoardInfo} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content> : <p className="text-center mt10">No record found.</p>}
            <Modal.Actions>
                <Button
                    content="Close"
                    primary={false}
                    onClick={() => handleClose()}
                />
            </Modal.Actions>
        </Modal>
    );
};

function mapStateToProps(state: RootState) {
    return {
        mdmBoardInfo: state.mdm.mdmPsBoardInfo,
    };
}

export default connect(mapStateToProps)(PcrSplashBoardInfo);
