import React, {useEffect, useState} from 'react';
import {useActions} from "../redux/actions";
import * as Actions from "../redux/actions/districts";
import Template from "./Template";
import {Button, Icon, Table,Modal} from 'semantic-ui-react';
import moment from "moment";


function DistrictsList() {

    const [appActions] = useActions([Actions]);
    const [districtsList, setDistrictsList] = useState([]);
    const [open, setOpen] = React.useState(false)
    const [name, setName] = React.useState('')

    useEffect(() => {
        getDistricts();
    }, []);

    const getDistricts = () => {
        appActions.getDistrictsList()
            .then((res: any) => {
                setDistrictsList(res);
            })
    }
    const addDistrict = () => {

    }

    const deleteDistrict = (id: number) => {
        appActions.deleteDistrict(id)
            .then((res: any) => {
                getDistricts();
            })
    }
    const createDistrict = (name: string) => {
        appActions.createDistrict(name)
            .then((res: any) => {
                getDistricts();
                setOpen(false)
            })
    }
    const openModal = () => {
        setOpen(true)
    }



    return (
        <Template activeLink='districts'>
            <div className="ui fluid card">
                <div className="content">
                    <h2 className="float-left mr10 mb10">Districts List</h2>
                    <Button primary={true} onClick={()=>openModal()} > Add New District </Button>
                    {
                        //create a model for adding a new district using name field
                        open&&  <Modal
                            open={open}
                            onClose={() => setOpen(false)}
                            centered={false}
                            style={{overflow: 'auto'}}
                            closeIcon={{style: {top: '1.0535rem', right: '1rem'}, color: 'black', name: 'close'}}
                            size="small"
                        >
                            <Modal.Header>Add New District</Modal.Header>
                            <Modal.Content>
                                <div className="ui form">
                                    <div className="field">
                                        <label>Name</label>
                                        <input type="text" name="name" placeholder="Name" required={true} onChange={(e) => setName(e.target.value)} />
                                    </div>
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color='red' onClick={() => setOpen(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    content="Add"
                                    labelPosition='right'
                                    icon='checkmark'
                                    onClick={() => createDistrict(name)}
                                    primary={true}
                                />
                            </Modal.Actions>
                        </Modal>

                    }
                </div>

            </div>
            <div className="ui fluid card">
                <Table celled={true}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Action</Table.HeaderCell>
                            <Table.HeaderCell>District Name</Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {districtsList.length && districtsList.map((item: any) => {
                            return (
                                <Table.Row key={item.id}>
                                    <Table.Cell> <Button primary={true} onClick={()=>deleteDistrict(item.id)}>Delete</Button> </Table.Cell>

                                    <Table.Cell>{item.name}</Table.Cell>
                                </Table.Row>
                            )
                        })}

                    </Table.Body>
                </Table>
            </div>
        </Template>
    )
}

export default DistrictsList;