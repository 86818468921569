import * as React from 'react';
import { Button, Icon, Table, Modal, Grid, Dimmer, Loader, Label } from 'semantic-ui-react'
import {Practice} from "../model/practice";
import { numberFormat } from './numberFormat';
import PMDUnitItem from './PMDUnitItem';
import PMDUnitItemClickable from './PMDUnitItemClickable';

export interface PMDTaskProps {
  practicename: string,
  practice: Practice,
  open : boolean,
  pmdtaskrollover : any,
  pmdtasktoday: any,
  pmdtasktotal : any,
  pmdnotbilled$ : any,
  pmdnotbillerproc : any,
}

export interface PMDTaskState {
  practicename: string,
  practice: Practice,
  open : boolean,
  pmdtaskrollover : any,
  pmdtasktoday: any,
  pmdtasktotal : any,
  pmdnotbilled$ : any,
  pmdnotbillerproc : any,
}

class PMDTask extends React.Component<PMDTaskProps,PMDTaskState> {  
  constructor(props: PMDTaskProps) {
    super(props);
    this.state={
      practicename : props.practicename,
      practice: props.practice,
      open : props.open,
      pmdtaskrollover : props.pmdtaskrollover,
      pmdtasktoday: props.pmdtasktoday,
      pmdtasktotal : props.pmdtasktotal,
      pmdnotbilled$: props.pmdnotbilled$,
      pmdnotbillerproc : props.pmdnotbillerproc,
    }
    this.setPmdNotBilled$=this.setPmdNotBilled$.bind(this);
    this.setPmdNotBilledProc=this.setPmdNotBilledProc.bind(this);
    this.setPmdTaskRollover=this.setPmdTaskRollover.bind(this);
    this.setPmdtTaskToday=this.setPmdtTaskToday.bind(this);
    this.setPmdTaskTotal=this.setPmdTaskTotal.bind(this);
    this.setPracticeName=this.setPracticeName.bind(this);
    this.setOpen=this.setOpen.bind(this);
    // this.setLoading = this.setLoading.bind(this);
  }

  // setLoading(value: any) {
  //   this.setState({
  //     loading : value
  //   })
  // }
  setPmdNotBilled$(value: any) {
    this.setState({
      pmdnotbilled$ : value
    })
  }

  setPmdNotBilledProc(value: any) {
    this.setState({
      pmdnotbillerproc : value
    })
  }

  setPmdTaskRollover(value: any) {
    this.setState({
      pmdtaskrollover : value
    })
  }

  setPmdtTaskToday(value: any) {
    this.setState({
      pmdtasktoday : value
    })
  }

  setPmdTaskTotal(value: any) {
    this.setState({
      pmdtasktotal : value
    })
  }

  setPracticeName(value: string) {
    this.setState({
      practicename : value
    })
  }

  componentWillReceiveProps(nextProps: PMDTaskProps) {
    this.setState({
      practicename: nextProps.practicename,
      open: nextProps.open,
      pmdtaskrollover: nextProps.pmdtaskrollover,
      pmdtasktoday: nextProps.pmdtasktoday,
      pmdtasktotal: nextProps.pmdtasktotal,
      pmdnotbilled$: nextProps.pmdnotbilled$,
      pmdnotbillerproc: nextProps.pmdnotbillerproc,
    })
  }


  setOpen(value: boolean) {
    this.setState({
      open : value
    })
  }

  checkValues(){
    return (
        !(
            this.props.pmdnotbilled$ !== null &&
            this.props.pmdtaskrollover !== null &&
            this.props.pmdtasktoday !== null &&
            this.props.pmdnotbillerproc !== null &&
            this.props.pmdtasktotal !== null
        ) &&
        <Dimmer active={true}>
          <Loader>Calculating</Loader>
        </Dimmer>
    )
  }

  render() {
    return (
    
    <Grid columns='two' >
      {this.checkValues()}
    <Grid.Row>
      <Grid.Column>
          <PMDUnitItem value={numberFormat(this.props.pmdnotbilled$ || 0)} description="Not Billed $"/>
      </Grid.Column>
      <Grid.Column>
      <PMDUnitItem value={this.props.pmdnotbillerproc || 0} description="Not Billed Proc"/>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
    <Grid.Column className='pdmitem'>
        <span onClick={() => this.setOpen(true)}>
          <PMDUnitItemClickable value={this.props.pmdtasktotal || 0} description="Tasks" /> </span>
      </Grid.Column>
    </Grid.Row>
        <Modal
          closeOnEscape={false}
          closeOnDimmerClick={false}
          open={this.state.open}
          onClose={() => this.setOpen(false)}
          onOpen={() => this.setOpen(true)}
        >
          <Modal.Header>Tasks Open
          <Label color={this.props.pmdtasktotal>0?'green':'red'} floating>
            {this.props.pmdtasktotal}
          </Label>
          <div className="ui top right attached label production_right_label">
              <Button onClick={() => this.setOpen(false)} inverted>
                Close
              </Button>
            </div>
          </Modal.Header>
          <Modal.Content>

            <Table className="table table-striped table-hover my-0 TableHeaderFixed">
              <Table.Header>              
                <Table.Row>
                  <Table.HeaderCell><div className="tbl-header">Age</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">Task Open</div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell>Rollover</Table.Cell>
                  <Table.Cell>{this.props.pmdtaskrollover}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Today</Table.Cell>
                  <Table.Cell>{this.props.pmdtasktoday}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell><div className="tbl-bold">Total</div></Table.Cell>
                  <Table.Cell><div className="tbl-bold">{this.props.pmdtasktotal}</div></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
          </Modal.Actions>
        </Modal>
  </Grid>
    


)
  }
}

export default PMDTask;