import React, {useEffect, useState} from "react";
import {
    Button, Form, TextArea,
    Grid, Modal, Table, Icon
} from "semantic-ui-react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as ReportActions from "../redux/actions/report"
import {useActions} from "../redux/actions";
import HelpMark from "./HelpMark";


interface AvailableApiListProps {
    apiList: any
}

const initialState = {
    editFormId: -1,
    form: {
        name: '',
        link: '',
        description: ''
    }
}

const AvailableApiList = (props: AvailableApiListProps) => {
    const reportActions = useActions(ReportActions);
    const [state, setStates] = useState(initialState);

    useEffect(() => {
        reportActions.fetchApiList()
    }, []);

    const deleteRecord = (id: number) => {
        if (window.confirm('Are you sure you want to delete this?')) {
            reportActions.deleteApiList(id)
        }
    }

    const saveRecord = (record: any) => {
        const item = record && record.value && JSON.parse(record.value);
        const form: any = {};
        const editFormId = record ? record.id : 0;
        form["name"] = item ? item.name : '';
        form["link"] = item ? item.link : '';
        form["description"] = item ? item.description : '';
        setStates({...state, form: form, editFormId: editFormId});
    }

    const handleClose = () => {
        const form: any = {};
        setStates({...state, form: form, editFormId: -1});
    }

    const handleEditField = (value: string, name: string) => {
        const form: any = state.form;
        form[name] = value;
        setStates({...state, form: form});
    }

    const handleSubmit = () => {
        const siteObj = {
            id: state.editFormId,
            type: 'api-list',
            value: JSON.stringify(state.form)
        }
        handleClose();
        reportActions.saveApiList(siteObj)
            .then(() => {
                reportActions.fetchApiList();
            });
    }

    return (
        <>
            <Template activeLink="available-api-list">
                {state.editFormId >= 0 &&
                    <Modal
                        size={'small'}
                        open={true}
                        onClose={() => handleClose()}
                    >
                        <Modal.Header>Edit API Details</Modal.Header>
                        <Modal.Content>
                            <Form className={'widthFluid'}>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>API name</label>
                                        <input placeholder='API name' defaultValue={state.form.name}
                                               onChange={(e) => handleEditField(e.target.value, 'name')}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>API link</label>
                                        <input placeholder='API link' defaultValue={state.form.link}
                                               onChange={(e) => handleEditField(e.target.value, 'link')}/>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label>Description</label>
                                        <TextArea placeholder='Description' defaultValue={state.form.description}
                                                  onChange={(_e: any, {value}: any) => handleEditField(value, 'description')}/>
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button secondary={true} onClick={() => handleClose()}>
                                Cancel
                            </Button>
                            <Button primary={true} onClick={() => handleSubmit()}>
                                Save
                            </Button>
                        </Modal.Actions>
                    </Modal>
                }

                <div className="ui card">
                    <div className="content pb0">
                        <h2 className="float-left mr10">Available Api List <HelpMark pageId='52'/></h2>
                        <div className="topFilters">
                            <Button
                                size="small"
                                primary={true}
                                onClick={() => {
                                    saveRecord(false);
                                }}
                            >
                                Create New
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="ui card">
                    <div className="content">
                        <div className="FreezeTable">
                            <Table className="ui table table-striped celled table-hover unstackable">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell style={{color: "#d16f26"}}>
                                            <div>Actions</div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{color: "#d16f26"}}>
                                            <div>Api Name</div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{color: "#d16f26"}}>
                                            <div>Link</div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell style={{color: "#d16f26"}}>
                                            <div>Description</div>
                                        </Table.HeaderCell>

                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {props.apiList.length &&
                                        props.apiList.map((result: any, index: any) => {
                                            const item = result.value && JSON.parse(result.value);
                                            return (
                                                <Table.Row key={index}>
                                                    <Table.Cell>
                                                        <Icon className="cursorPointer" name="edit"
                                                              onClick={() => {
                                                                  saveRecord(result);
                                                              }}/>
                                                        <Icon className="cursorPointer ml10" name="trash alternate"
                                                              onClick={() => {
                                                                  deleteRecord(result.id);
                                                              }}/>
                                                    </Table.Cell>
                                                    <Table.Cell>{item.name}</Table.Cell>
                                                    <Table.Cell>{item.link}</Table.Cell>
                                                    <Table.Cell>{item.description}</Table.Cell>

                                                </Table.Row>
                                            )
                                        })}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>

            </Template>
        </>
    );
};

function mapStateToProps(state: RootState) {
    return {
        apiList: state.report.apiList,
    };
}

export default connect(mapStateToProps)(AvailableApiList);
