export const ionStatusMapping: any = {
    "done_colors": [
        1,
        6
    ],
    "color_mapping": {
        "3": 158,
        "4": 3,
        "6": 10,
        "10": 6,
        "11": 4,
        "158": 159,
        "159": 11
    },
    "labels": {
        "0": "Doing",
        "1": "Done",
        "2": "Blocked",
        "3": "Backlog",
        "4": "To Do",
        "5": "TBD",
        "6": "Abandoned",
        "12": "Recurring"
    },
    "labels_positions_v2": {
        "0": 2,
        "1": 4,
        "2": 3,
        "3": 5,
        "4": 1,
        "5": 0,
        "6": 6,
        "12": 7
    },
    "labels_colors": {
        "0": {
            "color": "#fdab3d",
            "border": "#E99729",
            "var_name": "orange"
        },
        "1": {
            "color": "#00c875",
            "border": "#00B461",
            "var_name": "green-shadow"
        },
        "2": {
            "color": "#e2445c",
            "border": "#CE3048",
            "var_name": "red-shadow"
        },
        "3": {
            "color": "#A1E3F6",
            "border": "#A1E3F6",
            "var_name": "sail"
        },
        "4": {
            "color": "#0086c0",
            "border": "#3DB0DF",
            "var_name": "blue-links"
        },
        "5": {
            "color": "#c4c4c4",
            "border": "#B0B0B0",
            "var_name": "grey"
        },
        "6": {
            "color": "#333333",
            "border": "#000",
            "var_name": "soft-black"
        },
        "12": {
            "color": "#FF158A",
            "border": "#E01279",
            "var_name": "dark-pink"
        }
    }
}