import React, {ReactElement, useState} from "react";
import {Button, Table, Modal, Label, Icon,} from "semantic-ui-react";
import {useActions} from "../../redux/actions";
import * as lambdasActions from "../../redux/actions/lambdas";
import moment from "moment";


const ViewLogs = (props: any): ReactElement => {
    const lambdasAction = useActions(lambdasActions);
    const [open, setOpen] = useState(false);
    const [logs, setLogs] = useState([]);
    const loadLogs = async () => {
        const logs = await lambdasAction.getLogs(props.item.id);
        setLogs(logs);
        setOpen(true)
    }

    return (
        <>
            <Modal
                trigger={<Button style={{padding: '20px 15px'}} secondary={true}> <Icon name="book"/> Logs</Button>}
                onClose={() => setOpen(false)}
                onOpen={loadLogs}
                open={open}
            >
                <Modal.Header>
                    <h2 className="text-center"><span className="text-uppercase">{props.item.name} </span>Logs</h2>
                </Modal.Header>
                <Modal.Content scrolling={true}>
                    <Table basic={true}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell content="Started"/>
                                <Table.HeaderCell content="Completed"/>
                                <Table.HeaderCell content="Duration"/>
                                <Table.HeaderCell content="Status"/>
                                <Table.HeaderCell content="Details"/>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                logs.map((log: any, key) => {
                                    const duration = moment.duration(moment(log.end).diff(moment(log.start))).asMinutes().toFixed(2) + ' mins';
                                    const details = JSON.parse(log.logs);
                                    const failed = (details.result || []).filter((f: any) => !f.result).map((f: any) => f.db);
                                    const success = (details.result || []).filter((f: any) => f.result).map((f: any) => f.db);
                                    return <Table.Row key={'log_' + key}>
                                        <Table.Cell content={moment(log.start).format('DD MMM YYYY :HH:mm')}/>
                                        <Table.Cell content={moment(log.end).format('DD MMM YYYY :HH:mm')}/>
                                        <Table.Cell content={<b>{duration}</b>}/>
                                        <Table.Cell content={<b>{log.status ? 'Completed' : 'Failed'}</b>}/>
                                        <Table.Cell
                                            content={[
                                                success.length ? <Label title={success.join(', ')} color='green' content="Success" detail={success.length}/> : <></>,
                                                failed.length ? <Label title={failed.join(', ')} color='red' content='Failed' detail={failed.length}/> : <></>
                                            ]}
                                        />
                                    </Table.Row>
                                })
                            }

                            {
                                !logs || !logs.length && <Table.Row>
                                    <Table.Cell>
                                        <p>No Logs Found</p>
                                    </Table.Cell>
                                </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary={true} onClick={() => setOpen(false)}>Close</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
        ;
};
export default ViewLogs;
