import {User} from "../../../model/user";
import {connect} from "react-redux";
import Template from "../../../components/Template";
import React from "react";


interface Props {
    auth: User
}

function ViewWebsiteReports(props: Props) {
    return (<Template activeLink='aws websites'>
        <main className='aws-websites-root failure aws-websites-single poppins-regular'>
            <h1>This page is currently under construction. Something awesome is on the way</h1>

            <p>Tip: In the meantime, you can use the legacy websites dashboard to perform certain operations</p>

            <a className={'has-icon'} href="/aws/websites">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3"/>
                </svg>

                Back to websites dashboard
            </a>
        </main>
    </Template>)
}

function mapStateToProps(state: any) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(ViewWebsiteReports)