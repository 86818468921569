import React, {useEffect, useState} from "react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as ReportActions from "../redux/actions/report"
import {useActions} from "../redux/actions";
import {Button, Checkbox, Grid, Input, Table, Icon} from "semantic-ui-react";
import _ from "lodash";
import HelpMark from "./HelpMark";


interface DrAppPermissionsMatrixProps {
    drAppPermissionsMatrix: any,
    loader: any
}


const DrAppPermissionsMatrix = (props: DrAppPermissionsMatrixProps) => {
    const initialState: any = {
        loading: false,
        drAppPermissionsMatrix: props.drAppPermissionsMatrix,
        isEdit: false,
        initialValues: {},
        orderBy: null,
        order: true,
        searchKeyword: '',
        column: null,
    }

    let timeout: any = 0;
    let table: JSX.Element[] = []
    let tableHeaders: JSX.Element[] = []
    const [state, setStates] = useState(initialState);
    const reportActions = useActions(ReportActions);
    const {loader} = props;

    useEffect(() => {
        reportActions.getDrAppPermissions()
            .then((response: any) => {
                setStates({...state, drAppPermissionsMatrix: response});
            });
    }, []);

    const handleOptions = (checked: any, permissionIndex: number, configIndex: number) => {
        let {drAppPermissionsMatrix: drAppPermissionsMatrixLocal} = state;
        drAppPermissionsMatrixLocal[permissionIndex].configuration[configIndex].access = checked;
        setStates({...state, drAppPermissionsMatrix: drAppPermissionsMatrixLocal});
    };

    const toggleEdit = () => {
        const {isEdit: isEditLocal} = state;
        const newValue = !isEditLocal
        setStates({...state, isEdit: newValue})
    }

    const save = () => {
        reportActions.updateDrAppPermissions(state.drAppPermissionsMatrix)
            .then(() => {
                toggleEdit()
            })

    }

    const handleSort = (clickedColumn: any) => {
        const {column: columnLocal, drAppPermissionsMatrix: drAppPermissionsMatrixLocal, order: orderLocal} = state;
        const dat: any = _.sortBy(drAppPermissionsMatrixLocal, [clickedColumn]);
        if (columnLocal !== clickedColumn) {
            setStates({
                ...state,
                column: clickedColumn,
                drAppPermissionsMatrix: dat,
                order: true,
            })

            return
        }

        setStates({
            ...state,
            drAppPermissionsMatrix: drAppPermissionsMatrixLocal.reverse(),
            order: !orderLocal
        })
    }

    const getIcon = (condition: any) => {
        return condition ? <Icon color={"green"} name='check circle'/> : <Icon color={"red"} name='cancel'/>
    }

    const makeTableCellBody = (row: any, permissionIndex: any, configIndex: any) => {
        return isEdit ?
            <Checkbox
                defaultChecked={row.configuration[configIndex].access}
                onChange={(_e, data) => handleOptions(data.checked, permissionIndex, configIndex)}
            />
            : getIcon(row.configuration[configIndex].access)
    }

    const getTableData = (_drAppPermissionsMatrix: any) => {
        if (!state || !state.drAppPermissionsMatrix) {
            return table
        }
        const permissions = state.drAppPermissionsMatrix
        permissions.forEach((row: any, permissionIndex: any) => {
            table.push(
                <Table.Row key={'fgf' + permissionIndex}>
                    <Table.Cell>
                        {row.pageId}
                    </Table.Cell>
                    <Table.Cell on>
                        {row.pageTitle}
                    </Table.Cell>
                    {Array.isArray(row.configuration) && row.configuration.map((_config: any, configIndex: number) => {
                        if (permissionIndex === 0)
                            tableHeaders.push(
                                <Table.HeaderCell>
                                    <div>{row.configuration[configIndex].role}</div>
                                </Table.HeaderCell>);

                        return (
                            <Table.Cell>
                                {makeTableCellBody(row, permissionIndex, configIndex)}
                            </Table.Cell>
                        )
                    })}

                </Table.Row>
            )
        })
        return table;
    }

    const onSearchChange = (e: any) => {
        const searchKeyword = e.target.value;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            const data = props.drAppPermissionsMatrix && props.drAppPermissionsMatrix.filter((element: any) => {
                return element.pageTitle && element.pageTitle.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0;
            });

            setStates({...state, searchKeyword: searchKeyword, drAppPermissionsMatrix: data});
            clearTimeout(timeout);
        }, 100);
    };

    const {column, order} = state;
    const direction = order ? 'down' : 'up';

    const {isEdit} = state;


    return (

        <Template activeLink="ramp-threshold-defaults">
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">Dr App Permission Matrix <HelpMark pageId='83'/></h2>
                    <div className="topFilters">
                        {!isEdit ?
                            <Button primary={true} disabled={props.drAppPermissionsMatrix.length === 0}
                                    onClick={() => toggleEdit()}>{'Edit'}</Button> :
                            <span>
                                    <Button secondary={true} onClick={() => toggleEdit()}>{'Cancel'}</Button>
                                    <Button primary={true} onClick={() => save()}>{'Save'}</Button>
                                </span>
                        }
                        <Input className='float-right mb10' icon='search' placeholder="Search"
                               onChange={onSearchChange}/>
                    </div>
                </div>
            </div>

            {!loader &&
                <div className="ui card">
                    <div className="content">
                        <div className="FreezeTable">
                            <Table className="ui table table-striped celled table-hover unstackable">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            <div>Page Id</div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            className={`cursorPointer ${column === 'pageTitle' ? 'active' : ''}`}
                                            onClick={() => handleSort('pageTitle')}>
                                            <div>Page
                                                Title <i className={`sort amount ${direction} icon ml-05`}/></div>
                                        </Table.HeaderCell>
                                        {tableHeaders}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {getTableData(state.drAppPermissionsMatrix)}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
            }


        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        drAppPermissionsMatrix: state.report.drAppPermissionsMatrix,
        loader: state.app.loader
    };
}

export default connect(mapStateToProps)(DrAppPermissionsMatrix);
