import { Action, ActionType } from "../../model/actionTypes";
import { MDM_Info } from "../../model/mdm";
import { GoxProcedureCodeList } from "../../model/goxProcedureCode";
import createReducer from "./createReducer";



const initialState:any=[];

export const writeOffList = createReducer(initialState, {
    [ActionType.FETCH_GOX_PROCEDURE_CODES_SUCCESS](state: any, action: Action<GoxProcedureCodeList>) {
        return action.payload;
    },
    [ActionType.CREATE_GOX_PROCEDURE_CODES_SUCCESS](state: any, action: Action<GoxProcedureCodeList>) {
        return state
    },
    [ActionType.UPDATE_GOX_PROCEDURE_CODES_SUCCESS](state: any, action: Action<GoxProcedureCodeList>) {
        return state
    },
});