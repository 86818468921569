import React, {useState} from 'react';

import {Dimmer, Form, Label, Loader} from 'semantic-ui-react';
import {acceptHelpFileTypes, API_URL} from '../constants';
import ReactS3Uploader from 'react-s3-uploader';
import {RootState} from "../model";
import {connect} from "react-redux";

interface Props {
    fileLinks: any,
    accessToken : any,
    onFinishUploading: Function,
    showFiles : any
}

function FileUploader(props: Props) {

    const initialState = {
        progressCountVar: 0,
        loading: false
    }
    const [state, setState] = useState(initialState);
    let {progressCountVar} = state;

    const onUploadStart = (file: any, next: Function) => {
        state.progressCountVar++;
        setState({...state, loading: true});
        next(file);
    }

    const onProgress = (percent: number, status: string) => {
        state.progressCountVar = percent;
        setState({...state, loading: true});
    }

    const onUploadError = (e: any) => {
        alert("Upload file error.");
        state.progressCountVar = -1;
        setState({...state, loading: false});
    }

    const onFinish = async (e: any) => {
        state.progressCountVar = 0;
        setState({...state, loading: false});
        props.onFinishUploading(e);
    }

    const { fileLinks, showFiles, accessToken } = props;
    const additional: any = {keyPrefix: 'cdp-help'};
    const additionalAuth: any = {Authorization: 'bearer ' + accessToken};

    return (
      <Form.Field>
          {state.loading && <Dimmer active={true}><Loader>Uploading - {progressCountVar}%</Loader></Dimmer>}
          <label>Upload File (Upload Only pdf/mp4)
          </label>
          <ReactS3Uploader
            multiple={true}
            signingUrlMethod="GET"
            className='form-group'
            accept={acceptHelpFileTypes}
            signingUrlQueryParams={additional}
            signingUrl={`${API_URL}/helpMark/upload`}
            uploadRequestHeaders={{
                'x-amz-acl': 'public-read'
            }}
            signingUrlHeaders={additionalAuth}
            preprocess={(file: any, next: any) => onUploadStart(file, next)}
            onProgress={onProgress}
            onError={onUploadError}
            onFinish={(res: any) => onFinish(res)}
          />
          {(fileLinks && fileLinks.length) &&
            <p>
                <Label
                  className='cursorPointer'
                  onClick={showFiles}
                  basic={true}
                  color='orange'
                >
                    Total Files: {fileLinks.length}
                </Label>
            </p> }
      </Form.Field>
    );
}

function mapStateToProps(state: RootState) {
    return {
        accessToken: state.auth.accessToken
    };
}
export default connect(mapStateToProps)(FileUploader);

