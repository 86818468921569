import React, {useEffect, useState} from "react";
import Template from "./Template";
import * as adjustmentActions from "../redux/actions/adjustment";
import * as practiceActions from "../redux/actions/practice";
import {useActions} from "../redux/actions";
import {Practice} from "../model/practice";
import {Adjustments, Acknowledgement} from "../model/adjustment";
import {RootState} from "../model";
import {connect} from "react-redux";
import {Dropdown, Button, Icon} from "semantic-ui-react";
import moment from 'moment';
import DynamicHtmlTable from "./DynamicHtmlTable";
import _ from 'lodash'
import HelpMark from "./HelpMark";

const filterMoment = moment();
const filterformat = 'YYYY-MM-DD';

interface Props {
    practice: Practice,
    adjustments: Adjustments
}

function AdjustmentAcknowledgement(props: Props) {
    const adjustmentAction = useActions(adjustmentActions);
    const practiceAction = useActions(practiceActions);
    const initialState: Acknowledgement = {
        filters: {
            locations: [],
            region: '',
            startDate: filterMoment.startOf('month').format(filterformat),
            endDate: filterMoment.endOf('month').format(filterformat)
        },
        locationSelected: false,
        TableData: [[{title: 'No Records Found'}]],
        TableHead: [{title: ''}],
        practiceOptions: [],
        column: -1,
        direction: 'sort'
    };
    const [state, setStates] = useState(initialState);

    useEffect(() => {
        practiceAction.fetchRegions(refineRegionsArray);
        practiceAction.fetchPractices(refinePracticeArray)
            .then((res: { practiceNames: string[]; practiceInfo: []; }) => {
                const filters = {...state.filters};
                filters.locations = res? res.practiceNames:[];
                let practiceInfo = res? res.practiceInfo:[];
                getAcknolegdement(filters, false, practiceInfo);
            });
    }, []);

    const handleSort = (clickedColumn: number) => () => {
        let { column, TableData, direction } = state;
         if (column !== clickedColumn) {
                column =  clickedColumn;
                TableData =  _.sortBy(TableData, o => o[clickedColumn].title);
                direction = 'sort ascending';
                setStates({...state, TableData, column, direction});
             return;
         }
        TableData = TableData.reverse();
        direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
        setStates({...state, TableData, direction});
    };

    const getAcknolegdement = (filters: { endDate: string; locations: Array<string>; region: string; startDate: string }, locationSelected: boolean, practiceInfo: any) => {
        const practiceNames = props.practice.practiceNames;
        if (filters.locations.length < 1) {
            filters.locations = practiceNames;
            locationSelected = false;
        }
        adjustmentAction.loadAcknolegdement(filters).then((res: any) => {
            const TableData = pushTableData(res, practiceInfo);
            const TableHead = TableHeadData();
            setStates({...state, TableData, TableHead, filters, locationSelected});
        });
    };

    const onChangeLocation = (_e: any, element: any) => {
        const filters: any = {...state.filters};
        if (element.value) {
            filters.locations = element.value;
            getAcknolegdement(filters, true, props.practice.practiceInfo);
        }
    };
    const onChangeRegion = (_e: any, element: any) => {
        const filters: any = {...state.filters};
        if (element.value) {
            filters.locations = props.practice.regionPractices[element.value];
            filters.region = element.value;
            getAcknolegdement(filters, false, props.practice.practiceInfo);
        }
    };

    const nextMonth = () => {
        const filters: any = {...state.filters};
        filters.startDate = moment(filters.startDate).add(1, 'month').startOf('month').format(filterformat);
        filters.endDate = moment(filters.endDate).add(1, 'month').endOf('month').format(filterformat);
        getAcknolegdement(filters, state.locationSelected, props.practice.practiceInfo);
    };

    const prevMonth = () => {
        const filters: any = {...state.filters};
        filters.startDate = moment(filters.startDate).subtract(1, 'month').startOf('month').format(filterformat);
        filters.endDate = moment(filters.endDate).subtract(1, 'month').endOf('month').format(filterformat);
        getAcknolegdement(filters, state.locationSelected, props.practice.practiceInfo);
    };

    const refineRegionsArray = (regionList: any) => {
        let regionListOptions = [{key: '0', value: null, text: 'Select Region'}];
        if (regionList.length) {
            regionList.forEach((item: any) => {
                regionListOptions.push({text: item.name, value: item.id, key: item.id})
            });
        }
        return regionListOptions;
    };

    const refinePracticeArray = (response: any) => {
        let practiceNames: Array<string> = [];
        let practiceInfo: any = {};
        let practiceOptions = [{key: 'none', value: null, text: 'Select Practice'}];
        if (response) {
            response.forEach((item: any) => {
                practiceOptions.push({text: item.practice, value: item.practice, key: item.id.toString()});
                practiceNames.push(item.practice);
                practiceInfo[item.practice.toLowerCase()] = item;
            })
        }
        return {practiceNames: practiceNames, practiceOptions: practiceOptions, practiceInfo: practiceInfo}
    };

    const myDate = (date: string, format: string) => {
        return moment(date).format(format);
    };

    const TableHeadData = () => {
        const TableHead = new Array();
        TableHead[0] = {title: 'Practice'};
        TableHead[1] = {title: 'PatNum'};
        TableHead[2] = {title: 'ProcDate'};
        TableHead[3] = {title: 'AdjDate'};
        TableHead[4] = {title: 'AdjAmt'};
        TableHead[5] = {title: 'Provider'};
        TableHead[6] = {title: 'ADJ_Name'};
        TableHead[7] = {title: 'Note', className : 'adjNotesHead'};
        TableHead[8] = {title: 'ODUser'};
        TableHead[9] = {title: 'ProcCode'};
        TableHead[10] = {title: 'Proccat'};
        TableHead[11] = {title: 'Practice Manager'};
        TableHead[12] = {title: 'PM Acknowledgement Date'};
        TableHead[13] = {title: 'Regional Manager'};
        TableHead[15] = {title: 'RM Acknowledgement Date'};
        return TableHead;
    };

    const pushTableData = (res: any, practiceInfo: any) => {
        const TableData = new Array();
        const adjustments = res.adjustments;
        Object.keys(adjustments).forEach(location => {
            const acknowledgementDate = res.acknowledgementDate[location];

            Object.keys(adjustments[location]).forEach((item: any) => {
                const row = adjustments[location][item];
                const practiceEmails = practiceInfo[location.toLowerCase()];
                acknowledgementDate && acknowledgementDate.forEach((dat: { PMAcknowledgeDate: string; RMAcknowledgeDate: string; dateEntry:string }) => {
                    row.PMAcknowledgeDate = dat.dateEntry == row.DateEntry ? dat.PMAcknowledgeDate : '';
                    row.RMAcknowledgeDate = dat.dateEntry == row.DateEntry ? dat.RMAcknowledgeDate : '';
                });
                TableData.push([
                    {title: location},
                    {title: row.PatNum},
                    {title: row.ProcDate},
                    {title: row.AdjDate},
                    {title: row.AdjAmt},
                    {title: row.Provider},
                    {title: row.ADJ_Name},
                    {title: row.Note, className : 'adjNotes'},
                    {title: row.ODUser},
                    {title: row.ProcCode},
                    {title: row.Proccat},
                    {title: practiceEmails && practiceEmails.mgrEmail},
                    {title: row.PMAcknowledgeDate},
                    {title: practiceEmails && practiceEmails.regMgrEmail},
                    {title: row.RMAcknowledgeDate}
                ])
            });
        });
        return TableData;
    };

    return (
        <Template activeLink='adjustment-acknowledgement'>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10"> Adjustment Acknowledgement <HelpMark pageId="42"/> </h2>
                    <div className={'topFilters'}>
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="locations"
                            multiple={true}
                            placeholder="Practice"
                            selection={true}
                            options={props.practice.practiceOptions}
                            onChange={onChangeLocation}
                            value={state.locationSelected ? state.filters.locations : []}
                        />
                        <Dropdown
                            search={true}
                            className='mb15'
                            name="region"
                            placeholder="Region"
                            selection={true}
                            options={props.practice.regionOptions}
                            onChange={onChangeRegion}
                            value={state.filters.region}
                        />
                    </div>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    <div className={'dateFilers'}>
                        <Button onClick={prevMonth} attached='left'><Icon name={'chevron left'}/></Button>
                        <Button className="mr20" onClick={nextMonth} attached='right'>
                            <Icon name={'chevron right'}/>
                        </Button>
                        <h4 className="displayInline">{myDate(state.filters.startDate, 'MMM YYYY')}</h4>

                    </div>
                </div>
            </div>
            <div className="table-adjAcknowledgement">
            <DynamicHtmlTable
                key={0}
                className={'adjustment'}
                tableHead={state.TableHead}
                heading={""}
                tableData={state.TableData}
                onHeadClick={handleSort}
                sortedColumn={state.column}
                direction={state.direction}
            />
            </div>
        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        practice: state.practice,
        adjustments: state.adjustments,
    };
}

export default connect(mapStateToProps)(AdjustmentAcknowledgement);

