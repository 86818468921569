import React from "react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import PatientValidationInfoForm from "./PatientValidationForm/PatientValidationInfoForm";

function PatientValidation() {
    return (
        <Template activeLink='patient-validation'>
            <PatientValidationInfoForm key="main-patient-validation" />
        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        practice: state.practice,
        patients: state.patients,
        loginVia: state.report.loginVia,
        providerAccountList: state.providerAccountList,
    };
}

export default connect(mapStateToProps)(PatientValidation);
