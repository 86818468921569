import { numberFormat } from "../../components/numberFormat";
import { csvHeaderPMD, csvHeaderPMD_NoFutureApt, csvHeaderPMD_TodayGoalAll, csvHeaderPMD_TodayProdDetail, isSafari } from "../../constants";
import { ActionType } from "../../model/actionTypes";
import { ExportCSVPMD } from "../../model/common";
import { PMDCSVObject, PMDNoFutureAptCSVObject, PMDSetup, PMDTodayGoalAllCSVObject, PMDTodayProdDetailCSVObject } from "../../model/pmd";
import { sortBy } from "../../utils/common";
import { errorHandler } from "./auth";

export const saveSetup = (data: PMDSetup) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp =  await api.post("practiceManagerDashboard/setup", data);
        dispatch({ type: ActionType.PMD_DEFAULT_PRACTICES, payload: { data } });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.PMD_DEFAULT_PRACTICES, payload: '' });
        return dispatch(errorHandler(err));
    }
}

export const getSetup = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("practiceManagerDashboard/setup");
        dispatch({ type: ActionType.PMD_DEFAULT_PRACTICES, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        return dispatch({ type: ActionType.LOADER, payload: false });
    }
}

export const sendMailPMD = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        let progresscsv = 0;        
        let pmdCSVobj:PMDCSVObject[] = [];
        pmdCSVobj.push(csvHeaderPMD);
        params.practicenames.splice(0,1);
        params.practicenames = sortBy(params.practicenames,"value");
        let total = params.practicenames.length*17;

        let param ="";
        param = (param) ? "/"+param : "";
        const reportData = await api.get(`report/bCare${param}`);
           

        for (const item of params.practicenames) {
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });
            progresscsv+=1;
            let practiceName:string = item.value;
            let practice = (practiceName.toLowerCase()) ? "/"+practiceName.toLowerCase() : "";
            
            const pmdScheduleTodaySchAptToday:any = await api.get(`practiceManagerDashboard/getPMDScheduleTodaySchAptToday${practice}`);
            const schAptToday = pmdScheduleTodaySchAptToday[0].schAptToday ? pmdScheduleTodaySchAptToday[0].schAptToday : 0 ;
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });
            const pmdScheduleTodayPtSeenToday:any = await api.get(`practiceManagerDashboard/getPMDScheduleTodayPtSeenToday${practice}`);
            const ptSeenToday = pmdScheduleTodayPtSeenToday[0].ptseentoday ? pmdScheduleTodayPtSeenToday[0].ptseentoday : 0 ;
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });
            
            const pmdScheduleToday30DayCompletd:any =  await api.get(`practiceManagerDashboard/getPMDScheduleToday30DayCompletd${practice}`);
            const scheduleToday30DayCompletd = pmdScheduleToday30DayCompletd[0].v30daycompleted ? pmdScheduleToday30DayCompletd[0].v30daycompleted : 0 ;
            const pmdScheduleToday30DayApts=  await api.get(`practiceManagerDashboard/getPMDScheduleToday30DayApts${practice}`);
            const scheduleToday30DayApts = pmdScheduleToday30DayApts[0].v30dayapts ? pmdScheduleToday30DayApts[0].v30dayapts : 0 ;
            const show_rate = scheduleToday30DayCompletd/scheduleToday30DayApts;
            const expectedShow = (Math.round(show_rate*schAptToday));
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });

            param ="";
            param = (param) ? "/"+param : "";
            let budgetData = await api.get(`report/budget${param}`);

            var _FvCPV =0;
            var _FvPt = 0; 
            var _RcCPV = 0;
            var _RcPt = 0;
            var _TxCPV = 0;
            var _TxPt = 0;
            var _currentDayGoal = 0;
            var totalPt:any = 0;
            var budget:any = 0;
            var ACTPPP:any = 0;
        
            if(budgetData && budgetData[practiceName.toLowerCase()]) {
                var budgetDataObj = budgetData[practiceName.toLowerCase()];
                _FvCPV = budgetDataObj.FvCPV ? budgetDataObj.FvCPV : 0 ;
                _FvPt = budgetDataObj.FvPtPatientBudget ? budgetDataObj.FvPtPatientBudget : 0 ;
                _RcCPV = budgetDataObj.RcCPV ? budgetDataObj.RcCPV : 0 ;
                _RcPt = budgetDataObj.RcPtPatientBudget ? budgetDataObj.RcPtPatientBudget : 0 ;
                _TxCPV = budgetDataObj.TxCPV ? budgetDataObj.TxCPV : 0 ;
                _TxPt = budgetDataObj.TxPtPatientBudget ? budgetDataObj.TxPtPatientBudget : 0 ;
                _currentDayGoal = budgetDataObj.currentDayGoal ? budgetDataObj.currentDayGoal : 0 ;

                const FV_CPV:any  = _FvCPV
                const FV_PT:any  = budgetDataObj.FvPt ? budgetDataObj.FvPt : 0 ;
                const RC_CPV:any  = _RcCPV
                const RC_PT:any  = budgetDataObj.RcPt ? budgetDataObj.RcPt : 0 ;
                const TX_CPV:any  = _TxCPV
                const TX_PT:any  = budgetDataObj.TxPt ? budgetDataObj.TxPt : 0 ;
                budget = (((FV_CPV)*(FV_PT))+((RC_CPV)*(RC_PT))+((TX_CPV)*(TX_PT)));
                totalPt = FV_PT+RC_PT+TX_PT;
            }else{

            }

            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });            

            if(reportData && reportData.byPractice) {
                const bCare_perpractice:any  = reportData? reportData.byPractice? reportData.byPractice[practiceName.toLowerCase()]?reportData.byPractice[practiceName.toLowerCase()]['bCare'] ? reportData.byPractice[practiceName.toLowerCase()]['bCare'] : 0.00:0.00:0.00:0.00;
                const bCare_totalVisit:any  = reportData? reportData.byPractice? reportData.byPractice[practiceName.toLowerCase()]?reportData.byPractice[practiceName.toLowerCase()]['totalVisit'] ? reportData.byPractice[practiceName.toLowerCase()]['totalVisit']  :0.00:0.00:0.00:0.00;
                const bCare_adjustments:any  = reportData? reportData.byPractice? reportData.byPractice[practiceName.toLowerCase()]?reportData.byPractice[practiceName.toLowerCase()]['adjustments'] ? reportData.byPractice[practiceName.toLowerCase()]['adjustments']  :0.00:0.00:0.00:0.00;
                let n_bCare_perpractice:any = (isNaN(bCare_perpractice) ? 0 : ((bCare_perpractice*1.1)-bCare_adjustments));
                let n_bCare_totalVisit:any= (isNaN(bCare_totalVisit) ? 0 : bCare_totalVisit);
                const actppp:any = Math.round(parseInt((n_bCare_perpractice))/parseInt(n_bCare_totalVisit)).toFixed(2);
                let n_actppp = (isNaN(actppp) ? 0 : actppp);
                ACTPPP = n_actppp;
            }else{

            }

            let BDPPP:any = budget/totalPt;
            BDPPP = BDPPP? BDPPP.toFixed(2) : 0;
            
            let deltappp:any = ACTPPP - BDPPP;
            let n_deltappp:any = (isNaN(deltappp) ? 0 : deltappp.toFixed(2));
            let deltappp_display:string = n_deltappp>0 ? numberFormat(n_deltappp) : '('+numberFormat(n_deltappp*-1)+')';
            const DeltaCPV= deltappp_display;

            const ptVisitTotal:any = _FvPt+_RcPt+_TxPt;
            let ptGoal:any = Math.floor(ptVisitTotal/7);
            if(ptGoal<ptSeenToday){
                ptGoal= ptGoal+ptSeenToday;
            }

            const lwdProd:number = reportData? reportData.byPractice? reportData.byPractice[practiceName.toLowerCase()]? reportData.byPractice[practiceName.toLowerCase()]['lastDayBCare']? reportData.byPractice[practiceName.toLowerCase()]['lastDayBCare'] : 0: 0.0 : 0.00 : 0.000;
            const lwdPtCount:number = reportData? reportData.byPractice? reportData.byPractice[practiceName.toLowerCase()]? reportData.byPractice[practiceName.toLowerCase()]['lastDayPtCount']? reportData.byPractice[practiceName.toLowerCase()]['lastDayPtCount'] : 0: 0.0 : 0.00 : 0.000;
            
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });

            const pmdTodaysProduction=  await api.get(`practiceManagerDashboard/getPMDTodaysProduction${practice}`);
            const todayProd = pmdTodaysProduction[0].todayProd ? pmdTodaysProduction[0].todayProd : 0 ;
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });

            const pmdbiller =  await api.get(`practiceManagerDashboard/getPMDBiller${practice}`);
            const notbilled$ = pmdbiller[0].pmdnotbilled$ ? pmdbiller[0].pmdnotbilled$ : 0 ;
            const notbillerproc = pmdbiller[0].pmdnotbillerproc ? pmdbiller[0].pmdnotbillerproc : 0 ;
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });

            const pmdtasks =  await api.get(`practiceManagerDashboard/getPMDTasks${practice}`);
            const taskrollover = pmdtasks[0].pmdrollover ? pmdtasks[0].pmdrollover : 0 ;
            const tasktoday = pmdtasks[0].pmdtoday ? pmdtasks[0].pmdtoday : 0 ;
            const tasktotal = parseInt(taskrollover)+parseInt(tasktoday);
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });

            const npo =  await api.get(`practiceManagerDashboard/getPMDMCONPWlist${practice}`);
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });
            const rcno =  await api.get(`practiceManagerDashboard/getPMDMCORCNlist${practice}`);
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });

            const pmdScheduleCoordinator =  await api.get(`practiceManagerDashboard/getPMDScheduleCoordinator${practice}`);
            const apt_created_next_four_days = pmdScheduleCoordinator[0].apt4days ? pmdScheduleCoordinator[0].apt4days : 0 ;
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });

            const pmdScheduleCoordinatorWtX =  await api.get(`practiceManagerDashboard/getPMDScheduleCoordinatorWtX${practice}`);
            const no_future_w_tax = pmdScheduleCoordinatorWtX[0].nofutureWTX ? pmdScheduleCoordinatorWtX[0].nofutureWTX : 0 ;
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });

            const pmdScheduleCoordinatorApt =  await api.get(`practiceManagerDashboard/getPMDScheduleCoordinatorApt${practice}`);
            const no_future_apt = pmdScheduleCoordinatorApt[0].nofutureAPT ? pmdScheduleCoordinatorApt[0].nofutureAPT : 0 ;
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });

            const pmdleads = await api.get(`practiceManagerDashboard/leadsReports${practice}`);            
            let vleads_entered = 0;
                let vleads_won = 0;
                let vleads_worked = 0;
                let vleads_lost = 0;
                for (let i = 0; i < pmdleads.length; i++) {
                    switch (pmdleads[i].value) {
                        case "P1" : vleads_entered+=1;
                                    break;
                        case "Call Log" : vleads_worked+=1;
                                            break;
                        case "Lost" : vleads_lost+=1;
                                        break;
                        case "Won" : vleads_won+=1;
                                    break;
                        default : break;
                    }                               
                }
            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });

            pmdCSVobj.push({Practicename: item.value,
            ScheduledProd: (Math.round(expectedShow*ACTPPP)),
            LWDProd: lwdProd,
            TodayProd: todayProd,
            LWDPTCount: lwdPtCount ,
            TodaysGoal: _currentDayGoal,
            GLCPV: BDPPP,
            ACTCPV: ACTPPP,
            DeltaCPV: DeltaCPV,
            SchAptToday: schAptToday,
            PTSeenToday: ptSeenToday,
            ExpectedShow: expectedShow,
            PTGoalToday: ptGoal,
            NotBilled: notbilled$,
            NotBilledProc: notbillerproc,
            NPWorkNow: npo[0].npo,
            RcNoFutureWork: rcno[0].rcno,
            APTCreatedNext4Days: apt_created_next_four_days,
            NoFuturewtx: no_future_w_tax,
            APTCreatedGoal: 20,
            NoFutureApt: no_future_apt+no_future_w_tax,
            LeadsEnteredToday: vleads_entered,
            LeadsWorkedToday: vleads_worked,
            LeadsWonToday: vleads_won,
            LeadsLostToday: vleads_lost,
            TasksRollOver: taskrollover,
            TasksToday: tasktoday});

            progresscsv++;
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: (progresscsv/total)*100} });
            }
            dispatch({ type: ActionType.PMD_PROGRESSBAR, payload: { progresscsv: 100} });  
        const fileName = `Practice Manager Dashboard as at -${new Date()}.csv`;
        if (pmdCSVobj.length) {
            exportCSVPMD(fileName, pmdCSVobj);
        }
	} catch (err) {
        console.log(err);
        return dispatch({ type: ActionType.LOADER, payload: false });
	}
};

export const exportCSVPMD: ExportCSVPMD = (fileName, csvData) => {
    var csv = csvData.map(function(d){
        return JSON.stringify(Object.values(d));
    }).join('\n').replace(/(^\[)|(\]$)/mg, '');
    const dataType = isSafari() ? 'application/csv' : 'text/csv';
    const blob = new Blob(['', csv], {type: dataType});
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
};

export const intialiseRefresh = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        dispatch({ type: ActionType.DECREMENT, payload: 0});
        dispatch({ type: ActionType.LOADER, payload: false });
        return 0;
    } catch (err) {
        dispatch({ type: ActionType.DECREMENT, payload: 0});
        dispatch({ type: ActionType.LOADER, payload: false });
        return err;
    }
}

export const incrementPMDProgress = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        dispatch({ type: ActionType.INCREMENT, payload: 1});
        dispatch({ type: ActionType.LOADER, payload: false });
        return 0;
    } catch (err) {
        dispatch({ type: ActionType.INCREMENT, payload: 1});
        dispatch({ type: ActionType.LOADER, payload: false });
        return err;
    }
}

export const sendMailPMDNoFutureApt = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
    try {       
        let pmdCSVobj:PMDNoFutureAptCSVObject[] = [];
        pmdCSVobj.push(csvHeaderPMD_NoFutureApt);
        params.data = sortBy(params.data,"PatNum");           

        for (const item of params.data) {

            pmdCSVobj.push({Practice: params.practicename,
                PatNum: item.PatNum,
                LastVisit: item.LastVisit,
                LastApt: item.Last_Apt,
                NextApt: item.Next_Apt ,
                TxFee: item.TxFee});
            }
            
        if (pmdCSVobj.length) {
            params.data = pmdCSVobj;
            await api.post(`practiceManagerDashboard/sendMailNoFutureApt`,params);
            dispatch({ type: ActionType.LOADER, payload: false });
            return true;
        }
	} catch (err) {
        console.log(err);
        return dispatch({ type: ActionType.LOADER, payload: false });
	}
}

export const sendMailPMDNoFutureAptWtx = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
    try {       
        let pmdCSVobj:PMDNoFutureAptCSVObject[] = [];
        pmdCSVobj.push(csvHeaderPMD_NoFutureApt);
        params.data = sortBy(params.data,"PatNum");           

        for (const item of params.data) {

            pmdCSVobj.push({Practice: params.practicename,
                PatNum: item.PatNum,
                LastVisit: item.LastVisit,
                LastApt: item.Last_Apt,
                NextApt: item.Next_Apt ,
                TxFee: item.TxFee});
            }
            
        if (pmdCSVobj.length) {
            params.data = pmdCSVobj;
            await api.post(`practiceManagerDashboard/sendMailNoFutureAptWtx`,params);
            dispatch({ type: ActionType.LOADER, payload: false });
            return true;
        }
	} catch (err) {
        console.log(err);
        return dispatch({ type: ActionType.LOADER, payload: false });
	}
}

export const sendMailPMDTodayProdDetail = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
    try {       
        let pmdCSVobj:PMDTodayProdDetailCSVObject[] = [];
        pmdCSVobj.push(csvHeaderPMD_TodayProdDetail);
        params.data = sortBy(params.data,"PatNum");           

        for (const item of params.data) {

            pmdCSVobj.push({Practice: params.practicename,
                PatNum: item.PatNum,
                ProcDate: item.ProcDate,
                ProcCode: item.ProcCode,
                ProcFee: item.ProcFee});
            }

        if (pmdCSVobj.length) {
            pmdCSVobj.push({Practice: "TOTAL",
                PatNum: "",
                ProcDate: "",
                ProcCode: "",
                ProcFee: params.sum});
            params.data = pmdCSVobj;
            await api.post(`practiceManagerDashboard/sendMailTodayProdDetail`,params);
            dispatch({ type: ActionType.LOADER, payload: false });
            return true;
        }
	} catch (err) {
        console.log(err);
        return dispatch({ type: ActionType.LOADER, payload: false });
	}
}

export const sendMailPMDPtSeenTodayDetail = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
    try {       
        let pmdCSVobj:PMDTodayProdDetailCSVObject[] = [];
        pmdCSVobj.push(csvHeaderPMD_TodayProdDetail);
        params.data = sortBy(params.data,"PatNum");           

        for (const item of params.data) {

            pmdCSVobj.push({Practice: params.practicename,
                PatNum: item.PatNum,
                ProcDate: item.ProcDate,
                ProcCode: item.ProcCode,
                ProcFee: item.ProcFee});
            }

        if (pmdCSVobj.length) {
            pmdCSVobj.push({Practice: "TOTAL",
                PatNum: "",
                ProcDate: "",
                ProcCode: "",
                ProcFee: params.sum});
            params.data = pmdCSVobj;
            await api.post(`practiceManagerDashboard/sendMailPtSeenTodayDetail`,params);
            dispatch({ type: ActionType.LOADER, payload: false });
            return true;
        }
	} catch (err) {
        console.log(err);
        return dispatch({ type: ActionType.LOADER, payload: false });
	}
}

export const sendMailPMDLWDProdDetail = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
    try {       
        let pmdCSVobj:PMDTodayProdDetailCSVObject[] = [];
        pmdCSVobj.push(csvHeaderPMD_TodayProdDetail);
        params.data = sortBy(params.data,"PatNum");           

        for (const item of params.data) {

            pmdCSVobj.push({Practice: params.practicename,
                PatNum: item.PatNum,
                ProcDate: item.ProcDate,
                ProcCode: item.ProcCode,
                ProcFee: item.ProcFee});
            }

        if (pmdCSVobj.length) {
            pmdCSVobj.push({Practice: "TOTAL",
                PatNum: "",
                ProcDate: "",
                ProcCode: "",
                ProcFee: params.sum});
            params.data = pmdCSVobj;
            await api.post(`practiceManagerDashboard/sendMailLWDProdDetail`,params);
            dispatch({ type: ActionType.LOADER, payload: false });
            return true;
        }
	} catch (err) {
        console.log(err);
        return dispatch({ type: ActionType.LOADER, payload: false });
	}
}

export const sendMailPMDLWDptCountDetail = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
    try {       
        let pmdCSVobj:PMDTodayProdDetailCSVObject[] = [];
        pmdCSVobj.push(csvHeaderPMD_TodayProdDetail);
        params.data = sortBy(params.data,"PatNum");           

        for (const item of params.data) {

            pmdCSVobj.push({Practice: params.practicename,
                PatNum: item.PatNum,
                ProcDate: item.ProcDate,
                ProcCode: item.ProcCode,
                ProcFee: item.ProcFee});
            }

        if (pmdCSVobj.length) {
            pmdCSVobj.push({Practice: "TOTAL",
                PatNum: "",
                ProcDate: "",
                ProcCode: "",
                ProcFee: params.sum});
            params.data = pmdCSVobj;
            await api.post(`practiceManagerDashboard/sendMailLWDptCountDetail`,params);
            dispatch({ type: ActionType.LOADER, payload: false });
            return true;
        }
	} catch (err) {
        console.log(err);
        return dispatch({ type: ActionType.LOADER, payload: false });
	}
}

export const getPMDTodaysGoalAllPractices = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
    let pmdTodayGoalAll:any[] = [];
    try {
        
        params.practicenames.splice(0,1);
        params.practicenames = sortBy(params.practicenames,"value");
        for (const item of params.practicenames) {
            
            let practiceDisplayName = item.value;
            let practiceDBName:string = params.practiceinfo[practiceDisplayName.toLocaleLowerCase()].practiceDBName;
            let stringifyParams = '/'+JSON.stringify({practice:practiceDisplayName,practiceDBName:practiceDBName.toLocaleLowerCase()});
            const response = await api.get(`practiceManagerDashboard/getPMDTodaysGoalAllPractices${stringifyParams}`);
            pmdTodayGoalAll.push(response[0]);
            dispatch({ type: ActionType.TODAY_GOAL_INCREMENT, payload: 1});
        }
		dispatch({ type: ActionType.PMD_TODAY_GOAL_ALL, payload: pmdTodayGoalAll });
		dispatch({ type: ActionType.LOADER, payload: false });   
	} catch (err) {
        console.log(err);
        dispatch({ type: ActionType.PMD_TODAY_GOAL_ALL, payload: pmdTodayGoalAll });
		dispatch({ type: ActionType.LOADER, payload: false });
	}
    return pmdTodayGoalAll;
};

export const getPTTodayGoalSinglePractice = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
    let pTTodaysGoalAll:any[] = [];
    try {
        let stringifyParams = '/'+JSON.stringify({practice:params.practice,practiceDBName:params.practiceDBName,type:params.type});
        const response = await api.get(`practiceManagerDashboard/getPTTodayGoalSinglePractice${stringifyParams}`);
        pTTodaysGoalAll.push(response[0]);
		dispatch({ type: ActionType.PT_TODAY_GOAL_ALL, payload: {} });        
		dispatch({ type: ActionType.LOADER, payload: false });
	} catch (err) {
        console.log(err);
        dispatch({ type: ActionType.PT_TODAY_GOAL_ALL, payload: {} });
		dispatch({ type: ActionType.LOADER, payload: false });
        return pTTodaysGoalAll;
	}
    return pTTodaysGoalAll;
};

export const getPMDTodaysGoalPCRPopUp = (stringifyParams:any) => async (dispatch: Function, getState: Function, api: any) => {
    let pmdTodayGoalAll:any[] = [];
    try {        

        let response = [];
        if(stringifyParams) {
            response = await api.get(`practiceManagerDashboard/getPMDTodaysGoalAllPractices${stringifyParams}`);
            pmdTodayGoalAll.push(response[0]);
        } else {
            response = await api.get(`practiceManagerDashboard/getPMDTodaysGoalAllPractices/all`);
            pmdTodayGoalAll.push(response);
        }

		dispatch({ type: ActionType.PMD_TODAY_GOAL_ALL, payload: {} });        
		dispatch({ type: ActionType.LOADER, payload: false });
	} catch (err) {
        console.log(err);
        dispatch({ type: ActionType.PMD_TODAY_GOAL_ALL, payload: {} });
		dispatch({ type: ActionType.LOADER, payload: false });
        return pmdTodayGoalAll;
	}
    return pmdTodayGoalAll;
};

export const sendTodayGoalAllMail = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
    try {       
        let pmdCSVobj:PMDTodayGoalAllCSVObject[] = [];
        pmdCSVobj.push(csvHeaderPMD_TodayGoalAll);
        params.data = sortBy(params.data,"practice");           

        for (const item of params.data) {

            pmdCSVobj.push({practice: item.practice,
                todaysgoal: item.todaysgoal,
                totalbudget: item.totalbudget,
                MTDGoal: item.MTDGoal,
                mtdcare: item.mtdcare,
                MTDGoal_To_Act_care: item.MTDGoal_To_Act_care,
                workdaysTotal: item.workdaysTotal,
                workdaysLeft: item.workdaysLeft,
                totalshifts: item.totalshifts,
                totalsShiftsLeft: item.totalsShiftsLeft});
            }
            
        if (pmdCSVobj.length) {
            params.data = pmdCSVobj;
            await api.post(`practiceManagerDashboard/sendMailTodayGoalAll`,params);
            dispatch({ type: ActionType.LOADER, payload: false });
            return true;
        }
	} catch (err) {
        console.log(err);
        return dispatch({ type: ActionType.LOADER, payload: false });
	}
}
export const intialiseTodayGoalAllRefresh = () => async (dispatch: Function, _getState: Function, _api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        dispatch({ type: ActionType.TODAY_GOAL_DECREMENT, payload: 0});
        dispatch({ type: ActionType.LOADER, payload: false });
        return 0;
    } catch (err) {
        dispatch({ type: ActionType.TODAY_GOAL_DECREMENT, payload: 0});
        dispatch({ type: ActionType.LOADER, payload: false });
        return err;
    }
}

export function pmdActions(arg0: { practicenames: import("../../model/practice").DropDownOptions[]; practiceinfo: { [key: string]: { practice: string; callCenter: boolean; }; } | undefined; }): Promise<any> | undefined {
  throw new Error("Function not implemented.");
}

export const fetchPMDS3Data = (practiceName: string) => async (dispatch: Function, _getState: Function, api: any) => {

	dispatch({ type: ActionType.LOADER, payload: true });
	try {

		const response = await api.get(`practiceManagerDashboard/pmds3data/${practiceName}`);
		dispatch({ type: ActionType.PMD_S3_DATA, payload: response});
		dispatch({ type: ActionType.LOADER, payload: false });
        dispatch({ type: ActionType.INCREMENT, payload: 1});
		return {response, type: 'report'};
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.PMD_S3_DATA, payload: {} });
        dispatch({ type: ActionType.INCREMENT, payload: 1});
		return dispatch(errorHandler(err));
	}
};