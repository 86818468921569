import React, {useEffect, useState} from "react";
import {Button, Modal} from "semantic-ui-react";
import _ from "lodash";

function OSBrandRollUPModel (props: any) {

    const initialState = {column: null, data:[],order: true,}
    const [state, setState] = useState(initialState);
    const { name ,brand,popupInfo,total}=props;

    useEffect(() => {
        let data:any=[]
        Object.keys(popupInfo).forEach((r=>{
            if(r==brand||brand=="Grand Total"){
                data=[...data,...popupInfo[r][name]];
            }
        }))
        setState({...state,data})
    }, []);

    const handleSort = (clickedColumn: any) => {
        const {column, data, order} = state;
        const dat: any = _.sortBy(data, [clickedColumn]);
        if (column !== clickedColumn) {
            setState({
                ...state,
                column: clickedColumn,
                data: dat,
                order: true,
            })

            return
        }

        setState({
            ...state,
            data: data.reverse(),
            order: !order
        })
    }
    const getTableHeadings = () => {
        let returnArray: React.ReactElement[] = [];
        const {order} = state;
        const direction = order ? 'down' : 'up';
        const info:any = state.data.length>0?Object.keys(state.data[0]):['brand','practice']
        info.forEach((item: any, key: number) => {
            const klas= `header-os-inner cursorPointer ${state.column == item ? 'active' : ''}`
            returnArray.push(
                <th key={key} className={klas} onClick={() => handleSort(item)}>
                    {item} <i className={`sort amount ${direction} icon ml-05`}/>
                </th>);
        });
        return (<thead><tr>{returnArray}</tr></thead>)
    }

    const makeOSPCRBrandRollUpPopupCSV=()=>{
        const {data}=state;
        const header:any = data.length>0?Object.keys(state.data[0]).join(','):'brand,practice';
        let dataArr:any = []
        if (data && data.length > 0) {
            dataArr = data.map((item: any, key: number) => Object.values(item).join(','));
        }
        dataArr.unshift(header);
        return dataArr;
    }
    const getTableRows = () => {
        const {data}=state;
        let returnArrayInfo: React.ReactElement[] = [];
        let info:any = state.data.length>0?Object.keys(state.data[0]):['brand','practice'];
        let nameKey:any=name.split(" ").join("");
        if(!info.includes(nameKey)){
            nameKey=nameKey.toLowerCase()=="goalcare"?"budCare":nameKey;
            nameKey=nameKey.toLowerCase()=="dayscompleted"?"completeddays":nameKey;
            nameKey=nameKey.toLowerCase()=="actualpatientvisits"?"actPtVisits":nameKey;
            nameKey=nameKey.toLowerCase()=="goalpatientvisits"?"goalPt":nameKey;
            nameKey=nameKey.toLowerCase()=="actualcpv"?"actcpv":nameKey;
            nameKey=nameKey.toLowerCase()=="actualcare"?"actcare":nameKey;
        }
        if (data && data .length > 0){
            data.map((item: any, key: number) => {
                returnArrayInfo.push(<tr>
                    {info.map((r:any)=><td>{item[r]}</td>)}
                </tr>)
            });
            info=info.map((r:any)=>r.toLowerCase())
            if(total&&info.includes(nameKey.toLowerCase())){
                returnArrayInfo.push(<tr>{info.map((r:any)=><td>{nameKey.toLowerCase()==r.toLowerCase()?total:("brand"==r.toLowerCase()?"Grand Total":"")}</td>)} </tr>)
            }

        }else{
            returnArrayInfo.push(<tr><td colSpan={4}>no records found</td></tr>);
        }
        return (<tbody>{returnArrayInfo}</tbody>);
    }

    return (
        <Modal className={'modalCenter'}
               onClose={() => props.handleClose()}
               open={true}
        >
            <Modal.Header>{name} <a className={"ui orange label"}>{ total||""}</a> <Button
                className="float-right"
                content="close"
                primary={true}
                onClick={() => props.handleClose()}
            /></Modal.Header>
            <Modal.Content >
                <Modal.Description>
                    <div>
                        <div className="table-responsive">
                            <table className="ui celled single line table unstackable tableStyle tableOpenSurgery ">
                                {getTableHeadings()}
                                {getTableRows()}
                            </table>
                        </div>

                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="OK"
                    primary={true}
                    onClick={() => props.handleClose()}
                />
                <Button
                    content="SEND"
                    primary={true}
                    onClick={() => props.sendMailOSPCRPopupDetail({data:makeOSPCRBrandRollUpPopupCSV(),practicename:props.brand,popupTitle:"Brand Roll Up Pt Visit Appointments Scheduled"})}
                />
            </Modal.Actions>
        </Modal>
    );
}

export default OSBrandRollUPModel
