/* eslint-disable*/
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Button, Modal} from 'semantic-ui-react';

import {RootState} from '../model';
import {useActions} from '../redux/actions';
import * as Actions from '../redux/actions/hygienistBracketSystem';
import HygienistBracketSystemModal from './HygienistBracketSystemModal';
import Template from './Template';
import HelpMark from "./HelpMark";

function HygienistBracketSystem(props: any) {

    const initialState = {
        data: [],
        allowEdit: !!(props.user && props.user.isAdmin),
        openForm: false,
        addForm: {},
        editForm: {},
        allowDelete: false,
        deleteId: 0

    }
    const [state, setState] = useState(initialState);
    const BracketSystemActions = useActions(Actions);
    useEffect(() => fetchData(), [])
    const fetchData = () => {
        BracketSystemActions.getHygienistBracketSystem().then((data: any) => {
            setState({...state, data: data, openForm: false})
        })
    }
    const openForm = (key: any, pageId?: number) => {
        let stateobj: any = {openForm: 'addForm'}
        if (key !== 'addForm') {
            stateobj.openForm = 'editForm';
            let {data} = state;
            const editForm: any = state.editForm
            if (data && data[key] && editForm['id'] !== data[key]['id']) {
                stateobj['editForm'] = Object.assign({}, data[key]);
                // Interchange values for drop down
                if (stateobj['editForm']['parentPageId'] == '0') {
                    stateobj['editForm']['parentPageId'] = stateobj['editForm']['pageId'];
                    stateobj['editForm']['pageId'] = '0';
                }
                if (stateobj['editForm']['fileLinks'] && stateobj['editForm']['fileLinks'] !== '') {
                    stateobj['editForm']['fileLinks'] = stateobj['editForm']['fileLinks'].split(",");
                }
            }
        } else
            stateobj.addForm = {parentPageId: pageId};
        setState({...state, ...stateobj});
    }
    const handleDelete = (id?: number) => {
        if (id) {
            setState({...state, allowDelete: true, deleteId: id});
        }

    }
    const confirmDelete = () => {
        if (state.deleteId) {
            setState({...state, data: [], allowDelete: false})
            BracketSystemActions.deleteHygienistBracketSystem(state.deleteId)
                .then(() => {
                    fetchData()
                })
        }
    }
    const hideDelete = () => {
        setState({...state, allowDelete: false})
    }
    const {data, allowEdit, allowDelete} = state;
    const hideForm = () => {
        setState({...state, data: []})
        setState({...state, openForm: false, editForm: {}});
    }
    const openFormOnClick = () => {
        setState({...state, openForm: true});
    }

    return (
        <Template activeLink="hygienist-bracket-system">
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left">Hygienist Bracket System <HelpMark pageId='82'/></h2>
                    <div className="topFilters">
                        <Button
                            className="mb15"
                            primary
                            onClick={() => openFormOnClick()}
                        >{'Add'}
                        </Button>
                    </div>
                </div>
            </div>

            {
                state.openForm &&
                <HygienistBracketSystemModal
                    action={state.openForm}
                    hideForm={hideForm}
                    addForm={state.addForm}
                    editForm={state.editForm}
                    resertData={() => setState({...state, data: [], openForm: false})}
                    fetchData={fetchData}
                />
            }
            {
                state.allowDelete && (<Modal
                    open={true}
                    onClose={hideDelete}
                    aria-labelledby="ModalHeader"
                    centered={true}
                    closeIcon={true}
                >
                    <Modal.Header/>
                    <Modal.Content scrolling={true}>
                        Are you sure you want to Delete this Item ?
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' onClick={() => confirmDelete()}>Yes</Button>
                    </Modal.Actions>
                </Modal>)
            }
            <div className="ui card">
                <div className="content">
                    <table className="ui table table-striped celled table-hover unstackable">
                        <thead>
                        <tr>
                            <th className={`cursorPointer `}>
                                <div>ID <i className={`sort amount icon ml-05`}/></div>
                            </th>
                            <th className={`cursorPointer `}>
                                <div>Care Per Day <i className={`sort amount  icon ml-05`}/></div>
                            </th>
                            <th className={`cursorPointer  `}>
                                <div>Care Per Day Percentage <i className={`sort amount } icon ml-05`}/></div>
                            </th>
                            {allowEdit && <th>
                                <div>Action</div>
                            </th>}

                        </tr>
                        </thead>
                        <tbody>{
                            data.length !== 0 ? data && data.map((item: any, key: any) =>
                                <tr key={key}>
                                    <td>
                                        {item.id}
                                    </td>
                                    <td>{item.billableCarePerDay}</td>
                                    <td>{item.billableCarePerDayPercentage} %</td>
                                    {
                                        allowEdit &&
                                        <td>
                                            <Button
                                                size='small'
                                                className={item.id ? 'secondary' : 'primary'}
                                                onClick={() => openForm(item.id ? key : 'addForm', item.pageId)}
                                            >
                                                {item.id ? 'Edit' : 'Add'}
                                            </Button>
                                            <Button
                                                size='small'
                                                color='red'
                                                onClick={() => handleDelete(item.id)}
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                    }

                                </tr>
                            ) : "No Records"}
                        </tbody>
                    </table>
                </div>
            </div>
        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        alert: state.alert,
        accessToken: state.auth.accessToken
    };
}

export default connect(mapStateToProps)(HygienistBracketSystem);
