import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Template from "./Template";
import { User } from "../model/user";
import { RootState } from "../model";
import { Practice } from "../model/practice";
import { useActions } from "../redux/actions";
import * as usersActions from "../redux/actions/users";
import {Accordion, Form, Icon, Grid, Dropdown} from "semantic-ui-react";
import OpenDentalUserResult from "./OpenDentalUserResult";
import * as practiceActions from "../redux/actions/practice";
import {brands} from "../constants";
import Loading from "../Loading";

interface Props {
  auth: User;
  allPracticeList: any;
  loader : any;
}

function CreateOpenDentalUser(props: Props) {
  const UsersActions = useActions(usersActions);
  const practiceAction = useActions(practiceActions);

  const initialState: any = {
    practiceList: [],
    practiceOpts : [],
    groupList: [],
    username: "",
    password: "",
    passwordError: "Password must not be empty",
    result: [],
  };
  const initialFilters: any = {
    practices : [],
    brands : []
  };

  const initSelectedGroups: any = {};

  const [state, setState] = useState(initialState);
  const [filters, setFilters] = useState(initialFilters);
  const [selectedLocations, setSelectedLocations] = useState(initSelectedGroups);
  const [selectedGroups, setSelectedGroups] = useState(initSelectedGroups);
  const [activeIndex, setActiveIndex] = useState(-1);

  const {loader, allPracticeList } = props;

  useEffect(() => {
    UsersActions.getOdUserGroups().then((groups: any) => {
      const practiceOpts  = [{key: 'all', value: '', text: 'Select Practice'}];
      const practiceList : any = [];

      const grpList: any = {};
      groups &&
      Object.keys(groups).forEach((practice) => {

        practiceOpts.push({
          key: practice,
          value: practice,
          text: practice
        })
        practiceList.push(practice);

        grpList[practice] = [];
        groups[practice] &&
        groups[practice].forEach((group: any) => {
          grpList[practice].push({
            text: group.Description,
            value: group.UserGroupNum,
            key: group.UserGroupNum,
          });
        });
      });

      setState({...state, practiceOpts: practiceOpts, practiceList: practiceList, groupList: grpList})
    })
  }, []);

  const validatePassword = (password: string) => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    if (!/[A-Z]/.test(password)) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!/[a-z]/.test(password)) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!/[0-9]/.test(password)) {
      return "Password must contain at least one number.";
    }
    if (!/[!@#$%^&*]/.test(password)) {
      return "Password must contain at least one special character.";
    }
    return "";
  };

  const handleChange = (name: string, value: string) => {
    let passwordError = "";
    if (name === "password") {
      passwordError = validatePassword(value);
    }

    if (!passwordError) {
      state[name] = value;
    }
    setState({ ...state, passwordError: passwordError });
  };

  const handleAccordionClick = (index: number) => {
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  const closeResult = () => {
    setState({ ...state, result: [] });
  };

  const handleGroupChange = (location: string, group: number) => {
    setSelectedGroups((prev: any) => ({
      ...prev,
      [location]: group,
    }));
    setSelectedLocations((prev: any) => ({
      ...prev,
      [location]: true,
    }));
  };

  const handleSubmit = async () => {
    const { passwordError, username, password } = state;
    if (passwordError) {
      alert(passwordError);
      return;
    }
    if (!username) {
      alert("User Name must not be empty");
      return;
    }
    if(!Object.keys(selectedGroups).length){
      alert("Please select user group");
      return;
    }
    const odUser = {
      username: username,
      password: password,
      groups: selectedGroups,
    };
    const result = await UsersActions.createOpenDentalUser(odUser);
    setState({ ...state, result: result });
  };


  const getAccordionData = () => {
    const { practiceList, groupList } = state;
    const { practices, brands} = filters;

    let filteredList = practiceList;

    if (brands.length) {
      const practiceNames = allPracticeList.filter((item: any) => {
        if (item.UdaPracticeInfo) {
          return brands.includes(item.UdaPracticeInfo.brand);
        }
        return false;
      });
      filteredList = practiceNames.map((item: { practice: string }) => item.practice);
    }

    if (practices.length) {
      filteredList = filteredList.filter((item: any) => practices.includes(item));
    }

   return filteredList.map((location: string, index: number) => (
        <div key={index}>
          <Accordion.Title
              active={activeIndex === index}
              index={index}
              onClick={() => handleAccordionClick(index)}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "3px 10px",
              }}
          >
                          <span onClick={(e) => e.stopPropagation()}>
                            <Form.Checkbox
                                label={location}
                                checked={selectedLocations[location] || false}
                                onChange={() => {}}
                            />
                          </span>
            <Icon name="dropdown" />
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index}>
            <Grid>
              <Grid.Row>
                {groupList &&
                    groupList[location] &&
                    groupList[location].map(
                        (group: {
                          key: number;
                          text: string;
                          value: number;
                        }) => (
                            <Grid.Column
                                computer={8}
                                tablet={8}
                                mobile={16}
                                key={group.key}
                            >
                              <Form.Radio
                                  label={group.text}
                                  checked={
                                      selectedGroups[location] ===
                                      group.value
                                  }
                                  onChange={() =>
                                      handleGroupChange(
                                          location,
                                          group.value
                                      )
                                  }
                              />
                            </Grid.Column>
                        )
                    )}
              </Grid.Row>
            </Grid>
          </Accordion.Content>
        </div>
    ))
  }
  return (
    <Template activeLink="create-user">
      <div className="ui card">
        <div className="content">
          <h2 className="float-left mr10">Create Open Dental User</h2>
          <div className="topFilters">
            <Dropdown
                placeholder='Select Practice'
                options={state.practiceOpts}
                selection={true}
                search={true}
                multiple={true}
                value={filters.practices}
                onChange={(e, values) => setFilters({...filters, practices : values.value})}
                name="practice"
            />

            <Dropdown
                placeholder='Select Brand'
                options={brands}
                selection={true}
                multiple={true}
                search={true}
                value={filters.brands}
                onChange={(_: any, {value}: any) => {
                  setFilters({...filters, brands: value})
                }}
                name="brands"
            />
          </div>

        </div>
      </div>

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <div className="ui card">
              <div className="content">
                <Form>
                  <Form.Group widths="equal">
                    <Form.Input
                      placeholder="Username"
                      name="username"
                      onChange={(e, { name, value }) =>
                        handleChange(name, value)
                      }
                    />
                    <Form.Input
                      placeholder="Password"
                      name="password"
                      type="password"
                      onChange={(e, { name, value }) =>
                        handleChange(name, value)
                      }
                    />
                  </Form.Group>
                  <Accordion styled={true} fluid={true} className="mb15">
                    {getAccordionData()}
                  </Accordion>
                  <Form.Button content="Submit" onClick={handleSubmit} />
                </Form>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {state.result && state.result.length ? (
        <OpenDentalUserResult
          result={state.result}
          message={"User has been added successfully!"}
          handleClose={closeResult}
        />
      ) : (
        ""
      )}
      {loader && <Loading />}
    </Template>
  );
}

function mapStateToProps(state: RootState) {
  return {
    auth: state.auth,
    allPracticeList : state.practice.allPracticeList,
    loader : state.app.loader
  };
}

export default connect(mapStateToProps)(CreateOpenDentalUser);
