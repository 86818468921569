import React from "react";
import {useHistory} from 'react-router-dom';
import {Button, Modal} from "semantic-ui-react";

function PCRFailedModel (props: any) {

    const history = useHistory();

    const handleClose=()=>{
        history.push("/partners");
    }

    return (
        <Modal className={'modalCenter'}
               onClose={() => handleClose()}
               open={true}
        >
            <Modal.Header></Modal.Header>
            <Modal.Content >
                <Modal.Description>
                    <div>
                     PCR Failed to load. Please contact admin
                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="OK"
                    primary={true}
                    onClick={() => handleClose()}
                />

            </Modal.Actions>
        </Modal>
    );
}

export default PCRFailedModel



