import React, { useEffect, useState } from 'react'
import { AccordionContent, AccordionTitle, Grid, GridColumn, GridRow, Icon, Loader, Progress, Table, TableBody, TableCell, TableRow } from 'semantic-ui-react';
import * as PracticesActions from '../../redux/actions/practice';
import { capitalizeFirstLetter, subcategories } from './healthStatusUtils';
import { useActions } from '../../redux/actions';
import ProgressBar from './ProgressBar';
import { HealthScore } from '../../model/healthScore';

interface Props {
    data: any,
    loading: boolean
    overalCount: HealthScore
}

const DentaHubApps = ({ data, loading , overalCount }: Props) => {
    useEffect(() => { getData() }, [data]);
    const initialState = { panel: '', data: [] as any, practices: [], selected: { sub: '', practice: '' }, masterCount: 0 };
    const [state, setState] = useState(initialState);
    const practicesActions = useActions(PracticesActions);
    const { selected } = state;
    const prefix = 'dentaHubApps'

    const onSelect = (selected: string) => {
        if (state.panel === selected) {
            setState({ ...state, panel: '' });
            return;
        }
        setState({ ...state, panel: selected });
    }

    const getData = async () => {
        const practices = await practicesActions.getDentaHubPractices();
        setState({ ...state, data: data.groupedData, practices });
    }

    const onTileSelect = (sub: string, practice: string) => {
        const { selected } = state;
        if (!practice && selected.sub === sub) {
            setState({ ...state, selected: { ...selected, sub: '' } });
        } else if (selected.sub === sub && selected.practice === practice) {
            setState({ ...state, selected: { ...selected, practice: '' } });
        } else {
            setState({ ...state, selected: { sub, practice } });
        }
    }

    const setAppCount = (app: { name: string, label: string }) => {
        const trackerRecords = data.trackerRecords ? data.trackerRecords : [];
        const records = trackerRecords.filter((tr: any) => tr.scraper.toLowerCase() === app.label.toLowerCase());
        const failed = records.reduce((count: number, ar: any) => count + (ar.status === 'no' ? 1 : 0), 0);
        return (records.length - failed) * 100 / (records.length || 1);
    }

    const setAppPracticeCount = (app: { name: string, label: string }, practice: any) => {
        const trackerRecords = data.trackerRecords ? data.trackerRecords : [];
        const records = trackerRecords.filter((o: any) =>
            (o.practice === practice.practice || o.practice === practice.practiceDBName || o.practice === practice.practiceabbr)
            && o.scraper.toLowerCase() === app.label.toLowerCase());
        const failed = records.reduce((count: number, ar: any) => count + (ar.status === 'no' ? 1 : 0), 0);
        return (records.length - failed) * 100 / (records.length || 1);
    }

    return (
        <>
            <AccordionTitle active={state.panel === prefix} onClick={() => onSelect(prefix)}>

                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Insurance (Apps)</span>
                            {loading && <Loader size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={overalCount.failed} pending={overalCount.pending} success={overalCount.success} />
                        </GridColumn>
                    </GridRow>
                </Grid>

            </AccordionTitle>
            <AccordionContent active={state.panel === prefix}>
                {
                    subcategories.map((sub, index) =>
                        <Table fixed key={index} >
                            <TableBody>
                                <TableRow onClick={() => onTileSelect(sub.name, '')} style={{ cursor: 'pointer' }}>

                                    <TableCell><span style={{ marginLeft: '20px' }}><Icon name='dropdown' />{capitalizeFirstLetter(sub.label)} {sub?.caption}</span></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Progress color='green' size='medium' className='red-bg mb0' percent={setAppCount(sub)} />
                                    </TableCell>
                                </TableRow>
                                {
                                    selected.sub === sub.name && state.practices.map((practice: any, key: number) => (
                                        <TableRow style={{ cursor: 'pointer', backgroundColor: key % 2 !== 0 ? '#eaeaea' : 'white' }} key={practice.id}>
                                            <TableCell colspan='6'>
                                                <Grid>
                                                    <GridRow onClick={() => onTileSelect(sub.name, practice.practice)}>
                                                        <GridColumn width={14}>
                                                            <span style={{ fontSize: '15px', marginLeft: '60px' }}>{practice.practice}</span>
                                                        </GridColumn>
                                                        <GridColumn textAlign='right' width={2}>
                                                            <Progress color='green' size='medium' className='red-bg mb0' percent={setAppPracticeCount(sub, practice)} />
                                                        </GridColumn>
                                                    </GridRow>
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    )
                }
            </AccordionContent>
        </>
    )
}

export default DentaHubApps
