import React, {useState, useEffect, useCallback} from "react";
import {
    Grid,
    Table,
    Button, Icon, Dropdown, Input, Dimmer, Loader, Pagination,
} from "semantic-ui-react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as NotificationsActions from "../redux/actions/educatorNotifications";
import * as ProdecureCodeChange from "../redux/actions/procedureCodeChange"
import * as PracticeActions from "../redux/actions/practice"
import {useActions} from "../redux/actions";
import Form from "./Form";
import _ from "lodash";
import HelpMark from "./HelpMark";
import {render} from "react-dom";
import ProcedureCodeChangerList from "./ProcedureCodeChangeList";
import GroupModal from "./GroupModal";
import ProcedureCodeForm from "./ProcedureCodeForm";
import DeleteModal from "./DeleteProcedureCodeModal"

interface ProcedureCodeChangeProps {
    id: number,
    procedureCode: string,
    abbrDesc: string,
    description: string,
    'OD ProcCat': string,
    isDeleted: boolean,
    createdAt: string,
    updatedAt: string,
    ProcedureCode_GroupData: Array<Object>
}

type notificationScema = {
    practice: string
}

type procedureCodeSchema = {
    data: Array<any>,
    orderBy: any,
    order: boolean,
    search: string,
    loading: boolean,
    showDelConfirm: boolean,
    showForm: boolean,
    selectedId: any,
    formData: any,
    group: number,
    importError: any,
    action: any,
    groupSelect: number,
    options: Array<any>,
    selectOptions: Array<any>,
    groupListOptions: Array<any>,
    showGroupForm: boolean,
    groupError: any,
    optionError: any,
    id: any,
    abbrDesc: any,
    description: any
}


const ProcedureCodeChanger = (props: ProcedureCodeChangeProps) => {
    const initialState: procedureCodeSchema = {
        data: [],
        orderBy: null,
        order: true,
        search: '',
        loading: false,
        showDelConfirm: false,
        showForm: false,
        selectedId: null,
        formData: null,
        group: 0,
        importError: null,
        action: null,
        groupSelect: 0,
        options: [],
        selectOptions: [],
        groupListOptions: [],
        showGroupForm: false,
        groupError: null,
        optionError: null,
        id: null,
        abbrDesc: null,
        description: null

    }
    const [state, setStates] = useState(initialState);
    const [createForm, setCreate] = useState(false);
    const [practiceDropDown, setPracticeDropDown] = useState([]);
    const educatorNotifications = useActions(NotificationsActions);
    const procedureCodeChangerActions = useActions(ProdecureCodeChange);
    const [procedureCodeChangeData, setProcedureCodeChangeData] = useState([])
    const practiceActions = useActions(PracticeActions);
    const [groupListOptions, setGroupListOptions] = useState([])
    const [notificationEdited, setEditNotification] = useState<notificationScema>();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Define the number of items to display per page
    const [searchQuery, setSearchQuery] = useState("");
    const [editingId, setEditingId] = useState("");
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset page to 1 when search query changes
    };
    const style = {
        width: "50%",
        padding: 10,
    };
    useEffect(() => {

        educatorNotifications.fetchTableResult();
        practiceActions.fetchPractices(refinePracticeArray)
            .then((practiceOptions: any) => {
                setPracticeDropDown(practiceOptions);
            });
        procedureCodeChangerActions.getProcedureCodeGroup().then((procedureCodeGroups: any) => {
            let options = [{key: 0, value: 0, text: 'Select Group'}];

            procedureCodeGroups && procedureCodeGroups.forEach((item: any) => {
                options.push({
                    key: item.id,
                    value: item.id,
                    text: item.name
                })
            },)
            const options1 = Object.assign(state.options, options,)
            const groupOptions = Object.assign(state.groupListOptions, procedureCodeGroups)
            setStates({...state, options: options1, groupListOptions: procedureCodeGroups})

            let selectOptions = [{key: 0, value: 0, text: 'Select ...'}];
            if (group > 0) {
                let groupDetails = procedureCodeGroups.find((item: any) => item.id === group);
                const groupOptions = groupDetails.ProcedureCode_GroupOptions ? groupDetails.ProcedureCode_GroupOptions : [];
                groupOptions.forEach((item: any) => {
                    selectOptions.push({
                        key: item.id,
                        value: item.id,
                        text: item.name
                    })
                })
            }
            setStates({...state, selectOptions: selectOptions});

        })
        procedureCodeChangerActions.fetchProcedureCodeChanger().then((data1: any) => {
            setStates({...state, data: data1})
            setProcedureCodeChangeData(data1)


        })
    }, []);

    // Filters Functions
    const searchText = async (v: any, e: any) => {
        const {data} = state;
        const keyword = e.value;
        let result = _.filter(data, function (item) {
            return (item.procedureCode && item.procedureCode.indexOf(keyword) > -1
                || item.abbrDesc && item.abbrDesc.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                || item.Description && item.Description.toLowerCase().indexOf(keyword.toLowerCase()) > -1
                || item['OD ProcCat'] && item['OD ProcCat'].toLowerCase().indexOf(keyword.toLowerCase()) > -1);
        });
        setStates({...state, search: keyword, data: result})
    }
    const getSortRows = (sortColumn: any, order: any) => {
        const {data} = state;
        setStates({
            ...state,
            data: _.orderBy(data, [sortColumn], [order ? 'asc' : 'desc']),
            orderBy: sortColumn,
            order: !order
        });
    }

    const refinePracticeArray = (response: any) => {
        let practiceOptions = [{key: 0, value: 0, text: 'Select Practice'}];
        if (response) {
            response.forEach((item: any) => {
                practiceOptions.push({text: item.practice, value: item.practice, key: item.id.toString()});
            })
        }
        return practiceOptions;
    };
    const groupFilters = (v: any, e: any) => {
        const { data } = state;
        let data1 = data;
        const selectedId = parseInt(e.value);

        if (selectedId > 0) {
            data1 = data.filter((item: any) => {
                const group = item.ProcedureCode_GroupData || [];
                return group.some((groupItem: any) => parseInt(groupItem.groupId) === selectedId);
            });
        }
        setSearchQuery('');

        setStates({
            ...state,
            group: selectedId,
            data: data1,
            groupSelect: 0,
        });
    };


    const handleUpdate = async (data: any) => {

        procedureCodeChangerActions.updateProcedureCodeChanger(data)
            .then(() => {
                window.location.reload();
                setStates({...state, loading: true});
                procedureCodeChangerActions.fetchProcedureCodeChangerList()
                    .then((data: any) => {
                        const updateCarrierData = {...updateData(data)}
                        setStates({...state, loading: false, data: {...updateCarrierData}});
                    }).catch(() => setStates({...state, loading: false}))
            }).catch(() => setStates({...state, loading: false}))

    }
    const onShowHide = (id: any) => {
        let rowId = null;
        let isConfirm = false
        if (id) {
            isConfirm = true;
            rowId = id;
        }
        setStates({...state, showDelConfirm: isConfirm, selectedId: rowId, action: null});
    }
    const onDeleteGroup = (action: any, id: any) => {
        setStates({
            ...state,
            action,
            selectedId: id,
            showDelConfirm: true
        })
    }
    const addGroup = () => {
        setStates({...state, showGroupForm: true})
    }
    const updateData = (results: any) => {
        const {data} = state;
        let updateProcedureCodeData: any = [];
        data && data.forEach((item) => {
            const objExist = results.find((procCodeItem: any) => parseInt(procCodeItem.id) === parseInt(item.id));
            if (objExist) {
                updateProcedureCodeData.push(objExist);
            }
        })
        return updateProcedureCodeData;
    }
    const saveForm = async (formData: any) => {
        procedureCodeChangerActions.updateProcedureCodeChanger(formData).then(() => {
            procedureCodeChangerActions.fetchProcedureCodeChangerList()
                .then((data: any) => {
                    const updateCarrierData = {...updateData(data)};
                    const dataObj = Object.assign(state.loading, {loading: false})
                    setStates({
                        ...state,
                        loading: dataObj,
                        data: Object.assign(state.data, updateCarrierData),
                        showForm: false,
                        selectedId: null,
                        formData: null
                    });
                }).catch(() => setStates({...state, loading: false, showForm: false, selectedId: null, formData: null}))
        }).catch(() => setStates({...state, loading: false, showForm: false, selectedId: null, formData: null}))

        const dataObj = Object.assign(state.loading, {loading: false})
        setStates({...state, loading: dataObj})

    }
    const cancelForm = () => {
        setStates({...state, showForm: false, selectedId: null, formData: null})
    }

    const editForm = (id: any) => {
        const {data} = state;
        const formData = data.find((item: any) => parseInt(item.id) === parseInt(id))
        setStates({...state, showForm: true, selectedId: id, formData});
    }
    const onDeleteRow = () => {
        const {selectedId, action} = state;


        setStates({...state, loading: true});
        if (!action) {
            procedureCodeChangerActions.deleteProcedureCodeChangerList(selectedId)
                .then(() => {
                    procedureCodeChangerActions.fetchProcedureCodeChangerList()
                        .then((result: any) => {
                            const updateProcedureDataData = updateData(result);
                            const dataObj = Object.assign(state.loading, {loading: false})
                            setStates({
                                ...state,
                                selectedId: null,
                                showDelConfirm: false,
                                loading: dataObj,
                                data: updateProcedureDataData
                            });

                        }).catch(() => setStates({...state, selectedId: null, showDelConfirm: false}))
                }).catch(() => setStates({...state, selectedId: null, showDelConfirm: false, loading: false}));
        }

        if (action === 'deleteGroup') {
            let obj = {
                groupId: selectedId,
                action: 'delete'
            }
            procedureCodeChangerActions.updateProcedureCodeGroup(obj)
                .then(() => {
                    procedureCodeChangerActions.getProcedureCodeGroup()
                        .then(() => {
                            const dataObj = Object.assign(state.loading, {loading: false})
                            setStates({
                                ...state,
                                action: null,
                                selectedId: null,
                                showDelConfirm: false,
                                loading: dataObj
                            })
                        })
                })
                .catch((error: any) => {
                    setStates({
                        ...state,
                        action: null,
                        selectedId: null,
                        showDelConfirm: false,
                        loading: false
                    })
                })
        }

    }
    const importData = async () => {

        setStates({...state, loading: true})
        const response = await procedureCodeChangerActions.importProcedureCode();
        let updatedCount = response ? response.updatedCount : 0
        let insertCount = response ? response.insertCount : 0
        if (updatedCount > 0 || insertCount > 0) {
            const result = await procedureCodeChangerActions.fetchProcedureCodeChangerList();
            const dataObj = Object.assign(state.loading, {loading: false})
            setStates({...state, loading: dataObj, data: result});
            alert(`Data imported successfully.(Total Update=${updatedCount}, Total New=${insertCount})`)
        } else {
            alert('No new record found.');
            const dataObj = Object.assign(state.loading, {loading: false})
            setStates({...state, loading: dataObj})

        }
    }
    const onChangeRowData = (v: any, e: any) => {
        const key = e.name.split('-');
        const rowId = key[0];
        const groupId = key[1];
        const id = parseInt(e.value);
        let obj = {
            id: rowId,
            group: [{groupId: parseInt(groupId), optionId: id}]
        }

        handleUpdate(obj);
        setStates({...state, [`${e.name}`]: parseInt(e.value)})


    }
    const groupRowData = (rowData: any) => {
        const {groupListOptions} = state;
        const rowId = parseInt(rowData.id)
        const rowItem = rowData && rowData.ProcedureCode_GroupData;
        let groupList: any = [];
        groupListOptions.forEach((item: any) => {
            const groupId = parseInt(item.id);
            let value = 0;
            let listOptions = [{key: 0, value: 0, text: 'Select Group', style: {fontSize: '13px', height: '6px'}}];
            const groupOptions = item.ProcedureCode_GroupOptions ? item.ProcedureCode_GroupOptions : [];
            groupOptions.forEach((option: any) => {
                listOptions.push({
                    key: parseInt(option.id),
                    value: parseInt(option.id),
                    text: option.name,
                    style: {fontSize: '12px', height: '5px'}
                });
                const isExist = rowItem.find((row: any) => row.groupId === groupId);
                if (isExist) {
                    value = isExist.optionId
                }
            })
            groupList.push(
                <td key={item.id}>
                    <Dropdown
                        options={listOptions}
                        placeholder="Select Group"
                        className="mr10 mb15"
                        search
                        size='small'
                        onChange={onChangeRowData}
                        value={value}
                        name={`${rowId}-${groupId}`}
                    />
                </td>
            )
        })
        return groupList;
    }
    const onInlineChange = (e: any) => {
        setStates({...state, [`${e.target.name}`]: e.target.value});
    }
    const onClickInput = (id: any, name: any, value: any) => {
        if (name === 'abbrDesc') {
            setStates({...state, id, abbrDesc: value, description: null});
        } else {
            setStates({...state, id, description: value, abbrDesc: null});
        }
    };

    const groupHeading = () => {
        const {groupListOptions} = state;
        const groupList = groupListOptions.map((item: any, index: any) => {
            return (
                <Table.HeaderCell key={index}>{item.name}</Table.HeaderCell>
            )
        })
        return groupList;
    }
    const saveChanges = (e: any) => {
        handleUpdate({id: state.id});
        setStates({...state, id: '', abbrDesc: null, description: null});
    }
    const handlePageChange = (event: React.MouseEvent, data: any) => {
        const { activePage } = data;
        setCurrentPage(activePage);
    };

    const {
        search,
        order,
        orderBy,
        showDelConfirm,
        formData,
        showForm,
        group,
        loading,
        groupSelect,
        abbrDesc,
        description,
        id
    } = state;
    const direction = order ? 'down' : 'up';
    // Filter the data based on the search query
    const filteredData = state.data.filter((item: any) => {
        const procedureCode = item.procedureCode ? item.procedureCode.toLowerCase() : "";
        const description = item.Description ? item.Description.toLowerCase() : "";
        const abbrDesc = item.abbrDesc ? item.abbrDesc.toLowerCase() : "";
        const odProcCat = item['OD ProcCat'] ? item['OD ProcCat'].toLowerCase() : "";

        return (
            procedureCode.includes(searchQuery.toLowerCase()) ||
            description.includes(searchQuery.toLowerCase()) ||
            abbrDesc.includes(searchQuery.toLowerCase()) ||
            odProcCat.includes(searchQuery.toLowerCase())
        );
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    return (
        <>
            <Template activeLink="procedure-code-change">
                <div className="ui card">
                    <div className="content pb0">
                        <h2 className="float-left mr10">Procedure Code Change
                            ({state.data ? state.data.length : 0})<HelpMark pageId='53'/></h2>
                        <div className='topFilters'>
                            <Button primary onClick={() => importData()}>
                                Import Procedure Code
                            </Button>
                            <Button primary onClick={() => addGroup()}>
                                Groups
                            </Button>
                            <Dropdown
                                search={true}
                                name="selectGroup"
                                placeholder="Select Group"
                                selection={true}
                                options={state.options}
                                onChange={() => groupFilters}
                            />
                            <Input
                                name="search"
                                value={searchQuery}
                                placeholder="Search"
                                onChange={handleSearchChange}
                            />
                        </div>
                    </div>
                </div>
                {formData && <ProcedureCodeForm
                    showForm={showForm}
                    formData={formData}
                    saveForm={saveForm}
                    cancelForm={cancelForm}
                    header="Edit Procedure Code Changer"
                    hideForm={() => setStates({...state, showForm: false})}
                    procedureCodeGroups={state.groupListOptions}
                />}

                <div className="ui card">
                    <div className="content">
                        <div className="FreezeTable">
                            <Table className="ui table table-striped table-hover celled unstackable">
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell><div>Action</div></Table.HeaderCell>
                                        <Table.HeaderCell
                                            className={`cursorPointer ${orderBy === 'procedureCode' ? 'active' : ''}`}
                                            onClick={() => getSortRows('procedureCode', order)}>
                                            <div>Procedure Code
                                                <Icon className={`sort ${direction}`}/></div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            className={`cursorPointer ${orderBy === 'abbrDesc' ? 'active' : ''}`}
                                            onClick={() => getSortRows('abbrDesc', order)}>
                                            <div>Abbr Desc
                                                <Icon className={`sort ${direction}`}/></div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            className={`cursorPointer ${orderBy === 'description' ? 'active' : ''}`}
                                            onClick={() => getSortRows('description', order)}>
                                            <div>Description
                                                <Icon className={`sort ${direction} `}/></div>
                                        </Table.HeaderCell>
                                        <Table.HeaderCell
                                            className={`cursorPointer ${orderBy === 'OD ProcCat' ? 'active' : ''}`}
                                            onClick={() => getSortRows('OD ProcCat', order)}>
                                            <div>OD ProcCat
                                                <Icon className={`sort ${direction}`}/></div>
                                        </Table.HeaderCell>
                                        {groupHeading()}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {loading && <Dimmer active inverted>
                                        <Loader inverted>Loading</Loader>
                                    </Dimmer>
                                    }
                                    {currentItems && currentItems.map((i: any, index: any) =>
                                        <Table.Row key={index}>
                                            <Table.Cell style={{width: '200px'}}>
                                                <Icon className="cursorPointer" name="edit"
                                                      onClick={() => editForm(i.id)}/>
                                                <Icon className="cursorPointer ml10" name="trash alternate"
                                                      onClick={(a:any, i:any) => onShowHide(i.id)}/>
                                            </Table.Cell>
                                            <Table.Cell>{i.procedureCode}</Table.Cell>
                                            <Table.Cell>{(i.id === id && abbrDesc)
                                                ? (<input
                                                    type="text"
                                                    autoFocus={true}
                                                    name="abbrDesc"
                                                    onKeyPress={onInlineChange}
                                                    onChange={() => onInlineChange}
                                                    value={abbrDesc}
                                                    onBlur={saveChanges}/>)
                                                : (<p
                                                    onClick={() => {
                                                        onClickInput(i.id, 'abbrDesc', i.abbrDesc)
                                                    }}
                                                >
                                                    {i.abbrDesc}
                                                </p>)}
                                            </Table.Cell>
                                            <Table.Cell style={{'maxWidth': '200px'}}>{(i.id === id && description)
                                                ? (<input
                                                    type="text"
                                                    autoFocus={true}
                                                    name="description"
                                                    onKeyPress={onInlineChange}
                                                    onChange={onInlineChange}
                                                    value={description}
                                                    onBlur={saveChanges}/>)
                                                : (<p
                                                    onClick={() => {
                                                        onClickInput(i.id, 'description', i.description)
                                                    }}
                                                >
                                                    {i.description}
                                                </p>)}
                                            </Table.Cell>
                                            <Table.Cell>{i['OD ProcCat']}</Table.Cell>
                                            {groupRowData(i)}
                                        </Table.Row>
                                    )
                                    }
                                </Table.Body>
                            </Table>

                        </div>
                    </div>

                </div>
                { <div style={{ display: "flex", justifyContent: "center" }}>
                    <Grid>
                        <Grid.Column width={16} textAlign="center">
                            <Pagination
                                activePage={currentPage}
                                totalPages={totalPages}
                                onPageChange={ handlePageChange}
                            />
                        </Grid.Column>
                    </Grid>
                </div>
                }
                {
                    state.showGroupForm &&
                    <GroupModal
                        onHide={() =>
                            setStates({
                                ...state,
                                showGroupForm: false,
                                groupError: null,
                                optionError: null,
                                showDelConfirm: false
                            })}
                        loading={state.loading}
                        groupList={state.options}
                        procedureCodeGroups={state.groupListOptions}
                        onDeleteGroup={(action: any, id: any) => onDeleteGroup(action, id)}
                        onUpdate={() => {
                        }}
                    />}
                {showDelConfirm &&
                    <DeleteModal
                        showDelConfirm={showDelConfirm}
                        onShowHide={() => onShowHide(null)}
                        onDelete={() => onDeleteRow()}
                    />
                }

            </Template>
        </>
    );
};

function mapStateToProps(state: RootState) {
    return {
        app: state.app,
        procedure: state.procedure

    };
}

export default connect(mapStateToProps)(ProcedureCodeChanger);
