import React, {useEffect, useRef, useState} from 'react';
import Template from '../../components/Template';
import {useActions} from "../../redux/actions";
import * as reportActions from "../../redux/actions/report";
import * as practiceActions from "../../redux/actions/practice";
import {Practice, UdaPracticeInfo} from "../../model/practice";
import { CSVDObj, OrthoPCRExportDataObj, Report} from "../../model/report";
import {App, RootState} from "../../model";
import {connect} from 'react-redux';
import moment from "moment";
import { Button, Dimmer, Dropdown, DropdownProps, Grid, Icon, Input, Label, Loader, Modal, Search, Segment, Table } from 'semantic-ui-react';
import HelpMark from '../../components/HelpMark';
import _ from 'lodash';
import {brands, CONSULT_ProcCodes, CONTRACTS_ProcCodes, DEBOND_ProcCodes, FUTURECONSULT_ProcCodes, PTS_ProcCodes, RecallPT_ProcCodes, reportInitialState, reportPageNames, SDSTART_ProcCodes, SD_ProcCodes, START_ProcCodes, typeOfOptions, YTD,MTD } from '../../constants';
import { User } from '../../model/user';
import { addPropToArray, getMonthsBetweenDates, removeNullProperties } from '../../utils/common';
import OrthoPCRModal from '../../components/OrthoPCRModal';
import { OrthoPCRClickItem } from '../../model/common';
import ReactDOMServer from 'react-dom/server';
import FilterSectionOrtho from '../../components/FilterSectionOrtho';
import * as appActions from "../../redux/actions/app"
import OrthoPCRNoUserPermissionsModal from '../../components/OrthoPCRNoUserPermissionsModal';
import PriceFormat from '../../components/PriceFormat';
import DatePicker from "react-datepicker";
interface Props {
    report: Report,
    practice: Practice,
    app: App,
    auth: User
}

function OrthoPCRReport(this: any, props: Props) {
    const csvExportDataMTDRef = useRef<OrthoPCRExportDataObj>();
    const csvExportDataYTDRef=useRef<OrthoPCRExportDataObj>();
    const practicesCSVExport=useRef<string[]>();
    const initialState = {
        reportMTD: {},
        reportYTD: {},
        orthoUDRUserData: props.report.orthoUDRUserData,
        orthoUDROrthoDataYTD:props.report.orthoUDROrthoDataYTD,
	      orthoUDROrthoDataMTD:props.report.orthoUDROrthoDataMTD,
        orthoConsolidatedData:{},
        keyword: '', appttypGUID: null, value: '', name: '',
        index: 0, orderBy: null, order: true, column: null, showAll: false, btnText: 'Show Asterisk',open:false
    };    

    const initialOrthoItem = {
      practice: '',
      dataPoint: '',
      period: ''
    };

    const initialCodesState = {
      orthoChangeCodes: []
    };

    const {loaderMTD,loaderYTD,loaderStatusCodes} = props.report;
    const [codesState, setCodesState] = useState(initialCodesState);
    const customMoment = moment;
    const [selectedMonth,setSelectedMonth] = useState(customMoment().format('YYYY-MM-DD'))    
    const [year, setYear] = useState(moment().format('YYYY'));
    const [endDate,setEndDate] = useState(customMoment().format('YYYY-MM-DD'))
    const reportAction = useActions(reportActions);
    const practiceAction = useActions(practiceActions);
    const appAction=useActions(appActions)
    const [state, setState] = useState(initialState);
    const [filterState,setFilterState]=useState(reportInitialState)
    const page = reportPageNames.insurance
	  const [detailsModal, setDetailsModal] =  useState(false);
    const [permissionsModal, setPermissionsModal] =  useState(false);
    const [orthoItem, setOrthoItem] = useState(initialOrthoItem);
    const [setPractices,setSelectedPractices]=useState<string[]>([])
    const [filterDate,setFilterDate]=useState(
      ()=>{
        const saved=localStorage.getItem("filterDate")
        const initialValue=JSON.parse(saved!)?new Date(JSON.parse(saved!).date):new Date(moment().startOf('month').toString())
        return initialValue 
      })
    const [startDate,setStartDate] = useState(customMoment(filterDate).format('YYYY-MM-DD'))

    const orthoWorkDayReportMTD: any = props.report.orthoWorkDayMTD;
    const orthoWorkDayReportYTD: any = props.report.orthoWorkDayYTD;
    let orthoConsolidatedDataObj:any;
    let returnArrayTable1:React.ReactElement[] = [];
    let returnArrayTable2SD:React.ReactElement[] = [];
    let returnArrayTable2START:React.ReactElement[] = [];
    let returnArrayTable2DEBOND:React.ReactElement[] = [];
    let returnArrayTable2SDSTART:React.ReactElement[] = [];
    let returnArrayTable2TOTALSTART:React.ReactElement[] = [];
    let returnArrayTable2CONTRACTS:React.ReactElement[]=[]
    let returnArrayTable2TOTALCONTRACTS:React.ReactElement[] = [];
    let returnArrayTable2YTOTALCONTRACTS:React.ReactElement[] = [];
    let returnArrayTable2PTS:React.ReactElement[] = [];
    let returnArrayTable3:React.ReactElement[] = [];
    let returnArrayTable4:React.ReactElement[] = [];
    let returnArrayTable4YSD:React.ReactElement[] = [];
    let returnArrayTable4YSTART:React.ReactElement[] = [];
    let returnArrayTable4YDEBOND:React.ReactElement[] = [];
    let returnArrayTable4YSDSTART:React.ReactElement[] = [];
    let returnArrayTable4YPTS:React.ReactElement[] = [];
    let returnArrayTable4TOTALSTART:React.ReactElement[] = [];
    let returnArrayTable4TOTALCONTRACTS:React.ReactElement[] = [];
    let returnArrayTable2RECALLPT:React.ReactElement[]=[]
    let returnArrayTable2YRECALLPT:React.ReactElement[]=[]
    let returnArrayTable5MTDCONSULT:React.ReactElement[]=[]
    let returnArrayTable5MTDFUTURECONSULT:React.ReactElement[]=[]
    let returnArrayTable5YTDCONSULT:React.ReactElement[]=[]
    let returnArrayTable5YTDFUTURECONSULT:React.ReactElement[]=[]
    let returnArrayTable6MTDTOTALCONTRACTSTARTS:React.ReactElement[]=[]
    let returnArrayTable6YTDTOTALCONTRACTSTARTS:React.ReactElement[]=[]
    let drList:any[] =[];
    let csvExportDataObjMTD:OrthoPCRExportDataObj={
      WorkDays:[],
      Consults:[],
      FutureConsults:[],
      Contracts:[],
      SameDayContracts:[],
      TotalContracts:[],
      OrthoStart:[],
      TotalStarts:[],
      ActivePTS:[],
      OrthoDebonds:[],
      SameDayStart:[],
      RecallPatients:[],
      TotalContractSales:[],
    }
    let csvExportDataObjYTD:OrthoPCRExportDataObj={
      WorkDays:[],
      Consults:[],
      FutureConsults:[],
      Contracts:[],
      SameDayContracts:[],
      TotalContracts:[],
      OrthoStart:[],
      TotalStarts:[],
      ActivePTS:[],
      OrthoDebonds:[],
      SameDayStart:[],
      RecallPatients:[],
      TotalContractSales:[],
    }

    const refineRegionsArray = (regionList: []) => {
      let regionListOptions: Array<{text: string, value: null|number, key: number}> =
        [{key: 0, value: null, text: 'Select Region'}];
      if (regionList.length) {
        regionList.forEach((item: {name: string, id: number}) => {
          regionListOptions.push({text: item.name, value: item.id, key: item.id})
        });
      }
      return regionListOptions;
    };


    useEffect(()=>{
      localStorage.setItem("filterDate",JSON.stringify({date:filterDate}))
    },[filterDate])


    useEffect(() => {
        practiceAction.fetchPractices()
        practiceAction.fetchPracticeUda()
        practiceAction.fetchOrthoPractices()
        practiceAction.fetchRegions(refineRegionsArray)
        reportAction.getMountDate()
        reportAction.fetchOrthoPCRData(moment(
          filterDate
        ).format('YYYY-MM-DD')).then((reportMTD: any) => {
            setState({...state, reportMTD});
            reportAction.fetchOrthoPCRDataYTD(moment(
              filterDate
            ).format('YYYY-MM-DD')).then((reportYTD: any) => {
              setState({...state, reportYTD});
            });
            reportAction.fetchOrthoUDRUserData(getUdrOrthoDataYTD,'',moment().format('YYYY-MM-DD')).then((response: any) => {
              checkPermissions(response)
            });            
        });        
    }, []);

    const getUdrOrthoDataYTD = (orthoUDRUserData: any) => {
      drList = removeNullProperties(orthoUDRUserData?orthoUDRUserData.drList[3]?orthoUDRUserData.drList[3]:[]:[]);
      reportAction.fetchOrthoUdrOrthoYTDData(drList,moment(filterDate).format('YYYY-MM-DD')).then((orthoUDROrthoDataYTD: any) => {
        setState({...state, orthoUDROrthoDataYTD});
      });
      reportAction.fetchOrthoUdrOrthoMTDData(orthoUDRUserData,moment(filterDate).format('YYYY-MM-DD')).then((orthoUDROrthoDataMTD: any) => {
        reloadFailedYTD();
        setState({...state, orthoUDROrthoDataMTD});
      });
    };

    const getUdrOrthoDataFilteredMonths = (orthoUDRUserData: any,month:any) => {
      reportAction.fetchOrthoPCRData(moment(month).format('YYYY-MM-DD')).then((reportMTD: any) => {
        setState({...state, reportMTD});
        reportAction.fetchOrthoPCRDataYTD(moment(month).format('YYYY-MM-DD')).then((reportYTD: any) => {
          setState({...state, reportYTD});
        });
      });  
      drList = removeNullProperties(orthoUDRUserData?orthoUDRUserData.drList[3]?orthoUDRUserData.drList[3]:[]:[]);
      reportAction.fetchOrthoUdrOrthoYTDData(drList,moment(month).format('YYYY-MM-DD')).then((orthoUDROrthoDataYTD: any) => {
        setState({...state, orthoUDROrthoDataYTD});
      });
      reportAction.fetchOrthoUdrOrthoMTDData(drList,moment(month).format('YYYY-MM-DD')).then((orthoUDROrthoDataMTD: any) => {
        setState({...state, orthoUDROrthoDataMTD});
      });      
    };

    function checkPermissions(response: any) {
      if(response&&response.statusCode&&response.statusCode===400){
        setPermissionsModal(true)
      }else{
        refreshStatusCodes(); 
      } 
  }

    function mergeArrays(orthoWorkDayReportMTD:any,orthoWorkDayReportYTD:any,orthoUDROrthoDataMTD:any,orthoUDROrthoDataYTD:any,environment:string) {
      let orthoConsolidatedData: any = {};
      let orthPracticeNames: any = props.practice.practiceList; 
      let orthoPractices:any=props.practice.orthoPractices
      const practiceNamesInUdr=Object.keys(orthPracticeNames).map(
        (item:any)=>{
          return {
            "practiceName":orthPracticeNames[item].practice
          }
        }
      )

      const pracsNotInUdrList:any=[]
      orthoPractices.forEach((item:any)=>{
        let found=practiceNamesInUdr.find(a=>a.practiceName==item.practice)
        if(!found){
          pracsNotInUdrList.push(item)
        }
      })
      const orthoFormarted=pracsNotInUdrList.map((item:any)=>{
        return {[item.orthoLocation.toLowerCase()]:{
          "id": "N/A",
          "orthoLocation": item.orthoLocation,
          "practiceabbr": item.practiceabbr,
          "practice": item.practice,
          "practiceDBName": item.practiceDBName,
          "practiceDBNameSame": 0,
          "friday": "",
          "thursday": "",
          "wednesday": "",
          "tuesday": "",
          "monday": "0",
          "regMgrEmail": "",
          "brand": "",
          "mgrEmail": "",
          "callCenter": 0,
          "mgrFirstName": "",
          "mgrLastName": "",
          "regMgrFirstName": "",
          "regMgrLastName": "",
          "xrayService": 1}
      }})
      orthoFormarted.forEach((item:any)=>{
        orthPracticeNames[Object.keys(item)[0]]={...item[Object.keys(item)[0]]}
    })
      if (orthoWorkDayReportMTD) {
        for (let key in orthoWorkDayReportMTD) {
          let value = orthoWorkDayReportMTD[key];
          if(orthoConsolidatedData['mtd']){
            if(orthoConsolidatedData['mtd'][key]){              
              orthoConsolidatedData['mtd'][key]['Work Days']=value;
            }else{
              orthoConsolidatedData['mtd'][key]={};
              orthoConsolidatedData['mtd'][key]['Work Days']=value;
            }
          }else{
            orthoConsolidatedData['mtd']={};
            orthoConsolidatedData['mtd'][key]={};
            orthoConsolidatedData['mtd'][key]['Work Days']=value;
          }
        }        
      }
      if (orthoWorkDayReportYTD) {
        for (let key in orthoWorkDayReportYTD) {
          let value = orthoWorkDayReportYTD[key];
          if(orthoConsolidatedData['ytd']){
            if(orthoConsolidatedData['ytd'][key]){              
              orthoConsolidatedData['ytd'][key]['Work Days']=value;
            }else{
              orthoConsolidatedData['ytd'][key]={};
              orthoConsolidatedData['ytd'][key]['Work Days']=value;
            }
          }else{
            orthoConsolidatedData['ytd']={};
            orthoConsolidatedData['ytd'][key]={};
            orthoConsolidatedData['ytd'][key]['Work Days']=value;
          }
          
        }
      }
      if (orthoUDROrthoDataMTD && orthoUDROrthoDataMTD[environment]) {
        if (orthPracticeNames) {
          for (let key in orthoUDROrthoDataMTD[environment]) {
            let workday =0;
            let orthoLocation = orthPracticeNames[key.toLocaleLowerCase()];         
            if(orthoLocation){
              orthoLocation= orthoLocation.practice;
              let practiceData= orthoUDROrthoDataMTD[environment][key];
              if(orthoConsolidatedData['mtd']){
                if(orthoConsolidatedData['mtd'][orthoLocation]){
                  if(orthoConsolidatedData['mtd'][orthoLocation]['Work Days']){
                  workday = orthoConsolidatedData['mtd'][orthoLocation]['Work Days'];
                  }                  
                }
                orthoConsolidatedData['mtd'][orthoLocation]=practiceData
                orthoConsolidatedData['mtd'][orthoLocation]['Work Days']=workday
                orthoConsolidatedData['mtd'][orthoLocation]['Total Starts'] = Number(orthoConsolidatedData['mtd'][orthoLocation]['start'])+Number(orthoConsolidatedData['mtd'][orthoLocation]['sdstart']);
                orthoConsolidatedData['mtd'][orthoLocation]['Total Contracts'] = Number(orthoConsolidatedData['mtd'][orthoLocation]['sdcontract']);
              }else{
                orthoConsolidatedData['mtd']={}
                if(orthoConsolidatedData['mtd'][orthoLocation]){
                  if(orthoConsolidatedData['mtd'][orthoLocation]['Work Days']){
                  workday = orthoConsolidatedData['mtd'][orthoLocation]['Work Days'];
                  }
                }
                orthoConsolidatedData['mtd'][orthoLocation]=practiceData
                orthoConsolidatedData['mtd'][orthoLocation]['Work Days']=workday
              }
            }
              
          }
        }
      }

      if (orthoUDROrthoDataYTD && orthoUDROrthoDataYTD[environment]) {      
        if (orthPracticeNames) {
          for (let key in orthoUDROrthoDataYTD[environment]) {
            let workday =0;
            let orthoLocation = orthPracticeNames[key.toLocaleLowerCase()];         
            if(orthoLocation){
              orthoLocation= orthoLocation.practice;
              let practiceData= orthoUDROrthoDataYTD[environment][key];
              if(orthoConsolidatedData['ytd']){
                if(orthoConsolidatedData['ytd'][orthoLocation]){
                  if(orthoConsolidatedData['ytd'][orthoLocation]['Work Days']){
                  workday = orthoConsolidatedData['ytd'][orthoLocation]['Work Days'];
                  }
                }
                orthoConsolidatedData['ytd'][orthoLocation]=practiceData
                orthoConsolidatedData['ytd'][orthoLocation]['Work Days']=workday
                orthoConsolidatedData['ytd'][orthoLocation]['Total Starts'] = Number(orthoConsolidatedData['ytd'][orthoLocation]['start'])+Number(orthoConsolidatedData['ytd'][orthoLocation]['sdstart']);
                orthoConsolidatedData['ytd'][orthoLocation]['Total Contracts'] = Number(orthoConsolidatedData['ytd'][orthoLocation]['sdcontract']);
              }else{
                orthoConsolidatedData['ytd']={}
                if(orthoConsolidatedData['ytd'][orthoLocation]){
                  if(orthoConsolidatedData['ytd'][orthoLocation]['Work Days']){
                  workday = orthoConsolidatedData['ytd'][orthoLocation]['Work Days'];
                  }
                }
                orthoConsolidatedData['ytd'][orthoLocation]=practiceData
                orthoConsolidatedData['ytd'][orthoLocation]['Work Days']=workday
              }
            }
              
          }
        }
      }
      const {mtd,ytd}=orthoConsolidatedData
      const keysMtd=mtd&&Object.keys(mtd)
      const keysYtd=ytd&&Object.keys(ytd)
      const filteredKeysMtd=getPracsAfterFilter(keysMtd)
      const filteredKeydYtd=getPracsAfterFilter(keysYtd)
      const newMtd:any={}
      const newYtd:any={}
      filteredKeysMtd&&filteredKeysMtd.forEach((key: string)=>{
        newMtd[key]=mtd&&mtd[key]
      })
      filteredKeydYtd&&filteredKeydYtd.forEach((key:string)=>{
        newYtd[key]=ytd&&ytd[key]
      })
      const filteredConsolidatedData:any={}
      if(newMtd)
        filteredConsolidatedData['mtd']=newMtd
      if(newYtd)
        filteredConsolidatedData['ytd']=newYtd
      orthoConsolidatedDataObj=filteredConsolidatedData;
      return filteredConsolidatedData;
    }
      const sortObjectByKeysAlphabetically=(o:any)=> {
        const keys=Object.keys(o)
        keys.sort()
        const newObj:any={}
        keys.forEach(key=>{
          newObj[key]=o[key]
        })
        return newObj
    }
    const getSelectedPracs = (filters:any) => {
      let selectedPractices:string[] = [];
      if(filters && Object.keys(filters).length) {
        Object.keys(filters).forEach( (filter:string) => {
          // Apply Region Filter
          if(filter === 'region' && !selectedPractices.length) {
            if(filters[filter] && props.practice.regionPractices) {
              const regionFilter=filters[filter]
              const tempSelectedPracs:string[]=[]
              regionFilter.forEach((item: any)=>{
                  if(props.practice.regionPractices[item]){
                    tempSelectedPracs.push(
                      ...props.practice.regionPractices[item] as []
                    )
                  }
              })
              selectedPractices=Array.from(new Set([...tempSelectedPracs,...selectedPractices])).sort()
            }
          }
          // Apply brands Filter
          if( filter === 'brands' && filters[filter]){
          const tempBrandFilterPractices:any=[]
           filters[filter].forEach((brand:any)=>{
             const res= props.practice.udaPracticeInfo.filter(
               (item:UdaPracticeInfo)=>{
                 if(item.brand===brand){
                   return true
                 }
               }
              )
            tempBrandFilterPractices.push(
              ...res.map(prac=>{ return prac.practiceName})
            )
          })
          selectedPractices=Array.from(new Set([...selectedPractices,...tempBrandFilterPractices])).sort()
          }

          // Apply Practice Filter
          if(filter === 'practice' && filters[filter]) {
            const regionFilter=filters.region
            const tempSelectedPracs:string[]=[]
            regionFilter.forEach((item:any)=>{
                if(props.practice.regionPractices[item]){
                  tempSelectedPracs.push(
                    ...props.practice.regionPractices[item] as []
                  )
                }
            })
            selectedPractices = [...filters[filter]];
          }
        });
      }
      const regionOptions=props.practice.regionOptions
      const tempRegions:any=[]
      regionOptions.slice(1).forEach((option:any)=>{
        const {value}=option
        const practices:[]=props.practice.regionPractices[value]
        if(practices){
            selectedPractices.forEach(prac=>{
              const res=practices.find((pr:string)=>pr.toLowerCase()==prac.toLowerCase())
              if(res)
                  tempRegions.push(option)
            })
        }
      })

      const tempBrands:any=[]
      brands.forEach((brOption:any)=>{
        const res= props.practice.udaPracticeInfo.filter(
          (item:UdaPracticeInfo)=>{
            if(item.brand===brOption.value){
              return true
            }
          }
         ).map(item=>item.practiceName)
         selectedPractices.forEach(pr=>{
           if(res.find(p=>p.toLowerCase()==pr.toLowerCase())){
            if(!tempBrands.find((item:any)=>item.value==brOption.value))
              tempBrands.push({...brOption,key:Math.random()+brOption.key})
           }
         })
      })
      return {selectedPractices,tempRegions,tempBrands}
    }

    const onChangeFilter = async (filters:any) => {
       applyFilter(filters)
    };

    const applyFilter = (filters:any) => {
      const {selectedPractices,tempRegions,tempBrands}=getSelectedPracs(filters)
      const selectedPracticesFormatted=selectedPractices.map((str:any)=>str.charAt(0).toUpperCase() + str.slice(1))
      appAction.UpdatePracticesOptions(Array.from(new Set(selectedPracticesFormatted)))
      const payload=tempRegions.length>0?tempRegions:props.practice.regionOptions
      appAction.UpdateRegionOptions([...Array.from(new Set(payload))])
      const brandPayload=tempBrands.length>0?tempBrands:brands
      appAction.UpdateBrandsOption([...Array.from(new Set(brandPayload))])
      setSelectedPractices(selectedPracticesFormatted)
    };

    function convertTHeadMTD(data: any,period:string,description:string) {
        const {mtd}=data
        const keys=mtd?Object.keys(mtd):[]
        let total=0
        keys.forEach(key=>{
          total+=mtd[key]['Work Days']
        })
        const xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd),GrandTotal:{'Work Days':total}}:{}
        returnArrayTable1.push(
            <th key={'th'+period} colSpan={1} className="header-os"></th>)
            for (let key in xmtd) {
                returnArrayTable1.push(
                <th key={'th'+key+xmtd[key]+period} colSpan={1} className="header-os">{key}</th>)
            }
        return <tr>{returnArrayTable1}</tr>;
    }
    const getPracsAfterFilter=(pracKeys:string[])=>{
      if(setPractices.length >0){
        const commonFilteredKeys = setPractices.filter(value => pracKeys.includes(value));
      return commonFilteredKeys
      }
      return pracKeys
    }


    const convertTBodyMTD = (data: any,period:string,description:string) => {
      let returnArrayTable2:React.ReactElement[] = [];
      const {mtd}=data
      const keys=mtd?Object.keys(mtd):[]
      const allWorkDays:any=[]
      let total=0
      keys.forEach(key=>{
        total+=mtd[key]['Work Days']
      })
      const xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd),GrandTotal:{'Work Days':total}}:{}
      if(xmtd){
        returnArrayTable2.push(
          <td key={description+period}>{description}</td>)
      for (let key in xmtd) {
        const scheduleData=[...props.report.scheduleDataRawMTD]
        const scheduleDataCurrentPractice=addPropToArray(scheduleData.filter((item:any)=>item.location===key),key);
        if(xmtd[key]['Work Days']){
          let item:OrthoPCRClickItem = {
            practice: key,
            dataPoint: 'Work Days',
            period: 'mtd',
            appointmentData:scheduleDataCurrentPractice,
          };
          allWorkDays.push(...scheduleDataCurrentPractice)
          if(key!=="GrandTotal"){
            const tmp:CSVDObj={data:scheduleDataCurrentPractice.length,practice:key}
            csvExportDataObjMTD={...csvExportDataObjMTD,WorkDays:[...csvExportDataObjMTD.WorkDays,tmp]}
            returnArrayTable2.push(
              <td key={key+xmtd[key]+period}
              className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}
              >{xmtd[key]['Work Days']}</td>)
          }else{
            const tmp:CSVDObj={data:allWorkDays.length,practice:key}
            csvExportDataObjMTD={...csvExportDataObjMTD,WorkDays:[...csvExportDataObjMTD.WorkDays,tmp]}
            let grandTotalItem:OrthoPCRClickItem={
              practice:"All",
              dataPoint:'Work Days',
              period:'mtd',
              appointmentData:allWorkDays
            }
            returnArrayTable2.push(
              <td key={Math.random()+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}>{allWorkDays.length}</td>)
          }
          
        }else{
          const tmp:CSVDObj={data:'0',practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,WorkDays:[...csvExportDataObjMTD.WorkDays,tmp]}
          returnArrayTable2.push(
            <td key={key+xmtd[key]+period}>0</td>)
        }          
      }
      return <tr>{returnArrayTable2}</tr>;
      }
    }

    function convertTBodyMTDSD(data: any,period:string,description:string) {
      const {mtd}=data
      const xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd)}:{}
      const allSameDayContracts:any=[]
      if(xmtd){
        returnArrayTable2SD.push(
          <td key={description+period}>{description}</td>)
      for (let key in xmtd) {
        if(xmtd[key]['sdcontract']){
          const dataForPractice=xmtd[key].data?xmtd[key].data:[]
          const sameDayContracts=addPropToArray(dataForPractice.filter((d:any)=>d.ProcCode!==null?
          (SD_ProcCodes.indexOf(sanitiseString(d.ProcCode)) >= 0)&&filterByDate(d)
          :false),key)
		    let item:OrthoPCRClickItem = {
            practice: key,
            dataPoint: 'Sdcontract',
            appointmentData:sameDayContracts,
            period: 'mtd'
          };
        if(sameDayContracts)
              allSameDayContracts.push(...sameDayContracts)
          const tmp:CSVDObj={data:sameDayContracts.length,practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,SameDayContracts:[...csvExportDataObjMTD.SameDayContracts,tmp]}
          returnArrayTable2SD.push(
            <td key={key+xmtd[key]+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}>{sameDayContracts.length}</td>)
        }else{
          const tmp:CSVDObj={data:'0',practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,SameDayContracts:[...csvExportDataObjMTD.SameDayContracts,tmp]}
          returnArrayTable2SD.push(
            <td key={key+xmtd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'Sdcontract',
        period:'mtd',
        appointmentData:allSameDayContracts
      }
      returnArrayTable2SD.push(
        <td key={Math.random()+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}>{allSameDayContracts.length}</td>)
      return <tr>{returnArrayTable2SD}</tr>;
      }
      
    }

    function convertTBodyMTDRecallPT(data: any,period:string,description:string){
      const {mtd}=data
      const xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd)}:{}
      returnArrayTable2RECALLPT.push(
        <td key={description+period}>{description}</td>
      )
      let tempRecallTotal=0
      let tempRecallApt:any=[]
      for(let key in xmtd){
        const dataY=xmtd[key].data?xmtd[key].data:[]
        const res=addPropToArray(dataY!.filter((d:any)=>d.ProcCode!==null?
        (RecallPT_ProcCodes.indexOf(sanitiseString(d.ProcCode)) >= 0)&&filterByDate(d)
        :false),key)
        tempRecallApt.push(...res)
        const RecarePatientsCount=res?res.length:0;
        tempRecallTotal+=RecarePatientsCount
        let item:OrthoPCRClickItem={
          practice:key,
          dataPoint:'recall Patient',
          period:'mtd',
          appointmentData:res
        }
        const tmp:CSVDObj={data:RecarePatientsCount,practice:key}
        csvExportDataObjMTD={...csvExportDataObjMTD,RecallPatients:[...csvExportDataObjMTD.RecallPatients,tmp]}
        returnArrayTable2RECALLPT.push(
          <td key={key+xmtd[key]+period}
          className="pcr_tbl_numbers_clickable" onClick={()=>modalOpen(item)}
           >
            {RecarePatientsCount}
          </td>
        )
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'recall Patient',
        period:'mtd',
        appointmentData:tempRecallApt
      }
      returnArrayTable2RECALLPT.push(
        <td key={Math.random()+period} 
        className="pcr_tbl_numbers_clickable" onClick={()=>modalOpen(grandTotalItem)}
        >
          {tempRecallTotal}
        </td>
      )
      return <tr>{returnArrayTable2RECALLPT}</tr>
    }
    function convertTBodyMTDTOTALCONTRACTSALES(data:any,period:string,description:string){
      const {mtd}=data
      const xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd)}:{}
      let allContractsRecordsTotal=0
      const allContractSalesData=[]
      if(xmtd){
        returnArrayTable6MTDTOTALCONTRACTSTARTS.push(
          <td key={Math.random()+description+period}
          style={{backgroundColor: "#BF8F00"}}
          >{description}</td>
        )
        for(let key in xmtd){
          if(xmtd[key]){
            const dataForPractice=xmtd[key].data?xmtd[key].data:[]
            const contractsData=addPropToArray(dataForPractice.filter((d:any)=>d.ProcCode!==null?
            ((CONTRACTS_ProcCodes.indexOf(sanitiseString(d.ProcCode)) >= 0)||((SD_ProcCodes.indexOf(sanitiseString(d.ProcCode)) >= 0)))&&filterByDate(d)
            :false),key)
            let totalForPractice=0
            contractsData.forEach((data:any)=>{
              totalForPractice+=data.ContractTotal?data.ContractTotal:0
            })
            allContractSalesData.push(...contractsData)
            let item:OrthoPCRClickItem={
              practice:key,
              dataPoint:'Total Contract Sales',
              period:'mtd',
              appointmentData:contractsData
            }
            allContractsRecordsTotal+=totalForPractice
            const tmp:CSVDObj={data:`${totalForPractice}`,practice:key}
            csvExportDataObjMTD={...csvExportDataObjMTD,TotalContractSales:[...csvExportDataObjMTD.TotalContractSales,tmp]}
            csvExportDataMTDRef.current=csvExportDataObjMTD
              returnArrayTable6MTDTOTALCONTRACTSTARTS.push(
              <td key={Math.random()+description+period}
              className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}
              >${PriceFormat(totalForPractice)}</td>
            )
          }
        }
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'Total Contract Sales',
        period:'mtd',
        appointmentData:allContractSalesData
      }
      returnArrayTable6MTDTOTALCONTRACTSTARTS.push(
        <td key={Math.random()+description+period}
        className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}
        >${PriceFormat(allContractsRecordsTotal)}</td>
      )
      return<tr>{returnArrayTable6MTDTOTALCONTRACTSTARTS}</tr>
    }

    function convertTBodyYTDTOTALCONTRACTSALES(data:any,period:string,description:string){
      const {ytd}=data
      const xytd=ytd?{...sortObjectByKeysAlphabetically(ytd)}:{}
      practicesCSVExport.current=Object.keys(xytd)
      let allContractsRecordsTotal=0
      const allContractSalesData=[]
      if(xytd){
        returnArrayTable6YTDTOTALCONTRACTSTARTS.push(
          <td key={description+period+Math.random()}
          style={{backgroundColor: "#BF8F00"}}
          >{description}</td>
        )
        for(let key in xytd){
          if(xytd[key]){
            const dataForPractice=xytd[key].data?xytd[key].data:[]
            let contractsData:any=[]
            Object.keys(dataForPractice).forEach((d: string) => {
              if (dataForPractice[d]&&dataForPractice[d].ProcCode!==null&&((CONTRACTS_ProcCodes.indexOf(sanitiseString(dataForPractice[d].ProcCode)) >= 0)||((SD_ProcCodes.indexOf(sanitiseString(dataForPractice[d].ProcCode)) >= 0)))){
                contractsData.push(dataForPractice[d]);
              }
            });
            contractsData=addPropToArray(contractsData,key);
            let totalForPractice=0
            contractsData.forEach((data:any)=>{
              totalForPractice+=data.ContractTotal?data.ContractTotal:0
            })
            let item:OrthoPCRClickItem={
              practice:key,
              dataPoint:'Total Contract Sales',
              period:'ytd',
              appointmentData:contractsData
            }
            allContractSalesData.push(...contractsData)
            allContractsRecordsTotal+=totalForPractice
            const tmp:CSVDObj={data:`${totalForPractice}`,practice:key}
            csvExportDataObjYTD={...csvExportDataObjYTD,TotalContractSales:[...csvExportDataObjYTD.TotalContractSales,tmp]}
            csvExportDataYTDRef.current=csvExportDataObjYTD
            returnArrayTable6YTDTOTALCONTRACTSTARTS.push(
              <td key={description+period+Math.random()}
              className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}
              >${PriceFormat(totalForPractice)}</td>
            )
          }
        }
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'Total Contract Sales',
        period:'ytd',
        appointmentData:allContractSalesData
      }
      returnArrayTable6YTDTOTALCONTRACTSTARTS.push(
        <td key={description+period+Math.random()}
        className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}
        >${PriceFormat(allContractsRecordsTotal)}</td>
      )
      return<tr>{returnArrayTable6YTDTOTALCONTRACTSTARTS}</tr>
    }


    function convertTBodyMTDSTART(data: any,period:string,description:string) {
      const {mtd}=data
      const xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd)}:{}
      const allStartData:any=[]
      if(xmtd){
        returnArrayTable2START.push(
          <td key={description+period}>{description}</td>)
      for (let key in xmtd) {
        if(xmtd[key]['start']){
          const dataForPractice=xmtd[key].data?xmtd[key].data:[]
          const orthoStartData=addPropToArray(dataForPractice.filter((d:any)=>d.ProcCode!==null?
          (START_ProcCodes.indexOf(sanitiseString(d.ProcCode)) >= 0)&&filterByDate(d)
          :false),key)  
          const orthoStartDataDistinct=Array.from(new Set(orthoStartData.map((a:any)=>a.PatientID))).map((id:any)=>{return orthoStartData.find((a:any)=>a.PatientID===id)})
          if(orthoStartDataDistinct)
            allStartData.push(...orthoStartDataDistinct)
          let item:OrthoPCRClickItem = {
              practice: key,
              dataPoint: 'Start',
              period: 'mtd',
              appointmentData:orthoStartDataDistinct
            };

            const tmp:CSVDObj={data:`${orthoStartDataDistinct.length}`,practice:key}
            csvExportDataObjMTD={...csvExportDataObjMTD,OrthoStart:[...csvExportDataObjMTD.OrthoStart,tmp]}
          returnArrayTable2START.push(
            <td key={key+xmtd[key]+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}>{orthoStartDataDistinct.length}</td>)
        }else{
          const tmp:CSVDObj={data:'0',practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,OrthoStart:[...csvExportDataObjMTD.OrthoStart,tmp]}
          returnArrayTable2START.push(
            <td key={key+xmtd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'Start',
        period:'mtd',
        appointmentData:allStartData
      } 
      returnArrayTable2START.push(
        <td key={Math.random()+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}>{allStartData.length}</td>)
      return <tr>{returnArrayTable2START}</tr>;
      }
      
    }

    function convertTBodyMTDCONSULT(data:any,period:string,description:string){
      const {mtd}=data
      let consultsGrandTotal=0
      let xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd)}:{}
      let aptData:any={}
      let tempRecallApt:any=[]
      if(xmtd){
        returnArrayTable5MTDCONSULT.push(
          <td key={description+period}>{description}</td>)
        for(let key in xmtd){
            const {data}=xmtd[key]
            let tempAptData=[]
            if(data && data.length >0){
              for(let index=0;index<data.length;index++){
                if((data[index]!==null)&&data[index]&&data[index].ProcCode)
                {
                  if((CONSULT_ProcCodes.indexOf(sanitiseString(data[index].ProcCode)) >= 0)&&filterByDate(data[index])){
                    tempAptData.push(data[index])
                  }
                }
              }
              tempAptData=addPropToArray(tempAptData,key);
              }
              tempAptData=Array.from(new Map(tempAptData.map((item:any)=>[item["PatientID"],item])).values())
              xmtd[key]['consult']=tempAptData.length
              aptData[key]=tempAptData
              tempRecallApt.push(...tempAptData)
              consultsGrandTotal+=tempAptData.length
            }
            for(let key in xmtd){
            if(xmtd[key]['consult']){
              
              let item:OrthoPCRClickItem={
                practice:key,
                dataPoint:'consult',
                period:'mtd',
                appointmentData:aptData&&aptData[key]
              }
              const tmp:CSVDObj={data:xmtd[key]['consult'],practice:key}
              csvExportDataObjMTD={...csvExportDataObjMTD,Consults:[...csvExportDataObjMTD.Consults,tmp]}
              returnArrayTable5MTDCONSULT.push(
                <td key={key+xmtd[key]+period}
                className="pcr_tbl_numbers_clickable" 
                onClick={()=>modalOpen(item)}
                >{xmtd[key]['consult']}</td>)
            }else{
              const tmp:CSVDObj={data:'0',practice:key}
              csvExportDataObjMTD={...csvExportDataObjMTD,Consults:[...csvExportDataObjMTD.Consults,tmp]}
              returnArrayTable5MTDCONSULT.push(
                <td key={key+xmtd[key]+period}>0</td>
              )
            }
            }
            let grandTotalItem:OrthoPCRClickItem={
              practice:"All",
              dataPoint:'consult',
              period:'mtd',
              appointmentData:tempRecallApt
            }
            returnArrayTable5MTDCONSULT.push(
              <td key={Math.random()+period} 
              className="pcr_tbl_numbers_clickable" onClick={()=>modalOpen(grandTotalItem)}
              >
                {consultsGrandTotal}
              </td>
            )
            return <tr>{returnArrayTable5MTDCONSULT}</tr>
        }    
      }

      function convertTBodyMTDFUTURECONSULT(data:any,period:string,description:string){
        const {mtd}=data
        let consultsGrandTotal=0
        let xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd)}:{}
        let aptData:any={}
        let tempRecallApt:any=[]
        if(xmtd){
          returnArrayTable5MTDFUTURECONSULT.push(
            <td key={description+period}>{description}</td>)
          for(let key in xmtd){
              const {data}=xmtd[key]
              let tempAptData=[]
              if(data && data.length >0){
                for(let index=0;index<data.length;index++){
                  if((data[index]!==null)&&data[index]&&data[index].ProcCode)
                  {
                    if((FUTURECONSULT_ProcCodes.indexOf(sanitiseString(data[index].ProcCode)) >= 0)&&filterByDate(data[index])&& data[index].Next_Apt){
                      tempAptData.push(data[index])
                    }
                  }
                }
                tempAptData=addPropToArray(tempAptData,key);
                }
                tempAptData=Array.from(new Map(tempAptData.map((item:any)=>[item["PatientID"],item])).values())
                xmtd[key]['consult']=tempAptData.length
                aptData[key]=tempAptData
                tempRecallApt.push(...tempAptData)
                consultsGrandTotal+=tempAptData.length
              }
              for(let key in xmtd){
              if(xmtd[key]['consult']){
                let item:OrthoPCRClickItem={
                  practice:key,
                  dataPoint:'consult',
                  period:'mtd',
                  appointmentData:aptData&&aptData[key]
                }
              const tmp:CSVDObj={data:xmtd[key]['consult'],practice:key}
              csvExportDataObjMTD={...csvExportDataObjMTD,FutureConsults:[...csvExportDataObjMTD.FutureConsults,tmp]}
                returnArrayTable5MTDFUTURECONSULT.push(
                  <td key={key+xmtd[key]+period}
                  className="pcr_tbl_numbers_clickable" 
                  onClick={()=>modalOpen(item)}
                  >{xmtd[key]['consult']}</td>)
              }else{
                const tmp:CSVDObj={data:'0',practice:key}
                csvExportDataObjMTD={...csvExportDataObjMTD,FutureConsults:[...csvExportDataObjMTD.FutureConsults,tmp]}
                returnArrayTable5MTDFUTURECONSULT.push(
                  <td key={key+xmtd[key]+period}>0</td>
                )
                }
              }
              let grandTotalItem:OrthoPCRClickItem={
                practice:"All",
                dataPoint:'consult',
                period:'mtd',
                appointmentData:tempRecallApt
              }
              returnArrayTable5MTDFUTURECONSULT.push(
                <td key={Math.random()+period} 
                className="pcr_tbl_numbers_clickable" onClick={()=>modalOpen(grandTotalItem)}
                >
                  {consultsGrandTotal}
                </td>
              )
              return <tr>{returnArrayTable5MTDFUTURECONSULT}</tr>
          }    
        }

        function convertTBodyYTDCONSULT(data:any,period:string,description:string){
          const {ytd}=data
          let consultsGrandTotal=0
          let xytd=ytd?{...sortObjectByKeysAlphabetically(ytd)}:{}
          let aptData:any={}
          let tempRecallApt:any=[]
          if(xytd){
            returnArrayTable5YTDCONSULT.push(
              <td key={description+period}>{description}</td>)
            for(let key in xytd){
                let currTotalConsultForPractice=0
                const {data}=xytd[key]
                let tempAptData=[]
                if(data && data.length >0){
                  for(let index=0;index<data.length;index++){
                    if((data[index]!==null)&&data[index]&&data[index].ProcCode)
                  {
                    if((CONSULT_ProcCodes.indexOf(sanitiseString(data[index].ProcCode)) >= 0)){
                      currTotalConsultForPractice++
                      tempAptData.push(data[index])
                    }
                  }
                  }
                  tempAptData=addPropToArray(tempAptData,key);
                  }
                  tempAptData=Array.from(new Map(tempAptData.map((item:any)=>[item["PatientID"],item])).values())
                  xytd[key]['consult']=tempAptData.length
                  aptData[key]=tempAptData
                  tempRecallApt.push(...tempAptData)
                  consultsGrandTotal+=tempAptData.length
                }
                for(let key in xytd){
                if(xytd[key]['consult']){
                  let item:OrthoPCRClickItem={
                    practice:key,
                    dataPoint:'consult',
                    period:'mtd',
                    appointmentData:aptData&&aptData[key]
                  }
                  const tmp:CSVDObj={data:xytd[key]['consult'],practice:key}
                  csvExportDataObjYTD={...csvExportDataObjYTD,Consults:[...csvExportDataObjYTD.Consults,tmp]}
                  returnArrayTable5YTDCONSULT.push(
                    <td key={key+xytd[key]+period}
                    className="pcr_tbl_numbers_clickable"
                    onClick={()=>modalOpen(item)}
                    >{xytd[key]['consult']}</td>)
                }else{
                  const tmp:CSVDObj={data:'0',practice:key}
                  csvExportDataObjYTD={...csvExportDataObjYTD,Consults:[...csvExportDataObjYTD.Consults,tmp]}
                  returnArrayTable5YTDCONSULT.push(
                    <td key={key+xytd[key]+period}>0</td>
                  )
                }
                }
                let grandTotalItem:OrthoPCRClickItem={
                  practice:"All",
                  dataPoint:'consult',
                  period:'mtd',
                  appointmentData:tempRecallApt
                }
                returnArrayTable5YTDCONSULT.push(
                  <td key={Math.random()+period} 
                  className="pcr_tbl_numbers_clickable" onClick={()=>modalOpen(grandTotalItem)}
                  >
                    {consultsGrandTotal}
                  </td>
                )
                return <tr>{returnArrayTable5YTDCONSULT}</tr>
            }    
          }
    
          function convertTBodyYTDFUTURECONSULT(data:any,period:string,description:string){
            const {ytd}=data
            let consultsGrandTotal=0
            let xytd=ytd?{...sortObjectByKeysAlphabetically(ytd)}:{}
            let aptData:any={}
            let tempRecallApt:any=[]
            if(xytd){
              returnArrayTable5YTDFUTURECONSULT.push(
                <td key={description+period}>{description}</td>)
              for(let key in xytd){
                  let currTotalConsultForPractice=0
                  const {data}=xytd[key]
                  let tempAptData=[]
                  if(data && data.length >0){
                    for(let index=0;index<data.length;index++){
                    if((data[index]!==null)&&data[index]&&data[index].ProcCode)
                    {
                      if((FUTURECONSULT_ProcCodes.indexOf(sanitiseString(data[index].ProcCode)) >= 0)&& data[index].Next_Apt){
                        currTotalConsultForPractice++
                        tempAptData.push(data[index])
                      }
                    }
                    }
                    tempAptData=addPropToArray(tempAptData,key);
                    }
                    tempAptData=Array.from(new Map(tempAptData.map((item:any)=>[item["PatientID"],item])).values())
                    xytd[key]['consult']=tempAptData.length
                    aptData[key]=tempAptData
                    tempRecallApt.push(...tempAptData)
                    consultsGrandTotal+=tempAptData.length
                  }
                  for(let key in xytd){
                  if(xytd[key]['consult']){
                    let item:OrthoPCRClickItem={
                      practice:key,
                      dataPoint:'consult',
                      period:'mtd',
                      appointmentData:aptData&&aptData[key]
                    }
                    const tmp:CSVDObj={data:xytd[key]['consult'],practice:key}
                    csvExportDataObjYTD={...csvExportDataObjYTD,FutureConsults:[...csvExportDataObjYTD.FutureConsults,tmp]}
                    returnArrayTable5YTDFUTURECONSULT.push(
                      <td key={key+xytd[key]+period}
                      className="pcr_tbl_numbers_clickable" 
                      onClick={()=>modalOpen(item)}
                      >{xytd[key]['consult']}</td>)
                  }else{
                    const tmp:CSVDObj={data:'0',practice:key}
                    csvExportDataObjYTD={...csvExportDataObjYTD,FutureConsults:[...csvExportDataObjYTD.FutureConsults,tmp]}
                    returnArrayTable5YTDFUTURECONSULT.push(
                      <td key={key+xytd[key]+period}>0</td>
                    )
                    }
                  }
                  let grandTotalItem:OrthoPCRClickItem={
                    practice:"All",
                    dataPoint:'consult',
                    period:'mtd',
                    appointmentData:tempRecallApt
                  }
                  returnArrayTable5YTDFUTURECONSULT.push(
                    <td key={Math.random()+period} 
                    className="pcr_tbl_numbers_clickable" onClick={()=>modalOpen(grandTotalItem)}
                    >
                      {consultsGrandTotal}
                    </td>
                  )
                  return <tr>{returnArrayTable5YTDFUTURECONSULT}</tr>
              }    
            }

    function filterByDate(item: any) {
      return moment(filterDate).format('YYYY-MM')===moment(item.ProcDate).format('YYYY-MM').toLocaleLowerCase();
    }

    function removeDuplicatesFromArray(arr: any) {
      return arr.filter((v: any,i: any,a: any[])=>a.findIndex(v2=>(v2.PatientID===v.PatientID))===i);
    }    

    function convertTBodyMTDDEBOND(data: any,period:string,description:string) {
      const {mtd}=data
      const keys=mtd?Object.keys(mtd):[]
      const xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd)}:{}
      let tempDebondApt:any=[]
      if(xmtd){
        returnArrayTable2DEBOND.push(
          <td key={description+period}>{description}</td>)
      for (let key in xmtd) {       
        if(xmtd[key]['debond']){
          if(xmtd[key]&&xmtd[key].data){
          let tempData:any=[]
          Object.keys(xmtd[key].data).forEach((d: string) => {
            if (xmtd[key].data[d]&&xmtd[key].data[d].ProcCode!==null){
              if((DEBOND_ProcCodes.indexOf(sanitiseString(xmtd[key].data[d].ProcCode)) >= 0)&&filterByDate(xmtd[key].data[d])){
                tempData.push(xmtd[key].data[d]);
            }              
            }
          });
          tempData=addPropToArray(tempData,key);
          const tempDataDistinct = removeDuplicatesFromArray(tempData);
          tempDebondApt.push(...tempDataDistinct)
          const item:OrthoPCRClickItem={
            practice:key,
            dataPoint:'debond',
            period:'mtd',
            appointmentData:tempDataDistinct
          }
          const tmp:CSVDObj={data:xmtd[key]['debond'],practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,OrthoDebonds:[...csvExportDataObjMTD.OrthoDebonds,tmp]}
          returnArrayTable2DEBOND.push(
            <td key={key+xmtd[key]+period}
            className="pcr_tbl_numbers_clickable" 
            onClick={()=>modalOpen(item)}
            >{tempDataDistinct.length}</td>)
        }
      }
        else{
          const tmp:CSVDObj={data:'0',practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,OrthoDebonds:[...csvExportDataObjMTD.OrthoDebonds,tmp]}
          returnArrayTable2DEBOND.push(
            <td key={key+xmtd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'debond',
        period:'mtd',
        appointmentData:tempDebondApt
      }
      returnArrayTable2DEBOND.push(
        <td key={Math.random()+period} 
        className="pcr_tbl_numbers_clickable" onClick={()=>modalOpen(grandTotalItem)}
        >
          {tempDebondApt.length}
        </td>
      )
      return <tr>{returnArrayTable2DEBOND}</tr>;
      }
      
    }

    function convertTBodyMTDSDSTART(data: any,period:string,description:string) {
      const {mtd}=data
      const xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd)}:{}
      const allSdStartData:any=[]
      if(xmtd){
        returnArrayTable2SDSTART.push(
          <td key={description+period}>{description}</td>)
      for (let key in xmtd) {
        const dataForPractice=xmtd[key].data?xmtd[key].data:[]
        const sameDayStartsData:[]=addPropToArray(dataForPractice.filter((d:any)=>((d.ProcCode!==null)&&(filterByDate(d)))?
        (SDSTART_ProcCodes.indexOf(sanitiseString(d.ProcCode)) >= 0)
        :false),key)
        const sameDayStartsDataDistinct=Array.from(new Set(sameDayStartsData.map((a:any)=>a.PatientID))).map((id:any)=>{return sameDayStartsData.find((a:any)=>a.PatientID===id)})
        if(xmtd[key]['sdstart']){
		    let item:OrthoPCRClickItem = {
            practice: key,
            dataPoint: 'Sdstart',
            appointmentData:sameDayStartsDataDistinct,
            period: 'mtd'
          };
          if(sameDayStartsDataDistinct)
            allSdStartData.push(...sameDayStartsDataDistinct)
          const tmp:CSVDObj={data:`${sameDayStartsDataDistinct.length}`,practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,SameDayStart:[...csvExportDataObjMTD.SameDayStart,tmp]}
          returnArrayTable2SDSTART.push(
            <td key={key+xmtd[key]+period} 
            className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}>{sameDayStartsDataDistinct.length}</td>)
        }else{
          const tmp:CSVDObj={data:`0`,practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,SameDayStart:[...csvExportDataObjMTD.SameDayStart,tmp]}
          returnArrayTable2SDSTART.push(
            <td key={key+xmtd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'Sdstart',
        period:'mtd',
        appointmentData:allSdStartData
      } 
      returnArrayTable2SDSTART.push(
        <td key={Math.random()+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}>{allSdStartData.length}</td>)
      return <tr>{returnArrayTable2SDSTART}</tr>;
      }
      
    }

    function convertTBodyMTDPTS(data: any,period:string,description:string) {
      const {mtd}=data
      const keys=mtd?Object.keys(mtd):[]
      let total=0 
      const allActivePatientsData: any=[]
      keys.forEach(key=>{
        total+=mtd[key]['patients']?mtd[key]['patients']:0
      })
      const xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd)}:{}
      if(xmtd){
        returnArrayTable2PTS.push(
          <td key={description+period}>{description}</td>)
      for (let key in xmtd) {
        if(xmtd[key]['patients']){
          const dataForPractice=xmtd[key].data?xmtd[key].data:[]
          const activePTSAll=addPropToArray(dataForPractice.filter((d:any)=>d.PatientStatusDescription!==null?
          (PTS_ProcCodes.indexOf(d.PatientStatusDescription.substring(0,6)) >= 0)&&(d.ProcCode&& d.ProcCode.length > 0):false),key);
          const distinctActivePTS=Array.from(new Set(activePTSAll.map((a:any)=>a.PatientID))).map((id:any)=>{return activePTSAll.find((a:any)=>a.PatientID===id)})
		      let item:OrthoPCRClickItem = {
            practice: key,
            dataPoint: 'patients',
            period: 'mtd',
            appointmentData:distinctActivePTS
          };
          allActivePatientsData.push(...distinctActivePTS)      
          const tmp:CSVDObj={data:`${distinctActivePTS.length}`,practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,ActivePTS:[...csvExportDataObjMTD.ActivePTS,tmp]}
          returnArrayTable2PTS.push(
            <td key={key+xmtd[key]+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}>{distinctActivePTS.length}</td>)
        }else{
          const tmp:CSVDObj={data:`0`,practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,ActivePTS:[...csvExportDataObjMTD.ActivePTS,tmp]}
          returnArrayTable2PTS.push(
            <td key={key+xmtd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'patient',
        period:'mtd',
        appointmentData:allActivePatientsData
      } 
      returnArrayTable2PTS.push(
        <td key={Math.random() + period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}>{allActivePatientsData.length}</td>)
      return <tr>{returnArrayTable2PTS}</tr>;
      }
      
    }

    function convertTBodyMTDTOTALSTART(data: any,period:string,description:string) {
      const {mtd}=data
      const xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd)}:{}
      const totalStartsAllData=[]
      if(xmtd){
        returnArrayTable2TOTALSTART.push(
          <td key={description+period}
          style={{backgroundColor: "#BF8F00"}}
          >{description}</td>)
      for (let key in xmtd) {
        const dataForPractice=xmtd[key].data?xmtd[key].data:[]
        const sameDayStartsData:[]=addPropToArray(dataForPractice.filter((d:any)=>d.ProcCode!==null?
        (SDSTART_ProcCodes.indexOf(sanitiseString(d.ProcCode)) >= 0)&&filterByDate(d)
        :false),key)
        const orthoStartData=addPropToArray(dataForPractice.filter((d:any)=>d.ProcCode!==null?
        (START_ProcCodes.indexOf(sanitiseString(d.ProcCode)) >= 0)&&filterByDate(d)
        :false),key)
        const totalStartsData=[...orthoStartData,...sameDayStartsData]
        if(totalStartsData)
            totalStartsAllData.push(...totalStartsData)
        if(xmtd[key]['Total Starts']){
          let item:OrthoPCRClickItem={
            practice:key,
            dataPoint:'Total Starts',
            period:'ytd',
            appointmentData:totalStartsData
          }
          const tmp:CSVDObj={data:`${totalStartsData.length}`,practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,TotalStarts:[...csvExportDataObjMTD.TotalStarts,tmp]}
          returnArrayTable2TOTALSTART.push(
            <td key={key+xmtd[key]+period}
            className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}
            >{totalStartsData.length}</td>)
        }else{
          const tmp:CSVDObj={data:`0`,practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,TotalStarts:[...csvExportDataObjMTD.TotalStarts,tmp]}
          returnArrayTable2TOTALSTART.push(
            <td key={key+xmtd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'Total Starts',
        period:'ytd',
        appointmentData:totalStartsAllData,
      }
      returnArrayTable2TOTALSTART.push(
        <td key={Math.random()+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}>
        {totalStartsAllData.length}
      </td>
      )
      return <tr>{returnArrayTable2TOTALSTART}</tr>;
      }
    }
    function convertTBodyMTDCONTRACTS(data:any,period:string,description:string){
      const {mtd}=data
      const xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd)}:{}
      let totalContracts:any=[]
      if(xmtd){
        returnArrayTable2CONTRACTS.push(
          <td key={description+period}>
            {description}
          </td>
        )
        
        for(let key in xmtd){
          if(xmtd[key]){
            const dataForPractice=xmtd[key].data?xmtd[key].data:[]
            const contractsForPractice=addPropToArray(dataForPractice.filter((d:any)=>
            d.ProcCode!==null?
            (CONTRACTS_ProcCodes.indexOf(sanitiseString(d.ProcCode)) >= 0)&&filterByDate(d)
            :false
            ),key)
            totalContracts.push(...contractsForPractice)
                let item:OrthoPCRClickItem = {
                  practice: key,
                  dataPoint: 'Contracts',
                  period: 'mtd',
                  appointmentData:contractsForPractice
                };
            const tmp:CSVDObj={data:`${contractsForPractice.length}`,practice:key}
            csvExportDataObjMTD={...csvExportDataObjMTD,Contracts:[...csvExportDataObjMTD.Contracts,tmp]}
            returnArrayTable2CONTRACTS.push(
              <td key={key+xmtd[key]+period}
              className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}
              >{contractsForPractice.length}</td>
            )

          }
        }
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'Contracts',
        period:'mtd',
        appointmentData:totalContracts
      }
      returnArrayTable2CONTRACTS.push(
        <td key={Math.random()+period}
        className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}
        >{totalContracts.length}</td>
      )
      return <tr>{returnArrayTable2CONTRACTS}</tr>
    }

    function sanitiseString(data:any){
        if(data!==null)
        if(data!==undefined)
        return data.trim().toUpperCase();
        return "";
    }

    function convertTBodyYTDCONTRACTS(data:any,period:string,description:string){
      const {ytd}=data
      const xytd=ytd?{...sortObjectByKeysAlphabetically(ytd)}:{}
      let totalContracts:any=[]
      if(xytd){
        returnArrayTable2YTOTALCONTRACTS.push(
          <td key={description+period}    
          >
            {description}
          </td>
        )
        for(let key in xytd){
          if(xytd[key]){
            const dataForPractice=xytd[key].data?xytd[key].data:[];
            let contractsForPractice:any=[]
            Object.keys(dataForPractice).forEach((d: string) => {
              if (dataForPractice[d]&&dataForPractice[d].ProcCode!==null&&(CONTRACTS_ProcCodes.indexOf(sanitiseString(dataForPractice[d].ProcCode)) >= 0)){
                contractsForPractice.push(dataForPractice[d]);
              }
            });
            contractsForPractice=addPropToArray(contractsForPractice,key);
            totalContracts.push(...contractsForPractice)
                let item:OrthoPCRClickItem = {
                  practice: key,
                  dataPoint: 'Contracts',
                  period: 'mtd',
                  appointmentData:contractsForPractice
                };
                const tmp:CSVDObj={data:`${contractsForPractice.length}`,practice:key}
                csvExportDataObjYTD={...csvExportDataObjYTD,Contracts:[...csvExportDataObjYTD.Contracts,tmp]}
                returnArrayTable2YTOTALCONTRACTS.push(
                <td key={key+xytd[key]+period}
                className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}
                >{contractsForPractice.length}</td>
              )

          }
        }
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'Contracts',
        period:'mtd',
        appointmentData:totalContracts
      }
      returnArrayTable2YTOTALCONTRACTS.push(
        <td key={Math.random()+period}
        className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}
        >{totalContracts.length}</td>
      )
      return <tr>{returnArrayTable2YTOTALCONTRACTS}</tr>
    }


    function convertTBodyMTDTOTALCONTRACTS(data: any,period:string,description:string) {
      const {mtd}=data
      const totalContractsAll=[]
      const xmtd=mtd?{...sortObjectByKeysAlphabetically(mtd)}:{}
      if(xmtd){
        returnArrayTable2TOTALCONTRACTS.push(
          <td key={description+period}
          style={{backgroundColor: "#BF8F00"}}
          >{description}</td>)
      for (let key in xmtd) {
        const dataForPractice=xmtd[key].data?xmtd[key].data:[]
        const sameDayContracts=addPropToArray(dataForPractice.filter((d:any)=>d.ProcCode!==null?
        (SD_ProcCodes.indexOf(sanitiseString(d.ProcCode)) >= 0)&&filterByDate(d):false
        ),key)
        const contracts=addPropToArray(dataForPractice.filter((d:any)=>
        d.ProcCode!==null?
        (CONTRACTS_ProcCodes.indexOf(sanitiseString(d.ProcCode)) >= 0)&&filterByDate(d)
        :false 
        ),key)
        const allContractsForPrac=[...contracts,...sameDayContracts]
        if(allContractsForPrac)
              totalContractsAll.push(...allContractsForPrac)
        if(xmtd[key]['Total Contracts']){
          let item:OrthoPCRClickItem = {
            practice: key,
            dataPoint: 'Total Contracts',
            period: 'mtd',
            appointmentData:allContractsForPrac
          }
          const tmp:CSVDObj={data:`${allContractsForPrac.length}`,practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,TotalContracts:[...csvExportDataObjMTD.TotalContracts,tmp]}
          returnArrayTable2TOTALCONTRACTS.push(
            <td key={key+xmtd[key]+period}
            className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}
            >{allContractsForPrac.length}</td>)
        }else{
          const tmp:CSVDObj={data:`0`,practice:key}
          csvExportDataObjMTD={...csvExportDataObjMTD,TotalContracts:[...csvExportDataObjMTD.TotalContracts,tmp]}
          returnArrayTable2TOTALCONTRACTS.push(
            <td key={key+xmtd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:"Total Contracts",
        period:'ytd',
        appointmentData:totalContractsAll
      }
      returnArrayTable2TOTALCONTRACTS.push(
        <td key={Math.random()+period}
        className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}
        >{
          totalContractsAll.length
        }</td>)
      return <tr>{returnArrayTable2TOTALCONTRACTS}</tr>;
      }
      
    }

    function convertTBodyYTDTOTALSTART(data: any,period:string,description:string) {
      const {ytd}=data
      const xytd=ytd?{...sortObjectByKeysAlphabetically(ytd)}:{}
      const totalStartsAll=[]
      if(xytd){
        returnArrayTable4TOTALSTART.push(
          <td key={description+period}
          style={{backgroundColor: "#BF8F00"}}
          >{description}</td>)
      for (let key in xytd) {
        const dataForPractice=xytd[key].data?xytd[key].data:[]
        let sameDayStartsData:any=[]
            Object.keys(dataForPractice).forEach((d: string) => {
              if (dataForPractice[d]&&dataForPractice[d].ProcCode!==null&&SDSTART_ProcCodes.indexOf(sanitiseString(dataForPractice[d].ProcCode)) >= 0){
                sameDayStartsData.push(dataForPractice[d]);
              }
          });
          sameDayStartsData=addPropToArray(sameDayStartsData,key);
        let orthoStartData:any=[]
            Object.keys(dataForPractice).forEach((d: string) => {
              if (dataForPractice[d]&&dataForPractice[d].ProcCode!==null&&START_ProcCodes.indexOf(sanitiseString(dataForPractice[d].ProcCode)) >= 0){
                orthoStartData.push(dataForPractice[d]);
              }
        });
        orthoStartData=addPropToArray(orthoStartData,key);
        const totalStartsData=[...orthoStartData,...sameDayStartsData]
        if(totalStartsData)
            totalStartsAll.push(...totalStartsData)
        if(xytd[key]['Total Starts']){
          let item:OrthoPCRClickItem={
            practice:key,
            dataPoint:'Total Starts',
            period:'ytd',
            appointmentData:totalStartsData
          }
          const tmp:CSVDObj={data:`${totalStartsData.length}`,practice:key}
          csvExportDataObjYTD={...csvExportDataObjYTD,TotalStarts:[...csvExportDataObjYTD.TotalStarts,tmp]}
          returnArrayTable4TOTALSTART.push(
            <td key={key+xytd[key]+period}
            className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}
            >{totalStartsData.length}</td>)
        }else{
          const tmp:CSVDObj={data:`0`,practice:key}
          csvExportDataObjYTD={...csvExportDataObjYTD,TotalStarts:[...csvExportDataObjYTD.TotalStarts,tmp]}
          returnArrayTable4TOTALSTART.push(
            <td key={key+xytd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'Total Starts',
        period:'ytd',
        appointmentData:totalStartsAll
      }
      returnArrayTable4TOTALSTART.push(
        <td key={Math.random()+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}>
          {totalStartsAll.length}
        </td>
      )
      return <tr>{returnArrayTable4TOTALSTART}</tr>;
      }
      
    }

    function convertTBodyYTDTOTALCONTRACTS(data: any,period:string,description:string) {
      const {ytd}=data
      const totalContractsAll=[]
      const xytd=ytd?{...sortObjectByKeysAlphabetically(ytd)}:{}
      if(xytd){
        returnArrayTable4TOTALCONTRACTS.push(
          <td 
          style={{backgroundColor: "#BF8F00"}}
          key={description+period}>{description}</td>)
      for (let key in xytd) {
        const dataForPractice=xytd[key].data?xytd[key].data:[]

        let sameDayContracts:any=[]
            Object.keys(dataForPractice).forEach((d: string) => {
              if (dataForPractice[d]&&dataForPractice[d].ProcCode!==null&&SD_ProcCodes.indexOf(sanitiseString(dataForPractice[d].ProcCode)) >= 0){
                sameDayContracts.push(dataForPractice[d]);
              }
        });
        sameDayContracts=addPropToArray(sameDayContracts,key);
        let contracts:any=[]
            Object.keys(dataForPractice).forEach((d: string) => {
              if (dataForPractice[d]&&dataForPractice[d].ProcCode!==null&&CONTRACTS_ProcCodes.indexOf(sanitiseString(dataForPractice[d].ProcCode)) >= 0){
                contracts.push(dataForPractice[d]);
              }
        });
        contracts=addPropToArray(contracts,key);
        const allContractsForPrac=[...contracts,...sameDayContracts]
        if(allContractsForPrac)
              totalContractsAll.push(...allContractsForPrac)
        if(xytd[key]['Total Contracts']){
          let item:OrthoPCRClickItem = {
            practice: key,
            dataPoint: 'Total Contracts',
            period: 'mtd',
            appointmentData:allContractsForPrac
          }
          const tmp:CSVDObj={data:`${allContractsForPrac.length}`,practice:key}
          csvExportDataObjYTD={...csvExportDataObjYTD,TotalContracts:[...csvExportDataObjYTD.TotalContracts,tmp]}
          returnArrayTable4TOTALCONTRACTS.push(
            <td key={key+xytd[key]+period}
            className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}
            >{allContractsForPrac.length}</td>)
        }else{
          const tmp:CSVDObj={data:`0`,practice:key}
          csvExportDataObjYTD={...csvExportDataObjYTD,TotalContracts:[...csvExportDataObjYTD.TotalContracts,tmp]}
          returnArrayTable4TOTALCONTRACTS.push(
            <td key={key+xytd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:"Total Contracts",
        period:'ytd',
        appointmentData:totalContractsAll
      }

      returnArrayTable4TOTALCONTRACTS.push(
        <td key={Math.random()+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}
        >{
          totalContractsAll.length
        }</td>
      )
      return <tr>{returnArrayTable4TOTALCONTRACTS}</tr>;
      }
      
    }

    function convertTBodyYTDSD(data: any,period:string,description:string) {
      const {ytd}=data
      const xytd=ytd?{...sortObjectByKeysAlphabetically(ytd)}:{}
      const allSameDayContracts:any=[]
      if(xytd){
        returnArrayTable4YSD.push(
          <td key={description+period}>{description}</td>)
      for (let key in xytd) {
        if(xytd[key]['sdcontract']){
        const dataForPractice=xytd[key].data?xytd[key].data:[];

        let sameDayContracts:any=[]
            Object.keys(dataForPractice).forEach((d: string) => {
              if (dataForPractice[d]&&dataForPractice[d].ProcCode!==null&&(SD_ProcCodes.indexOf(sanitiseString(dataForPractice[d].ProcCode)) >= 0)){
                sameDayContracts.push(dataForPractice[d]);               
              }
            });
        sameDayContracts=addPropToArray(sameDayContracts,key);
        if(sameDayContracts)
            allSameDayContracts.push(...sameDayContracts)
		    let item:OrthoPCRClickItem = {
            practice: key,
            dataPoint: 'Sdcontract',
            appointmentData:sameDayContracts,
            period: 'ytd'
          };
          const tmp:CSVDObj={data:""+sameDayContracts.length,practice:key}
          csvExportDataObjYTD={...csvExportDataObjYTD,SameDayContracts:[...csvExportDataObjYTD.SameDayContracts,tmp]}
          returnArrayTable4YSD.push(
            <td key={key+xytd[key]+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}>{+sameDayContracts.length}</td>)
        }else{
          const tmp:CSVDObj={data:'0',practice:key}
          csvExportDataObjYTD={...csvExportDataObjYTD,SameDayContracts:[...csvExportDataObjYTD.SameDayContracts,tmp]}
          returnArrayTable4YSD.push(
            <td key={key+xytd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'Sdcontract',
        period:'ytd',
        appointmentData:allSameDayContracts
      }
      returnArrayTable4YSD.push(
        <td key={Math.random()+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}>{allSameDayContracts.length}</td>)
      return <tr>{returnArrayTable4YSD}</tr>;
      }
      
    }

    function convertTBodyYTDSTART(data: any,period:string,description:string) {
      const {ytd}=data
      const xytd=ytd?{...sortObjectByKeysAlphabetically(ytd)}:{}
      const allStartData:any=[]
      if(xytd){
        returnArrayTable4YSTART.push(
          <td key={description+period}>{description}</td>)
      for (let key in xytd) {
        if(xytd[key]['start']){
          const dataForPractice=xytd[key].data?xytd[key].data:[]
          let orthoStartData:any=[]
            Object.keys(dataForPractice).forEach((d: string) => {
              if (dataForPractice[d]&&dataForPractice[d].ProcCode!==null&&START_ProcCodes.indexOf(sanitiseString(dataForPractice[d].ProcCode)) >= 0){
                orthoStartData.push(dataForPractice[d]);
              }
          });
          orthoStartData=addPropToArray(orthoStartData,key);
          const orthoStartDataDistinct=Array.from(new Set(orthoStartData.map((a:any)=>a.PatientID))).map((id:any)=>{return orthoStartData.find((a:any)=>a.PatientID===id)})
		     let item:OrthoPCRClickItem = {
            practice: key,
            dataPoint: 'Start',
            appointmentData:orthoStartDataDistinct,
            period: 'ytd'
          };
          if(orthoStartDataDistinct)
            allStartData.push(...orthoStartDataDistinct)
            const tmp:CSVDObj={data:`${orthoStartDataDistinct.length}`,practice:key}
            csvExportDataObjYTD={...csvExportDataObjYTD,OrthoStart:[...csvExportDataObjYTD.OrthoStart,tmp]}
          returnArrayTable4YSTART.push(
            <td key={key+xytd[key]+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}
            >{orthoStartDataDistinct.length}</td>)
        }else{
          const tmp:CSVDObj={data:'0',practice:key}
            csvExportDataObjYTD={...csvExportDataObjYTD,OrthoStart:[...csvExportDataObjYTD.OrthoStart,tmp]}
          returnArrayTable4YSTART.push(
            <td key={key+xytd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'Start',
        period:'ytd',
        appointmentData:allStartData
      }
      returnArrayTable4YSTART.push(
        <td key={Math.random()+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}>{allStartData.length}</td>)
      return <tr>{returnArrayTable4YSTART}</tr>;
      }
      
    }
    function convertTBodyYTDRecallPT(data: any,period:string,description:string){
      const {ytd}=data
      returnArrayTable2YRECALLPT.push(
        <td key={description+period}>{description}</td>
      )
      let tempRecallTotal=0
      let tepRecallApt:any=[]
      for(let key in ytd){
        const dataY=ytd[key].data?ytd[key].data:[]
        let res:any=[]
            Object.keys(dataY!).forEach((d: string) => {
              if (dataY[d]&&dataY[d].ProcCode!==null&&RecallPT_ProcCodes.indexOf(sanitiseString(dataY[d].ProcCode)) >= 0){
                res.push(dataY[d]);
              }
          });
        res=addPropToArray(res,key);
        const RecarePatients=res?res.length:0
        tempRecallTotal+=RecarePatients
      }
      const ymtd=ytd?{...sortObjectByKeysAlphabetically(ytd),GrandTotal:{'Recall Patients':tempRecallTotal}}:{}
      for(let key in ytd){
        const dataY=ymtd[key].data?ymtd[key].data:[]
        let res:any=[]
            Object.keys(dataY!).forEach((d: string) => {
              if (dataY[d]&&dataY[d].ProcCode!==null&&RecallPT_ProcCodes.indexOf(sanitiseString(dataY[d].ProcCode)) >= 0){
                res.push(dataY[d]);
              }
          });
        res=addPropToArray(res,key);
        tepRecallApt.push(...res)
        const RecarePatientsCount:string=""+(res?res.length:0)
        let item:OrthoPCRClickItem={
          practice:key,
          dataPoint:'recall Patient',
          period:'mtd',
          appointmentData:res
        }
        const tmp:CSVDObj={data:RecarePatientsCount,practice:key}
        csvExportDataObjYTD={...csvExportDataObjYTD,RecallPatients:[...csvExportDataObjYTD.RecallPatients,tmp]}
        returnArrayTable2YRECALLPT.push(
          <td key={key+ymtd[key]+period} 
          className="pcr_tbl_numbers_clickable" onClick={()=>modalOpen(item)}
          >
            {RecarePatientsCount}
          </td>
        )
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'recall Patient',
        period:'mtd',
        appointmentData:tepRecallApt
      }
      returnArrayTable2YRECALLPT.push(
        <td key={Math.random()+period} 
        className="pcr_tbl_numbers_clickable" onClick={()=>modalOpen(grandTotalItem)}
        >
          {tempRecallTotal}
        </td>
      )
      return <tr>{returnArrayTable2YRECALLPT}</tr>
    }

    function convertTBodyYTDDEBOND(data: any,period:string,description:string) {
      const {ytd}=data
      let total=0
      const keys=ytd?Object.keys(ytd):[]
      keys.forEach(key=>{
        total+=ytd[key]['debond']?ytd[key]['debond']:0
      })      
      let grandTotalDebondAptData:any=[]
      const xytd=ytd?{...sortObjectByKeysAlphabetically(ytd)}:{}
      if(xytd){
        returnArrayTable4YDEBOND.push(
          <td key={description+period}>{description}</td>)
      for (let key in xytd) {
        if(xytd[key]['debond']){
          let tempDebondAptData:any=[];
          if(xytd[key]&&xytd[key].data){
            Object.keys(xytd[key].data).forEach((d: string) => {
              if (xytd[key].data[d]&&xytd[key].data[d].ProcCode!==null&&DEBOND_ProcCodes.indexOf(sanitiseString(xytd[key].data[d].ProcCode)) >= 0){
                tempDebondAptData.push(xytd[key].data[d]);
              }
            });
            tempDebondAptData=addPropToArray(tempDebondAptData,key);
          }
          grandTotalDebondAptData.push(...tempDebondAptData)
          let item:OrthoPCRClickItem = {
            practice: key,
            dataPoint: 'debond',
            period: 'ytd',
            appointmentData:tempDebondAptData
          };
          const tmp:CSVDObj={data:xytd[key]['debond'],practice:key}
          csvExportDataObjYTD={...csvExportDataObjYTD,OrthoDebonds:[...csvExportDataObjYTD.OrthoDebonds,tmp]}
          returnArrayTable4YDEBOND.push(
            <td key={key+xytd[key]+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}>{xytd[key]['debond']}</td>)
        }else{
          const tmp:CSVDObj={data:'0',practice:key}
          csvExportDataObjYTD={...csvExportDataObjYTD,OrthoDebonds:[...csvExportDataObjYTD.OrthoDebonds,tmp]}
          returnArrayTable4YDEBOND.push(
            <td key={key+xytd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'debond',
        period:'ytd',
        appointmentData:grandTotalDebondAptData
      }
      returnArrayTable4YDEBOND.push(
        <td key={Math.random()+period} 
        className="pcr_tbl_numbers_clickable" onClick={()=>modalOpen(grandTotalItem)}
        >
          {grandTotalDebondAptData.length}
        </td>
      )

      return <tr>{returnArrayTable4YDEBOND}</tr>;
      }
      
    }
    
    function convertTBodyYTDSDSTART(data: any,period:string,description:string) {
      const {ytd}=data
      const xytd=ytd?{...sortObjectByKeysAlphabetically(ytd)}:{}
      const allSdStartData:any=[]
      if(xytd){
        returnArrayTable4YSDSTART.push(
          <td key={description+period}>{description}</td>)
      for (let key in xytd) {
        if(xytd[key]['sdstart']){
          const dataForPractice=xytd[key].data?xytd[key].data:[]
          let sameDayStartsData:any=[]
            Object.keys(dataForPractice).forEach((d: string) => {
              if (dataForPractice[d]&&dataForPractice[d].ProcCode!==null&&SDSTART_ProcCodes.indexOf(sanitiseString(dataForPractice[d].ProcCode)) >= 0){
                sameDayStartsData.push(dataForPractice[d]);
              }
          });
          sameDayStartsData=addPropToArray(sameDayStartsData,key);
          const sameDayStartsDataDistinct=Array.from(new Set(sameDayStartsData.map((a:any)=>a.PatientID))).map((id:any)=>{return sameDayStartsData.find((a:any)=>a.PatientID===id)})
          let item:OrthoPCRClickItem = {
            practice: key,
            dataPoint: 'Sdstart',
            appointmentData:sameDayStartsDataDistinct,
            period: 'ytd'
          };
          if(key==="GrandTotal"){
            continue
          }
          if(sameDayStartsDataDistinct)
              allSdStartData.push(...sameDayStartsDataDistinct)
              const tmp:CSVDObj={data:`${sameDayStartsDataDistinct.length}`,practice:key}
              csvExportDataObjYTD={...csvExportDataObjYTD,SameDayStart:[...csvExportDataObjYTD.SameDayStart,tmp]}
          returnArrayTable4YSDSTART.push(
            <td key={key+xytd[key]+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}>{sameDayStartsDataDistinct.length}</td>)
        }else{
          const tmp:CSVDObj={data:`0`,practice:key}
          csvExportDataObjYTD={...csvExportDataObjYTD,SameDayStart:[...csvExportDataObjYTD.SameDayStart,tmp]}
          returnArrayTable4YSDSTART.push(
            <td key={key+xytd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'Sdstart',
        period:'ytd',
        appointmentData:allSdStartData
      } 
      returnArrayTable4YSDSTART.push(
        <td key={Math.random()+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}>{allSdStartData.length}</td>)
      return <tr>{returnArrayTable4YSDSTART}</tr>;
      }
      
    }

    function convertTBodyYTDPTS(data: any,period:string,description:string) {
      const {ytd}=data
      const allActivePatientsData: any=[]
      const xytd=ytd?{...sortObjectByKeysAlphabetically(ytd)}:{}
      if(xytd){
        returnArrayTable4YPTS.push(
          <td key={description+period}>{description}</td>)
      for (let key in xytd) {
        if(xytd[key]['patients']){
          let obj:any=xytd[key].data?xytd[key].data:[];
          let activePTSAll:any=[]
            Object.keys(obj).forEach((d: string) => {
              if (obj&&obj[d]&&obj[d]['PatientStatusDescription']!==null&&(PTS_ProcCodes.indexOf(sanitiseString(obj&&obj[d]&&obj[d]['PatientStatusDescription']&&obj[d]['PatientStatusDescription'].substring(0,6))) >= 0)&&(obj[d].ProcCode&& obj[d].ProcCode.length > 0)){
                activePTSAll.push(obj[d]);
              }
          });
          activePTSAll=addPropToArray(activePTSAll,key);
          const distinctActivePTS=Array.from(new Set(activePTSAll.map((a:any)=>a.PatientID))).map((id:any)=>{return activePTSAll.find((a:any)=>a.PatientID===id)})
		      let item:OrthoPCRClickItem = {
            practice: key,
            dataPoint: 'Patients',
            period: 'ytd',
            appointmentData:distinctActivePTS
          };
          allActivePatientsData.push(...distinctActivePTS)
          const tmp:CSVDObj={data:`${distinctActivePTS.length}`,practice:key}
          csvExportDataObjYTD={...csvExportDataObjYTD,ActivePTS:[...csvExportDataObjYTD.ActivePTS,tmp]}
          returnArrayTable4YPTS.push(
            <td key={key+xytd[key]+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}>{xytd[key]['patients']}</td>)
        }else{
          const tmp:CSVDObj={data:`0`,practice:key}
                csvExportDataObjYTD={...csvExportDataObjYTD,ActivePTS:[...csvExportDataObjYTD.ActivePTS,tmp]}
          returnArrayTable4YPTS.push(
            <td key={key+xytd[key]+period}>0</td>)
        }          
      }
      let grandTotalItem:OrthoPCRClickItem={
        practice:"All",
        dataPoint:'Patients',
        period:'ytd',
        appointmentData:allActivePatientsData
      } 
      returnArrayTable4YPTS.push(
        <td key={Math.random() + period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}>{allActivePatientsData.length}</td>
      )
      return <tr>{returnArrayTable4YPTS}</tr>;
      }
      
    }

    function convertTHeadYTD(data: any,period:string,description:string) {
      const {ytd}=data
      const xmtd=ytd?{...sortObjectByKeysAlphabetically(ytd),GrandTotal:{'Header':0}}:{}
      returnArrayTable3.push(
          <th key={'th'+period}colSpan={1} className="header-os"></th>)
          for (let key in xmtd) {
            returnArrayTable3.push(
              <th key={'th'+key+period} colSpan={1} className="header-os">{key}</th>)
          }
      return <tr>{returnArrayTable3}</tr>;
    }

    function convertTBodyYTD(data: any,period:string,description:string) {
      const {ytd}=data
      const keys=ytd?Object.keys(ytd):[]
      const allWorkDays:any=[]
      let total=0
      keys.forEach(key=>{
        total+=ytd[key]['Work Days']
      })
      const xytd=ytd?{...sortObjectByKeysAlphabetically(ytd),GrandTotal:{'Work Days':total}}:{}
      if(xytd){
        returnArrayTable4.push(
          <td key={description+period}>{description}</td>)
      for (let key in xytd) {
        const scheduleData=[...props.report.scheduleDataRawYTD]
        const scheduleDataCurrentPractice=addPropToArray(scheduleData.filter((item:any)=>item.location===key),key);
        if(xytd[key]['Work Days']){
          let item:OrthoPCRClickItem = {
            practice: key,
            dataPoint: 'Work Days',
            period: 'ytd',
            appointmentData:scheduleDataCurrentPractice,
          };
          allWorkDays.push(...scheduleDataCurrentPractice)
          if(key!=="GrandTotal"){
            const tmp:CSVDObj={data:scheduleDataCurrentPractice.length,practice:key}
            csvExportDataObjYTD={...csvExportDataObjYTD,WorkDays:[...csvExportDataObjYTD.WorkDays,tmp]}
            returnArrayTable4.push(
              <td key={key+xytd[key]+period}
              className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(item)}
              >{xytd[key]['Work Days']}</td>)
          }else{
            const tmp:CSVDObj={data:allWorkDays.length,practice:key}
            csvExportDataObjYTD={...csvExportDataObjYTD,WorkDays:[...csvExportDataObjYTD.WorkDays,tmp]}
            let grandTotalItem:OrthoPCRClickItem={
              practice:"All",
              dataPoint:'Work Days',
              period:'ytd',
              appointmentData:allWorkDays
            }
            returnArrayTable4.push(
              <td key={Math.random()+period} className="pcr_tbl_numbers_clickable" onClick={() => modalOpen(grandTotalItem)}>{allWorkDays.length}</td>)
          }
          
        }else{
          const tmp:CSVDObj={data:'0',practice:key}
          csvExportDataObjYTD={...csvExportDataObjYTD,WorkDays:[...csvExportDataObjYTD.WorkDays,tmp]}
          returnArrayTable4.push(
            <td key={key+xytd[key]+period}>0</td>)
        }          
      }      
      return <tr>{returnArrayTable4}</tr>;
      }
      
    }

    function hideDetailsModal (open:boolean) {
      if(open){
        if(loaderStatusCodes&& (codesState.orthoChangeCodes?codesState.orthoChangeCodes.length>0?true:false:false)){
          setState({...state,open:open});
        }        
        else{
          setState({ ...state,appttypGUID: null, value: '', name: '', index: 0,open:false });
        }
      }
      else{
        setState({ ...state,appttypGUID: null, value: '', name: '', index: 0,open:false });
      }        
    }
  
    function handleAddCode (e: any) {
      setState({ ...state,value: e.target.value });
    };
  
    function handleOptions (e: any, a: any) {
      const value = a.value;
      handleAddCode({ target: { value } });
    };
  
    /** @param {string} value */
    function handleOptionsWithAll (e: React.SyntheticEvent<HTMLElement, Event>, a: DropdownProps) {
      handleOptions(e, a);
    };
  
    function saveCode () {
      setState({ ...state,open:false});
    const orthoChangeCodes:any = codesState.orthoChangeCodes;
      const { appttypGUID, name, value, index } = state;
      
      if (orthoChangeCodes[index][name] !== value) {
        let record:any = {};
        record.appttypGUID = appttypGUID;
        if (value.length > 1){
          record[name] = value.concat(';');
          orthoChangeCodes[index][name] = value;
        } else if(value.length === 1) {
          record[name] = value[0].toString();
          orthoChangeCodes[index][name] = value;
        } else {
          record[name]= 'xxxxx';
          orthoChangeCodes[index][name] = null;
        }
        reportAction.updateStatusCode(record).then((response: any) => {
          setState({ ...state,appttypGUID: null, value: '', name: '', index: 0,open:false});
        });
        refreshStatusCodes();
      }
    };

    function refreshStatusCodes () {
      reportAction.getStatusCodes('').then((orthoChangeCodes: any) => {
        setCodesState({orthoChangeCodes});
        let data = orthoChangeCodes;

        const { keyword, orderBy, order } = state;
        if (keyword || orderBy) {

          if (keyword) {
            let result = _.filter(data, function(item:any) {
              return (item.appttypCode && item.appttypCode.toLowerCase().indexOf(keyword.toLowerCase()) > -1) || item.appttypDescription.toLowerCase().indexOf(keyword.toLowerCase()) > -1 || item.appttypLastModified.toLowerCase().indexOf(keyword.toLowerCase()) > -1;
            });
            data = result;
            setState({ ...state,appttypGUID: null, value: '', name: '', index: 0,open:false});
            setCodesState({orthoChangeCodes: data});
          }          
          if (orderBy) {
            data = _.orderBy(data, [orderBy], [!order ? 'asc' : 'desc']);
            setCodesState({orthoChangeCodes: data});
          }          
          setState({ ...state,appttypGUID: null, value: '', name: '', index: 0,open:false });

        }
      });
    };
  
    function onClickAddCode (appttypGUID: any, valuex: string, name: any, index: any) {
      let value:any = Array.isArray(valuex) ?  valuex : valuex.split(';');
      value[value.length -1]= value[value.length -1].trim();
      value = Array.isArray(value)? value.toString():value;
      setState({ ...state, appttypGUID, name, value, index });
    };
  
    function handleSort (clickedColumn:any){
      const column = state.column;
      let orthoChangeCodes:any = codesState.orthoChangeCodes;
      const order = state.order;      
  
      orthoChangeCodes = orthoChangeCodes?_.sortBy(orthoChangeCodes, [clickedColumn]):orthoChangeCodes;
      if (column !== clickedColumn) {
        setState({...state,
          column: clickedColumn,
          order: true,
        })
        setCodesState({orthoChangeCodes});
      }else{
        setState({
          ...state,
          order: !order ,
          column: null
        })
        setCodesState({orthoChangeCodes});
      }

      return true;
    }
  
    function showAsterisks () {
      let {showAll} = state;
      if(showAll){
        setState({...state,showAll: !showAll, btnText: 'Show Asterisk'})
      } else {
        setState({...state,showAll: !showAll, btnText: 'Hide Asterisk'})
      }
    }
  
    function trimString (str: string, len: number, addDots: boolean) {
      return (
        (str && str.length > len) ?
        (addDots !== false ?
          str.substring(0, len - 3) :
          str.substring(0, len - 3) + '...') :
        str);
    }
  
    function getHeader () {
      const { column, order } = state;
      const direction = order? 'down': 'up';  
        return (
          <tr>
            <th className={`cursorPointer ${column === 'appttypCode' ? 'active': ''}`} onClick={()=>handleSort('appttypCode')}>
                <div>
              ProcCode
              <i className={`sort amount ${direction} icon ml-05`}></i>
                </div>
            </th>
            <th className={`cursorPointer ${column === 'appttypDescription' ? 'active': ''}`} onClick={()=>handleSort('appttypDescription')}>
            Proc Code Description
              <i className={`sort amount ${direction} icon ml-05`}></i>
            </th>
            <th className={`cursorPointer ${column === 'typeOf' ? 'active': ''}`} onClick={()=>handleSort('typeOf')}>
            Type Of
              <i className={`sort amount ${direction} icon ml-05`}></i>
            </th>
          </tr>
        );
    }

    function getTableRows () {
      let data:any[] = codesState.orthoChangeCodes?codesState.orthoChangeCodes:
      props.report?props.report.orthoChangeCodes?props.report.orthoChangeCodes:
      []:[];
      let returnArray:any = [];
      const typeOf = 'typeOf';
      const {showAll} = state;
      try{
        if(Object.keys(data).length) {
          data.map((result, key) => {

            let resultTypeOfArray:any = [];
              let codes = result!=null?result.typeOf?result.typeOf:"":"";              
              if(codes.includes(';')){
                if(codes.includes(',')){
                  var delimiter = /,/gi;
                  var codesCopy = codes;
                  codes = codesCopy.replace(delimiter, ";");
                }
                  var delimiterDouble = /;;/gi;
                  var delimiterIsFirstCharacter = /^\;/gi;
                  var codesCopy = codes;
                  var newstr = codesCopy.replace(delimiterDouble, ";");
                  codes = newstr.replace(delimiterIsFirstCharacter, "");

                let codesArray = codes.trim().split(';');
                codesArray.forEach(function (item: any, index: any) {
                  resultTypeOfArray.push(item);
                });
              }else{
                resultTypeOfArray.push(codes.trim());
              }

            if(!showAll && !result.appttypCode.includes('*')) {

             returnArray.push(
               <tr key={key}>
                 <td>{result.appttypCode}</td>
                 <td>{result.appttypDescription}</td>
                 <td>{(result.appttypGUID === state.appttypGUID && state.name === 'typeOf') ? (<div className="dentist-list">
                 <Dropdown defaultValue={resultTypeOfArray} fluid multiple selection options={typeOfOptions} 
                 onChange={(e, a) =>handleOptionsWithAll(e, a)}
                 />
                   </div>) : <p onClick={() => onClickAddCode(result.appttypGUID, result.typeOf !== null ? result.typeOf : typeOfOptions[5].value, "typeOf", key)}>{result.typeOf !== null ? Array.isArray(result.typeOf) ? result.typeOf.join(';') : result.typeOf : typeOfOptions[5].value}</p>}</td>
               </tr>
             );
            } else if(showAll){
             returnArray.push(
               <tr key={key}>
                 <td>{result.appttypCode}</td>
                 <td>{result.appttypDescription}</td>
                 <td>{(result.appttypGUID === state.appttypGUID && state.name === 'typeOf') ? (<div className="dentist-list">
                 <Dropdown defaultValue={resultTypeOfArray} fluid multiple selection options={typeOfOptions} 
                 onChange={(e, a) =>handleOptionsWithAll(e, a)}
                 />
                   </div>) : <p onClick={() => onClickAddCode(result.appttypGUID, result.typeOf !== null ? result.typeOf : typeOfOptions[5].value, "typeOf", key)}>{result.typeOf !== null ? Array.isArray(result.typeOf) ? result.typeOf.join(';') : result.typeOf : typeOfOptions[5].value}</p>}</td>
               </tr>
             );
            }
             
          });
          return <tbody>{returnArray}</tbody>
       } else {
         return (
           <tbody>
             <tr>
               <td colSpan={7}>No Record Found</td>
             </tr>
           </tbody>
         );
       }
      }catch (ex) {
        return (
          <tbody>
            <tr>
              <td colSpan={7}>No Record Found</td>
            </tr>
          </tbody>
        );
      }
        
    }

    const onSearchChange = (e: any) => {
      let searchKeyword:string = e.target.value;
      searchKeyword= searchKeyword?searchKeyword.toLowerCase():'';
      let searchData:any = props.report.orthoChangeCodes;
      searchData=searchData?searchData:codesState.orthoChangeCodes;
      if(searchKeyword.trim().localeCompare('')!=0){
        const data = searchData && searchData.filter((element: any) => {
          return ((element.appttypCode?element.appttypCode.toLowerCase().indexOf(searchKeyword):-1) >= 0
            || (element.appttypDescription?element.appttypDescription.toLowerCase().indexOf(searchKeyword):-1) >= 0 || (element.typeOf?element.typeOf.toLowerCase().indexOf(searchKeyword):-1) >= 0
          )
        });
        setState({...state, keyword: searchKeyword});
        setCodesState({orthoChangeCodes: data});
      }else{
        setState({...state, keyword: searchKeyword});
        setCodesState({orthoChangeCodes: searchData});
      }
        
    };
	
	  const modalOpen = (orthoItem:OrthoPCRClickItem) =>{
      setOrthoItem(orthoItem);
      setDetailsModal(true);
    }

    const closeOrthoPCRModal = () => {
      setOrthoItem(initialOrthoItem);
      setDetailsModal(false);
      setDetailsModal(false);
    }

    const closePermissionsModal = () => {
      setPermissionsModal(false);
    }

  const getTotal = (total: any) => {
    return total.reduce((prev: number | string, curr: number | string) => {
      const prevVal = typeof prev === 'number' ? prev : parseInt(prev);
      const currVal = typeof curr === 'number' ? curr : parseInt(curr);
      return Math.ceil(prevVal) + Math.ceil(currVal);
    });
  };

  const generateCSVData = () => {
    const upperVal = mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production');
    const {mtd} = upperVal;

    let csv = "Month to date\n\n";
    const currentPractices:string[]=practicesCSVExport.current!;
    csv += ",";

    Object.keys(mtd).sort().forEach((practice:string) => csv+=practice+"," );

    csv += "Grand Total";
    csv += "\n";

    const data: any = csvExportDataMTDRef.current!;
    const keys = Object.keys(data);

    for(let index = 0; index < keys.length; index++) {
      const dataPoint = keys[index]
      const dataForCurrentPoint = data[dataPoint as keyof OrthoPCRExportDataObj];

      csv += dataPoint + ",";
      
      let total: any = [];
      const dataArr = dataForCurrentPoint.length > 0 ? dataForCurrentPoint.filter((i: any,j: any ,arr: any)=>arr.findIndex((l: any)=>(l.practice === i.practice)) === j) : [];

      dataArr.forEach((dp: any)=>{
        const {data, practice} = dp;
        if (practice !== "GrandTotal") {
          csv += data + ','
          total.push(data);
        }
      });

      const grandTotal = getTotal(total); 
      csv += grandTotal;        
      csv += "\n";  
    }

    csv += "\n\nYear to date\n\n"

    const dataYTD=csvExportDataYTDRef.current!;
    const keysYTD=Object.keys(dataYTD);
    csv += ",";

    currentPractices.forEach((practice:string) => csv += practice+"," );

    csv += "Grand Total,";
    csv += "\n";

    for(let index = 0; index < keysYTD.length; index++) {
      const dataPoint = keys[index];
      const dataForCurrentPoint = dataYTD[dataPoint as keyof OrthoPCRExportDataObj ];

      csv += dataPoint + ",";

      const dataArr = dataForCurrentPoint.length > 0 ? dataForCurrentPoint.filter((i: any,j: any ,arr: any)=>arr.findIndex((l: any)=>(l.practice === i.practice)) === j) : [];

      let total: any = [];
      dataArr.forEach((dp:any)=>{
        const {data, practice} = dp;
        if (practice !== "GrandTotal") {
          csv += data + ',';
          total.push(data);
        }
      });

      const grandTotal = getTotal(total);
      csv += grandTotal;
      csv += "\n";
    }
    return csv;
  };

    function sendMailPDFOrthoPCREmailAction() {      
      practiceAction.sendMailCSVOrthoPCR({data: generateCSVData(),practicename: "All Locations",download:true});

    };

    function sendMailCSVOrthoPCREmailAction() {
      practiceAction.sendMailPDFOrthoPCR({pdf: ReactDOMServer.renderToString(printOrthoPCRPDF()),practicename:"All Locations"});
      practiceAction.sendMailCSVOrthoPCR({data: generateCSVData(),practicename: "All Locations"});
    };

    function initialiseTablElements() {
      returnArrayTable1= [];
      returnArrayTable4= [];
      returnArrayTable2SD= [];
      returnArrayTable2START= [];
      returnArrayTable2DEBOND= [];
      returnArrayTable2SDSTART= [];
      returnArrayTable2TOTALSTART= [];
      returnArrayTable2TOTALCONTRACTS= [];
      returnArrayTable2PTS= [];
      returnArrayTable3= [];
      returnArrayTable4= [];
      returnArrayTable4YSD= [];
      returnArrayTable4YSTART= [];
      returnArrayTable4YDEBOND= [];
      returnArrayTable4YSDSTART= [];
      returnArrayTable4YPTS= [];
      returnArrayTable4TOTALSTART= [];
      returnArrayTable4TOTALCONTRACTS= [];
      returnArrayTable2RECALLPT=[];
      returnArrayTable2YRECALLPT=[];
      returnArrayTable5MTDCONSULT=[]
      returnArrayTable5MTDFUTURECONSULT=[]
      returnArrayTable5YTDCONSULT=[]
      returnArrayTable5YTDFUTURECONSULT=[]
      returnArrayTable6MTDTOTALCONTRACTSTARTS=[]
      returnArrayTable6YTDTOTALCONTRACTSTARTS=[]
      returnArrayTable2YTOTALCONTRACTS=[]
      returnArrayTable2CONTRACTS=[]
      }

    const printOrthoPCRPDF = () => {
      initialiseTablElements();
      return (
        <div  className="ui card">
            {orthoPCRMTDTable()}
            {orthoPCRYTDTable()}
        </div>
      )
    }

    const handleDateChange = (date:any) :void=> {
      let dateYMD:any = customMoment(date).format('YYYY-MM-DD');
      setFilterDate(date)
      setStartDate(dateYMD);
      setEndDate(customMoment(dateYMD).add(11, 'months').format('YYYY-MM-DD') );
      setSelectedMonth(dateYMD);
      reportAction.fetchOrthoUDRUserData(getUdrOrthoDataFilteredMonths,'',dateYMD).then((response: any) => {
        checkPermissions(response)
      });
    };

    const monthBadges=(period:string)=> { 
      let badges:React.ReactElement[]=[]
      let returnedMonths:any[] =[];
      let allValidMonths:any[]=[];
      if(period===YTD){
        returnedMonths = props.report.orthoYTDKeys;
        allValidMonths = getMonthsBetweenDates(moment().startOf('year').format('YYYY-MM-DD'),moment(filterDate).format('YYYY-MM-DD'))
      }else if(period===MTD){
        const mtdmonth:any[]=[];
        mtdmonth.push(moment(filterDate).format('YYYY-MM-DD'));
        returnedMonths = props.report.orthoMTDKeys;
        allValidMonths = mtdmonth;
      }       
      
      for (const month of allValidMonths) {
        if(returnedMonths.includes(month)){
          badges.push(<a className="ui green label">{moment(month).format('MMMM').toLocaleUpperCase()}</a>);
        }else{
          badges.push(<a className="ui red label">{moment(month).format('MMMM').toLocaleUpperCase()}</a>);
        }       
      }
      return <>{badges}</>;
    }
    
    const reloadFailedYTD=()=>{
      const allValidMonths:any[] = getMonthsBetweenDates(moment().startOf('year').format('YYYY-MM-DD'),moment(filterDate).format('YYYY-MM-DD'));
      reportAction.fetchOrthoUdrOrthoFailedMTDData(props.report.orthoUDRUserData, props.report.orthoYTDKeys,props.report.orthoUDROrthoDataYTD, allValidMonths).then((orthoUDROrthoDataYTD: any) => {
        setState({...state, orthoUDROrthoDataYTD});
      });
    }

    const reloadFailedMTD=()=>{
      reportAction.fetchOrthoUDRUserData(getUdrOrthoMTDFailedMonth,'',moment(filterDate).format('YYYY-MM-DD')).then((response: any) => {
        checkPermissions(response)
      });
    }

    const getUdrOrthoMTDFailedMonth = (orthoUDRUserData: any,month:any) => {
      drList = removeNullProperties(orthoUDRUserData?orthoUDRUserData.drList[3]?orthoUDRUserData.drList[3]:[]:[]);
      reportAction.fetchOrthoUdrOrthoMTDData(drList,moment(month).format('YYYY-MM-DD')).then((orthoUDROrthoDataMTD: any) => {
        setState({...state, orthoUDROrthoDataMTD});
        reportAction.upDateLoaderYTD();
      });      
    };

    const isAllMonthsLoaded=()=>{
      return ((Object.keys(props.report.orthoYTDKeys).length)===getMonthsBetweenDates(moment().startOf('year').format('YYYY-MM-DD'),moment(filterDate).format('YYYY-MM-DD')).length);
    }

    const isMTDMonthLoaded=()=>{
      let returnedMonths:any[] =[];
      returnedMonths = props.report.orthoMTDKeys;
      return returnedMonths.includes(moment(filterDate).format('YYYY-MM-DD'))?true:false;
    }

    const orthoPCRMTDTable = () => { 
      return (
                <div  className="table-responsive FreezeTable">
                    <h3>This table shows the MTD per practice ({moment(filterDate).format('MMMM')} - {moment(filterDate).format('YYYY')})</h3>
                    {monthBadges(MTD)} {!isMTDMonthLoaded()&&<Button basic color='red' onClick={()=>reloadFailedMTD()}>Reload Failed     <Icon name='sync alternate'/></Button>}
                    <table className="ui celled single line table unstackable tableStyle" key="mtd" id="mtd">
                        <thead>
                            {convertTHeadMTD(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Work Days')}
                        </thead>
                        <tbody>
                            {convertTBodyMTD(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Work Days')}
                            {convertTBodyMTDCONSULT(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Consults')}
                            {convertTBodyMTDFUTURECONSULT(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Future Consults')} 
                            {convertTBodyMTDCONTRACTS(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Contracts')}
                            {convertTBodyMTDSD(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Same-Day Contracts')}
                            {convertTBodyMTDTOTALCONTRACTS(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Total Contracts')}
                            {convertTBodyMTDSTART(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Ortho Start')}
                            {convertTBodyMTDSDSTART(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Same-Day Start')}
                            {convertTBodyMTDTOTALSTART(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Total Starts')}
                            {convertTBodyMTDPTS(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Active PTS')}
                            {convertTBodyMTDDEBOND(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Ortho Debonds')}
                            {convertTBodyMTDRecallPT(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Recall Patients')}
                            {convertTBodyMTDTOTALCONTRACTSALES(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'mtd','Total Contract Sales')}                        
                        </tbody>
                    </table>
                </div>
      )
  }

    const orthoPCRYTDTable = () => {
      return (
                <div  className="table-responsive FreezeTable">
                  <h3></h3>
                    <h3>This table shows the YTD per practice ({moment(filterDate).format('MMMM')} - {moment(filterDate).format('YYYY')})</h3>
                    {monthBadges(YTD)} {!isAllMonthsLoaded()&&<Button basic color='red' onClick={()=>reloadFailedYTD()}>Reload Failed     <Icon name='sync alternate'/></Button>}
                    <table className="ui celled single line table unstackable tableStyle" key="mtd" id="mtd">
                        <thead>
                            {convertTHeadYTD(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Work Days')}
                        </thead>
                        <tbody>
                            {convertTBodyYTD(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Work Days')}
                            {convertTBodyYTDCONSULT(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Consults')}
                            {convertTBodyYTDFUTURECONSULT(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Future Consults')}
                            {convertTBodyYTDCONTRACTS(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Contracts')}
                            {convertTBodyYTDSD(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Same-Day Contracts')}
                            {convertTBodyYTDTOTALCONTRACTS(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Total Contracts')}
                            {convertTBodyYTDSTART(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Ortho Start')}
                            {convertTBodyYTDSDSTART(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Same-Day Start')}
                            {convertTBodyYTDTOTALSTART(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Total Starts')}
                            {convertTBodyYTDPTS(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Active PTS')}
                            {convertTBodyYTDDEBOND(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Ortho Debonds')}
                            {convertTBodyYTDRecallPT(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Recall Patients')}
                            {convertTBodyYTDTOTALCONTRACTSALES(mergeArrays(orthoWorkDayReportMTD,orthoWorkDayReportYTD,props.report.orthoUDROrthoDataMTD,props.report.orthoUDROrthoDataYTD,'production'),'ytd','Total Contract Sales')}
                        </tbody>
                    </table>
                </div>
      )
  }
    return (
        <Template activeLink="Oral Surgery Patient Care Report">
            <div>
            <div className="ui card">
                <div className="content pb0">
                    <Grid>
                        <Grid.Row className="mb10">
                            <Grid.Column>
                                <FilterSectionOrtho  
                                handleDateChange={handleDateChange}
                                filterDate={filterDate}
                                filters={filterState.filters} page={page} heading="Ortho Patient Care Report" onChangeFilter={onChangeFilter} currentPracticesKeys={_.union(Object.keys(orthoWorkDayReportMTD),Object.keys(orthoWorkDayReportYTD))}/>
                                <div className="topFilters float-right">
                                {loaderStatusCodes&& ((codesState.orthoChangeCodes?codesState.orthoChangeCodes.length>0?true:false:false) || (props.report?props.report.orthoChangeCodes?props.report.orthoChangeCodes.length>0?true:false:false:false))&& <Button className='red mr10' onClick={()=>hideDetailsModal(true)}>Change Codes</Button>}
                                    <a className="link mr30" onClick={() => sendMailCSVOrthoPCREmailAction()}><Icon name="send"/> Send</a>
                                    <a className="link"  onClick={() => sendMailPDFOrthoPCREmailAction()}><Icon name="file alternate"/>Export</a>
                                </div>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
            <div>            
            <div key={"MTD"} className="ui card mt10">
            <div className="content">
                <div>
                  {loaderMTD && <Dimmer active inverted>
                  <Loader inverted>Loading values</Loader>
                  </Dimmer>}
                  {orthoPCRMTDTable()}
                </div>
                <div>
                  {loaderYTD && <Dimmer active inverted>
                  <Loader inverted>Loading values</Loader>
                  </Dimmer>}
                  {orthoPCRYTDTable()}
                </div>
            </div>
            </div>
            </div>
        </div>        
        <OrthoPCRNoUserPermissionsModal open={permissionsModal} handleClose={closePermissionsModal}/>
        {detailsModal && <OrthoPCRModal orthoItem={orthoItem}  handleClose={closeOrthoPCRModal}/>}
            <Modal
              open={state.open}
              onClose={() => hideDetailsModal(false)}
              onOpen={() => hideDetailsModal(true)}
              centered={false}
            >
              <Modal.Header>Status Codes
                <Label color={codesState.orthoChangeCodes?codesState.orthoChangeCodes.length>0?'green':'red':'red'} floating>
                {codesState.orthoChangeCodes?codesState.orthoChangeCodes.length:0}
                </Label>
                <div className="ui top right attached label production_right_label">
                <Button onClick={() => hideDetailsModal(false)} inverted>
                  Close
                </Button>
              </div>
              </Modal.Header>
              <Segment>
                          
              <Modal.Content scrolling className="pt-0">
                <Modal.Description className="mt20">
                
                    <Input className='mb10' icon='search' placeholder="Search" onChange={onSearchChange}/>
                    <Button  primary className="mb10 float-right" onClick={ () => showAsterisks() }>{state.btnText}</Button>
                    <Button className='green mr10 float-right' onClick={()=>saveCode()}>Save Changes</Button>
                  
                  <div className="table-responsive overflow-unset">
                    <table className="table table-striped table-hover my-0 TableHeaderFixed">
                      <thead>
                        {getHeader()}
                      </thead>
                      {getTableRows()}
                    </table>
                  </div>
  
                </Modal.Description>
              </Modal.Content>
              </Segment>
            </Modal>  
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app,
    };
}

export default connect(mapStateToProps)(OrthoPCRReport);