import {ActionType} from "../../model/actionTypes"
import {errorHandler} from "./auth";

export const fetchLambdasList = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp = await api.get(`lambdas/getLambdas`);
        dispatch({type: ActionType.LOAD_LAMBDAS_LIST, payload: {lambdas_list: resp}});
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    } finally {
        dispatch({type: ActionType.LOADER, payload: false});
    }
}

export const fetchLambdasListFromAWS = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp = await api.get(`lambdas/fetchLambdasFromAWS`);
        dispatch({type: ActionType.LOAD_LAMBDAS_LIST_FROM_AWS, payload: {lambdas_list_from_aws: resp}});
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    } finally {
        dispatch({type: ActionType.LOADER, payload: false});
    }
}

export const saveForm = (postObj: any, action: any) => async (dispatch: Function, getState: Function, api: any) => {
    if (action == 'editForm') {
        dispatch({type: ActionType.LOADER, payload: true});
        try {
            return await api.put(`lambdas/updateLambda`, postObj);
        } catch (err) {
            return dispatch(errorHandler(err));
        } finally {
            dispatch({type: ActionType.LOADER, payload: false});
        }

    } else {
        dispatch({type: ActionType.LOADER, payload: true});
        try {
            return await api.post(`lambdas/createLambda`, postObj);
        } catch (err) {
            return dispatch(errorHandler(err));
        } finally {
            dispatch({type: ActionType.LOADER, payload: false});
        }
    }
}

export const getLogs = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        return await api.get(`lambdas/logs?lambdaId=${id}&limit=20`);
    } catch (err) {
        return dispatch(errorHandler(err));
    } finally {
        dispatch({type: ActionType.LOADER, payload: false});
    }
}

export const invoke = (request: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        dispatch({type: ActionType.LOADER, payload: true});
        return await api.post(`lambdas/invoke`, request);
    } catch (err) {
        return dispatch(errorHandler(err));
    } finally {
        dispatch({type: ActionType.LOADER, payload: false});
    }
}

