import { Action, ActionType } from "../../model/actionTypes";
import { PMDTodaysGoallAllProgress } from "../../model/pmdTodaysGoallAllProgress ";
import createReducer from "./createReducer";
const initialState:PMDTodaysGoallAllProgress = {
	progressTodayGoalAllRefresh: 0
};
export const pmdTodaysGoallAllProgress = createReducer(initialState, {
	[ActionType.TODAY_GOAL_INCREMENT](state: PMDTodaysGoallAllProgress, action: Action<PMDTodaysGoallAllProgress>) {
		return Object.assign({}, initialState, { progressTodayGoalAllRefresh : state.progressTodayGoalAllRefresh += 1});
    },
	[ActionType.TODAY_GOAL_DECREMENT](state: PMDTodaysGoallAllProgress, action: Action<PMDTodaysGoallAllProgress>) {
		return Object.assign({}, initialState, { progressTodayGoalAllRefresh : 0});
    }
});
