import React, {useState} from 'react';
import {HEADERS} from './constants';
import {Button, Modal} from "semantic-ui-react";
import {PracticeList} from "../../../model/practice";
import Papa from "papaparse";
import DisplayTable from "./DisplayTable";
import moment from 'moment';

interface FileUploaderProps {
    data: any[],
    practices: PracticeList[],
    save?: (data: any) => {}
}

function FileUploader(props: FileUploaderProps) {
    const [state, setStates] = useState({
        data: [] as any[],
        modalOpen: false
    });

    const changeHandler = async (event: React.ChangeEvent<HTMLElement>) => {
        const excelData = (event as any).target && (event as any).target.files[0] && (await loadFileData((event as any).target.files[0])) || [];
        if (!excelData || !excelData.length) return;
        const data = excelData.map((excelVal:any)=> {
            const obj = {} as any;
            HEADERS.forEach(header => {
                if(header.value=="date"){
                    obj[header.value] = moment(excelVal[header.name],['DD-MM-YYYY','MM-DD-YYYY','YYYY-MM-DD']).format('YYYY-MM-DD').toString()
                }else{
                    obj[header.value] = excelVal[header.name]
                }
            })
            return obj;
        });
        setStates({...state, data});
    };

    const loadFileData = (file: any): Promise<any[]> => {
        return new Promise(resolve => {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results) =>resolve(results.data),
                error: (error) => {
                    alert(error.message);
                    resolve([]);
                }
            });
        })
    }

    const save = () => {
        setStates({...state, modalOpen: false});
        const data = state.data.map(val => {
            delete val.practice;
            return val;
        });
        props.save && props.save(data);
        setStates({...state, modalOpen: false,data:[]});
    }

    return (
        <Modal
            onClose={() => setStates({...state, modalOpen: false,data:[]})}
            onOpen={() => setStates({...state, modalOpen: true})}
            open={state.modalOpen}
            size="large"
            trigger={<Button className="mr10 mb15" primary={true}>Upload Patient Count and Care</Button>}
        >
            <Modal.Content scrolling={true}>
                <Modal.Description>
                    {
                        (state.data && state.data.length) ?
                            <DisplayTable data={state.data} autoSave = {false}/> :
                            <input
                                type="file"
                                name="file"
                                accept=".csv"
                                onChange={changeHandler}
                                style={{display: "block", margin: "10px auto"}}
                            />
                    }
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Close"
                    onClick={() => setStates({...state, modalOpen: false,data:[]})}
                />
                <Button
                    content="Upload Table"
                    disabled={!state.data || !state.data.length}
                    onClick={save}
                    primary={true}
                />
            </Modal.Actions>
        </Modal>);
}


export default FileUploader