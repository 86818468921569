import { Action, ActionType } from "../../model/actionTypes";
import { MDM_Info } from "../../model/mdm";
import { Spore } from "../../model/spore";
import createReducer from "./createReducer";



const initialState:any={
    user_practices:[],
    tableResult:[]
}
export const spore = createReducer(initialState, {
    [ActionType.LOAD_SPORE_USER_PRACTICES](state: any, action: Action<Spore>) {
      return Object.assign({...state}, action.payload);
    },
    [ActionType.LOAD_SPORE_TABLE_RESULT](state: any, action: Action<Spore>) {
        return Object.assign({...state}, action.payload);
      },
  });