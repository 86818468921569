import React, {useEffect, useState} from "react";
import {Button, Card, Dimmer, Grid, Icon, Label, List, Loader} from 'semantic-ui-react';
import Template from "./Template";
import {Account} from "../model/user";
import {XrayServiceStatus} from "../model/xrayServiceStatus";
import {useActions} from "../redux/actions";
import * as xrayActions from "../redux/actions/xrayService";
import * as practiceActions from "../redux/actions/practice";
import moment from "moment";
import {RootState} from "../model";
import {connect} from "react-redux";
import HelpMark from "./HelpMark";

interface Props {
    user: Account,
    status: XrayServiceStatus
}

function XrayServiceDashboard(props: Props) {
    const initialState: any = {
        xrayStatuses: null,
        filter: null
    }
    const [state, setStates] = useState(initialState);
    const xrayAction = useActions(xrayActions);
    const practiceAction = useActions(practiceActions)

    const load = async (allPractices: any[]) => {
        const practices = allPractices.filter(p => p.xrayService);
        let statuses = await xrayAction.fetchXrayStatus();
        const xrayStatuses: any[] = practices
            .map(practice => {
                const status: XrayServiceStatus = statuses.filter((status: any) =>
                    status.directory.toLowerCase() === practice.practiceDBName.toLowerCase())[0];
                if (!status) return practice;
                status.status._isRunning = status.status.IsRunning;
                status.status.daysLastRan = '+100';
                const lastDateProcessed = status.status && moment(status.status.DateProcessed);
                if (lastDateProcessed)
                    status.status.daysLastRan = moment(new Date()).diff(lastDateProcessed, 'days');
                status.status.IsRunning = status.status._isRunning && status.status.daysLastRan < 3;
                return {practice, ...status};
            })
            .sort((a: any, b: any) => {
                if (!a.status) return -100000;
                if (!b.status) return 100000;
                return a.status.IsRunning - b.status.IsRunning;
            })
            .sort((a: any, b: any) => {
                if (!a.status) return -100000;
                if (!b.status) return 100000;
                return b.status.daysLastRan - a.status.daysLastRan;
            });
        setStates({xrayStatuses, filter: null});
    }

    useEffect(() => {
        practiceAction.fetchPractices(load);
    }, []);


    const getColumn = (status: XrayServiceStatus | any) => {
        const getValue = (attribute: string, name: string, value: string = 'NOT AVAILABLE') => {
            const data: any = status.status || {};
            return {name, value: data[attribute] || value}
        }

        const lastDateProcessed = status.status && moment(status.status.DateProcessed);

        const icon: any = !status.status || !status.status.IsRunning ? {name: 'frown outline', color: 'red'}
            : status.status.daysLastRan >= 3 ? {name: 'meh outline', color: 'orange'}
                : {name: 'smile outline', color: 'green'}

        let details;
        if (status && status.directory) {
            const lastActive = status.status.daysLastRan < 1 ? 'TODAY' : status.status.daysLastRan < 2 ? 'YESTERDAY' : `${status.status.daysLastRan} DAYS ago`;
            const attributes = [
                getValue('Version', 'Service Version'),
                {
                    name: 'Last Processed Date',
                    value: lastDateProcessed ? lastDateProcessed.format('HH:mm, ddd D MMM YYYY') : 'NOT AVAILABLE'
                },
                {name: 'Last Active', value: lastActive},
                getValue('LastPatNum', 'Last PatNum Processed'),
                getValue('FolderUploadsToday', 'Folder Uploads on The Day', '0'),
                getValue('FileUploadsToday', 'File Uploads on The Day', '0')
            ]
            details = <List divided={true} className="f12">
                <List.Item style={{padding: '0.8em'}}>
                    STATUS <strong>: <b> {(status.status || {}).IsRunning ? 'RUNNING' : 'IDLE/OFFLINE'}</b></strong>
                </List.Item>
                {attributes.map(attr => <List.Item key={attr.name} style={{padding: '0.8em'}}>
                    {attr.name} <strong>: {attr.value}</strong>
                </List.Item>)}
                <List.Item style={{padding: '0.8em'}}>
                    IP Addresses <strong>: {(status.status.IPAddresses || []).map((address: string, i: number) =>
                    <Label color={'blue'} key={i + 'i'} size='large'><b>{address}</b></Label>)}</strong>
                </List.Item>
            </List>;
        } else {
            details = <p className='mb15 mt15'>
                Service with name <b>{status.practice.practiceDBName || status.practiceDBName}</b> was not
                found<br/><br/>
                <b>Verify that the service is installed and correctly configured</b>
            </p>
        }
        return <div>
            <h3 className='textCenter mt0 mb0'>{status.practice.practice}&nbsp;
                <Icon
                    name={icon.name}
                    color={icon.color}
                    size='large'
                    className='ml15'
                />
            </h3>
            {details}
        </div>
    }

    const addFilter = (filter: boolean | any) => {
        setStates({filter, xrayStatuses: state.xrayStatuses});
    }
    const {filter, xrayStatuses} = state;
    const statuses: XrayServiceStatus[] = filter == null
        ? xrayStatuses
        : (xrayStatuses || []).filter((f: any) => (f.status ? Boolean(f.status.IsRunning) : false) === filter);
    const totalRunning = (state.xrayStatuses || []).filter((data: any) => data.status && data.status.IsRunning).length;

    return (
        <Template activeLink='practice-manager-dashboard'>

            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left">Xray Service Dashboard <HelpMark pageId='20'/></h2>
                    <div className="topFilters flex items-end flex-wrap">
                        {
                            state.xrayStatuses && <>
                                <Button onClick={() => addFilter(null)}>
                                    <Icon color='grey' name='globe'/>
                                    All Services: {state.xrayStatuses.length}
                                </Button>
                                <Button onClick={() => addFilter(true)}>
                                    <Icon color='green' name='smile outline'/>Online Services: {totalRunning}
                                </Button>
                                <Button onClick={() => addFilter(false)}>
                                    <Icon color='red' name='smile outline'/>
                                    Idle/Offline Services: {state.xrayStatuses.length - totalRunning}
                                </Button>
                            </>
                        }
                    </div>
                </div>
            </div>

            {
                !state.xrayStatuses && <Dimmer active={true}><Loader/></Dimmer>
            }


            {
                statuses &&
                <div className="flex flex-wrap items-stretch fourCoulmns">
                    {(statuses).map((status: XrayServiceStatus) => getColumn(status))}
                </div>
            }
        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user
    };
}

export default connect(mapStateToProps)(XrayServiceDashboard);
