import React, {useState} from 'react';
import {Button, Form, Modal,Grid} from "semantic-ui-react";

interface ImplantCostListProps {
    initialValues : { id: any;
        code: any;
        description: any;
        office_fee: any;},
    cancelForm : any,
    saveForm : any,
    isDelete : boolean,
}

const ImplantCostListForm = (props: ImplantCostListProps) => {
    const [state, setStates] = useState({id:props.initialValues.id,
        code:props.initialValues.code,
        description:props.initialValues.description,
        office_fee:props.initialValues.office_fee,
        isDelete:props.isDelete,});

    const onChange = (e: any, element: any) => {
        const obj:any={...state}
        obj[element.name] =element.value;
        setStates(obj);
    };

    const saveForm = () => {
        props.saveForm(state);
    };

    const { initialValues, cancelForm,isDelete } = props;
    return(

        <Modal
            onClose={() => cancelForm}
            open={true}
        >
            <Modal.Header>{initialValues.id&&initialValues.id>0?isDelete==true?"Delete":"Edit ":"Create "} Implant Cost Descriptions</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form className={'widthFluid'}>
                        <Form.Group widths='equal'>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Form.Input
                                            fluid={true}
                                            label='CODE'
                                            placeholder='CODE'
                                            defaultValue={state.code}
                                            name={'code'}
                                            onChange={onChange}
                                            disabled={isDelete}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Form.Input
                                            fluid={true}
                                            label='DESCRIPTION'
                                            placeholder='DESCRIPTION'
                                            defaultValue={state.description}
                                            name={'description'}
                                            onChange={onChange}
                                            disabled={isDelete}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Form.Input
                                            fluid={true}
                                            label='COST'
                                            placeholder='COST'
                                            defaultValue={state.office_fee}
                                            name={'office_fee'}
                                            onChange={onChange}
                                            disabled={isDelete}
                                        />
                                    </Grid.Column>
                                </Grid.Row>                                

                            </Grid>
                        </Form.Group>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button className={'ml15'} primary={true} onClick={saveForm}>{props.isDelete ? 'Delete':'Save'}</Button> &nbsp;
                <Button secondary={true} onClick={cancelForm}>Cancel</Button>
            </Modal.Actions>
        </Modal>

    );
}

export default ImplantCostListForm;
