import React, { useState,useEffect } from "react";
import {Button, Modal, Table} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import _ from "lodash";
import moment from "moment";
import * as practiceActions from "../redux/actions/practice";
import { useActions } from "../redux/actions";

function OrthoPCRModal (props: any) {
    const practiceAction = useActions(practiceActions);
    const practice = props.orthoItem.practice?props.orthoItem.practice.toLowerCase():'';
    const practiceObj = props.practice.practiceNamesMap[practice];
    const orthoLocation = practiceObj?practiceObj.orthoLocation?practiceObj.orthoLocation:"":"";
    const appointmentData = props.orthoItem.appointmentData?props.orthoItem.appointmentData:false;  
    const data:any = props.orthoItem==='mtd'?props.report.orthoUDROrthoDataMTD:props.report.orthoUDROrthoDataYTD;
    let temp:any = props.orthoItem.dataPoint==="patients"?data.production:data[props.orthoItem.dataPoint];
    temp = temp&&((temp!== undefined)||(temp!== null))?temp[orthoLocation]?temp[orthoLocation]:[]:[];
    temp =temp?temp.data?temp.data:[]:[];    
    const [finalData, setFinalData] = useState(appointmentData?appointmentData:temp);
    const [column, setColumn] = useState(null);
    const [order, setOrder] = useState(true);
    
    const handleSort = (clickedColumn:any) => {
    
        if (column !== clickedColumn) {
            setFinalData(_.sortBy(finalData, [clickedColumn]))
            setColumn(clickedColumn)
            setOrder(true)
    
          return
        }
        setFinalData(finalData.reverse())
        setOrder(!order)
    }
    const info = props.orthoItem.dataPoint==='Work Days'?['Id','Description','isPublished',
    'Practice','Start Date','End Date','Title','Type']:
    ['PatientID','ProcDate','Practice','Procfee','Next_Apt'
    ,'Abbr','PatientTotalDue','AdjAmt','ProcCode','ContractTotal'
    ,'typeOf','PatientStatusDescription'];

    function sendMailOrthoPCRPopUpEmailAction() {        
        const dataPoint = props.orthoItem.dataPoint;

        if(finalData) {
            practiceAction.sendMailOrthoPCRPopUp({data: finalData,practicename: orthoLocation}, dataPoint, info);
        } else {
            practiceAction.sendMailOrthoPCRPopUp({data: props.props.orthoItem.appointmentData, practicename: orthoLocation}, dataPoint, info);
        }
        props.handleClose();
    };

    const getTableHeadings = () => {
        let returnArray: React.ReactElement[] = [];
        const direction = order? 'down': 'up';
        info.forEach((item: any, key: number) => {
                returnArray.push(                    
                    <th key={key} className={`cursorPointer ${column === item ? 'active': ''}`} onClick={() => handleSort(item)}>{item}
                    <i className={`sort amount ${direction} icon ml-05`}></i>
                    </th>);
            });
        return (<thead><tr>{returnArray}</tr></thead>)
    }

    const getStatus=(date:string)=>{
        return moment(date).isAfter(new Date())?
        "Scheduled":"Completed"
    }
    const getTableRows = () => {
        let returnArrayInfo: React.ReactElement[] = [];
        if (props.orthoItem.dataPoint!=="Work Days" && finalData && finalData .length > 0){
            finalData.map((item: any, key: number) => {
                returnArrayInfo.push(<tr key={key}>
                    <td>{item.PatientID}</td>
                    <td>{moment(item.ProcDate).format('DD-MMM-YYYY')}</td>
                    <td>{item.Location}</td>
                    <td>{item.Procfee}</td>
                    <td>{moment(item.Next_Apt).format('DD-MMM-YYYY')}</td>
                    <td>{item.Abbr}</td>
                    <td>{item.PatientTotalDue}</td>
                    <td>{item.AdjAmt}</td>
                    <td>{item.ProcCode}</td>
                    <td>{item.ContractTotal}</td>
                    <td>{item.typeOf}</td>
                    <td>{item.PatientStatusDescription}</td>
                    </tr>);
            });
        }
        else if (props.orthoItem.dataPoint==="Work Days" && finalData && finalData .length > 0){
                finalData.map((item: any, key: number) => {
                    returnArrayInfo.push(<tr key={key}>
                        <td>{item.id}</td>
                        <td>{item.desc}</td>
                        <td>{""+item.isPublished}</td>
                        <td>{item.location}</td>
                        <td>{moment(item.startDate).format('DD-MMM-YYYY')}</td>
                        <td>{moment(item.end).format('DD-MMM-YYYY')}</td>
                        <td>{item.title}</td>
                        <td>{item.type}</td></tr>);                    
                })
            }
        else{
            returnArrayInfo.push(<tr key={`${Math.random()}`}><td colSpan={11}>no records found</td></tr>);
            }

        return (<tbody>{returnArrayInfo}</tbody>);
    }
    return (
        <Modal 
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className={'modalCenter'}
               onClose={() => props.handleClose()}
               open={true}
        >
            <Modal.Header>{props.orthoItem.dataPoint.toUpperCase()} Details <a className="ui orange label">{props.orthoItem.practice.toLocaleUpperCase()}</a><Button
                className="float-right"
                content="close"
                primary={true}
                onClick={() => props.handleClose()}
            />
           {
              props.orthoItem.appointmentData&&(props.orthoItem.dataPoint!=='Total Contract Sales')&& 
              <Button color="green"> Count {props.orthoItem.appointmentData.length}</Button>
           }
            </Modal.Header>
            <Modal.Content scrolling>
                <div>
                    <div className="table-responsive">
                        <table className="table table-striped table-hover my-0 TableHeaderFixed">
                            {getTableHeadings()}
                            {getTableRows()}
                        </table>
                    </div>

                </div>
            </Modal.Content>
            <Modal.Actions>
            <Button onClick={() => sendMailOrthoPCRPopUpEmailAction()} inverted color='orange'>
            Send Mail
            </Button>
            </Modal.Actions>
        </Modal>
    );
}


function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app,
    };
}
export default connect(mapStateToProps)(OrthoPCRModal);
