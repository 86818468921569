import _ from 'lodash';
import * as React from 'react';
import {Button, Dimmer, Grid, Label, Loader, Modal, Table} from "semantic-ui-react";
import PMDUnitItem from './PMDUnitItem';
import PMDUnitItemClickable from './PMDUnitItemClickable';

export interface PMDScheduleCoordinatorProps {
  practicename: string,
  apt_created_next_four_days:any,
  apt_created_goal:any,
  no_future_w_tax:any,
  no_future_apt:any,
  pmdScheduleCoordinatorNoFutureWtX_Data:any [],
  pmdScheduleCoordinatorNoFutureApt_Data:any [],
  noFutureAptConcatArray:any[],
  sendNoFutureAptMail (item: any,practicename:any) : void,
  sendNoFutureAptWtxMail (item: any,practicename:any) : void
}

export interface PMDScheduleCoordinatorState {
  practicename: string,
  apt_created_next_four_days:any,
  apt_created_goal:any,
  no_future_w_tax:any,
  no_future_apt:any,
  open: boolean,
  open2: boolean,
  pmdScheduleCoordinatorNoFutureWtX_Data:any [],
  pmdScheduleCoordinatorNoFutureApt_Data:any [],
  column:any,
  direction:any,
  noFutureAptConcatArray:any[],
  columnX:any,
  directionX:any,
}

class PMDScheduleCoordinator extends React.Component<PMDScheduleCoordinatorProps,PMDScheduleCoordinatorState> {  
  constructor(props: PMDScheduleCoordinatorProps) {
    super(props);
    this.state={
      practicename : props.practicename,
      apt_created_next_four_days:props.apt_created_next_four_days,
      apt_created_goal:props.apt_created_goal,
      no_future_w_tax:props.no_future_w_tax,
      no_future_apt:props.no_future_apt,
      open : false,
      open2 : false,
      pmdScheduleCoordinatorNoFutureWtX_Data:props.pmdScheduleCoordinatorNoFutureWtX_Data,
      pmdScheduleCoordinatorNoFutureApt_Data:props.pmdScheduleCoordinatorNoFutureApt_Data,
      column: null,
      direction: null,
      noFutureAptConcatArray:props.noFutureAptConcatArray,
      columnX: null,
      directionX: null,
    }
    this.setPracticeName=this.setPracticeName.bind(this);
    this.setApt_created_next_four_days=this.setApt_created_next_four_days.bind(this);
    this.setApt_created_goal=this.setApt_created_goal.bind(this);
    this.setNo_future_w_tax=this.setNo_future_w_tax.bind(this);
    this.setNo_future_apt=this.setNo_future_apt.bind(this);
    this.setOpen=this.setOpen.bind(this);
    this.setOpen2=this.setOpen2.bind(this);
    this.setPmdScheduleCoordinatorNoFutureWtX_Data=this.setPmdScheduleCoordinatorNoFutureWtX_Data.bind(this);
    this.setPmdScheduleCoordinatorNoFutureApt_Data=this.setPmdScheduleCoordinatorNoFutureApt_Data.bind(this);
    this.setColumn=this.setColumn.bind(this);
    this.setDirection=this.setDirection.bind(this);
    this.setNoFutureAptConcatArray=this.setNoFutureAptConcatArray.bind(this);
    this.setColumnX=this.setColumnX.bind(this);
    this.setDirectionX=this.setDirectionX.bind(this);
  }

  componentWillReceiveProps(nextProps: PMDScheduleCoordinatorProps) {
    this.setState({
      practicename: nextProps.practicename,
      apt_created_next_four_days:nextProps.apt_created_next_four_days,
      apt_created_goal:nextProps.apt_created_goal,
      no_future_w_tax:nextProps.no_future_w_tax,
      no_future_apt:nextProps.no_future_apt,
      pmdScheduleCoordinatorNoFutureWtX_Data:nextProps.pmdScheduleCoordinatorNoFutureWtX_Data,
      pmdScheduleCoordinatorNoFutureApt_Data:nextProps.pmdScheduleCoordinatorNoFutureApt_Data,
      noFutureAptConcatArray:nextProps.noFutureAptConcatArray
    })
  }


  setPracticeName(value: string) {
    this.setState({
      practicename : value
    })
  }

  setApt_created_next_four_days(value: any) {
    this.setState({
      apt_created_next_four_days : value
    })
  }

  setApt_created_goal(value: any) {
    this.setState({
      apt_created_goal : value
    })
  }

  setNo_future_w_tax(value: any) {
    this.setState({
      no_future_w_tax : value
    })
  }

  setNo_future_apt(value: any) {
    this.setState({
      no_future_apt : value
    })
  }

  setPmdScheduleCoordinatorNoFutureWtX_Data(value: []){
    this.setState({
      pmdScheduleCoordinatorNoFutureWtX_Data : value
    })
  }

  setPmdScheduleCoordinatorNoFutureApt_Data(value: []){
    this.setState({
      pmdScheduleCoordinatorNoFutureApt_Data : value
    })
  }  

  setOpen(value: boolean) {
    this.setState({
      open : value
    });
  }

  setOpen2(value: boolean) {
    this.setState({
      open2 : value
    });
  }

  setColumn(value: string) {
    this.setState({
      column : value
    })
  }

  setDirection(value: string) {
    this.setState({
      direction : value
    })
  }

  setNoFutureAptConcatArray(value: []){
    this.setState({
      noFutureAptConcatArray : value
    })
  }

  setColumnX(value: string) {
    this.setState({
      columnX : value
    })
  }

  setDirectionX(value: string) {
    this.setState({
      directionX : value
    })
  }

  handleSort(clickedColumn: any){
    let { column, noFutureAptConcatArray, direction } = this.state;
     if (column !== clickedColumn) {
            column =  clickedColumn;
            noFutureAptConcatArray =  _.sortBy(noFutureAptConcatArray, o => o[clickedColumn]);
            direction = 'ascending';
            this.setState({...this.state, noFutureAptConcatArray, column, direction});
         return;
     }
     noFutureAptConcatArray = noFutureAptConcatArray.reverse();
    direction = direction === 'ascending' ? 'descending' : 'ascending';
    this.setState({...this.state, noFutureAptConcatArray, direction});
  };

  handleSortWtx(clickedColumn: any){
    let { columnX, pmdScheduleCoordinatorNoFutureWtX_Data, directionX } = this.state;
     if (columnX !== clickedColumn) {
            columnX =  clickedColumn;
            pmdScheduleCoordinatorNoFutureWtX_Data =  _.sortBy(pmdScheduleCoordinatorNoFutureWtX_Data, o => o[clickedColumn]);
            directionX = 'ascending';
            this.setState({...this.state, pmdScheduleCoordinatorNoFutureWtX_Data, columnX, directionX});
         return;
     }
     pmdScheduleCoordinatorNoFutureWtX_Data = pmdScheduleCoordinatorNoFutureWtX_Data.reverse();
    directionX = directionX === 'ascending' ? 'descending' : 'ascending';
    this.setState({...this.state, pmdScheduleCoordinatorNoFutureWtX_Data, directionX});
  };

  sendNoFutureAptEmailAction() {
    this.props.sendNoFutureAptMail(this.state.noFutureAptConcatArray,this.state.practicename);
    this.setOpen2(false); 
  };

   sendNoFutureAptWtxEmailAction() {
    this.props.sendNoFutureAptWtxMail(this.state.pmdScheduleCoordinatorNoFutureWtX_Data,this.state.practicename);
    this.setOpen(false); 
  };

  checkValues(){
    return (
        !(
            this.props.apt_created_next_four_days !== null &&
            this.props.apt_created_goal !== null &&
            this.props.no_future_w_tax !== null &&
            this.props.no_future_apt !== null
        ) &&
        <Dimmer active={true}>
          <Loader>Calculating</Loader>
        </Dimmer>
    )
  }

  render() {
    return (
      <>
        {this.checkValues()}
        <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.open}
        onClose={() => this.setOpen(false)}
        onOpen={() => this.setOpen(true)}
      >
        <Modal.Header><b>No Future W/TX</b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
        <Label color={this.state.pmdScheduleCoordinatorNoFutureWtX_Data.length>0?'green':'red'} floating>
        {this.state.pmdScheduleCoordinatorNoFutureWtX_Data.length}
      </Label>
      <div className="ui top right attached label production_right_label">
        <Button onClick={() => this.setOpen(false)} inverted>
          Close
        </Button>
      </div>
      </Modal.Header>
        <Modal.Content scrolling>

      <Table className="table table-striped table-hover my-0 FreezeTable sortable">   
        <Table.Header>
        <Table.Row>
        <Table.HeaderCell><div className="tbl-header">Practice</div></Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnX === 'PatNum' ? this.state.directionX : null}
              onClick={() => this.handleSortWtx('PatNum')}
              ><div className="tbl-header">PatNum</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnX === 'LastVisit' ? this.state.directionX : null}
              onClick={() => this.handleSortWtx('LastVisit')}
              ><div className="tbl-header">Last Visit</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnX === 'Last_Apt' ? this.state.directionX : null}
              onClick={() => this.handleSortWtx('Last_Apt')}
              ><div className="tbl-header">Last Apt</div>
            </Table.HeaderCell>            
            <Table.HeaderCell sorted={this.state.columnX === 'Next_Apt' ? this.state.directionX : null}
              onClick={() => this.handleSortWtx('Next_Apt')}
              ><div className="tbl-header">Next Apt</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnX === 'TxFee' ? this.state.directionX : null}
              onClick={() => this.handleSortWtx('TxFee')}
              ><div className="tbl-header">TxFee</div>
            </Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
            {Object.values(this.state.pmdScheduleCoordinatorNoFutureWtX_Data).map(
            ({ PatNum, LastVisit, Last_Apt, Next_Apt, TxFee}) => {
              return (
              <Table.Row>
                  <Table.Cell>{this.props.practicename}</Table.Cell>
                  <Table.Cell>{PatNum}</Table.Cell>
                  <Table.Cell>{LastVisit}</Table.Cell>
                  <Table.Cell>{Last_Apt}</Table.Cell>
                  <Table.Cell>{Next_Apt}</Table.Cell>
                  <Table.Cell>{TxFee}</Table.Cell>
              </Table.Row>
              );
          }
          )}
        </Table.Body>
      </Table> 
      </Modal.Content>

        <Modal.Actions>
        <Button onClick={() => this.sendNoFutureAptWtxEmailAction()} inverted color='orange'>
          Send Mail
        </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.open2}
        onClose={() => this.setOpen2(false)}
        onOpen={() => this.setOpen2(true)}
      >
        <Modal.Header><b>No Future Apt</b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
        <Label color={(this.state.noFutureAptConcatArray.length)>0?'green':'red'} floating>
        {(this.state.noFutureAptConcatArray.length)}
      </Label>
      <div className="ui top right attached label production_right_label">
        <Button onClick={() => this.setOpen2(false)} inverted>
          Close
        </Button>
      </div>
      </Modal.Header>
        <Modal.Content scrolling>

      <Table className="table table-striped table-hover my-0 FreezeTable sortable">   
        <Table.Header>
        <Table.Row>
            <Table.HeaderCell><div className="tbl-header">Practice</div></Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.column === 'PatNum' ? this.state.direction : null}
              onClick={() => this.handleSort('PatNum')}
              ><div className="tbl-header">PatNum</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.column === 'LastVisit' ? this.state.direction : null}
              onClick={() => this.handleSort('LastVisit')}
              ><div className="tbl-header">Last Visit</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.column === 'Last_Apt' ? this.state.direction : null}
              onClick={() => this.handleSort('Last_Apt')}
              ><div className="tbl-header">Last Apt</div>
            </Table.HeaderCell>            
            <Table.HeaderCell sorted={this.state.column === 'Next_Apt' ? this.state.direction : null}
              onClick={() => this.handleSort('Next_Apt')}
              ><div className="tbl-header">Next Apt</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.column === 'TxFee' ? this.state.direction : null}
              onClick={() => this.handleSort('TxFee')}
              ><div className="tbl-header">TxFee</div>
            </Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
            {Object.values(this.state.noFutureAptConcatArray).map(
            ({ PatNum, LastVisit, Last_Apt, Next_Apt, TxFee}) => {
                return (
                <Table.Row>
                    <Table.Cell>{this.props.practicename}</Table.Cell>
                    <Table.Cell>{PatNum}</Table.Cell>
                    <Table.Cell>{LastVisit}</Table.Cell>
                    <Table.Cell>{Last_Apt}</Table.Cell>
                    <Table.Cell>{Next_Apt}</Table.Cell>
                    <Table.Cell>{TxFee}</Table.Cell>
                </Table.Row>
                );
            }
            )}
        </Table.Body>
      </Table> 
      </Modal.Content>

        <Modal.Actions>
        <Button onClick={() => this.sendNoFutureAptEmailAction()} inverted color='orange'>
          Send Mail
        </Button>
        </Modal.Actions>
      </Modal>

      <Grid columns='two'>
          <Grid.Row>
            <Grid.Column>
              <PMDUnitItem value={this.props.apt_created_next_four_days || 0} description="APT Created Next 4 Days" />
            </Grid.Column>
            <Grid.Column className='pdmitem'><span
              onClick={() =>  this.setOpen(true)}>
              <PMDUnitItemClickable value={this.props.pmdScheduleCoordinatorNoFutureWtX_Data.length} description="No Future w/tx" /> </span>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <PMDUnitItem value="20" description="APT Created Goal" />
            </Grid.Column>
            <Grid.Column className='pdmitem'><span
              onClick={() =>  this.setOpen2(true)}>
              <PMDUnitItemClickable value={this.props.noFutureAptConcatArray.length} description="No Future Apt" /></span>
            </Grid.Column>
          </Grid.Row>
        </Grid></>  
)
  }
}

export default PMDScheduleCoordinator;