import { ActionType } from "../../model/actionTypes";
import {SendReportAction,SendReportOSAction,ReloadBudgetAction,SendReportPVSAction,SendReportPVAAction, OrthoPCRDataModel, OrthoPCRYTDDataModel, OrthoPCRPracticeModel, ReportTN} from "../../model/report";
import {errorHandler} from "./auth";
import {AdjustmentFilters} from "../../model/adjustment";
import {fetchMDMreportHelp} from "./mdm";
import { PCR_KEYS, reportInitialState } from "../../constants";
import moment from "moment";

export const mcoLoginRequest = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	// dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const params = JSON.stringify(data);
		const res=await api.get('report/mco/sendReport?params=' +params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return res;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch(errorHandler(err));
		return []
	}
}


export const patientValidationPracticeLogsReport = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });

	try {
		const response = await api.get(`patientValidation/patientValidationPracticelogsReport`);
		dispatch({ type: ActionType.FETCH_PATIENT_LOGS_REPORT, payload: { patientLogReport: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_PATIENT_LOGS_REPORT, payload: { patientLogReport: [] } });
		return dispatch(errorHandler(err));
	}
};

export const mcoScrapperRun = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const params = JSON.stringify(data);
		await api.get('report/mco/runScrapper?params=' +params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
}


export const fetchReportData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {

	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/bCare${params}`);
		dispatch({ type: ActionType.FETCH_REPORT, payload: { reportData: response } });
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_REPORT, payload: { reportData: [] } });
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return dispatch(errorHandler(err));
	}
};

export const fetchReportNewData = (payload: any, reportName: string) => async (dispatch: Function, _getState: Function, api: any) => {

	dispatch({ type: ActionType.LOADER, payload: true });
	try {

		let params = moment().startOf('month').format("YYYY-MM-DD");
		if (payload) {
			params = payload.month !== "" ? payload.month : params;
		}

		const response = await api.get(`report/allReportData/${params}/${reportName}`);
		dispatch({ type: ActionType.FETCH_REPORT_NEW_DATA, payload: { reportNewData: response }});
		dispatch({ type: ActionType.LOADER, payload: false });
		return {response, type: 'report'};
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_REPORT_NEW_DATA, payload: { reportData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchReportDataSingle = (param:any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	let stringifyParams;
	try {
		let params:any={};
        params.practice = param;
        stringifyParams = '/'+JSON.stringify(params);
		const response = await api.get(`report/bCareSingle${stringifyParams}`);
		dispatch({ type: ActionType.FETCH_REPORT, payload: { reportData: response } });
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_REPORT, payload: { reportData: [] } });
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return dispatch(errorHandler(err));
	}
};

export const fetchReportPerPracticeData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/bCarePerPractice${params}`);
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_REPORT_PER_PRACTICE, payload: { reportPerPracticeData: response } });
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });		
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return dispatch({ type: ActionType.FETCH_REPORT_PER_PRACTICE, payload: { reportPerPracticeData: [] } });
	}
};

export const fetchReportDataLastMonths = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/bCareLastMonths${params}`);
		dispatch({ type: ActionType.FETCH_REPORT_LAST_MONTHS, payload: { reportDataLastMonths: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_REPORT_LAST_MONTHS, payload: { reportDataLastMonths: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchScheduleData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/schedule${params}`);
		dispatch(fetchMDMreportHelp());
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_SCHEDULE, payload: { scheduleData: response } });
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_SCHEDULE, payload: { scheduleData: [] } });
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return dispatch(errorHandler(err));
	}
};

export const fetchBudgetData = (params:string="",practice:string) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const url =`report/budget${params}`+(practice?`?practice=${practice}`:``)
		const response = await api.get(url);
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_BUDGET, payload: { budgetData: response } });
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_BUDGET, payload: { budgetData: [] } });
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return dispatch(errorHandler(err));
	}
};

export const incrementPMD = (count:number) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.INCREMENT, payload: count});
};

export const fetchBudgetDataPerPractice = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/budgetPerPractice${params}`);
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_BUDGET_PER_PRACTICE, payload: { budgetDataPerPractice: response } });
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });		
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return dispatch({ type: ActionType.FETCH_BUDGET_PER_PRACTICE, payload: { budgetDataPerPractice: [] } });
	}
};

export const fetchAptData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/apt${params}`);
		dispatch({ type: ActionType.FETCH_APT, payload: { aptData: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.FETCH_APT, payload: { aptData: [] } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const fetchClinicalScheduleData = (brand:string="", page: number | null = 0, pageSize: number | null = 0) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const response = await api.get(`report/clinicalSchedule/${brand}/${page}/${pageSize}`);
        dispatch({ type: ActionType.FETCH_CLINICAL_SCHEDULE, payload: { clinicalScheduleData: response.data } });
        dispatch({ type: ActionType.FETCH_CLINICAL_SCHEDULE_COUNT, payload: response.rows });
        dispatch({ type: ActionType.LOADER, payload: false });
        return response.data;
    } catch (err) {
			dispatch({ type: ActionType.LOADER, payload: false });
			dispatch({ type: ActionType.FETCH_CLINICAL_SCHEDULE, payload: { clinicalScheduleData: [] } });
		  return dispatch(errorHandler(err));
    }
};

export const sendReportData:SendReportAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        await api.post(`report/sendEmail`, params);
        dispatch({ type: ActionType.LOADER, payload: false });
        return true;
    } catch (err) {
			dispatch({ type: ActionType.LOADER, payload: false });
			return dispatch(errorHandler(err));
    }
};

export const sendEmailWithCsv = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/sendEmailWithCSV`, params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const sendEmailWithAttachments:SendReportAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        await api.post(`report/sendEmailWithAttachments`, params);
        dispatch({ type: ActionType.LOADER, payload: false });
        return true;
    } catch (err) {
			dispatch({ type: ActionType.LOADER, payload: false });
			return dispatch(errorHandler(err));
    }
};

export const sendMailCSR = (practice: string) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const userId = getState().auth.user.id;
		await api.get(`report/sendMailCSR/${practice}/${userId}`);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};
export const sendMailOSR:SendReportOSAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/sendMailOSR`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const sendMailNewOSR:SendReportOSAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/sendMailNewOSR`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const sendMailOSPCRPopupDetail:SendReportOSAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/sendMailOSPCRPopupDetail`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};
export const fetchOSPCRData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get(`report/openSurgeryPCRReports/${params}`);
		dispatch({ type: ActionType.FETCH_OPEN_SURGERY, payload: { oSPCRData: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_OPEN_SURGERY, payload: { oSPCRData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchOSPCRRollUpData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get(`report/openSurgeryPCRRollUpReports/${params}`);
		dispatch({ type: ActionType.FETCH_OPEN_SURGERY, payload: { oSPCRRollUpData: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_OPEN_SURGERY, payload: { oSPCRRollUpData: {} } });
		return dispatch(errorHandler(err));
	}
};
export const fetchUsage = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get(`report/usage/All`);
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const saveUsagePTV = (usage : any) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const resp =  await api.post("report/ptv/saveUsage", usage);
		dispatch({ type: ActionType.SAVE_USAGE_PTV, payload: resp });
		return resp;
	} catch (err) {
		return dispatch(errorHandler(err));
	}
};

export const reRunCron = (cronId : number) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const obj = {
			id : cronId
		}
		const resp =  await api.post("report/cron/rerun", obj);
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const fetchUsagePTV = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get(`report/ptv/usage/All`);
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};
export const fetchUsageByPracticePTV = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get(`report/ptv/usageByPractice/All`);
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};
//WoPTV-----------------------------------------------------------------------------------------
export const fetchUsageWO = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
	let stringifyParams;
	if(params){
		stringifyParams = '/'+JSON.stringify(params)
	}
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get("report/wo/usage"+stringifyParams);
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};


export const hideUsagePTV = (userId: number, hideUsage: number) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.put(`report/ptv/hide/usage`, {userId, hideUsage});
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const flushUsageDataPTV = (userId: number) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.put(`report/ptv/flush/usage`, {userId});
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};






export const hideUsage = (userId: number, hideUsage: number) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.put(`report/hide/usage`, {userId, hideUsage});
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const flushUsageData = (userId: number) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.put(`report/flush/usage`, {userId});
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const reloadBudgetData:ReloadBudgetAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {

		const response = await api.post(`report/budget/osBudget`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const getPatientValidationReport = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	let stringifyParams;
	if(params){
		stringifyParams = JSON.stringify(params)
	}
	try {
		const response = await api.get(`patientValidation/patientValidationActionReport/${stringifyParams}`);
		dispatch({ type: ActionType.FETCH_PATIENT_VALIDATION_REPORT, payload: { patientValidationData: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_PATIENT_VALIDATION_REPORT, payload: { patientValidationData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const sendMailPVS:SendReportPVSAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/sendMailPVS`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const sendMailPVA:SendReportPVAAction = (params) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/sendMailPVA`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const mdhmWriteOffReport = (params: AdjustmentFilters) => async (dispatch: Function, getState: Function, api: any) => {
	let stringifyParams;
	if(params){
		stringifyParams = '/'+JSON.stringify(params)
	}
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.get("report/mdhmReport"+stringifyParams);
		dispatch({ type: ActionType.MDHM_WRITE_OFF_REPORT, payload: { mdhWriteOffReport: resp } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};


export const mdhmWriteOffReportDetails = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
	let stringifyParams;
	if(params){
		stringifyParams = '/'+JSON.stringify(params)
	}
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.get("report/mdhmReportDetails"+stringifyParams);
		dispatch({ type: ActionType.MDHM_WRITE_OFF_REPORT_DETAILS });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};
export const mdhDWriteOffDReport = (params: any) => async (dispatch: Function, getState: Function, api: any) => {

	let stringifyParams;
	if(params){
		stringifyParams = '/'+JSON.stringify(params)
	}
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.get("report/mdhmDReport"+stringifyParams);
		dispatch({ type: ActionType.MDHM_WRITE_OFF_D_REPORT, payload: { mdhWriteOffDReport: resp } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const fetchUserMatrix = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
	let stringifyParams;
	if(params){
		stringifyParams = '/'+JSON.stringify(params)
	}
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get('report/userMatrix'+stringifyParams);
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const fetchBudgetDataPeriodical = (params:any,practice:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	let stringifyParams =params? '/'+JSON.stringify(params):"";
	try {
		const url =`report/budget/selectday${stringifyParams}`+(practice?`?practice=${practice}`:``)
		const response = await api.get(url);
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_BUDGET_PERIODICAL, payload: false});
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_BUDGET_PERIODICAL, payload: false});
		dispatch({ type: ActionType.INCREMENT, payload: 1});
		return dispatch(errorHandler(err));
	}
};

export const sendMailTodaysGoal = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/sendMailTodaysGoal`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const fetchApiList = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp = await api.get(`report/apiList`);
		dispatch({ type: ActionType.LOAD_API_LIST, payload: { apiList: resp } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const getCronList = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp = await api.get(`report/crons`);
		dispatch({ type: ActionType.LOAD_CRONS_LIST, payload: { cronList: resp } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const getRampThresholdDefaults = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api
			.get(`report/rampThresholdDefaults/all`);
		dispatch({ type: ActionType.LOAD_RAMP_THRESHOLD_DEFAULTS, payload: { rampThresholdDefaults: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};


export const updateRampThresholdDefaults = (data:string) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api
			.post(`report/rampThresholdDefaults/update`,data)
			.then(() => {
				const response = api.get(`report/rampThresholdDefaults/all`);
				dispatch({ type: ActionType.LOAD_RAMP_THRESHOLD_DEFAULTS, payload: { rampThresholdDefaults: response } });
				dispatch({ type: ActionType.LOADER, payload: false });
				return response

			})
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const updateDrAppPermissions = (data:[]) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api
			.post(`report/drAppPermissionsMatrix/update`,data)
			.then(async () => {
				await api.get(`report/drAppPermissionsMatrix/all`).then((response:any) =>{
					dispatch({ type: ActionType.LOAD_DR_APP_PERMISSIONS_MATRIX, payload: { drAppPermissionsMatrix: response } });
					dispatch({ type: ActionType.LOADER, payload: false });
					return response
				})


			})
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const getDrAppPermissions = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api
			.get(`report/drAppPermissionsMatrix/all`);
		dispatch({ type: ActionType.LOAD_DR_APP_PERMISSIONS_MATRIX, payload: { drAppPermissionsMatrix: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const updateCronData =  (dataObj:any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/crons`, dataObj);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};



export const getMcoListWithStats = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp = await api.get(`report/mco/stats`);
		dispatch({ type: ActionType.LOAD_API_LIST, payload: { mcoList: resp } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const downloadMembersCSV = (provider: any, type: string) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const params = JSON.stringify({provider, type});
		const resp = await api.get(`report/download/members/`+ params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
}
export const getMcoList = () => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp = await api.get(`report/mco`);
		dispatch({ type: ActionType.LOAD_API_LIST, payload: { mcoList: resp } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const deleteApiList = (id:number) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.delete(`report/apiList`, {id});
		const apiList = getState().report.apiList;
		const filteredapiList = apiList.filter((elem:any)=>elem.id !== id)
		dispatch({type:ActionType.LOAD_API_LIST,payload:{apiList:filteredapiList}})
		dispatch({ type: ActionType.LOADER, payload: false });
		return filteredapiList;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const saveApiList = (listObj:any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/apiList`, listObj);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const sendMailPTGoal = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		await api.post(`report/sendMailPTGoal`,params);
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const fetchReportPerPracticeData_TodayGoalAll = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/bCarePerPractice${params}`);
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_REPORT_TODAY_GOAL_ALL, payload: {} });
		dispatch({ type: ActionType.INCREMENT_TODAY_GOAL_ALL, payload: 1});
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });		
		dispatch({ type: ActionType.INCREMENT_TODAY_GOAL_ALL, payload: 1});
		return dispatch({ type: ActionType.FETCH_REPORT_TODAY_GOAL_ALL, payload: {} });
	}
};

export const setPMDTodayGoalAll = (params:any[]) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		dispatch({ type: ActionType.PMD_TODAY_GOAL_ALL, payload: params });
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.PMD_TODAY_GOAL_ALL, payload: params });
		dispatch({ type: ActionType.LOADER, payload: false });
	}
};

export const fetchShiftsData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`report/shiftsData${params}`);
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_SHIFT, payload: { shiftData: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_SHIFT, payload: { shiftData: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchOrthoPCRData = (month:any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get(`report/orthoData/${JSON.stringify(month)}`);
		const response1=await api.get(`report/orthoDataRaw/${JSON.stringify(month)}`)
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_MTD, payload: { orthoWorkDayMTD: response,scheduleDataRawMTD:response1 } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_MTD, payload: { orthoWorkDayMTD: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchOrthoPCRDataYTD = (month:any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get(`report/orthoDataYTD/${JSON.stringify(month)}`);
		const response1=await api.get(`report/orthoDataRawYTD/${JSON.stringify(month)}`)
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_YTD, payload: { orthoWorkDayYTD: response,scheduleDataRawYTD:response1 } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return response.data;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_YTD, payload: { orthoWorkDayYTD: [] } });
		return dispatch(errorHandler(err));
	}
};

export const getStatusCodes = (paramsEmpty = '') => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	dispatch({ type: ActionType.LOADER_STATUS_CODES, payload: { loaderStatusCodes: false } });
	try {
		let params = {userId:getState().auth.user.id};
		const response = await api.get(`report/orthoCodes/${JSON.stringify(params)}`);
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_CODES, payload: { orthoChangeCodes: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.LOADER_STATUS_CODES, payload: { loaderStatusCodes: true } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_CODES, payload: { orthoChangeCodes: [] } });
		return dispatch(errorHandler(err));
	} 
  };

  export const updateStatusCode = (details:any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		details.userid = getState().auth.user.id;
		if(Array.isArray(details.typeOf)){
			details.typeOf.splice(-1)
			details.typeOf = details.typeOf.join(';')
		}		
		let typeOfSatinised = details.typeOf;
		details.typeOf=typeOfSatinised;
	  const response = await api.put(`report/orthoCodesUpdate`, details);
	  dispatch({ type: ActionType.LOADER, payload: false });
	  dispatch({ type: ActionType.FETCH_ORTHO_PCR_CODES_UPDATE, payload: { } });
	  return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_CODES_UPDATE, payload: { } });
	}
  };

  export const fetchOrthoUDRUserData = (callBackFunc:Function,params:string="",month:any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	dispatch({ type: ActionType.LOADER_MTD, payload: { loaderMTD: true } });
	dispatch({ type: ActionType.LOADER_YTD, payload: { loaderYTD: true } });
	try {
		params = (params) ? "/"+params : "";
		const responseUserUdrData = await api.get(`report/orthoUdrUserData/${getState().auth.user.id}`);
		callBackFunc(responseUserUdrData,month);
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_UDR_USER_DATA, payload: { orthoUDRUserData: responseUserUdrData } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return responseUserUdrData.data;
	} catch (err) {
			dispatch({ type: ActionType.LOADER, payload: false });
			dispatch({ type: ActionType.FETCH_ORTHO_PCR_UDR_USER_DATA, payload: { orthoUDRUserData: {} } });
		if(err&&err.statusCode&&err.statusCode===400){
			return err;
		}else{			
			return dispatch(errorHandler(err));
		}
		
	}
};

export const fetchOrthoUdrOrthoFailedMTDData= (params:any,monthsShowing:any,monthsShowingData:any,allValidMonths:any) => async (dispatch: Function, _getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER_MTD, payload: { loaderMTD: true } });
	try {
		let failedMonthsData:any=[];
		failedMonthsData["0"]=monthsShowingData;
		let failedMonths:string[]=[];
		for (const month of allValidMonths) {
			if(!monthsShowing.includes(month)){
				failedMonths.push(month)
			}     
		}

		for (const month of failedMonths) {
			failedMonthsData[month]=await api.post(`report/orthoUdrOrthoMTDData`, {data: params, month:month});
		}
		
		Object.keys(failedMonthsData).forEach((selectedMonth: string) => {
			if(Object.keys(failedMonthsData[selectedMonth]).includes("production")){
				if(selectedMonth!=="0"){
					monthsShowing.push(selectedMonth);
				}
			}
		});

		const finalData = orthoYTDMerge(failedMonthsData);

		dispatch({ type: ActionType.FETCH_ORTHO_PCR_YTD_KEYS, payload: { orthoYTDKeys: monthsShowing }});
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_UDR_ORTHO_YTD_DATA, payload: { orthoUDROrthoDataYTD: {} } });
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_UDR_ORTHO_YTD_DATA, payload: { orthoUDROrthoDataYTD: finalData } });
		dispatch({ type: ActionType.LOADER_MTD, payload: { loaderMTD: false } });
		return monthsShowingData;
	} catch (err) {
		dispatch({ type: ActionType.LOADER_MTD, payload: { loaderMTD: false } });
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_UDR_ORTHO_MTD_DATA, payload: { orthoUDROrthoDataMTD: {} } });
		return dispatch(errorHandler(err));
	}
};

export const fetchOrthoUdrOrthoYTDData= (params:any,month:any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER_YTD, payload: { loaderYTD: true } });
	try {
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_UDR_ORTHO_YTD_DATA, payload: { orthoUDROrthoDataYTD: null } });		
		const responseUdrOrthoDataYTD = await api.post(`report/orthoUdrOrthoYTDData`, {data: params, month:month});
		const finalData = orthoYTDMerge(responseUdrOrthoDataYTD);
		let monthResponse:string[]=[];
		Object.keys(responseUdrOrthoDataYTD).forEach((selectedMonth: string) => {
			if(Object.keys(responseUdrOrthoDataYTD[selectedMonth]).includes("production")){
				monthResponse.push(selectedMonth);
			}
		});
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_YTD_KEYS, payload: { orthoYTDKeys: monthResponse }});
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_UDR_ORTHO_YTD_DATA, payload: { orthoUDROrthoDataYTD: {} } });
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_UDR_ORTHO_YTD_DATA, payload: { orthoUDROrthoDataYTD: finalData } });

		dispatch({ type: ActionType.LOADER_YTD, payload: { loaderYTD: false } });
		return finalData;
	} catch (err) {
		dispatch({ type: ActionType.LOADER_YTD, payload: { loaderYTD: false } });
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_UDR_ORTHO_YTD_DATA, payload: { orthoUDROrthoDataYTD: {} } });
		return dispatch(errorHandler(err));
	}
};

export const orthoYTDMerge= (ytdData: OrthoPCRYTDDataModel)=> {
	let finalYtdData:OrthoPCRDataModel = {
		production: null,
		adjustment: null,
		sdstart: null,
		start: null,
		sdcontract: null,
		debond: null
		};

		Object.keys(ytdData).forEach((selectedMonth: string) => {
			let orthoPcrDataItem:OrthoPCRDataModel = ytdData[selectedMonth];
			Object.keys(orthoPcrDataItem).forEach((key: string) => {
				switch (key){
					case "production":
						finalYtdData.production=orthoYTDMergeItem(orthoPcrDataItem.production,finalYtdData.production,finalYtdData.production!==null);
						return;
					case "adjustment":
						finalYtdData.adjustment=orthoYTDMergeItem(orthoPcrDataItem.adjustment,finalYtdData.adjustment,finalYtdData.adjustment!==null);
						return;
					case "sdstart":
						finalYtdData.sdstart=orthoYTDMergeItem(orthoPcrDataItem.sdstart,finalYtdData.sdstart,finalYtdData.sdstart!==null);
						return;
					case "start":
						finalYtdData.start=orthoYTDMergeItem(orthoPcrDataItem.start,finalYtdData.start,finalYtdData.start!==null);
						return;
					case "sdcontract":
						finalYtdData.sdcontract=orthoYTDMergeItem(orthoPcrDataItem.sdcontract,finalYtdData.sdcontract,finalYtdData.sdcontract!==null);
						return;
					case "debond":
						finalYtdData.debond=orthoYTDMergeItem(orthoPcrDataItem.debond,finalYtdData.debond,finalYtdData.debond!==null);
						return;
					}
			});
	});
	return finalYtdData;
}

export const orthoYTDMergeItem= (iteratingData:OrthoPCRPracticeModel|null,finalData:OrthoPCRPracticeModel|null,condition:boolean)=> {
	if(condition){
		return orthoYTDMergeIteratePerPractice(iteratingData,finalData);
	}else{
		return iteratingData;
	}
}

export const orthoYTDMergeIteratePerPractice= (iteratingData:any|null,finalData:any|null)=> {
	if(iteratingData!==null && finalData!==null){
			Object.keys(iteratingData).forEach((key: string) => {
					if(finalData[key]!==undefined){
						if (['patients', 'price'].indexOf(key) >= 0){
							finalData[key] = finalData[key] + iteratingData[key];
						}
						if (['orderByDate', 'reportData'].indexOf(key) >= 0){
							finalData[key] = deepMergeFlatten(finalData[key],iteratingData[key]);
						}
						else{
							Object.keys(iteratingData[key]).forEach((item: string) => {
								if((finalData[key][item]!==undefined)){
									if (['debond', 'patients', 'price', 'sdcontract','sdstart','start','totalRecord'].indexOf(item) >= 0){
										finalData[key][item]=finalData[key][item]+iteratingData[key][item]
									}else{
										finalData[key][item] = deepMergeFlatten(finalData[key][item],iteratingData[key][item])
									}									
								}else{
									finalData[key][item]=iteratingData[key][item];
								}
							});							
						}	
						
					}else{
						finalData[key]=iteratingData[key];
					}
			});
	}	
	return finalData;
}

export const deepMergeFlatten=(obj1:any, obj2:any)=> {
	let array1:any =[];
	let array2:any =[];
		if(Object.prototype.toString.call(obj1) === '[object Array]') {
			array1=obj1;
		}else{
			array1=Object.entries(obj1);
		}
		if(Object.prototype.toString.call(obj2) === '[object Array]') {
			array2=obj2;
		}else{
			array2=Object.entries(obj2);
		}
	const nextObj = [ ...array1, ...array2 ]  
	return nextObj
  }

export const fetchOrthoUdrOrthoMTDData= (params:any,month:any) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER_MTD, payload: { loaderMTD: true } });
	try {
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_UDR_ORTHO_MTD_DATA, payload: { orthoUDROrthoDataMTD: null } });
		const responseUdrOrthoDataMTD = await api.post(`report/orthoUdrOrthoMTDData`, {data: params, month:month});
		let monthResponse:string[]=[];
		if(Object.keys(responseUdrOrthoDataMTD).includes("production")){
			monthResponse.push(month);
		}
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_MTD_KEYS, payload: { orthoMTDKeys: monthResponse }});
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_UDR_ORTHO_MTD_DATA, payload: { orthoUDROrthoDataMTD: {} } });
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_UDR_ORTHO_MTD_DATA, payload: { orthoUDROrthoDataMTD: responseUdrOrthoDataMTD } });

		dispatch({ type: ActionType.LOADER_MTD, payload: { loaderMTD: false } });
		return responseUdrOrthoDataMTD;
	} catch (err) {
		dispatch({ type: ActionType.LOADER_MTD, payload: { loaderMTD: false } });
		dispatch({ type: ActionType.FETCH_ORTHO_PCR_UDR_ORTHO_MTD_DATA, payload: { orthoUDROrthoDataMTD: {} } });
		return dispatch(errorHandler(err));
	}
};

export const upDateLoaderYTD=() => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER_YTD, payload: { loaderYTD: false } });
}

export const getMountDate = (params = '') => async (dispatch:Function, getState:Function, api:any) => {
	dispatch({type:ActionType.LOADER_MTD,payload:{loaderMTD:true}})
	try{
		dispatch({type:ActionType.FETCH_MOUNT_DATE,payload:{
			mountDateData:[]
		}})
		const responseMountDate=await api.get(`report/getMountData`)
		dispatch({type:ActionType.FETCH_MOUNT_DATE,payload:{mountDateData:responseMountDate}})
		dispatch({ type: ActionType.LOADER_MTD, payload: { loaderMTD: false } });
		return responseMountDate
	}catch(err){
		dispatch({type:ActionType.LOADER_MTD,payload:{loaderMTD:false}})
	}
  };

export const fetchPTGoalData = (params:string="") => async (dispatch: Function, getState: Function, api: any) => {

	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		params = (params) ? "/"+params : "";
		const response = await api.get(`practiceManagerDashboard/getPTTodayGoalAllPractice${params}`);
		dispatch({ type: ActionType.FETCH_PT, payload: { pTData: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_PT, payload: { pTData: [] } });
		return dispatch(errorHandler(err));
	}
};

/**
 * Manage Splash Board Filter
 */
export const filterByValue = (data: any) => async(dispatch: Function, _getState: Function, _api: any) =>  {
	try {
		await dispatch({ type: ActionType.STORE_FILTER_INFO, payload: { filterByVal: data } });
		return data;
	} catch(err) {
		dispatch({ type: ActionType.STORE_FILTER_INFO, payload: { filterByVal: reportInitialState.filters } });
		return dispatch(errorHandler(err));
	}
};

/**
 * Re Run PCR
 * @param key
 */
export const reRunPCR = (key: string) => async (dispatch: Function, _getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get(`api/pcr/${key}`);
		dispatch({ type: ActionType.RERUN_PATIENT_CARE_REPORT, payload: { pTData: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (error) {
		dispatch({ type: ActionType.RERUN_PATIENT_CARE_REPORT, payload: { filterByVal: reportInitialState.filters } });		
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(error));
	}
};

export const resendOrthoPCRReport = (key: string) => async (dispatch: Function, _getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.post(`api/pcr/autosendOrtho/${key}`,{month:moment().startOf('month').format('YYYY-MM-DD')});
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (error) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(error));
	}
};

/**
 * Re run cron for individual Report
 * @param reportName String
 * @returns status code
 */
export const resendPCRReportCron = (reportName: string) => async (dispatch: Function, _getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get(`api/pcr/${PCR_KEYS}/${reportName}`,{month:moment().startOf('month').format('YYYY-MM-DD')});
		dispatch({ type: ActionType.LOADER, payload: false });
		return response;
	} catch (error) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(error));
	}
};

/**
 * Preserve report data
 * @param data 
 * @returns Persist value
 */
export const reportTimeName = (reportTimeAndName: any, reportName?: string, currDateTime?: string) => (dispatch: Function, _getState: Function, _api: any) =>  {
	try {
		// add current time for report with name
		(reportName && currDateTime) && reportTimeAndName.push({time: currDateTime, name: reportName});

		if (reportTimeAndName.length > 1) {
			reportTimeAndName.shift();
		}

		dispatch({ type: ActionType.PRESERVE_REPORT_TIME_NAME, payload: { reportTimeAndName } });
		return reportTimeAndName;
	} catch(err) {
		dispatch({ type: ActionType.PRESERVE_REPORT_TIME_NAME, payload: { reportTimeAndName: [] } });
		return dispatch(errorHandler(err));
	}
};


export const resetVal = () => (dispatch: Function, _getState: Function, _api: any) =>  {
	dispatch({ type: ActionType.PRESERVE_REPORT_TIME_NAME, payload: { reportTimeAndName: [] } });
	return true;
};

export const resetReportData = () => (dispatch: Function, _getState: Function, _api: any) => {
	dispatch({ type: ActionType.FETCH_REPORT_NEW_DATA, payload: { reportNewData: [] }});
	return;
};