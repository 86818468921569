import React, { useEffect, useState } from 'react';
import {
    AccordionTitle,
    AccordionContent,
    Icon,
    Grid,
    GridRow,
    GridColumn,
    Loader,
    Button,
    Modal
} from 'semantic-ui-react'
import DentaHubChartTable from './DentaHubChartTable';
import * as PracticesActions from '../../redux/actions/practice';
import * as HealthStatusActions from "../../redux/actions/healthStatus";
import ProgressBar from './ProgressBar';
import { HealthScore } from '../../model/healthScore';
import { useActions } from '../../redux/actions';
import moment from 'moment';
import PatientValidationInfoForm from "../../components/PatientValidationForm/PatientValidationInfoForm";
import {enabledCarrierList, myPartners} from '../../constants';
import {User} from "../../model/user";
import McoScrapperForm from "../../components/McoScrapperForm/McoScrapperForm";
import * as Actions from "../../redux/actions/partners";

interface Props {
    data: { records: any[] }
    auth: User
    overalCount: HealthScore
}

function DentaHub({ data, overalCount, auth }: Props) {
    const initialState = { panel: '', tableData: [] as any[], loading: true  };
    const [state, setState] = useState(initialState);
    const healthStatusActions = useActions(HealthStatusActions);
    const practicesActions = useActions(PracticesActions);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMcoScrapperModalOpen, setIsMcoScrapperModalOpen] = useState(false);
    const [isClaimsModalOpen, setIsClaimsModalOpen] = useState(false);
    const [billUrl, setBillUrl] = useState<any>(null);
    const [isLoggedin, setIsLoggedin] = useState(false);

    const [partners, setPartners] = useState({sites: '', schedule: '', alerts: '',recruit:'',leads:'',dentist:'',
        credentialing:'',call_center: "",tools:"", scheduler: "",billing:'',tab32Queries:'',APTOOD:''});
    const [partnerActions] = useActions([Actions]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const openMcoScrapperModal = () => {
        setIsMcoScrapperModalOpen(true);
    };

    const handleIframeLoad = () => {
        if (!isLoggedin) {
            setIsLoggedin(true);
        }
    };

    const openClaimsModal = async () => {
        setIsClaimsModalOpen(true);
        setBillUrl(null);
        let res = await partnerActions.getDentalPartners()
        if (!res?.partners_uri?.billing)
            return alert("Failed to login claims form");
        partnerActions.saveDentalPartnerUsage(myPartners.CLAIMS_TEAM);
        const loginUrl:string = res?.partners_uri?.billing;
        const claimsFormUrl:string = new URL(loginUrl).origin + '/claims-form';
        setBillUrl({ loginUrl, claimsFormUrl });
    }

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const closeMcoScrapperModal = () => {
        setIsMcoScrapperModalOpen(false);
    };

    const closeClaimsModal = () => {
        setIsClaimsModalOpen(false);
    };

    const prefix = 'dentaHubTable';
    const { panel, loading } = state;

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }

    useEffect(() => {
        getPatientValidationData()
    }, [data.records.length > 0])

    const getPatientValidationData = async () => {
        const practices = await practicesActions.getDentaHubPractices();
        const practice = practices.map(({ practice }: any) => practice);
        const dataFilter = {
            locations: practice,
            insurance: enabledCarrierList,
            date: moment().format('YYYY-MM-DD'),
        };

        const carrierList = await healthStatusActions.getCarrierIdentity();
        const validationData = await healthStatusActions.getPatientValidation(dataFilter);

        const records = data.records.map(rec => ({
            ...rec,
            patientCount: setPatientCount(validationData[rec.name] , carrierList),
        }));

        setState(prevState => ({ ...prevState, tableData: records, loading: false }));
    };

 
    const setPatientCount = (patients: any[] , carrierList:any[]) => {
        const data = patients.reduce((acc, item) => {
            let insuranceGroup = carrierList.find(cat =>
                item.CarrierName?.toLowerCase() === cat.carrierName.toLowerCase() ||
                item.CarrierName?.toLowerCase() === cat.carrierName.toLowerCase() ||
                item.EmpName?.toLowerCase() === cat.carrierName.toLowerCase() ||
                item.EmpName?.toLowerCase() === cat.carrierName.toLowerCase());
            if (insuranceGroup) {
                acc[insuranceGroup.modifyCarrierName] = acc[insuranceGroup.modifyCarrierName] || [];
                acc[insuranceGroup.modifyCarrierName].push(item);
            } else {
                acc['others'] = acc['others'] || [];
                acc['others'].push(item);
            }
            return acc;
        }, {});
        return data;
    }

    return (
        <>
            <AccordionTitle active={panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13}>
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Insurance Table</span>
                            {loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={overalCount.failed} pending={overalCount.pending}
                                success={overalCount.success} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === prefix}>
                {auth.user.isAdmin && <Button className="mb5 right" primary={true} onClick={openModal}>Re-Run Patient Validation</Button>}
                {auth.user.isAdmin && <Button className="mb5 right" primary={true} onClick={openMcoScrapperModal}>Re-Run Members</Button>}
                {auth.user.isAdmin && <Button className="mb5 right" primary={true} onClick={openClaimsModal}>Re-Run Claims</Button>}
                <div className="overflowX">
                    {state.tableData.length > 0 && <DentaHubChartTable data={state.tableData}></DentaHubChartTable>}
                </div>
                <h1 className='text-center'> {!(state.tableData.length > 0) &&
                    <Loader active size='mini' inline='centered' />}</h1>
            </AccordionContent>
            <Modal
                open={isModalOpen}
                onClose={closeModal}
                aria-labelledby="modalHeader"
                centered={true}
                closeIcon={true}
                size='large'
            >
                <Modal.Content scrolling={true}>
                    <PatientValidationInfoForm key="health-patient-validation" />
                </Modal.Content>
            </Modal>
            <Modal
                open={isMcoScrapperModalOpen}
                onClose={closeMcoScrapperModal}
                aria-labelledby="modalHeader"
                centered={true}
                closeIcon={true}
                size='large'
            >
                <Modal.Content scrolling={true}>
                    <McoScrapperForm key="health-mco-scrapper" />
                </Modal.Content>
            </Modal>
            <Modal
                open={isClaimsModalOpen}
                onClose={closeClaimsModal}
                aria-labelledby="modalHeader"
                centered={true}
                closeIcon={true}
                size='large'
            >
                <Modal.Content scrolling={true}>
                    {billUrl ? (
                        <iframe
                            src={isLoggedin ? billUrl?.claimsFormUrl : billUrl?.loginUrl}
                            width="100%"
                            height="600px"
                            title="Claims"
                            frameBorder="0"
                            onLoad={handleIframeLoad}
                        >
                        </iframe>
                    ) : (
                        <>
                            <p>Loading...</p>
                        <Loader active size='tiny' inline='centered' />
                        </>
                    )}
                </Modal.Content>
            </Modal>
        </>
    );
}

export default DentaHub;