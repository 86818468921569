import React, { FC, ReactElement, useState,useRef,useEffect } from "react";
import {
  Grid,
  Input,
  Card,
  Button,
  Select,
  Checkbox,
} from "semantic-ui-react";

type FormProps = {
    saveForm: any;
    item: any;
    dropDownOptions: any;
    cancel:any;
    action:any;
    closeForm:any;
  };


const Form: FC<FormProps> = ({
    saveForm,
    item,
    dropDownOptions,
    cancel,
    action,
    closeForm
  }): ReactElement => {
    const [practice, setPractice] = useState(item.practice);
    const [activeInactive, setActiveInactive] = useState(item.status?"active":"inactive");
    const [om, setOm] = useState(item.om?item.om:false);
    const [rm, setRm] = useState(item.rm?item.rm:false);
    const [distMgr, setDistMgr] = useState(item.distMgr ? item.distMgr : false);
    const [others,setOthers]=useState(item.others?item.others:"");
    const input = useRef<Input>(null);    
    useEffect(()=>{
	if(input.current){
		input.current.focus();
	}
	},[])
    const submitForm=()=>{
      let postObject={
        id : item.id,
        om : +om,
        rm : +rm,
        distMgr : +distMgr,
        practice : practice,
        others : others,
        status : activeInactive==="active" ? true : false,
      }
      if (postObject.practice){
      saveForm(postObject,action)
      closeForm(action)
      }else{
        alert('Add practice and missing fields')
      }
    }
    return (
      <>
        <div className=".form-child">
        <Card color='grey'>
        <Grid columns={2} textAlign='left' >
          <Grid.Row>
            <Grid.Column>
            <label> Practice</label>
            </Grid.Column>
            <Grid.Column>
            <Checkbox
              label="ACTIVE/INACTIVE"
         checked={activeInactive==="active"?true:false}
              value={activeInactive}
              onChange={(event,data)=> {
		 let value:string=""
 		 if(activeInactive==="active")
			value="inactive"
		 else if(activeInactive=="inactive")
			value="active"			
                 setActiveInactive(value)
              }}
            />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Select
              placeholder="Select Practice"
              value={practice}
              search={true}
              options={[
                dropDownOptions[0],
                item?{
                  key:item.practice,
                  value:item.practice,
                  text:item.practice
                }:{},...dropDownOptions.slice(1)]}
              onChange={(event, data) => {
                setPractice(data.value as string);
              }}
            />
          </Grid.Row>
         < Grid.Row columns={3}>
         <Grid.Column>
          <Checkbox
            label="Practice Manager"
            checked={om ? true : false}
            value={+om}
            onChange={() => setOm(!om)}
          />
          </Grid.Column>
          <Grid.Column>
          <Checkbox
            label="Regional Manager"
            checked={rm ? true : false}
            value={+rm}
            onChange={() => setRm(!rm)}
          />
          </Grid.Column>
           <Grid.Column>
             <Checkbox
               label="Dist Manager"
               checked={distMgr ? true : false}
               value={+distMgr}
               onChange={() => setDistMgr(!distMgr)}
             />
           </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Input style={{width:"370px"}} ref={input} placeholder="Other"value={others} onChange={(event)=>setOthers(event.target.value)}/>
          </Grid.Row>
          <Grid.Row>
          <Grid.Column>
          <Button color='blue' onClick={(event)=>{
            event.preventDefault()
            submitForm()
          }}>Save</Button>
          </Grid.Column>
          <Grid.Column>
          <Button color='blue' onClick={()=>cancel(false)}>Cancel</Button>
          </Grid.Column>
          </Grid.Row>
        </Grid>
        </Card>
        </div>
      </>
    );
  };
  export default Form;