import React, {useEffect, useState} from "react";
import Template from "../../components/Template";
import {useActions} from "../../redux/actions";
import * as reportActions from "../../redux/actions/report";
import {App, RootState} from "../../model";
import {connect} from "react-redux";
import * as practiceActions from "../../redux/actions/practice";
import * as patientValidationActions from "../../redux/actions/patients";
import {DropDownOptions, Practice, Regions} from "../../model/practice";
import {
    loginPracticeReportField,
    ManagerDetails,
    practiceReportField,
    Report,
} from '../../model/report'
import {Dropdown, Icon} from "semantic-ui-react";
import {brands, csvPatientValidationPracticeLogsReport, enabledCarrierList} from '../../constants'
import {Patients} from "../../model/patients";
import HelpMark from "../../components/HelpMark";
import PatientPracticeReportFieldsModal
    from "../../components/Reports/PatientPracticeReportFieldsModal";
import ManagerInfoModal from "../../components/Reports/ManagerInfoModal";
import {
    exportPatientValidationPracticeLogsReport,
    exportPatientValidationPracticeLogsReportData
} from "../../components/ExportCsv";
import ReactDOMServer from "react-dom/server";
import DynamicHtmlTableMail from "../../components/DynamicHtmlTableMail";


interface Props {
    report: Report,
    practice: Practice,
    app: App,
    patients: Patients,
}


function PatientPracticeLogsReport(props: Props) {
    const insuranceCategoryOptions: Array<object> = [{key: 'none', value: null, text: 'Select Insurance'}];
    const initialState: any = {
        filters: {
            practice: [],
            insurance: '',
            practiceOptions: [],
            insuranceCategoryOptions: insuranceCategoryOptions,
            aptStatus: '',
            region: [],
            brands: []
        },
        report: [
            {
                practice: '',
                Date: '',
                users: [
                    {
                        userId: 0,
                        email: '',
                        firstName: '',
                        lastName: '',
                        udaRole: '',
                        deviceOS: '',
                        total: 0,
                        Desktop: '',
                        Cell: '',
                        Date: '',
                    }
                ]
            }
        ],
        tableData: [],
        column: "all",
        direction: "sort",
        color: '',
    };
    const [state, setState] = useState(initialState);
    const [carrierInfo, setCarrier] = useState([]);
    const [practiceInfoModalOpen, setPracticeInfoModalOpen] = React.useState(false);
    const [managerInfoModalOpen, setManagerInfoModalOpen] = React.useState(false);
    const [modalPractice, setModalPractice] = React.useState('');
    const [selectedRegionalManager, setSelectedRegionalManager] = useState('');
    const [regionalManagers, setRegionalManagers] = useState<string[]>([]);
    const [practiceInfoModalData, setPracticeInfoModalData] = React.useState([{
        userId: 0,
        email: '',
        firstName: '',
        lastName: '',
        udaRole: '',
        deviceOS: '',
        total: 0,
        Desktop: '',
        Cell: '',
        Date: '',
    }]);
    const [managerInfoModalData, setManagerInfoModalData] = React.useState({
        email: "",
        firstName: "",
        lastName: "",
        cellNumber: ""
    });

    enabledCarrierList && enabledCarrierList.forEach((item: any, index: number) => {
        insuranceCategoryOptions.push(
            {text: item, value: item, key: index}
        )
    });

    const [selectedBrands, setSelectedBrands] = useState([])
    let timeout: any = 0;

    const patientValidationAction = useActions(patientValidationActions);
    const practiceAction = useActions(practiceActions);
    const reportAction = useActions(reportActions);

    const refinePracticeArray = (response: []) => {
        let practiceAbbr: { [key: string]: string } = {};
        let practiceData: { [key: string]: object } = {};
        let practiceDataInfo: { [key: string]: object } = {};
        let practiceOptions: Array<{ key: string, value: null | string, text: string }> =
            [{key: 'none', value: 'all', text: 'Select Practice'}];
        if (response) {
            response.forEach((item: { id: number, practice: string, practiceabbr: string, practiceDBName: string }) => {
                practiceAbbr[item.practice.toLowerCase()] = item.practiceabbr;
                practiceOptions.push({
                    text: item.practice,
                    value: item.practice.toLowerCase(),
                    key: item.id.toString()
                });
                practiceData[item.practice.toLowerCase()] = {
                    practice: item.practice,
                    practiceDBName: item.practiceDBName
                }
                practiceDataInfo[item.practiceDBName.toLowerCase()] = {
                    practice: item.practice,
                }
            })
        }
        const practice = {practiceAbbr: practiceAbbr, practiceOptions: practiceOptions, practiceData};
        const filters = Object.assign(state.filters, {practiceOptions: practiceOptions});
        setState({...state, filters});
        return practice
    };

    useEffect(() => {
        const promiseArr = [];
        practiceAction.fetchPracticeUda();
        promiseArr.push(practiceAction.fetchRegions(refineRegionsArray));
        practiceAction.fetchPractices(refinePracticeArray);
        patientValidationAction.getCarrierIdentity().then((data: any) => {
            setCarrier(data);
            let filters = state.filters;
            filters.practice = ['all'];
            filters.brands = ['SMD', 'FSD', 'OTD', '3CD','SYN'];
            reportAction.fetchUsageByPracticePTV().then((report: {
                data: loginPracticeReportField[],
                practices: any
            }) => {
                const managers = report.data.map((item) => item.regMgr.firstName + ' ' + item.regMgr.lastName);
                setRegionalManagers(Array.from(new Set(managers))); //CDP-7322 to remove duplicates and update/initialize state
                const filteredData = initialFilterByBrand(filters.brands, report.data, report.practices);

                //Place the (All) practice on top of list
                const findPrac: any = filteredData.find((prac: any) => prac.practice.toLowerCase() === 'all')
                const foundIdx = filteredData.findIndex((el: any) => el == findPrac)
                filteredData.splice(foundIdx, 1)
                filteredData.unshift(findPrac)

                setState({...state, report: report.data, tableData: filteredData, filters});
            })
        });
    }, []);


    const refineRegionsArray = (regionList: Regions[]) => {
        let regionListOptions: DropDownOptions[] = [{key: '0', value: 0, text: 'Select Region'}];
        if (regionList.length) {
            regionList.forEach(item => {
                regionListOptions.push({text: item.name, value: item.id, key: item.id})
            });
        }
        return regionListOptions;
    };


    const getTableHeadings = () => {
        return (<thead>
        <tr key="firstHead">
            <th>Last Login Date</th>
            <th>Practice</th>
            <th>Office Manager</th>
            <th>Region Manager</th>
        </tr>
        </thead>)
    };


    function handleModalClose() {
        setPracticeInfoModalOpen(false);
        setManagerInfoModalOpen(false);
    }

    function handlePracticeUsersInfoAddClick(users: practiceReportField[], practice: string) {
        setPracticeInfoModalData(users);
        setModalPractice(practice)
        setPracticeInfoModalOpen(users.length > 0);
    }
    function handleManagerInfoAddClick(manager: ManagerDetails, practice: string) {
        setManagerInfoModalData(manager);
        setModalPractice(practice)
        setManagerInfoModalOpen(manager !== undefined);
    }

    const getTableRows = () => {

        const uniquePractices: {
            users: practiceReportField[];
            practice: string; date: string;
            mgr: ManagerDetails;
            regMgr: ManagerDetails;
        }[] = [];

        //this is to prevent memory leaks
        state.tableData.forEach((item: any) => {
            const practice = item.practice;
            const date = item.Date;
            const users = item.users;
            const mgr = item.mgr;
            const mgrRegion = item.regMgr;
            const exists = uniquePractices.some(
                (item) => item.practice === practice && item.date === date
            );
            if (!exists) {
                uniquePractices.push({practice, date, users: users, mgr: mgr, regMgr: mgrRegion});
            }
        });

        if (uniquePractices.length > 0 && state.tableData.length > 0) {
            let returnArrayData: React.ReactElement[] = [];
            let index = 1; //for unique key for date column
            for (const unique of uniquePractices) {
                returnArrayData.push(<tr key={index}>
                    <td key={unique.date + 'info' + index}>{unique.date}</td>
                    <td
                        key={unique.practice + 'info'}
                        onClick={() => handlePracticeUsersInfoAddClick(unique.users, unique.practice)}
                        className={'cursorPointer'}
                    >
                        {unique.practice}
                    </td>
                    <td
                        key={'info_manager' + index}
                        onClick={() => handleManagerInfoAddClick(unique.mgr, unique.practice + ' Office ')}
                        className={'cursorPointer'}
                    >
                        <div>{unique.mgr.firstName} {unique.mgr.lastName}</div>
                    </td>
                    <td
                        key={'info_reg_manager' + index}
                        onClick={() => handleManagerInfoAddClick(unique.regMgr, unique.practice + ' Region ')}
                        className={'cursorPointer'}
                    >
                        <div>{unique.regMgr.firstName} {unique.regMgr.lastName}</div>
                    </td>
                </tr>);
                index++;
            }

            return <tbody>{returnArrayData}</tbody>;
        }
        return <tbody>
        <tr>
            <td>No Data</td>
        </tr>
        </tbody>;

    };
    const initialFilterByBrand = (brands: any, data: loginPracticeReportField[], practicing: any) => {
        setSelectedBrands(brands)
        const practices = practicing.filter((prac: any) => brands.includes(prac.brand)).map((prac: any) => prac.practiceName)

        practices.unshift('all') //to always include the (all) practice

        return practices.length ? data.filter((data: loginPracticeReportField) => practices.includes(data.practice)) : data;
    }
    const onChangeBrandsMultiple = (e: any, {value}: any) => {
        const practiceNamesMap: any = props.practice.practiceNamesMap;
        if (selectedBrands.length > value.length) {
            selectedBrands.filter(
                x => !value.includes(x)
            )
        }
        setSelectedBrands(value)
        const practices = Object.values(practiceNamesMap).filter((prac: any) => value.includes(prac.brand)).map((prac: any) => prac.practice)
        practices.unshift('all') //to always include the (all) practice
        const tableData = practices.length ? state.report.filter((data: any) => practices.includes(data.practice)) : state.report

        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(() => {
            setState({...state, tableData: tableData, filters: {practice: practices, brands: value}})
        }, 500)
    }

    const onChangeRegionalManager = (e: any, { value }: any) => {
        setSelectedRegionalManager(value);

        // Perform the filtering logic based on the selected regional manager
        const filteredData = value
            ? state.report.filter((item: loginPracticeReportField) => item.regMgr.firstName + ' ' + item.regMgr.lastName === value)
            : state.report;

        // Update the table data with the filtered results
        setState({ ...state, tableData: filteredData });
    };


    function exportReportCSV() {
        exportPatientValidationPracticeLogsReport(state.tableData)
    }

    function sendReportEmail() {
        if(state && state.tableData && Object.keys(state.tableData).length) {
            const data = exportPatientValidationPracticeLogsReportData(state.tableData);

            const tableData = state.tableData.map((reportField: loginPracticeReportField) => {
                return [
                    { title: reportField.Date },
                    { title: reportField.practice },
                    { title: `${reportField.mgr.firstName} ${reportField.mgr.lastName}` },
                    { title: `${reportField.regMgr.firstName} ${reportField.regMgr.lastName}` },
                ];
            });

            const pdf = ReactDOMServer.renderToString(
                <DynamicHtmlTableMail
                    key={new Date().toISOString()}
                    className={`by-age`}
                    tableHead={csvPatientValidationPracticeLogsReport.map(item => ({ title: item }))}
                    heading={"Patient Validation Practice Logs Reports"}
                    tableData={tableData}
                />);

            reportAction.sendEmailWithAttachments({page: 'Patient Validation Practice Logs Reports', data: data, pdf: pdf });
        }
    }

    return (
        <Template activeLink='patient-validation-practice-logs-report'>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">
                        Patient Validation Practice Logs Reports <HelpMark pageId='0'/>
                    </h2>
                    <a className="link" onClick={()=> exportReportCSV()}><Icon name="file alternate"/>Export</a>
                    <a className="link" onClick={() => sendReportEmail()}><Icon name="send" />Send</a>
                    <Dropdown
                        search={true}
                        className='mr10 mb15 float-right'
                        name="brands"
                        placeholder="Brands"
                        selection={true}
                        multiple={true}
                        options={brands}
                        onChange={onChangeBrandsMultiple}
                        value={selectedBrands}
                    />
                    <Dropdown
                        search={true}
                        className='mr10 mb15 float-right'
                        selection={true}
                        clearable={true}
                        options={regionalManagers.map((manager) => ({ key: manager, value: manager, text: manager }))}
                        placeholder="Filter by Regional Manager"
                        value={selectedRegionalManager}
                        onChange={onChangeRegionalManager}
                    />
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    <div className="table-responsive FreezeTable">
                        <table className="ui celled single line table unstackable tableStyle PVS">
                            {getTableHeadings()}
                            {getTableRows()}
                        </table>
                    </div>
                </div>
            </div>
            {practiceInfoModalOpen && <PatientPracticeReportFieldsModal
                modalOpen={practiceInfoModalOpen}
                modalData={practiceInfoModalData}
                handleModalClose={handleModalClose}
                practice={modalPractice}
            />}
            {managerInfoModalOpen && <ManagerInfoModal
                modalOpen={managerInfoModalOpen}
                modalData={managerInfoModalData}
                handleModalClose={handleModalClose}
                practice={modalPractice}
            />}
        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app,
        patients: state.patients
    };
}

export default connect(mapStateToProps)(PatientPracticeLogsReport);

