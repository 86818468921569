import React, { useEffect, useState } from "react";
import { useActions } from "../../redux/actions";
import * as Actions from "../../redux/actions/users";
import {Button, Checkbox, Form, Modal} from "semantic-ui-react";
import * as permissionsActions from '../../redux/actions/permissions';
import { permissions, permissionsOptions, queueOptions, typeOptions,schedulePermissions } from "./constants";
import {updateUserInfo} from "../../redux/actions/users";
import * as UKGActions from "../../redux/actions/UKGServices";
import Loading from "../../Loading";
import * as UserActions from "../../redux/actions/userSettings";
import * as spotActions from "../../redux/actions/spot";
import { SetupFields } from "../../enums/userSetupField";
import { SetupAppName } from "../../enums/userSetupAppName";

interface props {
    onClose: (view: boolean) => void,
    onSave: () => void,
    view: boolean,
    user: any,
    practices: { options: any[], all: any[] },
}

const EditUser = (props: props) => {
    const initialState = {
        defaultPractices: "",
        loader: true,
        user: {} as any,
        udrPermissionOptions: [] as any[],
        notifyGroups: [] as any[],
        practiceOptions: [] as any[],
        ukgProfiles: [] as any[],
        ukgRole: "",
        ukgPractice: "",
        ukgId: ""
    }

    const [state, setState] = useState(initialState);
    const [showOdUsername, setShowOdUsernam] = useState(false);
    const permissionsAction = useActions(permissionsActions);
    const spotAction = useActions(spotActions);
    const usersActions = useActions(Actions);
    const userSettingsActions = useActions(UserActions);
    const ukgActions = useActions(UKGActions);

    useEffect(() => {
        const { user } = props;
        const setData = async () => {

            const params = { field: SetupFields.Practice, appId: SetupAppName.CDP_MY, userId: user.id };
            const result = await Promise.all([
                await permissionsAction.getUdrPermissions(), 
                await permissionsAction.getNotificationGroups(),
                await spotAction.fetchPractices(),
                await userSettingsActions.getUserSetup(params)
            ]);

            const defaultPractices = result[3] ? result[3].value.split(",") : [];
            const udrPermissionOptions = result[0].map((m: any) => { return { text: m.role, value: m.id, key: m.id } });
            const notifyGroups = result[1].map((m: any) => { return { text: m.group, value: m.id.toString(), key: m.id } });
            const practiceOptions = result[2].map((p: any) => { return { text: p.practice, value: p.practiceDBName, key: p.id } });
            const udaRole = user?.udrUsersPermissions?.map((perms: any) => perms?.roleid);

            let practiceIds = getPracticeValue(user.defaultLocationId);
            const practices = props.practices.options
                .filter((practice: any) => practiceIds.includes(practice.value))
                .map((a: any) => a.key).join(';');
            const permissions = user.permissions.split(',')?.map((s: string) => s?.trim());

            if(permissions.includes('callcenter')) setShowOdUsernam(true);

            let positionData;
            if (user.firstname && user.firstname.trim() !== '') {
                positionData = await ukgActions.getPositionDetails(user.firstname);
            } else {
                positionData = await ukgActions.getPositionDetails(user.username ? user.username : ' ');
            }

            let ukgRole = '';
            let ukgPractice = '';
            let ukgId = '';
            if (positionData?.data?.length > 0) {
                const matchingProfile = positionData.data.find(
                    (position: any) => position.employee_id === user.ukgId
                );
                if (matchingProfile) {
                    ukgRole = matchingProfile.position_name;
                    ukgPractice = matchingProfile.display_name;
                    ukgId = matchingProfile.employee_id;
                }
            }
    
            setState({
                ...state,
                defaultPractices,
                loader:false,
                practiceOptions,
                user: { ...user, permissions, udaRole, practices },
                udrPermissionOptions,
                notifyGroups,
                ukgProfiles: positionData?.data ?? [],
                ukgRole,
                ukgPractice,
                ukgId
            });
        };
    
        setData();
    }, []);

    const getUKGProfiles = async (firstName: string, lastName: string) => {
        setState({ ...state, loader: true });
        const positionData = await ukgActions.getPositionDetails(`${firstName}`);
        setState({ ...state, ukgProfiles: positionData?.data ?? null,loader: false });
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        field: string
      ) => {
        const user = state.user;
        user[field] = e.target.value;
        setState({...state, user})
    };

    const onSelectDefaultPracticeChange = (value: any) => {
        setState({...state, defaultPractices: value});
    };
    
    const onSelectUKGPracticeChange = (value: any) => {
        console.log("selected option ukg")
        console.dir(value)
        const selectedOption = state.ukgProfiles.find(
          (option) => option.employee_id === value
        );
        setState((prevState) => ({
          ...prevState,
          ukgRole: selectedOption?.position_name || "",
          ukgPractice: selectedOption?.display_name || "",
          ukgId: selectedOption?.employee_id || "",
        }));
    };

    const onSelectChange = (value: any, name: string) => {
        const { user } = state;
        const permission = { ...user.permission, [name]: value }
        if(permission.permissions&&permission.permissions.includes('callcenter')) {
            setShowOdUsernam(true)
        } else {
            setShowOdUsernam(false)
        }
        setState({ ...state, user: { ...user, ...permission, permission } });
    }


    const onSelectPracticeChange = (data: any) => {
        let { user } = state;
        let permission:any ={};
        if(data.value.length>20 || data.value.includes('All')) {
            const [, ...practices] = data.options.map((a:any)=>a.value);
            user.defaultLocationId = practices.join(';')
            permission.practice = data.options.map((a:any)=>a.value)[0];
            user.practices = data.options.map((a:any)=>a.value)[0];
        }
        else {
            user.defaultLocationId = data.value.join(';')
            const selectedPracticeKeys = data.options
                .filter((practice: any) => data.value.includes(practice.value))
                .map((a: any) => a.key);
            permission.practice = selectedPracticeKeys.join(';');
            user.practices = selectedPracticeKeys.join(';');
        }
        setState({ ...state, user: { ...user, ...permission } });
    }

    const onSelectNotificationChange = (value: any) => {
        let { user } = state;
        user.notificationPermission = value.join(';');
        setState({ ...state, user: { ...user } });
    }

    const saveDetails = async () => {
        setState({ ...state, loader: true });
        let user = state.user;
        const selectedPermissions: string[] = user.permissions;
        const perms: any = permissions.reduce((o, key) => ({ ...o, [key.value]: selectedPermissions.includes(key.value) ? 1 : 0 }), {});
        ['createdAt', 'updatedAt', 'udrUsersPermissions', 'udrPermissions','types',].forEach(key => delete user[key]);
        const details = { ...user, permissions: { ...perms } };
        const setup = {
            appId: SetupAppName.CDP_MY,
            userId: user.id,
            field: SetupFields.Practice,
            value: state.defaultPractices.toString(),
        };

        await Promise.all([
            await usersActions.updateUserInfo({user: details}),
            await userSettingsActions.createOrUpdateSetup(setup)
        ]);

        props.onSave();
    }

    const updateUkgId = async () => {
        setState({ ...state, loader: true });
        const ukgId = await usersActions.syncUkgs(state.user.email);
        setState({...state,loader: false, user: {...state.user, ukgId}});
        props.onSave();
    }

    const getPracticeValue =(locationId:any)=>{
        let practices = locationId? locationId?.split(';'):[]
        if(practices.length>20 || practices.includes('All'))
            return ['All'];
        return practices
    }

    const handleCheckboxChange = (e: any, {name, checked}: any) => {
        const { user } = state;
        setState({...state, user: { ...user, [name]: checked } });
    };

    const setForm = () => {
        const {user, loader,ukgRole,ukgPractice,ukgId} = state;
        if(loader){
            return <><Loading /></>
        }else{
            return <>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Input fluid label='First name' placeholder='First name' value={user.firstname}
                                onChange={(e) => handleChange(e, 'firstname')}/>
                    <Form.Input fluid label='Last name' placeholder='Last name' value={user.lastname}
                                onChange={(e) => handleChange(e, 'lastname')}/>
                    <Form.Input fluid label='Username' placeholder='Username' value={user.username}
                                onChange={(e) => handleChange(e, 'username')}/>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Select
                        label="UKG Profiles"
                        options={state.ukgProfiles.map((option) => ({
                            key: option.employee_id,
                            text: `${option.account_name}`,
                            value: option.employee_id,
                        }))}
                        placeholder="UKG Profiles"
                        value={state.ukgProfiles.find(
                            (option) => option.employee_id === ukgId
                        )?.employee_id}
                        onChange={(_e, data) => onSelectUKGPracticeChange(data.value)}
                        onSearchChange={(e, { searchQuery }) =>
                            getUKGProfiles(user.firstname, user.lastname)
                        }
                    />
                    <Form.Input
                        fluid
                        label="UKG Role"
                        placeholder="UKG Role"
                        value={ukgRole}
                        disabled={true}
                    />
                    <Form.Input
                        fluid
                        label="UKG Practice"
                        placeholder="UKG Practice"
                        value={ukgPractice}
                        disabled={true}
                    />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input fluid type="number" label='UKG ID' placeholder='UKG ID' value={ukgId} disabled={true}
                                onChange={(e) => handleChange(e, 'ukgId')}/>
                    <Form.Input type='password' autocomplete="off" name='password' fluid label='Password' placeholder='Password'
                                value={user?.password} disabled={false}
                                onChange={(e) => handleChange(e, 'password')}/>
                    <Form.Input fluid label='Email' placeholder='email' value={user.email} disabled={true}
                                onChange={(e) => handleChange(e, 'email')}/>                    
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input fluid label='Educator Manager' placeholder='Educator manager' value={user.manager}
                                onChange={(e) => handleChange(e, 'manager')}/>
                    <Form.Input fluid label='Regional manager' placeholder='Regional manager'
                                value={user.regionalmanager} onChange={(e) => handleChange(e, 'regionalmanager')}/>
                     <Form.Select fluid label='Schedule role'  options={schedulePermissions}
                                 onChange={(_e, data) => onSelectChange(data.value, 'srole')} placeholder='Schedule Role'
                                 value={user.srole}/>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Input fluid label='Copy manager' placeholder='Copy manager' value={user.specialmanager}
                                onChange={(e) => handleChange(e, 'specialmanager')}/>
                    <Form.Select fluid label='Queue' options={queueOptions} placeholder='Queue' value={user.queue}
                                 onChange={(_e, data) => onSelectChange(data.value, 'queue')}/>
                    <Form.Select fluid label='Type' options={typeOptions} placeholder='Type' value={user.types}
                                 onChange={(_e, data) => onSelectChange(data.value, 'types')}/>
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Select label='Practices' multiple options={props.practices.options} placeholder='practices'
                                 value={getPracticeValue(user.defaultLocationId)}
                                 onChange={(_e, data) => onSelectPracticeChange(data)}/>
                    <Form.Select fluid label='Notification' multiple placeholder='Notification'
                                 options={state.notifyGroups}
                                 value={user?.notificationPermission ? user?.notificationPermission.split(';') : []}
                                 onChange={(_e, data) => onSelectNotificationChange(data.value)}/>
                    <Form.Select fluid label='Uda role' multiple options={state.udrPermissionOptions}
                                 onChange={(_e, data) => onSelectChange(data.value, 'udaRole')} placeholder='Uda roles'
                                 value={user?.udaRole}/>
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Select fluid label='Permissions' multiple options={permissionsOptions}
                                 placeholder='Permissions' value={user?.permissions? user?.permissions:[]}
                                 onChange={(_e, data) => onSelectChange(data.value, 'permissions')}/>
                    <Form.Select 
                        fluid label='Default Practice' 
                        multiple 
                        search
                        options={state.practiceOptions}
                        placeholder='Default Practice' 
                        value={state.defaultPractices}
                        onChange={(_e, data) => onSelectDefaultPracticeChange(data.value)}
                    />
                </Form.Group>
                {
                    showOdUsername &&
                    <Form.Group widths='3'>
                        <Form.Input fluid label='OD Username' placeholder='OD Username' value={user.odUserName}
                                    onChange={(e) => handleChange(e, 'odUserName')}/>
                    </Form.Group>
                }
                <Form.Group>
                    <Form.Field>
                        <label>SPOT Export</label>
                        <Checkbox
                            name="spotExport"
                            toggle={true}
                            checked={state.user.spotExport}
                            onChange={handleCheckboxChange}
                        />
                    </Form.Field>
                </Form.Group>

            </Form>
        </>
        }
        
    }

    return (
        <Modal onClose={() => props.onClose(false)} onOpen={() => props.onClose(true)} open={props.view}>
            <Modal.Header>Edit User</Modal.Header>
            <Modal.Content  style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                {setForm()}
            </Modal.Content>
            <Modal.Actions>
                <Button primary loading={state.loader} onClick={() => saveDetails()}>Save</Button>
                <Button secondary={true} content='Update UKG ID' onClick={() => updateUkgId()}/>
                <Button content='Close' onClick={() => props.onClose(false)}/>
            </Modal.Actions>
        </Modal>
    );
};

export default EditUser
