import React, {useEffect, useState} from 'react';
import Template from '../../components/Template';
import {Practice} from "../../model/practice";
import {App, RootState} from "../../model";
import {connect} from 'react-redux';
import {Icon, Table} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import {isSafari, OSPracticesReportTypes} from "../../constants";
import DynamicReferralDataTable from "../../components/DynamicReferralDataTable";
import {useActions} from "../../redux/actions";
import * as practiceActions from "../../redux/actions/practice";
import moment from "moment";
import ReactDOMServer from "react-dom/server";
import * as referralsActions from "../../redux/actions/referrals";
import * as specialityPCOActions from "../../redux/actions/specialityPCO";

interface Props {
    practice: Practice,
    app: App,
}

function ReferralDataOsPractices(props: Props) {
    const practiceAction = useActions(practiceActions);
    const referralsAction = useActions(referralsActions);
    const specialityPCOAction = useActions(specialityPCOActions);
    const [filterDate, setFilterDate] = useState(new Date(moment().startOf('month').toString()));
    const [reportData, setReportData] = useState([]);

    const params = {
        startDate: moment(filterDate).startOf('month').format('YYYY-MM-DD'),
        endDate: moment(filterDate).endOf('month').format('YYYY-MM-DD')
    };

    useEffect(() => {
        practiceAction.fetchPractices()
    }, [filterDate]);
    
    const buildReport = (type: any, date: any) => {
        return (
            <div className="ui card">
                <div className="content">
                      <DynamicReferralDataTable
                        practices={props.practice.practiceNamesMap}
                        date={date}
                        setReportData={setReportData}
                        reportData={reportData}
                    />
                </div>
            </div>
        )
    }
    const headers=[
        "Practices",
        "# of OS Days",
        "Total PT. Count",
        "Total Patient count age 15+",
        "# of OS referrals",
        "Referral % (patients age 15 and up)",
        "% of Referred Patients Scheduled",
        "% of Scheduled Patients that Arrived",
        "% of Patients not receiving care",
        "% of Referrals receiving care"
    ];

    const  colmuns=["osDays","totalPTCount","totalPTCount15AndUp","osReferrals","perc15Up","percPatientScheduled","percPatientsArrived","patientsNotReceivingCare","percPatientReceivingCare"]

    const  getCSV=()=>{
        let data :any =reportData;
        const practices:any=props.practice.practiceNamesMap;
        const tableHeader = `${headers.map(header => header.toUpperCase()).join(',')}`;
        let returnArray: any = [];
        if (data) {
            Object.values(practices).filter((practice: any, key) =>{
                if(data[practice.practiceDBName] ){
                    let str=practice.practice+","
                    colmuns.map((k, i) =>{
                        str+=data[practice.practiceDBName][k];
                        str+=i==colmuns.length-1?"\n":","
                    })
                    returnArray.push(str)
                }
            })
        }
        const body=returnArray.join('\n');
        const csv = `${tableHeader}\n${body}`;
        return csv;
    }

    const exportCSV = (fileName:string, csvData:any) => {
        const dataType = isSafari() ? 'application/csv' : 'text/csv';
        const blob = new Blob(['', csvData], {type: dataType});
        const url = (window.URL || window.webkitURL).createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    };

    const getPDF=()=>{
        const practices:any=props.practice.practiceNamesMap
        let data :any =reportData;
        let tableData =( <Table>
            <Table.Header>
                <Table.Row>
                    {headers.map((header, i) => <Table.HeaderCell
                        key={'header_modal_' + i}
                    >
                    <span >
                        {header}
                    </span>
                    </Table.HeaderCell>)}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {
                    Object.values(practices).filter((practice: any, key) => data[practice.practiceDBName])
                        .map((practice:any,key)=>
                        <Table.Row key={'modalRow' + key}>
                            <Table.Cell key={ + 'column-p' + key}>{practice.practice}</Table.Cell>
                            {
                                colmuns.map((value: any, i: number) => <Table.Cell
                                    key={i + 'column' + key}>{data[practice.practiceDBName][value]}</Table.Cell>)
                            }
                        </Table.Row>)
                }
            </Table.Body>
        </Table>)

        const pdf = ReactDOMServer.renderToString(<div>
            <h3 style={{textAlign: "center"}}>Speciality PCO</h3>
            <br/>
            {tableData}
        </div>);
        return pdf;
    }
    const sendEmail = () => {
        const csv = getCSV()

        const pdf = getPDF();

        specialityPCOAction.sendMail({csv, pdf});
    }

    return (
        <Template  activeLink="Oral Surgery Dashboard Patient Care Report">
            <div className="ui card">
                <div className="content filterContainer">
                    <h2 className="float-left mr10">Referral Data OS Practices</h2>
                    <div className="float-right referralsFilters">
                        <a className="mr30" onClick={() => sendEmail()}><Icon name="send"/> Send</a>
                        <a className="mr30" onClick={() => exportCSV('Referral Data OS Practices.csv', getCSV())}>
                            <Icon name="file alternate" /> Export
                        </a>
                        <div className="month-year-picker">
                            <DatePicker
                                name="date"
                                className='ui reports-custom-date-input cursorPointer'
                                dateFormat="MMM yyyy"
                                showMonthYearPicker={true}
                                onChange={(date:any) => setFilterDate(date)}
                                selected={filterDate}
                                placeholderText={'Select Month'}
                            />
                            <Icon className="calendar-icon cursorPointer" name="calendar alternate outline" />
                        </div>
                    </div>
                </div>
            </div>
            {
                OSPracticesReportTypes.length ? OSPracticesReportTypes.map((type: any) => {
                    return buildReport(type, filterDate);
                }) : <></>
            }

        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        practice: state.practice,
        app: state.app,
    };
}

export default connect(mapStateToProps)(ReferralDataOsPractices);