import React from "react";
import {DynamicTable} from "../model/dynamicTable";

export default function DynamicHtmlTableMail(props: DynamicTable) {

  const getTableHeadings = () => {
    let returnArray:any = [];
    if(props.tableHead.length) {
      props.tableHead.forEach((item, key) => {
        returnArray.push(<th className={item.className} id={item.id}  style={item.style} key={item.key||key}><div>{item.title}</div></th>)
      })
    }
    return (<thead><tr>{returnArray}</tr></thead>)
  };

  const getTableBody = () => {
    let tableRows:any = [];
    if(props.tableData.length) {
      props.tableData.forEach((itemArray, key) => {
        if(itemArray.length) {
          let tableColumns:any = [];
          itemArray.forEach((item, key2) => {
            tableColumns.push(<td className={item.className} id={item.id}  style={item.style} key={item.key||key2}>{item.title}</td>)
          });
          tableRows.push(<tr key={key}>{tableColumns}</tr>)
        }
      })
    }
    return (<tbody>{tableRows}</tbody>)
  };

  return (<div className="ui card">
    <div className="content">
      <h5>{props.heading}</h5>
      <div className="table-responsive FreezeTable">
        <table style={props.style} className={`${ props.className ? `ui celled single line table unstackable tableStyle ${props.className}` : "ui celled single line table unstackable tableStyle" }`}>
          {getTableHeadings()}
          {getTableBody()}
        </table>
      </div>
    </div>
  </div>)
}