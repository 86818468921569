import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";
import {FinancialNoteCategory} from "../../model/FinancialNoteCategoryList";

export const saveFinancialNoteCategory = (obj: FinancialNoteCategory) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("financial-note-category/save", obj);
        dispatch({ type: ActionType.SAVE_FINANCIAL_NOTE_CATEGORY_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const updateFinancialNoteCategory = (obj:FinancialNoteCategory) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("financial-note-category/update", obj);
        dispatch({ type: ActionType.UPDATE_FINANCIAL_NOTE_CATEGORY_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const getFinancialNoteCategories= () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.get("financial-note-category/get");
        dispatch({ type: ActionType.FETCH_FINANCIAL_NOTE_CATEGORY_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};