import React, {useEffect, useState} from "react";
import {Button, Modal} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import {appointmentStatus} from "../constants";
import _ from "lodash";

function OsRollUpModel (props: any) {

    const initialState = {column: null, data:[],order: true,}
    const [state, setState] = useState(initialState);


    useEffect(() => {
        const data:any = props.aptPopupInfo.map((r:any)=>{
            const k:number=r.AptStatus
            return {...r,AptStatus:appointmentStatus[k]}
        });
        setState({...state,data})
    }, []);

    const handleSort = (clickedColumn: any) => {
        const {column, data, order} = state;
        const dat: any = _.sortBy(data, [clickedColumn]);
        if (column !== clickedColumn) {
            setState({
                ...state,
                column: clickedColumn,
                data: dat,
                order: true,
            })

            return
        }

        setState({
            ...state,
            data: data.reverse(),
            order: !order
        })
    }
    const getTableHeadings = () => {
        let returnArray: React.ReactElement[] = [];
        const {order} = state;
        const direction = order ? 'down' : 'up';
        const info:any = {
            'patient number':'PatNum',
            'Appointment Status':'AptStatus',
            'Appointment Date':'AptDateTime',
            'Operatory Name':'OpName'
        };
        Object.keys(info).forEach((item: any, key: number) => {
          const klas= `header-os-inner cursorPointer ${state.column == info[item] ? 'active' : ''}`
                returnArray.push(
                    <th key={key} className={klas} onClick={() => handleSort(info[item])}>
                    {item} <i className={`sort amount ${direction} icon ml-05`}/>
                    </th>);
            });
        return (<thead><tr>{returnArray}</tr></thead>)
    }

    const makeOSPCRBrandRollUpPopupCSV=()=>{
        const {data}=state;
        let dataArr:any = []
        if (data && data.length > 0) {
            dataArr = data.map((item: any, key: number) => `${item.PatNum},${item.AptStatus},${item.AptDateTime},${item.OpName}`);
        }
        dataArr.unshift("Patient Num, Appointment Status,Appointment Date, Op Name");
        return dataArr;
    }
    const getTableRows = () => {
        const {data}=state;
        let returnArrayInfo: React.ReactElement[] = [];
        if (data && data .length > 0){
            data.map((item: any, key: number) => {
                returnArrayInfo.push(<tr>
                    <td>{item.PatNum}</td>
                    <td>{item.AptStatus}</td>
                    <td>{item.AptDateTime}</td>
                    <td>{item.OpName}</td></tr>);
            });
        }else{
            returnArrayInfo.push(<tr><td colSpan={4}>no records found</td></tr>);
        }
        return (<tbody>{returnArrayInfo}</tbody>);
    }
    
    return (
        <Modal className={'modalCenter'}
               onClose={() => props.handleClose()}
               open={true}
        >
            <Modal.Header>Pt Visit Appointments Scheduled <a className={"ui orange label"}>{ state.data .length||""}</a> <Button
                className="float-right"
                content="close"
                primary={true}
                onClick={() => props.handleClose()}
            /></Modal.Header>
            <Modal.Content >
                <Modal.Description>
                    <div>
                        <div className="table-responsive">
                            <table className="ui celled single line table unstackable tableStyle tableOpenSurgery ">
                                {getTableHeadings()}
                                {getTableRows()}
                            </table>
                        </div>

                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="OK"
                    primary={true}
                    onClick={() => props.handleClose()}
                />
                <Button
                    content="SEND"
                    primary={true}
                    onClick={() => props.sendMailOSPCRPopupDetail({data:makeOSPCRBrandRollUpPopupCSV(),practicename:props.brand,popupTitle:"Brand Roll Up Pt Visit Appointments Scheduled"})}
                />
            </Modal.Actions>
        </Modal>
    );
}


function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app,
    };
}
export default connect(mapStateToProps)(OsRollUpModel);
