import React, {useState} from 'react';
import {Button, Form, Modal} from "semantic-ui-react";

interface CronFormProps {
  initialValues : any,
  cancelForm : any,
  saveForm : any
}

const CronForm = (props: CronFormProps) => {
  const [state, setStates] = useState(props.initialValues);

  const onChange = (_e: any, element: any) => {
    state[element.name] = element.value;
    setStates(state);
  };

  const saveForm = () => {
    props.saveForm(state);
  };

    const { initialValues, cancelForm } = props;
    return(

  <Modal
    onClose={() => cancelForm}
    open={true}
    size={"small"}
    trigger={<Button>Show Modal</Button>}
  >
    <Modal.Header>Update Cron details</Modal.Header>
    <Modal.Content>
      <Modal.Description>
        <Form className={'widthFluid'}>
          <Form.Group widths='equal'>
            <Form.Input
              fluid={true}
              label='Cron Title'
              placeholder='Cron Title'
              defaultValue={initialValues.title}
              name={'title'}
              onChange={onChange}
            />
            <Form.Input
              fluid={true}
              label='Cron Description'
              placeholder='Cron Description'
              defaultValue={initialValues.description}
              name={'description'}
              onChange={onChange}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Input
                fluid={true}
                label='Affected Application'
                placeholder='Affected Application'
                defaultValue={initialValues.affectedApplication}
                name={'affectedApplication'}
                onChange={onChange}
            />
            <Form.Input
                fluid={true}
                label='Repository'
                placeholder='Repository'
                defaultValue={initialValues.repository}
                name={'repository'}
                onChange={onChange}
            />
          </Form.Group>
        </Form>
      </Modal.Description>
    </Modal.Content>
    <Modal.Actions>
      <Button secondary onClick={cancelForm}>Cancel</Button>
      <Button className="ml10" primary onClick={saveForm}>Save</Button>

    </Modal.Actions>
  </Modal>

    );
}

export default CronForm;
