import React from "react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import McoScrapperForm from "./McoScrapperForm/McoScrapperForm";

const McoScrapper = () => {

    return (
        <Template activeLink="mco-scrapper">
            <McoScrapperForm key="main-mco-scrapper" />
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        mcoList: state.report.mcoList,
    };
}

export default connect(mapStateToProps)(McoScrapper);
