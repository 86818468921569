import { Practice } from "../../model/practice";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";
const initialState:Practice = {
	regionManagersOptions: [],
	schedulePMDDataOptions : [],
	practiceOptions: [],
	regionPractices: [],
	regionPracticeIds:[],
	regionOptions: [],
	practiceAbbr: {},
	drPractices: {},
	practiceInfo : {},
	practiceNames : [],	
	udaPracticeInfo:[],
	practiceList:[],
	regionPracticeList: [],
	regions: [],
	practiceNamesMap:[],
	orthoPractices:[],
	allPracticeList:[],
}
export const practice = createReducer(initialState, {
	[ActionType.FETCH_PRACTICES](state: Practice, action: Action<Practice>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_ALL_PRACTICES](state: Practice, action: Action<Practice>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_REGIONS](state: Practice, action: Action<Practice>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_REGIONMANAGERS](state: Practice, action: Action<Practice>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_PMD_SCHEDULES](state: Practice, action: Action<Practice>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_UDA_INFO_PRACTICES](state: Practice, action: Action<Practice>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_PRACTICES_DB](state: Practice, action: Action<Practice>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_ORTHO_PRACTICES](state: Practice, action: Action<Practice>) {
		return Object.assign({...state}, action.payload);
	}
});
