import React, {useEffect} from "react";
import {Loader} from "semantic-ui-react";
import Template from "./Template";
import { RootState } from "../model";
import { connect } from "react-redux";
import * as actions from "../redux/actions/spore"
import {useActions} from "../redux/actions"
import { SporeTableResult } from "../model/spore";
import SporeTableMain from "./SporeTableMain";


interface Props {
  user_practices:[],
  tableResult:Array<SporeTableResult>

}

const SporeServiceDashboard=(props:Props
)=> {
  const sporeActions=useActions(actions)
  useEffect(()=>{
   const fetchPractices=async()=>{
    sporeActions.fetchPractices()
       sporeActions.fetchTableResult()
   } 
   fetchPractices()
  },[])
  return (
    <>
      
      <Template activeLink="SPORE">
        <div className="ui card">
          <div className="card">
            <div className="content">
              <div className="mr10 ">
                <Loader>Loading</Loader>
                <SporeTableMain practices={props.user_practices} fetchTableResults={sporeActions.fetchTableResult} tableResults={props.tableResult}/>
              </div>
            </div>
          </div>
        </div>
      </Template>
    </>
  );
}

function mapStateToProps(state: RootState) {
  return {
    user: state.auth.user,
    user_practices:state.spore.user_practices,
    tableResult:state.spore.tableResult
  };
}

export default connect(mapStateToProps)(SporeServiceDashboard);
