import React, {  useState, useEffect } from "react";
import {
    Grid,
    Table,
    Button, Icon, Dropdown, Input,Modal,Form
} from "semantic-ui-react";
import Template from "./Template";
import { RootState } from "../model";
import { connect } from "react-redux";
import * as NotificationsActions from "../redux/actions/educatorNotifications";
import * as ProdecureCodeChange from "../redux/actions/procedureCodeChange"
import * as PracticeActions from "../redux/actions/practice"
import { useActions } from "../redux/actions";
import _ from "lodash";
import HelpMark from "./HelpMark";
import {render} from "react-dom";

interface ProcedureCodeChangeProps {
    id: number,
    procedureCode: string,
    abbrDesc: string,
    description: string,
    'OD ProcCat': string,
    isDeleted: boolean,
    createdAt: string,
    updatedAt:string,
    ProcedureCode_GroupData:Array<Object>
}
type notificationScema={
    practice:string
}

type procedureCodeSchema ={
    name: string,
    option: any,
    groupOption: any,
    groupId: number,
    action: any,
    groupError:any
    optionError:any

}


const GroupModal = (props: any) => {
    const initialState: procedureCodeSchema = {

        name: '',
        option: null,
        groupOption: null,
        groupId: 0,
        action: null,
        groupError:null,
        optionError:null
    }
    const [state,setStates] = useState(initialState);
    const procedureCodeChangerActions = useActions(ProdecureCodeChange);
   const groupAction = (groupName:any, groupOptionsName:any, id:any, action:any, groupOption:any) => {
        setStates({...state,
            name: groupName,
            option: groupOptionsName,
            groupId: parseInt(id),
            action: action,
            groupOption: groupOption ? groupOption : null
        })
    }
    const onChange = (e:any) => {
        setStates({...state,[`${e.target.name}`]: e.target.value })
    }
    const getRows = () => {
        const { procedureCodeGroups } = props;
        let rows:any = [];
        procedureCodeGroups.forEach((group:any) => {
            const groupName = group.name;
            const groupOptions = group.ProcedureCode_GroupOptions;
            let groupOptionsName = '';
            let groupOptionArr:any = [];
            groupOptions && groupOptions.forEach((opt:any) => {
                groupOptionArr.push({
                    id: opt.id,
                    name: opt.name
                })
                if (groupOptionsName.length === 0) {
                    groupOptionsName += opt.name.trim();
                } else {
                    groupOptionsName += ', ' + opt.name.trim();
                }
            })
            rows.push(
                <tr key={group.id}>
                    <td style={{width: '300px'}}>
                        <Button
                            className='btn btn-primary'
                            onClick={() => groupAction(groupName, groupOptionsName, group.id, 'edit', groupOptionArr)}>
                            Edit
                        </Button>
                        <Button
                            className='btn btn-primary ml10'
                            onClick={() => groupAction(groupName, groupOptionsName, group.id, 'copy',groupOptionArr)}>
                            Copy
                        </Button>
                        <Button
                            className='btn btn-primary ml10'
                            onClick={() => props.onDeleteGroup('deleteGroup', group.id) }>
                            Delete
                        </Button>
                    </td>
                    <td>{groupName}</td>
                    <td>{groupOptionsName}</td>
                </tr>
            )
        })
        return rows;
    }
    const onGroupsClicked = (action:any) => {
        setStates({...state,
            action,
            name: '',
            option: null,
            groupId: 0,
        })
    }
   const onCancelClicked = () => {
      setStates({...state,
            action: null,
            groupError: null,
            name: '',
            option: null,
            groupId: 0,
        })
    }
    const  saveForm = () => {
        const { name, option, groupId, action, groupOption } = state;
        let obj;
        switch(action) {
            case 'groupName':
                obj = {
                    name: name.trim()
                }
                procedureCodeChangerActions.createProcedureCodeGroup(obj)
                    .then(() => {
                        procedureCodeChangerActions.getProcedureCodeGroup()
                            .then(() => {
                                setStates({...state,
                                    action: null,
                                    groupError: null,
                                    name: '',
                                    option: null,
                                    groupId: 0,
                                })
                            })
                    })
                    .catch((error:any) => {
                        setStates({...state,
                            groupError: error
                        })
                    })
                break;

            case 'groupOption':
                obj = {
                    name: option.trim(),
                    groupId
                }
                procedureCodeChangerActions.createProcedureCodeGroupOptions(obj)
                    .then(() => {
                        procedureCodeChangerActions.getProcedureCodeGroup()
                            .then(() => {
                                setStates({...state,
                                    action: null,
                                    optionError: null,
                                    name: '',
                                    option: null,
                                    groupId: 0
                                })
                            })
                    })
                    .catch((error:any) => {
                        setStates({...state,
                            optionError: error
                        })
                    })
                break;

            case 'copy':
                obj = {
                    name: name.trim(),
                    option: option,
                    groupId: groupId,
                }
                procedureCodeChangerActions.createProcedureCodeGroup(obj)
                    .then(() => {
                        procedureCodeChangerActions.getProcedureCodeGroup()
                            .then(() => {
                                setStates({...state,
                                    action: null,
                                    groupError: null,
                                    name: '',
                                    option: null,
                                    groupId: 0,
                                })
                                props.onUpdate()
                            })
                    })
                    .catch((error:any) => {
                        setStates({...state,
                            groupError: error
                        })
                    })
                break;

            case 'edit':
                let update:any = [], insert:any = [];
                const optionArr = option.split(',');
                optionArr.forEach((item:any, index:any) => {
                    if (index < groupOption.length) {
                        if (item !== groupOption[index].name.trim()) {
                            update.push({
                                id: groupOption[index].id,
                                name: item.trim()
                            })
                        }

                    } else {
                        insert.push({
                            name: item.trim(),
                            groupId: groupId
                        })
                    }
                })
                obj = {
                    groupId: groupId,
                    name: name.trim(),
                    action: 'edit',
                    option: {update, insert}
                }
                procedureCodeChangerActions.updateProcedureCodeGroup(obj)
                    .then(() => {
                        procedureCodeChangerActions.getProcedureCodeGroup()
                            .then(() => {
                                setStates({...state,
                                    action: null,
                                    groupError: null,
                                    name: '',
                                    option: null,
                                    groupId: 0,
                                })
                            })
                    })
                    .catch((error:any) => {
                        setStates({...state,
                            groupError: error
                        })
                    })
                break;

            default:
                console.log('No action performed')
        }

    }
    const onClickSaveGroupId =(e :any,v:any)=>{
       setStates({...state,groupId:parseInt(v.value)})
    }
    const { onHide, groupList } = props;
    const { action, groupError, optionError } = state;
    return (
        <>
            <div>
                <Modal
                    className={'modalCenter'}
                    open={true}
                    onClose={onHide}
                    dialogClassName="carrierWidth"
                >
                    <Modal.Header>
                            <p>Groups!</p>
                    </Modal.Header>
                    <Modal.Content>
                        <div className="campaign-filters practice-info">
                            <Button primary color="blue" onClick={() => onGroupsClicked('groupName')}>
                                Add Group
                            </Button>
                            <Button primary color="blue" onClick={() =>onGroupsClicked('groupOption')}>
                                Add Group Options
                            </Button>
                            {groupError && (<p className="text-danger">{groupError}</p>)}
                            {optionError && (<p className="text-danger">{optionError}</p>) }

                            {(action === 'groupName') && <div className="row mt10">
                                <Form>
                                    <Form.Group>
                                        <Form.Field>
                                            <div className="col-md-3 col-xs-12">
                                                <label>Group Name</label>
                                                <Input
                                                    type="text"
                                                    value={state.name}
                                                    name="name"
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </Form.Field>
                                    </Form.Group>
                                </Form>

                            </div>}

                            {action === 'groupOption' && <div className="row mt10">
                                <Form>
                                    <Form.Group>
                                        <Form.Field>
                                            <div className="col-md-3 col-xs-6 dentist-list">
                                                <label>Group</label>
                                                <Dropdown
                                                    options={groupList}
                                                    placeholder="Select Group"
                                                    onChange={onClickSaveGroupId}
                                                    value={state.groupId}
                                                    name='groupId'
                                                />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <div className="col-md-3 col-xs-6 dentist-list">
                                                <label>Options</label>
                                                <input
                                                    className='form-group'
                                                    type="text"
                                                    value={state.option}
                                                    name="option"
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </Form.Field>
                                    </Form.Group>
                                </Form>


                            </div>}

                            {(action === 'copy' || action === 'edit') && <div className="row mt10">
                                <Form>
                                    <Form.Group>
                                        <Form.Field>
                                            <div className="col-md-3 col-xs-12">
                                                <label>Group Name</label>
                                                <input
                                                    className='form-group'
                                                    type="text"
                                                    value={state.name}
                                                    name="name"
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </Form.Field>
                                        <Form.Field>
                                            <div className="col-md-3 col-xs-12">
                                                <label>Group Options</label>
                                                <input
                                                    className='form-group'
                                                    type="text"
                                                    value={state.option}
                                                    name="option"
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </Form.Field>
                                    </Form.Group>

                                </Form>


                            </div>}

                            {(action && action !== 'delete') && <Button primary color="blue" onClick={onCancelClicked}>
                                Cancel
                            </Button>}

                            {(action && action !== 'delete') && <Button primary color="blue" onClick={saveForm}>
                                Save
                            </Button>}

                        </div>
                    </Modal.Content>

                        <div>
                            <table className="table table-striped FreezeTable carrierIdentity">
                                <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>Group Name</th>
                                    <th>Group Options</th>
                                </tr>
                                </thead>
                                <tbody>
                                {getRows()}
                                </tbody>
                            </table>
                        </div>
                    <Modal.Actions>
                        <Button primary color="blue"  onClick={onHide}>
                            DONE
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>

        </>
    );
};

function mapStateToProps(state: RootState) {
    return {


    };
}

export default connect(mapStateToProps)(GroupModal);