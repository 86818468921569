import React from 'react'
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
    Label,
} from 'semantic-ui-react'

interface props {
    data: any,
    stats:any
}
const Five9CallsTable = ({ data , stats }: props) => {

    const getLastDate = (logs: any) => {
        const [lastLog] = logs
        const diff : any = getDaysDiff(lastLog.createdAt);
        return diff < 1 ? 'TODAY' : diff < 2 ? 'YESTERDAY' : `${diff} DAYS ago`;
    }

    const getDaysDiff = (date: any) => {
        if(date){
            const givenDate: Date = new Date(date);
            const currentDate: Date = new Date();
            const diffInMs: number = currentDate.getTime() - givenDate.getTime();
            return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
        }
    }
    const getStatus = (logs: any) =>{
        const [lastLog] = logs
        let diffInDays : any = 1;

        if(lastLog.createdAt){
            diffInDays = getDaysDiff(lastLog.createdAt);
        }

        return lastLog.status && diffInDays == 0;

    }



    return (
        <>
            <Table striped compact='very'>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Title</TableHeaderCell>
                        <TableHeaderCell>Last Ran</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {
                        data.map((item: any, index: number) =>
                            <TableRow key={index}>
                                <TableCell>{item.title} <strong>({stats[item.title] || 0})</strong></TableCell>
                                <TableCell>{getLastDate(item.logs)}</TableCell>
                                <TableCell textAlign='right' width={3}>
                                    {
                                        getStatus(item.logs)
                                        ? <Label style={{ width: '70%' }} size='massive' color='green' horizontal></Label>
                                        : <Label style={{ width: '70%' }} size='massive' color='red' horizontal></Label>
                                    }
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </>

    )
}

export default Five9CallsTable