import { createContext, useContext } from 'react';

export type PracticeContextType = {
  theme: string;
  setTheme: (value: string) => void;    
}

let value =''
export const PMDPracticeContext = createContext<PracticeContextType>({ theme: value, setTheme: _theme => console.warn('no theme provider')});

export const useDefaultPracticeContext = () => useContext(PMDPracticeContext);
