import _ from "lodash";
import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";


export const fetchBudgetPractices = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const response = await api.get(`broken/budgetPractices`);
        let list_1 = {} as any;
        let list_2 = {} as any;
        response.forEach((item: any) => {
            list_1[item.practice] = item.practiceDBName;
            list_2[item.practiceDBName] = item.practice;
        });
        return [list_1, list_2];
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const fetchPractices = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const response = await api.get(`broken/allPractices`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const orderDate = (order: string, val: string, response: any) => {
    if (order == 'DESC') {
        response.sort((a: any, b: any) => {
            return new Date(b[val]).getTime() - new Date(a[val]).getTime()
        });
    }
    if (order == 'ASC') {
        response.sort((a: any, b: any) => {
            return new Date(a[val]).getTime() - new Date(b[val]).getTime()
        });
    }
    return response;
}

export const orderString = (order: string, val: string, response: any) => {
    if (order == 'DESC') {
        response.sort((a: any, b: any) => {
            return b[val].localeCompare(a[val])
        });
    }
    if (order == 'ASC') {
        response.sort((a: any, b: any) => {
            return a[val].localeCompare(b[val])
        });
    }
    return response;
}
export const orderInt = (order: string, val: string, response: any) => {
    if (order == 'DESC') {
        response.sort((a: any, b: any) => {
            return Number(b[val]) - Number(a[val])
        });
    }
    if (order == 'ASC') {
        response.sort((a: any, b: any) => {
            return Number(a[val]) - Number(b[val])
        });
    }
    return response;
}

export const orderBy = (data: any, response: any) => {
    if (data.orderBy) {
        let orderBy = JSON.parse(data.orderBy);
        Object.keys(orderBy).forEach((val: any) => {
            switch (val) {
                case "Guarantor":
                case "PatNum":
                case "age":
                case "Days_Since_Last_Apt":
                case "Days_Since_Last_Visit":
                case "commLogCount":
                case "show_rate":
                    response = orderInt(orderBy[val], val, response);
                    break;
                case "LName":
                case "HmPhone":
                    response = orderString(orderBy[val], val, response);
                    break;
                case "BirthDate":
                case "LastVisit":
                case "CommDateTime":
                case "notesUpdatedAt":
                case "dateLastVerified":
                case "Last_Apt":
                case "Next_Apt":
                    response = orderDate(orderBy[val], val, response);
                    break;
            }
        });
    }
    return response;
}

export const fetchBrokens = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const queryString = Object.keys(data || {})
            .filter(key => !(data[key] == undefined || data[key] == null))
            .map(key => `${key}=${data[key]}`).join('&');
        let response = await api.get(`broken/list?${queryString}`);
        dispatch({type: ActionType.LOADER, payload: false});
        response = orderBy(data, response);
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const getSpotWorkDetails = (filters: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        let params;
        if (filters) {
            params = JSON.stringify(filters)
        }
        let response = await api.get(`broken/work-report/details/${params}`);
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch({
            type: ActionType.LOAD_SPOT_WORK_REPORT,
            payload: {spotWorkReportDetails: response}
        });
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const getSpotWork = (filters: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        let params;
        if(filters){
            params = JSON.stringify(filters)
        }
        let response = await api.get(`broken/work-report/${params}`);
        dispatch({ type: ActionType.LOADER, payload: false });
        dispatch({
            type: ActionType.LOAD_SPOT_WORK_REPORT,
            payload: { spotWorkReport : response}
        });
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }

}

export const fetchTreatmentList = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const queryString = Object.keys(data || {})
            .filter(key => !(data[key] == undefined || data[key] == null))
            .map(key => `${key}=${data[key]}`).join('&');
        let response = await api.get(`broken/treatmentList?${queryString}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const fetchTreatmentPlan = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const queryString = Object.keys(data || {})
            .filter(key => !(data[key] == undefined || data[key] == null))
            .map(key => `${key}=${data[key]}`).join('&');
        let response = await api.get(`broken/tableOTPInfo?${queryString}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response && response[0];
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}


export const fetchBrokenDetails = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const queryString = Object.keys(data || {})
            .filter(key => !(data[key] == undefined || data[key] == null))
            .map(key => `${key}=${data[key]}`).join('&');
        let response = await api.get(`broken/brokenDetails?${queryString}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const lastExamResult = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const queryString = Object.keys(data || {})
            .filter(key => !(data[key] == undefined || data[key] == null))
            .map(key => `${key}=${data[key]}`).join('&');
        let response = await api.get(`broken/lastExamResult?${queryString}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const fetchBrokenCount = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const queryString = Object.keys(data || {})
            .filter(key => !(data[key] == undefined || data[key] == null))
            .map(key => `${key}=${data[key]}`).join('&');
        const response = await api.get(`broken/count?${queryString}`);
        const count = response[0] && response[0][0] && (response[0][0].count || 0);
        return getPager(count, +data.page, +data.limit);
    } catch (err) {
        dispatch(errorHandler(err))
        return [];
    }
}

export const fetchCommLogInfo = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const queryString = Object.keys(data || {})
            .filter(key => !(data[key] == undefined || data[key] == null))
            .map(key => `${key}=${data[key]}`).join('&');
        let response = await api.get(`broken/commLogHistoryInfo?${queryString}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}


export const fetchAppointmentList = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const queryString = Object.keys(data || {})
            .filter(key => !(data[key] == undefined || data[key] == null))
            .map(key => `${key}=${data[key]}`).join('&');
        let response = await api.get(`broken/appointmentList?${queryString}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const fetchRefreshTime = (location: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        location = lowercaseFirstLetter(location);
        const response = await api.get(`broken/refreshTime?location=${location}`);
        return response
    } catch (err) {
        dispatch(errorHandler(err))
        return [];
    }
}


export const saveBrokenResult = (prop: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const response = await api.post(`broken/save`, prop);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const saveNotes = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const response = await api.post(`broken/saveNotes`, payload);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const getResultList = (filters: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const params = JSON.stringify(filters)
        const response = await api.get(`broken/resultList/${params}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}


export const getNotesList = (filters: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const params = JSON.stringify(filters)
        const response = await api.get(`broken/notesList/${params}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const getCollectiveNotesList = (filters: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const params = JSON.stringify(filters)
        const response = await api.get(`broken/collectiveNotesList/${params}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const saveResult = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const response = await api.post(`broken/saveResult`, payload);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}


function lowercaseFirstLetter(string: string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}


export function getPager(items: number, currentPage: number, pageSize: number) {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    if (currentPage < 1 || currentPage > items) {
        return;
    }

    // calculate total pages
    let totalPages = Math.ceil(items / pageSize);
    let startPage, endPage;
    if (totalPages <= 10) {
        // less than 10 total pages so show all
        startPage = 1;
        endPage = totalPages;
    } else {
        // more than 10 total pages so calculate start and end pages
        if (currentPage <= 6) {
            startPage = 1;
            endPage = 10;
        } else if (currentPage + 4 >= totalPages) {
            startPage = totalPages - 9;
            endPage = totalPages;
        } else {
            startPage = currentPage - 5;
            endPage = currentPage + 4;
        }
    }

    // calculate start and end item indexes
    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, items - 1);
    // create an array of pages to ng-repeat in the pager control
    var pages = [];

    for (let i = startPage; i < endPage + 1; i++) {
        pages.push(i);
    }
    // return object with all pager properties required by the view
    return {
        totalItems: items,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages
    };
}

export const updatePractices = (data: {id: number}) => async(dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const response = await api.put(`update-user-setup`, data);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
};

export const fetchExcludeCodesList = (practice: string) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        let stringifyParams;
        if(practice){
            stringifyParams = '/'+JSON.stringify({practice:practice})
        }
        const response = await api.get(`spot/excludeList`+stringifyParams);
        dispatch({ type: ActionType.LOADER, payload: false });
        return response;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const getUnscheduledTreatment = (excludeProceduresCodeList: any, practice: string, month: number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const stringifyParams = '/'+JSON.stringify({excludeProceduresCodeList, practice, month});
        const response = await api.get(`spot/unscheduledTreatment`+stringifyParams);
        const sortedData = _.orderBy(response, ['practiceName'], ['asc']);
        dispatch({ type: ActionType.LOADER, payload: false });
        dispatch({
            type: ActionType.LOAD_UNSCHEDULED_TREATMENT,
            payload: { unscheduledTreatmentDetails : sortedData }
        });
        return sortedData;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const getSpotPatients = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const stringifyParams = '/'+JSON.stringify(params);
        const response = await api.get(`spot/getPatients`+stringifyParams);
        dispatch({ type: ActionType.LOADER, payload: false });
        dispatch({
            type: ActionType.LOAD_SPOT_PATIENTS,
            payload: { spotPatients : response}
        });
        return response;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getSpotPatientRetention = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        dispatch({ type: ActionType.LOADER, payload: true });
        const stringifyParams = '/'+JSON.stringify(params);
        const response = await api.get(`spot/getPatientRetention`+stringifyParams);
        dispatch({ type: ActionType.LOADER, payload: false });
        dispatch({
            type: ActionType.LOAD_SPOT_PATIENT_RETENTION,
            payload: { patientRetention : response}
        });
        return response;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getSpotRetentionDetails = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const stringifyParams = '/'+JSON.stringify(params);
        const response = await api.get(`spot/patientRetentionDetails`+stringifyParams);
        dispatch({ type: ActionType.LOADER, payload: false });
        return response;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const fetchUsageSPOT = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const response = await api.get(`spot/usage/all`);
        dispatch({ type: ActionType.LOADER, payload: false });
        return response.data;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const saveSpotUsage = (usage : any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        const resp =  await api.post("spot/saveUsage", usage);
        dispatch({ type: ActionType.SAVE_SPOT_USAGE, payload: resp });
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    }
};

export const hideUsageSpot = (userId: number, hideUsage: number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const response = await api.put(`spot/hide/usage`, {userId, hideUsage});
        dispatch({ type: ActionType.LOADER, payload: false });
        return response.data;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const flushUsageDataSpot = (userId: number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const response = await api.put(`spot/flush/usage`, {userId});
        dispatch({ type: ActionType.LOADER, payload: false });
        return response.data;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};


