/* eslint-disable*/
import _ from 'lodash';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import {connect} from 'react-redux';
import {Button, Dimmer, Dropdown, Grid, Input, Loader, Pagination, Popup, Segment} from 'semantic-ui-react';

import {RootState} from '../model';
import {useActions} from '../redux/actions';
import * as Actions from '../redux/actions/universalUsageReport';
import * as PracticeActions from '../redux/actions/practice';
import Template from './Template';
import HelpMark from "./HelpMark";

function OpenDentalApisUsageReport(props: any) {

    const initialState = {
        data: [],
        direction: 'sort',
        allowEdit: !!(props.user && props.user.isAdmin),
        order: true,
        column: null,
        startDate: new Date(moment().startOf('month').toString()),
        endDate: new Date(moment().endOf('month').toString()),
        searchKeyword: '',
        tempData: [],
        TableData: [[{title: 'No Records Found'}]],
        showHidden: false,
        actualData: [],
        options: [],
        practices: [],
        searchPractices: []

    }
    let timeout: any = 0;
    const [state, setState] = useState(initialState);
    const [loader, setLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const UniversalUsageReportActions = useActions(Actions);
    const PracticeAction = useActions(PracticeActions);

    useEffect(() => fetchData(), [state.startDate, state.endDate])
    const fetchData = () => {
        setCurrentPage(1);

        PracticeAction.fetchPractice('All').then((practicesResponse: any) => {
            let practiceOptions = practicesResponse.map((res: any) => {
                return { text: res.practice, value: res.id, key: res.practiceabbr }
            });
            UniversalUsageReportActions.getOdApisUsageReport(moment(state.startDate).format('YYYY-MM-DD'), moment(state.endDate).format('YYYY-MM-DD'), []).then((data: any) => {
                const actualData = data;
                const TableData = pushTableData(data);
                setState({
                    ...state,
                    TableData,
                    actualData,
                    data: data,
                    tempData: data,
                    practices: practicesResponse,
                    options: practiceOptions
                });
            })
        })
    }
    const handleSort = (clickedColumn: any) => {
        let {column, TableData, direction} = state;

        if (column !== clickedColumn) {
            column = clickedColumn;
            TableData = _.sortBy(TableData, (o: any) => o[clickedColumn]);
            direction = 'sort ascending';
            setState({...state, TableData: TableData.slice(), column, direction});
            return;
        }
        TableData = TableData.reverse();
        direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
        setState({...state, TableData: TableData.slice(), direction, order: !order});
    }
    const handleStartDateChange = (date: any) => {
        setState({...state, startDate: date})
    }
    const handleEndDateChange = (date: any) => {
        setState({...state, endDate: date})
    }
    const onSearchChange = (e: any) => {
        const searchKeyword = e.target.value;
        if (timeout) clearTimeout(timeout);
        setCurrentPage(1);
        timeout = setTimeout(() => {

            if (searchKeyword.toLowerCase() === '') {
                const TableData = pushTableData(state.actualData);
                setState({...state, searchKeyword: searchKeyword, TableData: TableData});
            } else {
                const data = state.actualData && state.actualData.filter((element: any) => {
                    return (element.apiEndpoint.toLowerCase().includes(searchKeyword.toLowerCase()))
                });
                const TableData = pushTableData(data);
                setState({...state, searchKeyword: searchKeyword, TableData: TableData});
            }

            clearTimeout(timeout);
            setLoader(false)
        }, 1000);
        setLoader(true)

    };

    const printOutPracticeName = (practiceId: number, practices: any) => {
        const prac: any = practices.find((item: any) => item.id === practiceId);
        return prac ? prac.practice : '';
    }

    const pushTableData = (res: any) => {
        const TableData = new Array();
        res && res.map((item: any, key: any) => {
            TableData.push(item)
        })
        return TableData;
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const onPracticeSelect = (selected: any) => {
        setState({...state, searchPractices: selected})
    }

    const search = () => {
        const { searchPractices, startDate, endDate } = state;
        setCurrentPage(1);
        UniversalUsageReportActions.getOdApisUsageReport(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), searchPractices).then((data: any) => {
            setState({...state, data: data, tempData: data})
            const actualData = data;
            const TableData = pushTableData(data);

            setState({...state, TableData, actualData});
        })
    }

    const {order, column, TableData} = state;

    const direction = order ? 'down' : 'up';

    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = TableData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(TableData.length / itemsPerPage);

    return (
        <Template activeLink="od-apis-usage-report">
            <div className="calendar-container">
                <div className="ui card">
                    <div className="content pb0">
                        <h2 className="float-left">Open Dental APIs Usage Report <HelpMark pageId='102'/></h2>
                        <div className="topFilters flex items-end flex-wrap">
                            <div className="filter d-InlineBlock float-left labelTop">
                                <label className="labelStyle displayBlock">Start Date</label>
                                <div className="datePicker">
                                    <DatePicker
                                        className="ui input"
                                        selected={state.startDate}
                                        onChange={(date) => handleStartDateChange(date)}
                                    />
                                </div>
                            </div>

                            <div className="filter d-InlineBlock float-left labelTop">
                                <label className="labelStyle displayBlock">End Date</label>
                                <div className="datePicker">
                                    <DatePicker
                                        className="ui input"
                                        selected={state.endDate}
                                        onChange={(date) => handleEndDateChange(date)}
                                    />
                                </div>
                            </div>
                            <Input
                                icon='search'
                                placeholder="Search API Endpoint"
                                onChange={onSearchChange}
                            />

                            <Dropdown
                                className="mr10"
                                placeholder='Select Practice(s)'
                                multiple
                                selection
                                search
                                onChange={(_e, data) => onPracticeSelect(data.value)}
                                options={state.options}
                            />

                            <Button
                                primary
                                onClick={() => search()}
                            > Search
                            </Button>

                        </div>
                    </div>
                </div>
                <div className="ui card">
                    <div className="content">
                        <div className="FreezeTable">
                            <table className="ui table table-striped celled table-hover unstackable">
                                <thead>
                                <tr>
                                    <th className={`cursorPointer  ${column === 'ApiConfig.practice.practice' ? 'active' : ''}`}
                                        onClick={() => handleSort('ApiConfig.practice.practice')}>
                                        <div>Practice <i className={`sort amount ${direction} icon ml-05`}/></div>
                                    </th>
                                    <th className={`cursorPointer ${column === 'apiEndpoint' ? 'active' : ''}`}
                                        onClick={() => handleSort('apiEndpoint')}>
                                        <div>API Url <i className={`sort amount ${direction} icon ml-05`}/></div>
                                    </th>
                                    <th className={`cursorPointer  ${column === 'createdAt' ? 'active' : ''}`}
                                        onClick={() => handleSort('createdAt')}>
                                        <div>Access Date <i className={`sort amount ${direction} icon ml-05`}/></div>
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                {
                                    <Dimmer as={Segment} active={loader}>
                                        <Loader active>Loading</Loader>
                                    </Dimmer>}
                                {
                                    !currentItems.length ? <tr>
                                        <td>No Records</td>
                                    </tr> : currentItems.map((item: any, key: any) => {
                                        return(
                                            <tr key={key}>
                                            <td>{printOutPracticeName(item['ApiConfig.practiceId'], state.practices)}</td>
                                            <td>{item.apiEndpoint}</td>
                                            <td>{moment(item.createdAt).format('yyyy/MM/DD HH:mm:ss')} </td>
                                        </tr>)
                                    })
                                }

                                </tbody>
                            </table>
                        </div>
                        <Grid>
                            <Grid.Column width={16} textAlign="right">
                            <Pagination
                                    activePage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={(_e, data: any) => handlePageChange(data.activePage)}
                                />
                            </Grid.Column>
                        </Grid>
                    </div>
                </div>
            </div>
        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        alert: state.alert,
        accessToken: state.auth.accessToken
    };
}

export default connect(mapStateToProps)(OpenDentalApisUsageReport);


