import React, {useState} from 'react';
import {Button, Form, Modal, Grid} from "semantic-ui-react";

interface GOXPrecedureCodeListFormProps {
    initialValues: { id: any; procedureCode: any; description: any; },
    cancelForm: any,
    saveForm: any,
    prefix: string
}

const GOXProcedureCodeListForm = (props: GOXPrecedureCodeListFormProps) => {
    const [state, setStates] = useState({
        id: props.initialValues.id,
        procedureCode: props.initialValues.procedureCode,
        description: props.initialValues.description ? props.initialValues.description : '',
        type: props.prefix,
    });

    const onChange = (e: any, element: any) => {
        const obj: any = {...state}
        obj[element.name] = element.name == "addToDrPay" ? element.checked : element.value;
        setStates(obj);
    };

    const saveForm = () => {
        props.saveForm(state);
    };

    const {initialValues, cancelForm} = props;
    return (

        <Modal
            onClose={() => cancelForm}
            open={true}
            size="small"
        >
            <Modal.Header>{initialValues.id && initialValues.id > 0 ? "Edit " : "Create "} {props.prefix} Procedure
                Codes</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form className={'widthFluid'}>
                        <Form.Group widths='equal'>
                            <Form.Input
                                fluid={true}
                                label='PROCEDURE CODE'
                                placeholder='PROCEDURE CODE'
                                defaultValue={state.procedureCode ? state.procedureCode : ''}
                                name={'procedureCode'}
                                onChange={onChange}
                            />

                            <Form.Input
                                fluid={true}
                                label={`${props.prefix} PROCEDURE CODE DESCRIPTION`}
                                placeholder={`${props.prefix} PROCEDURE CODE DESCRIPTION`}
                                defaultValue={state.description ? state.description : ''}
                                name={'description'}
                                onChange={onChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary={true} onClick={cancelForm}>Cancel</Button>
                <Button primary={true} onClick={saveForm}>Save</Button>
            </Modal.Actions>
        </Modal>

    );
}

export default GOXProcedureCodeListForm;
