import React, {Component, useEffect, useState} from 'react';
import {Table, Input, Modal, Select, Label, Button} from 'semantic-ui-react'
import {useActions} from "../../redux/actions";
import * as ProfileActions from "../../redux/actions/provider";

interface DoctorBaseWorkDaysModalProps {
    openDoctorBaseWorkDaysModal: boolean
    userId: number
    DocData: any,
    showDoctorBaseWorkDaysModal: any,
    initialValues: any
}

const DoctorBaseWorkDaysModal = (props: DoctorBaseWorkDaysModalProps) => {
    const doctorBaseWorkDays: Array<any> = []
    const {showDoctorBaseWorkDaysModal, DocData} = props;

    const [state, setState] = useState({
        loaded: 0,
        Location: null,
        value: null,
        name: null,
        index: null,
        doctorBaseWorkDays: doctorBaseWorkDays,
        practices: []
    });
    const providerActions = useActions(ProfileActions);

    useEffect(() => {
        const fetchData = async () => {
            const {userId} = props;
            const results = await providerActions.getDoctorBaseWorkDays(userId);
            if (results) {
                setState({
                    ...state,
                    doctorBaseWorkDays: results
                })
            }
        }
        fetchData();
    }, []);

    const handleOptionsWithAll = (e: React.ChangeEvent<HTMLInputElement>, a: number) => {
        handleOptions(e, a);
    };

    const handleOptions = (e: { target: { value: any; }; }, key: number) => {
        let {doctorBaseWorkDays} = state;
        doctorBaseWorkDays[key].baseWorkDays = e.target.value;
        setState({...state, doctorBaseWorkDays});
    };

    const saveBaseWorkDays = async() => {
        const {doctorBaseWorkDays} = state;
        const {userId} = props;
        const result = await providerActions.updateBaseWorkDays(doctorBaseWorkDays, userId);
        if (result) {
            alert('Doctor base work days change(s) successfully updated.');
            showDoctorBaseWorkDaysModal(false);
        } else {
            alert('Failed to update doctor base work days changes.');
        }
    };

    return (<>
        <Modal
            open={props.openDoctorBaseWorkDaysModal}
            size='large'
            centered
            closeIcon
            onClose={() => props.showDoctorBaseWorkDaysModal(false)}
        >
            <Modal.Header>
                <Modal.Content id='ModalHeader'
                               style={{
                                   display: "flex",
                                   justifyContent: "center",
                                   alignItems: "center",
                               }}>
                    Base work days for  &nbsp; <Label color='grey'>
                    Dr &nbsp;&nbsp;{props.DocData.firstName || ''} {" " + props.DocData.lastName || ''}
                </Label>  &nbsp;&nbsp;

                    <Modal.Content id='ModalHeader'>
                        | &nbsp;&nbsp;<span>  Status:</span> <Label
                        color={props.DocData.status ? 'teal' : 'red'}>{props.DocData.status ? 'Active' : 'DeActive'}</Label>
                    </Modal.Content>

                </Modal.Content>
            </Modal.Header>
            <Modal.Content style={{maxHeight: '500px', overflowY: 'scroll'}}>
                <div className="ui card">
                    <div className="content">
                        <div className="table-responsive FreezeTable">
                            <table className="table tableCustomStyle mb12">
                                <thead>
                                <tr>
                                    <th className={`FreezeTable-first-child `}>
                                        <div>
                                            Practice
                                        </div>
                                    </th>
                                    <th className={`FreezeTable-first-child `}>
                                        <div>
                                            Base Days
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    state.doctorBaseWorkDays && state.doctorBaseWorkDays.map((data, key) => {
                                        return <tr key={key} className="form-group">
                                            <td> {data.practiceName}</td>
                                            <td>
                                                <div className="form-group">
                                                    <input type="text"
                                                           style={{border: '1px solid #1b1c1d'}}
                                                           name="baseWorkDays"
                                                           value={data.baseWorkDays}
                                                           onChange={(e) => handleOptionsWithAll(e, key)}/>
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Save"
                    secondary
                    labelPosition='right'
                    icon='save'
                    onClick={() => saveBaseWorkDays()}
                    negative
                />
                <Button
                    content="Close"
                    secondary
                    labelPosition='right'
                    icon='close'
                    onClick={() => props.showDoctorBaseWorkDaysModal(false)}
                    negative
                />
            </Modal.Actions>
        </Modal>
    </>)

}

export default DoctorBaseWorkDaysModal;

