import React, {FC, ReactElement, useState} from "react";
import {
    Input,
    Button,
    Dropdown,
    Form,
} from "semantic-ui-react";

type CreateLambdasFormProps = {
    saveForm: any;
    item: any;
    action: any;
    closeForm: any;
};


const CreateLambdasForm: FC<CreateLambdasFormProps> = ({
                                                           saveForm,
                                                           action,
                                                           closeForm,
                                                           item
                                                       }): ReactElement => {
    const initialState = {
        name: item ? item["name"] : "",
        description: item ? item["description"] : "",
        status: item ? item["status"] : 0
    }
    const [state, setState] = useState(initialState)
    const submitForm = async () => {
        saveForm({
            name, description, status, id: item.id
        }, action)
        closeForm()
    }

    const {
        name, description, status
    } = state
    return (
        <>
            <Form>
                <Form.Field>
                    <label>Name</label>
                    <Input
                        style={{width: "100%"}}
                        placeholder="Name"
                        value={name}
                        onChange={(event) => setState({...state, name: event.target.value})}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Description</label>
                    <Input
                        style={{width: "100%"}}
                        placeholder="Description"
                        value={description}
                        onChange={(event) => setState({...state, description: event.target.value})}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Status</label>
                    <Dropdown
                        options={[{text: "Active", value: 1}, {text: "InActive", value: 0}]}
                        fluid={true}
                        selection={true}
                        placeholder={"Select Status"}
                        onChange={(_e, d) => {
                            setState({...state, status: d.value as number})
                        }}
                        value={status}
                    />
                </Form.Field>
                <Form.Field>
                    <Button secondary={true} onClick={closeForm}>
                        Cancel
                    </Button>
                    <Button
                        className='ui primary button'
                        onClick={(event) => {
                            event.preventDefault()
                            submitForm()
                        }}
                    >
                        Save
                    </Button>
                </Form.Field>
            </Form>
        </>
    );
};
export default CreateLambdasForm;
