import React, {useState} from "react";
import { Grid, Modal, Button, Dropdown, Icon, Form } from 'semantic-ui-react';
import {PopUpModals} from "../model/five9List";


function PopUpModal(props: PopUpModals) {
	const initialState: any = {
		name: (props.editDataSourcePageReportState||{}).name||'',
		id:(props.editDataSourcePageReportState||{}).id||'',
		reportId: (props.editDataSourcePageReportState||{}).reportId||0,
		formErrors: {},
	};
	const [state, setStates] = useState(initialState);
	const showPopupAsAlert = () => {
		return (
			<Grid>
				<label>{props.showLabel ? 'Warning, this cannot be undone!': ''}</label>
				<Grid.Row>
					<Grid.Column>
						{props.message}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		)
	};
	
	const formValidation = (obj: any) => {
		let formErrors: any = {};
		
		Object.keys(obj).forEach((key) => {
			if (key == 'name' && (!obj[key] || obj[key] == '')) { formErrors[key] = 'Field is required!'; }
			if (key == 'reportId' && (!obj[key] || obj[key] == 0)) { formErrors[key] = 'Please select Report!'; }
		});
		return formErrors;
	};
	
	const showPopupAsEditInfo = () => {
		return (
			<Form className="formStyle">
				{props.headerText === 'Add Page' &&
				<Form.Field required>
					<label>Select Report</label>
					<Dropdown
					placeholder='Select Report'
					selection
					label='Select Report'
					options={props.options}
					value={state.reportId}
					onChange={(e, element) => setStates({...state, reportId: element.value})}
				/>
					<span className="text-danger">{ (state.formErrors.reportId) ? state.formErrors.reportId : '' }</span>
				</Form.Field>}
				<Form.Field required>
					<Form.Input
						label="Name"
						required
						placeholder='Name'
						name='name'
						value={state.name}
						onChange={(e) => {
							setStates({...state, name: e.target.value})
						
						}}
					/>
					<span className="text-danger">{ (state.formErrors.name) ? state.formErrors.name : '' }</span>
				</Form.Field>
				
			</Form>
		)
	};
	
	const saveDetails = () => {
		const {name, reportId, id} = state;
		let obj: {name: string, reportId?: number} = {
			name
		};
		if (props.headerText === 'Add Page') {
			obj.reportId = reportId;
		}
		const formErrors = formValidation(obj);
		if (Object.keys(formErrors).length) {
			setStates({...state, formErrors: formErrors });
		} else {
			let data: {name: string, reportId?: string|number, id?: string|number} = {
				name
			};
			if(id){
				data.id=id;
			}
			if (props.headerText === 'Add Page' && reportId !== 0) {
				data.reportId = reportId;
			}
			props.addPopupHandler(data);
			setStates(formErrors)
		}
	};
	
	return (
		<Modal
			open={true}
			onClose={props.hidePopUpModal}
			aria-labelledby="ModalHeader"
			centered
			closeIcon
			size={props.size}
		>
			<Modal.Header>
				{props.headerText}
			</Modal.Header>
			<Modal.Content scrolling>
				{props.action === 'add' ? showPopupAsEditInfo() : showPopupAsAlert()}
			</Modal.Content>
			{props.deletePopUpHandler && <Modal.Actions>
				<Button secondary onClick={props.hidePopUpModal}>Cancel</Button>
				<Button negative onClick={props.deletePopUpHandler}>Delete</Button>
			</Modal.Actions>}
			{ props.addPopupHandler && <Modal.Actions>
				<Button secondary onClick={props.hidePopUpModal}>Cancel</Button>
				<Button primary={true} onClick={saveDetails}>Save</Button>
			</Modal.Actions>}
		</Modal>
	);
}


export default PopUpModal;
