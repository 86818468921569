import React from "react";
import {Five9Props} from "../model/five9List";
import * as Yup from 'yup';
import DataForm, { DataFormFieldType} from './formInputs'
import {leadStatus, priorities} from "../constants";

const AddFive9List:React.FC<Five9Props> = (props:Five9Props) => {
    const {practices, five9List, onCancel, initialValue, saveFive9Details} = props;

    return (
        <DataForm
            initialValues={initialValue}
            width={8}
            validationSchema={Yup.object().shape({
                practice: Yup.string().required('practice Name is required'),
                five9ListName: Yup.string().required('five9List Name is required'),
                countLimit: Yup.number(),
                priority: Yup.string().required('Priority is required')
            })}
            onSubmit={async (values:any, {setSubmitting}) => {
                values.leadStatus = Array.isArray(values.leadStatus)?values.leadStatus.join():values.leadStatus;
                values.priority = Array.isArray(values.priority)?values.priority.join():values.priority;
                await saveFive9Details(values);
                setSubmitting(false);
                onCancel();
            }}
            onCancel={() => {
                onCancel();
            }}
            fieldGroups={[
                {
                    fields: [{label: 'Practice', name: 'practice', type: DataFormFieldType.DropDown, options:practices, multiple:false},
                        {label: 'Five9 List', name: 'five9ListName', type: DataFormFieldType.DropDown, options:five9List, multiple:false}]
                },
                {
                    fields: [{label: 'Limit Record Count', name: 'countLimit', type: DataFormFieldType.Text},
                        {label: 'Lead Status', name: 'leadStatus', type: DataFormFieldType.DropDown, options:leadStatus, multiple:true}]
                },
                {
                    fields: {label: 'Priority', name: 'priority', type: DataFormFieldType.DropDown, options:priorities, multiple:true}
                }
            ]}
        />
    );
};
export default AddFive9List;
