import { split } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';

import { OSPracticesReportTypesInfo } from '../constants';
import { RootState } from '../model';
import { useActions } from '../redux/actions';
import * as referralsActions from '../redux/actions/referrals';

function DynamicReferralDataTable(props: any) {
    const referralsAction = useActions(referralsActions);
    const reportData = props.reportData;
    const [openModal, setOpenModal] = useState(false);
    const [numerator, setNumerator] = useState(0);
    const [denominator, setDenominator] = useState(0);
    const [type, setType] = useState('');

    const params = {
        startDate: moment(props.date).subtract(3, 'months').startOf('month').format('YYYY-MM-DD'),
        endDate: moment(props.date).endOf('month').format('YYYY-MM-DD')
    };
    useEffect(() => {
        referralsAction.referralsPractices(params).then((response: any) => {
            props.setReportData(response);
        });
    }, [props.date]);

    const getTableHeadings = () => {
        let returnArray: React.ReactElement[] = [];
        let info = [
            "Practices",
            "# of OS Days",
            "Total PT. Count",
            "Total Patient count age 15+",
            "# of OS referrals",
            "Referral % (patients age 15 and up)",
            "% of Referred Patients Scheduled",
            "% of Scheduled Patients that Arrived",
            "% of Patients not receiving care",
            "% of Referrals receiving care"
        ];
        info.forEach((item: any, key: number) => {
            returnArray.push(
                <th
                    key={key}
                    className={`cursorPointer`}
                >
                    {item}
                </th>
            );
        });
        return (<thead><tr>{returnArray}</tr></thead>)
    }

    const generateReportData = (title: string, data: any, practice: any) => {
        if(title === 'total-pt-count') {
            return data[practice].infoCurrentMonth.totalPTCount;
        }
        if(title === 'osDays') {
            return data[practice].infoCurrentMonth.osDays;
        }
        if(title === 'total-pt-count-age-15+') {
            return data[practice].infoCurrentMonth.totalPTCount15AndUp;
        }
        if(title === 'os-referrals') {
            return data[practice].infoCurrentMonth.osReferrals;
        }
        if(title === 'perc15Up') {
            return data[practice].infoCurrentMonth.perc15Up;
        }
        if(title === 'scheduled-patients-count') {
            return data[practice].infoCurrentMonth.patientScheduledCount;
        }
        if(title === 'percPatientScheduled') {
            return data[practice].infoCurrentMonth.percPatientScheduled;
        }
        if(title === 'arrived-patients-count') {
            return data[practice].infoCurrentMonth.patientArrivedCount;
        }
        if(title === 'percPatientsArrived') {
            return data[practice].infoCurrentMonth.percPatientsArrived;
        }
        if(title === 'patients-not-receiving-care-count') {
            return data[practice].infoCurrentMonth.patientsNotReceivingCare;
        }
        if(title === 'percPatientNotReceivingCare') {
            return data[practice].infoCurrentMonth.percPatientNotReceivingCare;
        }
        if(title === 'completed-procedures-count') {
            return data[practice].infoCurrentMonth.patientsCompletedProcedures;
        }

        if(title === 'percPatientReceivingCare') {
            return data[practice].infoCurrentMonth.percPatientReceivingCare;
        }
    }
    const getTableBody = () => {
        let returnArray: React.ReactElement[] = []


        if(reportData) {
            Object.values(props.practices).filter((practice: any, key) => reportData && reportData[practice.practiceDBName] &&
                returnArray.push(
                    <tr key={key}>
                        <td colSpan={1} className={'title'}>{practice.practice}</td>
                        <td>{generateReportData('osDays', reportData, practice.practiceDBName)}</td>
                        <td>{generateReportData('total-pt-count', reportData, practice.practiceDBName)}</td>
                        <td>{generateReportData('total-pt-count-age-15+', reportData, practice.practiceDBName)}</td>
                        <td>{generateReportData('os-referrals', reportData, practice.practiceDBName)}</td>
                        <td>
                            {
                                generateReportData('perc15Up', reportData, practice.practiceDBName) !== '0.00%' ?
                                    <div
                                        className={'btn clickable'}
                                        onClick={() => {
                                                setNumerator(Number(generateReportData('os-referrals', reportData, practice.practiceDBName)));
                                                setDenominator(Number(generateReportData('total-pt-count-age-15+', reportData, practice.practiceDBName)));
                                                setType('Patients Aged 15+ - Oral Referrals');
                                                setOpenModal(true);
                                            }
                                        }
                                    >
                                        {generateReportData('perc15Up', reportData, practice.practiceDBName)}
                                    </div> :
                                    '0%'
                            }
                        </td>
                        <td>
                            {
                                generateReportData('percPatientScheduled', reportData, practice.practiceDBName) !== '0.00%' ?
                                    <div
                                        className={'btn clickable'}
                                        onClick={() => {
                                                setNumerator(Number(generateReportData('scheduled-patients-count', reportData, practice.practiceDBName)));
                                                setDenominator(Number(generateReportData('os-referrals', reportData, practice.practiceDBName)));
                                                setType('Scheduled Patients - Oral Referrals');
                                                setOpenModal(true);
                                            }
                                        }
                                    >
                                        {generateReportData('percPatientScheduled', reportData, practice.practiceDBName)}
                                    </div> :
                                    '0%'
                            }
                        </td>
                        <td>
                            {
                                generateReportData('percPatientsArrived', reportData, practice.practiceDBName) !== '0.00%' ?
                                    <div
                                        className={'btn clickable'}
                                        onClick={() => {
                                            setNumerator(Number(generateReportData('arrived-patients-count', reportData, practice.practiceDBName)));
                                            setDenominator(Number(generateReportData('scheduled-patients-count', reportData, practice.practiceDBName)));
                                            setType('Scheduled Patients That Arrived - Scheduled Patients');
                                            setOpenModal(true);
                                        }
                                        }
                                    >
                                        {generateReportData('percPatientsArrived', reportData, practice.practiceDBName)}
                                    </div> :
                                    '0%'
                            }
                        </td>
                        <td>
                            {
                                generateReportData('percPatientNotReceivingCare', reportData, practice.practiceDBName) !== '0.00%' ?
                                    <div
                                        className={'btn clickable'}
                                        onClick={() => {
                                            setNumerator(Number(generateReportData('patients-not-receiving-care-count', reportData, practice.practiceDBName)));
                                            setDenominator(Number(generateReportData('scheduled-patients-count', reportData, practice.practiceDBName)));
                                            setType('Patients Not Receiving Care - Scheduled Patients');
                                            setOpenModal(true);
                                        }
                                        }
                                    >
                                        {generateReportData('percPatientNotReceivingCare', reportData, practice.practiceDBName)}
                                    </div> :
                                    '0%'
                            }
                        </td>
                        <td>
                            {
                                generateReportData('percPatientReceivingCare', reportData, practice.practiceDBName) !== '0.00%' ?
                                    <div
                                        className={'btn clickable'}
                                        onClick={() => {
                                            setNumerator(Number(generateReportData('completed-procedures-count', reportData, practice.practiceDBName)));
                                            setDenominator(Number(generateReportData('os-referrals', reportData, practice.practiceDBName)));
                                            setType('Patients Receiving Care - Oral Referrals');
                                            setOpenModal(true);
                                        }
                                        }
                                    >
                                        {generateReportData('percPatientReceivingCare', reportData, practice.practiceDBName)}
                                    </div> :
                                    '0%'
                            }
                        </td>
                    </tr>
                )
            );
        }
        if(returnArray.length) {
            return (<tbody>{returnArray}</tbody>)
        }
        else {
            return (
                <tbody>
                    <tr key={0} className={'text-center'}>
                        <td colSpan={12}>No data</td>
                    </tr>
                </tbody>
            )
        }
    }


    const getTableHeadingsPrior = () => {
        let returnArray: React.ReactElement[] = [];
        let info = [
            "Practices",
            "# of OS Days",
            "Total PT. Count",
            "Total Patient count age 15+",
            "# of OS referrals",
            "Referral % (patients age 15 and up)",
            "% of Referred Patients Scheduled",
            "% of Scheduled Patients that Arrived",
            "% of Patients not receiving care",
            "% of Referrals receiving care"
        ];
        info.forEach((item: any, key: number) => {
            returnArray.push(
                <th
                    key={key}
                    className={`cursorPointer`}
                >
                    {item}
                </th>
            );
        });
        return (<thead><tr>{returnArray}</tr></thead>)
    }

    const generateReportDataPrior = (title: string, data: any, practice: any) => {
        if(title === 'total-pt-count') {
            return data[practice].priorMonth.totalPTCount;
        }
        if(title === 'osDays') {
            return data[practice].priorMonth.osDays;
        }
        if(title === 'total-pt-count-age-15+') {
            return data[practice].priorMonth.totalPTCount15AndUp;
        }
        if(title === 'os-referrals') {
            return data[practice].priorMonth.osReferrals;
        }
        if(title === 'perc15Up') {
            return data[practice].priorMonth.perc15Up;
        }
        if(title === 'scheduled-patients-count') {
            return data[practice].priorMonth.patientScheduledCount;
        }
        if(title === 'percPatientScheduled') {
            return data[practice].priorMonth.percPatientScheduled;
        }
        if(title === 'arrived-patients-count') {
            return data[practice].priorMonth.patientArrivedCount;
        }
        if(title === 'percPatientsArrived') {
            return data[practice].priorMonth.percPatientsArrived;
        }
        if(title === 'patients-not-receiving-care-count') {
            return data[practice].priorMonth.patientsNotReceivingCare;
        }
        if(title === 'percPatientNotReceivingCare') {
            return data[practice].priorMonth.percPatientNotReceivingCare;
        }
        if(title === 'completed-procedures-count') {
            return data[practice].priorMonth.patientsCompletedProcedures;
        }

        if(title === 'percPatientReceivingCare') {
            return data[practice].priorMonth.percPatientReceivingCare;
        }
    }

    const getTableBodyPrior = () => {
        let returnArray: React.ReactElement[] = []


        if(reportData) {
            returnArray=Object.values(props.practices).filter((prac: any, ky) => reportData && reportData[prac.practiceDBName]).map( (practice:any,key)=> <tr key={key}>
                        <td colSpan={1} className={'title'}>{practice.practice}</td>
                        <td>{generateReportDataPrior('osDays', reportData, practice.practiceDBName)}</td>
                        <td>{generateReportDataPrior('total-pt-count', reportData, practice.practiceDBName)}</td>
                        <td>{generateReportDataPrior('total-pt-count-age-15+', reportData, practice.practiceDBName)}</td>
                        <td>{generateReportDataPrior('os-referrals', reportData, practice.practiceDBName)}</td>
                        <td>
                            {
                                generateReportDataPrior('perc15Up', reportData, practice.practiceDBName) !== '0.00%' ?
                                    <div
                                        className={'btn clickable'}
                                        onClick={() => {
                                            setNumerator(Number(generateReportDataPrior('os-referrals', reportData, practice.practiceDBName)));
                                            setDenominator(Number(generateReportDataPrior('total-pt-count-age-15+', reportData, practice.practiceDBName)));
                                            setType('Patients Aged 15+ - Oral Referrals');
                                            setOpenModal(true);
                                        }
                                        }
                                    >
                                        {generateReportDataPrior('perc15Up', reportData, practice.practiceDBName)}
                                    </div> :
                                    '0%'
                            }
                        </td>
                        <td>
                            {
                                generateReportDataPrior('percPatientScheduled', reportData, practice.practiceDBName) !== '0.00%' ?
                                    <div
                                        className={'btn clickable'}
                                        onClick={() => {
                                            setNumerator(Number(generateReportDataPrior('scheduled-patients-count', reportData, practice.practiceDBName)));
                                            setDenominator(Number(generateReportDataPrior('os-referrals', reportData, practice.practiceDBName)));
                                            setType('Scheduled Patients - Oral Referrals');
                                            setOpenModal(true);
                                        }
                                        }
                                    >
                                        {generateReportDataPrior('percPatientScheduled', reportData, practice.practiceDBName)}
                                    </div> :
                                    '0%'
                            }
                        </td>
                        <td>
                            {
                                generateReportDataPrior('percPatientsArrived', reportData, practice.practiceDBName) !== '0.00%' ?
                                    <div
                                        className={'btn clickable'}
                                        onClick={() => {
                                            setNumerator(Number(generateReportDataPrior('arrived-patients-count', reportData, practice.practiceDBName)));
                                            setDenominator(Number(generateReportDataPrior('scheduled-patients-count', reportData, practice.practiceDBName)));
                                            setType('Scheduled Patients That Arrived - Scheduled Patients');
                                            setOpenModal(true);
                                        }
                                        }
                                    >
                                        {generateReportDataPrior('percPatientsArrived', reportData, practice.practiceDBName)}
                                    </div> :
                                    '0%'
                            }
                        </td>
                        <td>
                            {
                                generateReportDataPrior('percPatientNotReceivingCare', reportData, practice.practiceDBName) !== '0.00%' ?
                                    <div
                                        className={'btn clickable'}
                                        onClick={() => {
                                            setNumerator(Number(generateReportDataPrior('patients-not-receiving-care-count', reportData, practice.practiceDBName)));
                                            setDenominator(Number(generateReportDataPrior('scheduled-patients-count', reportData, practice.practiceDBName)));
                                            setType('Patients Not Receiving Care - Scheduled Patients');
                                            setOpenModal(true);
                                        }
                                        }
                                    >
                                        {generateReportDataPrior('percPatientNotReceivingCare', reportData, practice.practiceDBName)}
                                    </div> :
                                    '0%'
                            }
                        </td>
                        <td>
                            {
                                generateReportDataPrior('percPatientReceivingCare', reportData, practice.practiceDBName) !== '0.00%' ?
                                    <div
                                        className={'btn clickable'}
                                        onClick={() => {
                                            setNumerator(Number(generateReportDataPrior('completed-procedures-count', reportData, practice.practiceDBName)));
                                            setDenominator(Number(generateReportDataPrior('os-referrals', reportData, practice.practiceDBName)));
                                            setType('Patients Receiving Care - Oral Referrals');
                                            setOpenModal(true);
                                        }
                                        }
                                    >
                                        {generateReportDataPrior('percPatientReceivingCare', reportData, practice.practiceDBName)}
                                    </div> :
                                    '0%'
                            }
                        </td>
                    </tr>
            );
        }
        if(returnArray.length) {
            return (<tbody>{returnArray}</tbody>)
        }
        else {
            return (
                <tbody>
                <tr key={0} className={'text-center'}>
                    <td colSpan={12}>No data</td>
                </tr>
                </tbody>
            )
        }
    }

    const calculationModal = (numerator: any, denominator: any, type: any) => {
        const titleType = split(type, '-')[0];
        const titleSubTitle = split(type, '-')[1];
        return (
            <Modal
                open={openModal}
                closeIcon={true}
                onClose={() => setOpenModal(false)}
            >
                <Modal.Header>
                    {titleType} Calculation
                </Modal.Header>
                <Modal.Content>
                    <div>
                        <strong> Out
                            of {denominator} {titleSubTitle} </strong> | <strong> {numerator} = {titleType} </strong>
                    </div>
                    <br/>
                    <div> ({numerator} / {denominator}) x 100 = {Number((numerator / denominator * 100).toFixed(2))}%
                    </div>
                </Modal.Content>
            </Modal>
        );
    }

    return (
        <>
            {calculationModal(numerator, denominator, type)}
            <div className="ui card">
                <div className="content">
                    <h4 className="mt-5" >{OSPracticesReportTypesInfo[0]}</h4>
                </div>
            </div>
            <div className="table-responsive FreezeTable">

                <table className="table celled single line table unstackable TableHeaderFixed customReferralTable">
                    {getTableHeadings()}
                    {getTableBody()}
                </table>
            </div>

            <div className="ui card">
                <div className="content">
                    <h4 className="mt-5">{OSPracticesReportTypesInfo[1]}</h4>
                </div>
            </div>

            <div className="table-responsive FreezeTable">

                <table className="table celled single line table unstackable TableHeaderFixed customReferralTable">
                    {getTableHeadingsPrior()}
                    {getTableBodyPrior()}
                </table>
            </div>
        </>
    )
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        practice: state.practice.practiceList
    };
}
export default connect(mapStateToProps)(DynamicReferralDataTable);