import * as React from "react";
import {Redirect, Route} from "react-router";
import SignIn from "./container/SignIn";
import Dashboard from "./container/Dashboard";
import ClinicalSchedulingReport from './container/Reports/ClinicalSchedulingReport';
import ByPractice from "./container/Reports/ByPractice";
import ByAge from "./container/Reports/ByAge";
import ByInsurance from "./container/Reports/ByInsurance";
import ByInsuranceMix from "./container/Reports/ByInsuranceMix";
import ByAgeMix from "./container/Reports/ByAgeMix";
import BySplashboard from "./container/Reports/BySplashboard";
import ByProviderCpv from "./container/Reports/ByProviderCpv";
import ByOutlook from "./container/Reports/ByOutlook";
import ByBudgetByWorkday from "./container/Reports/ByBudgetByWorkday";
import ByRegionRollUp from "./container/Reports/ByRegionRollUp";
import ByCpv from "./container/Reports/ByCpv";
import UserMatrixReport from './container/Reports/UserMatrixReport';
import AdjustmentSetup from "./components/AdjustmentSetup";
import AdjustmentConfirm from "./components/AdjustmentConfirm";
import HygienistBracketSystem from "./components/HygienistBracketSystem";
import DrAppPermissionMatrix from "./components/DrAppPermissionMatrix";
import AdjustmentAcknowledgement from "./components/AdjustmentAcknowledgement";
import {PrivateRoute, PrivateRouteProps} from "./components/PrivateRoute";
import Five9List from "./components/Five9List";
import OpenSurgeryPCRReport from "./container/Reports/OpenSurgeryPCRReport";
import OpenSurgeryDashboardPCRReport from "./container/Reports/OpenSurgeryDashboardPCRReport";
import PatientValidation from "./components/PatientValidation";
import PatientValidationActionReport from "./container/Reports/PatientValidationActionReport";
import PracticeManagerDashboard from "./components/PracticeManagerDashboard";
import PatientValidationStatusReport from "./container/Reports/PatientValidationStatusReport";
import EmailConfigurations from "./components/EmailConfigurations";
import DownTimeTracker from "./components/appdowntimetracker";
import PracticeAdminList from "./components/PracticeAdminSetupList";

import PatientPracticeLogsReport from "./container/Reports/PatientPracticeLogsReport";


import MDMInfo from './container/MDMInfo';
import UsageReport from './components/UsageReport';
import UsageReportPTV from './components/UsageReportPTV';
import MDHwriteOffReport from "./components/MDHwriteOffReport";

import MDHwriteOffMDHM$HistoryReport from "./components/MDHwriteOffMDHM$HistoryReport";
import MDHwriteOffMDHMHistoryReport from "./components/MDHwriteOffMDHMHistoryReport";
import MDHwriteOffMDHM$byWeekdayReport from "./components/MDHwriteOffMDHM$byWeekdayReport";
import MDHwriteOffMDHMUsagevsWOReport from "./components/MDHwriteOffMDHMUsagevsWOReport";

import LoginForm from "./components/LoginForm";
import PatientValidationErrorReport from "./components/PatientValidationErrorReport";
import XrayServiceDashboard from "./components/XrayServiceDashboard";
import SporeServiceDashboard from "./components/SporeDashboard";
import TableNotifications from "./components/SporeNotificationsTable"
import RampThresholdDefaults from "./components/RampThresholdDefaults";

import ManageHelp from "./container/Help";
import UserManagement from './components/Users/index'
import EducatorNotifications from "./components/EducatorNotifications";
import AvailableApiList from "./components/AvailableApiList";
import McoLoginReport from "./components/McoLoginReport";
import McoScrapper from "./components/McoScrapper";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import {history} from "./redux/configure";
import CronsList from "./components/CronsList";
import OrthoPCRReport from "./container/Reports/OrthoPCRReport";
import ProcedureCodeChanger from "./components/ProcedureCodeChanger";

import PcrBudget from "./components/PcrBudget";
import OralSurgeryBudget from "./components/OralSurgeryBudget";
import ReferralDataOsPractices from "./container/Reports/ReferralDataOsPractices";
import ReferralDataFutureOsOffices from "./container/Reports/ReferralDataFutureOsOffices";

import SpecialityPCO from './container/Reports/SpecialityPCO';
import AdjustmentList from "./components/AdjustmentList";
import MondayApp from './container/MondayApp';
import WriteOffList from './components/WriteOffList';
import GOXProcedureCodeList from './components/GOXProcedureCodeList';
/* import GOZProcedureCodeList from './components/GOZProcedureCodeList'; */
import sageAccountList from './components/SageAccount'
import implantCostList from './components/ImplantCost'
import SpotBroken from "./container/Spot";
import UsageSPOT from "./components/UsageSPOT";
import SpotUnscheduledTreatment from "./components/SpotUnscheduledTreatment";
import PatientCountCareUploader from "./container/ManualUploads/PatientCountCare";
import {useActions} from "./redux/actions";
import * as Actions from "./redux/actions/partners";
import SpotDetails from "./container/Spot/SpotDetails";

import EmployeeUsageReport from "./components/EmployeeUsageReport";
import NonEmployeeUsageReport from "./components/NonEmployeeUsageReport";
import UniversalUsageReport from "./components/UniversalUsageReport";
import OpenDentalApisUsageReport from "./components/OpenDentalApisUsageReport";

import ByBrandRollUp from "./container/Reports/ByBrandRollUp";
import RerunPCR from "./components/RerunPCr";

import ProviderProfile from './container/ProviderProfile';
import ProviderEarningsReport from "./components/ProviderEarningsReport";
import * as authActions from './redux/actions/auth';
import {getCookie, setCookie, validObj} from "./utils/common";

import AWSLegacyWebsites from "./container/AWSWebsites";
import SingleAWSWebsite from './container/AWSWebsites/Redesign/SingleSite'
import AWSWebsites from "./container/AWSWebsites/Redesign/Index";
import AWSWebsitesReports from "./container/AWSWebsites/Redesign/Reports";

import FinancialNoteCategoryList from "./components/FinancialNoteCategory";
import LambdaList from "./components/lambdas";
import PassCodes from "./components/PassCodes";
import DistrictsList from "./components/DistrictsList";

import Regions from "./components/Regions"
import providerAccounts from "./components/Providers/providerAccounts"
import SpotPatients from "./components/SpotPatients";
import SpotPatientRetention from "./components/SpotPatientRetention";
import HealthStatus from "./container/HealthStatus";
import OrthoProviderEarningsReport from "./components/OrthoProviderEarningsReport";
import OrganizationalChart from "./container/OrganizationalChart";
import UnderConstruction from "./components/UnderConstruction";
import SpotWork from "./components/SpotWork";

import PayrollList from "./components/Payroll/PayrollList";
import PayStatements from "./components/Payroll/PayStatements";
import OpenDentalUsers from "./components/OpenDentalUsers";
import CreateOpenDentalUser from "./components/CreateOpenDentalUser";

const isDevelopment = process.env.NODE_ENV === "development";

function AppRoutes(props: any) {
    const getcookie = getCookie('cdp-cookie');
    const cookie: any = getcookie ? JSON.parse(getcookie) : null;
    const authAction = useActions(authActions);

    if (validObj(cookie) && !validObj(props.auth) && !!isDevelopment) {
        authAction.cdpwebTokenLogin(cookie);
    }

    const isAuthenticated = !!(props.auth && props.auth.user && props.auth.user.id);
    const defaultProtectedRouteProps: PrivateRouteProps = {
        isAuthenticated: isAuthenticated,
        authenticationPath: "/"
    };
    const partnerActions = useActions(Actions);

    history.listen(() => {
        ReactGA.pageview(history.location.pathname + history.location.search);
        ReactGA4.send({hitType: "pageview", page: history.location.pathname + history.location.search});
    })

    if (isAuthenticated && !isDevelopment) {
        const value = {
            email: props.auth.user.email,
            givenName: props.auth.user.firstname,
            imageUrl: props.auth.user.profilePicture,
        }
        setCookie('cdp-cookie', JSON.stringify(value), 7);
    }

    isAuthenticated && history.listen((location: any) => {
        if (props && props.auth && props.auth.user && props.auth.user.id) {
            const {accessToken} = props.auth;
            const {pathname} = location;
            const {id, defaultPractice, defualtPracticeId} = props.auth.user;
            const usagePayLoad = {
                userId: id,
                defualtPracticeId: defaultPractice,
                visitedUrl: `my.cdp.dental${pathname}`,
                token: accessToken
            }
            pathname !== '/partners' && partnerActions.saveApplicationUsage(usagePayLoad)
        }

    })
    const RedirectTo = (props: any) => (isAuthenticated) ? <Redirect to="/partners"/> : <SignIn {...props} />;


    return (
        <div>
            <Route
                exact={true}
                path="/"
                render={renderProps => RedirectTo(renderProps)}
            />

            <Route
                exact={true}
                path="/login"
                component={LoginForm}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                exact={true}
                path="/partners"
                component={Dashboard}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patient-care-report/pcr-by-dr-practice"
                component={ByPractice}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patient-care-report/pcr-age"
                component={ByAge}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patient-care-report/pcr-insurance"
                component={ByInsurance}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patient-care-report/pcr-insurance-mix"
                component={ByInsuranceMix}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patient-care-report/pcr-age-mix"
                component={ByAgeMix}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patient-care-report/pcr-splashboard"
                component={BySplashboard}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patient-care-report/pcr-provider-cpv"
                component={ByProviderCpv}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patient-care-report/pcr-goal-by-workday"
                component={ByBudgetByWorkday}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patient-care-report/pcr-outlook"
                component={ByOutlook}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patient-care-report/pcr-cpv"
                component={ByCpv}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patient-care-report/pcr-by-region-roll-up"
                component={ByRegionRollUp}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/clinical-scheduling-report"
                component={ClinicalSchedulingReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/adjustment/adjustment-acknowledgement"
                component={AdjustmentAcknowledgement}
            />

            <Route
                path="/adjustment/confirm/:token"
                component={AdjustmentConfirm}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/five9/five9list"
                component={Five9List}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patients/patient-validation"
                component={PatientValidation}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/mdm"
                component={MDMInfo}
            />


            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/usage"
                component={UsageReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/open-surgery-pcr-report"
                component={OpenSurgeryPCRReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/open-surgery-dashboard"
                component={OpenSurgeryDashboardPCRReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patients/usage-ptv"
                component={UsageReportPTV}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/practice-manager-dashboard"
                component={PracticeManagerDashboard}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patients/patient-report/patient-action-report"
                component={PatientValidationActionReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patients/patient-report/patient-status-report"
                component={PatientValidationStatusReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patients/patient-practice-logs/patient-validation-practice-logs-report"
                component={PatientPracticeLogsReport}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patients/mdhm-wo-reports/mdhm-writeoff-report"
                component={MDHwriteOffReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patients/mdhm-wo-reports/mdhm-wo-$history-report"
                component={MDHwriteOffMDHM$HistoryReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patients/mdhm-wo-reports/mdhm-wo-history-report"
                component={MDHwriteOffMDHMHistoryReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patients/mdhm-wo-reports/mdhm-wo-$by-weekday-report"
                component={MDHwriteOffMDHM$byWeekdayReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patients/mdhm-wo-reports/mdhm--usage-vs-wo-report"
                component={MDHwriteOffMDHMUsagevsWOReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patients/patient-report/patient-error-report"
                component={PatientValidationErrorReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/sporeService"
                component={SporeServiceDashboard}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/sporenotifications"
                component={TableNotifications}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin"
                component={UserManagement}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/xray-service-dashboard"
                component={XrayServiceDashboard}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/available-api-list"
                component={AvailableApiList}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/mfa/:mco"
                component={PassCodes}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/crons"
                component={CronsList}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/lambdas"
                component={LambdaList}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/goal/oral-surgery-goal"
                component={OralSurgeryBudget}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/goal/pcr-goal"
                component={PcrBudget}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/re-run-pcr"
                component={RerunPCR}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/mdm"
                component={MDMInfo}
            />
            
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/five9-list"
                component={Five9List}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/adjustment"
                component={AdjustmentSetup}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/mco-login-report"
                component={McoLoginReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/mco-scrapper"
                component={McoScrapper}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/adjustment-list"
                component={AdjustmentList}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/write-off-list"
                component={WriteOffList}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/gox-procedure-code-list"
                component={GOXProcedureCodeList}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/goz-procedure-code-list"
                component={GOXProcedureCodeList}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/practice-admin-list"
                component={PracticeAdminList}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/districts"
                component={DistrictsList}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/sage-accounts-description"
                component={sageAccountList}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/implant-cost"
                component={implantCostList}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/financial-note-category"
                component={FinancialNoteCategoryList}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/email-configurations"
                component={EmailConfigurations}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/app-status-tracker"
                component={DownTimeTracker}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/educator-notifications"
                component={EducatorNotifications}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/procedure-code-change"
                component={ProcedureCodeChanger}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/manager-help"
                component={ManageHelp}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/hygienist-bracket-system"
                component={HygienistBracketSystem}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/usage/universal-usage-report"
                component={UniversalUsageReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/usage/employee-usage-report"
                component={EmployeeUsageReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/usage/non-employee-usage-report"
                component={NonEmployeeUsageReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/usage/od-apis-usage-report"
                component={OpenDentalApisUsageReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/user-matrix-report"
                component={UserMatrixReport}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/ramp-threshold-defaults"
                component={RampThresholdDefaults}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/provider-earnings-report"
                component={ProviderEarningsReport}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/ortho-pcr-report"
                component={OrthoPCRReport}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/referral-data-os-practices"
                component={ReferralDataOsPractices}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/referral-data-future-os-offices"
                component={ReferralDataFutureOsOffices}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/specialty-pco"
                component={SpecialityPCO}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/ion-tasks"
                component={MondayApp}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/org-chart"
                component={OrganizationalChart}
            />


            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/ukg/monthly"
                component={PayrollList}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/ukg/cumulative"
                component={PayStatements}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/spot/support-patient-opportunity-tool"
                component={SpotBroken}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/spot/usage-spot"
                component={UsageSPOT}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/spot/90-days-unscheduled-treatment"
                component={SpotUnscheduledTreatment}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/spot/spot-patients"
                component={SpotPatients}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/spot/spot-patient-retention"
                component={SpotPatientRetention}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/spot/spot-work"
                component={SpotWork}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/spot/spot-details/:practice/:patientId"
                component={SpotDetails}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/dr-app-permission-matrix"
                component={DrAppPermissionMatrix}
            />

                <PrivateRoute
                    {...defaultProtectedRouteProps}
                    path="/admin/setup/open-dental-users/update-user"
                    component={OpenDentalUsers}
                />

                <PrivateRoute
                    {...defaultProtectedRouteProps}
                    path="/admin/setup/open-dental-users/create-user"
                    component={CreateOpenDentalUser}
                />


            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/patient-care-report/pcr-by-brand-roll-up"
                component={ByBrandRollUp}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/manual-uploads/pt-count-care"
                component={PatientCountCareUploader}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/educator/educator-notifications"
                component={EducatorNotifications}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/provider-profiles"
                component={ProviderProfile}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/aws/websites"
                component={AWSWebsites}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/aws/websites/legacy"
                component={AWSLegacyWebsites}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/aws/websites/reports"
                component={AWSWebsitesReports}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/aws/websites/view/:siteId"
                component={SingleAWSWebsite}
            />


            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/regions"
                component={Regions}
            />
            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/sofd/mco-login-report/logins"
                component={providerAccounts}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/health-status"
                component={HealthStatus}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/ortho-provider-earnings-report"
                component={OrthoProviderEarningsReport}
            />

            <PrivateRoute
                {...defaultProtectedRouteProps}
                path="/admin/setup/brands"
                component={UnderConstruction}
            />
        </div>
    );
}

export default AppRoutes;
