import { ActionType } from "../../model/actionTypes";
import {Five9Details} from "../../model/five9List";
import {errorHandler} from "./auth";

export const fetchCallCenterPractices = (filterPractice:Function) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const response = await api.get(`five9/getCallCenterPractices`);
		const result = filterPractice(response);
		dispatch({ type: ActionType.FETCH_CALL_CENTER_PRACTICES, payload: { practiceName:result.practiceNames, practiceOptions:result.practiceOptions, callCenterPractice: response } });
		dispatch({ type: ActionType.LOADER, payload: false });
		return true;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		dispatch({ type: ActionType.FETCH_CALL_CENTER_PRACTICES, payload: { callCenterPractice: [] } });
		return dispatch(errorHandler(err));
	}
};

export const fetchFive9List = () => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response = await api.get(`five9/getFive9List`);
		dispatch({ type: ActionType.FETCH_FIVE9_LIST, payload: { five9Lists: response } });
		return response;
	} catch (err) {
		dispatch({ type: ActionType.FETCH_FIVE9_LIST, payload: { five9Lists: [] } });
		return dispatch(errorHandler(err));
	}
};

export const getFive9PracticeList = (filterFive9List:Function) => async (dispatch: Function, getState: Function, api: any) => {
	try {
		const response = await api.get(`five9/getFive9PracticeList`);
		const five9List = filterFive9List(response);
		dispatch({ type: ActionType.FETCH_FIVE9_PRACTICE_LIST, payload: { five9PracticeList: response, five9ListOptions:five9List.five9ListOptions, five9counts:five9List.five9counts } });
		return five9List.five9counts;
	} catch (err) {
		dispatch({ type: ActionType.FETCH_FIVE9_PRACTICE_LIST, payload: { five9PracticeList: [] } });
		return dispatch(errorHandler(err));
	}
};

export const saveFive9Details = (obj: Five9Details) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("five9/saveFive9List", obj);
		dispatch({ type: ActionType.FIVE9_SAVE_SUCCESS, payload: resp });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const updateFive9Details = (obj: Five9Details) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("five9/updateFive9List", obj);
		dispatch({ type: ActionType.FIVE9_SAVE_SUCCESS, payload: resp });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const deleteFive9Details = (obj: Five9Details) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.delete("five9/deleteFive9List", obj);
		dispatch({ type: ActionType.FIVE9_SAVE_SUCCESS, payload: resp });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const purgeFive9Details = (obj: Five9Details) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("five9/purgeFive9PracticeList", obj);
		dispatch({ type: ActionType.FIVE9_SAVE_SUCCESS, payload: resp });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};

export const importDataInFive9List = (obj: Five9Details) => async (dispatch: Function, getState: Function, api: any) => {
	dispatch({ type: ActionType.LOADER, payload: true });
	try {
		const resp =  await api.post("five9/bulkImportInFive9List", obj);
		dispatch({ type: ActionType.FIVE9_SAVE_SUCCESS, payload: resp });
		dispatch({ type: ActionType.LOADER, payload: false });
		return resp;
	} catch (err) {
		dispatch({ type: ActionType.LOADER, payload: false });
		return dispatch(errorHandler(err));
	}
};
