import React, {useState} from 'react';
import {Button, Dropdown, Form, Icon} from "semantic-ui-react";
import {PracticeList} from "../../../model/practice";
import ExcelDownloader from "./ExcelDownloader";
import ExcelUploader from "./ExcelUploader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {FilterOptions} from '../../../constants';
import moment from 'moment';

interface FiltersAndOptionsProps {
    filterProps: {
        date: any,
        data: any[],
        allData: any[],
        practices: PracticeList[],
        save?: (data: any) => {},
        onFilterChange: any
        onRefreshPCR: Function
    }
}

function FiltersAndOptions(props: FiltersAndOptionsProps) {
    const {filterProps} = props;
    const [date, setDate] = useState("")
    const practiceDropdownOptions = () => {
        return [{text: "All", value: "All"}, ...filterProps.practices.map(f => ({text: f.practice, value: f.id}))];
    }
    const onPracticeChange = (_e: any, d: any) => {
        filterProps.onFilterChange({filter: FilterOptions.PracticeFilter, filterValue: d.value});
    }
    const onDateChanged = (date: any) => {
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : "";
        setDate(formattedDate);
        filterProps.onFilterChange({filter: FilterOptions.DateFilter, filterValue: date});
    }
    return (<>

        <ExcelDownloader date={filterProps.date} allData={filterProps.allData} data={filterProps.data}
                         practices={filterProps.practices}/>

        <ExcelUploader data={filterProps.data} practices={filterProps.practices} save={filterProps.save}/>

        <Button className="mr10 mb15" primary={true} content={'Refresh'} onClick={() => filterProps.onRefreshPCR()}/>

        <Dropdown className="mr10 mb15" options={practiceDropdownOptions()}  selection={true} search={true}
                  onChange={onPracticeChange} labeled placeholder='Select Practice'/>
        <div className="datePicker mb15">
            <DatePicker name="date" onChange={onDateChanged} isClearable placeholderText='Select Date' value={date}/>
        </div>
    </>);
}


export default FiltersAndOptions;