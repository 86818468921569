import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";


const initialState:any={
    unscheduledTreatmentDetails : [],
    spotPatients : [],
}
export const spot = createReducer(initialState, {
    [ActionType.LOAD_UNSCHEDULED_TREATMENT](state: any, action: Action<any>) {
      return Object.assign({...state}, action.payload);
    },
    [ActionType.LOAD_SPOT_PATIENTS](state: any, action: Action<any>) {
        return Object.assign({...state}, action.payload);
    },
    [ActionType.LOAD_SPOT_PATIENT_RETENTION](state: any, action: Action<any>) {
        return Object.assign({...state}, action.payload);
    },
    [ActionType.LOAD_SPOT_WORK_REPORT](state: any, action: Action<any>) {
        return Object.assign({...state}, action.payload);
    },

  });