import React, {useEffect, useState} from "react";
import {Button, Checkbox, Confirm, Dropdown, Modal, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import _ from 'lodash';
import moment from "moment";
import {brands} from "../../constants";
import {convertToCSV} from "../../utils/common";
import {RootState} from "../../model";
import HelpMark from "../HelpMark";
import {useActions} from "../../redux/actions";
import * as ReportActions from "../../redux/actions/report"

interface ScrappersProps {
    mcoList: any
}

const initialState: any = {
    confirm: false,
    successModal: false,
    selectedMco: [],
    providers: [],
    filteredProviders: [],
    practices: [],
    mcos: [],
    filter: {},
    viewMco: false,
}

const initialFilters: any = {practices: [], mcos: [], brands: []}

const McoScrapperForm = (props: ScrappersProps) => {
    const reportActions = useActions(ReportActions);
    const [state, setStates] = useState(initialState);
    const [filters, setFilters] = useState(initialFilters);
    const [allpractices, setAllFilterData] = useState<any>(null);

    useEffect(() => {
        reportActions.getMcoListWithStats().then((data: any) => load(data.providers))
    },[]);

    const load = (providers: any[]) => {
        if (!Array.isArray(providers)) return;

        const omitPractices = ['Victoria Kids', 'Crossroads', 'DAL NW HWY']

        providers = providers.filter(provider => provider.mco !== 'SPORE' && provider.mco !== 'TMHP' && !omitPractices.includes(provider.practice));
        setStates({...state, providers, filteredProviders: providers});
        setAllFilterData(providers);
        loadFilters(providers);
    }

    const loadFilters = (providers: any[]) => {
        const practices = getFilterValues(providers, 'practice');
        const mcos = getFilterValues(providers, 'mco');

        const brand = brands.map((val:any, index:any) => ({
            value: val.value == 'FSD' ? "Family Smiles" : (val.value === 'SMD' ? 'Smile Magic' :
                val.value === '3CD' ? '3C Dental' : val.value == 'OTD' ? 'OrthoDent' : val.value === 'SDD' ? 'SDD' : val.value === 'SYN' ? 'SYN' : 'All'),
            text: val.value,
            key: val.value
        }));
        setFilters({practices, mcos, brands: brand});
    }

    const getFilterValues = (providers: any[], name: string) => {
        const set = new Set((providers || []).map((provider: any) => provider[name]));
        return Array.from(set)
            .sort()
            .map((val, index) => ({value: val, text: val, key: `${name}_${index}`}));
    }

    const handleLoginCheck = () => {
        const providers = state.selectedMco;
        reportActions.mcoScrapperRun({providers})
            .then(() => {
                setStates({...state, confirm: false, successModal: true})
            });
    }

    const hideSuccessModal = () => {
        setStates({...state, successModal: false})
    }
    const hideDetailsModal = () => {
        setStates({...state, viewMco: false})
    }

    const handleCancel = () => {
        setStates({...state, confirm: false});
    }

    const handleConfirm = () => {
        const providers = state.selectedMco;
        if (providers.length) {
            setStates({...state, confirm: true});
        } else {
            alert('Please select at least one MCO provider!')
        }
    }

    const handleCheckBox = (checked: any, id: number) => {
        const selectedMco = state.selectedMco;
        if (checked) {
            if (!selectedMco.includes(id))
                selectedMco.push(id);
        } else {
            const index = selectedMco.indexOf(id);
            selectedMco.splice(index, 1);
        }
        setStates({...state, selectedMco});
    };

    const handleCheckAll = (checked: boolean | undefined) =>
        state.filteredProviders.forEach((provider: any) => handleCheckBox(checked, provider.id));


    const onFilterChange = (e: any, obj: any) => {
        if (obj.value !== 'All') {
            state.filter[obj.name] = obj.value;
            setStates(() => ({...state}));
            loadFiltered(state.filter);
        } else {
            state.filter[obj.name] = obj.value;
            setStates({...state, filteredProviders: allpractices});
        }
    }

    const loadFiltered = (filter = state.filter, providers = state.providers) => {
        let keys = Object.keys(filter);
        if (filter.practice === 'All') {
            keys = keys.filter((item) => item !== 'practice')
        }
        let filteredProviders = providers
            .filter((provider: any) => keys.every(key => !filter[key] || provider[key] === filter[key]));
        filteredProviders = _.sortBy(filteredProviders, ['practice']);
        setStates({...state, providers, filteredProviders});
    }

    const onClearFilter = (e: any) => {
        Object.keys(state.filter).forEach((name) => state.filter[name] = null)
        setStates({...state});
        loadFiltered(state.filter);
    }

    const viewMoreHandler = (mcoDetails: any) => {
        setStates({...state, viewMco: mcoDetails});
    }

    const handleDownload = (item: any, type: string) => {
        const provider = {
            practice : item.practiceabbr,
            mco : item.mco
        }
        reportActions.downloadMembersCSV(provider, type)
            .then((data: any) => {
                const csvContent = convertToCSV(data);
                const blob = new Blob([csvContent], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', provider.practice+'-'+type+'-'+provider.mco+'.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
    }

    const allSelected = state.filteredProviders.map((p: any) => p.id).every((id: number) => state.selectedMco.includes(id));
    const membersData = props.mcoList.members
    return (
        <>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left">MCO Scrapper <HelpMark pageId='82'/></h2>
                    <div className="topFilters">
                        <Button primary={true} onClick={onClearFilter}>Clear
                            Filters</Button>
                        <Button primary={true} onClick={() => handleConfirm()}>
                            Run Scrapper
                        </Button>
                        <Dropdown
                            search={true}
                            name="brand"
                            placeholder="Select Brand"
                            selection={true}
                            options={filters.brands}
                            onChange={onFilterChange}
                        />
                        <Dropdown
                            search={true}
                            name="practice"
                            placeholder="Select Practice"
                            selection={true}
                            options={[{key: 'All', value: 'All', text: 'All'}].concat(filters.practices)}
                            onChange={onFilterChange}
                            value={state.filter.practice}
                        />
                        <Dropdown
                            search={true}
                            name="mco"
                            placeholder="Select MCO"
                            selection={true}
                            options={filters.mcos}
                            onChange={onFilterChange}
                            value={state.filter.mco}
                        />

                        <Confirm
                            open={state.confirm}
                            header='Are you sure you want to run scrapper?'
                            onCancel={handleCancel}
                            onConfirm={handleLoginCheck}
                            cancelButton='No'
                            confirmButton="Yes"
                            content='Clicking on Yes will initiate a request to lambda which will scrap the data for selected MCOs and upload the zip files on S3.'
                        />

                        {state.viewMco &&
                            <Modal open={true} closeIcon={true}  onClose={hideDetailsModal}>
                                <Modal.Header>Scrapper Details</Modal.Header>
                                <Modal.Content>
                                    <Modal.Description>
                                        <div className="FreezeTable">
                                            <Table className="ui table table-striped table-hover celled unstackable">
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell><div>Date</div> </Table.HeaderCell>
                                                        <Table.HeaderCell><div>Extracted Members</div> </Table.HeaderCell>
                                                        <Table.HeaderCell><div>Dropped</div> </Table.HeaderCell>
                                                        <Table.HeaderCell><div>New</div> </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {
                                                        state.viewMco && state.viewMco.McoMembers && state.viewMco.McoMembers.length > 0 &&
                                                        state.viewMco.McoMembers.map((item: any, index: any) => {
                                                            return (
                                                                <Table.Row key={index}>
                                                                    <Table.Cell>{moment(item.createdAt).format('YYYY-MM-DD')}</Table.Cell>
                                                                    <Table.Cell>{item.extractedMembersCount}</Table.Cell>
                                                                    <Table.Cell>{item.dropped}</Table.Cell>
                                                                    <Table.Cell>{item.latest}</Table.Cell>
                                                                </Table.Row>
                                                            )
                                                        })}
                                                </Table.Body>
                                            </Table>
                                        </div>
                                    </Modal.Description>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={() => hideDetailsModal()} negative={true}> Close </Button>
                                </Modal.Actions>
                            </Modal>}
                        {state.successModal &&
                            <Modal open={true}>
                                <Modal.Header>Success!</Modal.Header>
                                <Modal.Content>
                                    <Modal.Description>
                                        <p>Scrapping Request has been initiated!</p>
                                    </Modal.Description>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={() => hideSuccessModal()} positive={true}> Ok </Button>
                                </Modal.Actions>
                            </Modal>}
                    </div>
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    <div className="FreezeTable">
                        <Table className="ui table table-striped celled table-hover unstackable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        <div><Checkbox
                                            className='mr10'
                                            checked={allSelected}
                                            onChange={(e, {checked}) => handleCheckAll(checked)}
                                        />Practice
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>MCO</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>UserName</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Password</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Last Scrap Time</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Last Consolidation Time</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Members</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Extracted Members</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Dropped</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>New Members</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Actions</div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    state.filteredProviders && state.filteredProviders.length > 0 &&
                                    state.filteredProviders.map((item: any, index: any) => {
                                        const mostRecent = item.McoMembers && item.McoMembers[0];
                                        const memCount = membersData && membersData[item.practiceabbr] && membersData[item.practiceabbr][item.mco]
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell>
                                                    <Checkbox
                                                        key={item.id}
                                                        value={item.id}
                                                        className='mr10'
                                                        checked={state.selectedMco.includes(item.id)}
                                                        onChange={(e, {checked}) => handleCheckBox(checked, item.id)}
                                                    />
                                                    &nbsp;
                                                    {item.practice}
                                                </Table.Cell>
                                                <Table.Cell>{item.mco}</Table.Cell>
                                                <Table.Cell>{item.username}</Table.Cell>
                                                <Table.Cell>{item.password}</Table.Cell>
                                                <Table.Cell>{item.lastScrapTime && moment(item.lastScrapTime).format('YYYY-MM-DD')}</Table.Cell>
                                                <Table.Cell>{moment(item.lastConsolidationTime).format('YYYY-MM-DD')}</Table.Cell>
                                                <Table.Cell className={'cursorPointer'} onClick={() => handleDownload(item, 'members')}>{memCount}</Table.Cell>
                                                <Table.Cell className={'cursorPointer'} onClick={() => handleDownload(item, 'extracted')}>{mostRecent && mostRecent.extractedMembersCount}</Table.Cell>
                                                <Table.Cell>{mostRecent && mostRecent.dropped}</Table.Cell>
                                                <Table.Cell>{mostRecent && mostRecent.latest}</Table.Cell>
                                                <Table.Cell>
                                                    <Button primary={true} onClick={() => viewMoreHandler(item)}>View</Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
};

function mapStateToProps(state: RootState) {
    return {
        mcoList: state.report.mcoList,
    };
}

export default connect(mapStateToProps)(McoScrapperForm);
