import React, {useEffect, useState} from "react";
import {Form, Modal, Radio, Feed, Header, Dimmer, Loader} from "semantic-ui-react";
import {useActions} from "../redux/actions";
import * as patientValidationActions from "../redux/actions/patients";
import {RootState} from "../model";
import {connect} from "react-redux";
import moment from "moment";

interface Props {
  mdhmConfirmation: any,
  mdhmModal : any,
  mdhmModalSetData: any,
  isPTValidation: Boolean
}

function MDHMconfirmation(props: Props) {
  const patientValidationAction = useActions(patientValidationActions);

  const initialState : any = {
    communicationMode : 'call',
    description : '',
    status : 0,
  }

  const [state, setStates] = useState(initialState);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getLatestData();
  }, []);

  const handleChange = (value: any, name: string) => {
     state[name] = value;
     setStates({...state})
  }

  const getLatestData = () => {
    setLoader(true);
    const req = {
      type : props.mdhmModal.type,
      patNum: props.mdhmModal.patNum,
      practice: props.mdhmModal.practice,
      date : props.mdhmModal.date
    }
    patientValidationAction.getMDHMconfirmation(req).then((res: any) => {
      setLoader(false);
    })
  }

  const handleSubmit = () => {
    const obj = {
      type : props.mdhmModal.type,
      patNum: props.mdhmModal.patNum,
      practice: props.mdhmModal.practice,
      date : props.mdhmModal.date,
      communicationMode : state.communicationMode,
      status : state.status,
      description : state.description
    }
    if(state.description){
      patientValidationAction.addMDHMconfirmation(obj, props.isPTValidation).then((res: any) => {
        setStates(initialState);
        getLatestData();
        props.mdhmModalSetData({ show: false})
      })
    } else {
      setStates({...state, error: true});
    }

  }

  const getList = () => {
    let items:any = [];
    if(props.mdhmConfirmation.length) {
      props.mdhmConfirmation.forEach((item: any) => {
        if(props.mdhmConfirmation.length) {

          items.push(<Feed.Event key={item.id} className={'mb20'}>
              <Feed.Content>
                <Feed.Summary>
                  <strong><span className={'primaryColor'}>{item.users.firstname + ' ' + item.users.lastname}</span> added on </strong>
                  <Feed.Date>{moment(item.createdAt).format('LL')}</Feed.Date>
                </Feed.Summary>
                <Feed.Extra text={true}>
                  {props.mdhmModal.type === 'home' ?
                    <p className={'mb0 text-capitalize'}><b> Communication Mode: </b>{item.communicationMode} </p>
                    : <p className={'mb0'}><b> Status: </b>{item.status ? 'Resolved' : 'Open'} </p>
                  }
                  <p className={'mb0'}><b> Description: </b>{item.description} </p>

                </Feed.Extra>
              </Feed.Content>
            </Feed.Event>
          )

        }
      })
    }
    return (<Feed>{items}</Feed>)
  };

  return (
    <div className={'MDHMconfirmation'}>
      <Modal
        open={true}
        onClose={() => props.mdhmModalSetData({ show: false})}
        aria-labelledby="ModalHeader"
        centered={true}
        closeIcon={true}
        size={'large'}
      >

          {loader && <Dimmer active={true}>
          <Loader>Loading</Loader>
        </Dimmer>}

        {props.mdhmModal.type ==='home' ?
          <>
          <Modal.Header>MDHM confirmation</Modal.Header>

          <Modal.Content scrolling={true}>
            {getList()}
          <Form>
            <Header as='h3'>Add your Comments below</Header>
            <Form.Group inline={true}>
              <label>Communication Mode<span className={'error'}>*</span></label>
              <Form.Field>
                <Radio
                  label='Call'
                  name='communicationMode'
                  value='call'
                  checked={state.communicationMode === 'call'}
                  onChange={(e, { value }) => handleChange(value, 'communicationMode')}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label='Fax'
                  name='communicationMode'
                  value='fax'
                  checked={state.communicationMode === 'fax'}
                  onChange={(e, { value }) => handleChange(value, 'communicationMode')}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field><label>Description<span className={'error'}>*</span></label>
            <Form.TextArea
              name='description'
              placeholder='Tell us more about this...'
              defaultValue={state.description}
              onBlur={(e: any) => handleChange(e.target.value, 'description')}
            />
            </Form.Field>
            {state.error && <label className={'error'}>All fields are required!</label>}
          </Form>
          </Modal.Content>
            <Modal.Actions> <Form.Button primary={true} onClick={()=> handleSubmit()}>Submit</Form.Button> </Modal.Actions>


        </>
          : <>
            <Modal.Header>Error Explanation</Modal.Header>



              <Modal.Content scrolling={true}>
                {getList()}
                <Form>
              <Header as='h3'>Add your Comments below</Header>
              <Form.Group inline={true}>
                <label>Status</label>
                <Form.Field>
                  <Radio
                    label='Open'
                    name='status'
                    value={0}
                    checked={state.status === 0}
                    onChange={(e, { value }) => handleChange(value, 'status')}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label='Resolved'
                    name='status'
                    value={1}
                    checked={state.status === 1}
                    onChange={(e, { value }) => handleChange(value, 'status')}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field><label>Description<span className={'error'}>*</span></label>
                <Form.TextArea
                  name='description'
                  placeholder='Tell us more about this...'
                  onBlur={(e: any) => handleChange(e.target.value, 'description')}
                />
              </Form.Field>
              {state.error && <label className={'error'}>All fields are required!</label>}
                </Form>
          </Modal.Content>
              <Modal.Actions> <Form.Button primary={true} onClick={()=> handleSubmit()}>Submit</Form.Button> </Modal.Actions>

          </> }

      </Modal>
    </div>

  );

}

function mapStateToProps(state: RootState) {
  return {
    mdhmConfirmation: state.mdhmConfirmation,
  };
}

export default connect(mapStateToProps)(MDHMconfirmation);

