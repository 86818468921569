import React, { useEffect, useState } from 'react'
import { AccordionContent, AccordionTitle, Grid, GridColumn, GridRow, Icon, Label, Loader, Progress, Table, TableBody, TableCell, TableRow } from 'semantic-ui-react';
import { useActions } from '../../redux/actions';
import * as HealthStatusActions from "../../redux/actions/healthStatus";
import { capitalizeFirstLetter, categories, dentaTableFields } from './healthStatusUtils';
import ProgressBar from './ProgressBar';
import { HealthScore } from '../../model/healthScore';
const _ = require('underscore');

interface Props {
    setDentaData(data: any): any
    setLoader(loading: boolean): any
    setCount: (counts: HealthScore) => void
}

const DentaHubChart = ({ setDentaData, setLoader, setCount }: Props) => {
    useEffect(() => { getData() }, []);
    const initialState = {
        loading: false, panel: '', data: [], scraperRecords: [], selected: { insurance: '', practice: '' }, counts: {
            green: 0,
            red: 0,
            yellow: 0,
            null: 0
        }
    };
    const healthStatusActions = useActions(HealthStatusActions);
    const [state, setState] = useState(initialState);
    useEffect(() => { setOveralStatus() }, [state.data]);
    const { selected } = state
    const prefix = 'dentaHubChart'

    const onSelect = (selected: string) => {
        if (state.panel === selected) {
            setState({ ...state, panel: '' });
            return;
        }
        setState({ ...state, panel: selected });
    }

    const getData = async () => {
        setState({ ...state, loading: true });
        setLoader(true);
        const result = await healthStatusActions.getDentalHubHealth();
        setDentaData(result);
        setLoader(false);

        const sortedRecords = _.sortBy(result.records, 'name');
        setState({ ...state, data: sortedRecords, scraperRecords: result.trackerRecords, loading: false });
    }

    const onTileSelect = (practice: string, insurance: string) => {
        const { selected } = state;
        const newState = { ...state, selected: { practice, insurance } };
        if (selected.practice === practice && !insurance) {
            newState.selected.practice = '';
        } else if (selected.insurance === insurance) {
            newState.selected.insurance = '';
        }
        setState(newState);
    }

    function determineColor(item: any) {
        let redCount = 0;
        const properties = dentaTableFields.map(d => d.name);
        properties.forEach(property => {
            if (item.hasOwnProperty(property)) {
                const color = item[property]
                if (color === "red") redCount++;
            }
        });

        return (properties.length - redCount) * 100 / properties.length;
    }

    const setCategoryColor = (category: any, item: any) => {
        const subcategories = category.subcategories.map((s: any) => s.name);
        let redCount = 0;
        for (let subcategory of subcategories) {
            const value = item[`${category.name}_${subcategory}`];
            if (value === "red") redCount++;
        }
        return (subcategories.length - redCount) * 100 / subcategories.length;
    }

    const setOveralStatus = () => {
        const properties = dentaTableFields.map(d => d.name);
        const counts = { green: 0, red: 0, yellow: 0, null: 0 };
        state.data.forEach((item: any) => {
            properties.forEach(property => {
                const value = item[property];
                if (value === "green") {
                    counts.green++;
                } else if (value === "red") {
                    counts.red++;
                } else if (value === "yellow") {
                    counts.yellow++;
                } else if (value === null) {
                    counts.null++;
                }
            });
        });


        const count = { success: counts.green, pending: counts.null + counts.yellow, failed: counts.red }
        setCount(count);
        healthStatusActions.addToCount(count).then();
        setState({ ...state, counts: counts });
    }


    return (
        <>
            <AccordionTitle active={state.panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Insurance (Practices)</span>
                            {state.loading && <Loader size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar success={state.counts.green} pending={state.counts.null + state.counts.yellow} failed={state.counts.red} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={state.panel === prefix}>
                {
                    state.data.map((item: any, index: number) => {
                        const style = { cursor: 'pointer', backgroundColor: index % 2 === 0 ? '#e9e9e9' : 'white' };

                        return <Table key={index} fixed >
                            <TableBody>
                                <TableRow style={style} onClick={() => onTileSelect(item.practice, '')}>
                                    <TableCell><span style={{ marginLeft: '20px' }}><Icon name='dropdown' />{item.practice}</span></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Progress color='green' size='medium' className='red-bg mb0' percent={determineColor(item)} />
                                    </TableCell>
                                </TableRow>
                                {
                                    selected.practice === item.practice && categories.map(insurance => (
                                        <TableRow style={style} key={insurance.name}>
                                            <TableCell colspan='6'>
                                                <Grid>
                                                    <GridRow onClick={() => onTileSelect(item.practice, insurance.name)}>
                                                        <GridColumn width={14}>
                                                            <span style={{ fontSize: '15px', marginLeft: '60px' }}><Icon name='dropdown' />{insurance.label}</span>
                                                        </GridColumn>
                                                        <GridColumn textAlign='right' width={2}>
                                                            <Progress color='green' size='medium' className='red-bg mb0' percent={setCategoryColor(insurance, item)} />
                                                        </GridColumn>
                                                    </GridRow>
                                                    {
                                                        selected.insurance === insurance.name && insurance.subcategories.map(sub =>
                                                            <GridRow key={sub.name}>
                                                                <GridColumn width={13}>
                                                                    <span style={{ fontSize: '15px', marginLeft: '80px' }}>{capitalizeFirstLetter(sub.label)}</span>
                                                                </GridColumn>
                                                                <GridColumn textAlign='right' width={3}>
                                                                    <Label size='massive' style={{ width: '40%' }} color={item[`${insurance.name}_${sub.name}`] || 'yellow'} horizontal></Label>
                                                                </GridColumn>
                                                            </GridRow>
                                                        )
                                                    }
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    }
                    )
                }
            </AccordionContent>
        </>
    )
}

export default DentaHubChart