import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Template from "./Template";
import { User } from "../model/user";
import { RootState } from "../model";
import { Practice } from "../model/practice";
import { useActions } from "../redux/actions";
import * as usersActions from "../redux/actions/users";
import { Checkbox, Dropdown, Form, Radio, Grid } from "semantic-ui-react";
import OpenDentalUserResult from "./OpenDentalUserResult";
import Loading from "../Loading";

interface Props {
  auth: User;
  practice: Practice;
  loader : any;
}

function OpenDentalUsers(props: Props) {
  const UsersActions = useActions(usersActions);
  const [result, setResult] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [selectedUserNum, setSelectedUserNum] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [hidden, setHidden] = useState("true");
  const [listHidden, setListHidden] = useState(false);
  const {loader } = props;

  useEffect(() => {
    loadData(listHidden)
  }, []);

  const loadData = (hidden: boolean) => {
    const obj ={
      includeHidden : hidden
    }
    UsersActions.getOdUserList(obj).then((result: any) => {
      const allUsers: any = [];
      const userLocationsMap: any = {};

      for (const location in result) {
        result[location].forEach((user: any) => {
          if (!allUsers.some((u: any) => u.UserNum === user.UserNum)) {
            allUsers.push(user);
          }
          if (!userLocationsMap[user.UserNum]) {
            userLocationsMap[user.UserNum] = [];
          }
          userLocationsMap[user.UserNum].push(location);
        });
      }

      const userOptions: any = allUsers.map((user: any) => ({
        text: user.UserName,
        value: user.UserNum,
        key: user.UserNum,
        IsHidden: user.IsHidden,
        locations: userLocationsMap[user.UserNum],
      }));

      const locationOptions: any = Object.keys(result).map((location) => ({
        text: location,
        value: location,
        key: location,
      }));

      setUserOptions(userOptions);
      setLocationOptions(locationOptions);

      if (userOptions.length > 0) {
        const defaultUser = userOptions[0];
        setSelectedUserNum(defaultUser.value);
        setSelectedLocations(defaultUser.locations);
        setHidden(defaultUser.IsHidden);
      }
    });
  }
  const handleUserChange = (e: any, { value }: any) => {
    setSelectedUserNum(value);
    const selectedUser: any = userOptions.find(
      (user: any) => user.value === value
    );
    setSelectedLocations(selectedUser.locations);
    setHidden(selectedUser.IsHidden);
  };

  const handleLocationChange = (e: any, { value }: any) => {
    setSelectedLocations(value);
  };

  const handleStatusChange = (e: any, { checked }: any) => {
    setHidden(checked ? "true" : "false");
  };

  const handleListHiddenUsers = (e: any, { checked }: any) => {
    setListHidden(checked);
    loadData(checked)
  }

  const handleSubmit = async () => {
    const obj = {
      userNum: selectedUserNum,
      locations: selectedLocations,
      IsHidden: hidden,
    };
    const result = await UsersActions.updateOpenDentalUser(obj);
    setResult(result);
  };

  return (
    <Template activeLink="usage-spot">
      <div className="ui card">
        <div className="content">
          <h2 className="float-left mr10">Open Dental Users</h2>
        </div>
      </div>

      <Grid>
        <Grid.Row>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <div className="ui card">
              <div className="content">
                <Form onSubmit={handleSubmit}>
                  <Form.Field>
                    <Checkbox
                        label="List Hidden Users"
                        checked={listHidden}
                        onChange={handleListHiddenUsers}
                    />
                  </Form.Field>
                  <div className="mb15">
                    <label>User: </label>
                    <Dropdown
                      placeholder="Select User"
                      fluid={true}
                      search={true}
                      selection={true}
                      options={userOptions}
                      value={selectedUserNum}
                      onChange={handleUserChange}
                    />
                  </div>
                  <div className="mb15">
                    <label>Locations: </label>
                    <Dropdown
                      placeholder="Select Locations"
                      fluid={true}
                      multiple={true}
                      selection={true}
                      options={locationOptions}
                      value={selectedLocations}
                      onChange={handleLocationChange}
                    />
                  </div>
                  <Form.Field>
                    <Checkbox
                      label="Disable"
                      checked={hidden === "true"}
                      onChange={handleStatusChange}
                    />
                  </Form.Field>

                  <Form.Button>Submit</Form.Button>
                </Form>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {result && result.length ? (
        <OpenDentalUserResult
          result={result}
          message={"User has been updated successfully!"}
          handleClose={() => setResult([])}
        />
      ) : (
        ""
      )}
      {loader && <Loading />}
    </Template>
  );
}

function mapStateToProps(state: RootState) {
  return {
    auth: state.auth,
    practice: state.practice,
    loader : state.app.loader
  };
}

export default connect(mapStateToProps)(OpenDentalUsers);
