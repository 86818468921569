import React, {useEffect, useState} from "react";
import Template from "./Template";
import * as reportActions from "../redux/actions/report";
import {useActions} from "../redux/actions";
import {RootState} from "../model";
import {connect} from "react-redux";
import DynamicHtmlTable from "./DynamicHtmlTable";
import _ from "lodash";
import {allowDeleteUserId, brands} from '../constants' ;
import {Button, Dropdown, Icon} from "semantic-ui-react";
import {User} from "../model/user";
import {exportUsagePTVWOReport, numberWithCommas} from "./ExportCsv";
import {Patients} from "../model/patients";
import * as patientActions from "../redux/actions/patients";
import {Practice, UdaPracticeInfo} from "../model/practice";
import * as practiceActions from "../redux/actions/practice";
import moment from "moment";
import * as userSetupActions from "../redux/actions/userSettings";
import {SetupFields} from "../enums/userSetupField";
import {SetupAppName} from "../enums/userSetupAppName";
import HelpMark from "./HelpMark";

const filterformat = 'YYYY-MM-DD';

interface Props {
    auth: User,
    patients: Patients,
    practice: Practice,
}

function MDHwriteOffMDHMUsagevsWOReport(props: Props) {
    const reportAction = useActions(reportActions);
    const patientAction = useActions(patientActions);
    const practiceAction = useActions(practiceActions);
    const userSetup = useActions(userSetupActions);
    const initialState: any = {
        filters: {
            locations: [],
            region: '',
            startDate: moment().endOf('month').format(filterformat),
            endDate: moment().subtract(1, 'year').startOf('month').format(filterformat),
            practices: ['all'],
            reportStatus: 1,
            brands: []
        },
        locationSelected: false,
        direction: 'sort',
        TableData: [[{title: 'No Records Found'}]],
        TableHead: [{title: ''}],
        actualData: [],
        showHidden: false,
        column: -1,
        searchKeyword: '',
    };
    const [state, setStates] = useState(initialState);
    const user = props.auth.user;
    const allowDelete = (user && allowDeleteUserId.indexOf(user.id) >= 0)
    const info = props.patients.defaultPractice;

    useEffect(() => {
        if (!props.practice.practiceNames.length) {
            practiceAction.fetchPractices(refinePracticeArray);
        }
        if (!props.patients.defaultPractice) {
            let userParams = {field: SetupFields.Practice, appId: SetupAppName.CDP_MY};
            userSetup.getSetup(userParams);
        }
        practiceAction.fetchPracticeUda();
        practiceAction.fetchRegions(refineRegionsArray);
        practiceAction.fetchPractices(refinePracticeArray)
    }, []);
    const refineRegionsArray = (regionList: any) => {
        let regionListOptions = [{key: '0', value: null, text: 'Select Region'}];
        if (regionList.length) {
            regionList.forEach((item: any) => {
                regionListOptions.push({text: item.name, value: item.id, key: item.id})
            });
        }
        return regionListOptions;
    };

    const refinePracticeArray = (response: any) => {
        let practiceNames: Array<string> = [];
        let practiceInfo: any = {};
        let practiceOptions = [{key: 'none', value: 'all', text: 'All Practices'}];
        if (response) {
            response.forEach((item: any) => {
                practiceOptions.push({text: item.practice, value: item.practice, key: item.id.toString()});
                practiceNames.push(item.practice);
                practiceInfo[item.practice.toLowerCase()] = item;
            })
        }
        let userParams = {field: SetupFields.Practice, appId: SetupAppName.CDP_MY};
        userSetup.getSetup(userParams).then((defaultPractice: any) => {
            const filters = {...state.filters};
            filters.locations = practiceNames;
            filters.practices = ['all']
            if (defaultPractice && defaultPractice.value && defaultPractice.value !== 'all') {
                let defaultPractices: any = defaultPractice.value.split(',');
                let practices: any = []
                defaultPractices.forEach((defaultPractice: any) => {
                    if (practiceInfo[defaultPractice]) {
                        let practiceData: any = practiceInfo[defaultPractice]
                        practices.push(practiceData.practice)
                    } else practices.push(defaultPractice)
                })
                filters.locations = practices;
                filters.practices = practices;
            }
            getUsageData(filters, false, info, filters.brands);
        })
        return {practiceNames: practiceNames, practiceOptions: practiceOptions, practiceInfo: practiceInfo}
    };

    const getUsageData = (filters: any, locationSelected: boolean, info: any, brnds: any) => {
        reportAction.fetchUsageWO(filters)
            .then((res: any) => {
                const actualData = res;
                const TableData = pushTableData(res, info, filters);
                const TableHead = TableHeadData();
                filters.brands = brnds;
                setStates({...state, TableData, TableHead, actualData, filters, locationSelected});
            });
    }
    const TableHeadData = () => {
        const TableHead = new Array();
        TableHead[0] = {title: 'PRACTICE'};
        TableHead[1] = {title: 'PM'};
        TableHead[2] = {title: 'PM Usage'};
        TableHead[3] = {title: 'RM'};
        TableHead[4] = {title: 'LAST USAGE'};
        TableHead[5] = {title: '# OF USERS'};
        TableHead[6] = {title: 'MDHM WO Current Month'};
        TableHead[7] = {title: 'MDHM WO Prior Month'};
        TableHead[8] = {title: 'TWO MONTH TOTAL'};
        TableHead[9] = {title: 'REDUCTION'};
        TableHead[10] = {title: 'REDUCTION w/Usage'};
        TableHead[11] = {title: 'REDUCTION w/o Usage'};
        return TableHead;
    };


    const pushTableData = (res: any, info: any, filters: any) => {
        const TableData = new Array();

        const dataInfo: any = res && res.data;
        let dataPractice: any = res && res.udaPractices;
        let dataPracticeFinal = dataPractice && dataPractice.filter((item: any) => item.practiceName != null);
        let dataWoInfo: any = res && res.woInfo;

        const checkData1 = dataInfo && dataInfo.filter((item: any) => item.practices !== null);
        const checkData2 = checkData1 && checkData1.filter((item: any) => item.practices !== 'all');
        let returnArray: any = [];
        const dataArr: any = {};

        dataWoInfo && Object.keys(dataWoInfo).map((info: any, key: any) => {
            dataArr[info] = {practiceName: '',};
        });

        Object.keys(dataArr).map((info: any) => {

            const finalInfo = dataWoInfo[info];
            const objectPractice = {};
            Object.assign(objectPractice, {practiceName: info});
            let woInfoData: any = [];
            Object.keys(finalInfo).map((infoData: any, key: any) => {
                let checkItem = finalInfo[infoData];
                key === 0 && woInfoData.push(checkItem);
                key === 1 && woInfoData.push(checkItem);
            });

            if (woInfoData && woInfoData.length > 0) {
                Object.assign(objectPractice, {
                    currentMonth: '$' + woInfoData[0].toFixed(2), currentMonthTotal: woInfoData[0],
                    previousMonth: '$' + woInfoData[1].toFixed(2), previousMonthTotal: woInfoData[1]
                });
                const total = woInfoData[0] + woInfoData[1];
                Object.assign(objectPractice, {twoMonthTotal: '$' + total.toFixed(2), twoMonthTotalF: total});

                const reduction = woInfoData[1] - woInfoData[0];
                Object.assign(objectPractice, {reduction: '$' + reduction.toFixed(2), reductionTotal: reduction});
            }
            const checkInfo: any = checkData2.filter((item: any) => item.practices.trim().toLowerCase() === info.trim().toLowerCase());
            Object.assign(objectPractice, {userCount: checkInfo.length})
            if (checkInfo && checkInfo.length > 0) {
                const lastUsage = checkData2.reduce((a: any, b: any) => (a.Date > b.Date ? a : b));
                Object.assign(objectPractice, {pmUsage: checkInfo[0]['Date'], lastUsage: lastUsage.Date})
            } else {
                Object.assign(objectPractice, {pmUsage: '', lastUsage: ''});
            }
            const dataPracticeInfo = dataPracticeFinal.filter((item: any) => item.practiceName.trim().toLowerCase() === info.trim().toLowerCase());
            Object.assign(objectPractice, {
                pm: dataPracticeInfo[0] && dataPracticeInfo[0]['mgrFirstName'] + ' ' + dataPracticeInfo[0] && dataPracticeInfo[0]['mgrLastName']
                ,
                rm: dataPracticeInfo[0] && dataPracticeInfo[0]['regMgrFirstName'] + ' ' + dataPracticeInfo[0] && dataPracticeInfo[0]['regMgrLastName']
            });

            returnArray.push(objectPractice)

        });


        let [userCount, currentMonthTotal, previousMonthTotal, twoMonthTotal, reductionTotal, reductionATotal, finalTotal]: any = [0, 0, 0, 0, 0, 0];
        returnArray && returnArray.forEach((item: any, key: any) => {
            userCount = userCount + item.userCount;
            currentMonthTotal = currentMonthTotal + item.currentMonthTotal;
            previousMonthTotal = previousMonthTotal + item.previousMonthTotal;
            twoMonthTotal = twoMonthTotal + item.twoMonthTotalF;
            reductionTotal = reductionTotal + item.reductionTotal;
            reductionATotal = reductionATotal + item.reductionTotal;
            finalTotal = finalTotal + 0;
            TableData.push([
                {title: item.practiceName},
                {title: item.pm},
                {title: item.pmUsage},
                {title: item.rm},
                {title: item.lastUsage},
                {title: item.userCount},
                {title: numberWithCommas(item.currentMonth)},
                {title: numberWithCommas(item.previousMonth)},
                {title: numberWithCommas(item.twoMonthTotal)},
                {title: numberWithCommas(item.reduction)},
                {title: numberWithCommas(item.reduction)},
                {title: '$0.00'},
            ]);

        });

        TableData.push([
            {title: 'Total'},
            {title: ''},
            {title: ''},
            {title: ''},
            {title: ''},
            {title: userCount},
            {title: '$' + numberWithCommas(currentMonthTotal.toFixed(2))},
            {title: '$' + numberWithCommas(previousMonthTotal.toFixed(2))},
            {title: '$' + numberWithCommas(twoMonthTotal.toFixed(2))},
            {title: '$' + numberWithCommas(reductionTotal.toFixed(2))},
            {title: '$' + numberWithCommas(reductionATotal.toFixed(2))},
            {title: '$0.00'},
        ]);
        return TableData;
    };
    const sendWriteOffEmail = () => {
        let {filters} = state;
        filters.sendReport = true;
        reportAction.fetchUsageWO(filters);
    };


    const handleSort = (clickedColumn: number) => () => {
        let {column, TableData, direction} = state;
        if (column !== clickedColumn) {
            column = clickedColumn;
            TableData = _.sortBy(TableData, o => o[clickedColumn].title);
            direction = 'sort ascending';
            setStates({...state, TableData: TableData.slice(), column, direction});
            return;
        }
        TableData = TableData.reverse();
        direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
        setStates({...state, TableData: TableData.slice(), direction});
    };

    const downloadUsage = () => {
        let header = TableHeadData();
        let data: (string | number)[][] = [];
        state.TableData.forEach((itemArray: any) => {
            let rows: (string | number)[] = itemArray.map((item: any) => {
                let title = item.title;
                if (typeof item.title === 'string' && item.title.includes(',')) {
                    if (typeof item.title === 'string' && item.title.includes('$')) {
                        title = '"' + item.title + '"';
                    } else {
                        title = item.title.replace(',', ';')
                    }
                }
                return title;
            });

            data.push(rows);
        });
        exportUsagePTVWOReport({header, data: data})
    }
    const onChangeLocation = (e: any, element: any) => {
        const actualData = state.actualData;
        const filters: any = {...state.filters};

        const values = element.value;
        const practiceNames = props.practice.practiceNames;
        filters.locations = values;
        filters.practices = values;
        filters.region = null;
        if (values.length >= 0 && values.indexOf('all') === values.length - 1) {
            filters.locations = practiceNames;
            filters.practices = ['all'];
        } else if (values.length > 1 && values.indexOf('all') === 0) {
            values.splice(0, 1);
            filters.locations = values;
            filters.practices = values;
        }
        getUsageData(filters, true, info, filters.brands);

    };
    const onChangeBrands = (e: any, element: any) => {
        const filters: any = {...state.filters};
        const values = element.value;
        const practiceNames = props.practice.practiceNames;
        let selectedPractices: string[] = [];
        const tempBrandFilterPractices: any = [];
        values.forEach((brand: any) => {
            const res = props.practice.udaPracticeInfo.filter(
                (item: UdaPracticeInfo) => {
                    if (item.brand === brand) {
                        return true
                    }
                }
            )
            tempBrandFilterPractices.push(
                ...res.map(prac => {
                    return prac.practiceName
                })
            )
        })
        selectedPractices = Array.from(new Set([...selectedPractices, ...tempBrandFilterPractices])).sort();
        if (filters.locations.length > 0)
            selectedPractices = filters.locations.filter((item: any) => selectedPractices.includes(item));
        filters.locations = selectedPractices;
        filters.practices = selectedPractices;
        filters.region = null;
        if (values.length >= 0 && values.indexOf('all') === values.length - 1) {
            filters.locations = practiceNames;
            filters.practices = ['all'];
        } else if (values.length > 1 && values.indexOf('all') === 0) {
            values.splice(0, 1);
            filters.locations = selectedPractices;
            filters.practices = selectedPractices;
        }
        getUsageData(filters, false, props.practice.practiceInfo, values);
    };

    const brandsOptions = () => {
        const brandOptions: string[] = [];
        const brandsObject: { [key: string]: string[] } = {}
        brands.forEach((brand) => {
            const res = props.practice.udaPracticeInfo.filter(
                (item: UdaPracticeInfo) => {
                    if (item.brand === brand.value) {
                        return true
                    }
                }
            ).map(item => item.practiceName)
            brandsObject[brand.value] = [...res.map(i => i.toLocaleLowerCase())]
        })
        if (state.filters.region) {
            const tempSelectedPracsPerRegion: { [key: string]: string[] } = {};
            if (props.practice.regionPractices[state.filters.region]) {
                tempSelectedPracsPerRegion[state.filters.region] =
                    props.practice.regionPractices[state.filters.region] as []

            }
            Object.keys(tempSelectedPracsPerRegion).forEach(region => {
                Object.keys(brandsObject).forEach(brand => {
                    const commonPracs = _.intersection(brandsObject[brand], tempSelectedPracsPerRegion[region])
                    if (commonPracs.length > 0) {
                        brandOptions.push(brand)
                    }
                })
            });
            return (brandOptions.length > 0) ? brandOptions.map(b => {
                    return {
                        value: b,
                        key: b,
                        text: b
                    }
                }) :
                brands
        } else return brands;
    }


    const onChangeRegion = (e: any, element: any) => {
        const values = element.value;
        const filters: any = {...state.filters};

        filters.locations = props.practice.practiceNames;
        filters.practices = ['all'];
        filters.region = null;
        if (values) {
            if (element.value) {
                const mainData = props.practice.practiceNames;
                const info: any = props.practice.regionPractices[element.value];

                let practiceName: any = [];
                info && info.forEach((item: any, key: number) => {
                    const pracFilter = mainData.filter((data: any) =>
                        data.toLowerCase() === item.toLowerCase());
                    if (pracFilter.length > 0) {
                        practiceName.push(pracFilter[0])
                    }
                });
                filters.locations = practiceName;
                filters.practices = practiceName;
                filters.region = element.value;

            }
        }
        getUsageData(filters, false, info, filters.brands);
    };

    return (
        <Template activeLink='usage-report'>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10"> PT Validation WO Usage Report
                        ({state.TableData.length === 1 && state.TableData[0][0]['title'] === 'No Records Found' ? 0 : state.TableData.length}) <HelpMark
                            pageId='12'/></h2>
                    <div className="topFilters">
                        <a className="link" onClick={sendWriteOffEmail}><Icon name="send"/> Send</a>
                        <Button primary={true} onClick={downloadUsage}>Download</Button>
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="locations"
                            multiple={true}
                            placeholder="Practice"
                            selection={true}
                            options={props.practice.practiceOptions}
                            onChange={onChangeLocation}
                            value={state.filters.practices}
                        />
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="region"
                            placeholder="Region"
                            selection={true}
                            options={props.practice.regionOptions}
                            onChange={onChangeRegion}
                            value={state.filters.region}
                        />
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="brands"
                            placeholder="Brands"
                            selection={true}
                            multiple={true}
                            options={brandsOptions()}
                            onChange={onChangeBrands}
                            value={state.filters.brands}
                        />

                        {/* <Input className='mb10' icon='search' placeholder="Search" onChange={onSearchChange}/>*/}
                    </div>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    <DynamicHtmlTable
                        key={0}
                        className={'adjustment'}
                        tableHead={state.TableHead}
                        heading={""}
                        tableData={state.TableData}
                        onHeadClick={handleSort}
                        sortedColumn={state.column}
                        direction={state.direction}
                    />
                </div>
            </div>

        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
        patients: state.patients,
        practice: state.practice,
    };
}

export default connect(mapStateToProps)(MDHwriteOffMDHMUsagevsWOReport);


