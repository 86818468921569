import * as React from 'react';
import {Bar} from 'react-chartjs-2';
import {Card, Dimmer, Grid, Loader} from "semantic-ui-react";
import { nFormatter } from './nFormatter';

export interface PMDCallCenterProps {
  practicename: string,
  callcenter_data: any
}

export interface PMDCallCenterState {
  practicename: string,
  callcenter_data: any
}

class PMDCallCenter extends React.Component<PMDCallCenterProps,PMDCallCenterState> {  
  constructor(props: PMDCallCenterProps) {
    super(props);
    this.state={
      practicename : props.practicename,
      callcenter_data: props.callcenter_data
    }
    this.setPracticeName=this.setPracticeName.bind(this);
  }

  setPracticeName(value: string) {
    this.setState({
      practicename : value
    })
  }

  componentWillReceiveProps(nextProps: PMDCallCenterProps) {
    this.setState({
      practicename: nextProps.practicename,
      callcenter_data: nextProps.callcenter_data
    })
  }

options  = () => {
    const options_config = {
        scales: {
            xAxes: [{
                gridLines: {display: false},
                ticks: {beginAtZero: true},
            }],
            yAxes: [{
                gridLines: {display: false},
                ticks: {callback: nFormatter}
            }]            
        },
        responsive: false,
        maintainAspectRatio: false,
    };
    return options_config;
}

    checkValues(){
        return (
          this.props.callcenter_data && !(this.props.callcenter_data.hasOwnProperty('datasets') &&
            this.props.callcenter_data.labels.length) &&
            <Dimmer active={true}>
                <Loader>Calculating</Loader>
            </Dimmer>
        )
    }

  render() {
    return (
        <Grid>
            {this.checkValues()}
        <Grid.Row>
            <Grid.Column width={8}>
                <Card>
                    <Card.Content>
                      {this.props.callcenter_data &&
                        <Bar data={this.props.callcenter_data} options={this.options()}/>
                        }
                    </Card.Content>
                </Card>
            </Grid.Column>
        </Grid.Row>
        </Grid>
)
  }
}

export default PMDCallCenter;