import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../model";
import {useActions} from "../../../redux/actions";
import * as spotActions from "../../../redux/actions/spot";
import {Card, Checkbox, CheckboxProps, Grid, Popup, Table} from "semantic-ui-react";
import {boolean} from "yup";

interface Props {
    patientId: any,
    practice: any,
    result: any
}

function OpenTreatPlan(props: Props) {
    const spotAction = useActions(spotActions);
    const [state, setStates] = useState({
        openTreatment: [] as any[],
        txtPlanIgnore: {} as any,
        patientData: {} as any
    });

    useEffect(() => {
        loadData()
    }, []);

    useEffect(() => {
        const patientData = (props.result || []).find((f: any) => f.patientId == props.patientId) || {};
        const txtPlanIgnore = patientData.txPlanIgnore && JSON.parse(patientData.txPlanIgnore) || {};
        setStates({...state, patientData, txtPlanIgnore});
    }, [props.result])


    const loadData = async () => {
        const query: any = {location: props.practice, PatNum: props.patientId}
        const result: any[] = await spotAction.fetchTreatmentPlan(query);
        setStates({...state, openTreatment: result});
    }

    const {txtPlanIgnore} = state;

    function getTotalData(data: any) {
        let total = 0
        data.forEach((element: any) => {
            if (element.ProcFee && !txtPlanIgnore[element.ProcNum])
                total += parseFloat(element.ProcFee)
        });
        return (
            <tr>
                <td colSpan={3} style={{textAlign: 'right'}}>Total:</td>
                <td colSpan={2}><b>{total.toFixed(2)}</b></td>
            </tr>
        )
    }

    const handleIgnoreTxChange = async (p: CheckboxProps, name: any) => {
        let txPlanIgnore = (txtPlanIgnore && typeof txtPlanIgnore != 'string') ? txtPlanIgnore : {} as any;
        txPlanIgnore[name] = p.checked;

        let txPlanTotal = 0;
        state.openTreatment.forEach((item: any) => {
            if (item.ProcFee && !txPlanIgnore[item.ProcNum]) {
                txPlanTotal += item.ProcFee;
            }
        });

        await spotAction.saveBrokenResult({
            PT_Num: props.patientId,
            txPlanIgnore: JSON.stringify(txPlanIgnore),
            txPlanTotal: txPlanTotal,
            practice: props.practice
        });

        setStates({...state, txtPlanIgnore: txPlanIgnore})
    }

    return (
        <Card>
            <Card.Header>
                <Card.Content>
                    <h3>OPEN TREATMENT PLAN</h3>
                </Card.Content>
            </Card.Header>
            <Card.Content>
                <div className="table-responsive limit-height">
                    <Table striped={true}>
                        <thead>
                        <tr>
                            <th>ProcDate</th>
                            <th>Procedure</th>
                            <th>ToothNum</th>
                            <th>ProcFee</th>
                            <th>Ignore TX</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            state.openTreatment && state.openTreatment.map((item: any, key: any) =>
                                <tr key={key}>
                                    <td>{item.ProcDate ? item.ProcDate : ''}</td>
                                    <td>
                                        <Popup
                                            content={item.PC_cat}
                                            key={key + 'popup'}
                                            header={'#' + item.ProcNum}
                                            trigger={<b>{item.ProcCode}</b>}
                                        />
                                    </td>
                                    <td>{item.ToothNum}</td>
                                    <td>{item.ProcFee}</td>
                                    <td>
                                        <Checkbox
                                            checked={txtPlanIgnore && txtPlanIgnore[item.ProcNum] && txtPlanIgnore[item.ProcNum] != 'false'}
                                            onChange={(e, p) => handleIgnoreTxChange(p, item.ProcNum)}
                                        />
                                    </td>
                                </tr>
                            )
                        }
                        {state.openTreatment ? getTotalData(state.openTreatment) : 0}
                        </tbody>
                    </Table>
                </div>
            </Card.Content>
        </Card>
    )
}

function mapStateToProps(state: RootState) {
    return {user: state.auth.user,}
}

export default connect(mapStateToProps)(OpenTreatPlan)
