import React, {useEffect, useState} from "react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as ReportActions from "../redux/actions/financialNoteCategoryList"
import {useActions} from "../redux/actions";
import {Button, Grid, Icon, Message, Search, Table} from "semantic-ui-react";
import _ from "lodash";
import { AdminImpDataSaveUpdMsg } from "../constants";
import FinancialNoteCategoryListForm from "./FinancialNoteCategoryForm";
import HelpMark from "./HelpMark";


interface FinancialNoteCategoryListProps {
    financialNoteCategory : any
}

const initialState:any = {
    loading: false,
    financialNoteCategoryList:[],
    isEdit: false,
    initialValues: {name: '', isDeleted: false, id: 0},
    orderBy: null,
    order: true,
    search:""
}
const FinancialNoteCategoryList= (_props: FinancialNoteCategoryListProps) => {
    const reportActions = useActions(ReportActions);
    const [state, setStates] = useState(initialState);
    const [addedMsg, setAddedMsg] = useState(false);

    useEffect(() => {
        reportActions.getFinancialNoteCategories()
            .then((res: any) => {
                setStates({...state, financialNoteCategoryList : res});
            });
    }, []);


    const edit = (obj:any,isDelete?:any) => {
        const initialValues = {
            ...obj
        };
        setStates({...state, isEdit: true, initialValues});
    };
    const getSortRows = (sortColumn:string, order:string) => {
        const { financialNoteCategoryList } = state;
        const orderUpdated = !order;
        const financialNoteCategoryListCodesSort =  _.orderBy(financialNoteCategoryList, [sortColumn],[order ? 'asc' : 'desc'])
        setStates({...state, financialNoteCategoryList: financialNoteCategoryListCodesSort, orderBy:sortColumn, order: orderUpdated });
    };
    const cancelForm = () => {
        setStates({...state, isEdit: false, initialValues: {name: '', isDeleted: false, id: 0} });
    };
    const onSearch=(e: any, element: any) => {
        const obj={...state}
        obj.search =element.value;
        setStates(obj);
    };
    const updateFinancialNoteCategory = (data:any) => {
        cancelForm();
        const {id} = data;
        const func = id ? "updateFinancialNoteCategory" : 'saveFinancialNoteCategory';
        reportActions[func](data).then((data:any)=> {
            if(data){
                reportActions.getFinancialNoteCategories()
                    .then((res: any) => {
                        setAddedMsg(true);
                        setStates({...state, financialNoteCategoryList : res,isEdit: false, initialValues: {name: '', isDeleted: false, id: 0}});
                    });
            }

        })
    };
    const { financialNoteCategoryList, search ,orderBy, order, isEdit } = state;
    const direction = order ? 'sort down' : 'sort up';
    return (
        <Template activeLink="crons-list">
            {(!!addedMsg) &&
                <Message
                    color='green'
                    onDismiss={() => {setAddedMsg(false)}}
                    content={AdminImpDataSaveUpdMsg}
                />}
            <Grid>
                <Grid.Row>
                    <div className="ui card">
                        <div className="content pb0 mb10">
                            <h2 className="float-left mr10">Financial Note Category  <HelpMark pageId='89'/></h2>
                            <div className={'topFilters'}>
                                <Grid.Column width={6}>
                                    <Search
                                        placeholder='Search...'
                                        onSearchChange={onSearch}
                                        name={'search'}
                                    />

                                </Grid.Column>
                            </div>

                        </div>
                    </div>

                </Grid.Row>
                <Grid.Row>
                    <Button
                        color="blue"
                        size="small" onClick={()=>edit({name: '', isDeleted:false,id:0})}>Create New </Button>
                </Grid.Row>
                {isEdit &&
                    <Grid.Row>
                        <FinancialNoteCategoryListForm
                            cancelForm={() => cancelForm()}
                            saveForm={(data: any) => updateFinancialNoteCategory(data)}
                            initialValues={state.initialValues}
                        />
                    </Grid.Row>
                }
                <Grid.Row>
                    <Table singleLine className="ui celled table unstackable cronListTable">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{ color: "#d16f26" }}
                                                  className={`cursorPointer ${orderBy === 'name' ? 'active' : ''}`}
                                                  onClick={() => getSortRows('name', order)}
                                >
                                    NAME
                                    <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/>
                                </Table.HeaderCell>

                                <Table.HeaderCell style={{ color: "#d16f26" }}
                                                  className={`cursorPointer ${orderBy === 'isDeleted' ? 'active' : ''}`}
                                                  onClick={() => getSortRows('isDeleted', order)}
                                >
                                    Is DELETED
                                    <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/>
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ color: "#d16f26" }}>
                                    ACTION
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {financialNoteCategoryList.length &&
                                financialNoteCategoryList.filter((item: any, index: any)=>{
                                    if(!search) {
                                        return true
                                    }else{
                                        const values =Object.values(item).join(" ")
                                        return values.toLowerCase().includes(search.toLowerCase())
                                    }
                                })
                                    .map((item: any, index: any) => {
                                        return(
                                            <Table.Row key={index}>
                                                <Table.Cell>{item.name}</Table.Cell>
                                                <Table.Cell>{item.isDeleted?<Icon name='eye slash' color='red'/>:
                                                    <Icon name='eye' color='green'/>}</Table.Cell>
                                                <Table.Cell className={''}>
                                                    <Button
                                                        color="blue"
                                                        size="small" onClick={() => edit(item)}>
                                                        Edit
                                                    </Button>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                        </Table.Body>
                    </Table>
                </Grid.Row>
            </Grid>
        </Template>
    );

}


function mapStateToProps(state: RootState) {
    return {
        financialNoteCategoryList: state.financialNoteCategoryList,
    };
}

export default connect(mapStateToProps)(FinancialNoteCategoryList);
