import React, { useEffect, useState} from "react";
import {connect} from "react-redux";
import Template from "./Template";
import {User} from "../model/user";
import {RootState} from "../model";
import {Practice} from "../model/practice";
import {useActions} from "../redux/actions";
import * as spotActions from "../redux/actions/spot";
import * as regionActions from "../redux/actions/regions";
import * as practiceActions from "../redux/actions/practice";
import {brands, practiceBrand, unscheduledTreatmentHeader} from "../constants";
import ProcedureCodePopup from "./ProcedureCodePopup";
import {Button, Dropdown} from "semantic-ui-react";
import ProcedureCodeDetailsModal from "./ProcedureCodeDetailsModal";
import {setPractices, setRegions} from "../utils/common";
import * as issuesActions from "../redux/actions/downTimeTracker";
import HelpMark from "./HelpMark";
import * as reportActions from "../redux/actions/report";
interface Props {
    auth: User,
    practice: Practice,
}

var appIsDown: boolean = false;
var myCDPApplicationList: any[] = ['SPOT-UnscheduledTreatment'];

function SpotUnscheduledTreatment(props: Props) {
    const spotAction = useActions(spotActions);
    const regionAction = useActions(regionActions);
    const practiceAction = useActions(practiceActions);
    const reportAction = useActions(reportActions);
    const issuesAction = useActions(issuesActions);
    const initialFilters : any = {
            practice: 'all',
            brand: 'all',
            region: 'all',
            callCenter: false,
            total:0,
    };
    const [loader, setLoader] = useState(false);

    const initialState : any = {
        mainData: [],
        data: [],
        showDetailModal: false,
        selectedCategory: null,
        selectedPractice: null,
        selectedBrand: null,
        selectedRegion: null,
        procedureCodePopup : false,
        excludeProceduresCodeList : [],
        practicesList : false,
        excludeProceduresCodeStr : '',
        view: "all",
        order: true,
        column: '',
        reverse:false,
        sortCount:0,
        year:2021,
        month:3,
        monthOption:[],
        procedureCopePracticeName: "all",
        excludeProceduresCodeListAll : [],
        excludeProceduresCodeStrAll : '',
        practicesOpts: [],
        regionsOpts: []
    }
    const [state, setStates] = useState(initialState);


    useEffect(() => {
        const { month } = state;
        checkIssues();
        loadData(month);
    }, []);

    const checkIssues = async() => {
        issuesAction.fetchOpenIssuesList(myCDPApplicationList)
            .then((result: any[]) => {
                appIsDown = result.length > 0 ? true :false;
            });
	};

    const loadData = async (month: number)=>{
        const practice = 'all';
        setLoader(true)

        const regions = await regionAction.getRegions()
        const codeList = await spotAction.fetchExcludeCodesList(practice);
        const codesArr = codeList[0].codesList.split(",").map((item: string) => item.trim());
        const pracTreatData = await getPracticesAndTreatmentData(codesArr, practice, month);
        const data = unscheduledTreatmentData(pracTreatData.practices, pracTreatData.treatment)
        const pracListOpts = setPractices(pracTreatData.practices)
        const regionOpts = setRegions(regions)

        setStates({...state,
            excludeProceduresCodeStr : codeList[0].codesList,
            excludeProceduresCodeList : codesArr,
            excludeProceduresCodeListAll : codesArr,
            excludeProceduresCodeStrAll : codeList[0].codesList,
            practicesList : pracTreatData.practices,
            mainData: data,
            data : data,
            practicesOpts: pracListOpts,
            regionsOpts: regionOpts
        })

        setLoader(false)
    }

    const getPracticesAndTreatmentData = async (codesArr: any, practice: string, month: number) => {
        const practices = await practiceAction.fetchUdaPractices()
        const treatment = await spotAction.getUnscheduledTreatment(codesArr, practice, month);
        return {practices, treatment}
    }

    const sendEmail = (data: any) => {
        reportAction.sendEmailWithCsv({title: 'SPOT Unscheduled Treatment', csvData: data });

    }
    const unscheduledTreatmentData = (practices: any, result: any) => {
        let data :any = [];
        result && result.forEach((item: any) => {
            const practiceDetails = practices.find((prac:any) => (prac.practiceName ? prac.practiceName.toLowerCase() : prac.practice.toLowerCase()) === item.practiceName.toLowerCase());
            if(practiceDetails){
                let temp = {
                    practiceName: item.practiceName,
                    practiceBrand: (practiceDetails && practiceDetails.brand)
                    && practiceBrand[practiceDetails.brand] ? practiceBrand[practiceDetails.brand] : practiceDetails.brand,
                    callCenter: (practiceDetails && practiceDetails.callCenter)
                    && practiceDetails.callCenter ? 'Yes': '',
                    brand: practiceDetails.brand,
                    isCallCenterSupported: practiceDetails.callCenter ? 1 : 0,
                    medicaid: item.medicaid,
                    cashUnder18: item.cashUnder18,
                    cash: item.cash,
                    ppoUnder18: item.ppoUnder18,
                    ppo: item.ppo,
                    adultMedicaid: item.adultMedicaid,
                    regionId: (practiceDetails && practiceDetails.regionId) ? practiceDetails.regionId : 0,
                    medicaidPatients: item.medicaidPatients,
                    cashUnder18Patients: item.cashUnder18Patients,
                    cashPatients: item.cashPatients,
                    ppoUnder18Patients: item.ppoUnder18Patients,
                    ppoPatients: item.ppoPatients,
                    adultMedicaidPatients: item.adultMedicaidPatients
                }
                data.push(temp)
            }

        });
        return data;
    }

    const showProcedureCodePopup = (practicename: string) => {
        getProcCodes(practicename)
            .then((codes:any) => {
            setStates({
                ...state,
                procedureCodePopup: true,
                procedureCopePracticeName: practicename,
                excludeProceduresCodeStr: codes,
                excludeProceduresCodeList:codes? codes.split(","):[]
            });
        })
    }

    const handleProcCodeChange = (e: any) => {
        const codes = e.target.value;
        const codesArr = codes && codes.split(",").map((item: string) => item.trim());
        setStates({
            ...state,
            excludeProceduresCodeStr: codesArr.join(","),
            excludeProceduresCodeList: codesArr
        })
    }

    const hideProcedureCodePopup = () => {
        setStates({ ...state, procedureCodePopup : false });
    }

    const showDetailModal = (category: any, practiceName: string, callCenter : any ,total: number) => {
        callCenter = callCenter ? callCenter : "";
        setStates({
            ...state,
            showDetailModal: true,
            selectedCategory: category,
            selectedPractice: practiceName,
            callCenter:callCenter,
            total : total,
            view:"all"
        })
    }

    const  handleProcedureCodeSubmit = async (practice: string) => {
        const { excludeProceduresCodeList, month } = state;
        hideProcedureCodePopup();
        const pracTreatData = await getPracticesAndTreatmentData(excludeProceduresCodeList, practice.toLowerCase(), month);
        const data = unscheduledTreatmentData(pracTreatData.practices, pracTreatData.treatment)
        setStates({...state,
            practicesList : pracTreatData.practices,
            data : data
        })
    }

    const getProcCodes = (practicename: string) => new Promise((reslove,reject)=>{
        const { excludeProceduresCodeStrAll } = state;
        spotAction.fetchExcludeCodesList(practicename)
            .then((codeLst: any) => {
                if(!codeLst.length ){
                    return reslove(excludeProceduresCodeStrAll);
                }
                reslove(codeLst[0].codesList);
            });

    } )

    const getTableData = () => {
        const {data} = state;
        const tableData = [];
        const rows : any = [];
        const header: any = [];
        const tempData :any = {
            'Total OverAll' : {
                totalMedicaid: 0,
                totalCashUnder18: 0,
                totalCash: 0,
                totalPpoUnder18: 0,
                totalPpo: 0,
                totalAdultMedicaid: 0
            },
            'Total Practice Supported': {
                totalMedicaid: 0,
                totalCashUnder18: 0,
                totalCash: 0,
                totalPpoUnder18: 0,
                totalPpo: 0,
                totalAdultMedicaid: 0
            },
            'Total PCC Supported': {
                totalMedicaid: 0,
                totalCashUnder18: 0,
                totalCash: 0,
                totalPpoUnder18: 0,
                totalPpo: 0,
                totalAdultMedicaid: 0
            }
        }
        data.forEach((item: any) => {
            const totalUnscheduledAppt = item.medicaid + item.cashUnder18 + item.cash + item.ppoUnder18 + item.ppo + item.adultMedicaid;
            tempData['Total OverAll'].totalMedicaid += item.medicaid;
            tempData['Total OverAll'].totalCashUnder18 += item.cashUnder18;
            tempData['Total OverAll'].totalCash += item.cash;
            tempData['Total OverAll'].totalPpoUnder18 += item.ppoUnder18;
            tempData['Total OverAll'].totalPpo += item.ppo;
            tempData['Total OverAll'].totalAdultMedicaid += item.adultMedicaid;

            if (item.isCallCenterSupported) {
                tempData['Total PCC Supported'].totalMedicaid += item.medicaid;
                tempData['Total PCC Supported'].totalCashUnder18 += item.cashUnder18;
                tempData['Total PCC Supported'].totalCash += item.cash;
                tempData['Total PCC Supported'].totalPpoUnder18 += item.ppoUnder18;
                tempData['Total PCC Supported'].totalPpo += item.ppo;
                tempData['Total PCC Supported'].totalAdultMedicaid += item.adultMedicaid
            } else {
                tempData['Total Practice Supported'].totalMedicaid += item.medicaid;
                tempData['Total Practice Supported'].totalCashUnder18 += item.cashUnder18;
                tempData['Total Practice Supported'].totalCash += item.cash;
                tempData['Total Practice Supported'].totalPpoUnder18 += item.ppoUnder18;
                tempData['Total Practice Supported'].totalPpo += item.ppo;
                tempData['Total Practice Supported'].totalAdultMedicaid += item.adultMedicaid
            }

            const selectedCategories :any=[];
            if(item.medicaid > 0){selectedCategories.push('medicaid')}
            if(item.cashUnder18 > 0){selectedCategories.push('cashUnder18')}

            if(item.ppoUnder18 > 0){selectedCategories.push('ppoUnder18')}
            if(item.cash > 0){selectedCategories.push('cash')}
            if(item.ppo > 0){selectedCategories.push('ppo')}

            if(item.adultMedicaid > 0){selectedCategories.push('adultMedicaid')}
            let i : number =0;
            rows.push(
                <tr key={item.practiceName}>
                    <td>{item.practiceName.toUpperCase()}</td>
                    <td>
                      <span
                          className={"clickable"}
                      >
                           <Button
                               primary={true}
                               onClick={() => showProcedureCodePopup(item.practiceName && item.practiceName.toLowerCase())}
                           >
                               Exclude ProcedureCodes
                           </Button>
                      </span>
                    </td>
                    <td>{item.practiceBrand}</td>
                    <td>{item.callCenter}</td>
                    <td>
                        <span
                            className={item.medicaid > 0?"clickable":""}
                            onClick={() => item.medicaid > 0 && showDetailModal('medicaid', item.practiceName,"",item.medicaid)}
                        >
                          <b>{item.medicaid}</b>
                        </span>
                    </td>
                    <td>
                        <span
                            className={item.cashUnder18 > 0?"clickable":""}
                            onClick={() => item.cashUnder18 > 0 && showDetailModal('cashUnder18', item.practiceName,"",item.cashUnder18)}
                        >
                          <b>{item.cashUnder18}</b>
                        </span>
                    </td>
                    <td>
                        <span
                            className={item.cash > 0?"clickable":""}
                            onClick={() => item.cash > 0 && showDetailModal('cash', item.practiceName,"",item.cash)}
                        >
                          <b>{item.cash}</b>
                        </span>
                    </td>
                    <td>
                        <span
                            className={item.ppoUnder18 > 0?"clickable":""}
                            onClick={() => item.ppoUnder18 > 0 && showDetailModal('ppoUnder18', item.practiceName,"",item.ppoUnder18)}
                        >
                          <b>{item.ppoUnder18}</b>
                        </span>
                    </td>

                    <td>
                        <span
                            className={item.ppo > 0?"clickable":""}
                            onClick={() => item.ppo > 0 && showDetailModal('ppo', item.practiceName,"",item.ppo)}
                        >
                          <b>{item.ppo}</b>
                        </span>
                    </td>

                    <td>
                        <span
                            className={item.adultMedicaid > 0?"clickable":""}
                            onClick={() => item.adultMedicaid > 0 && showDetailModal('adultMedicaid', item.practiceName,"",item.adultMedicaid)}
                        >
                          <b>{item.adultMedicaid}</b>
                        </span>
                    </td>
                    <td>
                        <span
                            className={totalUnscheduledAppt> 0?"clickable":""}
                            onClick={() =>totalUnscheduledAppt > 0 && showDetailModal(selectedCategories, item.practiceName,"",totalUnscheduledAppt)}
                        >
                          <b>{totalUnscheduledAppt}</b>
                        </span>
                    </td>
                </tr>
            )
        });
        Object.keys(tempData).forEach((tempItem,i ) => {
            const item = tempData[tempItem];
            let callCenter=tempItem==="Total OverAll"?"":(
                tempItem==="Total Practice Supported"?"Yes":
                    (tempItem==="Total PCC Supported"?"No":"")
            )
            const total = item.totalMedicaid +  item.totalCashUnder18 + item.totalCash + item.totalPpoUnder18
                + item.totalPpo + item.totalAdultMedicaid
            rows.push(
                <tr>
                    <td colSpan={4}>{tempItem}</td>
                    <td>
                        <span
                            className={item.totalMedicaid> 0?"clickable":""}
                            onClick={() =>item.totalMedicaid > 0 && showDetailModal('medicaid', "",callCenter,item.totalMedicaid)}
                        >
                          <b>{item.totalMedicaid}</b>
                        </span>
                    </td>
                   <td>
                         <span
                             className={item.totalCashUnder18> 0?"clickable":""}
                             onClick={() =>item.totalCashUnder18 > 0 && showDetailModal('cashUnder18', "",callCenter,item.totalCashUnder18)}
                         >
                          <b>{item.totalCashUnder18}</b>
                         </span>
                    </td>
                    <td>
                         <span
                             className={item.totalCash> 0?"clickable":""}
                             onClick={() =>item.totalCash > 0 && showDetailModal('cash', "",callCenter,item.totalCash)}
                         >
                          <b>{item.totalCash}</b>
                         </span>
                    </td>
                    <td>
                         <span
                             className={item.totalPpoUnder18> 0?"clickable":""}
                             onClick={() =>item.totalPpoUnder18 > 0 && showDetailModal('ppoUnder18', "",callCenter,item.totalPpoUnder18)}
                         >
                          <b>{item.totalPpoUnder18}</b>
                         </span>
                    </td>
                    <td>
                         <span
                             className={item.totalPpo> 0?"clickable":""}
                             onClick={() =>item.totalPpo > 0 && showDetailModal('ppo', "",callCenter,item.totalPpo)}
                         >
                          <b>{item.totalPpo}</b>
                         </span>
                    </td>
                    <td>
                         <span
                             className={item.totalAdultMedicaid> 0?"clickable":""}
                             onClick={() =>item.totalAdultMedicaid > 0 && showDetailModal('adultMedicaid', "",callCenter,item.totalAdultMedicaid)}
                         >
                          <b>{item.totalAdultMedicaid}</b>
                         </span>
                    </td>
                    <td>
                        <span
                            className={total> 0?"clickable":""}
                            onClick={() =>total > 0 && showDetailModal(['medicaid','cashUnder18','ppoUnder18','cash','ppo','adultMedicaid'], "",callCenter,total)}
                        >
                          <b>{total}</b>
                        </span>
                    </td>
                </tr>
            )
        })
        unscheduledTreatmentHeader.forEach((item) => {
            header.push(<th>{item}</th>)
        });
        tableData.push(
            <table className="table tableCustomStyle mb0 mt10">
                <thead>
                <tr>
                    {header}
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );

        return tableData;
    };

   const hideDetailModal = () => {
        setStates({
            ...state,
            showDetailModal: false,
            selectedCategory: null,
            selectedPractice: null
        })
    }

    const changeView=(event: any)=>{
        setStates({
            ...state,
            view: event.target.value
        });
    }

   const changeFilter = (_e:any, element: any) => {
       const { mainData } = state
       let temp: any = [];

       if (typeof element.value === "string" && element.value.toLowerCase() === 'all') {
           setStates({ ...state, selectedBrand: null, selectedRegion: null, selectedPractice: null, data: mainData})
           return
       }

       if (element.name === 'practice') {
           mainData.forEach((data: any) => {
               if (data.practiceName === element.value.toLowerCase()) {
                   temp.push(data)
               }
           })
           setStates({ ...state, selectedBrand: null, selectedRegion: null, selectedPractice: element.value, data: temp})
       }

       if (element.name === 'brand') {
           mainData.forEach((data: any) => {
               if (data.brand === element.value) {
                   temp.push(data)
               }
           })
           setStates({ ...state, selectedBrand: element.value, selectedRegion: null, selectedPractice: null, data: temp})
       }

       if (element.name === 'region') {
           mainData.forEach((data: any) => {
               if (data.regionId === element.value) {
                   temp.push(data)
               }
           })
           setStates({ ...state, selectedBrand: null, selectedRegion: element.value, selectedPractice: null, data: temp})
       }
   }

    return(
        <Template activeLink='usage-spot'>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">90 Days Unscheduled Treatment <HelpMark pageId='0' appIsDown={{status:appIsDown, appsDown:myCDPApplicationList}} /></h2>

                    <div className="topFilters">


                        <Dropdown
                            placeholder='Select Practice'
                            options={state.practicesOpts}
                            selection={true}
                            value={state.selectedPractice}
                            onChange={changeFilter}
                            name="practice"
                        />
                        <Dropdown
                            placeholder='Select Brand'
                            selection
                            options={brands}
                            onChange={changeFilter}
                            value={state.selectedBrand}
                            name="brand"
                        />
                        <Dropdown
                            placeholder='Select Region'
                            selection
                            options={state.regionsOpts}
                            onChange={changeFilter}
                            value={state.selectedRegion}
                            name="region"
                        />

                    </div>
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    {!loader && getTableData()}
                </div>
            </div>
            {state.procedureCodePopup && <ProcedureCodePopup
                hideProcedureCodePopup={hideProcedureCodePopup}
                excludeProceduresCodeStr={state.excludeProceduresCodeStr}
                handleProcedureCodeSubmit={handleProcedureCodeSubmit}
                procedureCopePracticeName={state.procedureCopePracticeName}
                handleProcCodeChange={handleProcCodeChange}
            /> }
            {state.showDetailModal && <ProcedureCodeDetailsModal
                hideDetailModal={hideDetailModal}
                sendEmail={sendEmail}
                changeView={changeView}
                stateData={state}
            />}
        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
        practice: state.practice
    };
}

export default connect(mapStateToProps)(SpotUnscheduledTreatment)