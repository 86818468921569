import {Button, Modal} from "semantic-ui-react";
import React from "react";

const ManagerInfoModal = (props: any) => {

  return (
      <>
        {
          <Modal
              closeOnEscape={true}
              closeOnDimmerClick={true}
              open={props.modalOpen}
              onClose={props.handleModalClose}
          >
            <Modal.Header>
              <b>{props.practice} Manager</b>
              <div className="ui top right attached label production_right_label">
                <Button onClick={() => props.handleModalClose()} inverted={true}>
                  Close
                </Button>
              </div>
            </Modal.Header>
            <Modal.Content scrolling={true}>
              <Modal.Description>
                <div className="p-3">
                  <div className="mb-3">
                    <label className="font-weight-bold mr-2">Name:</label>
                    <span> {props.modalData.firstName} {props.modalData.lastName}</span>
                  </div>
                  { props.modalData.cellNumber && (<div className="mb-3">
                    <label className="font-weight-bold mr-2">Phone:</label>
                    <span>
                    <a
                        href={props.modalData.cellNumber && `tel:+1${props.modalData.cellNumber.replace(/\D+/g, "")}`}
                    > {props.modalData.cellNumber}
                    </a>
                  </span>
                  </div>)}
                  {props.modalData.email && (<div className="mb-3">
                    <label className="font-weight-bold mr-2">Email:</label>
                    <span>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={props.modalData.email && `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${props.modalData.email}`}
                    > {props.modalData.email}
                    </a>
                  </span>
                  </div>)}
                </div>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        }
      </>
  );
};

export default ManagerInfoModal;

