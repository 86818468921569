/* eslint-disable*/
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import HelpForm from './HelpForm';
import {HelpPages} from '../constants';
import _ from "lodash";
import {Button, Dropdown, Icon, Input} from 'semantic-ui-react'
import {useActions} from "../redux/actions";
import * as Actions from "../redux/actions/helpMark";
import {RootState} from "../model";
import HelpMark from "./HelpMark";
import * as issuesActions from "../redux/actions/downTimeTracker";

var myCDPApplicationList: any[] = ['Admin'];
function ManageHelp(props: any) {

    const initialState = {
        userId: '',
        role: 'Dentist',
        column: null,
        data: [] as any[],
        order: true,
        openForm: false,
        addForm: {},
        editForm: {},
        searchKeyword: '',
        selectedPageId: null as any,
        allowEdit: !!(props.user && props.user.isAdmin),
        selectedStatusId: null as any,
        appIsDown:false
    }
    const [state, setState] = useState(initialState);
    const helpActions = useActions(Actions);
    const issuesAction = useActions(issuesActions);

    useEffect(() => {

        fetchData()
    }, [])

    const checkIssues = async() => {
        return await issuesAction.fetchOpenIssuesList(myCDPApplicationList);
    }

    const fetchData = () => {
        helpActions.getHelpList().then(async (result: any[]) => {
            const helpPages = Object.keys(HelpPages)
                .filter(pageId => !result.some((d: any) => d.pageId == pageId))
                .map(pageId => ({pageId: Number.parseInt(pageId)}));
            const data = [...result, ...helpPages];
            data.forEach((item: any) => {
                item.page = (
                    (item.parentPageId && HelpPages[item.parentPageId] && HelpPages[item.pageId]) ?
                        `${HelpPages[item.parentPageId].name} > ${HelpPages[item.pageId].name}` :
                        HelpPages[item.pageId] ? HelpPages[item.pageId].name : ""
                );
            });

            let results: any = 0
            await checkIssues().then((res: any) => {
                results = res
            })
            setState({...state,
                data,
                addForm: {},
                openForm: false,
                editForm: {},
                appIsDown: results.length > 0
            });
        });
    }

    const search = (e: any) => {
        const keyword = e.target.value;
        setState({...state, searchKeyword: keyword});
    }

    const filter = (e: any, input: any) => {
        setState({...state, [input.name]: input.value});
    }

    const handleSort = (clickedColumn: any) => {
        const {column, data, order} = state;
        const dat: any = _.sortBy(data, [clickedColumn]);
        if (column !== clickedColumn) {
            setState({
                ...state,
                column: clickedColumn,
                data: dat,
                order: true,
            })

            return
        }

        setState({
            ...state,
            data: data.reverse(),
            order: !order
        })
    }

    const openForm = (key: any, pageId?: number) => {
        let stateobj: any = {openForm: 'addForm'}
        if (key !== 'addForm') {
            stateobj.openForm = 'editForm';
            let {data} = state;
            const editForm: any = state.editForm
            if (data && data[key] && editForm['id'] !== data[key]['id']) {
                stateobj['editForm'] = Object.assign({}, data[key]);
                // Interchange values for drop down
                if (stateobj['editForm']['parentPageId'] == '0') {
                    stateobj['editForm']['parentPageId'] = stateobj['editForm']['pageId'];
                    stateobj['editForm']['pageId'] = '0';
                }
                if (stateobj['editForm']['fileLinks'] && stateobj['editForm']['fileLinks'] !== '') {
                    stateobj['editForm']['fileLinks'] = stateobj['editForm']['fileLinks'].split(",");
                }
            }
        } else
            stateobj.addForm = {parentPageId: pageId};
        setState({...state, ...stateobj});
    }
    const hideForm = () => {
        setState({...state, openForm: false});
    }

    const saveForm = async (obj: any) => {
        hideForm();
        obj.fileLinks = (obj.fileLinks && obj.fileLinks.length) ? obj.fileLinks.join(",") : "";
        const site = HelpPages[obj.pageId].name ? HelpPages[obj.pageId].name.split('-') : ['udr'];
        obj.site = site && site[0].toLowerCase().trim();
        await helpActions.saveForm(obj);
        fetchData();
    }

    const updateForm = async (obj: any) => {
        hideForm();
        obj.fileLinks = (obj.fileLinks && obj.fileLinks.length) ? obj.fileLinks.join(",") : "";
        delete obj.page;
        const site = HelpPages[obj.pageId].name ? HelpPages[obj.pageId].name.split('-') : ['udr'];
        obj.site = site && site[0].toLowerCase().trim();
        await helpActions.updateForm(obj);
        fetchData();
    }

    const filterBySearch = (item: any) => {
        const bySearch = () => {
            if (!state.searchKeyword) return true;
            const columns = ['page', 'description', 'heading'];
            return columns.some(column => item[column] && item[column].toLowerCase().includes(state.searchKeyword.toLowerCase()));
        }
        const byFilter = () => {
            if (!state.selectedPageId) return true;
            return item.pageId == state.selectedPageId;
        }
        const byStatus = () => {
            if (!state.selectedStatusId) return true;
            const status = item.id ? item.isActive ? 1 : 2 : 3;
            return status == state.selectedStatusId;
        }
        return byFilter() && bySearch() && byStatus();
    }

    const {data, column, order, allowEdit} = state;
    const direction = order ? 'down' : 'up';

    const helpOptions = Object.keys(HelpPages)
        .sort((a: any, b: any) => (a.text || '').localeCompare(b.text))
        .map(pageId => ({text: HelpPages[pageId].name, value: Number.parseInt(pageId), key: pageId}));
    const statusOptions = [
        {text: 'Active', value: 1},
        {text: 'InActive', value: 2},
        {text: 'Not Saved', value: 3}
    ];

    return (
        <div className="calendar-container">
            {
                state.openForm &&
                <HelpForm
                    action={state.openForm}
                    hideForm={hideForm}
                    addForm={state.addForm}
                    editForm={state.editForm}
                    saveForm={saveForm}
                    updateForm={updateForm}
                />
            }
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left">Manage Help Section <HelpMark pageId='72' appIsDown={{status:state.appIsDown, appsDown:myCDPApplicationList}}/></h2>
                    {allowEdit ? <div className="topFilters">
                        <Button primary={true} onClick={() => openForm('addForm')}>Add
                            New</Button>
                        <Dropdown
                            search={true}
                            clearable={true}
                            wrapSelection={true}
                            style={{minWidth: '30em'}}
                            name="selectedPageId"
                            placeholder="Select Page"
                            selection={true}
                            options={helpOptions}
                            onChange={filter}
                            value={state.selectedPageId}
                        />
                        <Dropdown
                            search={true}
                            clearable={true}
                            wrapSelection={true}
                            name="selectedStatusId"
                            placeholder="Select Status"
                            selection={true}
                            options={statusOptions}
                            onChange={filter}
                            value={state.selectedStatusId}
                        />
                        <Input
                            icon='search'
                            name="search"
                            placeholder="Search"
                            value={state.searchKeyword}
                            onChange={search}
                        />
                    </div> : ""}
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    <div className="FreezeTable">
                        <table className="ui table table-striped celled table-hover unstackable">
                            <thead>
                            <tr>
                                <th className={`cursorPointer ${column === 'heading' ? 'active' : ''}`}
                                    onClick={() => handleSort('heading')}>
                                    <div>Heading <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer ${column === 'description' && 'active'}`}
                                    onClick={() => handleSort('description')}>
                                    <div>Description <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer ${column === 'page' && 'active'}`}
                                    onClick={() => handleSort('page')}>
                                    <div>Page <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer ${column === 'isActive' ? 'active' : ''}`}
                                    onClick={() => handleSort('isActive')}>
                                    <div>Status<i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                {allowEdit && <th><div>Action</div></th>}
                            </tr>
                            </thead>
                            <tbody>
                            {data && data.filter(filterBySearch).map((item: any, key) =>
                                <tr key={key}>
                                    <td>
                                        {
                                            item.fileLinks && item.fileLinks.length ?
                                                <Icon name='attach' className='mr-2'/> :
                                                <span className='ml15'>&nbsp;&nbsp;</span>
                                        }
                                        {item.id ? item.heading : <b className='text-muted'>-</b>}
                                    </td>
                                    <td>{item.id ? item.description : <b className='text-muted'>-</b>}</td>
                                    <td>{item.page}</td>
                                    <td>
                                        {item.id ? item.isActive ? 'Active' : 'Inactive' : 'Not Saved'}
                                    </td>
                                    {
                                        allowEdit &&
                                        <td>
                                            <Button
                                                size='small'
                                                className={item.id ? 'secondary' : 'primary'}
                                                onClick={() => openForm(item.id ? key : 'addForm', item.pageId)}
                                            >
                                                {item.id ? 'Edit' : 'Add'}
                                            </Button>
                                        </td>
                                    }
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        alert: state.alert,
        accessToken: state.auth.accessToken
    };
}

export default connect(mapStateToProps)(ManageHelp);
