import {ActionType} from "../../model/actionTypes"
import {errorHandler} from "./auth";

export const fetchIssuesList = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp = await api.get(`downtimeTracker/getIssues`);
        dispatch({type: ActionType.LOAD_DOWNTIME_TRACKER_LIST, payload: {issues_list: resp}});
        return resp;
    } catch (err) {
        return dispatch(errorHandler(err));
    } finally {
        dispatch({type: ActionType.LOADER, payload: false});
    }
}

export const fetchOpenIssuesList = (applicationList:string[]) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        return await api.post(`downtimeTracker/getOpenIssues`, {applicationList:applicationList});
    } catch (err) {
        return dispatch(errorHandler(err));
    } finally {
        dispatch({type: ActionType.LOADER, payload: false});
    }
}

export const saveForm = (postObj: any, action: any) => async (dispatch: Function, getState: Function, api: any) => {
    if (action == 'editForm') {
        dispatch({type: ActionType.LOADER, payload: true});
        try {
            return await api.put(`downtimeTracker/updateIssue`, postObj);
        } catch (err) {
            return dispatch(errorHandler(err));
        } finally {
            dispatch({type: ActionType.LOADER, payload: false});
        }

    } else {
        dispatch({type: ActionType.LOADER, payload: true});
        try {
            return await api.post(`downtimeTracker/createIssue`, postObj);
        } catch (err) {
            return dispatch(errorHandler(err));
        } finally {
            dispatch({type: ActionType.LOADER, payload: false});
        }
    }
}

export const saveNote = (postObj: any, action: any) => async (dispatch: Function, getState: Function, api: any) => {
    if (action == 'editForm') {
        dispatch({type: ActionType.LOADER, payload: true});
        try {
            return await api.put(`downtimeTracker/updateNote`, postObj);
        } catch (err) {
            return dispatch(errorHandler(err));
        } finally {
            dispatch({type: ActionType.LOADER, payload: false});
        }

    } else {
        dispatch({type: ActionType.LOADER, payload: true});
        try {
            return await api.post(`downtimeTracker/createNote`, postObj);
        } catch (err) {
            return dispatch(errorHandler(err));
        } finally {
            dispatch({type: ActionType.LOADER, payload: false});
        }
    }
}

export const getNotes = (id: number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        return await api.get(`downtimeTracker/notes?downTimeIssueId=${id}&limit=20`);
    } catch (err) {
        return dispatch(errorHandler(err));
    } finally {
        dispatch({type: ActionType.LOADER, payload: false});
    }
}

export const sendNotification = (postObj: any) => async (dispatch: Function, getState: Function, api: any) => {
    try {
        dispatch({type: ActionType.LOADER, payload: true});
        return await api.post(`downtimeTracker/sendNotification`, postObj);
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
    } finally {
        dispatch({type: ActionType.LOADER, payload: false});
    }
}

