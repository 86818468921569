// Add {hide: true} in object to hide any menu from menu bar
import {MainMenu} from "./constants";

export const menuOSP: Array<MainMenu> = [
    {
    pageId: 43,
    name: "Open Surgery Dashboard",
    class: "innerDropdown",
    icon: "file alternate outline",
    title: "Brand Rollup",
    partner: 'Open Surgery Dashboard',
    role: []
    },
    {
    pageId: 44,
    name: "Open Surgery PCR Report",
    class: "innerDropdown",
    icon: "file alternate outline",
    title: "OS PCR By Practice",
    partner: 'Open Surgery PCR Report',
    role: []
  },
    {
        pageId: 45,
        name: "Referral Data OS Practices",
        class: "innerDropdown",
        icon: "file alternate outline",
        title: "Referral Data - OS Practices",
        partner: 'Referral Data - OS Practices',
        role: []
    },
    {
        pageId: 46,
        name: "Referral Data Future OS Offices",
        class: "innerDropdown",
        icon: "file alternate outline",
        title: "Referral Data - Future OS Offices",
        partner: 'Referral Data - Future OS Offices',
        role: []
    },

];