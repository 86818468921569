import React, {useState} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../model";
import {Accordion, AccordionTitleProps, Card, Dropdown, Grid, Icon, Input, Label, Table} from "semantic-ui-react";
import moment from "moment";
import {useActions} from "../../../redux/actions";
import * as spotActions from "../../../redux/actions/spot";

interface Props {
    patientId: string | undefined,
    practice: string | undefined,
    result: any[],
    lastExamResult: any[],
    openTreatment: any[],
    resultOptions: any[]
}

function FamilyView(props: Props) {
    const spotAction = useActions(spotActions);
    const [state, setStates] = useState({activeIndex: 0, notes: null as any, result: null as any});
    const result = props.result && props.result[0] && props.result[0].result;
    const notes = props.result && props.result[0] && props.result[0].notes;

    const handleClick = (e: any, titleProps: AccordionTitleProps) => {
        const index = titleProps.index as number;
        const newIndex: number = state.activeIndex === index ? -1 : index || 0;
        setStates({...state, activeIndex: newIndex || 0})
    }

    const saveResults = async (obj: any) => {
        setStates({...state, ...obj});
        const updateObj = {...state, ...obj, practice: props.practice};
        updateObj.notes = updateObj.notes || notes;
        updateObj.result = updateObj.result || notes;

        for (let i = 0; i < (props.result || []).length; i++) {
            await spotAction.saveBrokenResult({...props.result[i], ...updateObj});
        }
    }

    const matchTreatmentTotal = (treatmentPlan:any, txPlanIgnore:any) => {
        let txPlanIgnoreObj = txPlanIgnore?JSON.parse(txPlanIgnore):{};
        let txPlanTotal = 0;

        treatmentPlan.forEach((item:any) => {
            if(item.ProcFee && txPlanIgnoreObj[item.ProcNum] != 'true') {
                txPlanTotal += item.ProcFee;
            }
        });
        return txPlanTotal;
    };

    const getStatusTextClass = (ptNum:any) => {
        let response:any = {}
        ptNum.forEach((item:any) => {
            let text = null
            let cls = '';
            const NextAptDays = item.Next_Apt ? moment(item.Next_Apt).diff(moment(), 'days') : item.Next_Apt;
            const matchTxTotal = (Object.keys(props.openTreatment).length
                && props.openTreatment[item.PT_Num]) ?
                matchTreatmentTotal(props.openTreatment[item.PT_Num], item.txPlanIgnore) : 0;

            const lastExamDate = (Object.keys(props.lastExamResult).length
                && props.lastExamResult[item.PT_Num]) ?
                props.lastExamResult[item.PT_Num] : 0;
            if( item.Next_Apt && NextAptDays <= 14 && NextAptDays >= 0 && matchTxTotal ) {
                text = `TX - ${matchTxTotal.toFixed(2)}`;
                cls = 'text-green';
            } else if( (!item.Next_Apt || item.Next_Apt < 0 || NextAptDays > 14) && matchTxTotal ) {
                text = `TX - ${matchTxTotal.toFixed(2)}`;
                cls = 'text-red';
            } else if(!item.Days_Since_Last_Visit && item.Next_Apt) {
                text = 'NP';
                cls = 'text-green';
            } else if(!item.Days_Since_Last_Visit && !item.Next_Apt) {
                text = 'NP';
                cls = 'text-red';
            } else if(item.age < 3 && item.Next_Apt) {
                text = 'FDH';
                cls = 'text-green';
            } else if(item.age < 3 && !item.Next_Apt) {
                text = 'FDH';
                cls = 'text-red';
            } else if(lastExamDate > 180 && item.Next_Apt) {
                text = 'RC';
                cls = 'text-green';
            } else if(lastExamDate > 180 && !item.Next_Apt) {
                text = 'RC';
                cls = 'text-red';
            } else if(lastExamDate && lastExamDate <= 180 && item.Next_Apt) {
                text = 'ND';
                cls = 'text-green';
            } else if(lastExamDate && lastExamDate <= 180 && !item.Next_Apt) {
                text = 'ND';
                cls = 'text-red';
            } else if(!lastExamDate && item.LastVisit) {
                text = 'RC';
                cls = 'text-red';
            }
            response[item.PT_Num] = { class: cls, text: text?' - '+text:''}
        });
        return response;
    };

    const {activeIndex} = state;

    return (
        <Card>
            <Card.Content>
                <Card.Header>
                    <h3>FAMILY VIEW</h3>
                </Card.Header>
            </Card.Content>
            <Accordion fluid={true} styled={true}>
                <Card>
                    <Card.Content>
                        <Grid>
                            <Grid.Row columns="equal">
                                <Grid.Column>
                                    <small className="primaryColor text-uppercase"> <b>Result</b></small>
                                    <br/>
                                    <Dropdown
                                        options={props.resultOptions}
                                        onChange={(e, d) => saveResults({result: d.value})}
                                        value={state.result || result}
                                        selection={true}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <small className="primaryColor text-uppercase"> <b>Notes</b></small>
                                    <br/>
                                    <Input
                                        name='notes'
                                        type='text'
                                        value={state.notes || notes}
                                        onChange={(e, d) => setStates({...state, notes: d.value})}
                                        onBlur={(e: any) => saveResults({notes: e.target.value})}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Card.Content>
                </Card>

                {
                    props.result && props.result.map((item, index) => {
                        return [
                            <Accordion.Title
                                active={activeIndex === index}
                                index={index}
                                key={index + 'acc_title'}
                                onClick={handleClick}
                            >
                                <Icon name='dropdown'/>
                                <b>{item.Guarantor == item.PT_Num ?
                                    <span>Guarantor: {item.PT_Name}
                                        <b className={getStatusTextClass(props.result)[item.PT_Num].class}>{getStatusTextClass(props.result)[item.PT_Num].text}</b></span> :
                                    <span>Dependent: {item.PT_Name}
                                        <b className={getStatusTextClass(props.result)[item.PT_Num].class}>{getStatusTextClass(props.result)[item.PT_Num].text}</b></span> }
                                    </b>
                            </Accordion.Title>,
                            <Accordion.Content key={index + 'acc_content'} active={activeIndex === index}>
                                <Table key={index + 'test_'} striped={true} style={{border: 'none'}}>
                                    <Table.Body key={item}>
                                        <Table.Row>
                                            <Table.Cell>
                                                <small className="primaryColor text-uppercase">
                                                    <b>Patient Name</b>
                                                </small>
                                                 <a href={`${item.PT_Num}${window.location.search}`}>
                                                    <p className="color-secondary">
                                                        <b>{item.PT_Name}</b>
                                                    </p>
                                                </a>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <small className="primaryColor text-uppercase"> <b>Patient
                                                    ID</b></small>
                                                <p className="color-secondary">
                                                    <b>{item.PT_Num}</b>
                                                </p></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                <small className="primaryColor text-uppercase"> <b>AGE</b></small>
                                                <p className="color-secondary">
                                                    <b>{item.age}</b>
                                                </p></Table.Cell>
                                            <Table.Cell>
                                                <small className="primaryColor text-uppercase"> <b>Birth
                                                    Date</b></small>
                                                <p className="color-secondary">
                                                    <b>{item.BirthDate}</b>
                                                </p>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                <small className="primaryColor text-uppercase"> <b>Practice</b></small>
                                                <p className="color-secondary text-uppercase">
                                                    <b> {props.practice}</b>
                                                </p>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <small className="primaryColor text-uppercase"> <b>Visit
                                                    Count</b></small>
                                                <p className="color-secondary">
                                                    <b>{item.visitCount && item.visitCount.Visit_Count || ''}</b>
                                                </p>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                <small className="primaryColor text-uppercase"> <b>Last
                                                    Visit</b></small>
                                                <p className="color-secondary">
                                                    <b>{item.LastVisit || ''}</b>
                                                </p>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <small className="primaryColor text-uppercase"> <b>Next
                                                    Appointment</b></small>
                                                <p className="color-secondary">
                                                    <b>{(item.Next_Apt) ? item.Next_Apt : ''}</b>
                                                </p>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                <small className="primaryColor text-uppercase"> <b>Show Rate
                                                    (%)</b></small>
                                                <p className="color-secondary">
                                                    <b>{item.showRate && item.showRate.show_rate || ''}</b>
                                                </p>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <small className="primaryColor text-uppercase"> <b>Insurance</b></small>
                                                <p className="color-secondary">
                                                    <b>
                                                        {item.insurance && item.insurance.Insurance || ''}
                                                    </b>
                                                </p>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell>
                                                <small className="primaryColor text-uppercase"> <b>Best Contact
                                                    Time</b></small>
                                                <p className="color-secondary">
                                                    <b>{item.bestContactTime}</b>
                                                </p></Table.Cell>
                                            <Table.Cell>
                                                <small className="primaryColor text-uppercase"> <b>Ins
                                                    Verified</b></small>
                                                <p className="color-secondary">
                                                    <b>
                                                        {item.DateLastVerified ? item.DateLastVerified : item.insurance && item.insurance.DateLastVerified ? moment(item.insurance.DateLastVerified).format("MM/DD/YYYY") : ''}
                                                    </b>
                                                </p>
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell colSpan={2}>
                                                <small className="primaryColor text-uppercase"> <b>Phone
                                                    Number</b></small>
                                                <p className="color-secondary">
                                                    <b>
                                                        {item.HmPhone || item.WkPhone || item.WirelessPhone || item.phone}
                                                    </b>
                                                </p>
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </Accordion.Content>
                        ]
                    })
                }
            </Accordion>
        </Card>
    )
}

function mapStateToProps(state: RootState) {
    return {user: state.auth.user}
}

export default connect(mapStateToProps)(FamilyView)
