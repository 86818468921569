import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";


export const fetchOmRm=()=>async (dispatch:Function, getState:Function,api:any) => {
      const url = `spore/notification/omrm`;
      dispatch({ type: ActionType.LOADER, payload: true });

      try{
          const resp= await api.get(url)
          dispatch({type:ActionType.LOAD_SPORE_NOTIFICATION_OMRM,payoad:{omrm:resp}})
          dispatch({ type: ActionType.LOADER, payload: false });
          return resp;
      }catch(err){
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
      }
}


export const fetchTableResult=(id:any)=>async(dispatch:Function, getState:Function,api:any) => {
      const url = `spore/notification/${JSON.stringify(id)}` 
      dispatch({ type: ActionType.LOADER, payload: true });
      
      try {
          const resp=await api.get(url)
          dispatch({type:ActionType.LOAD_SPORE_NOTIFICATION_TABLE,payload:{notifications:resp}})
          dispatch({ type: ActionType.LOADER, payload: false });
          return resp;
      }catch(err){
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
      }
}


export const deleteRecord=(id:number) =>async(dispatch:Function, getState:Function,api:any) => {
      const url = `spore/notification/${id}`
      dispatch({ type: ActionType.LOADER, payload: true });
      try {
          const resp=await api.delete(url)
          const sporeNotifications=getState().sporeNotification.notifications
          const filteredNotifications=sporeNotifications.filter((elem:any)=>elem.id!=id)
          dispatch({type:ActionType.LOAD_SPORE_NOTIFICATION_TABLE,payload:{notifications:filteredNotifications}})
          dispatch({ type: ActionType.LOADER, payload: false });
          return filteredNotifications;
      }catch(err){
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
      }
  }

export const saveForm=(postObj:any, action:any) => async(dispatch:Function, getState:Function,api:any) => {

      if(action == 'editForm') {
        dispatch({ type: ActionType.LOADER, payload: true });

        try{
           
            const resp=await api.put(`spore/notification`,postObj)
            dispatch({type:ActionType.SPORE_NOTIFICATION_SAVE_STATES,payload: { editForm: false }})
            dispatch(fetchTableResult({payload:0,all:"all"}))
            dispatch({ type: ActionType.LOADER, payload: false });
            return resp
        }catch(err){
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(err));
          }
        
      } else {
        dispatch({ type: ActionType.LOADER, payload: true });
        try{
            const resp=await api.post(`spore/notification`,postObj)
            dispatch({type:ActionType.SPORE_NOTIFICATION_SAVE_STATES,payload: { createForm: false }})
            dispatch(fetchTableResult({payload:0,all:"all"}))
            dispatch({ type: ActionType.LOADER, payload: false });
            return resp
        }catch(err){
            dispatch({ type: ActionType.LOADER, payload: false });
            return dispatch(errorHandler(err));
          }
        }
      
}

export const fetchPractices = () => async (
  dispatch: Function,
  getState: Function,
  api: any
) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const resp = await api.get(`spore/practices`);
    dispatch({
      type: ActionType.LOAD_SPORENOTIFICATION_USER_PRACTICES,
      payload: { user_practices: resp },
    });
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (error) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(error));
  }
};
