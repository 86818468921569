import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const referralsPractices = (params: any) => async (dispatch: Function, getState: Function, api: any) =>{
    let stringifyParams;
    if(params){
        stringifyParams = '/'+JSON.stringify(params)
    }
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const res: any = await api.get('report/referrals/practices' + stringifyParams);
        dispatch({ type: ActionType.LOADER, payload: false });
        return res;
    } catch (e) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(e));
    }
};