import React, {SyntheticEvent, useState} from 'react';
import {HEADERS} from './constants';
import {Button, Table} from "semantic-ui-react";
import {PracticeList} from "../../../model/practice";
import {isSafari} from "../../../constants";
import moment from "moment";

interface ExcelDownloaderProps {
    date:any,
    data: any[],
    allData: any[],
    practices: PracticeList[]
}

function ExcelDownloader(props: ExcelDownloaderProps) {
    const practiceToPT = (practice: any) => ({
        practiceId: practice.practiceId,
        practice: practice.practice,
        date: moment(practice.date).format('YYYY-MM-DD'),
        fv_pt_count: practice.fv_pt_count,
        rc_pt_count: practice.rc_pt_count,
        tx_pt_count: practice.tx_pt_count,
        ttl_pt_count: practice.ttl_pt_count,
        fv_care: practice.fv_care,
        rc_care: practice.rc_care,
        tx_care: practice.tx_care,
        ttl_care: practice.ttl_care
    });

    const prepareDownloadData = () => {
        let dateHasData:boolean =(props.data!=null)&&props.data.length>0;
        let hasAllData:boolean =(props.allData!=null)&&props.allData.length>0;
        let responseData: any[] =[];
        let practiceIds: string[] =[];

            props.allData.forEach((item: any)  => {
                item.date =  props.date?moment(props.date).format('YYYY-MM-DD'):moment(item.date).format('YYYY-MM-DD');
                item.fv_pt_count = 0;
                item.rc_pt_count = 0;
                item.tx_pt_count = 0;
                item.ttl_pt_count = 0;
                item.fv_care = 0;
                item.rc_care = 0;
                item.tx_care = 0;
                item.ttl_care = 0;
                if(!practiceIds.includes(item.id)){
                    practiceIds.push(item.id);
                    responseData.push(item);
                }
                
            })

        if(hasAllData && (props.date!="") || (props.date!=null)){
            props.allData.forEach((item: any)  => {
                if(moment(item.date).isBetween(moment().startOf('month'),moment().endOf('month'))){
                        responseData.push(item);
                }
            })
        }
        return responseData;
    }

    const downloadClick = () => {
        const downloadData:any[] = prepareDownloadData();
        const csvBody = downloadData
            .map(practice => practiceToPT(practice))
            .map(pt => Object.values(pt).join(','))
            .join('\r\n');
        const csvData = HEADERS.map(header => header.name).join(',')
            .concat('\r\n')
            .concat(csvBody);
        const blob = new Blob(['', csvData], {type: isSafari() ? 'application/csv' : 'text/csv'});

        let link = document.createElement("a");
        link.setAttribute("href", (window.URL || window.webkitURL).createObjectURL(blob));
        link.setAttribute("download", 'patient_count_care.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (<Button primary={true} className="ml10 mb15" content="Download Template" onClick={downloadClick}/>);
}


export default ExcelDownloader