import { PCR_KEYS, csvHeaderPayStatement } from "../../constants";
import {ActionType} from "../../model/actionTypes";
import { CsvPayStatementDTO } from "../../model/ukg";
import { sortByDesc } from "../../utils/common";
import {errorHandler} from "./auth";

export const getEmployee = (userFullName: string) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const results = await api.get(`api/ukg/employees/stored-chart/${PCR_KEYS}/${userFullName}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return results;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return null;
    }
};

export const getPositionDetails = (userFullName: string) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const results = await api.get(`api/ukg/employees/positions/${PCR_KEYS}/${userFullName}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return results;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return null;
    }
};

export const getEmployeePayrolls = (employeeId: number) => async(dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const results = await api.get(`api/ukg/employees/payroll/${PCR_KEYS}/${employeeId}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return results;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return null;
    }
};

export const getEmployeePayroll = (employeeId: number, account_id: number) => async(dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const results = await api.get(`api/ukg/employees/payroll/${PCR_KEYS}/${employeeId}/${account_id}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return results;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return null;
    }
};

export const getEmployeePayStatements = (employeeId: number) => async(dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const results = await api.get(`api/ukg/employees/payroll/statements/${PCR_KEYS}/${employeeId}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return results;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return null;
    }
};

export const sendMailPayStatement = (params:any) => async (dispatch: Function, getState: Function, api: any) => {
    try {       
        let payStatementCSVobj:CsvPayStatementDTO[] = [];
        payStatementCSVobj.push(csvHeaderPayStatement);
        params.data = sortByDesc(params.data,"id");           

        for (const item of params.data) {

            payStatementCSVobj.push({Id: item.id,
                Hours: item.hours,
                BaseRate: item.base_rate,
                EEAmountGross: item.ee_amount,
                PayDate: item.pay_date,
                DateRangeFrom: item.date_range_from,
                DateRangeTo: item.date_range_to});
            }

        if (payStatementCSVobj.length) {
            payStatementCSVobj.push({Id: "TOTAL",
            Hours: params.totalHours,
            BaseRate: "",
            EEAmountGross: params.sum,
            PayDate: "",
            DateRangeFrom: "",
            DateRangeTo: ""});
            params.data = payStatementCSVobj;
            await api.post(`api/ukg/sendMailPayStatements`,params);
            dispatch({ type: ActionType.LOADER, payload: false });
            return true;
        }
	} catch (err) {
        console.log(err);
        return dispatch({ type: ActionType.LOADER, payload: false });
	}
}