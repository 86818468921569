import React from 'react';
import { PCR_KEYS } from '../constants';
import {Button, Confirm as ConfirmModal, Grid, Dimmer, Loader, Table} from "semantic-ui-react";
import Template from "./Template";
import * as ReportActions from '../redux/actions/report';
import { useActions } from '../redux/actions';
import { strToCamelCase } from '../utils/common';
import HelpMark from "./HelpMark";


const RerunPCR = () => {
    const [isModal, setModal] = React.useState(false);
    const [displayMessage, setDisplayMessage] = React.useState('');
    const [optionName, setOptionName] = React.useState('');
    const reportActions = useActions(ReportActions);
    const [loader, setLoader] = React.useState(false);

    const reRunPCR = () => {
        reportActions.reRunPCR(PCR_KEYS);
        setModal(false);
    };

    const resendOrthoPCRReport = () => {
        reportActions.resendOrthoPCRReport(PCR_KEYS);
        setModal(false);
    };

    const RunButton = () => {
        const content = 'Yes';
        const RunButtProps: any = {
            content,
            onClick: () => optionName=="rerunPCRCron"?reRunPCR():resendOrthoPCRReport(),
            primary: true,
            type: 'button'
        };
        return (
            <Button {...RunButtProps} />
        )
    };

    const rerunConfirmProps = {
        confirmButton: RunButton,
        content: displayMessage,
        header: 'Re Run Options',
        onCancel: () => setModal(false),
        open: !!isModal
    };

    const rerunPCRCron = () => {
        setOptionName("rerunPCRCron")
        setDisplayMessage("Are you sure you want to Re-run the PCR cron?");
        setModal(true);
    };

    const resendOrthoPCR = () => {
        setOptionName("resendOrthoPCR")
        setDisplayMessage("Are you sure you want to resend the Ortho PCR Report?");
        setModal(true);
    };

    const allPcrReportCron = ["By Practice Report", "By Splash Board Report", "By Age Report","By Age Mix Report",
        "By Budget By Working Report","By Provider CPV Report","By PCR Insurance Report","By PCR Insurance Mix Report",
        "By Outlook Report","By Pcr Cpv Report", "By Pcr Region Roll Up", "By Pcr Brand Roll Up"
      ]

    const rePCRRunCron = (str: string) => {
        setLoader(true);
        const reportName = strToCamelCase(str);
        reportActions.resendPCRReportCron(reportName).then(() => {
            setLoader(false);
        }).catch((err:any) => {
            setLoader(false);
            console.error(err);
        })
    }

    return (
        <React.Fragment>
            {loader && 
                <Dimmer active={true}>
                    <Loader>Loading</Loader>
            </Dimmer>}

            <Template activeLink="mco-login-report">
                    <div className="ui card">
                        <div className="content">
                            <h2 className="float-left mr10">Re run PCR Crons <HelpMark pageId='87'/></h2>
                        </div>
                    </div>
                <div className="ui card">
                    <div className="content">
                <Table singleLine className="ui table table-striped celled table-hover unstackable">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell style={{ color: "#d16f26" }}>
                                <div>Title</div>
                            </Table.HeaderCell>
                            <Table.HeaderCell style={{ color: "#d16f26" }}>
                                <div>Actions</div>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row >
                            <Table.Cell>
                                Re Run PCR Cron
                            </Table.Cell>
                            <Table.Cell>
                                <Button primary size='small' onClick={() => rerunPCRCron()}>Re Run</Button>
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row >
                            <Table.Cell>
                                Resend Ortho PCR Report
                            </Table.Cell>
                            <Table.Cell>
                                <Button primary size='small' onClick={() => resendOrthoPCR()} >Resend</Button>
                            </Table.Cell>
                        </Table.Row>

                        {allPcrReportCron.map((i: string) =>
                        {
                            return ( <Table.Row >
                                <Table.Cell>
                                   Re Run PCR Cron {i}
                                </Table.Cell>
                                <Table.Cell>
                                    <Button primary size='small' onClick={() => rePCRRunCron(i)}>Re Run</Button>
                                </Table.Cell>
                            </Table.Row>  )
                        })
                        }
                    </Table.Body>
                </Table>
                    </div>
                </div>

                {
                    !!isModal &&
                    <ConfirmModal {...rerunConfirmProps} />
                }

        </Template>
        </React.Fragment>
    )
};

export default RerunPCR;