import React from "react";
import Template from "./Template";
import {Button, Grid, Table, Checkbox} from "semantic-ui-react";
import HelpMark from "./HelpMark";

const EmailConfigurations = () => {
    return (
        <Template activeLink="email-configurations">
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">Auto Send Email Configurations <HelpMark pageId='86'/></h2>
                    <div className="topFilters flex items-end flex-wrap">
                        <Button primary>Save</Button>
                    </div>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    <div className="FreezeTable">
                        <Table className="ui table table-striped table-hover celled unstackable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        <div><Checkbox label='MARK ALL'/></div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>NAME</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>EMAIL DESCRIPTION</div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </Template>
    );
};

export default (EmailConfigurations);
