import React, { useState } from 'react'
import {
    TableRow,
    TableCell,
    TableBody,
    Table,
    Label,
    Icon,
    Progress,
    Grid,
    GridColumn,
    GridRow,
} from 'semantic-ui-react'
import { capitalizeFirstLetter } from './healthStatusUtils';

interface props {
    records: any[],
    groupedData: any
}
const PasswordCheckerTable = ({ records, groupedData }: props) => {

    const [state, setState] = useState({ practice: null as any });
    const data = Object.keys(groupedData || {});

    const getProgress = (practice: string) => {
        const practices = groupedData[practice] ? groupedData[practice] : [];
        let count = 0;
        practices.forEach((item: any) => { if (item.status === 'red') count++; });
        return (practices.length - count) * 100 / (practices.length || 1);
    }

    const getSelected = (): any => {
        const { practice } = state;
        return groupedData[practice] ? groupedData[practice] : [];
    }

    const onSelect = (practice: string) => {
        if (state.practice === practice) {
            setState({ ...state, practice: '' });
            return;
        }
        setState({ ...state, practice: practice });
    }

    return (
        <>
            {
                data.map((practice: any, index: number) =>
                    <Table key={index} striped compact='very'>
                        <TableBody>
                            <TableRow  style={{ cursor: 'pointer', backgroundColor: index % 2 !== 0 ? '#d4d4d4' : 'white' }}  onClick={() => onSelect(practice)}>
                                <TableCell><span style={{ marginLeft: '20px' }}><Icon name='dropdown' />{capitalizeFirstLetter(practice)}</span></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell width={2}>
                                    <Progress color='green' size='medium' className='red-bg mb0' percent={getProgress(practice)} />
                                </TableCell>
                            </TableRow>

                            {
                                state.practice === practice && getSelected().map((item: any, index: number) =>
                                    <TableRow key={index}>
                                        <TableCell colspan='6'>
                                            <Grid>
                                                <GridRow>
                                                    <GridColumn width={2}> </GridColumn>
                                                    <GridColumn width={4}>
                                                        <strong>brand :</strong> {item.brand}
                                                    </GridColumn>
                                                    <GridColumn width={4}>
                                                        <strong>mco :</strong> {item.mco}
                                                    </GridColumn>
                                                    <GridColumn width={4}>
                                                        <strong>username :</strong> {item.username}
                                                    </GridColumn>
                                                    <GridColumn textAlign='right' width={2}>
                                                        <Label style={{ width: '60%' }} size='massive' color={item.status} horizontal></Label>
                                                    </GridColumn>
                                                </GridRow>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                )

            }
        </>

    )
}

export default PasswordCheckerTable