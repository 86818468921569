import React, {FC, ReactElement, useState} from "react";
import {
    Button,
    Form,
} from "semantic-ui-react";

type CreateNotesFormProps = {
    saveForm: any;
    item: any;
    action: any;
    closeForm: any;
};


const CreateNotesForm: FC<CreateNotesFormProps> = ({
                                                           saveForm,
                                                           action,
                                                           closeForm,
                                                           item
                                                       }): ReactElement => {
    const initialState = {
        user: item ? item["user"] : "",
        description: item ? item["description"] : ""
    }
    const [state, setState] = useState(initialState)
    const submitForm = async () => {
        saveForm({
            user, description, id: item.id
        }, action)
        closeForm()
    }

    const {
        user, description
    } = state
    return (
        <>
            <Form className={'widthFluid'}>
            <Form.Group widths='equal'>
                <Form.Field>
                <Form.TextArea
                      fluid={true}
                      label='Notes'
                      placeholder='notes'
                      value={description}
                      onChange={(event) => setState({...state, description: (event.target as  HTMLTextAreaElement).value})}
                    />
                </Form.Field>
                </Form.Group>                
                <Form.Group widths='equal' className="float-right">
                <Form.Field>
                    <Button secondary={true} onClick={closeForm}>
                        Cancel
                    </Button>
                    <Button
                        className='ui primary button'
                        onClick={(event) => {
                            event.preventDefault()
                            submitForm()
                        }}
                    >
                        Save
                    </Button>
                </Form.Field>
                </Form.Group>
            </Form>
        </>
    );
};
export default CreateNotesForm;
