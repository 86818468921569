import React, { useState} from "react";
import {Button, Form, Modal} from "semantic-ui-react";
import {brand, brandABBR, mco} from "../../constants";

interface CreateEditModalProps {
    initialValues: {id?:number ,practice?:string ,mco?:string,brand?:string,practiceabbr?:string,
      username?:string,password?:string,serviceLocation?:string,billingEntity?:string,provider?:string,
        facilityId?:string} ,
    cancelForm: () => void,
    saveForm: Function,
    practiceOptions:object [],
    practices: any;
    selectedMCO: string
}
function CreateEditModal(props: CreateEditModalProps) {
    const initialState={
        practiceName: props.initialValues.id?props.initialValues.practice:'' ,
        mcoValue:props.initialValues.id?props.initialValues.mco:'' ,
        brandValue:props.initialValues.id?props.initialValues.brand:'' ,
        practiceAbbr:props.initialValues.id?props.initialValues.practiceabbr:'' ,
        lastDateUsed:0,
        intervention:0,
        newSiteScrapper:false,
        username: props.initialValues.id?props.initialValues.username:'' ,
        password:props.initialValues.id?props.initialValues.password:'' ,
        serviceLocation:props.initialValues.serviceLocation?props.initialValues.serviceLocation:'' ,
        billingEntity:props.initialValues.billingEntity?props.initialValues.billingEntity:'' ,
        provider:props.initialValues.id?props.initialValues.provider:'' ,
        facilityId: props.initialValues.id?props.initialValues.facilityId:''
    }
    const [state,setStates]=useState(initialState);

    const saveForm = (e:{target:any}) => {
        const {initialValues} = props;
        if(!['masterAccount','DentalXChange'].includes(props.selectedMCO)){
            const formObj :any = {
                mco: state.mcoValue,
                brand: state.brandValue,
                username: state.username,
                password: state.password,
                practice: state.practiceName,
                practiceabbr: state.practiceAbbr,
                serviceLocation: state.serviceLocation,
                billingEntity: state.billingEntity,
                provider: state.provider,
                facilityId: state.facilityId,
                lastdateused: state.lastDateUsed,
                intervention: state.intervention,
                newSiteScrapper: state.newSiteScrapper
            }
            if(initialValues.id) {
                formObj.id = initialValues.id
            }
            props.saveForm(formObj);
        } else {
            const formObj :any = {
                mco: state.mcoValue,
                brand: state.brandValue,
                username: state.username,
                password: state.password,
                serviceLocation: state.serviceLocation,
                masterAccount : true
            }
            if(initialValues.id) {
                formObj.id = initialValues.id
            }
            props.saveForm(formObj);

        }



    };


    const onChangeSelect =(val:any,name:string)=>{
        if(name==="practice")
            setStates({...state,practiceAbbr: props.practices[val] , practiceName: val});
        if(name==='mco')
             setStates({...state,mcoValue: val})
        if(name==='brand')
            setStates({...state,brandValue: val})
        if(name==="lastdateused")
            setStates({...state,lastDateUsed:val===true?1:0})
        if(name==="intervention")
            setStates({...state,intervention:val===true?1:0})
        if(name==="newSiteScrapper")
            setStates({...state,newSiteScrapper:val})
    }

    const inputChange = (e:{target:{value:string}},name:string)=>{
            setStates({...state,[name]:e.target.value})
    }


    const {initialValues, cancelForm} = props;
    const {mcoValue , practiceName,practiceAbbr,brandValue,billingEntity,serviceLocation,
        provider,username,password,lastDateUsed,facilityId,newSiteScrapper,intervention} = state;

    return (

        <Modal
            onClose={cancelForm}
            open={true}
            closeIcon={true}
        >
            <Modal.Header>Provider</Modal.Header>
            <Modal.Content>
                <Form onSubmit={(e) => saveForm(e)}>
                    <Form.Select label="MCO" defaultValue={mcoValue} options={mco} placeholder="Select MCO" onChange={(e, { value }) => onChangeSelect(value, "mco")}  />
                    <Form.Select multiple={ ['masterAccount','DentalXChange'].includes(props.selectedMCO)} label="Brand" defaultValue={brandValue} options={['masterAccount','DentalXChange'].includes(props.selectedMCO) ? brandABBR : brand} placeholder="Select Brand" onChange={(e, { value }) => onChangeSelect(value, "brand")}  />
                    <Form.Input label="Username" placeholder="Username" value={username} name="username" onChange={(e) => inputChange(e, 'username')} />
                    <Form.Input label="Password" value={password} placeholder="Password" name="password" onChange={(e) => inputChange(e, 'password')} />
                    <Form.Input label="Service Location" value={serviceLocation} placeholder="Service Location" name="serviceLocation" onChange={(e) => inputChange(e, 'serviceLocation')} />
                    { !['masterAccount','DentalXChange'].includes(props.selectedMCO) ?
                    <>
                    <Form.Select label="Practice" defaultValue={practiceName} placeholder="Select Practice" options={props.practiceOptions} name="practice" onChange={(e, { value }) => onChangeSelect(value, "practice")} />
                    <Form.Input label="Practice Abbr" value={practiceAbbr} placeholder="Practice Abbr" name="practiceabbr" readOnly={true} />
                    <Form.Input label="Billing Entity" value={billingEntity} placeholder="Billing Entity" name="billingEntity" onChange={(e) => inputChange(e, 'billingEntity')} />
                    <Form.Input label="Provider Name" value={provider} placeholder="Provider Name" name="provider" onChange={(e) => inputChange(e, 'provider')} />
                    <Form.Input label="Facility Id" value={facilityId} placeholder="Facility Id" name="facilityId" onChange={(e) => inputChange(e, 'facilityId')} />
                    <Form.Checkbox checked={lastDateUsed === 1} name="lastdateused" label="Last Date Used" onChange={(e, { checked }) => onChangeSelect(checked, "lastdateused")} />
                    <Form.Checkbox checked={intervention === 1} name="intervention" label="Member Intervention" onChange={(e, { checked }) => onChangeSelect(checked, "intervention")} />
                    <Form.Checkbox checked={newSiteScrapper} name="newSiteScrapper" label="New Site Scrapper" onChange={(e, { checked }) => onChangeSelect(checked, "newSiteScrapper")} />
                    </>
                    : ''}
                    <Form.Button type="submit" primary={true}>Save</Form.Button>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary={true} onClick={cancelForm}>Cancel</Button>
            </Modal.Actions>
        </Modal>

    );
}
export default CreateEditModal