import { ActionType } from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const fetchMDMInfo = (data?: any) => async (dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    let queryParam = ''; 
    if (data) {
      queryParam = Object.keys(data || {})
      .filter(key => !(data[key] == undefined || data[key] == null))
      .map(key => `${key}=${data[key]}`).join('&');
    }
    const resp = data ? await api.get(`MDM/get?${queryParam}`) : await api.get(`MDM/get`);
    dispatch({ type: ActionType.LOAD_MDM_INFO_SUCCESS, payload: {mdm_info: resp} });
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};

export const fetchSearchMDMInfo = (predicate: string) => async (dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const response =  await api.get("MDM/search/"+predicate);
    dispatch({ type: ActionType.LOAD_MDM_INFO_SUCCESS, payload: {mdm_info: response} });
    dispatch({ type: ActionType.LOADER, payload: false });
    return response;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};

export const fetchMDMreportHelp = () => async (dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const resp = await api.get(`MDM/getHelpData`);
    dispatch({ type: ActionType.LOAD_MDM_HELP_REPORT_SUCCESS, payload: {mdm_report: resp} });
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};

export const deleteMDMInfo = (id: number) => async(dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const mdmInfo = getState().mdm.mdm_info;
    await api.delete(`MDM/delete`, {id});
    const index = mdmInfo.findIndex((item: any) =>  item.id === id);
    mdmInfo.splice(index, 1);
    dispatch({ type: ActionType.LOAD_MDM_INFO_SUCCESS, payload: {mdm_info: mdmInfo} });
    dispatch({ type: ActionType.LOADER, payload: false });
    return mdmInfo;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};

export const saveMDMInfo = (data: any) => async(dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    let mdmInfo;
    const resp = await api.post(`MDM/save`, data);
    if(Array.isArray(resp)){
      mdmInfo=[...getState().mdm.mdm_info, ...resp];
    }else{
      mdmInfo=[...getState().mdm.mdm_info, resp];
    }
    dispatch({ type: ActionType.LOAD_MDM_INFO_SUCCESS, payload: {mdm_info: mdmInfo} });
    dispatch({ type: ActionType.LOADER, payload: false });
    return mdmInfo;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};

export const updateMDMInfo = (updateData: any) => async(dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  const data: any = {
    id: updateData.id,
    field: updateData.field,
    hexCode: updateData.hexCode,
    description: updateData.description,
    short_desc: updateData.short_desc,
    fileLink: updateData.fileLink,
    formula: updateData.formula,
    code: updateData.code,
    dataSource: updateData.dataSource,
    report: updateData.report,
    page: updateData.page
  };
  const mdmState = getState().mdm;
  const mdmInfo = getMDMData(mdmState);
  try {
    await api.put(`MDM/update`, data);
    const index = mdmInfo.findIndex((item: any) =>  item.id === data.id);
    mdmInfo.splice(index, 1, data);
    dispatch({ type: ActionType.LOADER, payload: false });
    return mdmInfo;
  } catch (err) {
    dispatch({ type: ActionType.LOAD_MDM_INFO_SUCCESS, payload: {mdm_info: mdmInfo} });
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};

function getMDMData(mdmState: any) {
  if (mdmState) {
    if (mdmState.mdm_info)
      if (mdmState.mdm_info.rows)
        return mdmState.mdm_info.rows;
    return mdmState.mdm_info;
  }
  return []
}

export const fetchMDMDataSource = (filterDataSource:Function) => async(dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const resp = await api.get(`MDM/getDataSource`);
    const result = filterDataSource(resp);
 
    dispatch({
      type: ActionType.LOAD_MDM_DATASOURCE,
      payload: {dataSource: result.dataSource, dataSourceOptions: result.dataSourceOptions}
    });
    dispatch({ type: ActionType.LOADER, payload: false });
    return result;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};

export const fetchMDMReports = (filterReport:Function) => async(dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const resp = await api.get(`MDM/getReports`);
    const result = filterReport(resp);
    dispatch({ type: ActionType.LOAD_MDM_REPORTS, payload: {reports: result.reports, reportOptions: result.reportOptions } });
    dispatch({ type: ActionType.LOADER, payload: false });
    return result;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};


export const fetchMDMPages = (filterPage:Function) => async(dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const resp = await api.get(`MDM/getPages`);
    const result = filterPage(resp);
    dispatch({ type: ActionType.LOAD_MDM_PAGES, payload: {pages: result.pages, pageOptions: result.pageOptions} });
    dispatch({ type: ActionType.LOADER, payload: false });
    return result;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};

export const deleteDataSource = (id: number) => async(dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    let dataSource = getState().mdm.dataSource;
    let dataSourceOptions: any = getState().mdm.dataSourceOptions;
    await api.delete(`MDM/deleteDataSourcePageReport`, {id,type:'DataSource'});
    const index = dataSource.findIndex((item: any) =>  item.id === id);
    dataSource.splice(index, 1);
    dataSource=[...dataSource];
    const indx = dataSourceOptions.findIndex((item: any) =>  parseInt(item.value) === id);
    dataSourceOptions.splice(indx, 1);
      dispatch({
        type: ActionType.LOAD_MDM_DATASOURCE,
        payload: {dataSource: dataSource, dataSourceOptions: dataSourceOptions}
      });
      dispatch({ type: ActionType.LOADER, payload: false });
      return dataSource;
    } catch (err) {
      dispatch({ type: ActionType.LOADER, payload: false });
      return dispatch(errorHandler(err));
    }
  };

export const saveDataSource = (data: {name: string, id?: string|number}) => async(dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    if(data.id===''){
      delete data.id;
    }
    let dataSource = getState().mdm.dataSource;
    let dataSourceOptions: any = getState().mdm.dataSourceOptions;
    const resp = await api.post(`MDM/saveDataSource`, data);
    if(!data.id){
      dataSource=[...dataSource,resp];
      dataSourceOptions.push({ text: resp.name, value: resp.id, key: resp.id });
    }else{
      let dId:any=data.id||'';
      dataSource=dataSource.map((ds:any)=>{
        if(parseInt(ds.id)===parseInt(dId)){
          return {...ds,name:data.name}
        }
        return ds;
      })
      dataSourceOptions=dataSourceOptions.map((dso:any)=>{
        if(parseInt(dso.value)===parseInt(dId)){
          return {...dso,text:data.name}
        }
        return dso;
      })
    }
    dispatch({
      type: ActionType.LOAD_MDM_DATASOURCE,
      payload: {dataSource: dataSource, dataSourceOptions: dataSourceOptions}
    });
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};
export const deleteReport = (id: number) => async(dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    let reports = getState().mdm.reports;
    const reportOptions: any = getState().mdm.reportOptions;
    await api.delete(`MDM/deleteDataSourcePageReport`, {id,type:'Report'});
    const index = reports.findIndex((item: any) =>  item.id === id);
    reports.splice(index, 1);
    reports =[...reports];
    const indx = reportOptions.findIndex((item: any) =>  parseInt(item.value) === id);
    reportOptions.splice(indx, 1);
    dispatch({
      type: ActionType.LOAD_MDM_REPORTS,
      payload: {reports: reports, reportOptions: reportOptions}
    });
    dispatch({ type: ActionType.LOADER, payload: false });
    return reports;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};

export const saveReports = (data: {name: string,id?: string|number}) => async(dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    let reports = getState().mdm.reports;
    let reportOptions: any = getState().mdm.reportOptions;
    const resp = await api.post(`MDM/saveReport`, data);
    if(!data.id){
      reports=[...reports,resp];
      reportOptions.push({ text: resp.name, value: resp.id, key: resp.id });
    }else{
      let dId:any=data.id||'';
      reports=reports.map((r:any)=>{
        if(parseInt(r.id)===parseInt(dId)){
          return {...r,name:data.name}
        }
        return r;
      })
      reportOptions=reportOptions.map((ro:any)=>{
        if(parseInt(ro.value)===parseInt(dId)){
          return {...ro,text:data.name}
        }
        return ro;
      })
    }
    dispatch({
      type: ActionType.LOAD_MDM_REPORTS,
      payload: {reports: reports, reportOptions: reportOptions}
    });
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};
export const deletePage = (id: number) => async(dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    let pages = getState().mdm.pages;
    const pageOptions: any = getState().mdm.pageOptions;
    await api.delete(`MDM/deleteDataSourcePageReport`, {id,type:'Page'});
    const index = pages.findIndex((item: any) =>  item.id === id);
    pages.splice(index, 1);
    pages=[...pages];
    const indx = pageOptions.findIndex((item: any) =>  parseInt(item.value) === id);
    pageOptions.splice(indx, 1);
    dispatch({
      type: ActionType.LOAD_MDM_PAGES,
      payload: {pages: pages, pageOptions: pageOptions}
    });
    dispatch({ type: ActionType.LOADER, payload: false });
    return pages;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};
export const savePages = (data: {name: string, id?: string|number, reportId:string|number}) => async(dispatch: Function, getState: Function, api: any) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    let pages = getState().mdm.pages;
    let pageOptions: any = getState().mdm.pageOptions;
    const resp = await api.post(`MDM/savePage`, data);
    if(!data.id){
      pages=[...pages,resp];
      pageOptions.push({ text: resp.name, value: resp.id, key: resp.id });
    }else{
      let dId:any=data.id||'';
      pages=pages.map((p:any)=>{
        if(parseInt(p.id)===parseInt(dId)){
          return {...p,name:data.name,reportId:data.reportId}
        }
        return p;
      })
      pageOptions=pageOptions.map((ro:any)=>{
        if(parseInt(ro.value)===parseInt(dId)){
          return {...ro,text:data.name}
        }
        return ro;
      })
    }
    dispatch({
      type: ActionType.LOAD_MDM_PAGES,
      payload: {pages: pages, pageOptions: pageOptions}
    });
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (err) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(err));
  }
};

/**
 * Fetch PCR Splash Board Info
 * @param title 
 * @returns title mdm splash info
 */
export const getPcrSplashboardMdmInfo = (title: string) => async(dispatch: Function, _getState: Function, api: any) =>{
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const resp = await api.get(`MDM/getPSBoardInfo`, null, {title});
    dispatch({ type: ActionType.FETCH_PS_BOARD_INFO, payload: {mdmPsBoardInfo: resp} });
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (error) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(error));
  }
};
