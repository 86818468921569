import React, {useState} from 'react';
import {Button, Form, Modal,Grid} from "semantic-ui-react";

interface SageAccountListProps {
    initialValues : { id: any; accountNumber: any; accountDescription: any; ukg:string },
    cancelForm : any,
    saveForm : any,
    isActive?:boolean,
    hideShow?:string
}

const SageAccountListForm = (props: SageAccountListProps) => {
    const [state, setStates] = useState({id:props.initialValues.id,accountNumber:props.initialValues.accountNumber,
        accountDescription:props.initialValues.accountDescription,ukg:props.initialValues.ukg});

    const onChange = (e: any, element: any) => {
        const obj:any={...state}
        obj[element.name] =element.value;
        setStates(obj);
    };

    const saveForm = () => {
        props.saveForm(state);
    };
    const { initialValues, cancelForm } = props;
    return(

        <Modal
            onClose={() => cancelForm}
            open={true}
        >
            <Modal.Header>{initialValues.id&&initialValues.id>0?(props.isActive?props.hideShow:"Edit "):"Create "} Sage UKG Accounts</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form className={'widthFluid'}>
                        <Form.Group widths='equal'>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Form.Input
                                            fluid={true}
                                            label='SAGE ACCOUNT NUMBER'
                                            placeholder='SAGE ACCOUNT NUMBER'
                                            defaultValue={state.accountNumber}
                                            name={'accountNumber'}
                                            onChange={onChange}
                                            disabled={!!props.isActive}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Form.Input
                                            fluid={true}
                                            label='ACCOUNT NUMBER DESCRIPTION'
                                            placeholder='ACCOUNT NUMBER DESCRIPTION'
                                            defaultValue={state.accountDescription}
                                            name={'accountDescription'}
                                            onChange={onChange}
                                            disabled={!!props.isActive}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <Form.Input
                                            fluid={true}
                                            label='UKG'
                                            placeholder='UKG'
                                            defaultValue={state.ukg}
                                            name={'ukg'}
                                            onChange={onChange}
                                            disabled={!!props.isActive}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form.Group>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button className={'ml15'} primary={true} onClick={saveForm}>{props.isActive?props.hideShow:"Save"}</Button> &nbsp;
                <Button secondary={true} onClick={cancelForm}>Cancel</Button>
            </Modal.Actions>
        </Modal>

    );
}

export default SageAccountListForm;
