import * as React from 'react';
import {Grid,Loader,Dimmer, Button, Modal, Label, Table} from "semantic-ui-react";
import {Report} from '../model/report';
import Moment from 'moment';
import { numberFormat } from './numberFormat';
import PMDUnitItem from './PMDUnitItem';
import { Practice } from '../model/practice';
import PMDUnitItemClickable from './PMDUnitItemClickable';
import ReactDOMServer from 'react-dom/server';
import pmdpmdViewTodaysGoIcon from '../styles/images/pmdViewTodaysGo.svg';
import moment from 'moment';
import _ from 'lodash';
import { MTDCalculations, renderTableData, renderTableHeader, todaysGoalDialog } from './MTDCalculations';
import { DateFormat } from '../constants';
import {v4 as uuidv4} from 'uuid';

export interface PMDProductionProps {
  practicename: string,
  report: Report,
  bdppp: bigint,
  actppp: bigint,
  deltappp: any,
  ppp: boolean,
  FV_PT: any,
  FV_CPV: any,
  RC_PT: any,
  RC_CPV: any,
  TX_PT: any,
  TX_CPV: any,
  scheduleToday30DayApts: any,
  scheduleToday30DayCompletd: any,
  schAptToday: any,
  procFeeSum: any,
  practice: Practice,
  open : boolean,
  general_shifts: [],
  hygienists_shifts: [],
  pmdDailyCareSummary:[],
  Days_Complete: any,
  Days_Left: any,
  General_Shifts_Left: any,
  General_Shifts_Complete: any,
  Hygiene_Shifts_Left: any,
  Hygiene_Shifts_Complete: any,
  Care: any,
  currentDayGoal: any,
  sendTodayGoalMail (item: any,practicename:any) : void,
  pmdProductionTodaysProdDetail:any [],
  open2 : boolean,
  sendTodayProdDetailMail (item: any,practicename:any,sum:any) : void,
  pmdLWDate:any,
  procFeeSumLWD:any,
  pmdProductionLWDProdDetail:any [],
  open3 : boolean,
  sendLWDProdDetailMail (item: any,practicename:any,sum:any) : void,
  sendLWDptCountDetailMail (item: any,practicename:any,sum:any) : void,
  open4 : boolean,
  pmdlwdptCountDetail:any [],
  lwdptCount: any,
  todaysgoal: any,
  pmdCollections: any,
  openCollectionsLevel2: boolean,
  openCollectionsLevel3: boolean,
  pmdWriteOffs: any,
  openWriteOffsLevel2: boolean,
  pmdAdjustments: any,
  openAdjustmentsLevel2: boolean
}

export interface PMDProductionState {
  practicename: string,
  bdppp: bigint,
  actppp: bigint,
  deltappp: any,
  ppp: boolean,
  scheduleToday30DayApts: any,
  scheduleToday30DayCompletd: any,
  schAptToday: any,
  procFeeSum: any,
  practice: Practice,
  open: boolean,
  MTDCalculations: TodaysGoalMTDCalaculations[],
  MTDGoal: any,
  TodaysGoal: any,
  TodayDate: any,
  pmdProductionTodaysProdDetail:any [],
  columnX:any,
  directionX:any,
  open2: boolean,
  pmdLWDate:any,
  procFeeSumLWD:any,
  pmdProductionLWDProdDetail:any [],
  columnY:any,
  directionY:any,
  open3: boolean,
  open4: boolean,
  pmdlwdptCountDetail:any [],
  lwdptCount: any,
  columnZ:any,
  directionZ:any,
  initialise: boolean,
  pmdCollections:any,
  pmdCollectionsCategory: string,
  openCollectionsLevel2: boolean,
  openCollectionsLevel3: boolean,
  pmdWriteOffs: any,
  openWriteOffsLevel2: boolean
  pmdAdjustments: any,
  openAdjustmentsLevel2: boolean
}

export interface TodaysGoalMTDCalaculations {
  Care: any,
  MTDGoal: any,
  MTDCare: any,
  MTDGoal_To_Act_Care: any,
  bgt_Catch_Up: any,
  goal: any,
  goalByDay: any,
  date: any,
  shifts: any
}

export interface TodaysGoalDialog {
	  MTDCalculationsObj: TodaysGoalMTDCalaculations[],
    todaysgoal: any,
    TodayDate: any,
    MTDGoal: any,
    workdaysLeft: any,
    daysComplete: any,
    general_shifts_completed: any,
    hygienists_shifts_completed: any,
    general_shifts_left: any,
    hygienists_shifts_left: any,
    FvPt: any,
    FvCPV: any,
    RcPt: any,
    RcCPV: any,
    TxPt: any,
    TxCPV: any,
    practice: any,
}

class PMDProduction extends React.Component<PMDProductionProps,PMDProductionState> {
  budgetData = this.props.report.budgetData;
  bCareData = this.props.report.reportData;

  constructor(props: PMDProductionProps) {
    super(props);
    this.state={
      practicename : props.practicename,
      bdppp: props.bdppp,
      actppp: props.actppp,
      deltappp:props.deltappp,
      ppp:props.ppp,
      scheduleToday30DayApts: props.scheduleToday30DayApts,
      scheduleToday30DayCompletd: props.scheduleToday30DayCompletd,
      schAptToday: props.schAptToday,
      procFeeSum: props.procFeeSum,
      practice: props.practice,
      open : props.open,
      MTDCalculations : this.MTDCalculations(),
      MTDGoal: 0,
      TodaysGoal: props.todaysgoal,
      TodayDate: '',
      pmdProductionTodaysProdDetail: props.pmdProductionTodaysProdDetail,
      columnX: null,
      directionX: null,
      open2 : props.open2,
      pmdLWDate : props.pmdLWDate,
      procFeeSumLWD: props.procFeeSumLWD,
      pmdProductionLWDProdDetail: props.pmdProductionLWDProdDetail,
      columnY: null,
      directionY: null,
      open3 : props.open3,
      open4 : props.open4,
      pmdlwdptCountDetail:props.pmdlwdptCountDetail,
      lwdptCount: props.lwdptCount,
      columnZ: null,
      directionZ: null,
      initialise: true,
      pmdCollections: props.pmdCollections,
      pmdCollectionsCategory: '',
      openCollectionsLevel2: false,
      openCollectionsLevel3: false,
      pmdWriteOffs: props.pmdWriteOffs,
      openWriteOffsLevel2: false,
      pmdAdjustments: props.pmdAdjustments,
      openAdjustmentsLevel2: false
    }
    this.setPpp=this.setPpp.bind(this);
    this.setBdppp=this.setBdppp.bind(this);
    this.setActppp=this.setActppp.bind(this);
    this.setDeltappp=this.setDeltappp.bind(this);
    this.setPracticeName=this.setPracticeName.bind(this);
    Moment.locale('en');
    this.budgetData = this.props.report.budgetData;
    this.bCareData = this.props.report.reportData;
    this.setOpen=this.setOpen.bind(this);
    this.setMTDCalculations=this.setMTDCalculations.bind(this);
    this.setMTDGoal=this.setMTDGoal.bind(this);
    this.setTodaysGoal=this.setTodaysGoal.bind(this);
    this.setTodayDate=this.setTodayDate.bind(this);
    this.setColumnX=this.setColumnX.bind(this);
    this.setDirectionX=this.setDirectionX.bind(this);
    this.setOpen2=this.setOpen2.bind(this);
    this.setPmdLWDate=this.setPmdLWDate.bind(this);
    this.setColumnY=this.setColumnY.bind(this);
    this.setDirectionY=this.setDirectionY.bind(this);
    this.setColumnZ=this.setColumnZ.bind(this);
    this.setDirectionZ=this.setDirectionZ.bind(this);
    this.setInitialise=this.setInitialise.bind(this);
  }


  setPmdCollectionsCategory(value: string) {
    this.setState({
      pmdCollectionsCategory: value
    })
  }
  setOpenCollectionsLevel2(value: boolean) {
    this.setState({
      openCollectionsLevel2: value
    })
  }

  setOpenCollectionsLevel3(value: boolean) {
    this.setState({
      openCollectionsLevel3: value
    })
  }

  setOpenWriteOffsLevel2(value: boolean) {
    this.setState({openWriteOffsLevel2: value})
  }

  setOpenAdjustmentsLevel2(value: boolean) {
    this.setState({openAdjustmentsLevel2: value})
  }

  setPpp(value: boolean) {
    this.setState({
      ppp : value
    })
  }

  setDeltappp(value: any) {
    this.setState({
      deltappp : value
    })
  }

  setBdppp(value: bigint) {
    this.setState({
      bdppp : value
    })
  }

  setActppp(value: bigint) {
    this.setState({
      actppp : value
    })
  }

  setPracticeName(value: string) {
    this.setState({
      practicename : value
    })
  }

  setColumnX(value: string) {
    this.setState({
      columnX : value
    })
  }

  setDirectionX(value: string) {
    this.setState({
      directionX : value
    })
  }

  setColumnY(value: string) {
    this.setState({
      columnY : value
    })
  }

  setDirectionY(value: string) {
    this.setState({
      directionY : value
    })
  }

  setColumnZ(value: string) {
    this.setState({
      columnZ : value
    })
  }

  setDirectionZ(value: string) {
    this.setState({
      directionZ : value
    })
  }

  setPmdLWDate(value: string) {
    this.setState({
      pmdLWDate : value
    })
  }

  setInitialise(value: boolean) {
    this.setState({
      initialise : value
    })
  }

  componentWillReceiveProps(nextProps: PMDProductionProps) {
    this.setState({
      practicename: nextProps.practicename,
      bdppp: nextProps.bdppp,
      actppp: nextProps.actppp,
      open: nextProps.open,
      pmdProductionTodaysProdDetail: nextProps.pmdProductionTodaysProdDetail,
      pmdLWDate: nextProps.pmdLWDate,
      pmdProductionLWDProdDetail: nextProps.pmdProductionLWDProdDetail,
      pmdlwdptCountDetail:nextProps.pmdlwdptCountDetail,
      lwdptCount: nextProps.lwdptCount
    })
  }

  practiceDBName() {
    const practiceData: any = this.props.practice.practiceInfo;
            if (practiceData[this.props.practicename.toLowerCase()]){
              return practiceData[this.props.practicename.toLowerCase()].practiceDBName.toLowerCase();
            }else{
              return this.props.practicename.toLowerCase()
            }     
  }

  getBudget() {
    const FV_CPV:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].FvCPV :0.00:0.00:0.00;
    const FV_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].FvPt :0.00:0.00:0.00;
    const RC_CPV:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].RcCPV :0.00:0.00:0.00;
    const RC_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].RcPt :0.00:0.00:0.00;
    const TX_CPV:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].TxCPV :0.00:0.00:0.00;
    const TX_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].TxPt :0.00:0.00:0.00;
    return (((FV_CPV)*(FV_PT))+((RC_CPV)*(RC_PT))+((TX_CPV)*(TX_PT)));
  };

  getTotalPt() {
    const FV_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].FvPt :0.00:0.00:0.00;
    const RC_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].RcPt :0.00:0.00:0.00;
    const TX_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].TxPt :0.00:0.00:0.00;
    return FV_PT+RC_PT+TX_PT;
  };


  calculateBDPPP() {
    const BDPPP:any = this.getBudget()/this. getTotalPt();
    return BDPPP? BDPPP.toFixed(2) : 0;
  }

  calculateACTPPP() {
    try{
    if(this.props.report.reportData){
      const bCare_perpractice:any  = this.props.report ? this.props.report.reportData? this.props.report.reportData.byPractice? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['bCare'] ? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['bCare'] : 0.00:0.00:0.00:0.00;
      const bCare_totalVisit:any  = this.props.report ? this.props.report.reportData? this.props.report.reportData.byPractice? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['totalVisit'] ? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['totalVisit']  :0.00:0.00:0.00:0.00;
      var n_bCare_perpractice = (isNaN(bCare_perpractice) ? 0 : bCare_perpractice);
      var n_bCare_totalVisit= (isNaN(bCare_totalVisit) ? 0 : bCare_totalVisit);
      const actppp:any = Math.round(parseInt(n_bCare_perpractice)/parseInt(n_bCare_totalVisit)).toFixed(2);
      var n_actppp = (isNaN(actppp) ? 0 : actppp);
      return n_actppp;
    }else{
      return 0.00;
    }
  } catch (ex) {
    return 0.00;
    }
  }

  calculateDeltaPPP() {
    try{
      var deltappp:any = this.calculateBDPPP() - this.calculateACTPPP() ;
      var n_deltappp:any = (isNaN(deltappp) ? 0 : deltappp.toFixed(2));
      var deltappp_display:string = n_deltappp>0 ? numberFormat(n_deltappp) : '('+numberFormat(n_deltappp*-1)+')';
      return deltappp_display;
  } catch (ex) {
    var deltappp_display:string = '($0.00)';
    return deltappp_display;
    }
  }

  checkValues(){
    return (
      !(
          typeof this.props.report === 'object' && this.props.report !== null &&
          typeof this.props.report.reportData === 'object' && this.props.report.reportData !== null &&
          typeof this.props.report.budgetData === 'object' && this.props.report.budgetData !== null &&
          typeof this.props.pmdWriteOffs === 'object' && this.props.pmdWriteOffs !== null &&
          typeof this.props.pmdAdjustments === 'object' && this.props.pmdAdjustments !== null &&
          typeof this.props.pmdCollections === 'object' && this.props.pmdCollections !== null
      ) &&
      <Dimmer active={true}>
        <Loader>Calculating</Loader>
      </Dimmer>
    )
  }

  getExpectedShow():any {
    let show_rate = this.props.scheduleToday30DayCompletd/this.props.scheduleToday30DayApts;
    return (Math.round(show_rate*this.props.schAptToday));
  };

  getScheduledProduction():any {
    return (Math.round(this.getExpectedShow()*this.calculateACTPPP()));
  };

  setOpen(value: boolean) {
    this.setState({
      open : value
    });
    if(value){
      this.setMTDCalculations(this.MTDCalculations());
    }
  }

  initialiseTodayGoal() {    
    if(this.state.initialise){
      this.MTDCalculations();
      this.setInitialise(false);
    }    
  }

  setOpen2(value: boolean) {
    this.setState({
      open2 : value
    });
  }

  setOpen3(value: boolean) {
    this.setState({
      open3 : value
    });
  }

  setOpen4(value: boolean) {
    this.setState({
      open4 : value
    });
  }

  setMTDCalculations(value: TodaysGoalMTDCalaculations[]) {
    this.setState({
      MTDCalculations : value
    })
  }

  setMTDGoal(value: any) {
    this.setState({
      MTDGoal : value
    })
  }

  setTodaysGoal(value: any) {
    this.setState({
      TodaysGoal : value
    })
  }

  setTodayDate(value: any) {
    this.setState({
      TodayDate : value
    })
  }

  getBudgetTodayGoal() {
    return (((this.props.FV_CPV)*(this.props.FV_PT))+((this.props.RC_CPV)*(this.props.RC_PT))+((this.props.TX_CPV)*(this.props.TX_PT)));
  };

  getTodayGoal() {
    this.initialiseTodayGoal();
    return this.props.todaysgoal;
  };

  MTDCalculations  = () => {
    let returnObj = MTDCalculations(this.getBudgetTodayGoal(),this.props.general_shifts,this.props.hygienists_shifts,this.props.pmdDailyCareSummary)
    this.setMTDGoal(parseFloat(returnObj.MTDGoal)||0);
    this.setTodayDate(returnObj.TodayDate);
    this.setTodaysGoal(parseFloat(returnObj.goalByDay_value)||0);
    return returnObj.MTDCalculationsObj.slice(1);
   } 

  renderTableHeader() {
    return renderTableHeader(this.state.MTDCalculations[0]);
  }

  renderTableData() {
    return renderTableData(this.state.MTDCalculations);
 }

 sendPDFEmail() {
  let divStyle = {
    fontSize: '10px !important;',
    zoom: '75%',
    maxWidth: '2000px'
  };
  let todaysGoalDialogString:string = ReactDOMServer.renderToString((<div style={divStyle}>{this.todaysGoalDialog()}</div>)).replace(/<td/g, `<td style='max-width: 2000px;
  zoom: 65%;
  width: auto;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 10px;
  white-space:nowrap;'`);
  this.props.sendTodayGoalMail(todaysGoalDialogString,this.props.practicename.toLocaleUpperCase());
  this.setOpen(false); 
};

 formatLWDDate(LWDDate:any) {
  return LWDDate!=0?this.isValidDate(LWDDate)?moment.utc(LWDDate).utcOffset('-5:00').format('ddd MMM Do, YYYY'):"":"";
 }; 
 
 isValidDate(str:any) {
  var d = moment(str,'YYYY-MM-DD');
  if(d == null || !d.isValid()) return false;

  return str.indexOf(d.format('YYYY-MM-DD')) >= 0 
      || str.indexOf(d.format('YYYY-M-D')) >= 0
      || str.indexOf(d.format('YY-M-D')) >= 0 
      || str.indexOf(d.format('YY-MM-DD')) >= 0;
}

handleSortTodayProdDetail(clickedColumn: any){
  let { columnX, pmdProductionTodaysProdDetail, directionX } = this.state;
   if (columnX !== clickedColumn) {
          columnX =  clickedColumn;
          pmdProductionTodaysProdDetail =  _.sortBy(pmdProductionTodaysProdDetail, o => o[clickedColumn]);
          directionX = 'ascending';
          this.setState({...this.state, pmdProductionTodaysProdDetail, columnX, directionX});
       return;
   }
   pmdProductionTodaysProdDetail = pmdProductionTodaysProdDetail.reverse();
  directionX = directionX === 'ascending' ? 'descending' : 'ascending';
  this.setState({...this.state, pmdProductionTodaysProdDetail, directionX});
};

handleSortLWDProdDetail(clickedColumn: any){
  let { columnY, pmdProductionLWDProdDetail, directionY } = this.state;
   if (columnY !== clickedColumn) {
          columnY =  clickedColumn;
          pmdProductionLWDProdDetail =  _.sortBy(pmdProductionLWDProdDetail, o => o[clickedColumn]);
          directionY = 'ascending';
          this.setState({...this.state, pmdProductionLWDProdDetail, columnY, directionY});
       return;
   }
   pmdProductionLWDProdDetail = pmdProductionLWDProdDetail.reverse();
  directionY = directionY === 'ascending' ? 'descending' : 'ascending';
  this.setState({...this.state, pmdProductionLWDProdDetail, directionY});
};

handleSortLWDptCountDetail(clickedColumn: any){
  let { columnZ, pmdProductionLWDProdDetail, directionZ } = this.state;
   if (columnZ !== clickedColumn) {
    columnZ =  clickedColumn;
          pmdProductionLWDProdDetail =  _.sortBy(pmdProductionLWDProdDetail, o => o[clickedColumn]);
          directionZ = 'ascending';
          this.setState({...this.state, pmdProductionLWDProdDetail, columnZ, directionZ});
       return;
   }
   pmdProductionLWDProdDetail = pmdProductionLWDProdDetail.reverse();
   directionZ = directionZ === 'ascending' ? 'descending' : 'ascending';
  this.setState({...this.state, pmdProductionLWDProdDetail, directionZ});
};

sendTodayProdDetailMailAction() {
  this.props.sendTodayProdDetailMail(this.state.pmdProductionTodaysProdDetail,this.state.practicename,this.sumOfProcFee(this.state.pmdProductionTodaysProdDetail,"ProcFee"));
  this.setOpen2(false); 
};

sendLWDProdDetailMailAction() {
  this.props.sendLWDProdDetailMail(this.state.pmdProductionLWDProdDetail,this.state.practicename,this.sumOfProcFee(this.state.pmdProductionLWDProdDetail,"ProcFee"));
  this.setOpen3(false); 
};

sendLWDptCountDetailMailAction() {
  this.props.sendLWDptCountDetailMail(this.state.pmdlwdptCountDetail,this.state.practicename,this.sumOfPtSeenToday(this.state.pmdlwdptCountDetail,"PatNum"));
  this.setOpen4(false); 
};

sumOfProcFee(items:any[], prop:string){
  return items.reduce( function(a, b){
      return a + b[prop];
  }, 0);
};

sumOfPtSeenToday(items:any[], prop:string){
  return items.reduce( function(a, b){
      return a + b[prop];
  }, 0);
};

getLWDate() {
  try{
  if(this.props.report.reportData){
    const lwd:any  = this.props.report ? this.props.report.reportData? this.props.report.reportData.byPractice? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['lastDayDate'] ? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['lastDayDate'] : 0.00:0.00:0.00:0.00;
    return lwd;
  }else{
    return "";
  }
} catch (ex) {
  return "";
  }
}
        

todaysGoalDialog() {
  return todaysGoalDialog(
    this.state.MTDCalculations,
    this.getTodayGoal(),
    this.state.TodayDate,
    this.state.MTDGoal,
    this.props.Days_Left,
    this.props.Days_Complete,
    this.props.General_Shifts_Complete,
    this.props.Hygiene_Shifts_Complete,
    this.props.General_Shifts_Left,
    this.props.Hygiene_Shifts_Left,
    this.props.FV_PT,
    this.props.FV_CPV,
    this.props.RC_PT,
    this.props.RC_CPV,
    this.props.TX_PT,
    this.props.TX_CPV,
    this.props.practicename
    );
}
  render() {
    return (
      <><Grid columns='three'>
        <Grid.Row>
          <Grid.Column>
            <PMDUnitItem value={this.formatLWDDate(this.props.pmdLWDate)} description="LWD Date" />
          </Grid.Column>
          <Grid.Column></Grid.Column>
          <Grid.Column className='pdmitem'>
            <span onClick={() => this.setOpenCollectionsLevel2(true)}>
          <PMDUnitItemClickable value={numberFormat(this.props.pmdCollections?this.props.pmdCollections.levelOne?this.props.pmdCollections.levelOne:0:0)} description="Collections (Procdate)" /></span></Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className='pdmitem'>
            <span onClick={() => this.setOpen(true)}>
              <PMDUnitItemClickable value={numberFormat(this.props.todaysgoal)} description="Today's Goal" /> </span>
          </Grid.Column>          
          <Grid.Column className='pdmitem'>
          <span onClick={() => this.setOpen4(true)}>
          <PMDUnitItemClickable value={this.state.pmdlwdptCountDetail.length} description="LWD PT Count" /></span>            
          </Grid.Column>
          <Grid.Column className='pdmitem'>
          <span onClick={() => this.setOpenWriteOffsLevel2(true)}>
            <PMDUnitItemClickable value={numberFormat(this.props.pmdWriteOffs?this.props.pmdWriteOffs.levelOne?this.props.pmdWriteOffs.levelOne.writeoff?this.props.pmdWriteOffs.levelOne.writeoff:0:0:0)} description="Write Offs" /></span>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column className='pdmitem'>
          <span onClick={() => this.setOpen2(true)}>
            <PMDUnitItemClickable value={numberFormat(this.props.procFeeSum)} description="Today Prod" /></span>
          </Grid.Column>
          <Grid.Column className='pdmitem'>
          <span onClick={() => this.setOpen3(true)}>
            <PMDUnitItemClickable value={numberFormat(this.props.procFeeSumLWD)} description="LWD Prod" /></span>
          </Grid.Column>
          <Grid.Column className='pdmitem'>
          <span onClick={() => this.setOpenAdjustmentsLevel2(true)}>
            <PMDUnitItemClickable value={numberFormat(this.props.pmdAdjustments?this.props.pmdAdjustments.levelOne?this.props.pmdAdjustments.levelOne.writeoff?this.props.pmdAdjustments.levelOne.writeoff:0:0:0)} description="Adjustments" /></span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.open}
        onClose={() => this.setOpen(false)}
        onOpen={() => this.setOpen(true)}
      >
          <Modal.Header><b>Todays Goal</b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
            <div className="ui top right attached label production_right_label">
              <Button onClick={() => this.setOpen(false)} inverted>
                Close
              </Button>
            </div>
          </Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              {this.todaysGoalDialog()}
            </Modal.Description>
          </Modal.Content>

          <Modal.Actions>
            <Button onClick={() => this.sendPDFEmail()} inverted color='orange'>
              Send Mail
            </Button>
          </Modal.Actions>
        </Modal>
        
        <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.open2}
        onClose={() => this.setOpen2(false)}
        onOpen={() => this.setOpen2(true)}
      >
        <Modal.Header><b>Today Production</b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
        <Label color={this.state.pmdProductionTodaysProdDetail.length>0?'green':'red'} floating>
        {this.state.pmdProductionTodaysProdDetail.length}
      </Label>
      <div className="ui top right attached label production_right_label">
        <Button onClick={() => this.setOpen2(false)} inverted>
          Close
        </Button>
      </div>
      </Modal.Header>
        <Modal.Content scrolling>

      <Table className="table table-striped table-hover my-0 FreezeTable sortable">   
        <Table.Header>
        <Table.Row>
        <Table.HeaderCell><div className="tbl-header">Practice</div></Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnX === 'PatNum' ? this.state.directionX : null}
              onClick={() => this.handleSortTodayProdDetail('PatNum')}
              ><div className="tbl-header">PatNum</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnX === 'ProcDate' ? this.state.directionX : null}
              onClick={() => this.handleSortTodayProdDetail('ProcDate')}
              ><div className="tbl-header">ProcDate</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnX === 'ProcCode' ? this.state.directionX : null}
              onClick={() => this.handleSortTodayProdDetail('ProcCode')}
              ><div className="tbl-header">ProcCode</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnX === 'ProcFee' ? this.state.directionX : null}
              onClick={() => this.handleSortTodayProdDetail('ProcFee')}
              ><div className="tbl-header">ProcFee</div>
            </Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
            {Object.values(this.state.pmdProductionTodaysProdDetail).map(
            ({ PatNum, ProcDate, ProcCode,ProcFee}) => {
              return (
              <Table.Row>
                  <Table.Cell>{this.props.practicename}</Table.Cell>
                  <Table.Cell>{PatNum}</Table.Cell>
                  <Table.Cell>{ProcDate}</Table.Cell>
                  <Table.Cell>{ProcCode}</Table.Cell>
                  <Table.Cell>{numberFormat(ProcFee)}</Table.Cell>
              </Table.Row>
              );
          }
          )}
          <Table.Row>
                  <Table.Cell className="pmdbold">TOTAL</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell className="pmdbold">{numberFormat(this.sumOfProcFee(this.state.pmdProductionTodaysProdDetail,"ProcFee"))}</Table.Cell>
              </Table.Row>
        </Table.Body>
      </Table> 
      </Modal.Content>

        <Modal.Actions>
        <Button onClick={() => this.sendTodayProdDetailMailAction()} inverted color='orange'>
          Send Mail
        </Button>
        </Modal.Actions>
      </Modal>
      
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.open3}
        onClose={() => this.setOpen3(false)}
        onOpen={() => this.setOpen3(true)}
      >
        <Modal.Header><b>LWD Care</b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
        <Label color={this.state.pmdProductionLWDProdDetail.length>0?'green':'red'} floating>
        {this.state.pmdProductionLWDProdDetail.length}
      </Label>
      <div className="ui top right attached label production_right_label">
        <Button onClick={() => this.setOpen3(false)} inverted>
          Close
        </Button>
      </div>
      </Modal.Header>
        <Modal.Content scrolling>

      <Table className="table table-striped table-hover my-0 FreezeTable sortable">   
        <Table.Header>
        <Table.Row>
        <Table.HeaderCell><div className="tbl-header">Practice</div></Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnY === 'PatNum' ? this.state.directionY : null}
              onClick={() => this.handleSortLWDProdDetail('PatNum')}
              ><div className="tbl-header">PatNum</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnY === 'ProcDate' ? this.state.directionY : null}
              onClick={() => this.handleSortLWDProdDetail('ProcDate')}
              ><div className="tbl-header">ProcDate</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnY === 'ProcCode' ? this.state.directionY : null}
              onClick={() => this.handleSortLWDProdDetail('ProcCode')}
              ><div className="tbl-header">ProcCode</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnY === 'ProcFee' ? this.state.directionY : null}
              onClick={() => this.handleSortLWDProdDetail('ProcFee')}
              ><div className="tbl-header">ProcFee</div>
            </Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
            {Object.values(this.state.pmdProductionLWDProdDetail).map(
            ({ PatNum, ProcDate, ProcCode,ProcFee}) => {
              return (
              <Table.Row key={uuidv4()}>
                  <Table.Cell>{this.props.practicename}</Table.Cell>
                  <Table.Cell>{PatNum}</Table.Cell>
                  <Table.Cell>{ProcDate}</Table.Cell>
                  <Table.Cell>{ProcCode}</Table.Cell>
                  <Table.Cell>{numberFormat(ProcFee)}</Table.Cell>
              </Table.Row>
              );
          }
          )}
          <Table.Row>
                  <Table.Cell className="pmdbold">TOTAL</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell className="pmdbold">{numberFormat(this.sumOfProcFee(this.state.pmdProductionLWDProdDetail,"ProcFee"))}</Table.Cell>
              </Table.Row>
        </Table.Body>
      </Table> 
      </Modal.Content>

        <Modal.Actions>
        <Button onClick={() => this.sendLWDProdDetailMailAction()} inverted color='orange'>
          Send Mail
        </Button>
        </Modal.Actions>
      </Modal>
      
      <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.open4}
        onClose={() => this.setOpen4(false)}
        onOpen={() => this.setOpen4(true)}
      >
        <Modal.Header><b>LWD PT COUNT</b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
        <Label color={this.state.pmdlwdptCountDetail.length>0?'green':'red'} floating>
        {this.state.pmdlwdptCountDetail.length}
      </Label>
      <div className="ui top right attached label production_right_label">
        <Button onClick={() => this.setOpen4(false)} inverted>
          Close
        </Button>
      </div>
      </Modal.Header>
        <Modal.Content scrolling>

      <Table className="table table-striped table-hover my-0 FreezeTable sortable">   
        <Table.Header>
        <Table.Row>
        <Table.HeaderCell><div className="tbl-header">Practice</div></Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnZ === 'PatNum' ? this.state.directionZ : null}
              onClick={() => this.handleSortLWDptCountDetail('PatNum')}
              ><div className="tbl-header">PatNum</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnZ === 'ProcDate' ? this.state.directionZ : null}
              onClick={() => this.handleSortLWDptCountDetail('ProcDate')}
              ><div className="tbl-header">ProcDate</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnZ === 'ProcCode' ? this.state.directionZ : null}
              onClick={() => this.handleSortLWDptCountDetail('ProcCode')}
              ><div className="tbl-header">ProcCode</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnZ === 'ProcFee' ? this.state.directionZ : null}
              onClick={() => this.handleSortLWDptCountDetail('ProcFee')}
              ><div className="tbl-header">ProcFee</div>
            </Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
            {Object.values(this.state.pmdlwdptCountDetail).map(
            ({ PatNum, ProcDate, ProcCode,ProcFee}) => {
              return (
              <Table.Row>
                  <Table.Cell>{this.props.practicename}</Table.Cell>
                  <Table.Cell>{PatNum}</Table.Cell>
                  <Table.Cell>{ProcDate}</Table.Cell>
                  <Table.Cell>{ProcCode}</Table.Cell>
                  <Table.Cell>{numberFormat(ProcFee)}</Table.Cell>
              </Table.Row>
              );
          }
          )}
          <Table.Row>
                  <Table.Cell className="pmdbold">TOTAL</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell className="pmdbold">{numberFormat(this.sumOfProcFee(this.state.pmdlwdptCountDetail,"ProcFee"))}</Table.Cell>
              </Table.Row>
        </Table.Body>
      </Table> 
      </Modal.Content>

        <Modal.Actions>
        <Button onClick={() => this.sendLWDptCountDetailMailAction()} inverted color='orange'>
          Send Mail
        </Button>
        </Modal.Actions>
      </Modal>

        <Modal
            closeOnEscape={false}
            closeOnDimmerClick={false}
            open={this.state.openCollectionsLevel2}
            onClose={() => this.setOpenCollectionsLevel2(false)}
            onOpen={() => this.setOpenCollectionsLevel2(true)}
        >
          <Modal.Header>
            <b>Collections Categories</b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
            <Label color={this.props.pmdCollections && this.props.pmdCollections.levelTwo.length > 0 ? 'green':'red'} floating>
              {this.props.pmdCollections ? this.props.pmdCollections.levelTwo.length : 0}
            </Label>
            <div className="ui top right attached label production_right_label">
              <Button onClick={() => this.setOpenCollectionsLevel2(false)} inverted>
                Close
              </Button>
            </div>
          </Modal.Header>
          <Modal.Content scrolling>
            <Table className="table table-striped table-hover my-0 TableHeaderFixed">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell><div className="tbl-header">Category</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">Amount (Procdate)</div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
                <Table.Body>
                  {
                    this.props.pmdCollections ? this.props.pmdCollections.levelTwo.map((item: any, key: number) => {
                      return(
                          <Table.Row key={key}>
                            <Table.Cell>{item.Category}</Table.Cell>
                            <Table.Cell>
                              <span style={{cursor: "pointer", textDecoration: "underline", color: 'orange'}} onClick={() => {
                                this.setOpenCollectionsLevel2(false)
                                this.setOpenCollectionsLevel3(true)
                                this.setPmdCollectionsCategory(item.Category)
                              }}>
                                {numberFormat(item.Amount)}
                              </span>
                            </Table.Cell>
                          </Table.Row>
                      )
                    }) : ''
                  }
                </Table.Body>
            </Table>
          </Modal.Content>
        </Modal>

        <Modal
            closeOnEscape={false}
            closeOnDimmerClick={false}
            open={this.state.openCollectionsLevel3}
            onClose={() => {
              this.setOpenCollectionsLevel3(false)
              this.setOpenCollectionsLevel2(true)
            }}
            onOpen={() => {
                this.setOpenCollectionsLevel2(false)
                this.setOpenCollectionsLevel3(true)
              }
            }
        >
          <Modal.Header>
            <b>Collections {this.state.pmdCollectionsCategory} Category </b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
            <Label
                color={this.props.pmdCollections && this.props.pmdCollections.levelThree.filter((cat: any) => cat.Category === this.state.pmdCollectionsCategory).length>0?'green':'red'}
                floating
            >
              {this.props.pmdCollections && this.props.pmdCollections.levelThree.filter((cat: any) => cat.Category === this.state.pmdCollectionsCategory).length}
            </Label>
            <div className="ui top right attached label production_right_label">
              <Button onClick={() => {
                this.setOpenCollectionsLevel3(false)
                this.setOpenCollectionsLevel2(true)
              }} inverted>
                Close
              </Button>
            </div>
          </Modal.Header>
          <Modal.Content scrolling>
            <Table className="table table-striped table-hover my-0 TableHeaderFixed">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell><div className="tbl-header">Dr. Name</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">OD_user</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">PatNum</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">Amount</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">Entry/Deposit_Date</div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.props.pmdCollections ? this.props.pmdCollections.levelThree.filter((cat: any) => cat.Category === this.state.pmdCollectionsCategory).map((item: any, key: number) => {
                    return(
                        <Table.Row key={key}>
                          <Table.Cell>{item['Dr. Name']}</Table.Cell>
                          <Table.Cell>{item['OD_user']}</Table.Cell>
                          <Table.Cell>{item['PatNum']}</Table.Cell>
                          <Table.Cell>{numberFormat(item['Amount'])}</Table.Cell>
                          <Table.Cell>{item['Entry/Deposit_Date']}</Table.Cell>
                        </Table.Row>
                    )
                  }) : ''
                }
              </Table.Body>
            </Table>
          </Modal.Content>
        </Modal>


        <Modal
            closeOnEscape={false}
            closeOnDimmerClick={false}
            open={this.state.openWriteOffsLevel2}
            onClose={() => { this.setOpenWriteOffsLevel2(false) }}
            onOpen={() => { this.setOpenWriteOffsLevel2(true) }}
            size={'large'}
        >
          <Modal.Header>
            <b>WriteOffs </b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
            <Label
                color={this.props.pmdWriteOffs && this.props.pmdWriteOffs.levelTwo.length>0?'green':'red'}
                floating
            >
              {this.props.pmdWriteOffs && this.props.pmdWriteOffs.levelTwo.length}
            </Label>
            <div className="ui top right attached label production_right_label">
              <Button onClick={() => {
                this.setOpenWriteOffsLevel2(false)
              }} inverted>
                Close
              </Button>
            </div>
          </Modal.Header>
          <Modal.Content scrolling>
            <Table className="table table-striped table-hover my-0 TableHeaderFixed">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell><div className="tbl-header">Dr. Name</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">PatNum</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">Amount</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">ProcDate</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">Carrier Name</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">Remark_1</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">Remark_3</div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.props.pmdWriteOffs ? this.props.pmdWriteOffs.levelTwo.map((item: any, key: number) => {
                    return(
                        <Table.Row key={key}>
                          <Table.Cell>{item['Dr. Name']}</Table.Cell>
                          <Table.Cell>{item['PatNum']}</Table.Cell>
                          <Table.Cell>{numberFormat(item['Amount'])}</Table.Cell>
                          <Table.Cell>{item['ProcDate']}</Table.Cell>
                          <Table.Cell>{item['CarrierName']}</Table.Cell>
                          <Table.Cell>{item['Remark_1']}</Table.Cell>
                          <Table.Cell>{item['Remark_3']}</Table.Cell>
                        </Table.Row>
                    )
                  }) : ''
                }
              </Table.Body>
            </Table>
          </Modal.Content>
        </Modal>

        <Modal
            closeOnEscape={false}
            closeOnDimmerClick={false}
            open={this.state.openAdjustmentsLevel2}
            onClose={() => { this.setOpenAdjustmentsLevel2(false) }}
            onOpen={() => { this.setOpenAdjustmentsLevel2(true) }}
            size={'large'}
        >
          <Modal.Header>
            <b>Adjustments </b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
            <Label
                color={this.props.pmdAdjustments && this.props.pmdAdjustments.levelTwo.length>0?'green':'red'}
                floating
            >
              {this.props.pmdAdjustments && this.props.pmdAdjustments.levelTwo.length}
            </Label>
            <div className="ui top right attached label production_right_label">
              <Button onClick={() => {
                this.setOpenAdjustmentsLevel2(false)
              }} inverted>
                Close
              </Button>
            </div>
          </Modal.Header>
          <Modal.Content scrolling>
            <Table className="table table-striped table-hover my-0 TableHeaderFixed">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell><div className="tbl-header">Dr. Name</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">PatNum</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">Amount</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">ProcDate</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">Carrier Name</div></Table.HeaderCell>
                  <Table.HeaderCell><div className="tbl-header">Remark_1</div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {
                  this.props.pmdAdjustments ? this.props.pmdAdjustments.levelTwo.map((item: any, key: number) => {
                    return(
                        <Table.Row key={key}>
                          <Table.Cell>{item['Dr. Name']}</Table.Cell>
                          <Table.Cell>{item['PatNum']}</Table.Cell>
                          <Table.Cell>{numberFormat(item['Amount'])}</Table.Cell>
                          <Table.Cell>{item['ProcDate']}</Table.Cell>
                          <Table.Cell>{item['CarrierName']}</Table.Cell>
                          <Table.Cell>{item['Remark_1']}</Table.Cell>
                        </Table.Row>
                    )
                  }) : ''
                }
              </Table.Body>
            </Table>
          </Modal.Content>
        </Modal>
      </>
    )
  }
}

export default PMDProduction;