import React, {useEffect, useState} from "react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as ReportActions from "../redux/actions/report"
import {useActions} from "../redux/actions";
import {Button, Confirm, Dropdown, Grid, Icon, Table} from "semantic-ui-react";
import _ from "lodash";
import {cronStatus, CronStatusTxt} from "../constants";
import CronForm from "./CronForm";
import CronDetails from "./CronDetails";
import HelpMark from "./HelpMark";

interface CronsListProps {
    cronList: any,
    user: any
}

const initialState: any = {
    loading: false,
    showReRunConfirm: false,
    reRunCronData: false,
    cronData: [],
    cronStatus: 'All',
    isEdit: false,
    initialValues: {title: '', description: '', id: 0},
    orderBy: null,
    order: true
}

const CronsList = (props: CronsListProps) => {
    const reportActions = useActions(ReportActions);
    const [state, setStates] = useState(initialState);
    const [cronDetails, setDetails] = useState(false);

    useEffect(() => {
        reportActions.getCronList()
            .then((res: any) => {
                setStates({...state, cronData: res});
            });
    }, []);

    const getSortRows = (sortColumn: string, orderLocal: string) => {
        const orderUpdated = !orderLocal;
        let data: any;

        if (sortColumn === 'cronStatus') {
            data = _.orderBy(cronData, item => item.logs && item.logs.length && item.logs[0].status, [orderLocal ? 'asc' : 'desc'])
            return setStates({...state, cronData: data, orderBy: sortColumn, order: orderUpdated});
        }
        if (sortColumn === 'cronTime') {
            data = _.orderBy(cronData, item => item.cronTime.toLowerCase(), [orderLocal ? 'asc' : 'desc'])
            return setStates({...state, cronData: data, orderBy: sortColumn, order: orderUpdated});
        }
        data = _.orderBy(cronData, [sortColumn], [orderLocal ? 'asc' : 'desc'])
        return setStates({...state, cronData: data, orderBy: sortColumn, order: orderUpdated});
    };

    const onCloseDetails = () => {
        setDetails(false)
    }

    const changeOnCronStatus = (_e: any, element: any) => {
        const {cronList} = props;
        const keyword = element.value;
        let data = cronList;
        if (keyword !== 'All') {
            data = data.filter((filterArg: { logs: any; }) => (filterArg.logs && filterArg.logs[0] && filterArg.logs[0].status === keyword))
        }
        setStates({...state, cronStatus: keyword, cronData: data})
    };

    const reRunConfirm = (cronInfo: any) => {
        setStates({...state, showReRunConfirm: true, reRunCronData: cronInfo})
    }

    const closeReRunConfirm = () => {
        setStates({...state, showReRunConfirm: false})
    }

    const confirmReRun = () => {
        const {reRunCronData} = state;
        reportActions.reRunCron(reRunCronData.id)
        closeReRunConfirm();
    }

    const editCronData = (cronInfo: any) => {
        const initialValues = {
            title: cronInfo.title,
            description: cronInfo.description,
            affectedApplication: cronInfo.affectedApplication,
            repository: cronInfo.repository,
            id: cronInfo.id
        };
        setStates({...state, isEdit: true, initialValues});
    };

    const cancelForm = () => {
        setStates({...state, isEdit: false, initialValues: {title: '', description: '', id: 0}});
    };

    const updateCronData = (data: any) => {
        reportActions.updateCronData(data).then(() => {
            reportActions.getCronList()
                .then((res: any) => {
                    setStates({
                        ...state,
                        cronData: res,
                        isEdit: false,
                        initialValues: {title: '', description: '', affectedApplication: '', repository: '', id: 0}
                    });
                });
        })
    };

    const {cronData, orderBy, order, isEdit, showReRunConfirm} = state;
    const direction = order ? 'sort down' : 'sort up';

    return (
        <Template activeLink="crons-list">
            {cronDetails &&
                <CronDetails
                    onClose={onCloseDetails}
                    details={cronDetails}
                />}
            {showReRunConfirm &&
                <Confirm
                    open={true}
                    onCancel={closeReRunConfirm}
                    content='Are you sure you want to re-run this cron?'
                    onConfirm={confirmReRun}
                />
            }
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left">List of Crons <HelpMark pageId='65'/></h2>
                    <div className="topFilters">
                        <Dropdown
                            search={true}
                            name="cronStatus"
                            placeholder="Filter By Status"
                            selection={true}
                            onChange={changeOnCronStatus}
                            value={state.cronStatus}
                            options={cronStatus}
                        />
                    </div>
                </div>
            </div>

            {isEdit &&
                <CronForm
                    cancelForm={() => cancelForm()}
                    saveForm={(data: any) => updateCronData(data)}
                    initialValues={state.initialValues}
                />
            }
            <div className="ui card">
                <div className="content">
                    <div className="FreezeTable">
                        <Table className="ui table table-striped celled table-hover unstackable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        <div>Actions</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Title</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Description</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Affected Application</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Repository</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'cronTime' ? 'active' : ''}`}
                                        onClick={() => getSortRows('cronTime', order)}
                                    >
                                        <div>Cron Time
                                            <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/></div>
                                    </Table.HeaderCell>

                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'cronStatus' ? 'active' : ''}`}
                                        onClick={() => getSortRows('cronStatus', order)}
                                    >
                                        <div>Last Status
                                            <Icon className={orderBy === 'cronStatus' ? direction : 'sort'}/></div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {cronData.length ?
                                    cronData.map((item: any, index: any) => {
                                        const cronStatus: string = item.logs && item.logs[0] ? CronStatusTxt[item.logs[0].status] : '';
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell>
                                                    <Icon className="cursorPointer" name="edit"
                                                          onClick={() => editCronData(item)}/>
                                                    {props.user && (props.user.role && props.user.role.indexOf('reRunCron') >= 0) && item && item.functionName && !item.reRun ?
                                                        <Icon className="cursorPointer ml10" name="redo"
                                                              onClick={() => reRunConfirm(item)}/>
                                                        : ""}
                                                    <Icon className="cursorPointer ml10" name="eye"
                                                          onClick={() => setDetails(item)}/>
                                                </Table.Cell>
                                                <Table.Cell>{item.title}</Table.Cell>
                                                <Table.Cell>{item.description}</Table.Cell>
                                                <Table.Cell>{item.affectedApplication}</Table.Cell>
                                                <Table.Cell>
                                                    <a href={item.repository} target="_blank">{item.repository}</a>
                                                </Table.Cell>
                                                <Table.Cell>{item.cronTime}</Table.Cell>
                                                <Table.Cell>{cronStatus}</Table.Cell>
                                            </Table.Row>
                                        )
                                    }) : <Table.Row key={1}>
                                        <Table.Cell colspan={12} className='text-center'>No record found</Table.Cell>
                                    </Table.Row>
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>

        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        cronList: state.report.cronList,
    };
}

export default connect(mapStateToProps)(CronsList);
