import React, {useEffect, useState} from "react";
import {Modal, Button, Icon} from 'semantic-ui-react';
import {useActions} from "../redux/actions";
import * as reportActions from "../redux/actions/report";
import moment from "moment";
import DynamicHtmlTable from "./DynamicHtmlTable";
import _ from "lodash";
import * as patientValidationActions from "../redux/actions/patients";
import {enabledCarrierList} from "../constants";
const filterformat = 'YYYY-MM-DD';


function MDHwriteoffPopUp(props: any) {
	const initialState: any = {
		TableData: [[{title: 'No Records Found'}]],
		TableHead: [{title: ''}],
		column: -1,
		direction: 'sort'
	};
	const [state, setStates] = useState(initialState);
	const [pdfState, pdfSetState] = useState({pdfModal : false, pdfLinks : '', pdfLinksNotFound: false});
	const reportAction = useActions(reportActions);
	const patientValidationAction = useActions(patientValidationActions);
	useEffect(() => {
		const { data } = props;
		const filters = {
			    locations: data.locations,
				region: '',
				startDate: moment(data.monthYear).startOf('month').format(filterformat),
				endDate: moment(data.monthYear).endOf('month').format(filterformat)
		}
		reportAction.mdhmWriteOffReportDetails(filters).then((res: any) => {
				const TableData = pushTableData(res);
				const TableHead = TableHeadData();
				setStates({...state, TableData, TableHead});
		});
	}, []);

	const getPDFfiles = (carrierName: any, subscriberID: any, appDate: any, patNum: number, location: string) => {
		const carrierIndex = enabledCarrierList.findIndex(element => element.toLowerCase().includes(carrierName.toLowerCase()));
		let obj:any = {
			carrierName : enabledCarrierList[carrierIndex] ,
			subscriberID : subscriberID.trim(),
			date : appDate,
			patNum : patNum,
			location: location
		}
		const pdfModal  = true;
		patientValidationAction.getPdfFile(obj).then((res:any) => {
			if(res && res.url){
				const pdfLinks = res.url;
				pdfSetState({...pdfState, pdfModal:pdfModal, pdfLinks:pdfLinks });
			} else {
				pdfSetState({...pdfState, pdfModal:pdfModal, pdfLinksNotFound:true });
			}
		})
	}

	const handlePdfModal = () => {
		pdfSetState({...pdfState, pdfModal: false, pdfLinks:'', pdfLinksNotFound: false });
	}

	const pushTableData = (practiceData: any) => {
		const { locations } = props.data;
		const TableData = new Array();
		let total = 0;
		locations.forEach((location: string) => {
			const items = practiceData && practiceData[location];

			items && items.forEach((item: any) => {
				const appDate = moment(item.Apt_Date,'YYYY-MM-DD').format(filterformat);
				total  = total + item.WriteOff;
				const getPDF =  item.status !== 'Can\'t Find Member. Check Subscriber ID or Birthdate' ?
					<a onClick={() => getPDFfiles(item.mco , item.SubscriberID, appDate, item.PatNum, location)}>
						View Files</a> : 'No Image';
				let status = <Icon name='question circle' color='yellow'/>
				if (item.status === 'PT Assigned To Practice Active Insurance') {
					status = <Icon name='check circle' color='green'/>
				} else if (item.status === 'PT Does NOT Have Active Insurance') {
					status = <Icon name='delete' color='red'/>
				} else if (item.status === 'PT Has Active Insurance But Not Assigned To Practice') {
					status = <Icon name='font' color='red'/>
				}
				TableData.push([
					{title: location},
					{title: getPDF},
					{title: status},
					{title: item.PatNum},
					{title: item.Writeoff_Code},
					{title: item.WriteOff},
					{title: item.Remark_3},
					{title: item.ProcDate},
					{title: item.CarrierName},
					{title: item.Writeoff_Note}
				])
			});
		})

		TableData.push([
			{title: 'Total', className:'boldCont'},
			{title: ''},
			{title: ''},
			{title: '$'+total.toFixed(2), className:'boldCont'},
			{title: ''},
			{title: ''}
		])
		return TableData;
	}

	const TableHeadData = () => {
		const TableHead = new Array();
		TableHead[0] = {title: 'Practice'};
		TableHead[1] = {title: 'Confirmation Image'};
		TableHead[2] = {title: 'Status'}
		TableHead[3] = {title: 'Patient#'};
		TableHead[4] = {title: 'WO Code'};
		TableHead[5] = {title: 'WO Amount'};
		TableHead[6] = {title: 'Procedure Code'};
		TableHead[7] = {title: 'Date of Service'};
		TableHead[8] = {title: 'Insurance'};
		TableHead[9] = {title: 'WO Code Description'};
		return TableHead;
	};

	const handleClose = () => {
		props.handleClose();
	}

	const handleSort = (clickedColumn: number) => () => {
		let { column, TableData, direction, TableHead } = state;
		if (column !== clickedColumn) {
			column =  clickedColumn;
			TableData =  _.sortBy(TableData, o => o[clickedColumn].title);

			direction = 'sort ascending';
			setStates({...state, TableData: TableData.slice(), column, direction});
			return;
		}
		TableData = TableData.reverse();
		direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
		setStates({...state, TableData: TableData.slice(), direction});
	};

	const sendWriteOffDetailsEmail = () => {
		const { data } = props;
		const filters = {
			locations: data.locations,
			region: '',
			startDate: moment(data.monthYear).startOf('month').format(filterformat),
			endDate: moment(data.monthYear).endOf('month').format(filterformat),
			sendReport : true
		}
		reportAction.mdhmWriteOffReportDetails(filters);
	};
	return (
		<Modal
			size={'large'}
			open={true}
			centered
			closeIcon
			onClose={handleClose}
		>
			<Modal.Header>Write Off Report ({props.data.monthYear})</Modal.Header>
			<Modal.Content>
				<div className="table-adjAcknowledgement">
					<DynamicHtmlTable
						key={0}
						className={'adjustment'}
						tableHead={state.TableHead}
						heading={""}
						tableData={state.TableData}
						onHeadClick={handleSort}
						sortedColumn={state.column}
						direction={state.direction}
					/>
				</div>
				<Modal
					open={pdfState.pdfModal}
					onClose={handlePdfModal}
					aria-labelledby="ModalHeader"
					centered={true}
					closeIcon={true}
					size={'large'}
				>
					<Modal.Content scrolling={true}>
						{pdfState.pdfLinksNotFound ?
							<h4>No files found for specific member on this day. Please click on RUN button to generate files and then view it after execution.  </h4>
							:
							<iframe title="PDF files" src={pdfState.pdfLinks} height={'500px'} width={'100%'} />
						}
					</Modal.Content>
				</Modal>
			</Modal.Content>
			<Modal.Actions>
				<Button primary={true} onClick={sendWriteOffDetailsEmail}>
					Send Report
				</Button>
			</Modal.Actions>
		</Modal>
	);
}

export default MDHwriteoffPopUp;
