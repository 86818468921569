import React, {useEffect, useState} from "react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import {useActions} from "../redux/actions";
import {Button, Dropdown, Grid, Icon} from "semantic-ui-react";
import {brands, budgetFilters, budgetTypes, isSafari, monthsArray, omitPractices} from "../constants";
import * as BudgetActions from "../redux/actions/budget";
import * as practiceActions from "../redux/actions/practice";
import moment from "moment";
import ImportModal from "./ImportModal";
import {validObj} from "../utils/common";
import HelpMark from "./HelpMark";

interface PcrBudgetProps {
    budgetList: any
    practices: any,
    loader: boolean
    accessToken: string
}

const initialState: any = {
    editValue: null,
    openModel: false,
    collapseTable: 1,
    editedCells: [],
    averagedMonths: {1: {data: {}}, 2: {data: {}}, 3: {data: {}}, 4: {data: {}}, 5: {data: {}}, 6: {data: {}}},
    search: false,
    currentMonthYear: null
}

const initialFilters: any = {
    practice: null,
    type: null,
    years: null,
    months: null,
    endYears: null,
    endMonths: null,
    brand: null
};

const PcrBudget = (props: PcrBudgetProps) => {

    const budgetActions = useActions(BudgetActions);
    const practiceAction = useActions(practiceActions);
    const [state, setStates] = useState(initialState);
    const [filters, setFilters] = useState(initialFilters);


    useEffect(() => {
        getData(filters);
    }, [filters, state.search]);


    const getData = (filters: any) => {
        budgetActions.getPcrBudget(filters)
            .then((res: any) => {
                const d = new Date();
                const crrMonth = ("0" + (d.getMonth() + 1)).slice(-2);
                const crrYear = d.getFullYear();
                const crrmonthYear = crrYear + '-' + crrMonth;
                setStates({...state, currentMonthYear: crrmonthYear, budgetData: res});
            });
        if (!props.practices.length) {
            practiceAction.fetchPracticesOptions();
        }
    }

    const editValue = (val: any) => {

        const editedCells = state.editedCells;
        editedCells.push(val);
        setStates({...state, editValue: val, editedCells});

    }

    const updateValue = (e: any) => {
        const splitName = e.target.name.split(":");
        const postObj = {
            value: e.target.value,
            type: splitName[0],
            practice: splitName[1],
            monthYear: splitName[2]
        }
        budgetActions.updateValuePCR(postObj)
            .then(() => {
                setStates({...state, editValue: null});
                getData(filters);
            });

    }

    const getCsvHeading = (tableColumns: any) => {
        let returnArray = [];
        tableColumns.forEach((column: any) => {
            const monthYear = column.split("-");
            returnArray.push(monthsArray[monthYear[1] - 1] + "-" + monthYear[0]);
        });
        if (tableColumns.indexOf(state.currentMonthYear) === tableColumns.length - 1) {
            const mnthYr = state.currentMonthYear.split("-");
            mnthYr[1] == 12 ? returnArray.push(<th key={mnthYr[0] + mnthYr[1]}
                                                   className="width80">{monthsArray[0]}-{parseInt(mnthYr[0]) + 1}</th>) :
                returnArray.push(<th key={mnthYr[0] + mnthYr[1]}
                                     className="width80">{monthsArray[mnthYr[1]]}-{mnthYr[0]}</th>);
        }
        return returnArray;
    }

    const exportCsv = () => {
        let {budgetList, practices} = props;
        const {type} = filters;
        let csv: any = []
        let thColumns = [];
        let headers: any = [];
        const replacer = (_key: any, value: any) => value === null ? '' : value.toString().replace(/"/g, "'"); // specify how you want to handle null values here
        if (!type) {
            alert('Please select Type filter before export data in csv file.');
            return false;
        }

        let filename: any = budgetFilters.type[type].text;
        filename = filename.replace(/ /g, "-") + ".csv";

        if (budgetList && budgetList[type] && Object.keys(budgetList[type]).length) {
            thColumns = (budgetList[type].columns && budgetList[type].columns.length) ? getCsvHeading(budgetList[type].columns) : [];
            if (thColumns.length && budgetList[type].data) {
                Object.keys(budgetList[type].data).forEach((practice) => {
                    let tdColumnLength = budgetList[type].columns && budgetList[type].columns.length;
                    let tdColumns = new Array(tdColumnLength)
                    tdColumns.fill('');
                    tdColumns[0] = [practice];
                    Object.keys(budgetList[type].data[practice]).forEach((monthYear) => {
                        const yrmthIndex = budgetList[type].columns && budgetList[type].columns.indexOf(monthYear) + 1;
                        tdColumns[yrmthIndex] = JSON.stringify(budgetList[type].data[practice][monthYear], replacer);
                    });
                    csv.push(tdColumns.join(","));
                });
                practices.forEach((item: any) => {
                    csv.push(item.practice);
                });
            }
        } else {
            thColumns.push(JSON.stringify(moment().format('MMM-YYYY')));
            practices.forEach((item: { practice: string; }) => {
                csv.push(JSON.stringify(item.practice, replacer));
            });
        }

        headers = thColumns;
        headers.unshift("Practice");
        csv.unshift(headers.join(','));


        // let csv = items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        // csv.unshift(headers.join(','));

        csv = csv.join('\r\n');
        const dataType = isSafari() ? 'application/csv' : 'text/csv';
        // @ts-ignore
        const blob = new Blob(['', csv], {dataType});
        const URL = window.URL || window.webkitURL;
        const url = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();

    }

    const onFiltersChange = (e: any, element: any) => {
        const keyword = element.name;
        const value = element.value;
        filters[keyword] = value;
        setFilters({...filters});
    }

    const searchFilter = () => {
        setStates({...state, search: true})
    }

    const getColumns = (tableColumns: any) => {
        const {endYears, endMonths} = filters;
        let returnArray = [];
        const mnthYr = state.currentMonthYear && state.currentMonthYear.split("-");
        tableColumns.forEach((column: any, _index: any) => {
            const monthYear = column.split("-");
            returnArray.push(<th key={column} className="width80">{monthsArray[monthYear[1] - 1]}-{monthYear[0]}</th>);

        });
        if (filters.endMonths || filters.endYears) {
            returnArray.unshift(<th key={'average'} className="width80">AVERAGE</th>);
        }
        if (state.currentMonthYear) {
            if ((endYears == null && endMonths == null && tableColumns.indexOf(state.currentMonthYear) !== -1) ||
                (mnthYr[0] < endYears && tableColumns.indexOf(state.currentMonthYear) !== -1) ||
                (mnthYr[0] <= endYears && mnthYr[1] <= endMonths && tableColumns.indexOf(state.currentMonthYear) !== -1)) {


                const indexOfCurrMnth = tableColumns.indexOf(state.currentMonthYear);
            } else if ((endYears == null && endMonths == null && tableColumns.indexOf(state.currentMonthYear) == -1) ||
                (mnthYr[0] < endYears && tableColumns.indexOf(state.currentMonthYear) == -1) ||
                (mnthYr[0] <= endYears && mnthYr[1] <= endMonths && tableColumns.indexOf(state.currentMonthYear) == -1)) {
                returnArray.push(<th key={mnthYr[0] + mnthYr[1]}
                                     className="width80">{monthsArray[mnthYr[1] - 1]}-{mnthYr[0]}</th>);
                mnthYr[1] == 12 ? returnArray.push(<th key={mnthYr[0] + mnthYr[1] + 1}
                                                       className="width80">{monthsArray[0]}-{parseInt(mnthYr[0]) + 1}</th>) :
                    returnArray.push(<th key={mnthYr[0] + mnthYr[1] + 2} className="width80">
                        {monthsArray[mnthYr[1].startsWith('0') ? mnthYr[1].split('0')[1] : mnthYr[1]]}-{mnthYr[0]}</th>);
            }
        }
        return returnArray;
    }

    const collapseTableFunc = (type: any) => {
        setStates({...state, collapseTable: (state.collapseTable === type) ? 'none' : type});
    }

    const getTableData = (tableResult: any) => {
        const {collapseTable, currentMonthYear, endMonths, endYears} = state;

        let tableData: any = [];
        let nxtMonth: any = '';
        if (currentMonthYear !== null) {
            const mnthYr = currentMonthYear.split("-");
            mnthYr[1] == 12 ? nxtMonth = parseInt(mnthYr[0]) + 1 + '-01' : nxtMonth = mnthYr[0] + '-' + (parseInt(mnthYr[1]) + 1);
        }
        validObj(tableResult) && Object.keys(tableResult).forEach(type => {
            let thColumns = (tableResult[type].columns && tableResult[type].columns.length) ? getColumns(tableResult[type].columns) : [];
            let tBody: any = [];

            if (thColumns && tableResult[type].data) {
                // thColumns.push(<th key="blank"> &nbsp; </th>); // Add blank column to manage width
                Object.keys(tableResult[type].data).forEach((practice) => {

                    if (omitPractices.includes(practice)) return;

                    let tdColumnLength = tableResult[type].columns && tableResult[type].columns.length;
                    let tdColumns = new Array(tdColumnLength);
                    tdColumns.fill(<td className="width80"/>)
                    tdColumns[0] = [<td className="width100" key={practice}>{practice}</td>]
                    Object.keys(tableResult[type].data[practice]).forEach((monthYear, index) => {
                        const yrmthIndex = tableResult[type].columns && tableResult[type].columns.indexOf(monthYear) + 1;
                        const uniqueKey = `${type}:${practice}:${monthYear}`;
                        if (Object.keys(tableResult[type].data[practice]).indexOf(currentMonthYear) == -1 && index == Object.keys(tableResult[type].data[practice]).length - 1) {

                            tdColumns[yrmthIndex] = <td className="width80" key={uniqueKey}>
                                {
                                    (state.editValue && state.editValue === uniqueKey) ?
                                        <input className="width80" name={uniqueKey} onBlur={updateValue}
                                               defaultValue={tableResult[type].data[practice][monthYear]}/>
                                        :
                                        <span className="cursorPointer"
                                              onClick={() => editValue(uniqueKey)}>{tableResult[type].data[practice][monthYear]}</span>
                                }
                            </td>
                            if ((endYears == null && endMonths == null) || (endYears > (currentMonthYear && currentMonthYear.split('-')[0])) || (endMonths > (currentMonthYear && currentMonthYear.split('-')[1]) && endYears == (currentMonthYear && currentMonthYear.split('-')[0]))) {
                                let indxCrr = tableResult[type].columns && tableResult[type].columns.indexOf(currentMonthYear) !== -1 ? tableResult[type].columns.indexOf(currentMonthYear) :
                                    tableResult[type].columns.length// filter per Practice correct here
                                for (let cycle = 1; cycle <= 2; cycle++) {
                                    let totalAve = 0
                                    let nullColumns = 0
                                    const tbleColumns = Object.keys(tableResult[type].data[practice]);
                                    for (let count = 1; count <= 6; count++) {
                                        tableResult[type].data[practice][tbleColumns[(index + cycle) - count]] ? nullColumns += 0 : nullColumns++;
                                        totalAve += parseInt(tableResult[type].data[practice][tbleColumns[(index + cycle) - count]] ?
                                            tableResult[type].data[practice][tbleColumns[(index + cycle) - count]].replace('$', '') : 0);

                                    }

                                    const sixmthsAverage = state.search ? state.averagedMonths[type]["data"][practice][cycle == 1 ? currentMonthYear : nxtMonth] :
                                        (totalAve / (6 - nullColumns)) ? (totalAve / (6 - nullColumns)).toFixed() : 1

                                    if (state.search == false) {
                                        if (!state.averagedMonths[type]["data"][practice])
                                            state.averagedMonths[type]["data"][practice] = {}
                                        state.averagedMonths[type]["data"][practice][cycle == 1 ? currentMonthYear : nxtMonth] = sixmthsAverage;
                                    }
                                    const uniquekeyyc = `${type}:${practice}:${cycle == 1 ? currentMonthYear : nxtMonth}`;

                                    if (cycle !== 1) {
                                        tdColumns.splice(1, 0, <td className="width80" key={uniquekeyyc}>
                                            {
                                                (state.editValue && state.editValue === uniquekeyyc) ?
                                                    <input className="width80" name={uniquekeyyc} onBlur={updateValue}
                                                           defaultValue={tableResult[type].data[practice][nxtMonth]}/>
                                                    :
                                                    <span
                                                        style={{backgroundColor: state.editedCells.indexOf(uniquekeyyc) > -1 ? '' : '#FBF46D'}}
                                                        className="cursorPointer"
                                                        onClick={() => editValue(uniquekeyyc)}
                                                    >
                          {state.editedCells.indexOf(uniquekeyyc) > -1 ? tableResult[type].data[practice][cycle == 1 ? currentMonthYear : nxtMonth] : sixmthsAverage}
                        </span>
                                            }
                                        </td>)
                                    }
                                    else if (cycle === 1 && filters.endMonths === null && filters.endYears === null) {

                                        tdColumns[indxCrr + cycle] = <td className="width80" key={uniquekeyyc}>
                                            {
                                                (state.editValue && state.editValue === uniquekeyyc) ?
                                                    <input className="width80" name={uniquekeyyc} onBlur={updateValue}
                                                           defaultValue={tableResult[type].data[practice][currentMonthYear]}/>
                                                    :
                                                    <span
                                                        style={{backgroundColor: state.editedCells.indexOf(uniquekeyyc) > -1 ? '' : '#FBF46D'}}
                                                        className="cursorPointer"
                                                        onClick={() => editValue(uniquekeyyc)}
                                                    >
                          {state.editedCells.indexOf(uniquekeyyc) > -1 ? tableResult[type].data[practice][cycle == 1 ? currentMonthYear : nxtMonth] : sixmthsAverage}
                        </span>
                                            }
                                        </td>
                                    }
                                }
                            }
                        } else if (Object.keys(tableResult[type].data[practice]).indexOf(currentMonthYear) == index) { //&& index==Object.keys(tableResult[type].data[practice]).length - 1
                            tdColumns[yrmthIndex] = <td className="width80" key={uniqueKey}>
                                {
                                    (state.editValue && state.editValue === uniqueKey) ?
                                        <input className="width80" name={uniqueKey} onBlur={updateValue}
                                               defaultValue={tableResult[type].data[practice][monthYear]}/> :
                                        <span className="cursorPointer"
                                              onClick={() => editValue(uniqueKey)}>{tableResult[type].data[practice][monthYear]}</span>
                                }
                            </td>
                            if ((endYears == null && endMonths == null) || endYears > (currentMonthYear && currentMonthYear.split('-')[0]) || (endMonths > (currentMonthYear && currentMonthYear.split('-')[1]) && endYears >= (currentMonthYear && currentMonthYear.split('-')[0]))) {
                                let indxCrr = tableResult[type].columns && tableResult[type].columns.indexOf(currentMonthYear) !== -1 ? tableResult[type].columns.indexOf(currentMonthYear) :
                                    tableResult[type].columns.length;// filter per Practice correct here

                                let totalAve = 0;
                                let nullColumns = 0;
                                const tbleColumns = Object.keys(tableResult[type].data[practice]);
                                for (let count = 1; count <= 6; count++) {
                                    tableResult[type].data[practice][tbleColumns[(index + 1) - count]] ? nullColumns += 0 : nullColumns++;
                                    totalAve += parseInt(tableResult[type].data[practice][tbleColumns[(index + 1) - count]] ?
                                        tableResult[type].data[practice][tbleColumns[(index + 1) - count]].replace('$', '') : 0);
                                }
                                let avgMonthVal = 1;
                                if (!!state.averagedMonths[type]["data"][practice] && !!state.averagedMonths[type]["data"][practice][nxtMonth]) {
                                    avgMonthVal = state.averagedMonths[type]["data"][practice][nxtMonth];
                                }

                                const sixmthsAverage = state.search ? avgMonthVal : (totalAve / (6 - nullColumns)) ? (totalAve / (6 - nullColumns)).toFixed() : 1

                                if (state.search === false) {
                                    if (!state.averagedMonths[type]["data"][practice])
                                        state.averagedMonths[type]["data"][practice] = {}
                                    state.averagedMonths[type]["data"][practice][nxtMonth] = sixmthsAverage;
                                }
                                const uniquekeyyc = `${type}:${practice}:${nxtMonth}`;
                                tdColumns.splice(1, 0,
                                    <td className="width80" key={uniquekeyyc}>
                                        {
                                            (state.editValue && state.editValue === uniquekeyyc) ?
                                                <input className="width80" name={uniquekeyyc} onBlur={updateValue}
                                                       defaultValue={tableResult[type].data[practice][nxtMonth]}/> :
                                                <span
                                                    style={{backgroundColor: state.editedCells.indexOf(uniquekeyyc) > -1 ? '' : '#FBF46D'}}
                                                    className="cursorPointer"
                                                    onClick={() => editValue(uniquekeyyc)}
                                                >
                        {state.editedCells.indexOf(uniquekeyyc) > -1 ? tableResult[type].data[practice][nxtMonth] : sixmthsAverage}
                      </span>
                                        }
                                    </td>
                                    )
                            }
                        } else {
                            Object.keys(tableResult[type].data[practice])[index - 1] == currentMonthYear ?
                                tdColumns[yrmthIndex + 1] = <td className="width80" key={uniqueKey}>
                                    {
                                        (state.editValue && state.editValue === uniqueKey) ?
                                            <input className="width80" name={uniqueKey} onBlur={updateValue}
                                                   defaultValue={tableResult[type].data[practice][monthYear]}/>
                                            : <span className="cursorPointer"
                                                    onClick={() => editValue(uniqueKey)}>{tableResult[type].data[practice][monthYear]}</span>
                                    }
                                </td> :
                                tdColumns[yrmthIndex] = <td className="width80" key={uniqueKey}>
                                    {
                                        (state.editValue && state.editValue === uniqueKey) ?
                                            <input className="width80" name={uniqueKey} onBlur={updateValue}
                                                   defaultValue={tableResult[type].data[practice][monthYear]}/> :
                                            <span className="cursorPointer"
                                                  onClick={() => editValue(uniqueKey)}>{tableResult[type].data[practice][monthYear]}</span>
                                    }
                                </td>
                        }


                    })
                    if (filters.endMonths === null && filters.endYears === null) {
                    }
                    tBody.push(<tr key={`${type}-${practice}`}>
                        {shiftAndAddLast(tdColumns,1)}
                    </tr>);
                })
            }

            tableData.push(<div key={`${type}`}>
                <table className="ui table">
                    <thead>
                    <tr key="type" className="btn-primary">
                        <th onClick={() => collapseTableFunc(type)} className="text-center cursorPointer" colSpan={50}>
                            <span style={{padding: '6px', display: 'inline-block'}}>{budgetTypes[type]}
                                <Icon
                                    name={(collapseTable && type === collapseTable) ? 'caret up' : 'caret down'}/></span>
                        </th>
                    </tr>
                    </thead>
                </table>
                <div className={(collapseTable && type !== collapseTable) ? "hidden" : "FreezeTable mb15"}>
                    {/* <table key={type} className="table budgetTable TableHeaderFixed"> */}
                    <table key={type} className="ui table table-striped celled table-hover unstackable">
                        <thead>
                        <tr key="headings">
                            <th key="practice" className="width100">
                                <div>PRACTICE</div>
                            </th>
                            {shiftAndAddLast(thColumns,0)}
                        </tr>
                        </thead>
                        <tbody>
                        {tBody}
                        </tbody>
                    </table>
                </div>
            </div>);

        });
        return tableData;
    }

    const shiftAndAddLast=(arr:any,index:number) =>{
        const removedItem = arr.splice(index, 1)[0];
        arr.push(removedItem);
        return arr;
    }
    const hideModal = () => {
        setStates({...state, openModel: false});
    }

    const openModel = () => {
        setStates({...state, openModel: true});
    }

    const {budgetList, practices, accessToken} = props;
    const {practice, type, years, months, endMonths, endYears, brand} = filters;
    let dropDownOptions: any = {
        practices: practices,
        type: budgetFilters.type,
        years: budgetFilters.years,
        months: budgetFilters.months,
        endYears: [...budgetFilters.years],
        endMonths: [...budgetFilters.months],
        brands: brands
    };

    let tableData = (budgetList && Object.keys(budgetList).length) ? getTableData(budgetList) : [];

    return (
        <Template activeLink="budget-list">

            {state.openModel && <ImportModal hideModal={hideModal} searchFilter={searchFilter} parent={'PcrBudget'}
                                             accessToken={accessToken}/>}
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">PCR Goal <HelpMark pageId='58'/></h2>
                    <div className='topFilters'>
                        <Dropdown
                            placeholder="Select Brand"
                            search={true}
                            selection={true}
                            options={dropDownOptions.brands}
                            onChange={onFiltersChange}
                            defaultValue={brand || ''}
                            name="brand"
                        />
                        <Dropdown
                            placeholder="Select Practice"
                            search={true}
                            selection={true}
                            options={dropDownOptions.practices}
                            onChange={onFiltersChange}
                            defaultValue={practice || ''}
                            name="practice"
                        />
                        <Dropdown
                            placeholder="Select Type"
                            search={true}
                            selection={true}
                            options={dropDownOptions.type}
                            onChange={onFiltersChange}
                            defaultValue={type || ''}
                            name="type"
                        />
                        <Dropdown
                            placeholder="Select Month"
                            search={true}
                            selection={true}
                            options={dropDownOptions.months}
                            onChange={onFiltersChange}
                            defaultValue={months || ''}
                            name="months"
                        />
                        <Dropdown
                            placeholder="Select Year"
                            search={true}
                            selection={true}
                            options={dropDownOptions.years}
                            onChange={onFiltersChange}
                            defaultValue={years || ''}
                            name="years"
                        />
                        <Dropdown
                            placeholder="Select End Months"
                            search={true}
                            selection={true}
                            options={dropDownOptions.endMonths}
                            onChange={onFiltersChange}
                            defaultValue={endMonths || ''}
                            name="endMonths"
                        />
                        <Dropdown
                            placeholder="Select End Year"
                            search={true}
                            selection={true}
                            options={dropDownOptions.endYears}
                            onChange={onFiltersChange}
                            defaultValue={endYears || ''}
                            name="endYears"
                        />

                        <Button
                            primary
                            onClick={openModel}
                            className="btn btn-primary"
                        >
                            Import
                        </Button>
                        <Button
                            primary
                            onClick={exportCsv}
                            className="btn btn-primary"
                        >
                            Export
                        </Button>
                    </div>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    {tableData}
                </div>
            </div>
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        budgetList: state.budget.pcr,
        practices: state.practice.practiceOptions,
        loader: state.app.loader,
        accessToken: state.auth.accessToken
    };
}

export default connect(mapStateToProps)(PcrBudget);
