import React, {useEffect, useState} from "react";
import {Button, Checkbox, Confirm, Dropdown, Modal, Table} from "semantic-ui-react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as ReportActions from "../redux/actions/report"
import {useActions} from "../redux/actions";
import HelpMark from "./HelpMark";
import {brands} from "../constants";
import moment from "moment";
import {omitPractices} from '../constants';

interface McoLoginReportProps {
    mcoList: any
}

const initialState: any = {
    confirm: false,
    successModal: false,
    selectedMco: [],
    providers: [],
    filteredProviders: [],
    practices: [],
    mcos: [],
    filter: {}
}

const initialFilters: any = {lastLoginResult: [], practices: [], mcos: [], brands: []}

const McoLoginReport = (props: McoLoginReportProps) => {
    const reportActions = useActions(ReportActions);
    const [filteredCount, setFilteredCount] = useState(0);
    const [state, setStates] = useState(initialState);
    const [filters, setFilters] = useState(initialFilters);
    const [brandFilter, setBrandFilter] = useState<string[]>(['All']);
    const [practiceFilter, setPracticeFilter] = useState<string>("All");
    const [mcoFilter, setMcoFilter] = useState<string>("All");
    const [lastLoginResultFilter, setLastLoginResultFilter] = useState<string>('All');

    useEffect(() => {
        reportActions.getMcoList().then((providers: any[]) => load(providers))
    }, []);

    const load = async (providers: any[]) => {
        if (!Array.isArray(providers)) return;
        //providers = providers.filter(provider => provider.mco !== 'SPORE');
        providers = providers.filter((res: any) => {
            if (!omitPractices.includes(res.practice))
                return res
        });

        setStates({...state, providers, filteredProviders: providers});
        setFilteredCount(providers.length);
        loadFilters(providers);
    }

    const loadFilters = (providers: any[]) => {
        let practices = getFilterValues(providers, 'practice');
        practices = practices.filter((res: any) => {
            if (!omitPractices.includes(res.value))
                return res
        });
        const mcos = getFilterValues(providers, 'mco');
        const lastLoginResult = ['All', 'Success', 'Failed'].map((val, index) => ({
            value: val,
            text: val,
            key: `lastLoginResult_${index}`
        }));
        const brand = brands.map((val, index) => ({
            value: val.value == 'FSD' ? "Family Smiles" : (val.value === 'SMD' ? 'Smile Magic' :
                val.value === '3CD' ? '3C Dental' : val.value == 'OTD' ? 'OrthoDent' : val.value === 'SDD' ? 'SDD' : val.value === 'SYN' ? 'SYN' : 'All'),
            text: val.value,
            key: val.value
        }));
        setFilters({practices, mcos, lastLoginResult, brands: brand});
    }

    const getFilterValues = (providers: any[], name: string) => {
        const set = new Set((providers || []).map((provider: any) => provider[name]));
        return Array.from(set)
            .sort()
            .map((val, index) => ({value: val, text: val, key: `${name}_${index}`}));
    }

    const handleLoginCheck = () => {
        const providers = state.selectedMco;
        setStates({...state, confirm : false, successModal: true})
        reportActions.mcoLoginRequest({providers});
    }

    const hideSuccessModal = () => {
        setStates({...state, successModal: false})
    }

    const handleCancel = () => {
        setStates({...state, confirm: false});
    }

    const handleConfirm = () => {
        const providers = state.selectedMco;
        if (providers.length) {
            setStates({...state, confirm: true});
        } else {
            alert('Please select at least one MCO provider!')
        }
    }

    const handleCheckBox = (checked: any, id: number) => {
        const selectedMco = state.selectedMco;
        if (checked) {
            if (!selectedMco.includes(id))
                selectedMco.push(id);
        } else {
            const index = selectedMco.indexOf(id);
            selectedMco.splice(index, 1);
        }
        setStates({...state, selectedMco});
    };

    const handleCheckAll = (checked: boolean | undefined) =>
        state.filteredProviders.forEach((provider: any) => handleCheckBox(checked, provider.id));

    const onFilterChangeBrand = (e: any, obj: any) => {
        const value = obj.value.includes("All")
            ? obj.value[obj.value.length - 1] === "All"
                ? ["All"]
                : obj.value.filter((r: any) => r !== "All")
            : obj.value;
        setBrandFilter(value);

        let filteredProviders = state.providers.filter((provider: any) => {
            const brandFilterMatch = value.length === 0 || value[0] === 'All' || value.includes(provider.brand);
            const practiceFilterMatch =
                practiceFilter === "All" || provider.practice === practiceFilter;
            const mcoFilterMatch = mcoFilter === "All" || provider.mco === mcoFilter;
            const lastLoginResultFilterMatch =
                lastLoginResultFilter === "All" || provider.lastLoginResult === lastLoginResultFilter;
            return (
                brandFilterMatch &&
                practiceFilterMatch &&
                mcoFilterMatch &&
                lastLoginResultFilterMatch
            );
        });
        setStates({ ...state, filteredProviders });
        setFilteredCount(filteredProviders.length);
    };

    const onFilterChangePractice = (e: any, obj: any) => {
        setPracticeFilter(obj.value);
        let filteredProviders = state.providers.filter((provider: any) => {
            const brandFilterMatch = brandFilter.length === 0 || brandFilter[0] === 'All' || brandFilter.includes(provider.brand);
            const practiceFilterMatch =
                obj.value === "All" || provider.practice === obj.value;
            const mcoFilterMatch = mcoFilter === "All" || provider.mco === mcoFilter;
            const lastLoginResultFilterMatch =
                lastLoginResultFilter === "All" || provider.lastLoginResult === lastLoginResultFilter;
            return (
                brandFilterMatch &&
                practiceFilterMatch &&
                mcoFilterMatch &&
                lastLoginResultFilterMatch
            );
        });
        setStates({ ...state, filteredProviders });
        setFilteredCount(filteredProviders.length);
    };

    const onFilterChangeMco = (e: any, obj: any) => {
        setMcoFilter(obj.value);
        let filteredProviders = state.providers.filter((provider: any) => {
            const brandFilterMatch = brandFilter.length === 0 || brandFilter[0] === 'All' || brandFilter.includes(provider.brand);
            const practiceFilterMatch =
                practiceFilter === "All" || provider.practice === practiceFilter;
            const mcoFilterMatch = obj.value === "All" || provider.mco === obj.value;
            const lastLoginResultFilterMatch =
                lastLoginResultFilter === "All" || provider.lastLoginResult === lastLoginResultFilter;
            return (
                brandFilterMatch &&
                practiceFilterMatch &&
                mcoFilterMatch &&
                lastLoginResultFilterMatch
            );
        });
        setStates({ ...state, filteredProviders });
        setFilteredCount(filteredProviders.length);
    };

    const onFilterChangeLastLoginResult = (e: any, obj: any) => {
        setLastLoginResultFilter(obj.value);
        let filteredProviders = state.providers.filter((provider: any) => {
            const brandFilterMatch = brandFilter.length === 0 || brandFilter[0] === 'All' || brandFilter.includes(provider.brand);
            const practiceFilterMatch =
                practiceFilter === "All" || provider.practice === practiceFilter;
            const mcoFilterMatch = mcoFilter === "All" || provider.mco === mcoFilter;
            const lastLoginResultFilterMatch =
                obj.value === "All" || provider.lastLoginResult === obj.value;
            return (
                brandFilterMatch &&
                practiceFilterMatch &&
                mcoFilterMatch &&
                lastLoginResultFilterMatch
            );
        });
        setStates({ ...state, filteredProviders });
        setFilteredCount(filteredProviders.length);
    };

    const onClearFilter = (e: any) => {
        setBrandFilter(["All"]);
        setPracticeFilter("All");
        setMcoFilter("All");
        setLastLoginResultFilter('All');
        setFilteredCount(state.providers.length);
        setStates({ ...state, filteredProviders: state.providers }); // Reset to original providers data
    };

    const allSelected = state.filteredProviders.map((p: any) => p.id).every((id: number) => state.selectedMco.includes(id));

    return (
        <Template activeLink="mco-login-report">
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">MCO Login Report ({filteredCount})<HelpMark pageId='51'/></h2>
                    <div className="topFilters">
                        <Button primary={true} onClick={onClearFilter}>Clear Filters</Button>
                        <Button primary={true} onClick={() => handleConfirm()}>
                            Run Login Checker
                        </Button>
                        <Dropdown
                            search={true}
                            name="brand"
                            placeholder="Select Brand"
                            selection={true}
                            options={filters.brands}
                            multiple={true}
                            onChange={onFilterChangeBrand}
                            value={brandFilter}
                        />
                        <Dropdown
                            search={true}
                            name="practice"
                            placeholder="Select Practice"
                            selection={true}
                            options={[{ key: "All", value: "All", text: "All" }].concat(
                                filters.practices
                            )}
                            onChange={onFilterChangePractice}
                            value={practiceFilter}
                        />
                        <Dropdown
                            search={true}
                            name="mco"
                            placeholder="Select MCO"
                            selection={true}
                            options={[{ key: "All", value: "All", text: "All" }].concat(filters.mcos)}
                            onChange={onFilterChangeMco}
                            value={mcoFilter}
                        />
                        <Dropdown
                            search={true}
                            name="lastLoginResult"
                            placeholder="Select Login Status"
                            selection={true}
                            options={filters.lastLoginResult}
                            onChange={onFilterChangeLastLoginResult}
                            value={lastLoginResultFilter}
                        />

                        <Confirm
                            open={state.confirm}
                            header='Are you sure you want to do this?'
                            onCancel={handleCancel}
                            onConfirm={handleLoginCheck}
                            cancelButton='No'
                            confirmButton="Yes"
                            content='Clicking on Yes will initiate a request to cron which will validate the login for all MCO providers and send a final report via email.'
                        />
                    </div>
                </div>
            </div>

            {state.successModal &&
                <Modal open={true}>
                    <Modal.Header>Success!</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <p>Request has been initiated. You will get an email once process is completed!</p>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => hideSuccessModal()} positive={true}> Ok </Button>
                    </Modal.Actions>
                </Modal>}
            <div className="ui card">
                <div className="content">
                    <div className="FreezeTable">
                        <Table className="ui table table-striped celled table-hover unstackable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        <div><Checkbox
                                            className='mr10'
                                            checked={allSelected}
                                            onChange={(e, {checked}) => handleCheckAll(checked)}
                                        />
                                        &nbsp;
                                            Practice</div> </Table.HeaderCell>
                                    <Table.HeaderCell> <div>Brand</div> </Table.HeaderCell>
                                    <Table.HeaderCell> <div>MCO</div> </Table.HeaderCell>
                                    <Table.HeaderCell> <div>UserName</div> </Table.HeaderCell>
                                    <Table.HeaderCell> <div>Password</div> </Table.HeaderCell>
                                    <Table.HeaderCell> <div>Last Login Result</div> </Table.HeaderCell>
                                    <Table.HeaderCell> <div>Last Login Time</div> </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    state.filteredProviders && state.filteredProviders.length > 0 &&
                                    state.filteredProviders.map((item: any, index: any) => {
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell>
                                                    <Checkbox
                                                        key={item.id}
                                                        value={item.id}
                                                        className='mr10'
                                                        checked={state.selectedMco.includes(item.id)}
                                                        onChange={(e, {checked}) => handleCheckBox(checked, item.id)}
                                                    />
                                                    &nbsp;
                                                    {item.practice}
                                                </Table.Cell>
                                                <Table.Cell>{item.brand}</Table.Cell>
                                                <Table.Cell>{item.mco}</Table.Cell>
                                                <Table.Cell>{item.username}</Table.Cell>
                                                <Table.Cell>{item.password}</Table.Cell>
                                                <Table.Cell>{item.lastLoginResult}</Table.Cell>
                                                <Table.Cell>{item.lastLoginTime && moment(item.lastLoginTime).format("YYYY-MM-DD HH:mm")}</Table.Cell>
                                            </Table.Row>
                                        )
                                    })}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>

        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        mcoList: state.report.mcoList,
    };
}

export default connect(mapStateToProps)(McoLoginReport);
