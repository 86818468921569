import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../model";
import {useActions} from "../../../redux/actions";
import * as spotActions from "../../../redux/actions/spot";
import {Card, Header, Popup, Table} from "semantic-ui-react";
import {trimString} from '../constants';
import ReactTooltip from "react-tooltip";
import {fetchCommLogInfo} from "../../../redux/actions/spot";

interface Props {
    patientId: any,
    practice: any,
}

function CommlogHistory(props: Props) {
    const spotAction = useActions(spotActions);
    const [state, setStates] = useState({
        commlog: [] as any,
    });

    useEffect(() => {
        loadData();
    }, []);


    const loadData = async () => {
        const query: any = {location: props.practice, PatNum: props.patientId};
        const result: any[] = await spotAction.fetchCommLogInfo(query);
        setStates({commlog: result})
    }

    return (
        <Card>
            <Card.Content>
                <Card.Header>
                    <h3>COMMLOG HISTORY</h3>
                </Card.Header>
            </Card.Content>
            <Card.Content>
                <div className="table-responsive limit-height">
                    <Table striped={true}>
                        <thead>
                        <tr>
                            <th>PatNum</th>
                            <th>CommDateTime</th>
                            <th>CommType</th>
                            <th>Note</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            state.commlog.length && state.commlog.map((item: any, key: any) =>
                                <tr key={key}>
                                    <td>{item.PatNum}</td>
                                    <td>{item.CommDateTime ? item.CommDateTime : ''}</td>
                                    <td>{item.CommType}</td>
                                    <td>
                                        {item.Note ?
                                     
                                            <div> 
                                                  { 
                                            item.Note.length >=720?
                                            <Popup 
                                                    header={"Note"}
                                                    key={`commlog${key}`}
                                                    trigger={
                                                        <a data-tip={`commlog${key}`} data-for={`commlog${key}`}
                                                        style={{
                                                            color:"#D16F26"
                                                        }}
                                                        >
                                                        {trimString(item.Note, 15, false)}
                                                    </a>
                                                    }
                                                    wide       
                                                    hoverable   
                                                    position='top center'                          
                                                    on={['hover', 'click']}                                                                          >
                                                            <PopupContent>
                                                                <Header as="h4">Note</Header>
                                                                {item.Note}
                                                            </PopupContent>
                                                </Popup>
                                                :
                                                   <Popup  
                                                trigger={
                                                        <a data-tip={`commlog${key}`} data-for={`commlog${key}`}
                                                        style={{
                                                            color:"#D16F26"
                                                        }}
                                                        >
                                                        {trimString(item.Note, 15, false)}
                                                    </a>
                                                    }
                                                    header={"Note"}
                                                    key={`commlog${key}`}
                                                    wide
                                                    hoverable
                                            ><p>
                                                {item.Note}
                                            </p></Popup>
}
                                            
                                            </div> : ''}
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </Card.Content>
        </Card>
    )
}


function PopupContent(props:any){
    return (
        <div className='popup-content'>
                {props.children}
        </div>
    )
}
function mapStateToProps(state: RootState) {
    return {user: state.auth.user}
}

export default connect(mapStateToProps)(CommlogHistory)
