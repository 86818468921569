import React, { useState, useEffect } from 'react';
import { useActions } from '../../redux/actions';
import * as ProfileActions from "../../redux/actions/provider";
import { Button, Form, Label, Modal } from "semantic-ui-react";

interface OrthoDayRateModalProps {
    practiceStipend: string;
    loaded: number;
    orthoLocation: string;
    value: string;
    name: string ;
    index: number;
    dayRates: IOrthoDayRate[];
    DocID: number;
    doctorDayRate: Array<IOrthoDayRate>;
    hideModalODR: any;
    DocData: any;
    providerDayRate: string,
    openOrthoDayRateModal: boolean,
    showOpenOrthoDayRate: any
}

interface IOrthoDayRate {
    practiceStipend: string;
    orthoLocation: string;
    providerDayRate: string;
    userId: number;
    practiceId: number;
    
}

class DayRate implements IOrthoDayRate {
    orthoLocation!: string;
    providerDayRate!: string;
    userId!: number;
    practiceId!: number;
    practice!: string;
    practiceStipend!: string;
}

const OrthoDayRateModal = (props: OrthoDayRateModalProps) => {
    const dayRates : Array<DayRate> = []
    const tableRows : Array<JSX.Element> = []
    const [state, setState] = useState({
        loaded: 0, orthoLocation: '', value: '',
        name: '', index: 0,
        dayRates: dayRates,
        tableRows: tableRows
    });
    const providerActions = useActions(ProfileActions);

    useEffect(() => {
        const fetchData = async () => {
            const id = props.DocData.UserID
            const response = await providerActions.getDoctorDayRate(id)
            setState(prevState => ({
                ...prevState, dayRates: response
            }));
            setState(prevState => ({ ...prevState, tableRows: getTableRows(response) }))
        }
        fetchData();


    }, []);

    const getTableRows = (doctorDayRate: Array<IOrthoDayRate>) => {       
        const tableRows =  doctorDayRate && doctorDayRate.map((data, key) => {
            return <tr key={key}>
                <td> {data?.orthoLocation}</td>
                {<td>
                        {data?.orthoLocation === state.orthoLocation && state.name === props.providerDayRate ?
                            (
                                <div className="form-group">
                                    <input type="text" name="providerDayRate"
                                        value={state.value}
                                        onChange={(e) => handleOptionsWithAll(e, key)}
                                        onBlur={saveRate} />
                                </div>
                            ) : <p onClick={() => onClickAddRate(data?.orthoLocation, data?.providerDayRate, "providerDayRate", key)}>
                                {data?.providerDayRate}
                            </p>}
                </td>}
                {<td>
                        {data?.orthoLocation === state.orthoLocation && state.name === props.practiceStipend ?
                            (
                                <div className="form-group">
                                    <input type="text" name="practiceStipend"
                                        value={state.value}
                                        onChange={(e) => handleOptionsWithAll(e, key)}
                                        onBlur={saveRate} />
                                </div>
                            ) : <p onClick={() => onClickAddRate(data?.orthoLocation, data?.practiceStipend, "practiceStipend", key)}>
                                {data?.practiceStipend}
                            </p>}
                </td>}
            </tr>
        });
        return tableRows;
    }

    const onClickAddRate = (orthoLocation: string, value: string, name: string, index: number) => {
        setState({ ...state, orthoLocation: orthoLocation, name: name, value: value, index: index });
    };

    const handleOptionsWithAll = (e: any, key: number) => {
        handleOptions(e, key);
    };

    const handleOptions = (e: { target: { value: any; name:string }; }, key:  number) => {
        let dayRates:any = [...state.dayRates];
        const fieldName: string = e.target.name;
        dayRates[key][fieldName] = e.target.value;
        setState({ ...state, dayRates: dayRates });
    };

    const handleAddRate = (e: { target: { value: any; }; }) => {
        setState({ ...state, value: e.target.value });
    };

    const saveRate = () => {
        Promise.all([
            providerActions.updateOrthoDayRates(state.dayRates, props.DocID)])
            .then(() => {
                console.log("saveRate::updateOrthoDayRates:: success")
                alert('Ortho day rate(s) successfully updated.');
            }).catch(error => {
                console.log("saveRate::updateOrthoDayRates::", error)
                alert('Ortho day rate(s) update failed.');
            });
    };

    return (
        <Modal
            open={true}
            size='small'
            centered
            closeIcon
            onClose={() => props.showOpenOrthoDayRate(false)}
        >
            <Modal.Header>
                <div className="row">
                    <Modal.Content id='ModalHeader'
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }} >
                        Ortho Rates for  &nbsp; <Label color='grey'>
                        Dr &nbsp;&nbsp;{props.DocData.firstName || ''} {" " + props.DocData.lastName || ''} 
                            </Label>  &nbsp;&nbsp;

                        <Modal.Content id='ModalHeader' >
                           | &nbsp;&nbsp;<span >  Status:</span> <Label color={props.DocData.status ? 'teal' : 'red'}>{props.DocData.status ? 'Active' : 'DeActive'}</Label> 
                        </Modal.Content>
                    </Modal.Content>

                </div>


            </Modal.Header>
            <Modal.Content style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                <div className="ui card">
                    <div className="content">
                        <div className="table-responsive">
                            <table className="table table-striped table-hover my-0">
                                <thead>
                                    <tr>
                                        <th className={`FreezeTable-first-child `} >
                                            <div>
                                                Practice
                                            </div>
                                        </th>
                                        <th className={`FreezeTable-first-child `} >
                                            <div>
                                                Ortho Rate
                                            </div>
                                        </th>
                                        <th className={`FreezeTable-first-child `} >
                                            <div>
                                                Stipend Rate
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.dayRates && state.dayRates.map((data, key) => {
                                            return <tr>
                                                <td> {data?.practice}</td>
                                                <td>
                                                    <div className="form-group">
                                                        <Form.Input
                                                            type='text'
                                                            fluid={true}
                                                            defaultValue={data.providerDayRate || 0}
                                                            name={'providerDayRate'} onChange={(e) => handleOptionsWithAll(e, key)} />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-group">
                                                        <Form.Input
                                                            type='text'
                                                            fluid={true}
                                                            defaultValue={data.practiceStipend || 0}
                                                            name={'practiceStipend'} onChange={(e) => handleOptionsWithAll(e, key)} />
                                                    </div>
                                                </td>
                                            </tr>
                                        })

                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Close"
                    secondary
                    labelPosition='right'
                    icon='close'
                    onClick={() => props.showOpenOrthoDayRate(false)}
                    negative
                />
                <Button
                    content="Save Rates"
                    primary
                    labelPosition='right'
                    icon='checkmark'
                    onClick={saveRate}
                    positive
                />
            </Modal.Actions>
        </Modal>
    );
}

export default OrthoDayRateModal;
