import React, {useEffect, useState} from 'react';
import {Table, Checkbox, Popup, Dropdown, Icon, Button, Input, TextArea, Modal} from 'semantic-ui-react';
import * as practiceAction from "../redux/actions/practiceAdmin";
import {useActions} from "../redux/actions";
import PracticeAdminODconfig from "./PracticeAdminODconfig";

interface Data {
    [key: string]: any;
}

interface Props {
    data: Data[];
    reLoadData : Function
}
interface EditData {
    [key: string]: any;
}

const PracticeAdminDynamicTable: React.FC<Props> = (props, context) => {
    const practiceActions = useActions(practiceAction);
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [order, setOrder] = useState<boolean>(false);
    const [sortKey, setSortKey] = useState<string | undefined>(undefined);
    const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending');
    const [editRow, setEditRow] = useState(null);
    const [editData, setEditData] = useState<EditData>({});
    const [OdConfigPracticeData, setOdConfigPracticeData] = useState({});
    const data = props.data;
    const handleEdit = (rowIndex:any) => {
        setEditRow(rowIndex);
        setEditData(sortedData[rowIndex]);
    };

    const handleShowODconfig = (rowIndex: number) => {
        const practiceId =  sortedData[rowIndex] && sortedData[rowIndex].id;
        const configData = sortedData[rowIndex] && sortedData[rowIndex].PracticeCommunicationConfig
        const isReachable =  sortedData[rowIndex] && sortedData[rowIndex].isReachable;
        setOdConfigPracticeData({practiceId, configData,isReachable})
    }

    const handleSave = () => {
        // save the edited data here
        if (editRow !== null) {
            practiceActions.updatePractice(editData);
            setEditRow(null);
            setEditData({});
        }
    };

    const savePracticeODconfig = async (data: any) => {
        await practiceActions.saveOpenDentalConfig(data);
        props.reLoadData();
    }

    const closeConfigModal = () => {
        setOdConfigPracticeData({})
    }

    const handleCancel = () => {
        setEditRow(null);
        setEditData({});
    };

    const handleChangedCell = (event:any,data:any, columnName:any) => {
        setEditData((prev) => ({ ...prev, [columnName]: data.value }));
    };

    const handleSort = (columnName: string) => {
        if (sortKey === columnName) {
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortKey(columnName);
            setSortDirection('ascending');
        }
    };


    if (!data || data.length === 0) return null;
    const columnNames = Object.keys(data && data[0]);

    const sortedData = [...data].sort((a, b) => {
        let sortValue = 0;
        if (a[sortKey || columnNames[0]] < b[sortKey || columnNames[0]]) {
            sortValue = sortDirection === 'ascending' ? -1 : 1;
        } else if (a[sortKey || columnNames[0]] > b[sortKey || columnNames[0]]) {
            sortValue = sortDirection === 'ascending' ? 1 : -1;
        }
        return sortValue;
    });


    const handleChange = (event: any, data: any) => {
        setSelectedValues(data.value);
    };
    const direction = order ? 'sort down' : 'sort up';

    return (
        <div className="FreezeTable">
            { OdConfigPracticeData && Object.keys(OdConfigPracticeData).length > 0 ?
                <PracticeAdminODconfig
                    handleSave={savePracticeODconfig}
                    data={OdConfigPracticeData}
                    handleClose={closeConfigModal}
                /> : ''}
            <Table className="ui table table-striped table-hover celled unstackable">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                        {columnNames.map((columnName, index) => (
                            <Table.HeaderCell key={index}
                                              onClick={() => handleSort(columnName)}
                            >
                                <div>{columnName}
                                    <Icon className={order ? direction : 'sort'}/></div>

                            </Table.HeaderCell>
                        ))}
                    </Table.Row>

                </Table.Header>
                <Table.Body>
                    {sortedData.map((row, rowIndex) => (
                        <Table.Row key={rowIndex}>
                            <Table.Cell>
                                {editRow === rowIndex ? (
                                    <>
                                        <Button color='green' onClick={handleSave}>Save</Button>
                                        <Button className={'mt10'} color='orange' onClick={()=> handleShowODconfig(rowIndex)}>OpenDental Config</Button>
                                        <Button className={'mt10'} color='red' onClick={handleCancel}>Cancel</Button>
                                    </>
                                ) : (
                                    <Button.Group>
                                        <Button icon='edit' label='edit' onClick={() => handleEdit(rowIndex)}/>
                                    </Button.Group>
                                )}
                            </Table.Cell>
                            {columnNames.map((columnName, columnIndex) => (
                                <Table.Cell key={columnIndex}>
                                    {editRow === rowIndex ? (
                                            columnName === 'id' ? (
                                                row[columnName]
                                            ) : (
                                                typeof row[columnName] === 'boolean' ? (
                                                    <Checkbox checked={editData[columnName]} onChange={(event,data) => handleChangedCell(event,data, columnName)} />
                                                ) : (
                                                    typeof row[columnName] === 'object' && !Array.isArray(row[columnName])
                                                        ? JSON.stringify(editData[columnName])
                                                        : (columnName.toLowerCase().includes('message') || columnName.toLowerCase().includes('subject')) ?
                                                            <TextArea
                                                                value={editData[columnName]}
                                                                onChange={(event,data) => handleChangedCell(event,data, columnName)}
                                                            /> : Array.isArray(row[columnName]) ?
                                                                <Dropdown
                                                                    fluid
                                                                    multiple
                                                                    selection
                                                                    options={row[columnName].map((item: any) => ({
                                                                        key: item.practiceId,
                                                                        text: item.brand,
                                                                        value: item.brand,
                                                                    }))}
                                                                    defaultValue={editData[columnName].map((item: any) =>item.brand )}
                                                                    onChange={(event,data) => handleChangedCell(event,data, columnName)}
                                                                /> :<Input
                                                                    value={editData[columnName]}
                                                                    onChange={(event,data) => handleChangedCell(event,data, columnName)}
                                                                />
                                                )
                                            )
                                        )
                                        : (
                                        typeof row[columnName] === 'boolean' ? (
                                            <Checkbox checked={row[columnName]}/>
                                        ) : (
                                            typeof row[columnName] === 'object' && !Array.isArray(row[columnName])
                                                ? JSON.stringify(row[columnName])
                                                : (columnName.toLowerCase().includes('message') || columnName.toLowerCase().includes('subject')) ?
                                                    <Popup
                                                        trigger={<div>{row[columnName].slice(0, 15)}</div>}
                                                        content={row[columnName]}
                                                    /> : Array.isArray(row[columnName]) ?
                                                        <Dropdown
                                                            fluid
                                                            multiple
                                                            selection
                                                            options={row[columnName].map((item: any) => ({
                                                                key: item.practiceId,
                                                                text: item.brand,
                                                                value: item.brand,
                                                            }))}
                                                            defaultValue={row[columnName].map((item: any) =>item.brand )}
                                                            onChange={handleChange}
                                                        /> : row[columnName]
                                        )
                                    )}
                                </Table.Cell>


                            ))}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    );
};

export default PracticeAdminDynamicTable;
