import React, {useState} from "react";
import {Button, Icon, Label, Modal, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {RootState} from "../../model";
import ReactDOMServer from "react-dom/server";


function ReferralActualsModal(props: any) {
    const {practiceData} = props;
    const [state, setStates] = useState({
        sort: 0,
        ascending: true
    });

    const headers = ['PRACTICE', 'PATIENT NUMBER', 'DATE OF SERVICE', 'DOCTOR']
    const dataRows = practiceData[practiceData.actCode].map((log: any) => [
            {value: practiceData.practice.practice},
            {value: log.PatNum},
            {value: log.ProcDate},
            {value: log.Dr_Name}
        ]
    ).sort((a: any[], b: any[]) => {
        const sort = Number.isInteger(a[state.sort].value)
            ? a[state.sort].value - b[state.sort].value
            : a[state.sort].value.toString().localeCompare(b[state.sort].value.toString());
        return sort * (state.ascending ? -1 : 1)
    });


    const sendEmail = () => {
        const tableHeader = `${headers.map(header => header.toUpperCase()).join(',')}`;
        const body = practiceData[practiceData.actCode]
            .map((log: any) => `${practiceData.practice.practice},${log.PatNum},${log.ProcDate},${log.Dr_Name}`)
            .join('\n');
        const csv = `${tableHeader}\n${body}`;
        const pdf = ReactDOMServer.renderToString(<div>
            <h3 style={{textAlign: "center"}}>Speciality PCO</h3>
            <br/>
            {tableData}
        </div>);
        props.sendMail({csv, pdf});
    }

    const tableData = <Table>
        <Table.Header>
            <Table.Row>
                {headers.map((header, i) => <Table.HeaderCell
                    className='cursorPointer'
                    onClick={() => setStates({sort: i, ascending: state.sort == i && !state.ascending})}
                    key={'header_modal_' + i}
                >
                    <span style={{whiteSpace: 'nowrap'}}>
                        <Icon name='sort' color={state.sort == i ? 'orange' : 'grey'}/>
                        {header}
                    </span>
                </Table.HeaderCell>)}
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {
                dataRows && dataRows.map((row: any, key: number) =>
                    <Table.Row key={'modalRow' + key}>
                        {
                            row.map((value: any, i: number) => <Table.Cell
                                key={i + 'column' + key}>{value.text || value.value}</Table.Cell>)
                        }
                    </Table.Row>)
            }
        </Table.Body>
    </Table>


    return (
        <Modal className='modalCenter' size="large" onClose={() => props.handleClose()} open={true}>
            <Modal.Header>
                {practiceData.practice.practice} {practiceData.actCode=='orthoActualRef'? 'Ortho Actual Referral':'OS Actual Referral'}
                <Label className="ml15">Patient Count: <b>{practiceData[practiceData.actCode].length}</b></Label>               
            </Modal.Header>
            {
                practiceData[practiceData.actCode] && <Modal.Content scrolling={true}>
                    {tableData}
                </Modal.Content>
            }
            <Modal.Actions>
               { dataRows.length > 0 ?<Button
                    content="Send Email"
                    primary={true}
                    onClick={() => sendEmail()}
                /> : '' }
                <Button
                    content="Close"
                    onClick={() => props.handleClose()}
                />
            </Modal.Actions>
        </Modal>
    );
}

function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app
    };
}

export default connect(mapStateToProps)(ReferralActualsModal);