import { ActionType } from "../../model/actionTypes";
import {GoxProcedureCodeList} from "../../model/goxProcedureCode";
import {errorHandler} from "./auth";


export const saveGOXProcedureCodes = (obj: GoxProcedureCodeList) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("gox-procedure-codes/create", obj);
        dispatch({ type: ActionType.CREATE_GOX_PROCEDURE_CODES_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const updateGOXProcedureCodes = (obj:GoxProcedureCodeList) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("gox-procedure-codes/update", obj);
        dispatch({ type: ActionType.UPDATE_GOX_PROCEDURE_CODES_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const getGOXProcedureCodes = (id:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        id=id||'all';
        const resp =  await api.get("gox-procedure-codes/"+id);
        dispatch({ type: ActionType.FETCH_GOX_PROCEDURE_CODES_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const deleteGOXProcedureCodes = (id:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        id=id||'all';
        const resp =  await api.delete("gox-procedure-codes/"+id);
        dispatch({ type: ActionType.FETCH_GOX_PROCEDURE_CODES_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
