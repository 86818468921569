import React, {useEffect, useState} from 'react';
import Template from '../../components/Template';
import {Button, Dropdown, Icon, Input, Label, Modal, Pagination, Popup, Table} from 'semantic-ui-react'
import * as ProfileActions from "../../redux/actions/provider";
import {useActions} from "../../redux/actions";
import {HEADERS} from './constants';
import {isSafari, providerProfilesOptions,emailPattern} from "../../constants";
import _ from "lodash";
import * as PracticeActions from "../../redux/actions/practice"
import HelpMark from "../../components/HelpMark";
import EditProviderProfileForm from './EditProviderProfileForm';
import ProviderLockedHistory from "./ProviderLockedHistory";
import WorkHistoryModal from './workHistoryModal';
import moment from "moment/moment";
import OrthoDayRateModal from './OrthoDayRateModal';
import {v4 as uuidv4} from 'uuid';
import ProviderDayRateModal from "./ProviderDayRateModal";
import ProviderRampThresholdModal from "./ProviderRampThresholdModal";
import DoctorBaseWorkDaysModal from "./DoctorBaseWorkDaysModal";
import EditHistoryModal from './editHistoryModal';

let delayDebounceFn: NodeJS.Timeout;

function ProviderProfile() {
    const initialState: any = {
        profiles: [],
        paginationState: {page: 1, size: 0, limit: 10} as any,
        status: providerProfilesOptions.filter(ppo => ppo.key == "Active")[0].value,
        keyword: '',
        orderBy: null,
        order: true,
        showEditProvider: false,
        showProviderLockHistory: false,
        profileInEdit: {},
        practicesOptions: [],
        providerLockProfiles: [],
        profilesLockDetails: [],
        deleteModalOpen: false,
        profileToDelete: {},
        currentProfile: {},
        openWorkHistoryModal: false,
        profilesCount: 0,
        openOrthoDayRateModal: false,
        openProviderDayRateModal: false,
        openProviderRampThresholdModal: false,
        openDoctorBaseWorkDaysModal: false,
        currUserLogs:{},
        copiedEmails:0
    }

    const [openWorkHistoryModal, setWorkHistoryModalOpen] = useState(false);
    const [openEditHistoryModal, setEditHitoryModalOpen] = useState(false);

    const handleOpenWorkHistoryModal = () => {
        setWorkHistoryModalOpen(true);
    };

    const handleCloseWorkHistoryModal = () => {
        setStates({...state, currentProfile: {}});
        setWorkHistoryModalOpen(false);
    };

    const handleOpenEditHistoryModal=()=>{
        setEditHitoryModalOpen(true)
    }

    const handleCloseEditHistoryModal = () => {
        setStates({...state, currUserLogs: {}});
        setEditHitoryModalOpen(false);
    };

    const [state, setStates] = useState(initialState);
    const providerActions = useActions(ProfileActions);
    const practiceActions = useActions(PracticeActions);
    useEffect(() => {
        loadData().then();
    }, []);

    const loadData = async () => {
        const results: any = await Promise.all([
            providerActions.getProfiles({
                limit: paginationState.limit,
                offset: paginationState.limit * (paginationState.page - 1),
                status: status,
                keyword: keyword,
                orderBy: orderBy,
                order: order ? 'ASC' : 'DESC'
            }),
            providerActions.getTotal({
                keyword: keyword,
                status: status,
                orderBy: orderBy,
                order: order ? 'ASC' : 'DESC'
            }),
            practiceActions.fetchPractices(makePracticeOptions),
            providerActions.getProfilesLockDetails()
        ]);
        await setData(results, keyword, status, orderBy, order);
    }
    const makePracticeOptions = (response: any) => {
        let practiceOptions = [{key: 0, value: 0, text: 'Select Practice'}];
        if (response) {
            response.forEach((item: any) => {
                practiceOptions.push({text: item.practice, value: item.practice, key: item.id.toString()});
            })
        }
        return practiceOptions;
    };

    const setData = (results: any, search: any, statusValue: any, sortColumn: any, orderUpdated: boolean) => {
        let data = results[0].map((r: any) => {
            return {...r, ...r.UdaDrList}
        })
        const dentistEntries = data.filter((entry: { UdrUsersPermissions: any[]; }) => {
            return entry.UdrUsersPermissions.some(permission => permission.udrPermissions.role === 'Orthodontics');
          });

        let practices = results[2];
        const profileSort = _.orderBy(data, [sortColumn], [orderUpdated ? 'asc' : 'desc'])
        let convertedProfile = [];
        if (isValidIndex(results, 3)) {
            convertedProfile = results[3].map((item: any) => ({
                text: `${item.firstName} ${item.lastName}`,
                value: item.UserID.toString(),
                key: item.UserID.toString()
            }));
        }

        setStates({...state,
            profiles: profileSort, paginationState: {
                page: paginationState.page
                , limit: paginationState.limit, size: results[1].count
            }, profileInEdit:{}, showEditProvider: false,
            practicesOptions: practices,
            keyword: search, status: statusValue, orderBy: sortColumn, order: orderUpdated,
            profilesLockDetails: convertedProfile,
            profilesCount: isValidIndex(results, 1) ? results[1].count : 0,
            openWorkHistoryModal: true
        })
    }

    const isValidIndex = (data: Array<any>, index: number): Boolean => {
        return data.length > 0 && index >= 0 && index <= data.length - 1
    }

    const handleSearch = async (search: any) => {
        const keyword = search.target.value;
        delayDebounceFn && clearTimeout(delayDebounceFn);
        delayDebounceFn = setTimeout(() => executeSearch(keyword), 2000);
    };

    const executeSearch = async (search: any) => {
        const results: any = await Promise.all([
            providerActions.getProfiles({
                limit: paginationState.limit,
                offset: paginationState.limit * (state.paginationState.page - 1),
                keyword: search, status: status, orderBy: orderBy, order: order ? 'ASC' : 'DESC'
            }),
            providerActions.getTotal({keyword: search, status: status, orderBy: orderBy, order: order ? 'ASC' : 'DESC'})
        ]);
        await setData(results, search, status, orderBy, order);
    };

    const handleChange = async (selectedOption: any) => {
        const statusValue = selectedOption.target.textContent === 'All' ? '' : selectedOption.target.textContent;
        const results: any = await Promise.all([
            providerActions.getProfiles({
                limit: paginationState.limit,
                offset: paginationState.limit * (paginationState.page - 1),
                status: statusValue, keyword: keyword, orderBy: orderBy, order: order ? 'ASC' : 'DESC'
            }),
            providerActions.getTotal({
                status: statusValue,
                keyword: keyword,
                orderBy: orderBy,
                order: order ? 'ASC' : 'DESC'
            })
        ]);
        await setData(results, keyword, statusValue, orderBy, order);
    }

    const setPage = async (page: any) => {
        const results: any = await Promise.all([
            providerActions.getProfiles({
                limit: paginationState.limit,
                offset: ((page.activePage - 1) * paginationState.limit),
                status: status,
                keyword: keyword,
                orderBy: orderBy,
                order: order ? 'ASC' : 'DESC'
            }),
            providerActions.getTotal({
                status: status,
                keyword: keyword,
                orderBy: orderBy,
                order: order ? 'ASC' : 'DESC'
            })
        ]);
        paginationState.page = page.activePage;
        await setData(results, keyword, status, orderBy, order);
    }

    const getSortRows = async (sortColumn: string, order: string) => {
        const orderUpdated = !order;
        const results: any = await Promise.all([
            providerActions.getProfiles({
                limit: paginationState.limit,
                offset: paginationState.limit * (paginationState.page - 1),
                status: status, keyword: keyword, orderBy: sortColumn, order: order ? 'ASC' : 'DESC'
            }),
            providerActions.getTotal({
                status: status,
                keyword: keyword,
                orderBy: sortColumn,
                order: order ? 'ASC' : 'DESC'
            })
        ]);
        await setData(results, keyword, status, sortColumn, orderUpdated);
    };

    const handleOnChangeProvider = async (selectedOption: any) => {
        let profiles = await providerActions.getProviderLockDetails(selectedOption);
        setStates({...state, providerLockProfiles: profiles})
    }
    const lockHistory = () => {
        setStates({...state, showProviderLockHistory: true})
    }

    const downloadClick = async () => {
        const results: any = await Promise.all([
            providerActions.getExcelProfiles({
                status: status
            })
        ]);
        let profileResult = results[0].map((r: any) => {
            return {...r, ...r.UdaDrList}
        })
        const csvBody = profileResult && profileResult.map((data: any) => {
            return HEADERS.map((field: any) =>
                (field.format) ? field.format(data[field.value]) : data[field.value])
        }).map((pt: any) => Object.values(pt).join(',')).join('\r\n');
        const csvData = HEADERS.map((header, key) => header.label).join(',')
            .concat('\r\n')
            .concat(csvBody);
        const blob = new Blob(['', csvData], {type: isSafari() ? 'application/csv' : 'text/csv'});
        let link = document.createElement("a");
        link.setAttribute("href", (window.URL || window.webkitURL).createObjectURL(blob));
        link.setAttribute("download", 'provider_profile.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const saveForm = async (obj: any, udaObj: any) => {
        const res = await providerActions.saveProfile(obj);
        if (res) {
            await providerActions.saveUdaDrListForm(udaObj)
        }
        delayDebounceFn && clearTimeout(delayDebounceFn);
        delayDebounceFn = await setTimeout(async () => await loadData(), 2000);
    }
    const {
        orderBy, order, paginationState, profiles, keyword, status, showEditProvider, practicesOptions,
        showProviderLockHistory, providerLockProfiles, profilesLockDetails, profilesCount
    } = state;
    const direction = order ? 'sort down' : 'sort up';

    const toggleDeleteProfileModalView = (data: object) => {
        setStates({...state, deleteModalOpen: !state.deleteModalOpen, profileToDelete: data});
    }
    const toggleWorkHistoryModalView = (data: object) => {
        setStates({...state, currentProfile: data});
        handleOpenWorkHistoryModal();
    }
    const toggleEditHistoryModalView=(data:{AuditLogs:object[]})=>{
        data.AuditLogs.map((aLog:any)=>{
          const splitDate = (aLog.createdAt).split(":");
          const lastItem=splitDate[2];
          aLog.createdAt=splitDate.join(":").replace(lastItem,"00.000Z");
          return aLog;        
        })
        const grpData = _.groupBy(data.AuditLogs,'createdAt')
        setStates({...state, currUserLogs: grpData});
        handleOpenEditHistoryModal();
    }

    const deleteProfile = async (profile: any) => {
        const request = {
            id: profile.id,
            UserID: profile.UserID,
            isDeleted: 1
        }
        await providerActions.saveProfile(request);
        await loadData();
        setStates({...state, profileToDelete: {}, deleteModalOpen: false})
    }

    const onCloseDetails = () => {
        setStates({...state, showProviderLockHistory: false})
    }

    const handleCheckBox = async (data: any) => {
        const request = {
            id: 0,
            UserID: data.UserID,
            lockStatus: true,
            data: data,
            timestamp: moment(new Date()).format("YYYY-MM-DD")
        };
        const result = await providerActions.saveLockDetails(request);
        if (result.id) {
            alert(`${data.firstName} ${data.lastName}'s status locked successful`);
            return;
        }
        alert(`Failed to lock ${data.firstName} ${data.lastName}'s status, please try again`);
    };

    const clickEdit = (data: any) => {
        setStates({ ...state, showEditProvider: !state.showEditProvider, profileInEdit: data })
    }

    const getRows = (data: any) => {
        return <Table.Row key={`${uuidv4()} ${data.firstName} ${data.lastName}'s`}>
            {
                HEADERS.map((field: any) => <Table.Cell key={`${uuidv4()} ${data.firstName} ${data.lastName}`}>
                    {
                        (() => {
                            switch (field.value) {
                                case "action":
                                    return (
                                        <Button.Group compact size="mini">
                                            <Popup inverted
                                                   content={`Lock ${data.firstName} ${data.lastName}'s status.`}
                                                   trigger={
                                                       <Button secondary icon onClick={() => handleCheckBox(data)}>
                                                           <Icon name="lock"/>
                                                       </Button>}
                                            />
                                            <Popup content={`Delete ${data.firstName} ${data.lastName}'s profile.`}
                                                   trigger={
                                                       <Button basic icon
                                                               onClick={() => toggleDeleteProfileModalView(data)}>
                                                           <Icon name="trash alternate outline" className="mr10"/>
                                                       </Button>
                                                   } inverted/>
                                            <Popup inverted
                                                   content={`Edit ${data.firstName} ${data.lastName}'s profile.`}
                                                   trigger={
                                                       <Button primary icon onClick={() => clickEdit( data
                                                           )}>
                                                           <Icon name="edit outline" className="mr10"/>
                                                       </Button>}
                                            />
                                            <Popup inverted
                                                   content={`View ${data.firstName} ${data.lastName}'s work history.`}
                                                   trigger={
                                                       <Button basic icon
                                                               onClick={() => toggleWorkHistoryModalView(data)}>
                                                           <Icon name="history"/>
                                                       </Button>}
                                            />
                                                   <Popup inverted
                                                   content={`View ${data.firstName} ${data.lastName}'s Edit history.`}
                                                   trigger={
                                                       <Button basic icon
                                                               onClick={() => toggleEditHistoryModalView(data)}>
                                                           <Icon name="info"/>
                                                       </Button>}
                                            />
                                        </Button.Group>
                                    );
                                default:
                                    return field.format ? field.format(data[field.value]) : data[field.value];
                            }
                        })()
                    }
                </Table.Cell>)
            }
        </Table.Row>
    }
    const showOpenOrthoDayRate = (showOpenOrthoDayRate: boolean) => {
        setStates({
            ...state,
            openOrthoDayRateModal: showOpenOrthoDayRate
        })
    }

    const showOpenProviderDayRateModal = (openProviderDayRateModal: boolean) => {
        setStates({
            ...state,
            openProviderDayRateModal: openProviderDayRateModal
        })
    }

    const showOpenProviderRampThresholdModal = (openProviderRampThresholdModal: boolean) => {
        setStates({
            ...state,
            openProviderRampThresholdModal: openProviderRampThresholdModal
        })
    }
    const showDoctorBaseWorkDaysModal = (openDoctorBaseWorkDaysModal: boolean) => {
        setStates({
            ...state,
            openDoctorBaseWorkDaysModal: openDoctorBaseWorkDaysModal
        })
    }

    const getEmailsList=   ()=>{
        const {profiles} =state
        let emails: any =[]
        profiles && profiles.forEach((res:{docEmail:string})=>{
            emails.push(res.docEmail)
        });
        return Array.from(new Set(emails.filter((item:string) => emailPattern.test(item))));
    }

    const handleCopyAllEmail =  () => {
        const uniqueEmails =  getEmailsList();
        navigator.clipboard.writeText(uniqueEmails.join(',')).then(()=>{
            setStates({...state, copiedEmails: -1 });
            setTimeout(() => {
                setStates({...state, copiedEmails: 0 });
            }, 5000);
        })
        .catch(err => {
            console.error('Unable to copy emails: ', err);
        });  
    }

    return (
        <Template activeLink='provider profile'>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">Provider Profiles <HelpMark pageId='58'/></h2>
                    <Label circular color='orange'>{profilesCount}</Label>
                    <div className="topFilters">
                        <Button content="Lock History" secondary={true} onClick={lockHistory}/>
                        <Button primary={true} content="Download Template" onClick={downloadClick}/>
                        <Dropdown
                            search={true}
                            name="status"
                            placeholder="Select Status"
                            selection={true}
                            options={providerProfilesOptions}
                            onChange={handleChange}
                            defaultValue={state.status}
                        />
                        <Input
                            name='search'
                            placeholder='Search'
                            onChange={handleSearch}
                        />
                        <Button color={'green'} icon={'copy'} onClick={()=>handleCopyAllEmail()} content={state.copiedEmails === -1?'Emails Copied...':'Copy All Emails'}/>
                    </div>
                </div>
            </div>
            {showProviderLockHistory &&
                <ProviderLockedHistory profiles={providerLockProfiles} profilesLockDetails={profilesLockDetails}
                                       onChangeProvider={handleOnChangeProvider} onClose={onCloseDetails}/>}
            {showEditProvider &&
                <>
                    <Modal open={showEditProvider} size='large' centered closeIcon
                           onClose={() => setStates({...state, showEditProvider: !state.showEditProvider})}>
                        <Modal.Header>Edit Provider</Modal.Header>
                        <Modal.Content scrolling>
                            <EditProviderProfileForm
                                saveForm={saveForm}
                                deleteProfile={deleteProfile}
                                initialValues={{...state.profileInEdit, ...state.profileInEdit.UdaDrLists[0]}}
                                practicesOptions={practicesOptions || []}
                                cancelForm={() => setStates({
                                    ...state,
                                    showEditProvider: !state.showEditProvider,
                                    profileInEdit: {}
                                })}
                                setOpenOrthoDayRateModal={showOpenOrthoDayRate}
                                setOpenProviderDayRateModal={showOpenProviderDayRateModal}
                                setOpenProviderRampThresholdModal={showOpenProviderRampThresholdModal}
                                setOpenDoctorBaseWorkDaysModal={showDoctorBaseWorkDaysModal}
                            />
                        </Modal.Content>
                    </Modal>
                </>
            }
            <div className="ui card">
                <div className="content">
                    <div className="FreezeTable">
                        <Table className="ui table table-striped table-hover celled unstackable no-wrap">
                            <Table.Header>
                                <Table.Row>
                                    {
                                        HEADERS.map((header, key) =>
                                            <Table.HeaderCell
                                                key={header.label}
                                                style={{color: "#d16f26"}}
                                                className={`cursorPointer ${orderBy === header.value ? 'active' : ''}`}
                                                onClick={() => getSortRows(header.value, order)}
                                            >
                                                <div>{header.label}
                                                    <Icon className={orderBy === header.value ? direction : 'sort'}/>
                                                </div>
                                            </Table.HeaderCell>)
                                    }
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    state.profiles?.map((data: any) => getRows(data))
                                }
                            </Table.Body>
                        </Table>
                    </div>
                    <Pagination
                        defaultActivePage={paginationState.page}
                        totalPages={Math.ceil(paginationState.size / paginationState.limit)}
                        activePage={paginationState.page}
                        onPageChange={(a, b) => setPage(b)}
                    />
                </div>

            </div>
            <Modal size='small' open={state.deleteModalOpen} closeIcon onClose={() => toggleDeleteProfileModalView({})}>
                <Modal.Header>Delete Provider Profile.</Modal.Header>
                <Modal.Content>
                    <p>{`Are you sure you want to delete ${state.profileToDelete && Object.keys(state.profileToDelete).length > 0 ? state.profileToDelete.firstName + " " + state.profileToDelete.lastName + "'s" : ''} provider profile?`}</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button secondary negative onClick={() => toggleDeleteProfileModalView({})}>
                        No, Cancel
                    </Button>
                    <Button primary positive onClick={() => deleteProfile(state.profileToDelete)}>
                        Yes, Proceed
                    </Button>
                </Modal.Actions>
            </Modal>
            {state.currentProfile && Object.keys(state.currentProfile).length > 0 &&
                <WorkHistoryModal
                    UserID={state.currentProfile.id}
                    DocID={state.currentProfile.UserID}
                    DocData={state.currentProfile}
                    openState={openWorkHistoryModal}
                    onClose={handleCloseWorkHistoryModal}/>
            }
            {state.openOrthoDayRateModal && <OrthoDayRateModal
                openOrthoDayRateModal={state.openOrthoDayRateModal}
                name={state.profileInEdit.firstName}
                value={''}
                loaded={0}
                orthoLocation={''}
                index={0}
                dayRates={[]}
                doctorDayRate={[]}
                hideModalODR={true}
                DocID={state.profileInEdit.id}
                DocData={state.profileInEdit}
                providerDayRate={''}
                practiceStipend={''}
                showOpenOrthoDayRate={showOpenOrthoDayRate}
            />}
            {state.openProviderDayRateModal && <ProviderDayRateModal
                openProviderDayRateModal={state.openProviderDayRateModal}
                initialValues={{...state.profileInEdit.UdaDrLists[0]}}
                userId={state.profileInEdit.UserID}
                DocData={state.profileInEdit}
                showOpenProviderDayRateModal={showOpenProviderDayRateModal}
            />
            }
            {state.openProviderRampThresholdModal && <ProviderRampThresholdModal
                openProviderRampThresholdModal={state.openProviderRampThresholdModal}
                initialValues={{...state.profileInEdit.UdaDrLists[0]}}
                userId={state.profileInEdit.UserID}
                DocData={state.profileInEdit}
                showOpenProviderRampThresholdModal={showOpenProviderRampThresholdModal}
            />
            }
            {state.openDoctorBaseWorkDaysModal && <DoctorBaseWorkDaysModal
                openDoctorBaseWorkDaysModal={state.openDoctorBaseWorkDaysModal}
                initialValues={{...state.profileInEdit.UdaDrLists[0]}}
                userId={state.profileInEdit.UserID}
                DocData={state.profileInEdit}
                showDoctorBaseWorkDaysModal={showDoctorBaseWorkDaysModal}
            />
            }
            {state.currUserLogs && Object.keys(state.currUserLogs).length > 0 &&
                <EditHistoryModal
                    auditLogs={state.currUserLogs}
                    openState={openEditHistoryModal}
                    onClose={handleCloseEditHistoryModal}
                    title={"Provider"}/>                
            }
        </Template>

    );
}

export default ProviderProfile