import { ActionType } from "../../model/actionTypes";

export const getUdrPermissions = () => async (dispatch: Function, getState: Function, api: any) => {

    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.get("udr-permissions");
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return [];
    }
};

export const getNotificationGroups = () => async (dispatch: Function, getState: Function, api: any) => {

    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.get('notification-email-groups');
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return [];
    }
};