import React from "react";
import {Button, Modal} from "semantic-ui-react";

function OsRollUpVarianceModel (props: any) {
    const {item,key,brand}=props.variance
    const keys = Object.keys(props.variance.item);
    let actual= keys.includes("Actual Care")?"Actual Care":"Actual CPV";
    actual=keys.includes("Actual Patient Visits") ?'Actual Patient Visits':actual;
    let goal=keys.includes("Goal Care")?"Goal Care":"Goal CPV";
    goal=keys.includes("Goal Patient Visits")?"Goal Patient Visits":goal;
    let sign =" - ";
    let title="Variance";
        if(goal=="Goal Patient Visits"){
            sign= " / "
            title="Patient Show Rate";
        };
    return (
        <Modal className={'modalCenter'}
               onClose={() => props.handleClose()}
               open={true}
        >
            <Modal.Header> {title}
                <Button
                className="float-right"
                content="close"
                primary={true}
                onClick={() => props.handleClose()}
            /></Modal.Header>
            <Modal.Content >
                <Modal.Description>
                    <Button
                        content={"Variance"}
                        color={"green"}
                    /> =
                    <Button
                        content={actual}
                        color={"blue"}
                    /> {sign}
                    <Button
                        content={goal}
                        primary={true}
                    />
                    <table className="ui celled single line table unstackable tableStyle">
                        <thead>
                        <tr ><th colSpan={1} className="header-os">Variance</th><th colSpan={1} className="header-os">{item[key][brand]}</th></tr></thead>
                        <tbody>
                        <tr>
                            <td>{actual}</td> <td>{item[actual][brand]}</td>
                            </tr>
                                <tr>
                            <td>{goal}</td> <td>{item[goal][brand]}</td>
                        </tr>
                        </tbody>
                    </table>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="OK"
                    primary={true}
                    onClick={() => props.handleClose()}
                />
            </Modal.Actions>
        </Modal>
    );
}

export default OsRollUpVarianceModel
