import React, {FunctionComponent, useEffect, useState} from 'react';
import {RootState} from "../model";
import {connect} from 'react-redux';
import {useActions} from "../redux/actions";
import * as practiceActions from "../redux/actions/practice";
import * as reportActions from "../redux/actions/report";
import {DropDownOptions, Practice, PracticeList, Regions} from "../model/practice";
import {Report, ReportTN} from "../model/report";
import * as Actions from "../redux/actions/auth";
import {Dimmer, Loader} from "semantic-ui-react";
import { getTypeOfReport } from './Common';
import PCRFailedModel from "./PCRFailedModel";
import moment from 'moment';
import Loading from '../Loading';

interface Props {
	practice: Practice
	report: Report
	reportFilVal: any
	reportTN: ReportTN
}

const ReportMain:FunctionComponent<Props> = (props) => {
	const [practiceAction, reportAction] = useActions([practiceActions, reportActions]);
	const authActions = useActions(Actions);
	const [loader, setLoader] = useState(false);
	const refinePracticeArray = (response:PracticeList[]) => {
		let practiceAbbr:{[key:string]: string} = {};
		let practiceData: {[key: string]: object} = {};
		let practiceDataInfo: {[key: string]: object} = {};
		let practiceOptions:DropDownOptions[] = [{ key: 'none', value: null, text: 'Select Practice' }];
		if(response.length) {
			response.forEach((item:any) => {
				practiceAbbr[item.practice.toLowerCase()] = item.practiceabbr;
				practiceOptions.push({ text: item.practice, value: item.practice, key: item.id.toString()});
				practiceData[item.practice.toLowerCase()] = {
					practice: item.practice,
					callCenter: item.callCenter,
					practiceDBName: item.practiceDBName
				};
				practiceDataInfo[item.practiceDBName.toLowerCase()] = {
					practice: item.practice,
				}
			})
		}
		return {practiceAbbr: practiceAbbr, practiceOptions: practiceOptions, practiceData, practiceDataInfo}
	};

	const refineRegionsArray = (regionList:Regions[]) => {
		let regionListOptions:DropDownOptions[] = [{ key: '0', value: 0, text: 'Select Region' }];
		if(regionList.length) {
			regionList.forEach(item => {
				regionListOptions.push({ text: item.name, value: item.id, key: item.id })
			});
		}
		return regionListOptions;
	};

	const resetReportData = async() => {
		await reportAction.resetReportData();
	}

	useEffect(() => {
		setLoader(true);
		const lastElementName: string = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);		
		const reportName = getTypeOfReport(lastElementName);
		authActions.Load(); // Send request to check if session is expired or live
		const promiseArr = [];
		practiceAction.fetchPracticeUda();
		const reportTimeName: [] = props.reportTN.reportTimeAndName;
		const currDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
		let minDiff = 0;
		const isNameExist = reportTimeName.length > 0 && reportTimeName.find((i: {time: string, name: string}) => {			
			if(i.name === reportName) {
				const duration = moment.duration(moment(currDateTime).diff( moment(i.time)));
				minDiff = duration.asMinutes();
				return true;
			}

			return false;
		});	
		
		const callReportData = () => {
			reportAction.reportTimeName(reportTimeName, reportName, currDateTime);
			promiseArr.push(reportAction.fetchReportNewData(props.reportFilVal.filterByVal, reportName));
		}

		if (reportName === "bySplashBoardReport") {
			minDiff < 30 && props.reportFilVal.filterByVal && props.reportFilVal.filterByVal.month && callReportData();
		}

		(!isNameExist || minDiff > 30) ? callReportData() : resetReportData();

		if(!props.practice || !props.practice.practiceOptions.length)
			promiseArr.push(practiceAction.fetchPracticesAll(refinePracticeArray));
		if(!props.practice || !props.practice.regionOptions.length)
			promiseArr.push(practiceAction.fetchRegions(refineRegionsArray));

		Promise.all(promiseArr).then((values) => {
			if (!!values[0].response && values[0].response.length > 0 && values[0].type === 'report') {
				reportTimeName.forEach((i: {name: string}) => {
					if(i.name === reportName) {
						Object.assign(i, {reportNewData: values[0].response[0]});
					}
				});
				reportAction.reportTimeName(reportTimeName);
			}
			setLoader (false);
		});

		return () => reportAction.resetReportData();
	}, []);

	return (
		<> {loader &&
			<Dimmer active inverted>
				<Loading />
			</Dimmer>}
			{!loader && !props.report.reportNewData &&<PCRFailedModel/>}
			{props.children}
		</>
	);
};

function mapStateToProps(state: RootState) {
	return {
		practice: state.practice,
		report: state.report,
		reportFilVal:state.reportFilVal,
		reportTN: state.reportTimeAndName
	};
}

export default connect(mapStateToProps)(ReportMain);
