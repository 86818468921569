import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, Router } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { history } from "./redux/configure";
import { User } from "./model/user";
import { Alert, RootState } from "./model";
import 'semantic-ui-css/semantic.css';
import './styles/css/style.css';
import { PMDPracticeContext } from './PMDPracticeContext';
import ReactGA from 'react-ga'
import ReactGA4 from "react-ga4";
import CacheBuster from 'react-cache-buster';
import { version } from './meta.json';

interface Props extends RouteComponentProps<void> {
	auth: User,
	alert: Alert
}

function App(props?: Props) {
	React.useEffect(() => {
		ReactGA.initialize('UA-212282946-1');
		ReactGA4.initialize('G-YW9C9FSK15');
	})

	const isProduction = process.env.NODE_ENV === 'production';

	const [theme, setTheme] = React.useState('');
	if (!props) {
		return null;
	}
	
	return (
		<CacheBuster
			currentVersion={version}
			isEnabled={isProduction} //If false, the library is disabled.
		>
			<PMDPracticeContext.Provider value={{ theme, setTheme }}>
				<Router history={history}>
					<AppRoutes auth={props.auth} alert={props.alert} />
				</Router>
			</PMDPracticeContext.Provider>
		</CacheBuster>
	);
}

function mapStateToProps(state: RootState) {
	return {
		auth: state.auth,
		alert: state.alert
	};
}

export default connect(mapStateToProps)(App);
