import React, {useState} from 'react';
import {Button, Form, Modal,Grid} from "semantic-ui-react";
import {FinancialNoteCategory} from "../model/FinancialNoteCategoryList";

interface FinancialNoteCategoryListProps {
    initialValues : FinancialNoteCategory,
    cancelForm : any,
    saveForm : any,
    isDelete?:any
}

const FinancialNoteCategoryListForm = (props: FinancialNoteCategoryListProps) => {
    const [state, setStates] = useState({
        id: props.initialValues.id,
        name: props.initialValues.name,
        isDeleted: props.initialValues.isDeleted
    });

    const onChange = (e: any, element: any) => {
        const obj: any = {...state}
        obj[element.name] = element.value;
        setStates(obj);
    };

    const onCheckBoxChange = (e: any, element: any) => {
        const obj: any = {...state}
        obj[element.name] = element.checked;
        setStates(obj);
    };

    const saveForm = () => {
        props.saveForm(state);
    };

    const {initialValues, cancelForm} = props;
    return (

        <Modal onClose={() =>cancelForm } open={true}>
            <Modal.Header>{initialValues.id && initialValues.id > 0 ? "Edit " : "Create "}
                Financial Note Category </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form className={'widthFluid'}>
                        <Form.Group widths='equal'>
                            <Grid className={'widthFluid'}>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Input
                                            fluid={true}
                                            label='FINANCIAL NOTE CATEGORY NAME'
                                            placeholder='FINANCIAL NOTE CATEGORY NAME'
                                            defaultValue={state.name}
                                            name={'name'}
                                            onChange={onChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Form.Checkbox
                                            fluid={true}
                                            label='IS DELETED'
                                            checked={state.isDeleted}
                                            name={'isDeleted'}
                                            onChange={onCheckBoxChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form.Group>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button className={'ml15'}
                        primary={true}
                        onClick={saveForm}> Save </Button> &nbsp;
                <Button
                    secondary={true}
                    onClick={cancelForm}> Cancel </Button>
            </Modal.Actions>
        </Modal>

    ) ;
}

export default FinancialNoteCategoryListForm;
