import React, {useState} from "react";
import {MDM} from "../model/mdm";
import {Button, Form, Grid, Icon, Dropdown} from "semantic-ui-react";
import PopUpModal from "./PopUpModal";
import FileUploader from "./FileUploader";
import ShowFilesModal from "./ShowFilesModal";

interface MDMProps {
  onCancel: Function,
  initialValue: MDM,
  saveDetails: Function,
  dataSourceOptions: Array<any>,
  reportOptions: Array<any>
  pages: Array<any>
  pageOptions: Array<any>
  addInfo: Function,
  deleteDataSourcePageReportHandler:Function,
  reports?: Array<any>,
  dataSource?: Array<any>
}

const AddInfo: React.FC<MDMProps> = (props:MDMProps) => {
  const {onCancel, initialValue, saveDetails, dataSourceOptions, reportOptions, pages, pageOptions, addInfo} = props;
  const initialState: any = {
    data: initialValue,
    showFiles : false,
    pageOptions: pageOptions ? pageOptions : [],
    currentField:"Page",
  };
  const [state, setStates] = useState(initialState);
  let fileLink = state.data.fileLink ? state.data.fileLink.split(',') : [];
  
  const formValidation = (obj: any) => {
    let formErrors: any = {};
    Object.keys(obj).forEach((key) => {
      if (key == 'field' && (!obj[key] || obj[key] == '')) { formErrors[key] = 'Field is required!'; }
    });
    return formErrors;
  };
  
  const onSubmit = () => {
    state.data.fileLink = fileLink.join(',');
    const {field} = state.data;
    const formErrors = formValidation({field});
    if (Object.keys(formErrors).length === 0) {
      const data = state.data;
      saveDetails(data);
    }
  };

  const [deletePopUpState, setDeletePopUpState] = useState({showPopUp: false, id: 0,currentField : ""});

  const deleteInfo = () => {
    const { id ,currentField} = deletePopUpState;
    props.deleteDataSourcePageReportHandler(id,currentField);
    setDeletePopUpState({showPopUp:false, id:0,currentField: ""});
  };

  const getTableHeading=()=>{
    return (<thead>
    <tr>
      <th key='key1' className="t-header">
        Action
      </th>
      <th key='key2' className="t-header">
        Name
      </th>
      {(state.currentField === 'Page'||state.currentField === 'Pages')&&
      <th key='key3' className="t-header">
        ReportId
      </th> }
    </tr>
    </thead>)
  }
  const getTableTitle=()=>{
    if (state.currentField.includes('Page')) {
      return "Pages";
    }
    if (state.currentField.includes('Report')) {
      return "Reports";
    }
    return state.currentField;
  }
  const getTableRows = () => {
    const {reports,dataSource} = props;
    let reportRows: React.ReactElement[] = [];
    if (state.currentField.includes('Page')) {
      reportRows=pages.map((page:any)=>{
        return (
          <tr>
           <td>
            <Icon
                name="edit"
                className="mr10"
                onClick={() => {
                  setStates({...state, id:page.id,reportId: page.reportId,name:page.name})
                  addInfo('Add Page',page.id,page.name,page.reportId)
                }
                }
            />
            <Icon
                name="trash alternate"
                onClick={() => setDeletePopUpState({showPopUp:true, id:page.id,currentField:state.currentField })}
            />
          </td>
          <td >{page.name}</td>
          <td colSpan={10}>{page.reportId}</td>
          </tr>)
      })
    } else if (state.currentField.includes('DataSource')||state.currentField.includes('Report')) {
      let currData:any=state.currentField.includes('DataSource')?dataSource:reports
      reportRows=currData.map((curr:any)=>{
        return ( <tr>
          <td>
            <Icon
                name="edit"
                className="mr10"
                onClick={() => {
                  addInfo('Add '+state.currentField,curr.id,curr.name,0)
                }
                }
            />
            <Icon
                name="trash alternate"
                onClick={() => setDeletePopUpState({showPopUp:true, id:curr.id,currentField:state.currentField })}
            />
          </td>
          <td >{curr.name}</td>
        </tr>)
      })
    }
    return <tbody>{reportRows}</tbody>;
  }


  const handleChange = (e:any, name: string) => {
    const data = state.data;
    data[name] = e.target.value;
    setStates({...state, data})
  };
  
  const onChangeFilter = (_e:any, element: any) => {
    const data = state.data;
    data[element.name] = element.value;
    if (element.name === 'report'||element.name === 'page') {
      data[element.name+"Mult"] = element.value;
      data[element.name] = element.value[0];
    }
    if (element.name === 'report') {
      let valuesArr=element.value.map((x:any)=>parseInt(x));
      let pageList = pages.filter((item: any) => valuesArr.includes(parseInt(item.reportId)));
      let pagesDropDown:any = []
      pageList.forEach((item: any) => {
        pagesDropDown.push({text: item.name, value: item.id, id: item.id})
      });
      state.pageOptions = pagesDropDown;
      state.data.page = 0;
    }
    setStates({...state, data})
  };

  const onFinish = async (e: any) => {
    fileLink.push(e.signedUrl.split('?').shift());
  }

  const showFiles = () => {
    setStates({...state, showFiles: true});
  }

  const closeFilesModal = () => {
    setStates({...state, showFiles: false});
  }

  const deleteFiles = async (keys: any) => {
    if (keys.length) {
        keys.forEach((key: any) => {
          if (fileLink[parseInt(key)]) {
            fileLink.splice(parseInt(key), 1);
          }
      });
    }
    setStates({...state, showFiles: false});
  }

  return (
      <div>
    <Grid>
      <Grid.Row>
        <Grid.Column computer={8} tablet={16} mobile={16}>
          <div className="ui card">
            <div className="content">
              <Form className="formStyle">
                <Form.Group widths='equal'>
                  <Form.TextArea
                    fluid={true}
                    label='Field'
                    placeholder='field'
                    required={true}
                    value={state.data.field}
                    onChange={(e) => handleChange(e, 'field')}
                  />
                  <Form.TextArea
                    fluid={true}
                    label='Hex Code'
                    placeholder='hexCode'
                    required={true}
                    value={state.data.hexCode}
                    onChange={(e) => handleChange(e, 'hexCode')}
                  />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.TextArea
                    fluid={true}
                    label='Description'
                    placeholder='description'
                    value={state.data.description}
                    onChange={(e) => handleChange(e, 'description')}
                  />
                  <Form.TextArea
                    fluid={true}
                    label='Formula'
                    placeholder='formula'
                    value={state.data.formula}
                    onChange={(e) => handleChange(e, 'formula')}
                  />
                </Form.Group>
                <Form.Group widths='equal'>
                  <Form.TextArea
                    fluid={true}
                    label='Code'
                    placeholder='code'
                    value={state.data.code}
                    onChange={(e) => handleChange(e, 'code')}
                  />
                  <div className="field">
                    <label>DataSource</label>
                    <Dropdown
                      name="dataSource"
                      placeholder="DataSource"
                      selection={true}
                      options={dataSourceOptions}
                      value={parseInt(state.data.dataSource)}
                      onChange={onChangeFilter}
                    />
                  </div>
                </Form.Group>
                  <Form.Group widths='equal'>
                    <Form.TextArea
                      fluid={true}
                      label='Short Description'
                      placeholder='Short Description'
                      value={state.data.short_desc}
                      onChange={(e) => handleChange(e, 'short_desc')}
                    />
                    <FileUploader
                      fileLinks={fileLink}
                      onFinishUploading={onFinish}
                      showFiles={showFiles}
                    />
                  </Form.Group>
               
                  <div className="two fields">
                    <div className="field">
                      <label>Report</label>
                      <Dropdown
                        name="report"
                        placeholder="report"
                        multiple={true}
                        selection={true}
                        options={reportOptions}
                        onChange={onChangeFilter}
                      />
                    </div>
                  <div className="field">
                    <label>Pages</label>
                    <Dropdown
                      name="page"
                      placeholder="Pages"
                      multiple={true}
                      selection={true}
                      options={state.pageOptions}
                      onChange={onChangeFilter}
                    />
                  </div>
                  </div>
              
                    <Button primary={true} className="mr10" onClick={() => onSubmit()}>Submit</Button>
                    <Button secondary={true} onClick={() => onCancel()}>Cancel</Button>
              </Form>
              <div className="mt20">
                <Button primary={true} className="mr10" onClick={() => addInfo('Add DataSource')}>Add DataSource</Button>
                <Button primary={true} className="mr10" onClick={() => addInfo('Add Report')}>Add Reports</Button>
                <Button primary={true} onClick={() => addInfo('Add Page')}>Add Pages</Button>
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column computer={8} tablet={16} mobile={16}>
          <div className="ui card">
            <div className="content">
              <div className="table-responsive FreezeTable">
                <h3>{getTableTitle()}</h3>
                <table className="ui celled table unstackable">
                  {getTableHeading()}
                  {getTableRows()}
                </table>
              </div>
              <div className="mt20">
                <Button
                  primary={true}
                  className="mr10"
                  onClick={() => {
                  setStates({...state,currentField:"DataSource"});
                }}
                >DataSource
                </Button>
                <Button primary={true} className="mr10" onClick={() =>    setStates({...state,currentField:"Report"})}>Reports</Button>
                <Button primary={true} onClick={() =>    setStates({...state,currentField:"Page"})}>Pages</Button>
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
        {  deletePopUpState.showPopUp &&
        <PopUpModal
            headerText="Delete Selected Record"
            message="Are you sure you want to delete?"
            size='mini'
            action='delete'
            showLabel={true}
            hidePopUpModal={()=>setDeletePopUpState({showPopUp:false, id:0,currentField: ""})}
            deletePopUpHandler={deleteInfo}
        />
        }
        {
          state.showFiles &&
          <ShowFilesModal
            closeModal={closeFilesModal}
            files={fileLink ? fileLink : ""}
            deleteFiles={deleteFiles}
          />
        }
      </div>
  );
};

export default AddInfo;