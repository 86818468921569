import React, {useEffect, useState} from "react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as ReportActions from "../redux/actions/sageAccountList"
import {useActions} from "../redux/actions";
import {Button, Grid, Icon, Message, Search, Table} from "semantic-ui-react";
import _ from "lodash";
import SageAccountListForm from "./SageAccountForm";
import {AdminImpDataSaveUpdMsg, AdminImpDelMsg} from "../constants";
import HelpMark from "./HelpMark";


interface SageAccountListProps {
    sageAccount: any
}

const initialState: any = {
    loading: false,
    cronStatus: 'All',
    sageAccountList: [],
    isEdit: false,
    isActive: "",
    initialValues: {accountNumber: '', accountDescription: '', id: 0,ukg:''},
    orderBy: null,
    order: true,
    search: "",
    hideShow:""
}
const SageAccountList = (_props: SageAccountListProps) => {
    const reportActions = useActions(ReportActions);
    const [state, setStates] = useState(initialState);
    const [addedMsg, setAddedMsg] = useState(false);
    const [deletedMsg, setDeletedMsg] = useState(false);

    useEffect(() => {
        reportActions.getSageAccounts()
            .then((res: any) => {
                setStates({...state, sageAccountList: res});
            });
    }, []);


    const edit = (obj: any, isActive?: any, hideShow?:string) => {
        const initialValues = {
            ...obj
        };
        setStates({...state, isEdit: true, initialValues, isActive: isActive ,hideShow:hideShow});
    };
    const getSortRows = (sortColumn: string, order: string) => {
        const {sageAccountList} = state;
        const orderUpdated = !order;
        const sageAccountListCodesSort = _.orderBy(sageAccountList, [sortColumn], [order ? 'asc' : 'desc'])
        setStates({...state, sageAccountList: sageAccountListCodesSort, orderBy: sortColumn, order: orderUpdated});
    };
    const cancelForm = () => {
        setStates({...state, isEdit: false, initialValues: {accountNumber: '', accountDescription: '', id: 0,ukg:''}});
    };
    const onSearch = (e: any, element: any) => {
        const obj = {...state}
        obj.search = element.value;
        setStates(obj);
    };
    const updateSageAccount = (data: any) => {
        cancelForm();
        const {id} = data;
        const func = id ? (state.isActive ? "deleteSageAccounts" : "updateSageAccount") : 'saveSageAccount';
        reportActions[func](data).then((data: any) => {
            if (data) {
                reportActions.getSageAccounts()
                    .then((res: any) => {
                        id ? setDeletedMsg(true) : setAddedMsg(true);
                        setStates({
                            ...state,
                            sageAccountList: res,
                            isEdit: false,
                            initialValues: {accountNumber: '', accountDescription: '', id: 0,ukg:''}
                        });
                    });
            }

        })
    };
    const {sageAccountList, search, orderBy, order, isEdit} = state;
    const direction = order ? 'sort down' : 'sort up';
    return (
        <Template activeLink="crons-list">
            {(!!addedMsg || !!deletedMsg) &&
                <Message
                    color='green'
                    onDismiss={() => {
                        setAddedMsg(false);
                        setDeletedMsg(false);
                    }}
                    content={state.isActive? AdminImpDelMsg : AdminImpDataSaveUpdMsg}
                />}
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left">Sage UKG Accounts <HelpMark pageId='84'/></h2>
                    <div className="topFilters flex items-end flex-wrap">
                        <Button
                            primary={true}
                            onClick={() => edit({accountNumber: '', accountDescription: '', id: 0,ukg:''})}
                        >Create New
                        </Button>
                        <div>
                            <Search
                                placeholder='Search...'
                                onSearchChange={onSearch}
                                name={'search'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix"/>

            {isEdit &&
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <SageAccountListForm
                                cancelForm={() => cancelForm()}
                                saveForm={(data: any) => updateSageAccount(data)}
                                initialValues={state.initialValues}
                                isActive={state.isActive}
                                hideShow={state.hideShow}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }

            <div className="clearfix"/>
            <div className="ui card">
                <div className="content">
                    <div className="FreezeTable">
                        <Table className="ui table table-striped celled table-hover unstackable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'accountNumber' ? 'active' : ''}`}
                                        onClick={() => getSortRows('accountNumber', order)}
                                    >
                                        <div>ACCOUNT NUMBER
                                            <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/></div>
                                    </Table.HeaderCell>

                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'accountDescription' ? 'active' : ''}`}
                                        onClick={() => getSortRows('accountDescription', order)}
                                    >
                                        <div>ACCOUNT DESCRIPTION
                                            <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/></div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'ukg' ? 'active' : ''}`}
                                        onClick={() => getSortRows('ukg', order)}
                                    >
                                        <div>UKG
                                            <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/></div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>ACTION</div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {sageAccountList.length &&
                                    sageAccountList.filter((item: any) => {
                                        if (!search) {
                                            return true
                                        } else {
                                            const values = Object.values(item).join(" ")
                                            return values.toLowerCase().includes(search.toLowerCase())
                                        }
                                    })
                                        .map((item: any, index: any) => {
                                            return (
                                                <Table.Row key={index}>
                                                    <Table.Cell>{item.accountNumber}</Table.Cell>
                                                    <Table.Cell>{item.accountDescription}</Table.Cell>
                                                    <Table.Cell>{item.ukg}</Table.Cell>
                                                    <Table.Cell>
                                                        <Icon className="cursorPointer" name="edit" onClick={() => edit(item)}/>
                                                        {item.isActive?
                                                        <Button className={"secondary"} onClick={() => edit(item, true,"Hide")}>Hide</Button>:
                                                            <Button className={"primary"} onClick={() => edit(item, true,"Show")}>Show</Button>
                                                        }
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </Template>
    );

}


function mapStateToProps(state: RootState) {
    return {
        sageAccountList: state.sageAccountList,
    };
}

export default connect(mapStateToProps)(SageAccountList);
