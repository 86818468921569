import React from "react";
import {Button, Modal} from "semantic-ui-react";
function DeleteSiteModal(props: any) {
    return(
        <Modal className='aws-modal modalCenter' size="small" onClose={() => props.hideDeleteSiteModal()} open={true}>
            <Modal.Header>
                <div className={'poppins-black'}>Confirm Action?</div>
                <div className="close_modal_link">
                    <a href="javascript:void(0)" onClick={() => props.hideDeleteSiteModal()}>
                        X
                    </a>
                </div>
            </Modal.Header>
            <Modal.Content>
                <p>Please confirm that you want to proceed with the permanent deletion of this record.</p>
                <Button onClick={() => props.deleteSiteHandler()} className='bg-danger primaryColor'>DELETE</Button>
            </Modal.Content>
        </Modal>
    )
}
export default DeleteSiteModal