import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const getTotal = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const response = await api.get(`manual-uploads/pt-count-care/count`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const getBulk = (data = {} as any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    const query = Object.keys(data).map(key => `${key}=${data[key]}`).join('&');
    try {
        const response = await api.get(`manual-uploads/pt-count-care?${query}`);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}


export const saveBulk = (prop: any) => async (dispatch: Function, _getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const response = await api.post(`manual-uploads/pt-count-care`, prop);
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch({type: ActionType.MESSAGE, payload: {successMsg: "PT counts have been updated successfully."}});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const updatePTCount = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const response = await api.put(`manual-uploads/pt-count-care`, data);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(err))
        return [];
    }
}

export const resetMessage = () => async (dispatch: Function, _getState: Function, _api: any) => {
    dispatch({type: ActionType.MESSAGE, payload: {successMsg: null}});
}