import * as React from "react";
import {useState} from "react";
import {Button, Header, Modal} from "semantic-ui-react";
import {useActions} from "../redux/actions";
import * as BudgetActions from "../redux/actions/budget";

interface Props {
  hideModal : any
  parent : any
  searchFilter : any
  accessToken : string
}

function ImportModal(props: Props) {


  const initialState : any = {
    selectedFile: null,
    loaded:0
  }

  const [state, setStates] = useState(initialState);
  const budgetActions = useActions(BudgetActions);

  const checkMimeType = (event: any) => {
    //getting file object
    let files = event.target.files;
    //define message container
    let err = ''
    // list allow mime type
    // const types = ['.csv']
    const types = '.csv';
    // loop access array
    for (var x = 0; x<files.length; x++) {
      // compare file type find doesn't matach
      // if (types.every(type => files[x].type !== type)) {
      if (files[x].name.indexOf(types)<0 && files[x].name.indexOf(".xlsx")<0) {
        // create error message and assign to container
        // err += files[x].type+' is not a supported format '+files[x].name;
        err += files[x].name+' is not a supported format ';
        console.log("files[x]", files[x]);
      }
    }

    if (err !== '') { // if message not same old that mean has error
      event.target.value = null // discard selected file
      console.log(err)
      setStates({ ...state, error: err });
      return false;
    }
    return true;
  };

  const onChangeHandler = (event: any) => {
    if (checkMimeType(event)) {
      // if return true allow to setState
      setStates({ ...state,
        selectedFile: event.target.files[0],
        loaded: 0,
        error: null,
        success: false
      })
    }
  };

  const closeMessage = () => {
    setStates({...state, error: null, success: false });
  };

  const onClickHandler = () => {
    const { parent, accessToken } = props;
    if(!state.selectedFile) {
      setStates({...state, error: "Please upload csv file." });
      return false;
    }
    const data = new FormData()
    data.append('file', state.selectedFile);
    data.append('filename', state.selectedFile.name);

    budgetActions.importPCRbudget(data, parent, accessToken)
      .then((response : any) => {
            setStates({...state, error: null, success: response.data || {}, loaded: 0 });
            setTimeout(closeMessage, 5000);
            if(response.data && (response.data.update > 0 || response.data.newInsert > 0))
              props.searchFilter();
          })
          .catch((error:any) => {
            console.log('error: ', error);
            let errorMessage = (error.response && error.response.data && error.response.data.message) ? error.response.data.message : "File upload failed.";
            setStates({...state, error: errorMessage, success: false, loaded: 0 });
            setTimeout(closeMessage, 5000);
          });
  };

  const { error, success, loaded } = state;

  return (
    <Modal
      open={true}
      onClose={props.hideModal}
      centered={false}
      size='large'
      closeIcon={true}
    >
      <Modal.Header closeButton={true}>
        <Header content="Import Csv"/>
      </Modal.Header>
      <Modal.Content>
        {success && <div className="form-group"><p className="text-success">Data imported successfully. (Total Update={success.update || 0}, Total New={success.newInsert || 0})</p></div>}
        {error && <div className="form-group"><p className="text-danger">{error}</p></div>}
        <div className="form-group">
          <input type="file" name="file" onChange={onChangeHandler}/>
        </div>
        <div className="form-group">
          <Button className={'mt30'} primary={true} onClick={onClickHandler}>Upload</Button>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary={true} onClick={props.hideModal}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ImportModal;
