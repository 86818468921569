import React, { useEffect, useState } from "react";
import { Button, Modal, Dropdown, Grid, Form } from "semantic-ui-react";
import { RootState } from "../model";
import { connect } from "react-redux";
import { useActions } from "../redux/actions";
import * as Actions from "../redux/actions/userSettings";
import { SetupFields } from "../enums/userSetupField";
import { SetupAppName } from "../enums/userSetupAppName";
import * as spotActions from "../redux/actions/spot";
import { validArr } from "../utils/common";

const initialState: any = {
    userSetup: {} as any,
    practices: [],
    practiceOpts: []
}

function DefaultPracticeModal(props: any) {
    const userSettingsActions = useActions(Actions);
    const spotAction = useActions(spotActions);
    const [state, setStates] = useState(initialState);

    useEffect(() => {
        setData();
    }, [])

    const setData = async () => {
        const params = { field: SetupFields.Practice, appId: SetupAppName.CDP_MY };
        const result = await Promise.all([spotAction.fetchPractices(), userSettingsActions.getSetup(params)])

        const options = result[0] ? result[0].map((r: any) => {
            return { text: r.practice, value: r.practiceDBName, key: r.id }
        }) : [];

        const userSettings = result[1] ? result[1] : {};
        const defaultPractices = result[1] ? result[1].value.split(",") : [];

        setStates({ practices: defaultPractices, practiceOpts: options, userSetup: userSettings })
    }

    const resetUserSetup = () => {
        let setup: any = {
            appId: SetupAppName.CDP_MY,
            field: SetupFields.Practice,
            value: 'all',
        };

        if (state.userSetup) 
            setup['id'] = state.userSetup.id;

        userSettingsActions.createSetup(setup).then(() => {
            props.onSaveSetup()
            setStates({...state, practices: []});
        });

    }

    const saveUserSetup = () => {
        let setup: any = {
            appId: SetupAppName.CDP_MY,
            field: SetupFields.Practice,
            value: state.practices.toString(),
        };

        if (state.userSetup) 
            setup['id'] = state.userSetup.id;

        userSettingsActions.createSetup(setup).then(() => {
            props.onSaveSetup()
            setStates({...state, practices: []});
        });
    }

    const onSelectChange = (data: any) => {
        setStates({ ...state, practices: data.value });
    }

    return (
        <>
            <Modal className={'modalCenter'} onClose={() => props.handleClose()} open={true}>
                <Modal.Header content='Default Practice' />
                <Modal.Content>
                    <Form className="formStyle mt10">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <label className="mr10">Select Default Practice</label>
                                    <Dropdown
                                        search={true}
                                        className='mr10'
                                        fluid={true}
                                        placeholder='Select Practice'
                                        selection={true}
                                        multiple={true}
                                        options={state.practiceOpts || []}
                                        onChange={(_event, data) => onSelectChange(data)}
                                        value={state.practices}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="OK"
                        primary={true}
                        disabled={!state.practices.toString()}
                        onClick={() => {
                            saveUserSetup()
                        }}
                    />
                    <Button
                        content="RESET"
                        secondary={true}
                        disabled= { state.userSetup == undefined }
                        onClick={() => { resetUserSetup() }}
                    />
                </Modal.Actions>
            </Modal>

        </>
    );
}

function mapStateToProps(state: RootState) {
    return {
        practice: state.practice,
        patients: state.patients
    };
}

export default connect(mapStateToProps)(DefaultPracticeModal);
