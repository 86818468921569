import React from "react";
import {Button, Header, Modal, Table} from "semantic-ui-react";

interface Props {
    handleClose : Function,
    workReportDetails : any,
    sendDetailsEmail : Function
}



function SpotWorkDetails(props: Props) {
    const { workReportDetails } = props;
    const getTableData = () => {
        return <Table className="ui table table-striped table-hover celled unstackable no-wrap">
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Practice</Table.HeaderCell>
                    <Table.HeaderCell>PatNum</Table.HeaderCell>
                    <Table.HeaderCell>Sub ID</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Result</Table.HeaderCell>
                    <Table.HeaderCell>User</Table.HeaderCell>

                </Table.Row>
            </Table.Header>

            <Table.Body>
                {workReportDetails && workReportDetails.map((item: any) => {
                    return(
                        <Table.Row>
                            <Table.Cell>{item.practice}</Table.Cell>
                            <Table.Cell>{item.patnum}</Table.Cell>
                            <Table.Cell>{item.sid}</Table.Cell>
                            <Table.Cell>{item.createdAt ? item.createdAt.split('T')[0]  : ''}</Table.Cell>
                            <Table.Cell>{item.Result}</Table.Cell>
                            <Table.Cell>{item.firstname +' '+ item.lastname}</Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        </Table>
    }

    return (
        <Modal
            closeIcon={true}
            open={true}
            onClose={() => props.handleClose(false)}
        >
            <Header content='SPOT Work Report Details' />
            <Modal.Content>
                <div className="FreezeTable">
                    {getTableData()}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button negative={true} onClick={() => props.handleClose()}>
                    Close
                </Button>
                <Button positive={true} onClick={() => props.sendDetailsEmail()}>
                    Send Email
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default SpotWorkDetails