import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../model";

import HelpMark from "../HelpMark";
import Template from "../Template";
import { Button, Icon, Input, Modal, Table } from "semantic-ui-react";

import * as ukgActions from "../../redux/actions/UKGServices";
import { useActions } from "../../redux/actions";
import * as issuesActions from "../../redux/actions/downTimeTracker";
import Loading from "../../Loading";
import { numberFormat } from "../numberFormat";
import { nonCurrencyNumberFormat } from "../nonCurrencyNumberFormat";
import { removeUndefinedObjects } from "../../utils/common";

import _ from "lodash";


interface Props {
    user: any
}

function Render(props: Props) {
    const [payrollDetails, setPayrollDetails] = useState({} as any);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowingPayrollDetailsModal, setIsShowingPayrollDetailsModal] = useState(false);
    const [issuesState, setIssuesState] = useState({ appIsDown: false });
    const [order, setOrder] = useState(true);
    const [orderBy, setOrderBy] = useState('');
    const [payroll, setPayroll] = useState({
        "account_id": "",
        "status": false,
        "payroll_data": [],
        "employee_name": ""
    });
    const [searchValue, setSearchValue] = useState('');

    const issuesAction = useActions(issuesActions);
    const ukgAction = useActions(ukgActions);

    useEffect(() => {
        checkIssues();
    }, []);

    const checkIssues = async () => {
        setIsLoading(true);
		const result: any = await issuesAction.fetchOpenIssuesList(["Admin-UKG-Payrolls"]);

        setIsLoading(false);
        setIssuesState({
            ...issuesState,
            appIsDown: result.length > 0 ? true : false
        });
    }

    const handleSearchChange = (event: any, data: any) => {
        setSearchValue(data.value);
    };

    const getSortRows = (sortColumn: string, orderLocal: boolean) => {
        const orderUpdated = !orderLocal;
        const sortFn = (item: any) => {
          switch (sortColumn) {
            case 'name':
              return item.name.toLowerCase();
            case 'company':
              return item.ein.ein_name.toLowerCase();
            case 'startdate':
              return new Date(item.start_date.toLowerCase());
            case 'enddate':
              return new Date(item.end_date.toLowerCase());
            case 'paydate':
              return new Date(item.pay_date.toLowerCase());
            default:
              return item[sortColumn];
          }
        };
      
        const sortedData:any = _.orderBy(payroll.payroll_data, sortFn, [orderLocal ? 'asc' : 'desc']);
        payroll.payroll_data = sortedData;
        setOrder(orderUpdated);
        setOrderBy(sortColumn);
        setPayroll(payroll);
      };

    const onClickSearch = () => {

        setIsLoading(true);

        ukgAction.getEmployeePayrolls(searchValue)
            .then((results: any) => {

                setIsLoading(false);
                setPayroll(results);
            })
            .catch((error: any) => {

                setIsLoading(false);
                setPayroll({
                    "account_id": "",
                    "status": false,
                    "payroll_data": [],
                    "employee_name": ""
                });
            });
    }

    const sendCSV = () => {
        setIsLoading(true);
        const payStatementsData = payrollDetails != null ? payrollDetails.payroll_statement !== null ? payrollDetails.payroll_statement : { earnings: [] } : { earnings: [] };
        const employeeName = payroll?.employee_name ?? ".";
        const payStatementsTableData = removeUndefinedObjects(payStatementsData.earnings?.map((payroll: any) => ({
            id: payroll.id || '',
            base_rate: nonCurrencyNumberFormat(payroll.base_rate || 0),
            hours: nonCurrencyNumberFormat(payroll.hours || 0),
            ee_amount: numberFormat(payroll.ee_amount || 0),
            pay_date: payStatementsData.pay_date || '',
            date_range_from: payroll.date_range_from || '',
            date_range_to: payroll.date_range_to || ''
        })));
        const totalEeAmount = payStatementsTableData?.reduce(
            (total: any, row: any) => {
                const eeAmount = row?.ee_amount ? parseFloat(row.ee_amount.replace(/[$,]/g, "")) : 0;
                return isNaN(eeAmount) ? NaN : total + eeAmount;
            },
            0
        );

        const totalHours = nonCurrencyNumberFormat(payStatementsTableData?.reduce(
            (total: any, row: any) => {
                const hours = row?.hours ? parseFloat(row.hours.replace(/[$,]/g, "")) : 0;
                return isNaN(hours) ? NaN : total + hours;
            },
            0
        ));
        const accountId = payrollDetails?.account_id ?? ".";

        ukgAction.sendMailPayStatement({ data: payStatementsTableData, accountId: accountId || ".", employeeName: employeeName || ".", sum: numberFormat(totalEeAmount), totalHours });
        setIsLoading(false);
        setIsShowingPayrollDetailsModal(false)
    }

    const onClickPayroll = (selectedPayroll: any) => {

        setIsLoading(true);

        ukgAction.getEmployeePayroll(selectedPayroll.id, payroll.account_id)
            .then((results: any) => {

                setPayrollDetails(results);
                setIsLoading(false);
                setIsShowingPayrollDetailsModal(true);
            })
            .catch((error: any) => {

                setPayrollDetails([]);
                setIsLoading(false);
            });
    }

    const getPayrollsTableBody = () => {
        const payroll_data = payroll?.payroll_data || [];

        if (payroll_data.length > 0) {
            return payroll_data.map((payroll: any) => (
                <Table.Row key={payroll.id || 0}>
                    <Table.Cell>{`${payroll.name || ''}`}</Table.Cell>
                    <Table.Cell>{payroll.ein.ein_name || ''}</Table.Cell>
                    <Table.Cell>{payroll.start_date || ''}</Table.Cell>
                    <Table.Cell>{payroll.end_date || ''}</Table.Cell>
                    <Table.Cell>{payroll.pay_date || ''}</Table.Cell>
                    <Table.Cell>
                        <Button
                            primary={true}
                            onClick={() => onClickPayroll(payroll)}
                        >
                            View Details
                        </Button>
                    </Table.Cell>
                </Table.Row>
            ));
        } else {
            return (
                <Table.Row>
                    <Table.Cell colSpan={6}>No records found</Table.Cell>
                </Table.Row>
            );
        }
    };

    const enableSendEmail = () => {
        let hasPayrollData: boolean = false;

        if (payrollDetails?.payroll_statement) {
            hasPayrollData = payrollDetails.payroll_statement.earnings?.length > 0;
        }
        if (hasPayrollData) {
            return (
                <Button onClick={() => sendCSV()} inverted color="orange">
                    Send Mail
                </Button>
            );
        }
    }

    const getPayrollDetailsTableBody = () => {
        let hasPayrollData: boolean = false;

        if (payrollDetails?.payroll_statement) {
            hasPayrollData = payrollDetails.payroll_statement.earnings?.length > 0;
        }
        if (hasPayrollData) {
            return payrollDetails.payroll_statement.earnings?.map((payroll: any) => (
                <Table.Row key={payroll.id || 0}>
                    <Table.Cell>{`${payroll.id || ''}`}</Table.Cell>
                    <Table.Cell>{`${nonCurrencyNumberFormat(payroll.base_rate || 0)}`}</Table.Cell>
                    <Table.Cell>{`${nonCurrencyNumberFormat(payroll.hours || 0)}`}</Table.Cell>
                    <Table.Cell>{numberFormat(payroll.ee_amount || 0)}</Table.Cell>
                    <Table.Cell>{payrollDetails.payroll_statement.pay_date || ''}</Table.Cell>
                    <Table.Cell>{payroll.date_range_from || ''}</Table.Cell>
                    <Table.Cell>{payroll.date_range_to || ''}</Table.Cell>
                </Table.Row>
            ));
        } else {
            return (
                <Table.Row>
                    <Table.Cell colSpan={7}>No records found</Table.Cell>
                </Table.Row>
            );
        }
    };

    function showPayrollDetailsModal() {

        return (
            <Modal
                open={true}
                className='modalCenter'
                closeIcon={true}
                onClose={() => setIsShowingPayrollDetailsModal(false)}
            >
                <Modal.Header>
                    Payroll Details
                </Modal.Header>

                <Modal.Content>
                    <Table className="table table-striped table-hover my-0 FreezeTable sortable">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    ID
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Base Rate
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Hours
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    EE Amount(Gross)
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Pay Date
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Period Start Date
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    Period End Date
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                getPayrollDetailsTableBody()
                            }
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    {enableSendEmail()}
                </Modal.Actions>
            </Modal>
        )
    }

    const direction = order ? 'sort down' : 'sort up';

    return (
        <Template activeLink='payrolls'>
            {
                isLoading
                && <Loading />
            }

            {
                isShowingPayrollDetailsModal
                && showPayrollDetailsModal()
            }

            <div className="ui card">
            {props.user && (props.user.role && props.user.role.indexOf('ukg') >= 0)?
                <div className="content pb0">
                    <h2 className="float-left mr10"> Payroll List<HelpMark pageId="96" appIsDown={{status:issuesState.appIsDown, appsDown:["Admin-UKG-Payrolls"]}}/>
                    </h2>
                    <div className="topFilters flex items-end flex-wrap">
                    <Input
                        icon={<Icon name="search" />}
                        placeholder="Employee ID..."
                        value={searchValue}
                        onChange={handleSearchChange}
                    />
                        <Button
                            primary={true}
                            onClick={() => onClickSearch()}
                        >
                            Search
                        </Button>
                    </div>
                </div>
            :""}
            </div>

            <div className="ui card">
                {props.user && (props.user.role && props.user.role.indexOf('ukg') >= 0) ?
                    <div className="content">
                        <Table className="table table-striped table-hover my-0 FreezeTable sortable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'name' ? 'active' : ''}`}
                                        onClick={() => getSortRows('name', order)} 
                                    >
                                        <div>
                                            Payroll Name
                                            <Icon className={orderBy === 'name' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'company' ? 'active' : ''}`}
                                        onClick={() => getSortRows('company', order)} 
                                    >
                                        <div>
                                            Company
                                            <Icon className={orderBy === 'company' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'startdate' ? 'active' : ''}`}
                                        onClick={() => getSortRows('startdate', order)} 
                                    >
                                        <div>
                                            Start Date
                                            <Icon className={orderBy === 'startdate' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'enddate' ? 'active' : ''}`}
                                        onClick={() => getSortRows('enddate', order)} 
                                    >
                                        <div>
                                            End Date
                                            <Icon className={orderBy === 'enddate' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'paydate' ? 'active' : ''}`}
                                        onClick={() => getSortRows('paydate', order)} 
                                    >
                                        <div>
                                            Pay Date
                                            <Icon className={orderBy === 'paydate' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        Action
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    getPayrollsTableBody()
                                }
                            </Table.Body>
                        </Table>
                    </div>
                    : ""}
            </div>
        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user
    };
}

export default connect(mapStateToProps)(Render);