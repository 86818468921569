import React, {useState} from 'react';
import {Button, Checkbox, CheckboxProps, Grid, Modal} from 'semantic-ui-react';

interface Props{
    files:any;
    closeModal:any;
    deleteFiles:any;
};
export default function ShowFilesModal(props:Props) {
  const initialState  = {
    deleteKeys: [] as string[]
  }
  const [state, setState] = useState(initialState);

    const selectFiles = (e: any, data: CheckboxProps) => {
        let deleteKeys: string[] = state.deleteKeys;
        const name: any = data.name;
        if (deleteKeys.includes(name))
            deleteKeys.splice(deleteKeys.indexOf(name), 1);
        else
            deleteKeys.push(name);
        setState({...state, deleteKeys: deleteKeys});
    }

    const  files:any = props.files;
    const closeModal:any =props.closeModal;
    const deleteKeys = state.deleteKeys;

    return (
      <Modal
          open={true}
          onClose={closeModal}
          aria-labelledby="ModalHeader"
          centered={false}
          style={{overflow: 'auto'}}
          closeIcon
          size="large"
      >
        <Modal.Header>
            Uploaded Files
        </Modal.Header>
      <Modal.Content scrolling>
          <Grid>
              <Grid.Row>
                  {
                      files && files.map((file: any, key: any) => {
                          return (
                              <Grid.Column key={key} width={4}>
                                  <Checkbox name={`${key}`} checked={deleteKeys.includes(key.toString())} onChange={selectFiles}/>
                                  <br/>
                                  {(file.indexOf(".mp4") > 0) ?
                                      <video className="w-100 help-files-video" controls>
                                          <source src={file} type="video/mp4"/>
                                          Your browser does not support the video tag.
                                      </video>
                                      : <iframe title={`pdf-${key}`} className="w-100 help-files-pdf" src={file}/>
                                  }

                              </Grid.Column>)
                      })
                  }
              </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={closeModal}>Close</Button>
            <Button color='red' className='ml20' onClick={() => props.deleteFiles(deleteKeys)}>
                Delete {deleteKeys && deleteKeys.length} Selected
            </Button>
        </Modal.Actions>
      </Modal>
    );

}
