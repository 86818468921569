import React, {useEffect, useState} from "react";
import Template from "../Template";
import { Button, Card, Table, Input} from "semantic-ui-react";
import "react-datepicker/dist/react-datepicker.css";
import {useActions} from "../../redux/actions";
import * as providerActions from "../../redux/actions/providerAccounts"
import CreateEditModal from "./CreateEditModal";
import DeleteConfirm from "./DeleteConfirm";
import {RootState} from "../../model";
import {connect} from "react-redux";
import {User} from "../../model/user";
import {Practice} from "../../model/practice";

interface Props {
    practices: [],
    providers : []
}

function ProviderAccounts(props : Props ) {

    const initialState:any = {
        mco:'',
        deleteProviderId:0,
        practiceOptions:[]
    }
    let srno=1;

    const prActions = useActions(providerActions)
    const [state, setStates]=useState(initialState);
    const [isModalCreateVisible, setIsModalCreateVisible]=useState(false)
    const [createProviderValues , setInitProviderValues]=useState({})
    const [isDeleteModal , setIsDeleteModal]=useState(false);

    useEffect(()=>{
        prActions.getProviders().then((results:{practices:object , providers:object []})=>{
            const pracOptions:object []=[]
            Object.keys(results.practices).forEach((prac:string,idx:number)=>{
                pracOptions.push({key:idx , value:prac , text:prac});
            })
            setStates({...state,mco:"MCNA",practiceOptions: pracOptions});
        })

    },[])

    const handleTabChange=(name:string)=>{
        setStates({...state,mco:name})
        srno=1;
    }

    const handleOpenModal =()=>{
        setIsModalCreateVisible(true)
    }

    const closeModal=()=>{
        setIsModalCreateVisible(false)
        setInitProviderValues({});
    }

    const saveProviderDetails=(providerData:any)=> {
        let provObj={}
        if (providerData.lastdateused !== undefined && providerData.intervention !== undefined ) {
            provObj = {
                lastdateused: providerData.lastdateused.toString(),
                intervention: providerData.intervention.toString()
            };
        }

        const providerInfo=Object.assign(providerData, provObj);

        prActions.saveProvider(providerInfo).then(()=>{
            closeModal();
        });
    }

    const handleDelete=(id:number)=>{
       setIsDeleteModal(true);
       setStates({...state , deleteProviderId:id})
    }

    const closeDelete =()=>{
        setIsDeleteModal(false);
        setStates({...state , deleteProviderId:0})
    }

    const deleteProvider=()=>{
        const idObj={id:state.deleteProviderId}
        prActions.deleteProvider(idObj).then(()=>{
              closeDelete();
        });
    }

    const handleEdit =(idx:number) =>{
        setInitProviderValues(props.providers[idx])
        setIsModalCreateVisible(true)

    }

    const getTableBody = () => {
        const {providers} = props;
        const rows: any = [];
        providers && providers.length && providers.map((provider:any,index:number)=> {
                if (provider.mco === state.mco || provider.mco.endsWith('-'+state.mco)) {
                    rows.push(<Table.Row key={provider.id}>
                        <Table.Cell>{srno++}</Table.Cell>
                        <Table.Cell>{provider.mco}</Table.Cell>
                        <Table.Cell>{provider.brand}</Table.Cell>
                        <Table.Cell>{provider.username}</Table.Cell>
                        <Table.Cell>{provider.password}</Table.Cell>
                        <Table.Cell>{provider.practice}</Table.Cell>
                        <Table.Cell>{provider.practiceabbr}</Table.Cell>
                        <Table.Cell>{provider.serviceLocation}</Table.Cell>
                        <Table.Cell>{provider.billingEntity}</Table.Cell>
                        <Table.Cell>{provider.provider}</Table.Cell>
                        {
                            (state.mco==='MCNA' || state.mco==='DENTA' || state.mco==='TMHP' || state.mco==='SPORE'|| state.mco==='DENTAL HUB' || state.mco==='DentalXChange')&&
                            <>
                                <Table.Cell>{provider.facilityId}</Table.Cell>
                                <Table.Cell>{provider.lastdateused == 1?<Input type="checkbox" checked={true} disabled={true}/>
                                    :<Input  type="checkbox" checked={false} disabled={true}/>}</Table.Cell>
                                <Table.Cell>{provider.newSiteScrapper?<Input type="checkbox" checked={true} disabled={true}/>
                                    :<Input  type="checkbox" checked={false} disabled={true}/>}</Table.Cell>
                                <Table.Cell>{provider.intervention == 1?<Input type="checkbox" checked={true} disabled={true}/>
                                    :<Input  type="checkbox" checked={false} disabled={true}/>}</Table.Cell>
                            </>
                        }
                        <Table.Cell><a onClick={()=>handleEdit(index)}>Edit</a> <span/>/ <a onClick={()=>handleDelete(provider.id)}>Delete</a></Table.Cell>
                        {
                            (state.mco==='MCNA' || state.mco==='DENTA')&&
                            <>
                                <Table.Cell>{provider.passwordResetDate?provider.passwordResetDate.toLocaleDateString():''}</Table.Cell>
                                <Table.Cell>{}</Table.Cell>
                            </>
                        }
                        {
                            (state.mco == 'MCNA' || state.mco == 'DENTA' || state.mco == 'UNITED HEALTH CARE' || state.mco == 'DentalXChange')&&
                            <Table.Cell><Button secondary={true}>
                                {state.mco === 'MCNA' && <a href='https://portal.mcna.net/login/index' target="_blank">Launch</a>}
                                {state.mco === 'DENTA'&& provider.newSiteScrapper && <a href='https://connectsso.dentaquest.com/provideraccessv2/index.html' target="_blank">Launch</a>}
                                {state.mco === 'DENTA' && !provider.newSiteScrapper&& <a href='https://connectsso.dentaquest.com/provideraccessv2/index.html' target="_blank">Launch</a>}
                                {state.mco === 'UNITED HEALTH CARE' && <a href='https://dentaltx.uhc.com/content/texas-medicaid/en/homepage.html' target="_blank">Launch</a>}
                                {state.mco === 'DentalXChange' && <a href='https://www.emdeondental.com/' target="_blank">Launch</a>}
                            </Button>
                            </Table.Cell>

                        }
                    </Table.Row>)
                }
            })
        return rows;
    }

    return (
        <Template activeLink='logins'>
            <div className="ui card">
                <div className="content">
                    <h2 className="float-left mr10"> Provider Accounts </h2><br/>
                    <Button onClick={()=>handleOpenModal()}>Add New</Button>
                </div>
            </div>
            <Card>
                <Card.Content>
                    <ul className="providersTab nav nav-tabs">
                        <li className={state.mco === "MCNA" ? "active" : ""} onClick={() => handleTabChange("MCNA")}>
                            <a href='#mcna'>MCNA</a>
                        </li>
                        <li className={state.mco === "DENTA" ? "active" : ""} onClick={() => handleTabChange("DENTA")}>
                            <a href='#denta'>DENTA</a>
                        </li>
                        <li className={state.mco === "TMHP" ? "active" : ""} onClick={() => handleTabChange("TMHP")}>
                            <a href='#tmhp'>TMHP</a>
                        </li>
                        <li className={state.mco === "SPORE" ? "active" : ""} onClick={() => handleTabChange("SPORE")}>
                            <a href='#SPORE'>SPORE</a>
                        </li>
                        <li className={state.mco === "DENTAL HUB" ? "active" : ""} onClick={() => handleTabChange("DENTAL HUB")}>
                            <a href='#dentalhub'>UHC</a>
                        </li>
                        <li className={state.mco === "masterAccount" ? "active" : ""} onClick={() => handleTabChange("masterAccount")}>
                            <a href='#masterAccount'>Master Account</a>
                        </li>
                        <li className={state.mco === "DentalXChange" ? "active" : ""} onClick={() => handleTabChange("DentalXChange")}>
                            <a href='#DentalXChange'>DentalXChange</a>
                        </li>
                    </ul>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell>Srno.</Table.HeaderCell>
                            <Table.HeaderCell>MCO</Table.HeaderCell>
                            <Table.HeaderCell>Brand</Table.HeaderCell>
                            <Table.HeaderCell>Username</Table.HeaderCell>
                            <Table.HeaderCell>Password</Table.HeaderCell>
                            <Table.HeaderCell>Practice</Table.HeaderCell>
                            <Table.HeaderCell>Practice Abbr</Table.HeaderCell>
                            <Table.HeaderCell>Service Location</Table.HeaderCell>
                            <Table.HeaderCell>Billing Entity</Table.HeaderCell>
                            <Table.HeaderCell>Provider Name</Table.HeaderCell>
                            {
                                (state.mco==='MCNA' || state.mco==='DENTA' || state.mco==='TMHP' || state.mco==='SPORE'|| state.mco==='DENTAL HUB' || state.mco==='DentalXChange')&&
                                <>
                                    <Table.HeaderCell>Facility Id</Table.HeaderCell>
                                    <Table.HeaderCell>Last Date Used</Table.HeaderCell>
                                    <Table.HeaderCell>New Site Scrapper</Table.HeaderCell>
                                    <Table.HeaderCell>Intervention</Table.HeaderCell>
                                </>
                            }
                            <Table.HeaderCell>Operation</Table.HeaderCell>
                            {
                                (state.mco==='MCNA' || state.mco==='DENTA' ||state.mco == 'UNITED HEALTH CARE'|| state.mco==='DENTAL HUB' || state.mco==='DentalXChange')&&
                                <>
                                    <Table.HeaderCell>Pw change Date</Table.HeaderCell>
                                    <Table.HeaderCell>Days Remaining Pw expired</Table.HeaderCell>
                                    <Table.HeaderCell>Launch</Table.HeaderCell>
                                </>
                            }
                            </Table.Header>
                        <Table.Body>
                            {getTableBody()}
                        </Table.Body>
                    </Table>
                    {isDeleteModal && <DeleteConfirm cancel={closeDelete} confirm={deleteProvider}/>}
                    {isModalCreateVisible &&
                        <CreateEditModal
                            initialValues={createProviderValues}
                            saveForm={saveProviderDetails}
                            cancelForm={closeModal}
                            practiceOptions={state.practiceOptions}
                            practices={props.practices}
                            selectedMCO={state.mco}
                        />}
                </Card.Content>

            </Card>


        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        practices: state.providerAccountList.practices,
        providers : state.providerAccountList.providers
    };
}

export default connect(mapStateToProps)(ProviderAccounts)
