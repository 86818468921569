import React from "react";
import DynamicHtmlTable from "../../components/DynamicHtmlTable";
import {ReportData} from "../../model/report";
import ReactDOMServer from "react-dom/server";

interface Props {
	data: null|ReportData,
	page: string,
	as?: string
}

function RenderHtml(props:Props) {
	const data = props.data;
	if(data && data.tableData && Object.keys(data.tableData).length) {
		const returnArray = Object.keys(data.tableData).map(key => {
			const response = <DynamicHtmlTable
				key={key}
				className={`by-${props.page} ${key}`}
				tableHead={data.tableHead}
				heading={data.tableData[key].heading}
				tableData={data.tableData[key].tableData}
			/>;
			return (props.as && props.as === 'string') ? ReactDOMServer.renderToString(response) : response;
		});
		return <div> {(props.as && props.as === 'string') ? returnArray.join("") : returnArray}</div>;

	} else {
		return <></>;
	}

}
export default RenderHtml;
