import React, { useEffect, useState } from 'react';
import { AccordionTitle, AccordionContent, Icon, Grid, GridRow, GridColumn, Button, ButtonGroup, Loader, Progress } from 'semantic-ui-react'
import * as xrayActions from "../../../redux/actions/xrayService";
import * as practiceActions from "../../../redux/actions/practice";
import { useActions } from '../../../redux/actions';
import { XrayServiceStatus } from '../../../model/xrayServiceStatus';
import * as HealthStatusActions from "../../../redux/actions/healthStatus";
import moment from 'moment';
import XrayHealthTable from './XrayHealthTable';
import ProgressBar from '../ProgressBar';
const _ = require('underscore');

function XrayServiceHealth() {
    const initialState = { loading: false, data: [] as any[], panel: '', counts: { success: 0, failed: 0, pending: 0 }, records: [] as any[] };
    const xrayAction = useActions(xrayActions);
    const practiceAction = useActions(practiceActions)
    const healthStatusActions = useActions(HealthStatusActions);
    const [state, setState] = useState(initialState);
    const { data, loading, panel, records, counts } = state;
    const prefix = 'AptoodConfig'

    const load = async (allPractices: any[]) => {
        const practices = allPractices.filter(p => p.xrayService);
        let statuses = await xrayAction.fetchXrayStatus();
        const info: any[] = practices
            .map(practice => {
                const status: XrayServiceStatus = statuses.filter((status: any) =>
                    status.directory.toLowerCase() === practice.practiceDBName.toLowerCase())[0];
                if (!status) return practice;
                status.status._isRunning = status.status.IsRunning;
                status.status.daysLastRan = '+100';
                const lastDateProcessed = status.status && moment(status.status.DateProcessed);
                if (lastDateProcessed)
                    status.status.daysLastRan = moment(new Date()).diff(lastDateProcessed, 'days');
                status.status.IsRunning = status.status._isRunning && status.status.daysLastRan < 3;
                return { practice, ...status };
            })
        const failedCount = info.filter(f => !f.status.IsRunning).length;
        const stats = { success: info.length - failedCount, failed: failedCount, pending: 0 };
        healthStatusActions.addToCount(stats).then();
        setState({ ...state, data: info, counts: stats, records: info, loading: false, });
    }

    useEffect(() => {
        setState({ ...state, loading: true })
        practiceAction.fetchPractices(load)
    }, []);

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }

    const viewRecords = (failed: boolean) => {
        if (failed)
            setState({ ...state, records: data.filter(d => !d.status.IsRunning) })
        else
            setState({ ...state, records: data.filter(d => d.status.IsRunning) })
    }

    return (
        <>
            <AccordionTitle active={state.panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>XRay Services</span>
                            {loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={counts.failed} pending={counts.pending} success={counts.success} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === prefix}>
                {!loading && <Grid>
                    <GridRow>
                        <GridColumn width={16}>
                            <ButtonGroup >
                                <Button onClick={() => viewRecords(false)} icon='folder outline' color='green' content={`Passed ${(counts.success)}`} />
                                <Button onClick={() => viewRecords(true)} labelPosition='right' icon='folder outline' color='red' content={`Failed ${(counts.failed)}`} />
                            </ButtonGroup>
                            <XrayHealthTable data={records} />
                        </GridColumn>
                    </GridRow>
                </Grid>}
            </AccordionContent>
        </>
    );
}

export default XrayServiceHealth;