import React, {useEffect, useState} from 'react';
import Template from "../../components/Template";
import {Button, Checkbox, Icon, Input, SemanticCOLORS} from "semantic-ui-react";
import {Report, UserMatrix} from "../../model/report";
import moment from "moment";
import {Account, User} from "../../model/user";
import {connect} from "react-redux";
import {RootState} from "../../model";
import {useActions} from "../../redux/actions";
import * as reportActions from "../../redux/actions/report";
import DynamicHtmlTable from "../../components/DynamicHtmlTable";
import _ from "lodash";
import {allowDeleteUserId} from "../../constants";
import {exportUsermatrixReport} from "../../components/ExportCsv";
import HelpMark from "../../components/HelpMark";
const filterMoment = moment();
const filterformat = 'YYYY-MM-DD HH:mm:ss';

interface Props {
  user: Account,
  report: Report
}

function UserMatrixReport(props: Props) {
  const reportAction = useActions(reportActions);
  const initialState: UserMatrix = {
    date: filterMoment.format(filterformat),
    currentDate: filterMoment.format(filterformat),
    TableData: [[{title: 'No Records Found'}]],
    TableHead: [{title: ''}],
    column: -1,
    direction: 'sort',
    showHidden : false,
    data:  [],
    searchKeyword: ''
  };
  let timeout: any = 0;
  const [state, setStates] = useState(initialState);
  const user = props.user;
  const allowDelete = (user && allowDeleteUserId.indexOf(user.id) >= 0)
  useEffect(() => {
    let startDate = filterMoment.startOf('day').format(filterformat)
    let endDate = filterMoment.endOf('day').format(filterformat)
    reportAction.fetchUserMatrix({startDate, endDate}).then((res: any) => {
      
      const TableHead = TableHeadData();
      const checked = state.showHidden ? 1 : 0;
      const filterData = res.filter((item: any) => item.hideUsage === checked);
      const TableData = getTableData(filterData);
      setStates({...state, TableHead, TableData, data: res})
    })
  }, []);
  
  const myDate = (date: string, format: string) => {
    return moment(date).format( 'MMMM-YYYY');
  };
  
  const TableHeadData = () => {
    let TableHead = new Array();
      TableHead[0] = {title: 'Hide'};
      TableHead[1] = {title: 'First Name'};
      TableHead[2] = {title: 'Last Name'};
      TableHead[3] = {title: 'Email Address'};
      TableHead[4] = {title: 'my'};
      TableHead[5] = {title: 'sites'};
      TableHead[6] = {title: 'schedule'};
      TableHead[7] = {title: 'recruits'};
      TableHead[8] = {title: 'alerts'};
      TableHead[9] = {title: 'leads'};
      TableHead[10] = {title: 'udr'};
      TableHead[11] = {title: 'five9'};
      TableHead[12] = {title: 'OS PCR'};
      TableHead[13] = {title: 'MDM'};
      TableHead[14] = {title: 'CSR'};
      TableHead[15] = {title: 'PCR'};
      TableHead[16] = {title: 'Adjustment'};
      TableHead[17] = {title: 'PT Validation'};
      TableHead[18] = {title: 'PM Dashboard'};
    
    return TableHead;
  };
  
  const onSearchChange = (e: any) => {
    const searchKeyword = e.target.value;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      const data = state.data && state.data.filter((element: any) => {
        return ((element.firstName && element.firstName.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0)
          || (element.lastName && element.lastName.toLowerCase().indexOf(searchKeyword.toLowerCase()) >= 0)
        )
      });
      let TableData;
      if(!state.showHidden) {
        const checked = state.showHidden ? 1 : 0;
        const filterData = data.filter((item: any) => item.hideUsage === checked);
        TableData = getTableData(filterData);
      } else {
        TableData = getTableData(data);
      }
      setStates({...state, searchKeyword: searchKeyword, TableData: TableData});
      clearTimeout(timeout);
    }, 500);
  };
  
  const handleCheckBox = (hideUsage: any, userId: number) => {
    reportAction.hideUsage(userId, +hideUsage)
      .then((res:any) => {
        let startDate = moment(state.date).subtract(1, 'month').startOf('month').format(filterformat)
        let endDate = moment(state.date).subtract(1, 'month').endOf('month').format(filterformat)
        reportAction.fetchUserMatrix({startDate, endDate}).then((res: any) => {
          const TableHead = TableHeadData();
          let TableData;
          if(!state.showHidden) {
            const checked = state.showHidden ? 1 : 0;
            const filterData = state.data.filter((item: any) => item.hideUsage === checked);
            TableData = getTableData(filterData);
          } else {
            TableData = getTableData(res);
          }
          setStates({...state, TableHead, TableData, data: res})
        })
      });
  };
  
  const getTableData = (data: any) => {
    let TableData =new Array();
    let totalMy = 0, totalSites = 0, totalSchedule = 0, totalRecruits = 0, totalAlert = 0, totalLeads = 0, totalUdr = 0;
    let five9Total = 0, os_pcrTotal = 0, mdmTotal= 0, csrTotal = 0, pcrTotal = 0, adjustmentTotal = 0, patientValidationTotal = 0, pmDashboardTotal = 0 ;
    data.forEach((row: any) => {
      totalMy +=  row.my ? parseInt(row.my) : 0;
      totalSites +=  row.sites ? parseInt(row.sites) : 0;
      totalSchedule +=  row.schedule ? parseInt(row.schedule) : 0;
      totalRecruits +=  row.recruits ? parseInt(row.recruits) : 0;
      totalAlert +=  row.alert ? parseInt(row.alert) : 0;
      totalLeads +=  row.leads ? parseInt(row.leads) : 0;
      totalUdr +=  row.udr ? parseInt(row.udr) : 0;
      five9Total += row.five9 ? parseInt(row.five9) : 0;
      os_pcrTotal += row.os_pcr ? parseInt(row.os_pcr) : 0;
      mdmTotal += row.mdm ? parseInt(row.mdm) : 0;
      csrTotal += row.csr ? parseInt(row.csr) : 0;
      pcrTotal += row.pcr ? parseInt(row.pcr) : 0;
      adjustmentTotal += row.adjustment ? parseInt(row.adjustment) : 0;
      patientValidationTotal += row.patientValidation ? parseInt(row.patientValidation) : 0;
      pmDashboardTotal += row.pmDashboard ? parseInt(row.pmDashboard) : 0;

      TableData.push([
        {
          title: <Checkbox
            onChange={(e, {checked}) => handleCheckBox(checked, row.userId)}
            checked={row.hideUsage}
          />
        },
        {title: row.firstName},
        {title: row.lastName},
        {title: row.email ? row.email : ''},
        {title: row.my ? parseInt(row.my) : 0},
        {title: row.sites ? parseInt(row.sites) : 0},
        {title: row.schedule ? parseInt(row.schedule) : 0},
        {title: row.recruits ? parseInt(row.recruits): 0},
        {title: row.alert ? parseInt(row.alert) : 0},
        {title: row.leads ? parseInt(row.leads) : 0},
        {title: row.udr ? parseInt(row.udr) : 0},
        {title: row.five9 ? parseInt(row.five9) : 0},
        {title: row.os_pcr ? parseInt(row.os_pcr) : 0},
        {title: row.mdm ? parseInt(row.mdm) : 0},
        {title: row.csr ? parseInt(row.csr) : 0},
        {title: row.pcr ? parseInt(row.pcr) : 0},
        {title: row.adjustment ? parseInt(row.adjustment) : 0},
        {title: row.patientValidation ? parseInt(row.patientValidation) : 0},
        {title: row.pmDashboard ? parseInt(row.pmDashboard) : 0}
      ])
    })
    TableData.push([
      {
        title: 'Total'
      },
      {title: ''},
      {title: ''},
      {title: ''},
      {title: totalMy},
      {title: totalSites},
      {title:totalSchedule},
      {title: totalRecruits},
      {title: totalAlert},
      {title: totalLeads},
      {title: totalUdr},
      {title: five9Total},
      {title: os_pcrTotal},
      {title: mdmTotal},
      {title: csrTotal},
      {title: pcrTotal},
      {title: adjustmentTotal},
      {title: patientValidationTotal},
      {title: pmDashboardTotal}
    ])
    return TableData;
  }
  
  const prevMonth = () => {
     let date = moment(state.date).subtract(1, 'month').format(filterformat);
    let startDate = moment(state.date).subtract(1, 'month').startOf('month').format(filterformat)
    let endDate = moment(state.date).subtract(1, 'month').endOf('month').format(filterformat)
    reportAction.fetchUserMatrix({startDate, endDate}).then((res: any) => {
      const TableHead = TableHeadData();
      let TableData;
      if(!state.showHidden) {
        const checked = state.showHidden ? 1 : 0;
        const filterData = res.filter((item: any) => item.hideUsage === checked);
        TableData = getTableData(filterData);
      } else {
        TableData = getTableData(res);
      }
      setStates({...state, TableHead, TableData, date, data: res})
    })
  };
  
  const nextMonth = () => {
    let date = moment(state.date).add(1, 'month').format(filterformat);
    let startDate = moment(state.date).add(1, 'month').startOf('month').format(filterformat);
    let endDate = moment(state.date).add(1, 'month').endOf('month').format(filterformat);
    reportAction.fetchUserMatrix({startDate, endDate}).then((res: any) => {
      const TableHead = TableHeadData();
      let TableData;
      if(!state.showHidden) {
        const checked = state.showHidden ? 1 : 0;
        const filterData = res.filter((item: any) => item.hideUsage === checked);
        TableData = getTableData(filterData);
      } else {
        TableData = getTableData(res);
      }
      setStates({...state, TableHead, TableData, date, data: res, searchKeyword: ''})
    })
  };
  
  const currentDate = () => {
    let date = filterMoment.format(filterformat);
    let startDate = filterMoment.startOf('day').format(filterformat)
    let endDate = filterMoment.endOf('day').format(filterformat)
    reportAction.fetchUserMatrix({startDate, endDate}).then((res: any) => {
      const TableHead = TableHeadData();
      let TableData;
      if(!state.showHidden) {
        const checked = state.showHidden ? 1 : 0;
        const filterData = res.filter((item: any) => item.hideUsage === checked);
        TableData = getTableData(filterData);
      } else {
        TableData = getTableData(res);
      }
      setStates({...state, TableHead, TableData, date, data: res, searchKeyword: ''})
    })
  };

  const thisCurrentMothToToday=()=>{
    let filterDate=moment(new Date())
    let date=filterMoment.format(filterformat)
    let startDate=filterDate.startOf('M').format(filterformat)
    let endDate=filterDate.endOf('M').format(filterformat)
    reportAction.fetchUserMatrix({startDate, endDate}).then((res: any) => {
      const TableHead = TableHeadData();
      let TableData;
      if(!state.showHidden) {
        const checked = state.showHidden ? 1 : 0;
        const filterData = res.filter((item: any) => item.hideUsage === checked);
        TableData = getTableData(filterData);
      } else {
        TableData = getTableData(res);
      }
      setStates({...state, TableHead, TableData, date, data: res, searchKeyword: ''})
    })
  }
  
  const handleSort = (clickedColumn: number) => () => {
    let { column, TableData, direction, TableHead } = state;
    if (column !== clickedColumn) {
      column =  clickedColumn;
      TableData =  _.sortBy(TableData, o => o[clickedColumn].title);
      direction = 'sort ascending';
      setStates({...state, TableData: TableData.slice(), column, direction});
      return;
    }
    TableData = TableData.reverse();
    direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
    setStates({...state, TableData: TableData.slice(), direction});
  };
  
  const showHiddenReports = (value: boolean|undefined) => {
    if(!value) {
      const checked = value ? 1 : 0;
      const filterData = state.data.filter((item: any) => item.hideUsage === checked);
      const TableData = getTableData(filterData);
      setStates({...state, showHidden: value, TableData})
      return
    }
    const TableData = getTableData(state.data);
    setStates({...state, showHidden: value, TableData})
  }
  
  const downloadReport = () => {
    let header = TableHeadData();
    header.splice(0, 1);
    let data: (string | number)[][] = [];
    state.TableData.forEach((itemArray) => {
      itemArray.splice(0,1);
      let rows: (string | number)[] = itemArray.map((item) => {
        let title = item.title;
        if (typeof item.title === 'string' && item.title.includes(',')) {
          title = item.title.replace(',', ';')
        }
        return title;
      });
      data.push(rows);
    })
    exportUsermatrixReport({header, data: data})
  }
  
  return (
    <Template activeLink="clinical scheduling report">
      <div className="ui card">
        <div className="content pb0">
          <h2 className="float-left">User Matrix Report ({state.TableData.length-1}) <HelpMark pageId='21'/></h2>
          <div className="topFilters">
            {
            allowDelete &&
            <Checkbox
              label={'Show Hidden Reports'}
              className='mt10 mr10'
              onChange={(e, {checked}) => showHiddenReports(checked)}
              checked={state.showHidden}
            />
            }
            <Button className="mb15 mr10" primary={true} onClick={downloadReport}>Download</Button>
            <Input className='mb15' icon='search' placeholder="Search" onChange={onSearchChange} />
          </div>
        </div>
      </div>
      
      <div className="ui card">
        <div className="content">
          <div className={'dateFilers'}>
            <Button  attached='left'><Icon name={'chevron left'} onClick={() => prevMonth()}/></Button>
            <Button className="mr10"  attached='right' onClick={() => nextMonth()}>
              <Icon name={'chevron right'}/>
            </Button>
            <Button className="mr10" primary  onClick={() =>{
               currentDate()
            }}>
              Today
            </Button>
            <Button className="mr20" primary   onClick={()=>{
              thisCurrentMothToToday()
            }}>
              This month
            </Button>
            <h4 className="inline-block py10 no-wrap mt0">{myDate(state.date, filterformat)}</h4>
          </div>
          <div className="mt15">
            <DynamicHtmlTable
                key={0}
                className={'adjustment'}
                tableHead={state.TableHead}
                heading={""}
                tableData={state.TableData}
                onHeadClick={handleSort}
                sortedColumn={state.column}
                direction={state.direction}
            />
          </div>
        </div>
      </div>

    </Template>
  )
}

function mapStateToProps(state: RootState) {
  return {
    user: state.auth.user
  };
}

export default connect(mapStateToProps)(UserMatrixReport);
