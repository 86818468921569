import React, { useEffect} from "react";
import Template from './Template';

const UnderConstruction = () => {

    useEffect(() => {
    }, []);

    return (<Template activeLink='aws websites'>
        <main className='aws-websites-root failure aws-websites-single poppins-regular'>
            <h1>This page is currently under construction. Something awesome is on the way</h1>
        </main>
    </Template>)
};


export default UnderConstruction
