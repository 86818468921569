import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../model";
import {useActions} from "../../../redux/actions";
import * as spotActions from "../../../redux/actions/spot";
import {Card, Popup, Table} from "semantic-ui-react";
import ReactTooltip from "react-tooltip";

interface Props {
    patientId: any,
    practice: any,
}

function PatientApptList(props: Props) {
    const spotAction = useActions(spotActions);
    const [state, setStates] = useState({appList: [] as any});

    useEffect(() => {
        loadList();
    },[])


    const loadList = async () => {
        const query: any = {location: props.practice, PatNum: props.patientId};
        const result: any[] = await spotAction.fetchAppointmentList(query);
        setStates({appList: result});
    }

   const trimString = (str:string, len:number, addDots:any) => {
        return (
            (str && str.length > len) ?
                (addDots !== false ?
                    str.substring(0, len - 3) :
                    str.substring(0, len - 3) + '...') :
                str);
    }

    return (
        <Card>
            <Card.Content>
                <Card.Header>
                    <h3>PATIENT APPOINTMENT LIST</h3>
                </Card.Header>
            </Card.Content>
            <Card.Content>
                <div className="table-responsive limit-height">
                    <Table striped={true}>
                        <thead>
                        <tr>
                            <th>PatNum</th>
                            <th>AptDateTime</th>
                            <th>AptStatus</th>
                            <th>Note</th>
                        </tr>
                        </thead>
                        <tbody>
                        {state.appList && state.appList.map( (item:any,key:any) =>
                            <tr key={key}>
                                <td>{item.PatNum}</td>
                                <td>{item.AptDateTime ? item.AptDateTime : ''}</td>
                                <td>{item.APT_Status}</td>
                                <td>
                                    { item.Note ?
                                        <div>
                                            <ReactTooltip id={`aptResult${key}`} place="right" type="light" effect="float" border={true}>
                                                {item.Note}
                                            </ReactTooltip>
                                            <Popup 
                                                content={
                                                    <p>{item.Note}</p>
                                                }
                                                position='right center'
                                                header={"Note"}
                                                trigger={
                                                    <a className="primaryColor"  data-for={`aptResult${key}`}>
                                                    {trimString(item.Note, 15, false)}
                                                </a>
                                                }
                                            />
                                        </div> : ''}
                                </td>
                            </tr>)
                        }
                        </tbody>
                    </Table>
                </div>
            </Card.Content>
        </Card>
    )
}

function mapStateToProps(state: RootState) {
    return {user: state.auth.user}
}

export default connect(mapStateToProps)(PatientApptList)
