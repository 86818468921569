import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Form, Header, Modal } from 'semantic-ui-react';

import { useActions } from '../redux/actions';
import * as HygienistActions from '../redux/actions/hygienistBracketSystem';

interface Props {
    hideModal : any
}

function HygienistBracketSystemModal(props: any) {


    const initialState : any = {
        billableCarePerDayPercentage: null,
        billableCarePerDay:null,
        id:null
    }
    useEffect(()=>{
        if(Object.keys(props.editForm).length !== 0){
            const {billableCarePerDayPercentage,
                billableCarePerDay, id} =props.editForm;
            setStates ({...state,billableCarePerDayPercentage,billableCarePerDay, id})
        }

    },[])

    const [state, setStates] = useState(initialState);
    const hygienistAction = useActions(HygienistActions);

    const onClickHandler = (status:string) => {
        switch (status){
            case 'new':
                const newData = {
                    billableCarePerDay: state.billableCarePerDay,
                    billableCarePerDayPercentage: state.billableCarePerDayPercentage
                }
                hygienistAction.saveHygienistBracketSystem(newData)
                    .then(()=>props.resertData())
                    .then(()=>props.fetchData())
                break;
            case 'update':
                const updateData = {
                    billableCarePerDay: state.billableCarePerDay,
                    billableCarePerDayPercentage: state.billableCarePerDayPercentage,
                    id:state.id
                }
                hygienistAction.updateHygienistBracketSystem(updateData)
                    .then(()=>props.resertData())
                    .then(()=>props.fetchData())
                break;
            default:
                props.hideForm();
        }
    };

    const onChange = (e: any, d?: any) => {
        let formState = state;
        if (d && d.name)
            formState[d.name] = d.checked;
        else
            formState[e.target.name] = e.target.value;
        setStates({...state, ...formState});
    }


    return (
        <Modal
            open={true}
            onClose={props.hideForm}
            centered={false}
            size='large'
            closeIcon={true}
        >
            <Modal.Header closeButton={true}>
                <Header content="Add Hygienist Bracket System"/>
            </Modal.Header>
            <Modal.Content>
                <Form
                    className="formStyle"
                    onSubmit={()=>onClickHandler(Object.keys(props.editForm).length !== 0 ? 'update':'new')}
                >
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>billable Care PerDay</label>
                            <input
                                name="billableCarePerDay"
                                defaultValue={Object.keys(props.editForm).length !== 0 ? props.editForm.billableCarePerDay:null}
                                onChange={onChange}
                                required={true}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>billable Care PerDay Percentage</label>
                            <input
                                name="billableCarePerDayPercentage"
                                defaultValue={Object.keys(props.editForm).length !== 0 ? props.editForm.billableCarePerDayPercentage:null}
                                onChange={onChange}
                                required={true}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>

            </Modal.Content>
            <Modal.Actions>
                <Button
                    color='green'
                    onClick={()=>onClickHandler(Object.keys(props.editForm).length !== 0 ? 'update':'new')}
                >
                    Save
                </Button>
                <Button secondary={true} onClick={props.hideForm}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default HygienistBracketSystemModal;
