import React, { useEffect, useState } from 'react';
import { AccordionTitle, AccordionContent, Icon, Grid, GridRow, GridColumn, Loader, Progress } from 'semantic-ui-react'
import { useActions } from '../../../redux/actions';

import Five9CallsTable from './Five9CallsTable';
import * as HealthStatusActions from "../../../redux/actions/healthStatus";
import ProgressBar from '../ProgressBar';

function Five9CallsHealth() {
    const initialState = { loading: false, data: {} as any, panel: '', records: [], stats: {} };
    const healthStatusActions = useActions(HealthStatusActions);
    const [state, setState] = useState(initialState);
    const { data, loading, panel, records, stats } = state;
    const prefix = 'Five9Calls'


    useEffect(() => {
        const fetchData = async () => {
            try {
                setState(prevState => ({ ...prevState, loading: true }));
                const [stats, data] = await Promise.all([
                    healthStatusActions.get('health-status/call-logs'),
                    healthStatusActions.getCronsHealthStatus('Five9')
                ]);
                healthStatusActions.addToCount({ success: data.succeeded, failed: data.failed, pending: 0 });
                setState(prevState => ({ ...prevState, data, records: data.records.all,loading: false, stats }));
            } catch (error) {
                setState(prevState => ({ ...prevState, loading: false }));
            }
        };

        fetchData();
    }, []);

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }

    return (
        <>
            <AccordionTitle active={state.panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Five9 Calls</span>
                            {loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={data.failed} pending={0} success={data.succeeded} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === prefix}>
                {!loading && <Grid>
                    <GridRow>
                        <GridColumn width={16}>
                            <Five9CallsTable data={records} stats={stats} />
                        </GridColumn>
                    </GridRow>
                </Grid>}
            </AccordionContent>
        </>
    );
}

export default Five9CallsHealth;