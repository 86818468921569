import React, { useState } from 'react'
import { TableRow, TableCell, TableBody, Table, Icon } from 'semantic-ui-react'
import ProgressBar from '../ProgressBar'
import _ from 'lodash'
import { getStatus } from '../healthStatusUtils'

interface props {
    data: any[]
}

const PatientValidTable = ({ data }: props) => {
    const [state, setState] = useState({ selected: { mco: '', tab: '' } })
    const opts = [{ name: 'failedPatients', label: 'Failed Patients', count: 'fail' }, { name: 'allPatients', label: 'All Patients', count:'total' }];
    const { selected } = state;

    const onTileSelect = (mco: string, tab: string) => {
        if (!tab && selected.mco === mco) {
            setState({ ...state, selected: { ...selected, mco: '' } });
        } else if (selected.mco === mco && selected.tab === tab) {
            setState({ ...state, selected: { ...selected, tab: '' } });
        } else {
            setState({ ...state, selected: { mco, tab } });
        }
    }

    const getData = (mco: string, tab: string): any[] => {
        const info = data.find(d => d.name === mco)
        if (!info) return []
        return info[tab];
    }


    return (
        <>
            {
                data.map((mco, index) =>
                    <Table compact="very" fixed key={index} >
                        <TableBody key={index}>
                            <TableRow key={index} onClick={() => onTileSelect(mco.name, '')} style={{ cursor: 'pointer' }}>

                                <TableCell colspan='9'><strong style={{ marginLeft: '20px' }}><Icon name='dropdown' />{mco.name} <small>({mco.total})</small></strong></TableCell>
                                <TableCell>
                                    <ProgressBar success={mco.total - mco.fail} pending={0} failed={mco.fail} />
                                </TableCell>
                            </TableRow>
                            {
                                state.selected.mco === mco.name && opts.map((tab, key: number) => (
                                    <>
                                        <TableRow onClick={() => onTileSelect(mco.name, tab.name)} style={{ cursor: 'pointer', backgroundColor: key % 2 == 0 ? '#eaeaea' : 'white' }} key={key + index}>
                                            <TableCell colspan='10'><strong style={{ marginLeft: '35px' }}>{tab.label}  <small>({mco[tab.count]})</small></strong></TableCell>
                                        </TableRow>

                                        {selected.mco === mco.name && tab.name === selected.tab &&
                                            getData(mco.name, tab.name).map((o , i) =>
                                            (
                                                <TableRow key={i}>
                                                    <TableCell></TableCell>
                                                    <TableCell> <strong>Status: </strong> {getStatus(o)}</TableCell>
                                                    <TableCell> <strong>Practice: </strong> {o.practice}</TableCell>
                                                    <TableCell><strong>PatNum: </strong>{o.PatNum}</TableCell>
                                                    <TableCell><strong>Apt_Date: </strong>{o.Apt_Date}</TableCell>
                                                    <TableCell><strong>APT_Status: </strong>{o.APT_Status}</TableCell>
                                                    <TableCell colspan='2'><strong>CarrierName: </strong>{o.CarrierName}</TableCell>
                                                    <TableCell><strong>BirthDate: </strong>{o.BirthDate}</TableCell>
                                                    <TableCell><strong>SubscriberID: </strong>{o.SubscriberID}</TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </>
                                ))
                            }
                        </TableBody>
                    </Table>
                )
            }
        </>
    )
}

export default PatientValidTable