import React, {useEffect, useState} from "react";
import Template from "./Template";
import {Form, Grid, Checkbox, Button, Icon} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useActions} from "../redux/actions";
import * as adjustmentActions from "../redux/actions/adjustment";
import {AdjustmentSetup as AdjustmentSetupType} from "../model/adjustment";
import HelpMark from "./HelpMark";

function AdjustmentSetup() {
    const adjustmentAction = useActions(adjustmentActions);
    const initialState: any = {
        setup: {
            notificationAmount: 0,
            startDate: new Date(),
            emailPracMngr: 0,
            textlPracMngr: 0,
            emailRgnlMngr: 0,
            textRgnlMngr: 0
        },
        error: false
    };

    const [state, setStates] = useState(initialState);

    useEffect(() => {
        getSetupData();
    }, []);

    const getSetupData = () => {
        adjustmentAction.getSetup().then((res: AdjustmentSetupType) => {
            if(res){
                const setup = {
                    notificationAmount: res.notificationAmount,
                    startDate: new Date(res.startDate),
                    emailPracMngr: res.emailPracMngr,
                    textlPracMngr: res.textlPracMngr,
                    emailRgnlMngr: res.emailRgnlMngr,
                    textRgnlMngr: res.textRgnlMngr
                };
                setStates({...state, setup: setup});
            }
        });
    };
    const onChange = (e: any, name: string) => {
        const setup = state.setup;
        if (name == 'notificationAmount' && Math.sign(e.target.value) === 1) {
            setStates({...state, error: true});
            return;
        }
        setup[name] = e.target.value;
        setStates({...state, setup: setup, error: false});
    };
    const handleCheckBox = (value: any, name: string) => {
        const setup = state.setup;
        setup[name] = +value;
        setStates({...state, setup: setup});
    };
    const handleSubmit = () => {
        const setup = state.setup;
        adjustmentAction.saveSetup(setup).then(() => {
            getSetupData();
        });
    };
    const handleDateChange = (date: any) => {
        const setup = state.setup;
        setup['startDate'] = date;
        setStates({...state, setup: setup});
    };

    return (
        <Template activeLink='adjustment-acknowledgement'>
            <div className="ui card">
                <div className="content">
                    <h2 className="float-left mr10"> Adjustment - Setup <HelpMark pageId="41"/></h2>
                </div>
            </div>

            <Grid>
                <Grid.Row>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <div className="ui card">
                            <div className="content">
                                <Form className="formStyle">
                                    <Form.Group widths='equal'>
                                        <Form.Input
                                            fluid={true}
                                            label='Notification Amount (only negative values are allowed)'
                                            placeholder='Notification Amount'
                                            error={state.error}
                                            value={state.setup.notificationAmount}
                                            onChange={(e) => onChange(e, 'notificationAmount')}
                                        />
                                        <div className="field">
                                            <label>Date</label>
                                            <div className="datePicker widthFluid">
                                                <DatePicker
                                                    selected={state.setup.startDate}
                                                    onChange={handleDateChange}
                                                />
                                                <Icon name="calendar alternate"/>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <div className="field">
                                            <label>Email Notification</label>
                                            <Checkbox
                                                label='Practice Manager'
                                                name={'emailPracMngr'}
                                                className="mr30"
                                                checked={state.setup.emailPracMngr}
                                                onChange={(_e, {checked}) => handleCheckBox(checked, 'emailPracMngr')}
                                            />
                                            <Checkbox
                                                label='Regional Manager'
                                                name={'emailRgnlMngr'}
                                                checked={state.setup.emailRgnlMngr}
                                                onChange={(_e, {checked}) => handleCheckBox(checked, 'emailRgnlMngr')}
                                            />
                                        </div>
                                        <div className="field">
                                            <label>Text Notification</label>
                                            <Checkbox
                                                label='Practice Manager'
                                                name={'textlPracMngr'}
                                                className="mr30"
                                                checked={state.setup.textlPracMngr}
                                                onChange={(_e, {checked}) => handleCheckBox(checked, 'textlPracMngr')}
                                            />
                                            <Checkbox
                                                label='Regional Manager'
                                                name={'textRgnlMngr'}
                                                checked={state.setup.textRgnlMngr}
                                                onChange={(_e, {checked}) => handleCheckBox(checked, 'textRgnlMngr')}
                                            />
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="mb0">
                                        <div className="field">
                                            <Button primary={true} onClick={() => handleSubmit()}>Save</Button>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </Template>
    );

}

export default AdjustmentSetup;
