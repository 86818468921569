import { ReportFilterObject } from '../../../model/report';
import { isEmpty } from '../../../utils/common';
import { getReportData } from './Common';

/**
 * Returns data table array based on filtered data that is required to generate front end table
 * @param props (props holds all report data ex: billing, schedule, budget)
 * @param selectedPractices (it contains selected practices names if filter is applied for practice)
 */

export const budgetProCPVFilterObject:ReportFilterObject = (props, selectedPractices =[], reportName?: string) => {
const {report, reportTN} = props;

let returnObj:any = report && !isEmpty(report.reportNewData) ? report.reportNewData[0].reportData : reportTN.reportTimeAndName.length > 0 ? getReportData(reportTN, reportName) : [];
returnObj=JSON.parse(JSON.stringify(returnObj));
return returnObj;
};
