import { Action, ActionType } from "../../model/actionTypes";
import { Partners, PartnersUsage } from "../../model/partners";
import createReducer from "./createReducer";

export const partner = createReducer({}, {
    [ActionType.LOAD_DENTAL_PARTNERS](state: Partners, action: Action<Partners>) {
        return action.payload;
    },
    [ActionType.LOAD_DENTAL_PARTNERS_SUCCESS](state: Partners, action: Action<Partners>) {
        return action.payload;
    },
    [ActionType.SAVE_DENTAL_PARTNERS_USAGE](state: PartnersUsage, action: Action<PartnersUsage>) {
        return action.payload;
    },
    [ActionType.SAVE_DENTAL_PARTNERS_USAGE_SUCCESS](state: PartnersUsage, action: Action<PartnersUsage>) {
        return action.payload;
    }
});