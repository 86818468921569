import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import {Button, Dimmer, Grid, Label, Loader, Modal, Table} from "semantic-ui-react";
import { Practice } from '../model/practice';
import { Report } from '../model/report';
import { numberFormat } from './numberFormat';
import PMDUnitItemClickable from './PMDUnitItemClickable';
import Moment from 'moment';
import _ from 'lodash';
export interface PMDScheduleTodayProps {
  practicename: string,
  scheduleToday30DayApts: any,
  scheduleToday30DayCompletd: any,
  scheduleTodayPlanScheduledApts: any,
  practice: Practice,
  schAptToday: any,
  schAptTodayData: any[],
  ptSeenToday: any,
  FV_PT: any,
  RC_PT: any,
  TX_PT: any,
  Shifts: any,
  report: Report,
  bdppp: bigint,
  actppp: bigint,
  deltappp: any,
  ppp: boolean,
  FV_CPV: any,
  RC_CPV: any,
  TX_CPV: any,
  procFeeSum: any,
  open : boolean,
  general_shifts: [],
  hygienists_shifts: [],
  pmdDailyPTSeenSummary:[],
  Days_Complete: any,
  Days_Left: any,
  General_Shifts_Left: any,
  General_Shifts_Complete: any,
  Hygiene_Shifts_Left: any,
  Hygiene_Shifts_Complete: any,
  currentDayGoal: any,
  sendPTGoalMail (item: any,practicename:any) : void,
  pmdPtSeenTodayDetail:any [],
  open2 : boolean,
  open3: boolean,
  open4: boolean,
  open5: boolean,
  sendPtSeenTodayDetailMail (item: any,practicename:any,sum:any) : void,
  pmdNonConfirmedApts: any [];
  openingHours: {}
}

export interface PMDScheduleTodayState {
  practicename: string,
  scheduleToday30DayApts: any,
  scheduleToday30DayCompletd: any,
  scheduleTodayPlanScheduledApts: any,
  practice: Practice,
  schAptToday: any,
  schAptTodayData: any,
  ptSeenToday: any,
  FV_PT: any,
  RC_PT: any,
  TX_PT: any,
  Shifts: any,
  bdppp: bigint,
  actppp: bigint,
  deltappp: any,
  ppp: boolean,
  procFeeSum: any,
  open: boolean,
  MTDCalculations: PTGoalMTDCalaculations[],
  MTDGoal: any,
  TodaysGoal: any,
  TodayDate: any,
  pmdPtSeenTodayDetail:any [],
  open2: boolean,
  open3: boolean,
  open4: boolean,
  open5: boolean,
  columnX:any,
  directionX:any,
  daysCountInMonth: any[]
}

export interface PTGoalMTDCalaculations {
	date: any,
	shifts : any,
  goalByDay: any,
	PTCount : any,
  MTDGoal: any,
	MTDPTCount : any,
  MTDGoal_To_Act_PTCount: any
}

class PMDScheduleToday extends React.Component<PMDScheduleTodayProps,PMDScheduleTodayState> {
  budgetData = this.props.report.budgetData;
  bCareData = this.props.report.reportData;

  constructor(props: PMDScheduleTodayProps) {
    super(props);
    this.state={
      practicename : props.practicename,
      scheduleToday30DayApts: props.scheduleToday30DayApts,
      scheduleToday30DayCompletd: props.scheduleToday30DayCompletd,
      scheduleTodayPlanScheduledApts: props.scheduleTodayPlanScheduledApts,
      practice: props.practice,
      schAptToday: props.schAptToday,
      schAptTodayData: props.schAptTodayData,
      ptSeenToday: props.ptSeenToday,
      FV_PT: props.FV_PT,
      RC_PT: props.RC_PT,
      TX_PT: props.TX_PT,
      Shifts: props.Shifts,
      bdppp: props.bdppp,
      actppp: props.actppp,
      deltappp:props.deltappp,
      ppp:props.ppp,
      procFeeSum: props.procFeeSum,
      open : props.open,
      MTDCalculations : this.MTDCalculations(),
      MTDGoal: 0,
      TodaysGoal: 0,
      TodayDate: '',
      pmdPtSeenTodayDetail: props.pmdPtSeenTodayDetail,
      open2 : props.open2,
      open3 : props.open3,
      open4 : props.open4,
      open5 : props.open5,
      columnX: null,
      directionX: null,
      daysCountInMonth: this.daysCountInMonth()
    }
    this.setPracticeName=this.setPracticeName.bind(this);
    this.setScheduleToday30DayApts=this.setScheduleToday30DayApts.bind(this);
    this.setScheduleToday30DayCompletd=this.setScheduleToday30DayCompletd.bind(this);
    this.setScheduleTodayPlanScheduledApts=this.setScheduleTodayPlanScheduledApts.bind(this);     
    this.setPracticeName=this.setPracticeName.bind(this);
    Moment.locale('en');
    this.budgetData = this.props.report.budgetData;
    this.bCareData = this.props.report.reportData;
    this.setOpen=this.setOpen.bind(this);
    this.setMTDCalculations=this.setMTDCalculations.bind(this);
    this.setMTDGoal=this.setMTDGoal.bind(this);
    this.setTodaysGoal=this.setTodaysGoal.bind(this);
    this.setTodayDate=this.setTodayDate.bind(this);
    this.setColumnX=this.setColumnX.bind(this);
    this.setDirectionX=this.setDirectionX.bind(this);
    this.setOpen2=this.setOpen2.bind(this);
    this.setOpen3=this.setOpen3.bind(this);
    this.setOpen4=this.setOpen4.bind(this);
    this.setOpen5=this.setOpen5.bind(this);
  }

  setPracticeName(value: string) {
    this.setState({
      practicename : value
    })
  }

  setScheduleToday30DayApts(value: any) {
    this.setState({
      scheduleToday30DayApts : value
    })
  }

  setScheduleToday30DayCompletd(value: any) {
    this.setState({
      scheduleToday30DayCompletd : value
    })
  }

  setScheduleTodayPlanScheduledApts(value: any) {
    this.setState({
      scheduleTodayPlanScheduledApts : value
    })
  }

  setColumnX(value: string) {
    this.setState({
      columnX : value
    })
  }

  setDirectionX(value: string) {
    this.setState({
      directionX : value
    })
  }

  componentWillReceiveProps(nextProps: PMDScheduleTodayProps) {
    this.setState({
      practicename: nextProps.practicename,
      bdppp: nextProps.bdppp,
      actppp: nextProps.actppp,
      open: nextProps.open,
      pmdPtSeenTodayDetail: nextProps.pmdPtSeenTodayDetail,
      open2: nextProps.open2
    })
  }

  getExpectedShow():any {
    let show_rate = this.props.scheduleToday30DayCompletd/this.props.scheduleToday30DayApts;
    return (Math.round(show_rate*this.props.schAptToday));
  };

  getPTGoal():any {
    let ptVisitTotal:any = this.props.FV_PT+this.props.RC_PT+this.props.TX_PT;
    let ptVisitPerShift = ptVisitTotal/this.props.Shifts;
    const today = new Date();
    const index = today.getDate();
    let todaysShifts:any =  this.getItemAtIndex(this.props.general_shifts,index)+this.getItemAtIndex(this.props.hygienists_shifts,index);
    let ptGoal:any = todaysShifts*ptVisitPerShift;
    return Math.ceil(ptGoal);  
  };

  getItemAtIndex(data: any[], index: number){
    if (data && data.length > index && index >= 0) {
      return data[index];
    }
    return 0;
  };

  practiceDBName() {
    const practiceData: any = this.props.practice.practiceInfo;
            if (practiceData[this.props.practicename.toLowerCase()]){
              return practiceData[this.props.practicename.toLowerCase()].practiceDBName.toLowerCase();
            }else{
              return this.props.practicename.toLowerCase()
            }     
  }

  getBudget() {
    const FV_CPV:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].FvCPV :0.00:0.00:0.00;
    const FV_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].FvPt :0.00:0.00:0.00;
    const RC_CPV:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].RcCPV :0.00:0.00:0.00;
    const RC_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].RcPt :0.00:0.00:0.00;
    const TX_CPV:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].TxCPV :0.00:0.00:0.00;
    const TX_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].TxPt :0.00:0.00:0.00;
    return (((FV_CPV)*(FV_PT))+((RC_CPV)*(RC_PT))+((TX_CPV)*(TX_PT)));
  };

  getTotalPt() {
    const FV_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].FvPt :0.00:0.00:0.00;
    const RC_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].RcPt :0.00:0.00:0.00;
    const TX_PT:any  = this.props.report ? this.props.report.budgetData ? this.props.report.budgetData[this.practiceDBName()] ? this.props.report.budgetData[this.practiceDBName()].TxPt :0.00:0.00:0.00;
    return FV_PT+RC_PT+TX_PT;
  };

  calculateBDPPP() {
    const BDPPP:any = this.getBudget()/this. getTotalPt();
    return BDPPP? BDPPP.toFixed(2) : 0;
  }

  calculateACTPPP() {
    try{
    if(this.props.report.reportData){
      const bCare_perpractice:any  = this.props.report ? this.props.report.reportData? this.props.report.reportData.byPractice? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['bCare'] ? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['bCare'] : 0.00:0.00:0.00:0.00;
      const bCare_totalVisit:any  = this.props.report ? this.props.report.reportData? this.props.report.reportData.byPractice? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['totalVisit'] ? this.props.report.reportData.byPractice[this.props.practicename.toLowerCase()]['totalVisit']  :0.00:0.00:0.00:0.00;
      var n_bCare_perpractice = (isNaN(bCare_perpractice) ? 0 : bCare_perpractice);
      var n_bCare_totalVisit= (isNaN(bCare_totalVisit) ? 0 : bCare_totalVisit);
      const actppp:any = Math.round(parseInt(n_bCare_perpractice)/parseInt(n_bCare_totalVisit)).toFixed(2);
      var n_actppp = (isNaN(actppp) ? 0 : actppp);
      return n_actppp;
    }else{
      return 0.00;
    }
  } catch (ex) {
    return 0.00;
    }
  }

  calculateDeltaPPP() {
    try{
      var deltappp:any = this.calculateBDPPP() - this.calculateACTPPP() ;
      var n_deltappp:any = (isNaN(deltappp) ? 0 : deltappp.toFixed(2));
      var deltappp_display:string = n_deltappp>0 ? numberFormat(n_deltappp) : '('+numberFormat(n_deltappp*-1)+')';
      return deltappp_display;
  } catch (ex) {
    var deltappp_display:string = '($0.00)';
    return deltappp_display;
    }
  }

  checkValues(){
    return (
        !(typeof this.props.report === 'object' && this.props.report !== null &&
          typeof this.props.report.reportData === 'object' && this.props.report.reportData !== null &&
          typeof this.props.report.budgetData === 'object' && this.props.report.budgetData !== null &&
          this.props.schAptTodayData !== null &&
          this.props.pmdNonConfirmedApts !== null &&
          this.props.schAptTodayData !== null
        ) &&
        <Dimmer active={true}>
          <Loader>Calculating</Loader>
        </Dimmer>)
  }

  getScheduledProduction():any {
    return (Math.round(this.getExpectedShow()*this.calculateACTPPP()));
  };

  setOpen(value: boolean) {
    this.setState({
      open : value
    });
    if(value){
      this.setMTDCalculations(this.MTDCalculations());
    }
  }

  setOpen2(value: boolean) {
    this.setState({
      open2 : value
    });
  }

  setOpen3(value: boolean) {
    this.setState({
      open3 : value
    });
  }

  setOpen4(value: boolean) {
    this.setState({
      open4 : value
    });
  }

  setOpen5(value: boolean) {
    this.setState({
      open5 : value
    });
  }

  setMTDCalculations(value: PTGoalMTDCalaculations[]) {
    this.setState({
      MTDCalculations : value
    })
  }

  setMTDGoal(value: any) {
    this.setState({
      MTDGoal : value
    })
  }

  setTodaysGoal(value: any) {
    this.setState({
      TodaysGoal : value
    })
  }

  setTodayDate(value: any) {
    this.setState({
      TodayDate : value
    })
  }

  getTodayPT() {
    return this.props.FV_PT+this.props.RC_PT+this.props.TX_PT;
        
    }; 

  MTDCalculations  = () => {
    let MTDCalculations_a: PTGoalMTDCalaculations[] = [];
    let dailyCalculations: PTGoalMTDCalaculations = {
      date: 0,
      shifts : 0,
      goalByDay: 0,
      PTCount : 0,
      MTDGoal: 0,
      MTDPTCount : 0,
      MTDGoal_To_Act_PTCount: 0
    };
    MTDCalculations_a.push(dailyCalculations);
    let monthNames = ["January", "February", "March", "April", "May","June","July", "August", "September", "October", "November","December"];
    let todaysDate = new Date().getDate();
    let shifts_completed =0, shifts_left=0, total_monthly_shifts =0;
    for (let i = 1; (i <= this.getDaysInMonth()); i++) {
      let general_shifts_value=0,hygienists_shifts_value =0;
      if(this.props.general_shifts){
        general_shifts_value = typeof this.props.general_shifts[i] === 'undefined'?0:(this.props.general_shifts[i] != null ? this.props.general_shifts[i] : 0);
      }
      if(this.props.hygienists_shifts){
        hygienists_shifts_value = typeof this.props.hygienists_shifts[i] === 'undefined'?0:(this.props.hygienists_shifts[i] != null ? this.props.hygienists_shifts[i] : 0);
      }
      if(i<todaysDate){
        shifts_completed += general_shifts_value;
        shifts_completed += hygienists_shifts_value;
      }
      if(i>=todaysDate){
        shifts_left += general_shifts_value;
        shifts_left += hygienists_shifts_value;
      }
      total_monthly_shifts = shifts_completed+shifts_left;
    }
    for (let i = 1; ((i <= this.getDaysInMonth())&&(i<=todaysDate)); i++) {
      let date_value:any = i+" "+monthNames[new Date().getMonth()]+" "+(new Date().getFullYear());
      let general_shifts_value:any = typeof this.props.general_shifts[i] === 'undefined'?0:(this.props.general_shifts[i] != null ? this.props.general_shifts[i] : 0);
      let hygienists_shifts_value:any = typeof this.props.hygienists_shifts[i] === 'undefined'?0:(this.props.hygienists_shifts[i] != null ? this.props.hygienists_shifts[i] : 0);
      let shifts_value:any = general_shifts_value+hygienists_shifts_value;
      let budget:any = this.getTodayPT();
      let total_shifts:any = total_monthly_shifts;
      let goalByDay_value:any = (budget/total_shifts)*(shifts_value != null ? shifts_value : 0);
      let PTCount:any = this.props.pmdDailyPTSeenSummary[i];
      let MTDGoal_value:any = ( i==1 ? goalByDay_value : (parseFloat(goalByDay_value)+parseFloat(MTDCalculations_a[i-1].MTDGoal)));
      let MTDPTCount_value:any = ( i==1 ? PTCount : PTCount + MTDCalculations_a[i-1].MTDPTCount);
      let MTDGoal_To_Act_PTCount_value:any = parseFloat(MTDPTCount_value)-parseFloat(MTDGoal_value);

     let dailyCalculations: PTGoalMTDCalaculations = {
        date: date_value,
        shifts : shifts_value+"/"+total_monthly_shifts,
        goalByDay: (parseFloat(goalByDay_value)||0).toFixed(2),
        PTCount : PTCount,
        MTDGoal: (parseFloat(MTDGoal_value)||0).toFixed(2),
        MTDPTCount : MTDPTCount_value,
        MTDGoal_To_Act_PTCount: (parseFloat(MTDGoal_To_Act_PTCount_value)||0).toFixed(2)
      };

      MTDCalculations_a.push(dailyCalculations);
      this.setMTDGoal(Number(MTDGoal_value).toFixed(2));
      this.setTodayDate(i+" "+monthNames[new Date().getMonth()]);
    }
    return MTDCalculations_a.slice(1);
   } 

  getDaysInMonth() {
   return new Date(new Date().getFullYear(),new Date().getMonth()+1, 0).getDate();
  };

  handleSortPtSeenTodayDetail(clickedColumn: any){
    let { columnX, pmdPtSeenTodayDetail, directionX } = this.state;
     if (columnX !== clickedColumn) {
            columnX =  clickedColumn;
            pmdPtSeenTodayDetail =  _.sortBy(pmdPtSeenTodayDetail, o => o[clickedColumn]);
            directionX = 'ascending';
            this.setState({...this.state, pmdPtSeenTodayDetail, columnX, directionX});
         return;
     }
     pmdPtSeenTodayDetail = pmdPtSeenTodayDetail.reverse();
    directionX = directionX === 'ascending' ? 'descending' : 'ascending';
    this.setState({...this.state, pmdPtSeenTodayDetail, directionX});
  };
  
  sendPtSeenTodayDetailMailAction() {
    this.props.sendPtSeenTodayDetailMail(this.state.pmdPtSeenTodayDetail,this.state.practicename,this.sumOfPtSeenToday(this.state.pmdPtSeenTodayDetail,"PatNum"));
    this.setOpen2(false); 
  };
  
  sumOfPtSeenToday(items:any[], prop:string){
    return items.reduce( function(a, b){
        return a + b[prop];
    }, 0);
  };  

renderTableHeader() {
  let header = Object.keys(this.state.MTDCalculations[0])
  return header.map((key, index) => {
     return <th key={index}>{key.toUpperCase()}</th>
  })
}

  renderTableData() {
    return this.state.MTDCalculations.map((mtdCalculation, index) => {
       const { date, shifts, goalByDay, PTCount, MTDGoal, MTDPTCount, MTDGoal_To_Act_PTCount} = mtdCalculation
       return (
          <tr key={date}>
             <td>{date}</td>
             <td>{shifts}</td>
             <td>{goalByDay}</td>
             <td>{PTCount}</td>
             <td>{MTDGoal}</td>
             <td>{MTDPTCount}</td>
             <td>{MTDGoal_To_Act_PTCount>=0 ? MTDGoal_To_Act_PTCount : '('+Math.abs(MTDGoal_To_Act_PTCount)+')'}</td>
          </tr>
       )
    })
 }

 sendPDFEmail(isOpenToday: boolean) {
  this.props.sendPTGoalMail(ReactDOMServer.renderToString(this.patientGoalDialog(isOpenToday)),this.props.practicename.toLocaleUpperCase());
  this.setOpen(false); 
};

  patientGoalDialog(isOpenToday: boolean) {
    return (
      <div>
          <div className="visible content">
            <table className="ui very basic collapsing celled table">
              <thead>
                <tr><th>PT Goal Today</th>
                <th>{this.getPTGoal() > 0 && isOpenToday ? this.getPTGoal() : 0}</th>
              </tr></thead>
              <tbody>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      Goal
                        <div className="sub header">Total Value
                      </div>
                    </div>
                  </h4>
                  </td>
                  <td>
                  {this.getTodayPT() && isOpenToday ? this.getTodayPT() : 0}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      MTD Goal - {this.state.TodayDate}
                        <div className="sub header">Total Value
                      </div>
                    </div>
                  </h4></td>
                  <td>
                  {parseFloat(this.state.MTDGoal)||0}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      Work Days
                        <div className="sub header">Total Days
                      </div>
                    </div>
                  </h4></td>
                  <td>
                    {this.props.Days_Left+this.props.Days_Complete}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      Left
                        <div className="sub header">Work Days Left Only
                      </div>
                    </div>
                  </h4></td>
                  <td>
                    {this.props.Days_Left}/{this.props.Days_Left+this.props.Days_Complete}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      Shifts
                        <div className="sub header">Total Shifts
                      </div>
                    </div>
                  </h4></td>
                  <td>
                    {this.props.General_Shifts_Complete+this.props.Hygiene_Shifts_Complete+this.props.General_Shifts_Left+this.props.Hygiene_Shifts_Left}
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4 className="ui image header">
                      <div className="content">
                      Shifts Left
                        <div className="sub header">Shifts Left Only
                      </div>
                    </div>
                  </h4></td>
                  <td>
                    {this.props.General_Shifts_Left+this.props.Hygiene_Shifts_Left}/{this.props.General_Shifts_Complete+this.props.Hygiene_Shifts_Complete+this.props.General_Shifts_Left+this.props.Hygiene_Shifts_Left}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        
            <table className="ui celled structured table">
              <thead>
                <tr>
                  <th rowSpan={1}>Goal</th>
                  <th rowSpan={1}>Type</th>
                  <th rowSpan={1}>PT Visit</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3}>Goal</td>
                  <td>FV</td>
                  <td className="right aligned">{this.props.FV_PT}</td>
                </tr>
                <tr>
                  <td>RC</td>
                  <td className="right aligned">{this.props.RC_PT}</td>
                </tr>
                <tr>
                  <td>TX</td>
                  <td className="right aligned">{this.props.TX_PT}</td>
                </tr>
                <tr>
                  <td rowSpan={1}>Total</td>
                  <td></td>
                  <td className="right aligned">{this.props.FV_PT+this.props.RC_PT+this.props.TX_PT}</td>
                </tr>
              </tbody>
            </table>
            
            <div>
            <table className="ui celled structured table">
              <thead>
                <tr>
                  <th >Days</th>
                  <th >Practice</th>
                  <th >Complete</th>
                  <th >Left</th>
                  <th >Total</th>
                </tr>
                <tr>
                  <td rowSpan={1}>Days</td>
                  <td rowSpan={1}>{this.props.practicename}</td>
                  <td rowSpan={1}>{this.props.Days_Complete}</td>
                  <td rowSpan={1}>{this.props.Days_Left}</td>
                  <td rowSpan={1}>{this.props.Days_Complete+this.props.Days_Left}</td>
                </tr>
              </thead>
            </table>
            </div>

            <table className="ui celled structured table">
              <thead>
                <tr>
                  <th rowSpan={1}>Shifts</th>
                  <th rowSpan={1}>Type</th>
                  <th rowSpan={1}>Shifts Complete</th>
                  <th rowSpan={1}>Shifts Left</th>
                  <th rowSpan={1}>Total Shifts</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={3}>Shifts</td>
                  <td className="left aligned">General Shifts</td>
                  <td className="right aligned">{this.props.General_Shifts_Complete}</td>
                  <td className="right aligned">{this.props.General_Shifts_Left}</td>
                  <td className="right aligned">{this.props.General_Shifts_Complete+this.props.General_Shifts_Left}</td>
                </tr>
                <tr>
                  <td className="left aligned">Hygiene Shifts</td>
                  <td className="right aligned">{this.props.Hygiene_Shifts_Complete}</td>
                  <td className="right aligned">{this.props.Hygiene_Shifts_Left}</td>
                  <td className="right aligned">{this.props.Hygiene_Shifts_Complete+this.props.Hygiene_Shifts_Left}</td>
                </tr>
              </tbody>
            </table>

            <div>
            <table className="ui striped table" id='mtdCalculations'>
              <thead>
                <tr>{this.renderTableHeader()}</tr>
              </thead>
              <tbody>                    
                {this.renderTableData()}
              </tbody>
            </table>
            </div>  
        
      </div>
    )
}

  daysCountInMonth() {
    const currentDate = new Date();
    const today = currentDate.getDate();
    let returnObj:any = {}
    const weekDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    for (let i = 1; i < today; i++) {
      const dateChange = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
      const day = weekDays[dateChange.getDay()];
      if (!returnObj[day]) {
        returnObj[day] = 0;
      }
      returnObj[day]++;
    }
    return returnObj;
  }
  render() {
    let isOpenToday = false;
    const openingHours: any = this.props.openingHours;
    const practice: any = this.state.practicename;
    const today = Moment().format('YYYY-MM-DD')

    if (openingHours && openingHours[practice] && openingHours[practice][today]) {
      isOpenToday = true;
    }

    return (
  <><Grid columns='two'>
        <Grid.Row>
          <Grid.Column className='pdmitem'>
            <span onClick={() => this.setOpen3(true)}>
              <PMDUnitItemClickable value={this.props.schAptToday} description="Sch Apt Today" />
            </span>
          </Grid.Column>
          <Grid.Column className='pdmitem'>
          <span onClick={() => this.setOpen2(true)}>
              <PMDUnitItemClickable value={this.state.pmdPtSeenTodayDetail.length} description="PT Seen Today" /></span>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column className='pdmitem'>
            <span onClick={() => this.setOpen4(true)}>
            <PMDUnitItemClickable value={this.getExpectedShow() > 0 && isOpenToday ? this.getExpectedShow() : 0} description="Expected Show" /></span>
          </Grid.Column>
          <Grid.Column className='pdmitem'>
            <span onClick={() => this.setOpen(true)}>
              <PMDUnitItemClickable value={this.getPTGoal() > 0 && isOpenToday ? this.getPTGoal() : 0} description="PT Goal Today" /></span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className='pdmitem'>
                <span onClick={() => this.setOpen5(true)}>
                <PMDUnitItemClickable value={this.props.pmdNonConfirmedApts ? this.props.pmdNonConfirmedApts.length : 0} description="Non Confirmed" /></span>
          </Grid.Column>
        </Grid.Row>

      </Grid><Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.open}
        onClose={() => this.setOpen(false)}
        onOpen={() => this.setOpen(true)}
      >
          <Modal.Header><b>PT Goal</b> <a className="ui orange label">{this.props.practicename.toLocaleUpperCase()}</a>
            <div className="ui top right attached label production_right_label">
              <Button onClick={() => this.setOpen(false)} inverted>
                Close
              </Button>
            </div>
          </Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              {this.patientGoalDialog(isOpenToday)}
            </Modal.Description>
          </Modal.Content>

          <Modal.Actions>
            <Button onClick={() => this.sendPDFEmail(isOpenToday)} inverted color='orange'>
              Send Mail
            </Button>
          </Modal.Actions>
        </Modal>
        
        <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.open2}
        onClose={() => this.setOpen2(false)}
        onOpen={() => this.setOpen2(true)}
      >
        <Modal.Header><b>PT Seen Today</b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
        <Label color={this.state.pmdPtSeenTodayDetail.length>0?'green':'red'} floating>
        {this.state.pmdPtSeenTodayDetail.length}
      </Label>
      <div className="ui top right attached label production_right_label">
        <Button onClick={() => this.setOpen2(false)} inverted>
          Close
        </Button>
      </div>
      </Modal.Header>
        <Modal.Content scrolling>

      <Table className="table table-striped table-hover my-0 FreezeTable sortable">   
        <Table.Header>
        <Table.Row>
        <Table.HeaderCell><div className="tbl-header">Practice</div></Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnX === 'PatNum' ? this.state.directionX : null}
              onClick={() => this.handleSortPtSeenTodayDetail('PatNum')}
              ><div className="tbl-header">PatNum</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnX === 'ProcDate' ? this.state.directionX : null}
              onClick={() => this.handleSortPtSeenTodayDetail('ProcDate')}
              ><div className="tbl-header">ProcDate</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnX === 'ProcCode' ? this.state.directionX : null}
              onClick={() => this.handleSortPtSeenTodayDetail('ProcCode')}
              ><div className="tbl-header">ProcCode</div>
            </Table.HeaderCell>
            <Table.HeaderCell sorted={this.state.columnX === 'ProcFee' ? this.state.directionX : null}
              onClick={() => this.handleSortPtSeenTodayDetail('ProcFee')}
              ><div className="tbl-header">ProcFee</div>
            </Table.HeaderCell>
        </Table.Row>
        </Table.Header>

        <Table.Body>
            {Object.values(this.state.pmdPtSeenTodayDetail).map(
            ({ PatNum, ProcDate, ProcCode,ProcFee}) => {
              return (
              <Table.Row>
                  <Table.Cell>{this.props.practicename}</Table.Cell>
                  <Table.Cell>{PatNum}</Table.Cell>
                  <Table.Cell>{ProcDate}</Table.Cell>
                  <Table.Cell>{ProcCode}</Table.Cell>
                  <Table.Cell>{numberFormat(ProcFee)}</Table.Cell>
              </Table.Row>
              );
          }
          )}
          <Table.Row>
                  <Table.Cell className="pmdbold">TOTAL</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell className="pmdbold">{numberFormat(this.sumOfPtSeenToday(this.state.pmdPtSeenTodayDetail,"ProcFee"))}</Table.Cell>
              </Table.Row>
        </Table.Body>
      </Table> 
      </Modal.Content>

        <Modal.Actions>
        <Button onClick={() => this.sendPtSeenTodayDetailMailAction()} inverted color='orange'>
          Send Mail
        </Button>
        </Modal.Actions>
      </Modal>


    <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.open3}
        onClose={() => this.setOpen3(false)}
        onOpen={() => this.setOpen3(true)}
    >
      <Modal.Header><b>Sch Apt Today</b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
        <Label color={this.props.schAptToday>0?'green':'red'} floating>
          {this.props.schAptToday}
        </Label>
        <div className="ui top right attached label production_right_label">
          <Button onClick={() => this.setOpen3(false)} inverted>
            Close
          </Button>
        </div>
      </Modal.Header>
      <Modal.Content scrolling>
        <Table className="table table-striped table-hover my-0 FreezeTable sortable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell><div className="tbl-header">PatNum</div></Table.HeaderCell>
              <Table.HeaderCell><div className="tbl-header">Confirmation Status</div></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.props.schAptTodayData && this.props.schAptTodayData.length ? this.props.schAptTodayData.map((item: any) => {
                  return (
                      <Table.Row key={item.PatNum}>
                        <Table.Cell>{item.PatNum}</Table.Cell>
                        <Table.Cell>{item.Confirmation_Status}</Table.Cell>
                      </Table.Row>
                  );
                }
            ): ''}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>


    <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.open4}
        onClose={() => this.setOpen4(false)}
        onOpen={() => this.setOpen4(true)}
    >
      <Modal.Header><b>Expected Show</b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
        <Label color={this.getExpectedShow()>0 && isOpenToday?'green':'red'} floating>
          {this.getExpectedShow() && isOpenToday ? this.getExpectedShow() : 0}
        </Label>
        <div className="ui top right attached label production_right_label">
          <Button onClick={() => this.setOpen4(false)} inverted>
            Close
          </Button>
        </div>
      </Modal.Header>
      <Modal.Content scrolling>
        <Table className="table table-striped table-hover my-0 FreezeTable sortable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell><div className="tbl-header">Schedule Today 30 Day Completed</div></Table.HeaderCell>
              <Table.HeaderCell><div className="tbl-header">Schedule Today 30 Day Apts</div></Table.HeaderCell>
              <Table.HeaderCell><div className="tbl-header">Schedule Today</div></Table.HeaderCell>
              <Table.HeaderCell><div className="tbl-header">Rate</div></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row key={1}>
              <Table.Cell>{this.props.scheduleToday30DayCompletd}</Table.Cell>
              <Table.Cell>{this.props.scheduleToday30DayApts}</Table.Cell>
              <Table.Cell>{this.props.schAptToday && isOpenToday ? this.props.schAptToday: 0}</Table.Cell>
              <Table.Cell>
                ({this.props.scheduleToday30DayCompletd}/{this.props.scheduleToday30DayApts}) * {isOpenToday ? this.props.schAptToday : 0} = {isOpenToday ? this.getExpectedShow() : 0}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>


    <Modal
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={this.state.open5}
        onClose={() => this.setOpen5(false)}
        onOpen={() => this.setOpen5(true)}
    >
      <Modal.Header><b>Non Confirmed Appointments</b> <a className="ui orange label">{this.state.practicename.toLocaleUpperCase()}</a>
        <Label color={this.props.pmdNonConfirmedApts && this.props.pmdNonConfirmedApts.length>0?'green':'red'} floating>
          {this.props.pmdNonConfirmedApts ? this.props.pmdNonConfirmedApts.length : 0}
        </Label>
        <div className="ui top right attached label production_right_label">
          <Button onClick={() => this.setOpen5(false)} inverted>
            Close
          </Button>
        </div>
      </Modal.Header>
      <Modal.Content scrolling>
        <Table className="table table-striped table-hover my-0 FreezeTable sortable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell><div className="tbl-header">PatNum</div></Table.HeaderCell>
              <Table.HeaderCell><div className="tbl-header">ProcDate</div></Table.HeaderCell>
              <Table.HeaderCell><div className="tbl-header">Confirmation Status</div></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.props.pmdNonConfirmedApts && this.props.pmdNonConfirmedApts.length ? this.props.pmdNonConfirmedApts.map((item: any, key: number) => {
                  return (
                      <Table.Row key={item.PatNum}>
                        <Table.Cell>{item.PatNum}</Table.Cell>
                        <Table.Cell>{Moment(item.AptDateTime).format('YYYY-MM-DD')}</Table.Cell>
                        <Table.Cell>{item.Confirmation_Status}</Table.Cell>
                      </Table.Row>
                  );
                }
            ): ''}
          </Table.Body>
        </Table>
      </Modal.Content>
    </Modal>

  </>
)
  }
}

export default PMDScheduleToday;