import React, {useEffect, useState} from 'react';
import {useActions} from "../redux/actions";
import * as Actions from "../redux/actions/app";
import Template from "./Template";
import {Button, Icon, Table} from 'semantic-ui-react';
import moment from "moment";
import {useParams} from 'react-router-dom';

function PassCodes() {

    const [appActions] = useActions([Actions]);
    const [passCodes, setPassCodes] = useState([]);
    const {mco}:any = useParams();
    useEffect(() => {
        getCodes(mco);
    }, [mco]);

    const getCodes = (mco: string) => {
        appActions.getPassCodes(mco)
            .then((res: any) => {
                setPassCodes(res);
            })
    }

    return (
        <Template activeLink='mfa'>
            <div className="ui fluid card">
                <div className="content">
                    <h2 className="float-left mr10 mb10">Passcodes for MCNA 2FA authentication</h2>
                    <Button primary={true} onClick={() => getCodes(mco)}> Refresh List </Button>
                </div>
            </div>
            <div className="ui fluid card">
            <Table celled={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Pass Code</Table.HeaderCell>
                        <Table.HeaderCell>Time</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {passCodes.length && passCodes.map((item: any) => {
                       return (
                           <Table.Row key={item.id}>
                               <Table.Cell>{item.text}</Table.Cell>
                               <Table.Cell>{moment(item.createdAt).utc().format('LLLL')}</Table.Cell>
                           </Table.Row>
                       )
                    })}

                </Table.Body>
            </Table>
            </div>
        </Template>
    )
}

export default PassCodes;