import React, {useState, useEffect} from "react";
import Template from "../../components/Template";
import FilterSection from "../../components/FilterSection";
import ReportMain from "../../components/ReportMain";
import {connect} from 'react-redux';
import {useActions} from "../../redux/actions";
import * as reportActions from "../../redux/actions/report";
import * as appActions from "../../redux/actions/app";
import {RootState} from "../../model";
import {ReportProps, SendEmail} from "../../model/report";
import {ApplyFilter, OnChangeFilter} from "../../model/common";
import {getFilteredData} from "./Filters/Common";
import {reportInitialState, reportPageNames} from "../../constants";
import {exportPcrCSV} from "../../components/ExportCsv";
import ReactDOMServer from "react-dom/server";
import {sendPCREmail} from "../../components/sendEmail";
import RenderHtml from "./RenderHtml";
import DynamicHtmlTableMail from "../../components/DynamicHtmlTableMail";
import {SetupFields} from "../../enums/userSetupField";
import {SetupAppName} from "../../enums/userSetupAppName";
import * as userSettingsActions from "../../redux/actions/userSettings";
import {validArr} from "../../utils/common";

function ByBudgetByWorkday(props: ReportProps) {
	const reportAction = useActions(reportActions);
	const appAction = useActions(appActions)
	const [state, setStates] = useState(reportInitialState);
	const userSettingsAction = useActions(userSettingsActions);
	const data = state.data, page = reportPageNames.budgetWorkDay;

	const getPraticesRecords = async() => {
		const params = { field: SetupFields.Practice, appId: SetupAppName.CDP_MY };
		const result = await userSettingsAction.getSetup(params);
		const defaultPractices = result ? result.value.split(",") : [];
		const propVal = !!props.report.reportNewData ? props.report.reportNewData : props.reportTN.reportTimeAndName[0];
		if(propVal) {
			setStates(state => {
				const [,data] = getFilteredData(state.filters, props, page, 'byBudgetByWorkingReport');
				return { ...state, data};
			});
			if (validArr(defaultPractices)) {
				let practiceNamesMap: any = props.practice.practiceNamesMap;
				let practices: any[] = [];
				defaultPractices.forEach((practice: any) => {
					Object.entries(practiceNamesMap)
						.forEach(([, practiceInfo], index: number) => {
							let practiceDetails: any = practiceInfo;
							if (practiceDetails.practiceDBName === practice) {
								practices.push(practiceDetails.practice)
							}
						});
				})
				if (practices.length > 0) {
					state.filters.practice = practices;
					applyFilter(state.filters)
				}
			}
		}
		return await reportAction.filterByValue(reportInitialState.filters);
	};

	useEffect(() => { getPraticesRecords()},  [props]);
	const onChangeFilter:OnChangeFilter = async (filters, callApi=false) => {
		applyFilter(filters);
	};

	const applyFilter:ApplyFilter = (filters) => {
		const [selectedPractices, data] = getFilteredData(filters, props, page);
		appAction.UpdatePracticesOptions(selectedPractices)
		setStates({...state, filters, data, selectedPractices});
	};

	const sendEmail:SendEmail = (dataArray) => {
		if(data && data.tableData && Object.keys(data.tableData).length) {
			const pdf = Object.keys(data.tableData).map( key => (
				ReactDOMServer.renderToString(
					<DynamicHtmlTableMail
						key={key}
						className={`budget-by-work-day ${key}`}
						tableHead={data ? data.tableHead : []}
						heading={data ? data.tableData[key].heading : ""}
						tableData={data ? data.tableData[key].tableData : []}
					/>)
			));
			reportAction.sendReportData({ filters: state.filters, page: page, data: dataArray, pdf: pdf.join("") });
		}
	};

	return (
		<Template activeLink={`pcr-${page}`}>
			<ReportMain>
				<FilterSection page={page} heading="PCR By Goal By Work Day" filters={state.filters} onChangeFilter={onChangeFilter} exportCSV={()=>exportPcrCSV(data, page)} sendEmail={()=>sendPCREmail(data, page, sendEmail)} />
				<RenderHtml data={data} page={page} />
			</ReportMain>
		</Template>
	);

}

function mapStateToProps(state: RootState) {
	return {
		report: state.report,
		practice: state.practice,
		reportTN: state.reportTimeAndName
	};
}

export default connect(mapStateToProps)(ByBudgetByWorkday);