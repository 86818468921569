
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";

const initialState = { data: [] }
export const patientValidationHealthReducer = createReducer(initialState, {
    [ActionType.HEALTH_STATUS_PATIENT_VALIDATION](state: any, action: Action<any>) {
        return action.payload;
    }
});
