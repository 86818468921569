import React, {Component} from 'react'
import {Icon, Modal, List, Grid, Message} from 'semantic-ui-react';
import moment from "moment";


interface EditHistoryModalProps {
    auditLogs: any
    openState: boolean,
    onClose: any,
    title:string
}

      
function EditHistoryModal(props: EditHistoryModalProps) {
    const STATUS :any = {
        'DELETE': {name: 'trash', color: 'red'},
        'UPDATE': {name: 'edit', color: 'blue'},

    }


    const getLog=(log:{Users:object,action:string,createdAt:string,data:string}[])=> {
        let logObj: any ={};
        if(log.length>1){
            const combineData={}
             log.forEach((logItem:{Users:object,action:string,createdAt:string,data:string})=>{
                logObj.Users=logItem.Users;
                logObj.action=logItem.action;
                logObj.createdAt=logItem.createdAt;
                Object.assign(combineData,JSON.parse(logItem.data))
             })
             logObj.data=combineData;
        }else{
           logObj.Users=log[0].Users;
           logObj.action=log[0].action;
           logObj.createdAt=log[0].createdAt;
           logObj.data=JSON.parse(log[0].data);
        }
        let fullName = logObj.Users?logObj.Users.firstname+" "+logObj.Users.lastname:"";
        return <Message color={STATUS[logObj.action].color}>
            <Message.Header>
                <p className="float-right mb0">
                    <Icon name="calendar alternate"/>
                    <small>{moment(logObj.createdAt).format('DD-MMM-YYYY @HH:mm')}</small>
                </p>
                <h3 className="mb10 mt0">
                    <Icon
                        name={STATUS[logObj.action].name}
                        color={STATUS[logObj.action].color}
                    />
                    {fullName}
                </h3>
            </Message.Header>
            <br/>
            <Message.Content>
                <Grid>
                    <Grid.Row columns={4}>
                        {
                            Object.entries(logObj.data)
                                .filter(l => !l[0].endsWith('Id') && l[0].toLowerCase() !== 'id')
                                .map(l => <Grid.Column className="mb5">
                                    <span className="uppercase mr7">{l[0]}: </span>
                                    <b className="">{l[1] + ''}</b>
                                </Grid.Column>)
                        }
                    </Grid.Row>
                </Grid>
            </Message.Content>
        </Message>
    }


         const {auditLogs, title} = props;
        return (
            <Modal
                closeOnEscape={false}
                closeOnDimmerClick={false}
                onClose ={ props.onClose }
                open={props.openState}
                trigger={<Icon name="history"/>}
                size="large"
                closeIcon
            >
                <Modal.Content scrolling={true}>
                    <h3>{title} Edit History</h3>
                    <List divided={true}>
                        {
                            Object.keys(auditLogs).length>0 && Object.keys(auditLogs).map((dateKey) => {
                                return <List.Item>
                                    <List.Content>{getLog(auditLogs[dateKey])}</List.Content>
                                </List.Item>
                            })
                        }
                    </List>
                </Modal.Content>
            </Modal>
        );
    }
 export default EditHistoryModal;
