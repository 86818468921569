import React, {useEffect, useState} from "react";
import Template from "./Template";
import {Form, Grid, Checkbox, Button, Dropdown, Radio} from "semantic-ui-react";
import {useActions} from "../redux/actions";
import * as appActions from "../redux/actions/app";
import HelpMark from "./HelpMark";
import {defaultReport} from "../constants";

function OrthoProviderEarningsReport() {
    const appAction = useActions(appActions);
    const initialState: any = {
        setup: {
            toProvider: 0,
            toLoggedUser: 0,
            emailList: defaultReport,
            reportOptions: {} 
        }
    };

    const [state, setStates] = useState(initialState);

    useEffect(() => {
        getSetupData();
    }, []);

    const getSetupData = () => {
        appAction.getOrthoEarningsSendingSetup().then((res: any) => {
            if(res){
                const reportOptions = JSON.parse(res.reportOptions);
                const setup = {
                    toProvider: res.toProvider,
                    toLoggedUser: res.toLoggedUser,
                    emailList: res.emailList,
                    reportOptions: reportOptions
                };
                setStates({...state, setup: setup});
            }
        });
    };
    const onChange = (e: any, name: string) => {
        const setup = state.setup;
        setup[name] = e.target.value;
        setStates({...state, setup: setup});
    };
    const handleCheckBox = (value: any, name: string) => {
        const setup = state.setup;
        setup[name] = +value;
        setStates({...state, setup: setup});
    };
    const handleSubmit = () => {
        let setup = state.setup;
        setup.reportOptions = JSON.stringify(setup.reportOptions);
        appAction.saveOrthoEarningsSendingSetup(setup).then(() => {
            getSetupData();
        });
    };
    
    return (
        <Template activeLink='adjustment-acknowledgement'>
            <div className="ui card">
                <div className="content">
                    <h2 className="float-left mr10"> Ortho Earnings Sending - Setup <HelpMark pageId="90"/></h2>
                </div>
            </div>

            <Grid>
                <Grid.Row>
                    <Grid.Column computer={8} tablet={16} mobile={16}>
                        <div className="ui card">
                            <div className="content">
                                <Form className="formStyle">
                                    <Form.Group widths='equal'>
                                        <Form.Input
                                            fluid={true}
                                            label='Email List - (,) separated values are allowed'
                                            placeholder='Email List'
                                            value={state.setup.emailList}
                                            onChange={(e) => onChange(e, 'emailList')}
                                        />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <div className="field">
                                            <Checkbox
                                                name={'toLoggedUser'}
                                                label={"Send To  LoggedIn User"}
                                                className="mr30"
                                                checked={state.setup.toLoggedUser}
                                                onChange={(_e, {checked}) => handleCheckBox(checked, 'toLoggedUser')}
                                            />
                                        </div>
                                        <div className="field">
                                            <Checkbox
                                                label={"Send To  Provider"}
                                                name={'toProvider'}
                                                className="mr30"
                                                checked={state.setup.toProvider}
                                                onChange={(_e, {checked}) => handleCheckBox(checked, 'toProvider')}
                                            />
                                        </div>
                                    </Form.Group>
                                                                        
                                    <Form.Group className="mb0">
                                        <div className="field">
                                            <Button primary={true} onClick={() => handleSubmit()}>Save</Button>
                                        </div>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

        </Template>
    );

}

export default OrthoProviderEarningsReport;
