import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Dropdown, Form, Modal} from 'semantic-ui-react';
import ShowFilesModal from './ShowFilesModal';
import {HelpPages} from '../constants';
import FileUploader from "./FileUploader";

interface Props {
    action: any,
    addForm: any,
    editForm: object,
    saveForm: Function,
    hideForm: any,
    updateForm: Function,
}

function HelpForm(props: Props) {
    let subPageLst: any = [];
    useEffect(() => {

        const editForm: any = props.editForm;
        if (props.action === 'editForm' && editForm.parentPageId && HelpPages[editForm.parentPageId]) {
            const parentPageId = editForm.parentPageId;
            Object.keys(HelpPages).forEach(pageId => {
                const text = HelpPages[pageId].name;
                if (HelpPages[pageId].parent && HelpPages[pageId].parent === parentPageId)
                    subPageLst.push({text: text, value: pageId, key: pageId});
            });
        }
    })
    const initialState = {
        addForm: props.addForm,
        editForm: props.editForm,
        signedIconUrl: null,
        subPageList: subPageLst,
        showFiles: false,
        loading: false
    }
    const [state, setState] = useState(initialState);

    const showFiles = () => {
        setState({...state, showFiles: true});
    }

    const closeFilesModal = () => {
        setState({...state, showFiles: false});
    }

    const deleteFiles = async (keys: any) => {
        let formState = getFormState();
        if (keys.length) {
            keys.forEach((key: any) => {
                if (formState.fileLinks[parseInt(key)]) {
                    formState.fileLinks.splice(parseInt(key), 1);
                }
            });
        }
        setState({...state, ...formState, showFiles: false});
    }

    const getFormState = () => {
        if (props.action == "addForm") {
            return state["addForm"];
        }
        if (props.action == "editForm") {
            return state["editForm"];
        }
        return null
    }

    const saveForm = (event: any) => {
        event.preventDefault();
        // if(form.checkValidity()) {
        let formState = getFormState();
        if (!formState.parentPageId || formState.parentPageId === '') {
            alert('You must select a page.');
            return false;
        } else if (HelpPages[formState.parentPageId].totalChild && !formState.pageId) {
            alert('You must select a sub page.');
            return false;
        }
        // assign parentPageId as pageId if there is not sub page
        if (!formState.pageId || formState.pageId === '0') {
            formState.pageId = formState.parentPageId;
            formState.parentPageId = 0;
        }

        if (props.action === 'addForm')
            props.saveForm(formState);
        else
            props.updateForm(formState);
        //}
    }

    const onChange = (e: any, d?: any) => {
        let formState = getFormState();
        if (d && d.name)
            formState[d.name] = d.checked;
        else
            formState[e.target.name] = e.target.value;
        setState({...state, ...formState});
    }

    const onFinish = async (e: any) => {
        let formState = getFormState();
        if (!formState.fileLinks) {
            formState.fileLinks = []
        }
        formState.fileLinks.push(e.signedUrl.split('?').shift());
        setState({...state, loading: false});
    }

    const getPageList = () => {
        let pagesList: any = []
        if (Object.keys(HelpPages).length) {
            Object.keys(HelpPages).forEach(pageId => {
                const text = HelpPages[pageId].name + (HelpPages[pageId].totalChild ? " (" + HelpPages[pageId].totalChild + " sub pages)" : "")
                if (!HelpPages[pageId].parent)
                    pagesList.push({text: text, value: pageId, key: pageId});
            });
        }
        return pagesList;
    }

    const selectPage = (e: any, a: any, subPage: any) => {
        onChange({target: {name: a.name, value: a.value}});

        //Set sub page value 0 every time page changes
        if (!subPage)
            onChange({target: {name: "pageId", value: 0}});

        if (!subPage && HelpPages[a.value].totalChild) {
            let subPageList: any = [];
            Object.keys(HelpPages).forEach(pageId => {
                const text = HelpPages[pageId].name;
                if (HelpPages[pageId].parent && HelpPages[pageId].parent.toString() === a.value)
                    subPageList.push({text: text, value: pageId, key: pageId});
            });
            if (subPageList.length) {
                setState({...state, subPageList: subPageList});
            }
        }
    }
    const action: string = props.action;
    const subPageList: any = state.subPageList
    let fields = getFormState();
    const pageList = getPageList();


    return (
        <Modal
            open={true}
            onClose={props.hideForm}
            centered={false}
            style={{overflow: 'auto'}}
            closeIcon={{style: {top: '1.0535rem', right: '1rem'}, color: 'black', name: 'close'}}
            size="large"
        >
            <Modal.Header>{action === 'editForm' ? "Edit Help" : "Add New Help"}</Modal.Header>
            <Modal.Content scrolling>
                <Modal.Description>
                    <Form className="formStyle" onSubmit={saveForm}>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Heading</label>
                                <textarea name="heading" value={fields ? fields.heading : ""} onChange={onChange}
                                          required={true}/>
                            </Form.Field>

                            <Form.Field>
                                <label>Description</label>
                                <textarea className='form-control' name="description"
                                          value={fields ? fields.description : ""}
                                          onChange={onChange} required={true}/>
                            </Form.Field>
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Select Page</label>
                                <Dropdown
                                    required={true} search={true} fluid={true} selection={true} name="parentPageId"
                                    value={fields && fields.parentPageId ? fields.parentPageId.toString() : ''}
                                    onChange={(e, a) => selectPage(e, a, false)}
                                    placeholder='Select Page'
                                    options={pageList}/>
                            </Form.Field>
                            <Form.Field>
                                <label>Select Sub Page</label>
                                <Dropdown
                                    required={true}
                                    search={true}
                                    fluid={true}
                                    selection={true}
                                    name="pageId"
                                    value={fields && fields.pageId ? fields.pageId.toString() : ''}
                                    onChange={(e, a) => selectPage(e, a, true)}
                                    placeholder='Select Sub Page'
                                    options={subPageList}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <FileUploader
                              fileLinks={fields.fileLinks}
                              onFinishUploading={onFinish}
                              showFiles={showFiles}
                            />
                            <Form.Field>
                                <label>Active</label>
                                <Checkbox
                                    toggle={true}
                                    className='mt10'
                                    name='isActive'
                                    checked={(fields.isActive)}
                                    onChange={onChange}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <Button floated='right' primary={true}>Save</Button>
                        </Form.Field>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            {
                state.showFiles &&
                <ShowFilesModal
                    closeModal={closeFilesModal}
                    files={fields ? fields.fileLinks : ""}
                    deleteFiles={deleteFiles}
                />
            }
        </Modal>
    );
}

export default HelpForm
