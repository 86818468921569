import React, {useEffect, useState} from "react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as ReportActions from "../redux/actions/adjustmentCodes"
import {useActions} from "../redux/actions";
import {Button, Confirm, Icon, Search, Table} from "semantic-ui-react";
import _ from "lodash";
import AdjustmentListForm from "./AdjustmentListForm";
import HelpMark from "./HelpMark";


const initialState: any = {
    loading: false,
    cronStatus: 'All',
    adjustmentCodes: [],
    isEdit: false,
    initialValues: {adjustmentsCode: '', removeFromDrPay: false, id: 0},
    orderBy: null,
    order: true,
    search: ""
}

const AdjustmentCodes = () => {
    const reportActions = useActions(ReportActions);
    const [state, setStates] = useState(initialState);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null);

    useEffect(() => {
        reportActions.getAdjustmentCodes()
            .then((res: any) => {
                setStates({...state, adjustmentCodes: res});
            });
    }, []);

    const getSortRows = (sortColumn: string, orderLocal: string) => {
        const orderUpdated = !orderLocal;
        const adjustmentCodesSort = _.orderBy(adjustmentCodes, [sortColumn], [orderLocal ? 'asc' : 'desc'])
        setStates({...state, adjustmentCodes: adjustmentCodesSort, orderBy: sortColumn, order: orderUpdated});
    };

    const onSearch=(e: any, element: any) => {
        const obj={...state}
        obj.search =element.value;
        setStates(obj);
    };
    const edit = (obj: any) => {
        const initialValues = {
            ...obj
        };
        setStates({...state, isEdit: true, initialValues});
    };

    const cancelForm = () => {
        setStates({...state, isEdit: false, initialValues: {title: '', description: '', id: 0}});
    };

    const updateAdjustmentCodes = (data: any) => {
        const func = data.id ? "updateAdjustmentCodes" : 'saveAdjustmentCodes'
        reportActions[func](data).then(() => {
            reportActions.getAdjustmentCodes()
                .then((res: any) => {
                    setStates({
                        ...state,
                        adjustmentCodes: res,
                        isEdit: false,
                        initialValues: {title: '', description: '', id: 0}
                    });
                });
        })
    };

    const deleteAdjustmentCode = (item: any) => {
        setDeletingItem(item);
        setShowDeleteConfirmation(true);
    };

    const deleteConfirmed = (item: any) => {
        reportActions.deleteAdjustmentCodes(item).then(() => {
            reportActions.getAdjustmentCodes()
            .then((res: any) => {
                setStates({
                    ...state,
                    adjustmentCodes: res,
                    isEdit: false,
                    initialValues: {title: '', description: '', id: 0}
                });
            });
        });
        setShowDeleteConfirmation(false);
    };

    const {adjustmentCodes, search, orderBy, order, isEdit} = state;
    const direction = order ? 'sort down' : 'sort up';
    return (
        <Template activeLink="crons-list">
                <div className="ui card">
                    <div className="content pb0">
                        <h2 className="float-left mr10">UDA Adjustment Codes <HelpMark pageId='0'/></h2>
                        <div className="topFilters flex items-end flex-wrap">
                            <Button
                                primary={true}
                                onClick={() => edit({adjustmentsCode: '', removeFromDrPay: false, id: 0})}
                            >
                                Create New
                            </Button>
                            <div>
                                <Search
                                    placeholder='Search...'
                                    onSearchChange={onSearch}
                                    name={'search'}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                {isEdit &&
                    <>
                        <h2 className="headingMain">Edit</h2>
                        <AdjustmentListForm
                            cancelForm={() => cancelForm()}
                            saveForm={(data: any) => updateAdjustmentCodes(data)}
                            initialValues={state.initialValues}
                        />
                    </>
                }
            <div className="ui card">
                <div className="content">
                    <div className="FreezeTable">
                        <Table className="ui table table-striped table-hover celled unstackable no-wrap">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                                  className={`cursorPointer ${orderBy === 'adjustmentsCode' ? 'active' : ''}`}
                                                  onClick={() => getSortRows('adjustmentsCode', order)}
                                >
                                    <div>Adjustments Code
                                        <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/></div>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <div>REMOVE FROM DR PAY</div>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <div>Action</div>
                                </Table.HeaderCell>

                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {adjustmentCodes.length &&
                                adjustmentCodes.filter((item: any, index: any) => {
                                    if (!search) {
                                        return true
                                    } else {
                                        const values = Object.values(item).join(" ")
                                        return values.toLowerCase().includes(search.toLowerCase())
                                    }
                                }).map((item: any, index: any) => {
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell>{item.adjustmentsCode}</Table.Cell>
                                            <Table.Cell>{item.removeFromDrPay ? 'Yes' : 'No'}</Table.Cell>
                                            <Table.Cell>
                                                <Icon
                                                    className="cursorPointer"
                                                    name="edit"
                                                    onClick={() => edit(item)}
                                                />
                                                <Icon
                                                    className="cursorPointer ml10"
                                                    name="trash alternate"
                                                    onClick={() => deleteAdjustmentCode(item)}
                                                />

                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                        </Table.Body>
                    </Table>
                    </div>
                </div>
            </div>
            {
                showDeleteConfirmation &&
                <Confirm
                    open={showDeleteConfirmation}
                    onCancel={() => setShowDeleteConfirmation(false)}
                    onConfirm={() => deleteConfirmed(deletingItem)}
                    header="Confirm Delete"
                    content="Are you sure you want to delete this item?"
                    cancelButton="Cancel"
                    confirmButton="Delete"
                    confirmButtonClass="danger-confirm-button"
                />
            }
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        adjustmentCodes: state.adjustmentCodes,
    };
}

export default connect(mapStateToProps)(AdjustmentCodes);
