import React from "react";
import {Button, Header, List, Modal, Table} from "semantic-ui-react";

interface Props {
    handleClose : Function,
    result : any,
    message : string
}



function OpenDentalUserResult(props: Props) {
       return (
        <Modal
            closeIcon={true}
            open={true}
            onClose={() => props.handleClose(false)}
        >
            <Header content='Open Dental User Details' />
            <Modal.Content>
                <List size={'huge'} divided={true} verticalAlign='middle'>
                    {props.result && props.result.map((item:any)=> {
                        return(
                            <List.Item>
                                <List.Icon name={item.success ? 'checkmark' : 'attention'} color={item.success ? 'green' : 'red'}/>
                                <List.Content>
                                    <List.Header>{item.practice}</List.Header>
                                    <List.Description>
                                        {item.error ? item.error : props.message}
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        )
                    })}
                </List>
            </Modal.Content>
            <Modal.Actions>
                <Button negative={true} onClick={() => props.handleClose()}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default OpenDentalUserResult