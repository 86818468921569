import { reportPageNames } from "../../../constants";
import { SetupAppName } from "../../../enums/userSetupAppName";
import { SetupFields } from "../../../enums/userSetupField";
import { FilteredData } from "../../../model/common";
import { isEmpty } from "../../../utils/common";
import { filterObject } from "./BySplashboard";
import { budgetProCPVFilterObject } from "./budgetProCPVFilterObject";


export const getFilteredData:FilteredData = (filters, props, page, reportName, defaultPractices: any) => {
  let selectedPractices:string[] = [];
  let practicesOptions:string[] = [];
  let info:any =  props.practice.practiceNamesMap;
  info = page !== "budget-by-work-day" ? info && Object.values(info).filter((item:any) => item.PCR === 1):props.practice.practiceNamesMap ;
  let practiceListBrands:any = [];
  for (const key in info) {
    practiceListBrands.push({practiceName:info[key].practice,brand:info[key].brand})
  }



  if(filters && Object.keys(filters).length) {

    Object.keys(filters).forEach( (filter:string) => {
      // Apply Practice Filter
      if (defaultPractices && defaultPractices.length) {
        selectedPractices = defaultPractices
        practicesOptions = [...selectedPractices]
      }

      if(filter === 'practice' && filters[filter]) {

        selectedPractices = [...filters[filter]].filter(r => r).map((r:any)=>r.toLowerCase());

        practicesOptions=[...selectedPractices]
      }

      // Apply Region Filter
      if(filter === 'region') {
        if(filters[filter] && props.practice.regionPractices) {
          const regionFilter=filters[filter]
          const tempSelectedPracs:string[]=[]
          regionFilter.forEach((item)=>{
            if(props.practice.regionPractices[item]){
              tempSelectedPracs.push(
                  ...props.practice.regionPractices[item] as []
              )
            }
          })
          practicesOptions=Array.from(new Set([...practicesOptions,...tempSelectedPracs])).sort()
          if(filters['practice'].length==0){
            selectedPractices = Array.from(new Set([...tempSelectedPracs,...selectedPractices])).sort();
          }

        }
      }

      // Apply doctor Types Filter
      if(page !== reportPageNames.practice && filter === 'drType' && filters[filter]) {

        const doctorsFilters=filters[filter].map((data)=>+data);

        const tempSelectedPracs:string[]=[];


        doctorsFilters.forEach((item)=>{
          if(item){
            tempSelectedPracs.push(...props.practice.drPractices[item])
          }
        })
        practicesOptions=Array.from(new Set([...practicesOptions,...tempSelectedPracs])).sort()

        if(filters['practice'].length==0){
          selectedPractices = Array.from(new Set([...tempSelectedPracs,...selectedPractices])).sort();
        }
      }
      // Apply brands Filter
      if(page !== reportPageNames.practice && filter === 'brands' && filters[filter]){
        const tempBrandFilterPractices:any=[]
        filters[filter].forEach((brand:any)=>{
          const res= practiceListBrands.filter((item:any)=>item.brand===brand)

          tempBrandFilterPractices.push(
              ...res.map((prac:any)=>prac.practiceName.toLowerCase())
          )
        })
        practicesOptions=Array.from(new Set([...practicesOptions,...tempBrandFilterPractices])).sort()
        if(filters['practice'].length==0){
          selectedPractices = Array.from(new Set([...tempBrandFilterPractices,...selectedPractices])).sort();
        }
      }
    });
  }

  switch (page) {
    case reportPageNames.age:
      return [practicesOptions, filterObject(props, selectedPractices, reportName)];
    case reportPageNames.ageMix:
      return [practicesOptions, filterObject(props, selectedPractices, reportName)];
    case reportPageNames.insurance:
      return [practicesOptions,filterObject(props,selectedPractices, reportName)]
    case reportPageNames.insuranceMix:
      return [practicesOptions, filterObject(props, selectedPractices, reportName)];
    case reportPageNames.practice:
      return [practicesOptions, budgetProCPVFilterObject(props, selectedPractices, reportName)];
    case reportPageNames.budgetWorkDay:
      return [practicesOptions, budgetProCPVFilterObject(props, selectedPractices, reportName)];
    case reportPageNames.byProviderCpv:
      return [practicesOptions, budgetProCPVFilterObject(props, selectedPractices, reportName)];
    case reportPageNames.cpv:
      return [practicesOptions, filterObject(props, selectedPractices, reportName)];
    case reportPageNames.outlook:
      return [practicesOptions, filterObject(props, selectedPractices, reportName)];
    case reportPageNames.byRegionRollUp:
      return [practicesOptions, budgetProCPVFilterObject(props, selectedPractices, reportName)];
    case reportPageNames.byBrandRollUp:
      return [practicesOptions, budgetProCPVFilterObject(props, selectedPractices, reportName)];
    default:
      return [practicesOptions, filterObject(props, selectedPractices, reportName)];
  }
};

export const practiceOriginalNameOnHeader=(allPracticesOptions:any,returnObj:any)=>{
  const pracObj:any = {}
  allPracticesOptions.map( (item:any) => {pracObj[item.text.toLowerCase()]=item.text})
  return returnObj.tableHead.map((r:any)=>{ const pract = pracObj[r.title]||r.title;return {title:pract}})
}

export const practicePCRAllName =(allPractices:any,pcrCheck:any)=>{
  const info  = allPractices.filter((item1:any) => {
    return pcrCheck.find((item2:any) => item1 === item2.practice.toLowerCase())
  });
  return info;
}

export const allPracticeOptions=(allPracticesOptions:any)=>{
  const dataInfo = [{key: 'none', value: 'all', text: 'All Practices', lowerName:''}];
  if (dataInfo) {
    for (const key in allPracticesOptions) {
      dataInfo.push({text: allPracticesOptions[key].practice, value: allPracticesOptions[key].practice, key: allPracticesOptions[key].id.toString(),
        lowerName: allPracticesOptions[key].practice.toLowerCase()});
    }
  }
  return dataInfo;
}

export const getReportData = (props: any, reportName?: string)  => {
  const value: any = props.reportTimeAndName.length > 0 ?
      props.reportTimeAndName.map((i: any) => {
        if(i.name === reportName) {
          return i.reportNewData !=undefined && i.reportNewData.reportData !== undefined ? i.reportNewData.reportData : [];
        }

        return [];
      }) : [];
  const newArray = value.filter((i: any) => i.length !== 0);
  return !isEmpty(newArray) ? newArray[0] : {};
};


export const practiceRecords = async (
    props: any,
    state: any,
    appAction: any,
    practiceAction: any,
    userSettingsAction: any,
    page: any,
    setTodayGoalAllArrayAction?: string,
) => {
  const params = { field: SetupFields.Practice, appId: SetupAppName.CDP_MY };
  const userSettings = await userSettingsAction.getSetup(params);
  const practicesAll: any = await practiceAction.getPracticesList();

  let defaultPracticeList: string[] = [];
  const defaultPracticesValues: [] = userSettings ? userSettings.value.split(",") : [];

  if (defaultPracticesValues && defaultPracticesValues.length > 1) {
    practicesAll.forEach((item: any) => {
      defaultPracticesValues.forEach((dp: any) => {
        if (item.practiceDBName === dp) {
          defaultPracticeList.push(item.practice)
        }
      })
    })
    defaultPracticesValues.shift();
  }

  const defaultPractices: any = defaultPracticeList;

  const [selectedPractices, data] =  getFilteredData(state.filters, props, page, setTodayGoalAllArrayAction, defaultPractices);
  let stateObj = { ...state, data, selectedPractices, defaultPractices}
  if (defaultPractices && defaultPractices.length) {
    state.filters.practice = defaultPractices;
  }

  appAction.UpdatePracticesOptions(selectedPractices);

  return stateObj
}