import React from "react";
import {Button, Modal} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";
import { Link } from "react-router-dom";

function OrthoPCRNoUserPermissionsModal (props: any) {
    
    return (
        <Modal 
        closeOnEscape={false}
        closeOnDimmerClick={false}
        className={'modalCenter'}
               onClose={() => props.handleClose()}
               open={props.open}
        >
            <Modal.Header>ACCESS DENIED <Link to='/partners'><Button
                className="float-right"
                content="close"
                primary={true}
                onClick={() => props.handleClose()}
            /></Link></Modal.Header>
            <Modal.Content scrolling>
            <p>The current user does not have permissions to view Ortho Data. Kindly contact your Administrator</p>
            </Modal.Content>
            <Modal.Actions>
            </Modal.Actions>
        </Modal>
    );
}


function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app,
    };
}
export default connect(mapStateToProps)(OrthoPCRNoUserPermissionsModal);
