import React, {useState, useEffect} from "react";
import Template from "../../components/Template";
import FilterSection from "../../components/FilterSection";
import ReportMain from "../../components/ReportMain";
import {connect, useSelector} from 'react-redux';
import {useActions} from "../../redux/actions";
import * as reportActions from "../../redux/actions/report";
import * as appActions from "../../redux/actions/app";
import {RootState} from "../../model";
import {ReportProps, SendEmail} from "../../model/report";
import {ApplyFilter, OnChangeFilter} from "../../model/common";
import {getFilteredData} from "./Filters/Common";
import {reportInitialState, reportPageNames} from "../../constants";
import {exportPcrCSV} from "../../components/ExportCsv";
import ReactDOMServer from "react-dom/server";
import {sendPCREmail} from "../../components/sendEmail";
import RenderHtml from "./RenderHtml";
import DynamicHtmlTableMail from "../../components/DynamicHtmlTableMail";
import { getActiveFillterStatus } from "./Filters/FilterStatus";
import {SetupFields} from "../../enums/userSetupField";
import {SetupAppName} from "../../enums/userSetupAppName";
import {validArr} from "../../utils/common";
import * as userSettingsActions from "../../redux/actions/userSettings";
import { Dimmer, Loader } from "semantic-ui-react";
import Loading from "../../Loading";

function ByOutlook(props: ReportProps) {
	const reportAction = useActions(reportActions);
	const appAction = useActions(appActions)
	const userSettingsAction = useActions(userSettingsActions);
	const [state, setStates] = useState(reportInitialState);
	const data = state.data, page = reportPageNames.outlook;
	const [loader, setLoader] = useState(false);
	const filByVal: any = useSelector((state: RootState) => state.reportFilVal) || {};

	useEffect(() => { getPracticesRecords()},  [props]);

	const getPracticesRecords = async() => {
		const propVal = !!props.report.reportNewData ? props.report.reportNewData : props.reportTN.reportTimeAndName[0];
		const defaultPractices = await getDefaultPractices();
		let filters:any={...state.filters};
		if(state.filters.practice.length===0){
			 filters=await setDefaultPractices(defaultPractices,filters);
		}
		if(propVal) {
			setStates((state: any) => {
				const [selectedPractices, data] = getFilteredData(filters, props, page, 'byOutlookReport');
				appAction.UpdatePracticesOptions(selectedPractices);
				return { ...state,filters, data, selectedPractices};
			});
		}
		await reportAction.filterByValue(reportInitialState.filters);
	}

	const getDefaultPractices= async() => {
		const params = { field: SetupFields.Practice, appId: SetupAppName.CDP_MY };
		const result = await userSettingsAction.getSetup(params);
		const defaultPractices = result ? result.value.split(",") : [];
		return defaultPractices;
	}
	const setDefaultPractices= async(defaultPractices:any,filters:any) => {
		if (validArr(defaultPractices)) {
			let practiceNamesMap: any = props.practice.practiceNamesMap;
			let practices: any[] = [];
			defaultPractices.forEach((practice: any) => {
				Object.entries(practiceNamesMap)
					.forEach(([key, practiceInfo], index: number) => {
						let practiceDetails: any = practiceInfo;
						if (practiceDetails.practiceDBName === practice) {
							practices.push(practiceDetails.practice)
						}
					});
			})
			if (practices.length > 0) {
				filters.practice = practices;
			}
		}
		return filters;
	}
	
	const onChangeFilter:OnChangeFilter = async (filters) => {
		filters.month && await reportAction.resetReportData();
		reportAction.filterByValue(filters);
		applyFilter(filters);
		setLoader(true);
		await reportAction.filterByValue(reportInitialState.filters);
		setLoader(false);
	};

	const applyFilter:ApplyFilter = (filters) => {
		const propsData=JSON.parse(JSON.stringify(props));
		const [selectedPractices, data] = getFilteredData(filters, propsData, page);
		appAction.UpdatePracticesOptions(selectedPractices)
		setStates({...state, filters, data, selectedPractices});
	};

	const sendEmail:SendEmail = (dataArray) => {
		if(data && data.tableData && Object.keys(data.tableData).length) {
			const pdf = Object.keys(data.tableData).map( key => (
				ReactDOMServer.renderToString(
					<DynamicHtmlTableMail
						key={key}
						className={`by-age ${key}`}
						tableHead={data ? data.tableHead : []}
						heading={data ? data.tableData[key].heading : ""}
						tableData={data ? data.tableData[key].tableData : []}
					/>)
			));
			reportAction.sendReportData({ filters: state.filters, page: page, data: dataArray, pdf: pdf.join("") });
		}
	};

	return (
		<Template activeLink={`pcr-${page}`}>
			{loader && <Dimmer active={true} inverted={true}>
				<Loading />
			</Dimmer>
			}
			<ReportMain>
				<FilterSection page={page} heading="PCR By Outlook" filters={state.filters} onChangeFilter={onChangeFilter} exportCSV={()=>exportPcrCSV(data, page)} sendEmail={()=>sendPCREmail(data, page, sendEmail)} />
				<RenderHtml data={data} page={page} />
			</ReportMain>
		</Template>
	);

}

function mapStateToProps(state: RootState) {
	return {
		report: state.report,
		practice: state.practice,
		reportTN: state.reportTimeAndName
	};
}

export default connect(mapStateToProps)(ByOutlook);