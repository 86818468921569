import { Action, ActionType } from "../../model/actionTypes";
import { PMDProgress } from "../../model/pmdprogress";
import createReducer from "./createReducer";
const initialState:PMDProgress = {
	progressRefresh: 0
};
export const pmdprogress = createReducer(initialState, {
	[ActionType.INCREMENT](state: PMDProgress, action: Action<PMDProgress>) {
		return Object.assign({}, initialState, { progressRefresh : state.progressRefresh += 1});
    },
	[ActionType.DECREMENT](state: PMDProgress, action: Action<PMDProgress>) {
		return Object.assign({}, initialState, { progressRefresh : 0});
    }
});
