import {
    csvHeader,
    csvPatientValidationPracticeLogsReport,
    enabledCarrierColor,
    enabledCarrierList,
    enabledcategoryList,
    enabledStatusCarrierColor,
    insuranceList,
    isSafari,
    statusList
} from "../constants";
import {
    ExportClinicalReport,
    ExportCSV,
    ExportHTMLPVAReport,
    ExportHTMLPVSReport,
    ExportLoginPracticeReport,
    ExportOpenSurgeryHtmlReport,
    ExportOpenSurgeryReport,
    ExportPatientValidationActionReport,
    ExportPatientValidationStatusReport,
    ExportPcrCSV,
    ExportPTVMDHDReport,
    ExportPTVMDHReport,
    ExportUsagePTVReport,
    ExportUsagePTVWOReport,
    ExportUserMatrixReport
} from "../model/common";
import {ClinicalDataReport, loginPracticeReportField, PcrTableResults} from "../model/report";
import {sortBy} from "../utils/common";
import moment from "moment";
import React from "react";

export const exportCSV: ExportCSV = (fileName, csvData) => {
    const csv = csvData.join('\r\n');
    const dataType = isSafari() ? 'application/csv' : 'text/csv';
    const blob = new Blob(['', csv], {type: dataType});
    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
};

export const exportPcrCSV: ExportPcrCSV = (data, page) => {
    if (!data) {
        alert("Data is not available");
        return false;
    }
    const fileName = `pcr-${page}.csv`;
    const csvData = pcrTableResults(data, page);
    if (csvData.length) {
        exportCSV(fileName, csvData);
    }
};

export const pcrTableResults: PcrTableResults = (data, page) => {
    const csvData: string[][] = [];
    Object.keys(data.tableData).forEach(key => {
        csvData.push([data.tableData[key].heading]);
        csvData.push(data.tableHead.map(item => item.title.toString().toUpperCase()));
        data.tableData[key].tableData.forEach(rowArray => {
            csvData.push(rowArray.map(item => {
                if (item.title) {
                    let text = item.title.toString().replace(/"/g, '""');
                    if (text.search(/([",\n])/g) >= 0) {
                        text = '"' + text + '"';
                    }
                    return text.toString();   
                }

                return "";
            }));
        });
        csvData.push([""]);//Insert blank row
    });
    return csvData;
};

export const exportClinicalReport: ExportClinicalReport = (data) => {
    let csvData = [];
    csvData.push(csvHeader);
    const practices = Object.keys(data);
    const toCurrency = (value: number) => `$${(+(value || 0)).toFixed(2)}`;

    sortBy(practices);
    practices.forEach((key: string) => {
        if (data[key] && data[key].length > 0) {
            data[key].forEach((item: ClinicalDataReport) => {
                let rows = [];
                rows.push(`${key.toUpperCase()},${item.aptDate}-${item.day}`,
                    item.aptCount, item.show, item.drCount,
                    item.ptsPerDentist,toCurrency(item.totalScheduledCare) , toCurrency(item.expectedScheduledCare), item.allAptConfirmed,item.txAptsConfirmed,item.confirmedDigitally);
                csvData.push(rows);
            });
        }
    });
    exportCSV("CSR.csv", csvData);
};

export const  exportPatientValidationPracticeLogsReportData = (data: loginPracticeReportField[]) => {
    const csvData = data.map(item => [
        item.Date,
        item.practice,
        `${item.mgr.firstName} ${item.mgr.lastName}`,
        `${item.regMgr.firstName} ${item.regMgr.lastName}`
    ]);
    // Adding the CSV header to the beginning of csvData
    csvData.unshift(csvPatientValidationPracticeLogsReport);
    return csvData;
}

export const exportPatientValidationPracticeLogsReport: ExportLoginPracticeReport = (data) => {
    const csvData = exportPatientValidationPracticeLogsReportData(data);
    exportCSV("exportPatientValidationPracticeLogs.csv", csvData);
};

export const exportPatientValidationStatusReport: ExportPatientValidationStatusReport =
    (startDate: any, csvHeaderInfo: any, infoDataExcel: any, carrierInfo: any, insuranceInfo: any, excel: any) => {
        let csvData: any = [];
        csvData.push("");
        csvData.push(["Date :-" + startDate, "Patient Validation Status Reports"]);
        let returnArrayList: any = [];
        let returnArrayListIns: any = [];
        let otherHeader: any = [];
        csvHeaderInfo.forEach((item: any, key: any) => {
            otherHeader.push("");
            otherHeader.push(item);
            otherHeader.push("");
            otherHeader.push("");
        });

        if (otherHeader.length === 4) {
            enabledCarrierColor.forEach((item: any, key: any) => {
                const name: any = enabledCarrierColor[key];
                returnArrayListIns.push(name)
            });
            returnArrayListIns.unshift("practice");
            csvHeaderInfo.unshift("");
            csvData.push(otherHeader);
            csvData.push(returnArrayListIns);
        } else {
            enabledCarrierColor.forEach((item: any, key: any) => {
                returnArrayList.push(enabledCarrierColor);
            });
            const csvInfo: any = returnArrayList[0].concat(returnArrayList[1], returnArrayList[2], returnArrayList[3])
            csvHeaderInfo.unshift("");
            csvData.push(otherHeader);
            csvInfo.unshift("Practice");
            csvData.push(csvInfo);
        }

        if (infoDataExcel && Object.keys(infoDataExcel).length > 0) {
            const practices = Object.keys(infoDataExcel);
            const carrierList: any = carrierInfo;
            practices.forEach((practiceName: any, index: any) => {
                let returnArrayData: any = [];
                const data: any = infoDataExcel[practiceName];
                const objData: any = {[practiceName]: insuranceList};
                if (insuranceInfo) {
                    Object.assign(objData, {[practiceName]: insuranceList[insuranceInfo]})
                }
                let statusItem: any = false;
                if (data && data.length > 0) {
                    carrierList.forEach((item: any, key: any) => {
                        const filterCarrierCheck = data.filter((word: any) => word.CarrierName === item.modifyCarrierName);
                        if (filterCarrierCheck && filterCarrierCheck.length > 0) {
                            statusItem = true;
                        }
                    });
                    let [greenM, redXM, redAM, noImageM, greenD, redXD, redAD, noImageD, greenT, redXT, redAT, noImageT,
                        greenU, redXU, redAU, noImageU]: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    data.forEach((item: any, key: any) => {
                        const isCarrierExist = carrierList.filter((carrier: any) => carrier.carrierName === item.CarrierName);
                        const objectInfo = isCarrierExist && isCarrierExist.length > 0;
                        if (objectInfo) {
                            let name: any = isCarrierExist[0].modifyCarrierName;
                            switch (item.status) {
                                case 'PT Assigned To Practice Active Insurance': {
                                    switch (name) {
                                        case 'MCNA': {
                                            greenM++;
                                            break;
                                        }
                                        case 'DentaQuest': {
                                            greenD++;
                                            break;
                                        }
                                        case 'TMHP': {
                                            greenT++;
                                            break;
                                        }case 'UHC Medicaid': {
                                            greenU++;
                                            break;
                                        }

                                    }
                                    break;
                                }
                                case 'PT Does NOT Have Active Insurance': {
                                    switch (name) {
                                        case 'MCNA': {
                                            redXM++;
                                            break;
                                        }
                                        case 'DentaQuest': {
                                            redXD++;
                                            break;
                                        }
                                        case 'TMHP': {
                                            redXT++;
                                            break;
                                        }case 'UHC Medicaid': {
                                            redXU++;
                                            break;
                                        }

                                    }
                                    break;
                                }
                                case 'PT Has Active Insurance But Not Assigned To Practice': {
                                    switch (name) {
                                        case 'MCNA': {
                                            redAM++;
                                            break;
                                        }
                                        case 'DentaQuest': {
                                            redAD++;
                                            break;
                                        }
                                        case 'TMHP': {
                                            redAT++;
                                            break;
                                        }case 'UHC Medicaid': {
                                            redAU++;
                                            break;
                                        }

                                    }
                                    break;
                                }
                                default : {
                                    switch (name) {
                                        case 'MCNA': {
                                            noImageM++;
                                            break;
                                        }
                                        case 'DentaQuest': {
                                            noImageD++;
                                            break;
                                        }
                                        case 'TMHP': {
                                            noImageT++;
                                            break;
                                        }case 'UHC Medicaid': {
                                            noImageU++;
                                            break;
                                        }

                                    }
                                    break;

                                }
                            }
                        }
                    });

                    switch (insuranceInfo) {
                        case 'MCNA': {
                            Object.assign(objData[practiceName], {
                                "MCNA": {green: greenM, redA: redAM, redX: redXM, noImage: noImageM},
                            });
                            break;
                        }
                        case 'DentaQuest': {
                            Object.assign(objData[practiceName], {
                                "DentaQuest": {green: greenD, redA: redAD, redX: redXD, noImage: noImageD},
                            });
                            break;
                        }
                        case 'TMHP': {
                            Object.assign(objData[practiceName], {
                                "TMHP": {green: greenT, redA: redAT, redX: redXT, noImage: noImageT},
                            });
                            break;
                        }
                        case 'UHC Medicaid': {
                            Object.assign(objData[practiceName], {
                                "UHC Medicaid": {green: greenU, redA: redAU, redX: redXU, noImage: noImageU},

                            });
                            break;
                        }
                        default:{
                            Object.assign(objData[practiceName], {
                                "MCNA": {green: greenM, redA: redAM, redX: redXM, noImage: noImageM},
                                "DentaQuest": {green: greenD, redA: redAD, redX: redXD, noImage: noImageD},
                                "TMHP": {green: greenT, redA: redAT, redX: redXT, noImage: noImageT,},
                                "UHC Medicaid": {green: greenU, redA: redAU, redX: redXU, noImage: noImageU},
                            });
                        }
                    }


                    Object.keys(objData).forEach((key1: any, dataRecords: any) => {
                        const data = Object.values(objData);
                        const records: any = data[dataRecords];
                        Object.keys(records).forEach((key2: any) => {
                            enabledCarrierList.forEach((dataValue: any, key3: any) => {
                                if (key2 === dataValue) {
                                    if (key3 == 1 || insuranceInfo) {
                                        returnArrayData.push(practiceName);
                                    }
                                    returnArrayData.push(records[dataValue].green);
                                    returnArrayData.push(records[dataValue].redA);
                                    returnArrayData.push(records[dataValue].redX);
                                    returnArrayData.push(records[dataValue].noImage);
                                }
                            });
                        });
                    });
                }
                {
                    returnArrayData && statusItem && returnArrayData.length > 0 ? csvData.push(returnArrayData) : csvData.push([practiceName, "No records Found"])
                }
            });
            {
                excel && exportCSV("PVS.csv", csvData);
            }
            return csvData;
        }
    };


export const exportPatientValidationActionReport: ExportPatientValidationActionReport =
    (startDate: any, csvHeaderInfo: any, infoDataExcel: any, carrierInfo: any, insuranceInfo: any, excel: any,defaultPractice:any) => {
        let csvData: any = [];
        csvData.push("");
        csvData.push(["Date :-" + startDate, "Patient Validation Action Reports"]);
        let returnArrayList: any = [];
        let returnArrayListIns: any = [];
        let otherHeader: any = [];
        csvHeaderInfo.forEach((item: any, key: any) => {
            otherHeader.push("");
            otherHeader.push(item);
            otherHeader.push("");

        });
        if (csvHeaderInfo.length === 3) {
            enabledStatusCarrierColor.forEach((item: any, key: any) => {
                const name: any = enabledStatusCarrierColor[key];
                returnArrayListIns.push(name)
            });
            returnArrayListIns.unshift("practice");
            csvHeaderInfo.unshift("");
            csvData.push(otherHeader);
            csvData.push(returnArrayListIns);
        } else {
            enabledStatusCarrierColor.forEach((item: any, key: any) => {
                returnArrayList.push(enabledStatusCarrierColor);
            });
            const csvInfo: any = returnArrayList[0].concat(returnArrayList[1], returnArrayList[2])
            csvHeaderInfo.unshift("");
            csvData.push(otherHeader);
            csvInfo.unshift("Practice");
            csvData.push(csvInfo);
        }

        if (infoDataExcel && Object.keys(infoDataExcel).length > 0) {
            const returnArrayListInsPractice = ['Total'];
            const practices = Object.keys(infoDataExcel);

            let [greyETotal, greenETotal, redETotal,
                greyVTotal, greenVTotal, redVTotal,
                greyHTotal, greenHTotal, redHTotal]: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, ];

            const finalPractices = defaultPractice && defaultPractice.length > 0 ?  defaultPractice[0] ==='all'? practices:
                defaultPractice : practices;

            finalPractices.forEach((practiceName: any, index: any) => {
                let returnArrayData: any = [];
                const data: any = infoDataExcel[practiceName];
                const objData: any = {[practiceName]: statusList};
                if (insuranceInfo) {
                    Object.assign(objData, {[practiceName]: statusList[insuranceInfo]})
                }

                if (data && data.length > 0) {

                    let [greyE, greenE, redE,
                        greyV, greenV, redV,
                        greyH, greenH, redH]: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, ];

                    data.forEach((item: any, key: any) => {

                        switch (item.Details.statusE) {
                            case 0: {
                                redE++;
                                break;
                            }
                            case 1: {
                                greenE++;
                                break;
                            }
                            case 2: {
                                greyE++;
                                break;
                            }
                        }

                        switch (item.Details.statusV) {
                            case 1: {
                                greyV++;
                                break;
                            }
                            case 2: {
                                greenV++;
                                break;
                            }
                            case 3: {
                                redV++;
                                break;
                            }
                        }

                        switch (item.Details.statusH) {
                            case 0: {
                                redH++;
                                break;
                            }
                            case 1: {
                                greenH++;
                                break;
                            }
                            case 2: {
                                greyH++;
                                break;
                            }
                        }
                    });

                    switch (insuranceInfo) {
                        case 'Error': {
                            Object.assign(objData[practiceName], {
                                "Error": {grey: greyE, green: greenE, red: redE},
                            });
                            break;
                        }
                        case 'Verified': {
                            Object.assign(objData[practiceName], {
                                "Verified": {grey: greyV, green: greenV, red: redV},
                            });
                            break;
                        }
                        case 'Main Dental Home': {
                            Object.assign(objData[practiceName], {
                                "Main Dental Home": {grey: greyH, green: greenH, red: redH},
                            });
                            break;
                        }
                        default: {
                            Object.assign(objData[practiceName], {
                                "Error": {grey: greyE, green: greenE, red: redE},
                                "Verified": {grey: greyV, green: greenV, red: redV},
                                "Main Dental Home": {grey: greyH, green: greenH, red: redH},
                            });
                        }
                    }

                    Object.keys(objData).forEach((key1: any, dataRecords: any) => {
                        const data = Object.values(objData);
                        const records: any = data[dataRecords];

                        Object.keys(records).forEach((key2: any) => {

                            enabledcategoryList.forEach((dataValue: any, key3: any) => {
                                if (key2 === dataValue) {

                                    switch (key3) {
                                        case 1: {
                                            greyETotal = greyETotal + records[dataValue].grey;
                                            greenETotal = greenETotal + records[dataValue].green;
                                            redETotal = redETotal + records[dataValue].red;
                                            break;
                                        }
                                        case 2: {
                                            greyVTotal = greyVTotal + records[dataValue].grey;
                                            greenVTotal = greenVTotal + records[dataValue].green;
                                            redVTotal = redVTotal + records[dataValue].red;
                                            break;
                                        }
                                        case 3: {
                                            greyHTotal = greyHTotal + records[dataValue].grey;
                                            greenHTotal = greenHTotal + records[dataValue].green;
                                            redHTotal = redHTotal + records[dataValue].red;
                                            break;
                                        }
                                    }

                                    if (key3 == 1 || insuranceInfo) {
                                        returnArrayData.push(practiceName);
                                    }
                                    returnArrayData.push(records[dataValue].grey);
                                    returnArrayData.push(records[dataValue].green);
                                    returnArrayData.push(records[dataValue].red);
                                }
                            });
                        });
                    });
                }

                {
                    returnArrayData && returnArrayData.length > 0 ? csvData.push(returnArrayData) : csvData.push([practiceName, "No records Found"])
                }
            });

            returnArrayListInsPractice.push(greyETotal);
            returnArrayListInsPractice.push(greenETotal);
            returnArrayListInsPractice.push(redETotal);

            returnArrayListInsPractice.push(greyVTotal);
            returnArrayListInsPractice.push(greenVTotal);
            returnArrayListInsPractice.push(redVTotal);

            returnArrayListInsPractice.push(greyHTotal);
            returnArrayListInsPractice.push(greenHTotal);
            returnArrayListInsPractice.push(redHTotal);

            csvData.push(returnArrayListInsPractice);


            {
                excel && exportCSV("PVA.csv", csvData);
            }
            return csvData;
        }
    };



export const exportUsagePTVReport: ExportUsagePTVReport = (usageData: any) => {
    let csvData = [];
    let header: Array<string> = []
    usageData.header.forEach((item: { title: string }) => {
        header.push(item.title);
    })
    csvData.push(header);
    usageData.data.forEach((item: []) => {
        csvData.push(item);
    })
    exportCSV("UsagePTVReport.csv", csvData);
};

export const exportUsagePTVWOReport: ExportUsagePTVWOReport = (usageData: any) => {
    let csvData = [];
    let header: Array<string> = []
    usageData.header.forEach((item: { title: string }) => {
        header.push(item.title);
    })
    csvData.push(header);
    usageData.data.forEach((item: []) => {
        csvData.push(item);
    })
    exportCSV("UsagePTVWOReport.csv", csvData);
};


export const exportPTVMDHReport: ExportPTVMDHReport = (data: any) => {
    let csvData = [];
    let header: Array<string> = []
    data.header.forEach((item: { title: string }) => {
        header.push(item.title);
    })
    csvData.push(header);
    data.data.forEach((item: []) => {
        csvData.push(item);
    })
    exportCSV("PTVMDHReport.csv", csvData);
};

export const exportPTVMDHDReport: ExportPTVMDHDReport = (data: any) => {
    let csvData = [];
    let header: Array<string> = []
    data.header.forEach((item: { title: string }) => {
        header.push(item.title);
    })
    csvData.push(header);
    data.data.forEach((item: []) => {
        csvData.push(item);
    })
    data.status === 2 ? exportCSV("PTVMDHHReport.csv", csvData) : data.status === 3 ? exportCSV("PTVMDHWReport.csv", csvData) : exportCSV("PTVMDHDReport.csv", csvData);

};
export const  numberWithCommas = (x: string) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export const exportPDFPVAReport: ExportHTMLPVAReport = (startDate: any, csvHeaderInfo: any, infoDataExcel: any, carrierInfo: any, insuranceInfo: any,defaultPractice:any) => {
    let mainHtml: any = `<div class="container"><div class="ui card"><div class="content"><h3 style="margin-top: 0px;margin-left::5px;">Date:${startDate}</h3><div class="table-responsive FreezeTable">
                        <table class="ui celled single line table unstackable tableStyle PVA">`;
    let firstHeader: any = '';

    csvHeaderInfo.forEach((item: any, key: any) => {
        firstHeader += `<th key="${key}" colspan="3" style="text-align: center" >${item}</th>`;
    });
    let secondHeader: any = '';
    let thirdHeader: any = '';
    {
        csvHeaderInfo.length === 1 ? enabledStatusCarrierColor.forEach((item: any, key: any) => {
            let secondHeaderData = '';
            if (key === 0) {
                secondHeaderData += `<i class="fa fa-check-circle grey" aria-hidden="true"></i>`;
            } else if (key === 1) {
                secondHeaderData += `<i class="fa fa-check-circle green" aria-hidden="true"></i>`;
            }  else {
                secondHeaderData += `<i class="fa fa-check-circle red" aria-hidden="true"></i>`;
            }
            secondHeader += `<th key="${key}" style="text-align: center" >${secondHeaderData}</th>`;

        }) : enabledStatusCarrierColor.forEach((item: any, key: any) => {
            let thirdHeaderData = '';
            if (key === 0) {
                thirdHeaderData += `<i class="fa fa-check-circle grey" aria-hidden="true"></i>`;
            } else if (key === 1) {
                thirdHeaderData += `<i class="fa fa-check-circle green" aria-hidden="true"></i>`;
            } else {
                thirdHeaderData += `<i class="fa fa-check-circle red" aria-hidden="true"></i>`;
            }
            thirdHeader += `<th key="${key}" style="text-align: center">${thirdHeaderData}</th>`;
        })
    }

    if (csvHeaderInfo.length === 1) {
        mainHtml += `<thead><tr><th>&nbsp;</th>${firstHeader}</tr><tr><th>Practice</th>${secondHeader}</tr></thead>`;
    } else {
        mainHtml += `<thead><tr><th>&nbsp;</th>${firstHeader}</tr><tr><th>Practice</th>${thirdHeader}${thirdHeader}${thirdHeader}</tr></thead>`;
    }
    let newStrHtmlBodyInner: any = '<tbody>';
    let [greyETotal, greenETotal, redETotal,
        greyVTotal, greenVTotal, redVTotal,
        greyHTotal, greenHTotal, redHTotal]: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, ];
    if (infoDataExcel && Object.keys(infoDataExcel).length > 0) {

        const practices = Object.keys(infoDataExcel);
        const finalPractices = defaultPractice && defaultPractice.length > 0 ?  defaultPractice[0] ==='all'? practices:
            defaultPractice : practices;
        finalPractices.forEach((practiceName: any, index: any) => {
            newStrHtmlBodyInner += `<tr key="${practiceName}"><td>${practiceName}</td>`;
            const data: any = infoDataExcel[practiceName];
            const objData: any = {[practiceName]: statusList};

            if (insuranceInfo) {
                Object.assign(objData, {[practiceName]: statusList[insuranceInfo]})
            }
            if (data && data.length > 0) {
                let [greyE, greenE, redE,
                    greyV, greenV, redV,
                    greyH, greenH, redH]: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, ];

                data.forEach((item: any, key: any) => {

                    switch (item.Details.statusE) {
                        case 0: {
                            redE++;
                            break;
                        }
                        case 1: {
                            greenE++;
                            break;
                        }
                        case 2: {
                            greyE++;
                            break;
                        }
                    }

                    switch (item.Details.statusV) {
                        case 1: {
                            greyV++;
                            break;
                        }
                        case 2: {
                            greenV++;
                            break;
                        }
                        case 3: {
                            redV++;
                            break;
                        }
                    }

                    switch (item.Details.statusH) {
                        case 0: {
                            redH++;
                            break;
                        }
                        case 1: {
                            greenH++;
                            break;
                        }
                        case 2: {
                            greyH++;
                            break;
                        }
                    }
                });

                    switch (insuranceInfo) {
                        case 'Error': {
                            Object.assign(objData[practiceName], {
                                "Error": {grey: greyE, green: greenE, red: redE},
                            });
                            break;
                        }
                        case 'Verified': {
                            Object.assign(objData[practiceName], {
                                "Verified": {grey: greyV, green: greenV, red: redV},
                            });
                            break;
                        }
                        case 'Main Dental Home': {
                            Object.assign(objData[practiceName], {
                                "Main Dental Home": {grey: greyH, green: greenH, red: redH},
                            });
                            break;
                        }
                        default: {
                            Object.assign(objData[practiceName], {
                                "Error": {grey: greyE, green: greenE, red: redE},
                                "Verified": {grey: greyV, green: greenV, red: redV},
                                "Main Dental Home": {grey: greyH, green: greenH, red: redH},
                            });
                        }
                    }


                    Object.keys(objData).forEach((key1, dataRecords) => {
                        const data = Object.values(objData);
                        const records: any = data[dataRecords];
                        Object.keys(records).forEach((key2) => {
                            enabledcategoryList.forEach((dataValue, key3) => {

                                switch (key3) {
                                    case 0: {
                                        greyETotal = greyETotal + records[dataValue].grey;
                                        greenETotal = greenETotal + records[dataValue].green;
                                        redETotal = redETotal + records[dataValue].red;
                                        break;
                                    }
                                    case 1: {
                                        greyVTotal = greyVTotal + records[dataValue].grey;
                                        greenVTotal = greenVTotal + records[dataValue].green;
                                        redVTotal = redVTotal + records[dataValue].red;
                                        break;
                                    }
                                    case 2: {
                                        greyHTotal = greyHTotal + records[dataValue].grey;
                                        greenHTotal = greenHTotal + records[dataValue].green;
                                        redHTotal = redHTotal + records[dataValue].red;
                                        break;
                                    }
                                }


                                if (key2 === dataValue) {
                                    newStrHtmlBodyInner += `<td key="${key3 + 'grey' + key2}">${records[dataValue].grey}</td>`;
                                    newStrHtmlBodyInner += `<td key="${key3 + 'green' + key2}">${records[dataValue].green}</td>`;
                                    newStrHtmlBodyInner += `<td key="${key3 + 'red' + key2}">${records[dataValue].red}</td>`;
                                }
                            });
                        });
                    });


            } else {
                newStrHtmlBodyInner += `<td colspan="17">No records Found</td>`;
            }
        });
    }

    newStrHtmlBodyInner += `<tr><td>Total</td>
                            <td>${greyETotal}</td><td>${greenETotal}</td><td>${redETotal}</td>
                            <td>${greyVTotal}</td><td>${greenVTotal}</td><td>${redVTotal}</td>
                            <td>${greyHTotal}</td><td>${greenHTotal}</td><td>${redHTotal}</td></tr>`;
    mainHtml += `${newStrHtmlBodyInner}</div></tbody></table></div></div></div>`;
    return mainHtml;
}


export const exportPDFPVSReport: ExportHTMLPVSReport = (startDate: any, csvHeaderInfo: any, infoDataExcel: any, carrierInfo: any, insuranceInfo: any) => {
    let mainHtml: any = `<div class="container"><div class="ui card"><div class="content"><h3 style="margin-top: 0px;margin-left::5px;">Date:${startDate}</h3><div class="table-responsive FreezeTable"><table class="ui celled single line table unstackable tableStyle PVS">`;
    let firstHeader: any = '';
    csvHeaderInfo.forEach((item: any, key: any) => {
        firstHeader += `<th key="${key}" colspan="4" style="text-align: center" >${item}</th>`;
    });
    let secondHeader: any = '';
    let thirdHeader: any = '';
    {
        csvHeaderInfo.length === 1 ? enabledCarrierColor.forEach((item: any, key: any) => {
            let secondHeaderData = '';
            if (key === 0) {
                secondHeaderData += `<i class="fa fa-check  green" aria-hidden="true"></i>`;
            } else if (key === 1) {
                secondHeaderData += `<span class="red">A</span>`;
            } else if (key === 2) {
                secondHeaderData += `<i aria-hidden="true" class="fa fa-times red"></i>`;
            } else {
                secondHeaderData += `<i class="fa fa-question-circle yellow" aria-hidden="true"></i>`;
            }
            secondHeader += `<th key="${key}" style="text-align: center" >${secondHeaderData}</th>`;

        }) : enabledCarrierColor.forEach((item: any, key: any) => {
            let thirdHeaderData = '';
            if (key === 0) {
                thirdHeaderData += `<i class="fa fa-check  green" aria-hidden="true"></i>`;
            } else if (key === 1) {
                thirdHeaderData += `<span class="red">A</span>`;
            } else if (key === 2) {
                thirdHeaderData += `<i aria-hidden="true" class="fa fa-times red"></i>`;
            } else {
                thirdHeaderData += `<i class="fa fa-question-circle yellow" aria-hidden="true"></i>`;
            }
            thirdHeader += `<th key="${key}" style="text-align: center">${thirdHeaderData}</th>`;
        })
    }

    if (csvHeaderInfo.length === 1) {
        mainHtml += `<thead><tr><th>&nbsp;</th>${firstHeader}</tr><tr><th>Practice</th>${secondHeader}</tr></thead>`;
    } else {
        mainHtml += `<thead><tr><th>&nbsp;</th>${firstHeader}</tr><tr><th>Practice</th>${thirdHeader}${thirdHeader}${thirdHeader}${thirdHeader}</tr></thead>`;
    }
    let newStrHtmlBodyInner: any = '<tbody>';
    if (infoDataExcel && Object.keys(infoDataExcel).length > 0) {

        const practices = Object.keys(infoDataExcel);
        const carrierList: any = carrierInfo;

        practices.forEach((practiceName: any, index: any) => {
            newStrHtmlBodyInner += `<tr key="${practiceName}"><td>${practiceName}</td>`;
            const data: any = infoDataExcel[practiceName];
            const objData: any = {[practiceName]: insuranceList};

            if (insuranceInfo) {
                Object.assign(objData, {[practiceName]: insuranceList[insuranceInfo]})
            }

            let statusItem: any = false;

            if (data && data.length > 0) {
                carrierList.forEach((item: any, key: any) => {
                    const filterCarrierCheck = data.filter((word: any) => word.CarrierName === item.modifyCarrierName);
                    if (filterCarrierCheck && filterCarrierCheck.length > 0) {
                        statusItem = true;
                    }
                });
                if (statusItem) {
                    let [greenM, redXM, redAM, noImageM, greenD, redXD, redAD, noImageD, greenT, redXT, redAT, noImageT,
                        greenU, redXU, redAU, noImageU]: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
                    data.forEach((item: any, key: any) => {
                        const isCarrierExist = carrierList.filter((carrier: any) => carrier.carrierName === item.CarrierName);
                        const objectInfo = isCarrierExist && isCarrierExist.length > 0;
                        if (objectInfo) {
                            let name: any = isCarrierExist[0].modifyCarrierName;
                            switch (item.status) {
                                case 'PT Assigned To Practice Active Insurance': {
                                    switch (name) {
                                        case 'MCNA': {
                                            greenM++;
                                            break;
                                        }
                                        case 'DentaQuest': {
                                            greenD++;
                                            break;
                                        }
                                        case 'TMHP': {
                                            greenT++;
                                            break;
                                        }case 'UHC Medicaid': {
                                            greenU++;
                                            break;
                                        }

                                    }
                                    break;
                                }
                                case 'PT Does NOT Have Active Insurance': {
                                    switch (name) {
                                        case 'MCNA': {
                                            redXM++;
                                            break;
                                        }
                                        case 'DentaQuest': {
                                            redXD++;
                                            break;
                                        }
                                        case 'TMHP': {
                                            redXT++;
                                            break;
                                        }case 'UHC Medicaid': {
                                            redXU++;
                                            break;
                                        }

                                    }
                                    break;
                                }
                                case 'PT Has Active Insurance But Not Assigned To Practice': {
                                    switch (name) {
                                        case 'MCNA': {
                                            redAM++;
                                            break;
                                        }
                                        case 'DentaQuest': {
                                            redAD++;
                                            break;
                                        }
                                        case 'TMHP': {
                                            redAT++;
                                            break;
                                        }case 'UHC Medicaid': {
                                            redAU++;
                                            break;
                                        }

                                    }
                                    break;
                                }
                                default : {
                                    switch (name) {
                                        case 'MCNA': {
                                            noImageM++;
                                            break;
                                        }
                                        case 'DentaQuest': {
                                            noImageD++;
                                            break;
                                        }
                                        case 'TMHP': {
                                            noImageT++;
                                            break;
                                        }case 'UHC Medicaid': {
                                            noImageU++;
                                            break;
                                        }

                                    }
                                    break;

                                }
                            }
                        }
                    });

                    switch (insuranceInfo) {
                        case 'MCNA': {
                            Object.assign(objData[practiceName], {
                                "MCNA": {green: greenM, redA: redAM, redX: redXM, noImage: noImageM},
                            });
                            break;
                        }
                        case 'DentaQuest': {
                            Object.assign(objData[practiceName], {
                                "DentaQuest": {green: greenD, redA: redAD, redX: redXD, noImage: noImageD},
                            });
                            break;
                        }
                        case 'TMHP': {
                            Object.assign(objData[practiceName], {
                                "TMHP": {green: greenT, redA: redAT, redX: redXT, noImage: noImageT},
                            });
                            break;
                        }
                        case 'UHC Medicaid': {
                            Object.assign(objData[practiceName], {
                                "UHC Medicaid": {green: greenU, redA: redAU, redX: redXU, noImage: noImageU},

                            });
                            break;
                        }
                        default:{
                            Object.assign(objData[practiceName], {
                                "MCNA": {green: greenM, redA: redAM, redX: redXM, noImage: noImageM},
                                "DentaQuest": {green: greenD, redA: redAD, redX: redXD, noImage: noImageD},
                                "TMHP": {green: greenT, redA: redAT, redX: redXT, noImage: noImageT,},
                                "UHC Medicaid": {green: greenU, redA: redAU, redX: redXU, noImage: noImageU},
                            });
                        }
                    }

                    Object.keys(objData).forEach((key1, dataRecords) => {
                        const data = Object.values(objData);
                        const records: any = data[dataRecords];
                        Object.keys(records).forEach((key2) => {
                            enabledCarrierList.forEach((dataValue, key3) => {
                                if (key2 === dataValue) {
                                    newStrHtmlBodyInner += `<td key="${key3 + 'green' + key2}">${records[dataValue].green}</td>`;
                                    newStrHtmlBodyInner += `<td key="${key3 + 'redA' + key2}">${records[dataValue].redA}</td>`;
                                    newStrHtmlBodyInner += `<td key="${key3 + 'redX' + key2}">${records[dataValue].redX}</td>`;
                                    newStrHtmlBodyInner += `<td key="${key3 + 'noImage' + key2}" style="border-right:1px solid black">${records[dataValue].noImage}</td>`;

                                }
                            });
                        });
                    });

                } else {
                    newStrHtmlBodyInner += `<td colspan="17">No records Found</td>`;
                }
            } else {
                newStrHtmlBodyInner += `<td colspan="17">No records Found</td>`;
            }
        });
    }
    mainHtml += `${newStrHtmlBodyInner}</div></tbody></table></div></div></div>`;
    return mainHtml;
}


export const exportOpenSurgeryReport: ExportOpenSurgeryReport = (data, month, year, excel) => {
    let csvData: any = [];
    const currentMonth = moment(month + 1, 'M').format('MMMM') + ' ' + year;
    const numberWithCommas = (x: string) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const insertSpaces = (s: string) => {
        s = s.replace(/([a-z])([A-Z])/g, '$1 $2');
        s = s.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        return s;
    }
    const capitalizeFirstLetter = (s: string) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }
    if (data.monthData && data.monthData.length) {
        const objMonth: any = {};
        data.monthData.map((s: any) => (Object.assign(objMonth, {[s.practiceAbbr]: s.month})));
        let dataObj: any = {};
        let csvData: any = [];
        let monthHeader = ['current_month_year', 'table_name', 'field_name'];
        let practiceName: string[] = [];
        let practiceHeader: string[] = [];
        let dataKeys: string[] = [];
        data.monthData.forEach((item: any, key: number) => {
            const data: any = key == 0 ? dataKeys = Object.keys(item.month) : '';
            practiceName.push(item.practiceAbbr);
            practiceHeader.push(item.practice);
            dataKeys = dataKeys.filter(item => item !== 'scheduleApp')
        });
        monthHeader = monthHeader.concat(practiceHeader);
        monthHeader.push('Grand Total')
        csvData.push(monthHeader);
        dataKeys.forEach((key) => {
            let obj = {};
            let monthObj = {};
            data.monthData.forEach((item: any) => {
                const monthData = item.month[key];
                Object.keys(monthData).forEach((value) => {
                    Object.assign(monthObj, {[`${item.practiceAbbr}_${value}`]: monthData[value],});
                    obj = Object.assign(obj, {[value]: monthObj})
                });
            });
            Object.assign(dataObj, {[key]: obj})
        });

        Object.keys(dataObj).forEach((key) => {
            const keyObj = dataObj[key];
            Object.keys(keyObj).forEach((value) => {
                let newArr: any = [];
                newArr.push(currentMonth, capitalizeFirstLetter(insertSpaces(key)), capitalizeFirstLetter(insertSpaces(value)));
                let monthgrandTotal: any = 0;
                let count: any = 0;
                practiceName.forEach((practice) => {
                    let numseVar: string;
                    if (value == 'percentCompleted' || value == 'showRateHistorical' || value == 'showRateCurrentMonth') {
                        numseVar = parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(1) + '%';
                        monthgrandTotal = monthgrandTotal + parseInt(keyObj[value][`${practice}_${value}`]);

                    } else if (value == 'currentMonthGoalCpv' || value == 'deltaCpv' ||
                        value == 'actCpv' || value == 'goalCpv' || value == 'splyActCpv') {
                        numseVar = '"$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '"';
                        const checkNum: any = parseFloat(keyObj[value][`${practice}_${value}`])
                        if (checkNum !== 0) {
                            count++;
                            monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                        }

                    } else if (value == 'nextWorkDay' || value == 'lastWorkDay') {
                        numseVar = keyObj[value][`${practice}_${value}`];
                        monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                    } else if ((value).toLowerCase().includes("care") || (value).toLowerCase().includes("cpv")) {
                        numseVar = '"$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '"';
                        monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                    } else {
                        monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                        numseVar = `"${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}"`;
                    }
                    newArr.push(numseVar);
                });
                if (value == 'percentCompleted' || value == 'showRateHistorical' || value == 'showRateCurrentMonth') {
                    const percent: any = (monthgrandTotal / practiceName.length);
                    newArr.push(parseFloat(percent).toFixed(1) + '%');
                } else if (value == 'currentMonthGoalCpv' || value == 'deltaCpv' ||
                    value == 'actCpv' || value == 'goalCpv' || value == 'splyActCpv') {
                    const val: any = (monthgrandTotal / count);
                    const budData: string = `"$${numberWithCommas(parseFloat(val ? val : 0).toFixed(0))}"`;
                    newArr.push(budData);
                } else if (value == 'nextWorkDay' || value == 'lastWorkDay') {
                    newArr.push('');
                } else if ((value).toLowerCase().includes("care") || (value).toLowerCase().includes("cpv")) {
                    const price: string = `"$${numberWithCommas(parseFloat(monthgrandTotal).toFixed(0))}"`;
                    newArr.push(price);
                } else {
                    const num: string = `"${parseFloat(monthgrandTotal).toFixed(0)}"`;
                    newArr.push(num);
                }
                csvData.push(newArr)
            });
        });

        csvData.push('');
        csvData.push('');

        let dataObjYear: any = {};
        let yearHeader: string[] = ['current_year', 'table_name', 'field_name'];
        let practiceNameYear: string[] = [];
        let dataKeysYear: string[] = [];
        let practiceYearHeader: string[] = [];
        data.yearData.forEach((item: any, key: number) => {
            const data: any = key == 0 ? dataKeysYear = Object.keys(item.year) : '';
            practiceNameYear.push(item.practiceAbbr);
            practiceYearHeader.push(item.practice)
            dataKeysYear = dataKeysYear.filter(item => item !== 'scheduleApp')
        });
        yearHeader = yearHeader.concat(practiceYearHeader);
        yearHeader.push('Grand Total')
        csvData.push(yearHeader);
        dataKeysYear.forEach((key) => {
            let objYear = {};
            let yearObj = {};
            data.yearData.forEach((item: any) => {
                const yearData = item.year[key];
                Object.keys(yearData).forEach((value) => {
                    Object.assign(yearObj, {[`${item.practiceAbbr}_${value}`]: yearData[value],});
                    objYear = Object.assign(objYear, {[value]: yearObj})
                });
            });
            Object.assign(dataObjYear, {[key]: objYear})
        });
        Object.keys(dataObjYear).forEach((key) => {
            const keyObj = dataObjYear[key];
            Object.keys(keyObj).forEach((value) => {
                let newArr: string[] = [];
                newArr.push(year, capitalizeFirstLetter(insertSpaces(key)), capitalizeFirstLetter(insertSpaces(value)));
                let yearGrandTotal: any = 0;
                let yearCount: any = 0;
                practiceNameYear.forEach((practice) => {
                    let numseVar: string;
                    if (value == 'percentCompleted' || value == 'showRateHistorical' || value == 'showRateCurrentMonth') {
                        numseVar = parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(1) + '%';
                        yearGrandTotal = yearGrandTotal + parseInt(keyObj[value][`${practice}_${value}`]);

                    } else if (value == 'currentMonthGoalCpv' || value == 'deltaCpv' ||
                        value == 'actCpv' || value == 'goalCpv' || value == 'splyActCpv') {
                        numseVar = '"$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '"';
                        const checkNum: any = parseFloat(keyObj[value][`${practice}_${value}`])
                        if (checkNum !== 0) {
                            yearCount++;
                            yearGrandTotal = yearGrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                        }
                    } else if (value == 'nextWorkDay' || value == 'lastWorkDay') {
                        numseVar = keyObj[value][`${practice}_${value}`];
                        yearGrandTotal = yearGrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                    } else if ((value).toLowerCase().includes("care") || (value).toLowerCase().includes("cpv")) {
                        numseVar = '"$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '"';
                        yearGrandTotal = yearGrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                    } else {
                        yearGrandTotal = yearGrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                        numseVar = `"${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}"`;
                    }
                    newArr.push(numseVar);
                });
                if (value == 'percentCompleted' || value == 'showRateHistorical' || value == 'showRateCurrentMonth') {
                    const percent: any = (yearGrandTotal / practiceNameYear.length);
                    newArr.push(parseFloat(percent).toFixed(1) + '%');
                } else if (value == 'currentMonthGoalCpv' || value == 'deltaCpv' ||
                    value == 'actCpv' || value == 'goalCpv' || value == 'splyActCpv') {
                    const bud: any = (yearGrandTotal / yearCount);
                    const budData: any = `"$${numberWithCommas(parseFloat(bud ? bud : 0).toFixed(0))}"`;
                    newArr.push(budData);
                } else if (value == 'nextWorkDay' || value == 'lastWorkDay') {
                    newArr.push('');
                } else if ((value).toLowerCase().includes("care") || (value).toLowerCase().includes("cpv")) {
                    const price: string = `"$${numberWithCommas(parseFloat(yearGrandTotal).toFixed(0))}"`;
                    newArr.push(price);
                } else {
                    const num: string = `"${parseFloat(yearGrandTotal).toFixed(0)}"`;
                    newArr.push(num);
                }
                csvData.push(newArr)
            });
        });
        {
            excel && exportCSV("OSR.csv", csvData);
        }
        return csvData;
    }


};

export const exportNewOpenSurgeryReport: ExportOpenSurgeryReport = (data, month, year, excel) => {


    const numberWithCommas = (x: string) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const insertSpaces = (s: string) => {
        s = s.replace(/([a-z])([A-Z])/g, '$1 $2');
        s = s.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        return s;
    }
    const capitalizeFirstLetter = (s: string) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }
    const yearAllData: Array<{}> = data.yearData;

    if (data.monthData && data.monthData.length) {
        const objMonth: any = {};
        data.monthData.map((s: any) => (Object.assign(objMonth, {[s.practiceAbbr]: s.monthNewAllData})));
        let dataObj: any = {};
        let csvData: any = [];
        let monthHeader = [''];
        let practiceName: string[] = [];
        let practiceHeader: string[] = [];
        let yearPracticeHeader: string[] = [];
        let dataKeys: string[] = [];
        csvData.push([`${moment(month + 1, 'M').format('MMMM')} ${year}`, '', 'Oral Surgery Patient Care New Report']);
        csvData.push('');
        csvData.push('');
        data.monthData.forEach((item: any, key: number) => {
            const data: any = key == 0 ? dataKeys = Object.keys(item.monthNewAllData) : '';
            practiceName.push(item.practiceAbbr);
            practiceHeader.push(item.practice);
        });
        monthHeader = monthHeader.concat(practiceHeader);
        monthHeader.push('Grand Total');
        csvData.push(monthHeader);

        let returnArrayDaysOpen: React.ReactElement[] = [];
        let returnArrayCompletedDays: React.ReactElement[] = [];
        let returnArrayPercentCompleted: React.ReactElement[] = [];
        let returnArrayDaysLeft: React.ReactElement[] = [];

        let returnArrayBudCare: React.ReactElement[] = [];
        let returnArrayActCare: React.ReactElement[] = [];
        let returnArrayDeltaCare: React.ReactElement[] = [];

        let returnArrayBudCpvCare: React.ReactElement[] = [];
        let returnArrayActCpvCare: React.ReactElement[] = [];
        let returnArrayDeltaCpvCare: React.ReactElement[] = [];

        let returnArrayActPtVisits: React.ReactElement[] = [];
        let returnArrayBudPt: React.ReactElement[] = [];
        let returnArrayDeltaPTVisits: React.ReactElement[] = [];

        let returnArrayLastWorkDay: React.ReactElement[] = [];
        let returnArrayLwdCare: React.ReactElement[] = [];
        let returnArrayLwdPtCount: React.ReactElement[] = [];




        dataKeys.forEach((key) => {
            let obj = {};
            let monthObj = {};
            data.monthData.forEach((item: any) => {
                const monthData = item.monthNewAllData[key];
                Object.keys(monthData).forEach((value) => {
                    Object.assign(monthObj, {[`${item.practiceAbbr}_${value}`]: monthData[value],});
                    obj = Object.assign(obj, {[value]: monthObj})
                });
            });
            Object.assign(dataObj, {[key]: obj})
        });


        Object.keys(dataObj).forEach((key) => {
            const keyObj: any = dataObj[key];
            const keyName: any = key;

            Object.keys(keyObj).forEach((value: any) => {
                let title: any = capitalizeFirstLetter(insertSpaces(value));

                if (keyName === "workDays") {
                    if (value === 'daysOpen') {
                        returnArrayDaysOpen.push(title);
                    } else if (value === 'completedDays') {
                        returnArrayCompletedDays.push(title);
                    } else if (value === 'percentCompleted') {
                        returnArrayPercentCompleted.push(title);
                    } else if (value === 'daysLeft') {
                        returnArrayDaysLeft.push(title);
                    }
                }
                if (keyName === "LWD") {
                    if (value === 'lastWorkDay') {
                        returnArrayLastWorkDay.push(title);
                    } else if (value === 'lwdCare') {
                        title = 'LWD Care';
                        returnArrayLwdCare.push(title);
                    } else if (value === 'lwdPtCount') {
                        title = 'LWD Pt Count'
                        returnArrayLwdPtCount.push(title);
                    }

                }

                if (keyName === "careMTD") {
                    if (value === 'budCare') {
                        title = 'Care Goal MTD';
                        returnArrayBudCare.push(title);
                    } else if (value === 'actCare') {
                        title = 'Care Actual MTD';
                        returnArrayActCare.push(title);
                    } else if (value === 'deltaCare') {
                        title = 'Care Delta MTD';
                        returnArrayDeltaCare.push(title);
                    }
                }

                if (keyName === 'goalToActualPatientVisitsMTD') {
                    if (value === 'goalPt') {
                        title = 'goal Pt Visits'
                        returnArrayBudPt.push(title);
                    } else if (value === 'actPtVisits') {
                        returnArrayActPtVisits.push(title);
                    } else if (value === 'deltaPTVisits') {
                        title = 'Delta Pt Visits'
                        returnArrayDeltaPTVisits.push(title);
                    }
                }

                if (keyName === 'goalToActualCPV') {
                    if (value === 'goalCpv') {
                        title = 'GoalCPV';
                        returnArrayBudCpvCare.push(title);
                    } else if (value === 'actCpv') {
                        title = 'Act CPV';
                        returnArrayActCpvCare.push(title);

                    } else if (value === 'deltaCpv') {
                        title = 'Delta CPV';
                        returnArrayDeltaCpvCare.push(title);

                    }
                }




                let monthgrandTotal: any = 0;
                //Lwd Pt Count
                let LWDmonthgrandTotal: any = 0;
                //Lwd Care Total
                let CareActualMTDGrandTotal: any = 0;
                let CMTDmonthgrandTotal: any = 0;
                let VMTDmonthgrandTotal: any = 0;
                let ACPVmonthgrandTotal: any = 0;


                let CareGaolMTD: any = 0;
                let count: any = 0;
                practiceName.forEach((practice) => {

                    if (keyName === "workDays") {
                        if (value === 'daysOpen') {
                            monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                            const numseVar: any = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                            returnArrayDaysOpen.push(numseVar);
                        } else if (value === 'completedDays') {
                            monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                            const numseVar: any = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                            returnArrayCompletedDays.push(numseVar);
                        } else if (value === 'percentCompleted') {
                            const numseVar: any = parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(1) + '%';
                            monthgrandTotal = monthgrandTotal + parseInt(keyObj[value][`${practice}_${value}`]);
                            returnArrayPercentCompleted.push(numseVar);
                        } else if (value === 'daysLeft') {
                            monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                            const numseVar: any = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                            returnArrayDaysLeft.push(numseVar);
                        }
                    }

                    if (keyName === "LWD") {
                        if (value == 'lastWorkDay') {
                            const numseVar: any = keyObj[value][`${practice}_${value}`];
                            CareGaolMTD = CareGaolMTD + parseFloat(keyObj[value][`${practice}_${value}`]);
                            returnArrayLastWorkDay.push(numseVar);
                        } else if (value == 'lwdCare') {
                            const numseVar: any = '"$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '"';
                            CareActualMTDGrandTotal = CareActualMTDGrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                            returnArrayLwdCare.push(numseVar);
                        } else if (value == 'lwdPtCount') {
                            LWDmonthgrandTotal = LWDmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                            const numseVar: any = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                            returnArrayLwdPtCount.push(numseVar);
                        }
                    }

                    if (keyName === "careMTD") {
                        if (value == 'budCare' || value == 'actCare' || value == 'deltaCare') {
                            const numseVar: any = '"$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '"';
                            const checkNum: any = parseFloat(keyObj[value][`${practice}_${value}`])
                            if (checkNum !== 0) {
                                count++;
                                CMTDmonthgrandTotal = CMTDmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                            }
                            {
                                value == 'budCare' && returnArrayBudCare.push(numseVar)
                            }
                            ;
                            {
                                value == 'actCare' && returnArrayActCare.push(numseVar)
                            }
                            ;
                            {
                                value == 'deltaCare' && returnArrayDeltaCare.push(numseVar)
                            }
                            ;
                        }
                    }

                    if (keyName === 'goalToActualPatientVisitsMTD') {
                        if (value == 'actPtVisits' || value == 'goalPt' || value == 'deltaPTVisits') {
                            VMTDmonthgrandTotal = VMTDmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                            const numseVar: any = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                            {
                                value == 'actPtVisits' && returnArrayActPtVisits.push(numseVar)
                            }
                            ;
                            {
                                value == 'goalPt' && returnArrayBudPt.push(numseVar)
                            }
                            ;
                            {
                                value == 'deltaPTVisits' && returnArrayDeltaPTVisits.push(numseVar)
                            }
                            ;
                        }
                    }

                    if (keyName === 'goalToActualCPV') {
                        if (value == 'goalCpv' || value == 'actCpv' || value == 'deltaCpv') {
                            const numseVar: any = '"$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '"';
                            const checkNum: any = parseFloat(keyObj[value][`${practice}_${value}`])
                            if (checkNum !== 0) {
                                count++;
                                ACPVmonthgrandTotal = ACPVmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                            }
                            {
                                value == 'goalCpv' && returnArrayBudCpvCare.push(numseVar)
                            }
                            ;
                            {
                                value == 'actCpv' && returnArrayActCpvCare.push(numseVar)
                            }
                            ;
                            {
                                value == 'deltaCpv' && returnArrayDeltaCpvCare.push(numseVar)
                            }
                            ;
                        }
                    }


                });


                if (keyName === "workDays") {
                    if (value === 'daysOpen') {
                        const num: any = `${parseFloat(monthgrandTotal).toFixed(0)}`;
                        returnArrayDaysOpen.push(num);
                    } else if (value === 'completedDays') {
                        const num: any = `${parseFloat(monthgrandTotal).toFixed(0)}`;
                        returnArrayCompletedDays.push(num);
                    } else if (value === 'percentCompleted') {
                        const percent: any = (monthgrandTotal / practiceName.length);
                        const per: any = parseFloat(percent).toFixed(1) + '%';
                        returnArrayPercentCompleted.push(per);
                    } else if (value === 'daysLeft') {
                        const num: any = `${parseFloat(monthgrandTotal).toFixed(0)}`;
                        returnArrayDaysLeft.push(num);
                    }

                }

                if (keyName === "LWD") {
                    if (value === 'lastWorkDay') {
                        returnArrayLastWorkDay.push();
                    } else if (value === 'lwdCare') {
                        const price: any = '"$' + numberWithCommas(parseFloat(CareActualMTDGrandTotal).toFixed(0)) + '"';
                        returnArrayLwdCare.push(price);
                    } else if (value === 'lwdPtCount') {
                        const num: any = `${parseFloat(LWDmonthgrandTotal).toFixed(0)}`;
                        returnArrayLwdPtCount.push(num);
                    }
                }

                if (keyName === "careMTD") {
                    if (value == 'budCare' || value == 'actCare' || value == 'deltaCare') {
                        const val: any = CMTDmonthgrandTotal;
                        const num: any = '"$' + numberWithCommas(parseFloat(val ? val : 0).toFixed(0)) + '"';
                        {
                            value == 'budCare' && returnArrayBudCare.push(num)
                        }
                        ;
                        {
                            value == 'actCare' && returnArrayActCare.push(num)
                        }
                        ;
                        {
                            value == 'deltaCare' && returnArrayDeltaCare.push(num)
                        }
                        ;
                    }
                }

                if (keyName === 'goalToActualPatientVisitsMTD') {
                    if (value == 'actPtVisits' || value == 'goalPt' || value == 'deltaPTVisits') {
                        const num: any = `${parseFloat(VMTDmonthgrandTotal).toFixed(0)}`;
                        {
                            value == 'actPtVisits' && returnArrayActPtVisits.push(num)
                        }
                        ;
                        {
                            value == 'goalPt' && returnArrayBudPt.push(num)
                        }
                        ;
                        {
                            value == 'deltaPTVisits' && returnArrayDeltaPTVisits.push(num)
                        }
                        ;

                    }
                }

                if (keyName === 'goalToActualCPV') {
                    if (value == 'goalCpv' || value == 'actCpv' || value == 'deltaCpv') {
                        const val: any = (ACPVmonthgrandTotal/count);
                        const num: any = '"$' + numberWithCommas(parseFloat(val ? val : 0).toFixed(0)) + '"';
                        {
                            value == 'goalCpv' && returnArrayBudCpvCare.push(num)
                        }
                        {
                            value == 'actCpv' && returnArrayActCpvCare.push(num)
                        }
                        {
                            value == 'deltaCpv' && returnArrayDeltaCpvCare.push(num)
                        }
                    }
                }


            });
        });


        csvData.push(returnArrayDaysOpen, returnArrayCompletedDays,
            returnArrayDaysLeft, returnArrayPercentCompleted, returnArrayLastWorkDay,
            returnArrayLwdCare, returnArrayLwdPtCount, returnArrayBudCare, returnArrayActCare, returnArrayDeltaCare,
            returnArrayBudPt, returnArrayActPtVisits, returnArrayDeltaPTVisits, returnArrayBudCpvCare, returnArrayActCpvCare, returnArrayDeltaCpvCare,
           );
        csvData.push('');
        csvData.push('');
        let yearHeader: string[] = [''];
        let practiceNameYear: string[] = [];
        let dataKeysYear: string[] = [];
        let practiceYearHeader: string[] = [];
        data.yearData.forEach((item: any, key: number) => {
            const data: any = key == 0 ? dataKeysYear = Object.keys(item.year) : '';
            practiceNameYear.push(item.practiceAbbr);
            practiceYearHeader.push(item.practice)
            dataKeysYear = dataKeysYear.filter(item => item !== 'scheduleApp')
        });
        yearHeader = yearHeader.concat(practiceYearHeader);
        yearHeader.push('Grand Total')
        csvData.push(yearHeader);

        let returnArrayActCareY: React.ReactElement[] = [];
        let returnArrayPtCount: React.ReactElement[] = [];
        let returnArrayYNextWorkDay: React.ReactElement[] = [];
        let returnArrayYNwdCareGoal: React.ReactElement[] = [];
        let returnArrayYNwdAptCount: React.ReactElement[] = [];
        let returnArrayYNwdPtGoal: React.ReactElement[] = [];
        let returnArrayYNwdScheduleCare: React.ReactElement[] = [];

        let returnArrayYPTCptCount: React.ReactElement[] = [];
        let returnArrayYPTCage15ptCount: React.ReactElement[] = [];
        let returnArrayYPTCosReferrals: React.ReactElement[] = [];
        let returnArrayYPTCreferralsPercentage: React.ReactElement[] = [];

        // year Records
        if (yearAllData && yearAllData.length > 0) {
            const objYear: any = {};
            yearAllData.map((s: any) => (Object.assign(objYear, {[s.practiceAbbr]: s.year})));
            let dataObjYear: any = {};
            let practiceNameYear: string[] = [];
            let dataKeysYear: string[] = [];
            yearAllData.forEach((item: any, key: number) => {
                const data: any = key == 0 ? dataKeysYear = Object.keys(item.year) : '';
                practiceNameYear.push(item.practiceAbbr);
            });
            let practiceNameCheckYear = '';
            dataKeysYear.forEach((key) => {
                let objYear = {};
                let yearObj = {};
                yearAllData.forEach((item: any) => {
                    const yearData = item.year[key];
                    Object.keys(yearData).forEach((value) => {
                        Object.assign(yearObj, {[`${item.practiceAbbr}_${value}`]: yearData[value],});
                        objYear = Object.assign(objYear, {[value]: yearObj})
                    });
                });
                Object.assign(dataObjYear, {[key]: objYear})
            });
            Object.keys(dataObjYear).forEach((key, index) => {
                const keyObjYear = dataObjYear[key];
                const keyName = key;
                let titlePTCData: any = ['Total PT. Count', 'Total Patient count age 15+','# of OS referrals','Referral %  (patients age 15 and up) line 56/55 x 100'];
                let titleData: any = ['Care Actual YTD', 'PT Count YTD']
                let titleNWDData: any = ['Next Work Day', 'NWD Apt Count','NWD Care Goal','NWD Pt Goal','NWD Schedule Care']
                Object.keys(keyObjYear).forEach((value: any, index: any) => {

                    if (keyName === 'NWD') {
                            if (value == 'nextWorkDay') {
                                returnArrayYNextWorkDay.push(titleNWDData[0]);
                            } else if (value == 'nwdAptCount') {
                                returnArrayYNwdAptCount.push(titleNWDData[1]);

                            } else if (value == 'nwdCareGoal') {
                                returnArrayYNwdCareGoal.push(titleNWDData[2]);

                            } else if (value === 'nwdPtGoal') {
                                returnArrayYNwdPtGoal.push(titleNWDData[3]);

                            } else if (value === 'nwdScheduleCare') {
                                returnArrayYNwdScheduleCare.push(titleNWDData[4]);

                            }
                        }
                    if(keyName === 'PTC') {
                        if (value == 'totalPTCount'){
                            returnArrayYPTCptCount.push(titlePTCData[0]);
                        }
                        if (value == 'totalPTCountAge15') {
                            returnArrayYPTCage15ptCount.push(titlePTCData[1]);
                        }
                        if (value == 'osReferrals') {
                            returnArrayYPTCosReferrals.push(titlePTCData[2]);
                        }
                        if (value == 'referralsPercentage') {
                            returnArrayYPTCreferralsPercentage.push(titlePTCData[3]);
                        }
                    }
                    if (keyName === "newYReport") {
                        if (value === 'careActualYTD') {

                            returnArrayActCareY.push(titleData[0]);
                        } else if (value === 'ptCountYtd') {
                            returnArrayPtCount.push(titleData[1]);
                        }
                    }

                    let yearGrandTotal: any = 0;
                    let NWDYgrandTotal:any = 0;
                    let PTCYgrandTotal: any = 0;
                    practiceNameYear.forEach((practice, index) => {
                        practiceNameCheckYear = practice;
                        if (keyName === 'PTC') {
                            if(value === 'totalPTCount') {
                                const ptCount = parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                PTCYgrandTotal = PTCYgrandTotal + ptCount;
                                returnArrayYPTCptCount.push(PTCYgrandTotal);
                            }
                            if (value == 'totalPTCountAge15') {
                                const ptCountAge15 = parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                PTCYgrandTotal = PTCYgrandTotal + ptCountAge15;
                                returnArrayYPTCage15ptCount.push(PTCYgrandTotal);
                            }
                            if (value == 'osReferrals') {
                                const orals = parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                PTCYgrandTotal = PTCYgrandTotal + orals;
                                returnArrayYPTCosReferrals.push(PTCYgrandTotal);
                            }
                            if (value == 'referralsPercentage') {
                                const referralsPercentage = parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                PTCYgrandTotal = PTCYgrandTotal + referralsPercentage;
                                returnArrayYPTCreferralsPercentage.push(PTCYgrandTotal);
                            }
                        }


                        if (keyName === "newYReport") {
                            if (value === 'careActualYTD') {
                                const numseVar: any = '"$' + numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0)) + '"';
                                yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                returnArrayActCareY.push(numseVar);
                            } else if (value === 'ptCountYtd') {
                                yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                const numseVar: any = `${numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0))}`;
                                returnArrayPtCount.push(numseVar);
                            }
                        }
                        if (keyName === 'NWD') {
                            if (value === 'nextWorkDay') {
                                const numseVar: any = keyObjYear[value][`${practice}_${value}`];
                                NWDYgrandTotal = NWDYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                returnArrayYNextWorkDay.push(numseVar);
                            } else if (value === 'nwdCareGoal') {
                                const numseVar: any = '"$' + numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0)) + '"';
                                NWDYgrandTotal = NWDYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                returnArrayYNwdCareGoal.push(numseVar);
                            } else if (value === 'nwdAptCount') {
                                NWDYgrandTotal = NWDYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                const numseVar: any = `${numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0))}`;
                                returnArrayYNwdAptCount.push(numseVar);
                            } else if (value === 'nwdPtGoal') {
                                NWDYgrandTotal = NWDYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                const numseVar: any = `${numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0))}`;
                                returnArrayYNwdPtGoal.push(numseVar);
                            } else if (value === 'nwdScheduleCare') {
                                const numseVar: any = '"$' + numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0)) + '"';
                                NWDYgrandTotal = NWDYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                returnArrayYNwdScheduleCare.push(numseVar);
                            }
                        }
                    });

                    if (keyName === 'PTC') {
                        if(value === 'totalPTCount') {
                            const total: any = `${numberWithCommas(parseFloat(PTCYgrandTotal).toFixed(0))}`;
                            returnArrayYPTCptCount.push(total);
                        }
                        if (value == 'totalPTCountAge15') {
                            const total: any = `${numberWithCommas(parseFloat(PTCYgrandTotal).toFixed(0))}`;
                            returnArrayYPTCage15ptCount.push(total)
                        }
                        if (value == 'osReferrals') {
                            const total: any = `${numberWithCommas(parseFloat(PTCYgrandTotal).toFixed(0))}`;
                            returnArrayYPTCosReferrals.push(total)
                        }
                        if (value == 'referralsPercentage') {
                            const total: any = `${numberWithCommas(parseFloat(PTCYgrandTotal).toFixed(0))}`;
                            returnArrayYPTCreferralsPercentage.push(total)
                        }
                    }
                    if (keyName === "newYReport") {
                        if (value === "careActualYTD") {
                            const price: any = '"$' + numberWithCommas(parseFloat(yearGrandTotal).toFixed(0)) + '"';
                            returnArrayActCareY.push(price);
                        }
                        if (value === "ptCountYtd") {
                            const num: any = `${parseFloat(yearGrandTotal).toFixed(0)}`;
                            returnArrayPtCount.push(num);
                        }
                    }
                    if (keyName === 'NWD') {
                            if (value === 'nextWorkDay') {
                                const space: any = '';
                                returnArrayYNextWorkDay.push(space);
                            } else if (value === 'nwdCareGoal') {
                                const price: any = `"$${numberWithCommas(parseFloat(NWDYgrandTotal).toFixed(0))}"`;
                                returnArrayYNwdCareGoal.push(price);
                            } else if (value === 'nwdAptCount') {
                                const num: any = `${parseFloat(NWDYgrandTotal).toFixed(0)}`;
                                returnArrayYNwdAptCount.push(num);
                            } else if (value === 'nwdPtGoal') {
                                const num: any = `${parseFloat(NWDYgrandTotal).toFixed(0)}`;
                                returnArrayYNwdPtGoal.push(num);
                            } else if (value === 'nwdScheduleCare') {
                                const price: any = `"$${numberWithCommas(parseFloat(NWDYgrandTotal).toFixed(0))}"`;
                                returnArrayYNwdScheduleCare.push(price);
                            }
                        }
                });

            });
        }
        csvData.push(returnArrayYNextWorkDay,returnArrayYNwdCareGoal,returnArrayYNwdAptCount,returnArrayYNwdPtGoal,returnArrayYNwdScheduleCare
        ,returnArrayYPTCptCount,returnArrayYPTCage15ptCount,returnArrayYPTCosReferrals,returnArrayYPTCreferralsPercentage,returnArrayActCareY, returnArrayPtCount,
            );

        {
            excel && exportCSV("NEWOSR.csv", csvData);
        }
        return csvData;
    }


};

export const htmlNewOpenSurgeryReport: ExportOpenSurgeryHtmlReport = (data, month, year) => {
    const currentMonth = moment(month + 1, 'M').format('MMMM');
    const monthData = data.monthData;
    const yearData = data.yearData;

    const insertSpaces = (s: string) => {
        s = s.replace(/([a-z])([A-Z])/g, '$1 $2');
        s = s.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        return s;
    }

    const capitalizeFirstLetter = (s: string) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const numberWithCommas = (x: string) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    let newStroHtml: any = '';
    if (monthData) {
        // month data--------------------------------
        const objMonth: any = {};
        data.monthData.map((s: any) => (Object.assign(objMonth, {[s.practiceAbbr]: s.month})));
        let dataObj: any = {};

        let practiceName: string[] = [];
        let dataKeys: string[] = [];
        data.monthData.forEach((item: any, key: number) => {
            const data: any = key == 0 ? dataKeys = Object.keys(item.monthNewAllData) : '';
            practiceName.push(item.practiceAbbr);
            dataKeys = dataKeys.filter(item => item !== 'scheduleApp')
        });
        let headeoMonth = '';
        if (monthData && monthData.length) {
            monthData.forEach((item: any, key: number) => {
                headeoMonth += `<th class="header-os">${item.practice}`;
            });
        }

        const odDay = moment().format('DD');
        newStroHtml += `<div class="ui card"><div class="content pb0"><div class="float-left width100"><h2 class="float-left width50 text-center">New Oral Surgery Patient Care Report</h2>` +
            `<h2 class="float-left width50 text-center">${currentMonth} ${odDay} ${year}</h2></div>` +
            `</div></div>` +
            `<div class="ui"><div class="content"><div class="table-responsive ">` +
            `<div class="ui card"><div class="content"><div class="table-responsive FreezeTable">`;
        newStroHtml += `<div class="ui card"><div class="content"><div class="table-responsive FreezeTable">`;
        dataKeys.forEach((key) => {
            let obj = {};
            let monthObj = {};
            data.monthData.forEach((item: any) => {
                const monthData = item.monthNewAllData[key];
                Object.keys(monthData).forEach((value) => {
                    Object.assign(monthObj, {[`${item.practiceAbbr}_${value}`]: monthData[value],});
                    obj = Object.assign(obj, {[value]: monthObj})
                });
            });
            Object.assign(dataObj, {[key]: obj})
        });

        Object.keys(dataObj).forEach((key) => {

            const keyObj = dataObj[key];
            const keyName = key;
            if (keyName === "workDays" || keyName === "LWD" || keyName === "careMTD" || keyName === 'goalToActualPatientVisitsMTD'
                || keyName === 'goalToActualCPV' ) {

                newStroHtml += `<div class="ui card mt10"><div class="content"><div class="table-responsive FreezeTable">` +
                    `<h3 class="heading">This table shows the ${capitalizeFirstLetter(insertSpaces(key))} per practice</h3>` +
                    `<table class="ui celled single line table unstackable tableStyle "><thead><th colspan="1" class="header-os-inner">&nbsp;</th>`;
                newStroHtml += headeoMonth;
                newStroHtml += `</th><th class="header-os">Grand Total</th></tr></thead>`;
                newStroHtml += `<tbody>`;
            }


            Object.keys(keyObj).forEach((value) => {
                if (keyName === "workDays" || keyName === "LWD" || keyName === "careMTD" || keyName === 'goalToActualPatientVisitsMTD'
                    || keyName === 'goalToActualCPV' ) {
                    switch (value) {
                        case 'lwdCare':
                            newStroHtml += `<tr><td>LWD Care</td>`;
                            break;
                        case 'lwdPtCount':
                            newStroHtml += `<tr><td>LWD Pt Count</td>`;
                            break;
                        case 'budCare':
                            newStroHtml += `<tr><td>Care Goal MTD</td>`;
                            break;
                        case 'actCare':
                            newStroHtml += `<tr><td>Care Actual MTD</td>`;
                            break;
                        case 'deltaCare':
                            newStroHtml += `<tr><td>Care Delta MTD</td>`;
                            break;
                        case 'deltaPTVisits':
                            newStroHtml += `<tr><td>Delta Pt Visits</td>`;
                            break;
                        case 'goalCpv':
                            newStroHtml += `<tr><td>Bud CPV</td>`;
                            break;
                        case 'actCpv':
                            newStroHtml += `<tr><td>Act CPV</td>`;
                            break;
                        case 'deltaCpv':
                            newStroHtml += `<tr><td>Delta CPV</td>`;
                            break;

                        default:
                            newStroHtml += `<tr><td>${capitalizeFirstLetter(insertSpaces(value))}</td>`;
                            break;
                    }
                }
                let monthgrandTotal: any = 0;
                //Lwd Pt Count
                //Lwd Care Total
                let CareActualMTDGrandTotal: any = 0;
                let CMTDmonthgrandTotal: any = 0;
                let VMTDmonthgrandTotal: any = 0;
                let ACPVmonthgrandTotal: any = 0;

                //LWD Pt Count Grand Total
                let LWDPtCountmonthgrandTotal: any = 0;

                let CareGaolMTD: any = 0;
                let count: any = 0;
                practiceName.forEach((practice) => {
                    if (keyName === "workDays" || keyName === "LWD" || keyName === "careMTD" || keyName === 'goalToActualPatientVisitsMTD'
                        || keyName === 'goalToActualCPV' ) {
                        let numseVar: any;
                        if (value == 'percentCompleted') {
                            numseVar = parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(1) + '%';
                            monthgrandTotal = monthgrandTotal + parseInt(keyObj[value][`${practice}_${value}`]);

                        } else if (value == 'budCare' || value == 'actCare' || value == 'deltaCare') {
                            numseVar = '$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '';
                            const checkNum: any = parseFloat(keyObj[value][`${practice}_${value}`])
                            if (checkNum !== 0) {
                                count++;
                                CMTDmonthgrandTotal = CMTDmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                            }
                        } else if (value == 'goalCpv' || value == 'actCpv' || value == 'deltaCpv') {
                            numseVar = '$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '';
                            const checkNum: any = parseFloat(keyObj[value][`${practice}_${value}`])
                            if (checkNum !== 0) {
                                count++;
                                ACPVmonthgrandTotal = ACPVmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                            }
                        } else if (value == 'lastWorkDay') {
                            numseVar = keyObj[value][`${practice}_${value}`];
                            CareGaolMTD = CareGaolMTD + parseFloat(keyObj[value][`${practice}_${value}`]);
                        } else if (value === 'lwdCare') {
                            numseVar = '$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))
                                + '';
                            CareActualMTDGrandTotal = CareActualMTDGrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);

                        } else if (value == 'lwdPtCount') {
                            LWDPtCountmonthgrandTotal = LWDPtCountmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                            numseVar = numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))

                        }else if (value == 'actPtVisits' || value == 'budPt' || value == 'deltaPTVisits') {
                            VMTDmonthgrandTotal = VMTDmonthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                            numseVar = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                        } else if (value === 'daysOpen' || value === 'completedDays' || value === 'daysLeft') {
                            monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                            numseVar = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                        }
                        newStroHtml += `<td>${numseVar}</td>`;
                    }
                });
                if (keyName === "workDays" || keyName === "LWD" || keyName === "careMTD" || keyName === 'goalToActualPatientVisitsMTD'
                    || keyName === 'goalToActualCPV') {
                    if (value == 'percentCompleted') {
                        const percent: any = (monthgrandTotal / practiceName.length);
                        newStroHtml += `<td>${parseFloat(percent).toFixed(1) + '%'}</td></tr>`;
                    } else if (value == 'budCare' || value == 'actCare' || value == 'deltaCare') {
                        const val: any = CMTDmonthgrandTotal;
                        newStroHtml += `<td>${'$' + numberWithCommas(parseFloat(val ? val : 0).toFixed(0))}</td></tr>`;
                    } else if (value == 'goalCpv' || value == 'actCpv' || value == 'deltaCpv') {
                        const val: any = (ACPVmonthgrandTotal/count);
                        newStroHtml += `<td>${'$' + numberWithCommas(parseFloat(val ? val : 0).toFixed(0))}</td></tr>`;
                    } else if (value == 'lastWorkDay') {
                        newStroHtml += `<td>&nbsp;</td></tr>`;
                    } else if (value === 'daysOpen' || value === 'completedDays' || value === 'daysLeft') {
                        const num: string = `${parseFloat(monthgrandTotal).toFixed(0)}`;
                        newStroHtml += `<td>${num}</td></tr>`;
                    }  else if (value == 'actPtVisits' || value == 'budPt' || value == 'deltaPTVisits') {
                        const num: string = `${parseFloat(VMTDmonthgrandTotal).toFixed(0)}`;
                        newStroHtml += `<td>${num}</td></tr>`;
                    } else if (value == 'lwdPtCount') {
                        const num: string = `${parseFloat(LWDPtCountmonthgrandTotal).toFixed(0)}`;
                        newStroHtml += `<td>${num}</td></tr>`;
                    } else if (value === 'lwdCare') {
                        const price: string = `$${numberWithCommas(parseFloat(CareActualMTDGrandTotal).toFixed(0))}`;
                        newStroHtml += `<td>${price}</td></tr>`;
                    }
                }

            });
            newStroHtml += `</tbody></table></div></div>`;
        });

    }
    //year Data
    if (yearData) {
        const objYear: any = {};
        data.yearData.map((s: any) => (Object.assign(objYear, {[s.practiceAbbr]: s.year})));
        let dataObjYear: any = {};
        let practiceNameYear: string[] = [];
        let dataKeysYear: string[] = [];
        data.yearData.forEach((item: any, key: number) => {
            const data: any = key == 0 ? dataKeysYear = Object.keys(item.year) : '';
            practiceNameYear.push(item.practiceAbbr);
            dataKeysYear = dataKeysYear.filter(item => item !== 'scheduleApp')
        });
        let headerYear = '';
        if (yearData && yearData.length) {
            yearData.forEach((item: any, key: number) => {
                headerYear += `<th class="header-os">${item.practice}`;
            });
        }

        dataKeysYear.forEach((key) => {
            let objYear = {};
            let yearObj = {};
            data.yearData.forEach((item: any) => {
                const yearData = item.year[key];
                Object.keys(yearData).forEach((value) => {
                    Object.assign(yearObj, {[`${item.practiceAbbr}_${value}`]: yearData[value],});
                    objYear = Object.assign(objYear, {[value]: yearObj})
                });
            });
            Object.assign(dataObjYear, {[key]: objYear})
        });

        Object.keys(dataObjYear).forEach((key) => {
            const keyObjYear = dataObjYear[key];
            const keyName = key;
            let title = '';
            if (keyName === "newYReport" || keyName === 'NWD' || keyName === "PTC" ) {
                switch (keyName) {
                    case 'newYReport':
                        title = "Care";
                        break;
                    case 'NWD':
                        title = "NWD";
                        break;
                    case 'PTC':
                        title = "Patient Count";
                        break;
                    default:
                       break;
                }


                newStroHtml += `<div class="table-responsive FreezeTable">` +
                    `<h3 class="heading">This table shows the ` + title +` Report per practice</h3>` +
                    `<table class="ui celled single line table unstackable tableStyle"><thead><th colspan="1" class="header-os-inner">&nbsp;</th>`;
                newStroHtml += headerYear;
                newStroHtml += `</th><th class="header-os">Grand Total</th></tr></thead>`;
                newStroHtml += `<tbody>`;
            }


            Object.keys(keyObjYear).forEach((value) => {
                if (keyName === "newYReport" || keyName === 'NWD' || keyName === 'PTC') {
                    switch (value) {
                        case 'nextWorkDay':
                            newStroHtml += `<tr><td>NWD</td>`;
                            break;
                        case 'nwdAptCount':
                            newStroHtml += `<tr><td>NWD Apt Count</td>`;
                            break;
                        case 'nwdCareGoal':
                            newStroHtml += `<tr><td>NWD Care Goal</td>`;
                            break;
                        case 'nwdPtGoal':
                            newStroHtml += `<tr><td>NWD Goal</td>`;
                            break;
                        case 'nwdScheduleCare':
                            newStroHtml += `<tr><td>NWD Schedule Care</td>`;
                            break;

                        case 'careActualYTD':
                            newStroHtml += `<tr><td>careActualYTD</td>`;
                            break;
                        case 'ptCountYtd':
                            newStroHtml += `<tr><td>ptCountYtd</td>`;
                            break;
                        case 'totalPTCount':
                            newStroHtml += `<tr><td>Total PT. Count</td>`;
                            break;
                        case 'totalPTCountAge15':
                            newStroHtml += `<tr><td>Total Patient count age 15+</td>`;
                            break;
                        case 'osReferrals':
                            newStroHtml += `<tr><td># of OS referrals</td>`;
                            break;
                        case 'referralsPercentage':
                            newStroHtml += `<tr><td>Referral %  (patients age 15 and up) line 56/55 x 100</td>`;
                            break;
                        default:
                            newStroHtml += `<tr><td>${capitalizeFirstLetter(insertSpaces(value))}</td>`;
                            break;
                    }

                }

                let yearGrandTotal: any = 0;
                let NWDYgrandTotal: any = 0;
                let NWDPtGoalYgrandTotal: any = 0;
                let PTCYgrandTotal: any = 0;
                practiceNameYear.forEach((practice) => {

                 let numseVarYear: any;

                 if ( value === 'nwdAptCount' || value == 'nextWorkDay' || value === 'nwdCareGoal') {
                     numseVarYear = keyObjYear[value][`${practice}_${value}`];
                     NWDYgrandTotal = NWDYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                     newStroHtml += `<td>${numseVarYear}</td>`;
                    }
                if (value === 'nwdScheduleCare'){
                    numseVarYear = parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0);
                    NWDYgrandTotal = NWDYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                    newStroHtml += `<td>${numseVarYear}</td>`;
                }
                 if (value === 'nwdPtGoal') {
                     numseVarYear = keyObjYear[value][`${practice}_${value}`];
                     NWDPtGoalYgrandTotal = NWDPtGoalYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                     newStroHtml += `<td>${numseVarYear}</td>`;
                    }

                    if (keyName === 'PTC') {
                        if(value === 'totalPTCount') {
                            numseVarYear = parseFloat(keyObjYear[value][`${practice}_${value}`]);
                            PTCYgrandTotal = PTCYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);

                        }
                        if (value == 'totalPTCountAge15') {
                            numseVarYear = parseFloat(keyObjYear[value][`${practice}_${value}`]);
                            PTCYgrandTotal = PTCYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                        }
                        if (value == 'osReferrals') {
                            numseVarYear = parseFloat(keyObjYear[value][`${practice}_${value}`]);
                            PTCYgrandTotal = PTCYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                        }
                        if (value == 'referralsPercentage') {
                            numseVarYear = parseFloat(keyObjYear[value][`${practice}_${value}`]);
                            PTCYgrandTotal = PTCYgrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                        }
                        newStroHtml += `<td>${numseVarYear}</td>`;
                    }


                 if (keyName === "newYReport") {
                        if (value === 'careActualYTD') {
                            numseVarYear = '$' + numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0)) + '';
                            yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                        } else if (value === 'ptCountYtd') {
                            yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                            numseVarYear = `${numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0))}`;
                        }
                        newStroHtml += `<td>${numseVarYear}</td>`;
                    }


                });



                if (keyName === "newYReport" || keyName === 'NWD' || keyName === 'PTC') {
                    if (value == 'careActualYTD') {
                        const price: string = `$${numberWithCommas(parseFloat(yearGrandTotal).toFixed(0))}`;
                        newStroHtml += `<td>${price}</td></tr>`;
                    } else if (value === 'ptCountYtd') {
                        const num: string = `${parseFloat(yearGrandTotal).toFixed(0)}`;
                        newStroHtml += `<td>${num}</td></tr>`;
                    }


                        if(value === 'totalPTCount') {
                            const price: string = `${numberWithCommas(parseFloat(PTCYgrandTotal).toFixed(0))}`;
                            newStroHtml += `<td>${price}</td></tr>`;

                        }
                        else if (value == 'totalPTCountAge15') {
                            const price: string = `${numberWithCommas(parseFloat(PTCYgrandTotal).toFixed(0))}`;
                            newStroHtml += `<td>${price}</td></tr>`;
                        }
                        else if (value == 'osReferrals') {
                            const price: string = `${numberWithCommas(parseFloat(PTCYgrandTotal).toFixed(0))}`;
                            newStroHtml += `<td>${price}</td></tr>`;
                        }else if (value == 'referralsPercentage') {
                            const price: string = `${numberWithCommas(parseFloat(PTCYgrandTotal).toFixed(0))}`;
                            newStroHtml += `<td>${price}</td></tr>`;
                        }

                    if (value === 'nwdScheduleCare') {
                        const val: any = NWDYgrandTotal;
                        newStroHtml += `<td>${'$' + numberWithCommas(parseFloat(val ? val : 0).toFixed(0))}</td></tr>`;
                    } else if (value == 'nextWorkDay' ) {

                        newStroHtml += `<td>&nbsp;</td></tr>`;
                    }
                    else if (value === 'nwdCareGoal') {
                        const price: string = `$${numberWithCommas(parseFloat(NWDYgrandTotal).toFixed(0))}`;
                        newStroHtml += `<td>${price}</td></tr>`;
                    } else if (value === 'nwdAptCount') {
                        const price: string = `${numberWithCommas(parseFloat(NWDYgrandTotal).toFixed(0))}`;
                        newStroHtml += `<td>${price}</td></tr>`;
                    } else if (value === 'nwdPtGoal') {
                        const num: string = `${parseFloat(NWDYgrandTotal).toFixed(0)}`;
                        newStroHtml += `<td>${num}</td></tr>`;
                    }
                }

            });
            newStroHtml += `</tbody></table></div>`;
        });

    }
    newStroHtml += `</div></div></div></div></div>`;
    newStroHtml += `</div></div></div></div></div></div>`;

    return newStroHtml;

};

export const htmlOpenSurgeryReport: ExportOpenSurgeryHtmlReport = (data, month, year) => {
    const currentMonth = moment(month + 1, 'M').format('MMMM');
    const monthData = data.monthData;
    const yearData = data.yearData;

    const insertSpaces = (s: string) => {
        s = s.replace(/([a-z])([A-Z])/g, '$1 $2');
        s = s.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
        return s;
    }

    const capitalizeFirstLetter = (s: string) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    const numberWithCommas = (x: string) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    let newStroHtml: any = '';
    if (monthData) {
        // month data--------------------------------
        const objMonth: any = {};
        data.monthData.map((s: any) => (Object.assign(objMonth, {[s.practiceAbbr]: s.month})));
        let dataObj: any = {};

        let practiceName: string[] = [];
        let dataKeys: string[] = [];
        data.monthData.forEach((item: any, key: number) => {
            const data: any = key == 0 ? dataKeys = Object.keys(item.month) : '';
            practiceName.push(item.practiceAbbr);
            dataKeys = dataKeys.filter(item => item !== 'scheduleApp')
        });
        let headeoMonth = '';
        if (monthData && monthData.length) {
            monthData.forEach((item: any, key: number) => {
                headeoMonth += `<th class="header-os">${item.practice}`;
            });
        }


        const printDay = moment().format('DD');
        const printMonth = moment().format('M');

        const odDay = moment().subtract(1, 'day').format('DD');
        const odMonth = moment().subtract(1, 'day').format('M');
        newStroHtml += `<div class="ui card"><div class="content pb0"><div class="float-left width100"><h2 class="float-left width50 text-center">Oral Surgery Patient Care Report</h2>` +
            `<h2 class="float-left width50 text-center">${currentMonth} ${odDay} ${year}</h2></div>` +
            `</div></div>` +
            `<div class="ui"><div class="content"><div class="table-responsive ">` +
            `<h3>Current Month: ${moment(month + 1, 'M').format('MMMM')} ${year}</h3>` +
            `<div class="ui card"><div class="content"><div class="table-responsive FreezeTable">`;
        newStroHtml += `<div class="ui card"><div class="content"><div class="table-responsive FreezeTable">`;
        dataKeys.forEach((key) => {
            let obj = {};
            let monthObj = {};
            data.monthData.forEach((item: any) => {
                const monthData = item.month[key];
                Object.keys(monthData).forEach((value) => {
                    Object.assign(monthObj, {[`${item.practiceAbbr}_${value}`]: monthData[value],});
                    obj = Object.assign(obj, {[value]: monthObj})
                });
            });
            Object.assign(dataObj, {[key]: obj})
        });

        Object.keys(dataObj).forEach((key) => {
            const keyObj = dataObj[key];
            newStroHtml += `<div class="ui card mt10"><div class="content"><div class="table-responsive FreezeTable">` +
                `<h3 class="heading">This table shows the ${capitalizeFirstLetter(insertSpaces(key))} per practice</h3>` +
                `<table class="ui celled single line table unstackable tableStyle "><thead><th colspan="1" class="header-os-inner">&nbsp;</th>`;
            newStroHtml += headeoMonth;
            newStroHtml += `</th><th class="header-os">Grand Total</th></tr></thead>`;
            newStroHtml += `<tbody>`;


            Object.keys(keyObj).forEach((value) => {
                newStroHtml += `<tr><td>${capitalizeFirstLetter(insertSpaces(value))}</td>`;
                let monthgrandTotal: any = 0;
                let count: any = 0;
                practiceName.forEach((practice) => {
                    let numseVar: string;
                    if (value == 'percentCompleted' || value == 'showRateHistorical' || value == 'showRateCurrentMonth') {
                        numseVar = parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(1) + '%';
                        monthgrandTotal = monthgrandTotal + parseInt(keyObj[value][`${practice}_${value}`]);

                    } else if (value == 'currentMonthGoalCpv' || value == 'deltaCpv' ||
                        value == 'actCpv' || value == 'goalCpv' || value == 'splyActCpv') {
                        numseVar = '$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '';
                        const checkNum: any = parseFloat(keyObj[value][`${practice}_${value}`])
                        if (checkNum !== 0) {
                            count++;
                            monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                        }
                    } else if (value == 'nextWorkDay' || value == 'lastWorkDay') {
                        numseVar = keyObj[value][`${practice}_${value}`];
                        monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                    } else if ((value).toLowerCase().includes("care") || (value).toLowerCase().includes("cpv")) {
                        numseVar = '$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '';
                        monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                    } else {
                        monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                        numseVar = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;
                    }
                    newStroHtml += `<td>${numseVar}</td>`;
                });

                if (value == 'percentCompleted' || value == 'showRateHistorical' || value == 'showRateCurrentMonth') {
                    const percent: any = (monthgrandTotal / practiceName.length);
                    newStroHtml += `<td>${parseFloat(percent).toFixed(1) + '%'}</td></tr>`;
                } else if (value == 'currentMonthGoalCpv' || value == 'deltaCpv' ||
                    value == 'actCpv' || value == 'goalCpv' || value == 'splyActCpv') {
                    const val: any = (monthgrandTotal / count);
                    newStroHtml += `<td>${'$' + numberWithCommas(parseFloat(val ? val : 0).toFixed(0))}</td></tr>`;
                } else if (value == 'nextWorkDay' || value == 'lastWorkDay') {

                    newStroHtml += `<td>&nbsp;</td></tr>`;
                } else if ((value).toLowerCase().includes("care") || (value).toLowerCase().includes("cpv")) {
                    const price: string = `$${numberWithCommas(parseFloat(monthgrandTotal).toFixed(0))}`;
                    newStroHtml += `<td>${price}</td></tr>`;
                } else {
                    const num: string = `${parseFloat(monthgrandTotal).toFixed(0)}`;
                    newStroHtml += `<td>${num}</td></tr>`;
                }

            });
            newStroHtml += `</tbody></table></div></div></div>`;
        });
        newStroHtml += `</div></div></div>`
        newStroHtml += `</div></div></div></div></div></div>`;
    }
    //year Data
    if (yearData) {
        const objYear: any = {};
        data.yearData.map((s: any) => (Object.assign(objYear, {[s.practiceAbbr]: s.year})));
        let dataObjYear: any = {};
        let practiceNameYear: string[] = [];
        let dataKeysYear: string[] = [];
        data.yearData.forEach((item: any, key: number) => {
            const data: any = key == 0 ? dataKeysYear = Object.keys(item.year) : '';
            practiceNameYear.push(item.practiceAbbr);
            dataKeysYear = dataKeysYear.filter(item => item !== 'scheduleApp')
        });
        let headerYear = '';
        if (yearData && yearData.length) {
            yearData.forEach((item: any, key: number) => {
                headerYear += `<th class="header-os">${item.practice}`;
            });
        }

        newStroHtml += `<div class="ui"><div class="content"><div class="table-responsive">` +
            `<h3>Current Year:${year}</h3>` +
            `<div class="ui card"><div class="content"><div class="table-responsive FreezeTable">`;
        newStroHtml += `<div class="ui card"><div class="content"><div class="table-responsive FreezeTable">`;
        dataKeysYear.forEach((key) => {
            let objYear = {};
            let yearObj = {};
            data.yearData.forEach((item: any) => {
                const yearData = item.year[key];
                Object.keys(yearData).forEach((value) => {
                    Object.assign(yearObj, {[`${item.practiceAbbr}_${value}`]: yearData[value],});
                    objYear = Object.assign(objYear, {[value]: yearObj})
                });
            });
            Object.assign(dataObjYear, {[key]: objYear})
        });

        Object.keys(dataObjYear).forEach((key) => {
            const keyObjYear = dataObjYear[key];
            newStroHtml += `<div class="ui card"><div class="content"><div class="table-responsive FreezeTable">` +
                `<h3 class="heading">This table shows the ${capitalizeFirstLetter(insertSpaces(key))} per practice</h3>` +
                `<table class="ui celled single line table unstackable tableStyle"><thead><th colspan="1" class="header-os-inner">&nbsp;</th>`;
            newStroHtml += headerYear;
            newStroHtml += `</th><th class="header-os">Grand Total</th></tr></thead>`;
            newStroHtml += `<tbody>`;


            Object.keys(keyObjYear).forEach((value) => {
                newStroHtml += `<tr><td>${capitalizeFirstLetter(insertSpaces(value))}</td>`;
                let yearGrandTotal: any = 0;
                let yearCount: any = 0;
                practiceNameYear.forEach((practice) => {
                    let numseVarYear: string;
                    if (value == 'percentCompleted' || value == 'showRateHistorical' || value == 'showRateCurrentMonth') {
                        numseVarYear = parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(1) + '%';
                        yearGrandTotal = yearGrandTotal + parseInt(keyObjYear[value][`${practice}_${value}`]);

                    } else if (value == 'currentMonthGoalCpv' || value == 'deltaCpv' ||
                        value == 'actCpv' || value == 'goalCpv' || value == 'splyActCpv') {
                        numseVarYear = '$' + numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0)) + '';
                        const checkNum: any = parseFloat(keyObjYear[value][`${practice}_${value}`])
                        if (checkNum !== 0) {
                            yearCount++;
                            yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                        }
                    } else if (value == 'nextWorkDay' || value == 'lastWorkDay') {
                        numseVarYear = keyObjYear[value][`${practice}_${value}`];
                        yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                    } else if ((value).toLowerCase().includes("care") || (value).toLowerCase().includes("cpv")) {
                        numseVarYear = '$' + numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0)) + '';
                        yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                    } else {
                        yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                        numseVarYear = `${numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0))}`;
                    }
                    newStroHtml += `<td>${numseVarYear}</td>`;
                });

                if (value == 'percentCompleted' || value == 'showRateHistorical' || value == 'showRateCurrentMonth') {
                    const percent: any = (yearGrandTotal / practiceNameYear.length);
                    newStroHtml += `<td>${parseFloat(percent).toFixed(1) + '%'}</td></tr>`;
                } else if (value == 'currentMonthGoalCpv' || value == 'deltaCpv' ||
                    value == 'actCpv' || value == 'goalCpv' || value == 'splyActCpv') {
                    const val: any = (yearGrandTotal / yearCount);
                    newStroHtml += `<td>${'$' + numberWithCommas(parseFloat(val ? val : 0).toFixed(0))}</td></tr>`;
                } else if (value == 'nextWorkDay' || value == 'lastWorkDay') {
                    newStroHtml += `<td>&nbsp;</td></tr>`;
                } else if ((value).toLowerCase().includes("care") || (value).toLowerCase().includes("cpv")) {
                    const price: string = `$${numberWithCommas(parseFloat(yearGrandTotal).toFixed(0))}`;
                    newStroHtml += `<td>${price}</td></tr>`;
                } else {
                    const num: string = `${parseFloat(yearGrandTotal).toFixed(0)}`;
                    newStroHtml += `<td>${num}</td></tr>`;
                }

            });
            newStroHtml += `</tbody></table></div></div></div>`;
        });
        newStroHtml += `</div></div></div>`
        newStroHtml += `</div></div></div></div></div></div>`;
    }
    return newStroHtml;

};


export const exportUsermatrixReport: ExportUserMatrixReport = (usageData: any) => {
    let csvData = [];
    let header: Array<string> = []
    usageData.header.forEach((item: { title: string }) => {
        header.push(item.title);
    })
    csvData.push(header);
    usageData.data.forEach((item: []) => {
        csvData.push(item);
    })
    exportCSV("UserMatrixReport.csv", csvData);
};


export const exportUsageSpotReport: ExportUsagePTVReport = (usageData: any) => {
    let csvData = [];
    let header: Array<string> = []
    usageData.header.forEach((item: { title: string }) => {
        header.push(item.title);
    })
    csvData.push(header);
    usageData.data.forEach((item: []) => {
        csvData.push(item);
    })
    exportCSV("UsageSPOTReport.csv", csvData);
};