import * as React from 'react';
import pmdpmdCommunityOutreachWonIcon from '../styles/images/pmdCommunityOutreachWon.svg';
import pmdpmdCommunityOutreachLostIcon from '../styles/images/pmdCommunityOutreachLost.svg';

export interface PMDCommunityOutReachUnitItemProps {
  value: any,
  description: any,
  won: boolean
}

export interface PMDCommunityOutReachUnitItemState {
  value: any,
  description: any,
  won: boolean
}

class PMDCommunityOutReachUnitItem extends React.Component<PMDCommunityOutReachUnitItemProps,PMDCommunityOutReachUnitItemState> {  
  constructor(props: PMDCommunityOutReachUnitItemProps) {
    super(props);
    this.state={
      value : props.value,
      description: props.description,
      won: props.won
    }
  }

  componentWillReceiveProps(nextProps: PMDCommunityOutReachUnitItemProps) {
    this.setState({
      value: nextProps.value,
      description: nextProps.description,
      won: nextProps.won
    })
  }

  render() {
    return (
      <table className="pmd_tbl_no_border">
      <thead>
            <tr>
              <td className={this.props.won ? "header pmd_numbers_above pmd_color_below_community_outreach_won":"header pmd_numbers_above pmd_color_below_community_outreach_lost"} >{this.props.value}</td>
            </tr>
            <tr>
              <td className={this.props.won ? "pmd_numbers_below_community_outreach_won" : "pmd_numbers_below_community_outreach_lost"}>
              <img className="ui top left attached spaced image" src={this.props.won ? pmdpmdCommunityOutreachWonIcon : pmdpmdCommunityOutreachLostIcon}/>{this.props.description}</td>
            </tr>
          </thead>
      </table>  
)
  }
}

export default PMDCommunityOutReachUnitItem;