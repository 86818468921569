import React, {useEffect, useState} from 'react';
import Template from "../../components/Template";
import {Button, Card, Icon, Table, Dropdown} from "semantic-ui-react";
import {Report} from "../../model/report";
import moment from "moment";
import {Account} from "../../model/user";
import {Practice} from "../../model/practice";
import {connect} from "react-redux";
import {RootState} from "../../model";
import {useActions} from "../../redux/actions";
import * as specialityPCOActions from "../../redux/actions/specialityPCO";
import * as practiceActions from "../../redux/actions/practice";
import ReferralGoalDetailsModal from "../../components/Reports/ReferralGoalDetailsModal";
import ReferralActualsModals from "../../components/Reports/ReferralActualsModal";
import {brands} from '../../constants'
import {exportCSV} from "../../components/ExportCsv";
import ReactDOMServer from "react-dom/server";
import HelpMark from "../../components/HelpMark";

interface Props {
    user: Account,
    report: Report,
    practice: Practice
}

const tableHeaders = [
    {title: 'Practice', value: 'practiceName'},
    {title: 'Actual OS Referrals', value: 'osReferrals', modal: true},
    {title: 'Actual OS Referrals %', value: 'osCountPerc'},
    {title: 'OS Referral Goal #', value: 'osGoal', modal: true},
    {title: 'OS Referral Goal', subTitle: '(OS - 20% of Actual PV ages 15 and Up practice)', value: 'osGoalPerc'},
    {title: 'Actual Ortho Referrals', value: 'orthoReferrals', modal: true},
    {title: 'Actual Ortho Referrals %', value: 'orthoCountPerc'},
    {title: 'Ortho Referral Goal #', value: 'orthoGoal', modal: true},
    {
        title: 'Ortho Referral Goal',
        subTitle: '(Ortho - 30% of Actual PV ages 9 and Up practice)',
        value: 'orthoGoalPerc'
    },
]

function SpecialityPCO(props: Props) {
    const practiceAction = useActions(practiceActions);
    const specialityPCOAction = useActions(specialityPCOActions);
    const [state, setStates] = useState({
        date: new Date()
    });

    const [dataState, setDataState] = useState({
        data: {} as any,
        practiceData: [] as any[] | null,
        practices: [] as string[],
        modalOpen: false,
        sortBy: '',
        modalActualOpen: false
    });

    const [filters, setFilters] = useState({
        practices: [], region: '', practiceOptions: [], regionOptions: []
    })

    const [selectedRegion, setRegion] = useState(0)
    const [selectedBrand, setBrand] = useState(['FSD', 'SMD'] as any);
    const [selectedPractice, setPractice] = useState('all')
    const [direction, setDirection] = useState<any>(null)
    const [column, setColumn] = useState('')

    const refineRegionsArray = (regionList: []) => {
        let regionListOptions: Array<{ text: string, value: null | number, key: number }> =
            [{key: 0, value: 0, text: 'Select Region'}];
        if (regionList.length) {
            regionList.forEach((item: { name: string, id: number }) => {
                regionListOptions.push({text: item.name, value: item.id, key: item.id})
            });
        }
        const filterss = Object.assign(filters, {regionOptions: regionListOptions});
        setFilters(filterss);
        return regionListOptions;
    };

    const refinePracticeArray = async (response: any) => {
        let practiceNames: Array<string> = [];
        let practiceOptions = [{key: 'all', value: 'all', text: 'Select Practice'}];

        const options = (response as any[]).filter(item => {
            return (!selectedRegion || (props.practice.regionPracticeIds[selectedRegion] as any[]).includes(item.id))
                && (selectedBrand == 'all' || selectedBrand.includes(item.brand));
        }).map(item => ({
            text: item.practice,
            value: item.practiceDBName,
            key: `_pr_drp_${item.id}`
        })) || [];
        practiceOptions = [...practiceOptions, ...options]
        const practices = options.map(practice => practice.value);
        setFilters(Object.assign(filters, {practiceOptions, practices}));
        return {practiceNames: practiceNames, practiceOptions: practiceOptions}
    };

    useEffect(() => {
        loadData();
    }, [state, selectedRegion, selectedBrand, selectedPractice]);

    useEffect(() => {
        practiceAction.fetchRegions(refineRegionsArray)
        practiceAction.fetchPractices(refinePracticeArray)
    }, [selectedRegion, selectedBrand])

    const loadData = async () => {
        const startDate = moment(state.date).startOf('M').format('YYYY-MM-DD');
        const endDate = moment(state.date).endOf('M').format('YYYY-MM-DD');
        const data = await specialityPCOAction.getSpecialityLogs({startDate, endDate});
        const practicesDB = Object.keys(data);
        let practizeObj = practicesDB.map((pItem) => data[pItem].practice);
        practizeObj.sort((pracItemA, pracItemB) => pracItemA.practice > pracItemB.practice ? 1 : -1)
        let practices = practizeObj.map((prac) => prac.practiceDBName);
        if (selectedRegion !== 0 || selectedBrand !== 'all' || selectedPractice !== 'all') {
            setDataState({...dataState, data, practices: filters.practices});
        } else {
            setDataState({...dataState, data, practices});
        }
    }

    const prevMonth = () => {
        const date = moment(state.date).subtract(1, 'months').toDate();
        setStates({...state, date});
    };

    const nextMonth = () => {
        const date = moment(state.date).add(1, 'months').toDate();
        setStates({...state, date});
    };

    const setCurrentMonth = () => {
        setStates({...state, date: new Date()} as any);
    }

    const toggleModal = (modalOpen: boolean, practiceData?: any[]) => {
        setDataState({...dataState, modalOpen, practiceData: practiceData || []})
    }

    const toggleActualsModal = (modalOpen: boolean, practiceData?: any[]) => {
        setDataState({...dataState, modalActualOpen: modalOpen, practiceData: practiceData || []})
    }

    const sendReferralGoal = async (data: any) => {
        toggleModal(false);
        await specialityPCOAction.sendMail(data);
    }

    const sendReferralActual = async (data: any) => {
        toggleActualsModal(false);
        await specialityPCOAction.sendMail(data);
    }

    const handleSort = (name: string) => () => {
        const {data, practices} = dataState
        if (column !== name) {
            const practicesOd = Object.keys(data)
                .map((item) => data[item])
                .sort((itemA, itemB) => itemA[name] > itemB[name] ? 1 : -1)
                .map((prac) => prac.practice.practiceDBName);
            setDataState({...dataState, practices: practicesOd});
            setColumn(name);
            setDirection('ascending');
            return;
        }

        setDataState({...dataState, practices: practices.reverse()});
        direction === 'ascending' ? setDirection('descending') : setDirection('ascending');
    };

    const onChangePracticeMultiple = (e: any, {value}: any) => {
        setFilters({...filters, practices: [value]} as any);
        setPractice(value);
    }

    const getTableData = () => {
        const tableHeader = ['Practice', ...tableHeaders.map(header => header.title)];
        const body = dataState && dataState.practices && dataState.practices
            .map((practice: string) => {
                const columnData = tableHeaders.map(header => dataState.data[practice][header.value as string] || 0);
                return [dataState.data[practice].practice.practice, ...columnData];
            });
        return [tableHeader, ...body];
    }

    const sendEmail = async () => {
        const data = getTableData();
        const csv = data.map(row => row.join(',')).join('\n');
        const pdf = ReactDOMServer.renderToString(<div>
            <h3 style={{textAlign: "center"}}>Speciality PCO</h3>
            <br/>
            {tableData}
        </div>);
        await specialityPCOAction.sendMail({csv, pdf});
    }

    const dataRows = dataState && dataState.practices && dataState.practices.map((practice, i) =>
        dataState.data[practice] &&
        <Table.Row key={'tr_' + i}>
            {tableHeaders.map((header, index) =>
                <Table.Cell key={'row_' + index}>
                    <span
                        className={header.modal ? 'primaryUnderline primaryColor' : ''}
                        onClick={() => {
                            const practiceData = dataState.data[practice];
                            practiceData.code = header.value == 'osGoal' ? 'os' : 'ortho';
                            practiceData.actCode = header.value == 'osReferrals' ? 'osActualRef' : (header.value == 'orthoReferrals' ? 'orthoActualRef' : '')
                            header.value == 'orthoReferrals' || header.value == 'osReferrals' ? toggleActualsModal(!!header.modal, practiceData) : toggleModal(!!header.modal, practiceData);
                        }}
                    >
                        {dataState.data[practice][header.value as string] || 0}
                    </span>
                </Table.Cell>)}
        </Table.Row>
    );

    const tableData = <Table sortable={true} className="table-striped">
        <Table.Header>
            <Table.Row>
                {
                    tableHeaders.map((header, index) =>
                        <Table.HeaderCell
                            key={'header_' + index}
                            sorted={column === header.value ? direction : null}
                            onClick={handleSort(header.value)}
                        >
                            {header.title} {column !== header.value ? <Icon name="sort"/> : ''}
                            {header.subTitle && <br/>}
                            {header.subTitle && <small>{header.subTitle}</small>}
                        </Table.HeaderCell>)
                }
            </Table.Row>
        </Table.Header>
        <Table.Body> {dataRows}</Table.Body>
    </Table>

    return (
        <Template activeLink="clinical scheduling report">
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10 mb10">Speciality PCO <HelpMark pageId='0'/></h2>
                </div>
            </div>

            {
                dataState.modalOpen &&
                <ReferralGoalDetailsModal
                    handleClose={() => toggleModal(false)}
                    sendMail={(data: string) => sendReferralGoal(data)}
                    practiceData={dataState.practiceData}
                />

            }

            {
                dataState.modalActualOpen &&
                <ReferralActualsModals
                    handleClose={() => toggleActualsModal(false)}
                    sendMail={(data: string) => sendReferralActual(data)}
                    practiceData={dataState.practiceData}
                />

            }
            <Card>
                <Card.Content>
                    <div className='dateFilers'>
                        <Button attached='left'><Icon name={'chevron left'} onClick={() => prevMonth()}/></Button>
                        <Button className="mr20" attached='right' onClick={() => nextMonth()}>
                            <Icon name={'chevron right'}/>
                        </Button>
                        <Button
                            content='This month'
                            className="mr20"
                            primary={true}
                            onClick={() => setCurrentMonth()}
                        />
                        <h4 className="displayInline">{moment(state.date).format('MMMM YYYY')}</h4>

                        <Dropdown
                            search={true}
                            className='ml20 mr10 mb15'
                            name="practice"
                            placeholder="Practice"
                            selection={true}
                            options={filters.practiceOptions}
                            onChange={onChangePracticeMultiple}
                        />

                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="region"
                            placeholder="Region"
                            selection={true}
                            options={filters.regionOptions}
                            onChange={(e, d) => setRegion(d.value as any)}
                        />
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="brands"
                            placeholder="Brands"
                            selection={true}
                            multiple={true}
                            options={brands}
                            value={selectedBrand}
                            onChange={(e, data) => setBrand(data.value)}
                        />
                        <Button className="mr20" primary={true} onClick={() => sendEmail()}>
                            <Icon name='file pdf'/> Send Email
                        </Button>
                        <Button
                            className="mr20"
                            primary={true}
                            onClick={() => exportCSV('Speciality PCO.csv', getTableData())}
                        >
                            <Icon name='download'/> Export
                        </Button>
                    </div>
                </Card.Content>
            </Card>

            <Card>
                <Card.Content>
                    {tableData}
                </Card.Content>
            </Card>
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        practice: state.practice
    };
}

export default connect(mapStateToProps)(SpecialityPCO);
