const PriceFormat = (amount:string|number, toFixed=0, format="UK") => {

  if(format === 'UK') {
        if(!amount) {
            return amount;
        }

        if(amount.toString().indexOf('$') < 0) {
            // Check if value is negative then concat brackets (value) and remove -
            if(amount < 0) {
                return '(' + parseFloat(amount.toString()).toFixed(toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('-','') + ')';
            }
            return parseFloat(amount.toString()).toFixed(toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        amount = amount.toString().replace('$',''); //Remove $ sign if exists
        // Check if value is negative then concat brackets (value) and remove -
        if(parseInt(amount) < 0) {
            return `($${parseFloat(amount).toFixed(toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace('-','')})`;
        }

        return `$${parseFloat(amount).toFixed(toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;

  } else {

        const formatter = new Intl.NumberFormat('en-IN', {
          minimumFractionDigits: 2,
        });
        let splitPrice = '0';
        const returnPrice = formatter.format(parseInt(amount.toString()));
        if(returnPrice.indexOf(".") > 0 && toFixed > 0) {
            const splitPrice2 = returnPrice.split(".");
            splitPrice2[1] = splitPrice2[1].slice(0, toFixed);
            splitPrice = splitPrice2.join(".");
        } else if(returnPrice.indexOf(".") > 0 && toFixed === 0) {
            splitPrice = returnPrice.split(".")[0];
        }
        return splitPrice;

      }
};
export default PriceFormat;
