import createReducer from "./createReducer";
import {Action, ActionType} from "../../model/actionTypes";
import { ReferralPractices } from "../../model/referralPractices";

const initialState: ReferralPractices = {
    referralPractices: []
};


export const referrals = createReducer(initialState, {
    [ActionType.FETCH_REFERRAL_PRACTICES](state: ReferralPractices, action: Action<ReferralPractices>) {
        return Object.assign({...state}, action.payload);
    },
})