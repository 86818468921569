import React, {useState, useEffect} from "react";
import {
    Grid,
    Table,
    Button, Icon, Modal, TextArea,
} from "semantic-ui-react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as NotificationsActions from "../redux/actions/educatorNotifications";
import * as PracticeActions from "../redux/actions/practice"
import {useActions} from "../redux/actions";
import Form from "./Form";
import {log} from "util";
import HelpMark from "./HelpMark";

interface EducatorNotificationsProps {
    notifications: any;
    pageStates: {
        createForm: false;
        editForm: false;
    };
    user_practices: practiceSchema[];
}

type practiceSchema = {
    practice: string
}

type notificationScema = {
    createdAt: string
    id?: string
    is_deleted: boolean
    practice: string
    om: number
    rm: number
    others: string
    status: boolean
    updatedAt: string
    mgrLastName: string
    regMgrLastName: string
    mgrEmail: string
    regMgrEmail: string
}
const EducatorNotifications = (props: EducatorNotificationsProps) => {
    const [editForm, setEditForm] = useState(false);
    const [createForm, setCreate] = useState(false);
    const [practiceDropDown, setPracticeDropDown] = useState([]);
    const educatorNotifications = useActions(NotificationsActions);
    const practiceActions = useActions(PracticeActions);
    const [notificationEdited, setEditNotification] = useState<notificationScema>();
    const style = {
        width: "50%",
        padding: 10,
    };
    useEffect(() => {
        educatorNotifications.fetchTableResult();
        practiceActions.fetchPractices(refinePracticeArray)
            .then((practiceOptions: any) => {
                setPracticeDropDown(practiceOptions);
            });
    }, []);

    const refinePracticeArray = (response: any) => {
        let practiceOptions = [{key: 0, value: 0, text: 'Select Practice'}];
        if (response) {
            response.forEach((item: any) => {
                practiceOptions.push({text: item.practice, value: item.practice, key: item.id.toString()});
            })
        }
        return practiceOptions;
    };

    const closeForm = (action: any) => {
        if (action === "editForm") {
            setEditForm(false);
        } else if (action === "createForm") {
            setCreate(false);
        }
    };

    const deleteRecord = (id: number) => {
        const confirm = window.confirm("Are you sure you want to delete this?");
        if (confirm) {
            educatorNotifications.deleteRecord(id)
        }

    }

    return (
        <>
            <Template activeLink="educator-notifications">

                <Grid columns={2}>
                    <Grid.Row>
                        {createForm && (
                            <Grid.Column>
                                <div className="ui card">
                                    <div className="content pb0 mb10">
                                        <div style={style}>
                                            <h1>Campaign Creation</h1>
                                            <Form
                                                action={createForm ? "createForm" : ""}
                                                saveForm={educatorNotifications.saveForm}
                                                closeForm={closeForm}
                                                item={{}}
                                                cancel={setCreate}
                                                dropDownOptions={practiceDropDown}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid.Column>
                        )}
                        {editForm && (
                            <Grid.Column>
                                <div className="ui card">
                                    <div className="content pb0 mb10">
                                        <div style={style}>
                                            <h1>Edit</h1>
                                            <Form
                                                action={editForm ? "editForm" : ""}
                                                saveForm={educatorNotifications.saveForm}
                                                item={notificationEdited}
                                                cancel={setEditForm}
                                                closeForm={closeForm}
                                                dropDownOptions={practiceDropDown}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid.Column>
                        )}
                    </Grid.Row>
                </Grid>
                    <div className="ui card">
                        <div className="content pb0">
                            <h2 className="float-left mr10">Educator Notifications <HelpMark pageId='53'/></h2>
                            <div className="topFilters">
                                <Button primary onClick={() => setCreate(true)}>
                                    Create New
                                </Button>
                            </div>
                        </div>
                    </div>

              <div className="ui card">
                <div className="content">
                  <div className="FreezeTable">
                    <Table className="ui table table-striped celled table-hover unstackable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                      <div>ACTIVE/INACTIVE</div>
                                    </Table.HeaderCell>
                                  <Table.HeaderCell>
                                        <div>PRACTICE</div>
                                    </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    <div>Practice Manager</div>
                                    </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    <div>Regional Manager</div>
                                    </Table.HeaderCell>
                                  <Table.HeaderCell>
                                    <div>Dist Manager</div>
                                    </Table.HeaderCell>
                                  <Table.HeaderCell>
                                        <div>OTHERS</div>
                                    </Table.HeaderCell>
                                  <Table.HeaderCell>
                                        Action
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {props.notifications &&
                                    props.notifications.map((result: any, index: any) => (
                                        <Table.Row key={result.id + `${index} ${Math.random()}`}>
                                            <Table.Cell>
                                                <input
                                                    type="checkbox"
                                                    readOnly={true}
                                                    checked={result.status}
                                                    value={result.status ? 0 : 1}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>{result.practice}</Table.Cell>
                                            <Table.Cell>

                                                {result.om > 0 ? <Icon color='green' name={'checkmark'}/> :
                                                    <Icon color='red' name={'close'}/>}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {result.rm > 0 ? <Icon color='green' name={'checkmark'}/> :
                                                    <Icon color='red' name={'close'}/>}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {result.distMgr > 0 ? <Icon color='green' name={'checkmark'}/> :
                                                    <Icon color='red' name={'close'}/>}
                                            </Table.Cell>
                                            <Table.Cell>{result.others}</Table.Cell>
                                            <Table.Cell>
                                                <Icon className="cursorPointer" name="edit"
                                                      onClick={() => {
                                                          setEditNotification(result);
                                                          setEditForm(!editForm);
                                                      }}/>
                                                <Icon className="cursorPointer ml10" name="trash alternate"
                                                      onClick={() => {
                                                          deleteRecord(result.id);
                                                      }}/>

                                            </Table.Cell>

                                        </Table.Row>
                                    ))}
                            </Table.Body>
                        </Table>
                  </div>
                </div>
              </div>
            </Template>
        </>
    );
};

function mapStateToProps(state: RootState) {
    return {
        notifications: state.educatorNotification.notifications,
        pageStates: state.educatorNotification.pageStates,
        user_practices: state.educatorNotification.user_practices,
    };
}

export default connect(mapStateToProps)(EducatorNotifications);
