import React, {useState} from 'react';
import {Button, Modal, Dropdown, Input, Form} from "semantic-ui-react";

function ExportModal(props: any) {
    const [state, setState] = useState({
        exportModal: false,
        exportCount: 100,
        location: props.location
    });

    const exportCSV = () => {
        setState({...state, exportModal: false});
        props.exportCSV(state.exportCount, state.location || props.location);
    };

    return (
        <div>
            <Button
                content="EXPORT to CSV"
                secondary={true}
                size="small"
                icon="download"
                onClick={() => setState({...state, exportModal: true})}
            />
            {
                state.exportModal &&
                <Modal
                    onClose={() => setState({...state, exportModal: true})}
                    size="small"
                    open={true}
                >
                    <Modal.Content>
                        <h2>SPOT EXPORT</h2>
                        <br/>
                        <Form>
                            <Form.Field>
                                <label>Select Practice</label>
                                <Dropdown
                                    className="form-group"
                                    options={props.allPractices}
                                    onChange={(e, d) => setState({...state, location: d.value})}
                                    value={state.location || props.location}
                                    placeholder="Select Practice"
                                    selection={true}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Maximum Rows to Export</label>
                                <Input
                                    className="form-control "
                                    type="number"
                                    value={state.exportCount}
                                    onChange={(e, v) => setState({...state, exportCount: +v.value})}
                                    required={true}
                                />
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary={true} onClick={exportCSV} content="Export to CSV"/>
                    </Modal.Actions>
                </Modal>
            }
        </div>
    );
}

export default ExportModal;
