import React, {useEffect, useState} from "react";
import {GoogleLogin} from 'react-google-login';
import Logo from "../styles/images/cdpLogin.png";
import LoginImageSlider from '../components/LoginImageSlider';
import {useActions} from "../redux/actions";
import * as Actions from "../redux/actions/auth";
import {Grid,} from 'semantic-ui-react';
import CookieModal from '../components/CookieModal';

function SignIn(props: any) {
    const authActions = useActions(Actions);
    const [practiceDetails, setPracticeDetails] = useState([]);
    const [cookieModal, setCookieModal] =  useState(false);


    useEffect(() => {
        authActions.getPractices().then((res: any) => {
            res && setPracticeDetails(res.filter((r: any) => r && r.officeImage));
        })
        const queryParams: any = new URLSearchParams(window.location.search);
        const accessToken = queryParams.get("token");
        if (accessToken && accessToken.length > 0) {
            authActions.cdpLogin(accessToken)
        }
    }, []);


    const doLogin = async (data: any) => {
        await authActions.LogIn(data.profileObj);
    };

    const onFailure = (response: any) => new Promise((resolve, reject) => {
        if (response && response.error === 'idpiframe_initialization_failed') {
            setCookieModal(true);
        }
    });

    const closeCookieModal = () => {
        setCookieModal(false);
    }

    return (
        <div className="login">
            <div className="bgShape">
            <Grid>
                <Grid.Row>
                    <Grid.Column floated='right' className="customGrid" computer={14} tablet={16} mobile={16} >
                        <Grid.Row>
                            <Grid.Column className='textCenter'>
                                <img
                                    src={Logo}
                                    className="align-self-center loginLogo"
                                    alt="Logo"
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className='textCenter'>
                                <GoogleLogin
                                    clientId="534451231648-d2a526h66llkn1e781gv6fgjvofsph2c.apps.googleusercontent.com"
                                    buttonText="Sign in with Google"
                                    className="google"
                                    onSuccess={doLogin}
                                    onFailure={onFailure}
                                />
                                { cookieModal && <CookieModal handleClose={closeCookieModal}/>}
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column computer={6} tablet={10} mobile={16} className='textCenter'>
                                <LoginImageSlider practiceDetails={practiceDetails}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>

            </Grid>
            </div>
        </div>
    )
        ;
}


export default SignIn;