import * as React from 'react';
import {Grid} from "semantic-ui-react";
import PMDCommunityOutReachUnitItem from './PMDCommunityOutReachUnitItem';
import PMDUnitItem from './PMDUnitItem';

export interface PMDCommunityOutreachProps {
  practicename: string,
  leads_entered:any,
  leads_won:any,
  leads_worked:any,
  leads_lost:any,
}

export interface PMDCommunityOutreachState {
  practicename: string,
  leads_entered:any,
  leads_won:any,
  leads_worked:any,
  leads_lost:any,
}

class PMDCommunityOutreach extends React.Component<PMDCommunityOutreachProps,PMDCommunityOutreachState> {  
  constructor(props: PMDCommunityOutreachProps) {
    super(props);
    this.state={
      practicename : props.practicename,
      leads_entered:props.leads_entered,
      leads_won:props.leads_won,
      leads_worked:props.leads_worked,
      leads_lost:props.leads_lost,
    }
    this.setPracticeName=this.setPracticeName.bind(this);
    this.setLeads_entered=this.setLeads_entered.bind(this);
    this.setLeads_won=this.setLeads_won.bind(this);
    this.setLeads_worked=this.setLeads_worked.bind(this);
    this.setLeads_lost=this.setLeads_lost.bind(this);
  }

  setPracticeName(value: string) {
    this.setState({
      practicename : value
    })
  }

  setLeads_entered(value: any) {
    this.setState({
      leads_entered : value
    })
  }

  setLeads_won(value: any) {
    this.setState({
      leads_won : value
    })
  }

  setLeads_worked(value: any) {
    this.setState({
      leads_worked : value
    })
  }

  setLeads_lost(value: any) {
    this.setState({
      leads_lost : value
    })
  }

  componentWillReceiveProps(nextProps: PMDCommunityOutreachProps) {
    this.setState({
      practicename: nextProps.practicename
    })
  }

  render() {
      return (
        <Grid columns='two'>
          <Grid.Row>
            <Grid.Column>
              <PMDUnitItem value={this.props.leads_entered} description="Leads Entered Today"/>
            </Grid.Column>
            <Grid.Column>
              <PMDUnitItem value={this.props.leads_worked} description="Leads Worked Today"/>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <PMDCommunityOutReachUnitItem value={this.props.leads_won} description="Leads Won Today" won={true}/>
            </Grid.Column>
            <Grid.Column>
              <PMDCommunityOutReachUnitItem value={this.props.leads_lost} description="Leads Lost Today" won={false}/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )
  }
}

export default PMDCommunityOutreach;