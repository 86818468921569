import React, { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";

function RoadmapModal(props: any) {
    return (
        <>
            {
                <Modal className='modalCenter' size="large" onClose={() => props.onRoadmapModalClose()} open={true}>
                    <Modal.Header>
                        <b>CDP Websites Roadmap</b>
                        <div className="ui top right attached label production_right_label">
                            <Button onClick={() => props.onRoadmapModalClose()} inverted>
                                Close
                            </Button>
                        </div>
                    </Modal.Header>
                    <Modal.Content scrolling={true}>
                        <div style={{height: "70vh"}}>
                            <iframe src="https://cdp-websites.s3.amazonaws.com/Roadmap.pdf" width="100%" height="100%"></iframe>
                        </div>
                    </Modal.Content>
                </Modal>
            }

        </>
    )
}

export default RoadmapModal