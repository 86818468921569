import React from 'react'
import {Button, Icon, Modal} from "semantic-ui-react";
import {unscheduledTreatmentObj} from "../constants";
import _ from "lodash";
interface ProcedureCodeDetailsModalPros {
    hideDetailModal : Function,
    changeView : Function,
    sendEmail : Function,
    stateData : any
}

const ProcedureCodeDetailsModal:React.FC<ProcedureCodeDetailsModalPros>=(props)=>{

    const prepareEmail = () => {
        const patientDetails = getPatientDetails();
        const patientData = patientDetails.patientData;
        const data :any = [];

        patientData && patientData.forEach((item: any) => {
            data.push({
                Practice : item.selectedPractice.toUpperCase(),
                PatNum : item.PatNum,
                Insurance : item.Insurance,
                LastVisit : item.LastVisit,
                Next_Apt : item.Next_Apt,
                APT_Type2 : item.APT_Type2,
                OpName : item.OpName,
                TxFee : item.TxFee?'$'+item.TxFee:'',
                source : item.source,
            })
        });
        props.sendEmail(data);

    }

    const getPatientDetails = () => {

        const {data, selectedPractice, callCenter, selectedCategory, view, column, reverse, sortCount} = props.stateData;
        if (data == null || selectedPractice == null || selectedCategory == null || view == null) {
            return {patientData: [], num: 0}
        }

        let num = 0;
        const isSingle = !Array.isArray(selectedCategory);
        let selectedCategories = [];
        let patientData :any = [];


        const patientHeader = ['Practice', 'PatNum', 'Insurance', 'Last Visit', 'Next Appointment', 'Appointment Type', 'Operatory Name', 'Open TX'];
        const headerKeys = ['selectedPractice', 'PatNum', 'Insurance', 'LastVisit', 'Next_Apt', 'APT_Type2', 'OpName', 'TxFee'];

        if(!isSingle){
            patientHeader.push("Source");
            headerKeys.push('source')
        }

        const practiceData = data.find((item: { practiceName: string; }) => item.practiceName.toLowerCase() === selectedPractice.toLowerCase());
        if(isSingle){
            selectedCategories.push(selectedCategory);
        }else{
            selectedCategories=selectedCategory;
        }
        selectedCategories.forEach((selectedCateg: string)=> {

            if (selectedPractice) {
                let currPatientData = selectedCateg ? practiceData[`${selectedCateg}Patients`] : practiceData[`${selectedCateg}Patients`];
                currPatientData = currPatientData.map((r: { selectedPractice: any; source: any; }) => {
                    r.selectedPractice = selectedPractice
                    if(!isSingle) {
                        r.source = unscheduledTreatmentObj[selectedCateg] ? unscheduledTreatmentObj[selectedCateg] : "";
                    }
                    return r;
                })
                patientData=[...patientData,...currPatientData]
            } else {
                let filtered = data.filter((r: { callCenter: string; }) => {
                    if (callCenter && callCenter === "Yes") {
                        return r.callCenter == "Yes";
                    }
                    if (callCenter && callCenter === "No") {
                        return r.callCenter != "Yes";
                    }
                    return r;
                });

                filtered.forEach((dat: { [x: string]: any; practiceName: any; }) => {


                    let currP = dat[`${selectedCateg}Patients`];
                    currP = currP.map((r: { selectedPractice: any; source: any; }) => {
                        r.selectedPractice = dat.practiceName
                        if(!isSingle) {
                            r.source = unscheduledTreatmentObj[selectedCateg] ? unscheduledTreatmentObj[selectedCateg] : "";
                        }
                        return r;
                    })
                    patientData = [...patientData, ...currP]
                })
            }
        })

        patientData=view =="with appointment"?patientData.filter((r: { APT_Type2: string; })=>r.APT_Type2=='TX Apt'):
            (view =="without appointment"?patientData.filter((r: { APT_Type2: string; })=>r.APT_Type2!='TX Apt'):patientData)
        num+=patientData.length;
        if(column){
            if(reverse){
                patientData=_.sortBy(patientData, [column]);
                if(sortCount%2==1){
                    patientData=patientData.reverse();
                }
            }else{
                patientData=_.sortBy(patientData, [column]);
            }
        }


        return {patientData, num};
    }

    const generateTable = () => {
        const { selectedCategory, column } = props.stateData;
        const isSingle = !Array.isArray(selectedCategory);
        const patientDetails = getPatientDetails();
        const patientData = patientDetails.patientData;
        const num = patientDetails.num;



        const tableData = [];
        const rows: any = [];
        const header :any = [];


        const patientHeader = ['Practice', 'PatNum', 'Insurance', 'Last Visit', 'Next Appointment', 'Appointment Type', 'Operatory Name', 'Open TX'];
        const headerKeys = ['selectedPractice', 'PatNum', 'Insurance', 'LastVisit', 'Next_Apt', 'APT_Type2', 'OpName', 'TxFee'];

        if (patientData && !patientData.length) {
            return {tableData: [], num: 0}
        }


        if(!isSingle){
            patientHeader.push("Source");
            headerKeys.push('source')
        }

        patientData && patientData.forEach((item: any) => {
            rows.push(
                <tr>
                    <td>{item.selectedPractice.toUpperCase()}</td>
                    <td>{item.PatNum}</td>
                    <td>{item.Insurance}</td>
                    <td>{item.LastVisit}</td>
                    <td>{item.Next_Apt}</td>
                    <td>{item.APT_Type2}</td>
                    <td>{item.OpName}</td>
                    <td>{item.TxFee?'$'+item.TxFee:''}</td>
                    {!isSingle&&<td>{item.source}</td>}
                </tr>
            )
        });

        patientHeader.forEach((item,k) => {

            header.push(< th
                    className={`cursorPointer ${column === headerKeys[k] ? 'active' : ''}`}
                    // onClick={this.handleSort(k<headerKeys.length?headerKeys[k]:"")}
                >
                    <div>
                        {item}
                        {/*<Icon name={`sort amount ${direction} icon ml-05`}></Icon>*/}
                    </div>
                </th>
            )
        });
        tableData.push(
            <table className="table tableCustomStyle mb0 mt10 table-freeze">
                <thead>
                <tr>
                    {header}
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        );
        return {tableData,num};

    }


    const {selectedPractice, selectedCategory} = props.stateData;
    const patientDetaits= generateTable()
    return(
        <Modal
            onClose={() => props.hideDetailModal()}
            open={true}
        >
            <Modal.Header>
                Patient Details! {selectedPractice} { Array.isArray(selectedCategory)?'TOTAL UNSCHEDULED APPT':selectedCategory}  ({patientDetaits.num})
            </Modal.Header>
            <Modal.Content>
                <div className="budgetContainer">
                    <select name="view" id="view" onChange={(e) => props.changeView(e)}>
                        <option value="all">all</option>
                        <option value="with appointment">With Appointment</option>
                        <option value="without appointment">without Appointment</option>
                    </select>
                    {patientDetaits && patientDetaits.tableData}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary={true} onClick={() => props.hideDetailModal()}>
                    Cancel
                </Button>
                <Button
                    primary={true}
                    onClick={()=> prepareEmail()}
                >
                    Send Email
                </Button>
            </Modal.Actions>
        </Modal>
    )
}


export default ProcedureCodeDetailsModal

