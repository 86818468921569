import React, {useEffect, useState} from "react";
import Template from "./Template";
import * as reportActions from "../redux/actions/report";
import * as practiceActions from "../redux/actions/practice";
import {useActions} from "../redux/actions";
import {Practice, UdaPracticeInfo} from "../model/practice";
import {RootState} from "../model";
import {connect} from "react-redux";
import {Dropdown, Button, Icon} from "semantic-ui-react";
import moment from 'moment';
import DynamicHtmlTable from "./DynamicHtmlTable";
import _ from "lodash";
import {Report} from "../model/report";
import MDHwriteoffPopUp from './MDHwriteoffPopUp'
import {exportPTVMDHReport} from "./ExportCsv";
import {Patients} from "../model/patients";
import * as patientActions from "../redux/actions/patients";
import {brands} from "../constants";
import * as userSetupActions from "../redux/actions/userSettings";
import {SetupFields} from "../enums/userSetupField";
import {SetupAppName} from "../enums/userSetupAppName";
import HelpMark from "./HelpMark";

const filterformat = 'YYYY-MM-DD';

interface Props {
    report: Report
    practice: Practice,
    patients: Patients
}

function MDHwriteOffReport(props: Props) {
    const reportAction = useActions(reportActions);
    const practiceAction = useActions(practiceActions);
    const patientAction = useActions(patientActions);
    const userSetup = useActions(userSetupActions);
    const initialState: any = {
        filters: {
            locations: [],
            region: '',
            brands: [],
            startDate: moment().endOf('month').format(filterformat),
            endDate: moment().subtract(1, 'year').startOf('month').format(filterformat),
            practices: ['all']
        },
        locationSelected: false,
        TableData: [[{title: 'No Records Found'}]],
        TableHead: [{title: ''}],
        practiceOptions: [],
        column: -1,
        direction: 'sort',
    };
    const popUpStateInit: any = {
        modal: false,
        locations: null,
        monthYear: null
    }
    const [state, setStates] = useState(initialState);
    const [popupState, setPopupState] = useState(popUpStateInit);

    useEffect(() => {
        practiceAction.fetchPracticeUda();
        practiceAction.fetchRegions(refineRegionsArray);
        practiceAction.fetchPractices(refinePracticeArray)
    }, []);

    const handleSort = (clickedColumn: number) => () => {
        let {column, TableData, direction, TableHead} = state;
        if (column !== clickedColumn) {
            column = clickedColumn;
            TableData = _.sortBy(TableData, o => {
                return o[clickedColumn].title.props ? o[clickedColumn].title.props.children[1] : o[clickedColumn].title;
            });
            direction = 'sort ascending';
            setStates({...state, TableData: TableData.slice(), column, direction});
            return;
        }
        TableData = TableData.reverse();
        direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
        setStates({...state, TableData: TableData.slice(), direction});
    };

    const getData = (filters: any, locationSelected: boolean, practiceInfo: any, brnds: any) => {
        reportAction.mdhmWriteOffReport(filters).then((res: any) => {
            const TableData = pushTableData(res, filters);
            const TableHead = TableHeadData(filters);
            filters.brands = brnds;
            setStates({...state, TableData, TableHead, filters, locationSelected});
        });
    };

    const sendWriteOffEmail = () => {
        let {filters} = state;
        filters.sendReport = true;
        reportAction.mdhmWriteOffReport(filters);
    };

    const exportWriteOff = () => {
        let header = TableHeadData(state.filters);
        const res: any = props.report.mdhWriteOffReport;
        const filters = state.filters;
        let total: any = {};
        const dataArr: any = {};
        let data: (string | number)[][] = [];
        Object.keys(res).map(location => {
            const montData: any = {};
            let rows = [];
            Object.keys(res[location]).forEach((item: any) => {
                const row = res[location][item];
                dataArr[location] = {};
                const monthYear = moment(row.procByMonth + ' ' + row.procByYear, 'M YYYY').format('MMM YYYY');
                montData[monthYear] = row.WriteOff
            });
            dataArr[location] = montData;
            rows.push(location)
            for (let i = 0; i <= 12; i++) {
                const monthDate = moment(filters.startDate).subtract(i, 'month').startOf('month').format('MMM YYYY');
                const writeOff = dataArr[location] && dataArr[location][monthDate] ? dataArr[location][monthDate] : 0;
                const title = writeOff > 0 ? '$' + writeOff : writeOff;
                rows.push(title);
                total[monthDate] = (total[monthDate] ? total[monthDate] : 0) + writeOff;

            }
            data.push(rows)
        });
        let rows: any = ['Total']
        for (let i = 1; i <= 13; i++) {
            const monthDate = moment(filters.startDate).subtract(i - 1, 'month').startOf('month').format('MMM YYYY');
            const title = total[monthDate] > 0 ? '$' + total[monthDate].toFixed(2) : 0;
            rows.push(title)
        }
        data.push(rows);
        exportPTVMDHReport({header, data: data})
    }

    const onChangeLocation = (e: any, element: any) => {
        const filters: any = {...state.filters};
        const values = element.value;
        const practiceNames = props.practice.practiceNames;
        filters.locations = values;
        filters.practices = values;
        filters.region = null;
        if (values.length >= 0 && values.indexOf('all') === values.length - 1) {
            filters.locations = practiceNames;
            filters.practices = ['all'];
        } else if (values.length > 1 && values.indexOf('all') === 0) {
            values.splice(0, 1);
            filters.locations = values;
            filters.practices = values;
        }
        getData(filters, true, props.practice.practiceInfo, filters.brands);
    };

    const onChangeRegion = (e: any, element: any) => {
        const values = element.value;
        const filters: any = {...state.filters};
        filters.locations = props.practice.practiceNames;
        filters.practices = ['all'];
        filters.region = null;
        if (values) {
            if (element.value) {
                const mainData = props.practice.practiceNames;
                const info: any = props.practice.regionPractices[element.value];

                let practiceName: any = [];
                info && info.forEach((item: any, key: number) => {
                    const pracFilter = mainData.filter((data: any) =>
                        data.toLowerCase() === item.toLowerCase());
                    if (pracFilter.length > 0) {
                        practiceName.push(pracFilter[0])
                    }
                });
                filters.locations = practiceName;
                filters.practices = practiceName;
                filters.region = element.value;

            }
        }
        getData(filters, false, props.practice.practiceInfo, filters.brands);
    };

    const onChangeBrands = (e: any, element: any) => {
        const filters: any = {...state.filters};
        const values = element.value;
        const practiceNames = props.practice.practiceNames;
        let selectedPractices: string[] = [];
        const tempBrandFilterPractices: any = [];
        values.forEach((brand: any) => {
            const res = props.practice.udaPracticeInfo.filter(
                (item: UdaPracticeInfo) => {
                    if (item.brand === brand) {
                        return true
                    }
                }
            )
            tempBrandFilterPractices.push(
                ...res.map(prac => {
                    return prac.practiceName
                })
            )
        })
        selectedPractices = Array.from(new Set([...selectedPractices, ...tempBrandFilterPractices])).sort();
        if (filters.locations.length > 0)
            selectedPractices = filters.locations.filter((item: any) => selectedPractices.includes(item));
        filters.locations = selectedPractices;
        filters.practices = selectedPractices;
        filters.region = null;
        if (values.length >= 0 && values.indexOf('all') === values.length - 1) {
            filters.locations = practiceNames;
            filters.practices = ['all'];
        } else if (values.length > 1 && values.indexOf('all') === 0) {
            values.splice(0, 1);
            filters.locations = selectedPractices;
            filters.practices = selectedPractices;
        }
        getData(filters, false, props.practice.practiceInfo, values);
    };

    const brandsOptions = () => {
        const brandOptions: string[] = [];
        const brandsObject: { [key: string]: string[] } = {}
        brands.forEach((brand) => {
            const res = props.practice.udaPracticeInfo.filter(
                (item: UdaPracticeInfo) => {
                    if (item.brand === brand.value) {
                        return true
                    }
                }
            ).map(item => item.practiceName)
            brandsObject[brand.value] = [...res.map(i => i.toLocaleLowerCase())]
        })
        if (state.filters.region) {
            const tempSelectedPracsPerRegion: { [key: string]: string[] } = {};
            if (props.practice.regionPractices[state.filters.region]) {
                tempSelectedPracsPerRegion[state.filters.region] =
                    props.practice.regionPractices[state.filters.region] as []

            }
            Object.keys(tempSelectedPracsPerRegion).forEach(region => {
                Object.keys(brandsObject).forEach(brand => {
                    const commonPracs = _.intersection(brandsObject[brand], tempSelectedPracsPerRegion[region])
                    if (commonPracs.length > 0) {
                        brandOptions.push(brand)
                    }
                })
            });
            return (brandOptions.length > 0) ? brandOptions.map(b => {
                    return {
                        value: b,
                        key: b,
                        text: b
                    }
                }) :
                brands
        } else return brands;
    }

    const nextMonth = () => {
        const filters: any = {...state.filters};
        filters.startDate = moment(filters.startDate).add(1, 'month').endOf('month').format(filterformat);
        filters.endDate = moment(filters.endDate).add(1, 'month').startOf('month').format(filterformat);
        getData(filters, state.locationSelected, props.practice.practiceInfo, filters.brands);
    };

    const prevMonth = () => {
        const filters: any = {...state.filters};
        filters.startDate = moment(filters.startDate).subtract(1, 'month').endOf('month').format(filterformat);
        filters.endDate = moment(filters.endDate).subtract(1, 'month').startOf('month').format(filterformat);
        getData(filters, state.locationSelected, props.practice.practiceInfo, filters.brands);
    };

    const refineRegionsArray = (regionList: any) => {
        let regionListOptions = [{key: '0', value: null, text: 'Select Region'}];
        if (regionList.length) {
            regionList.forEach((item: any) => {
                regionListOptions.push({text: item.name, value: item.id, key: item.id})
            });
        }
        return regionListOptions;
    };

    const refinePracticeArray = (response: any) => {
        let practiceNames: Array<string> = [];
        let practiceInfo: any = {};
        let practiceOptions = [{key: 'all', value: 'all', text: 'Select Practice'}];
        if (response) {
            response.forEach((item: any) => {
                practiceOptions.push({text: item.practice, value: item.practice, key: item.id.toString()});
                practiceNames.push(item.practice);
                practiceInfo[item.practice.toLowerCase()] = item;
            })
        }
        let userParams = {field: SetupFields.Practice, appId: SetupAppName.CDP_MY};
        userSetup.getSetup(userParams).then((defaultPractice: any) => {
            const filters = {...state.filters};
            filters.locations = practiceNames;
            filters.practices = ['all']
            if (defaultPractice && defaultPractice.value && defaultPractice.value !== 'all') {
                let defaultPractices: any = defaultPractice.value.split(',');
                let practices: any = []
                defaultPractices.forEach((defaultPractice: any) => {
                    if (practiceInfo[defaultPractice]) {
                        let practiceData: any = practiceInfo[defaultPractice]
                        practices.push(practiceData.practice)
                    } else practices.push(defaultPractice)
                })
                filters.locations = practices;
                filters.practices = practices;
            }
            getData(filters, false, practiceInfo, filters.brands);
        })
        return {practiceNames: practiceNames, practiceOptions: practiceOptions, practiceInfo: practiceInfo}
    };

    const myDate = (date: string, format: string) => {
        return moment(date).format(format);
    };

    const handlePopUpClose = () => {
        setPopupState({...popupState, modal: false});
    }

    const handleDetailsPopup = (locations: any, monthYear: any) => {
        setPopupState({...popupState, modal: true, locations: locations, monthYear: monthYear});
    }

    const TableHeadData = (filters: any) => {
        const TableHead = new Array();
        TableHead[0] = {title: 'Practice'};
        for (let i = 1; i <= 13; i++) {
            const monthDate = moment(filters.startDate).subtract(i - 1, 'month').startOf('month').format('MMM YYYY');
            TableHead[i] = {title: monthDate}
        }
        return TableHead;
    };

    const pushTableData = (res: any, filters: any) => {
        const TableData = new Array();
        const dataArr: any = {};
        let total: any = {};
        Object.keys(res).map(location => {
            const montData: any = {};
            Object.keys(res[location]).forEach((item: any) => {
                const row = res[location][item];
                dataArr[location] = {};
                const monthYear = moment(row.procByMonth + ' ' + row.procByYear, 'M YYYY').format('MMM YYYY');
                montData[monthYear] = row.WriteOff
            });
            dataArr[location] = montData;
            const writeOffArr = [];
            for (let i = 0; i <= 12; i++) {
                const monthDate = moment(filters.startDate).subtract(i, 'month').startOf('month').format('MMM YYYY');
                const writeOff = dataArr[location] && dataArr[location][monthDate] ? dataArr[location][monthDate].toFixed(2) : 0;
                const title = writeOff > 0 ? <span className={'primaryUnderline'}
                                                   onClick={() => handleDetailsPopup([location], monthDate)}>${writeOff}</span> : writeOff;
                total[monthDate] = (total[monthDate] ? total[monthDate] : 0) + writeOff;
                writeOffArr.push({title: title});
            }
            writeOffArr.unshift({title: location})
            TableData.push(writeOffArr)
        });
        const TotalRow = new Array();
        TotalRow[0] = {title: 'Total'};
        for (let i = 1; i <= 13; i++) {
            const monthDate = moment(filters.startDate).subtract(i - 1, 'month').startOf('month').format('MMM YYYY');
            const title = total[monthDate] > 0 ? <span className={'primaryUnderline'}
                                                       onClick={() => handleDetailsPopup(filters.locations, monthDate)}>${parseFloat(total[monthDate]).toFixed(2)}</span> : 0;
            TotalRow[i] = {title: title}
        }
        TableData.push(TotalRow);
        return TableData;
    };

    return (
        <Template activeLink='MDHMwriteOffReport'>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10"> MDHM WO Current View <HelpMark pageId='8'/></h2>
                    <div className={'topFilters'}>
                        <a className="link" onClick={sendWriteOffEmail}><Icon name="send"/> Send</a>
                        <Button primary={true} onClick={exportWriteOff}>Download</Button>
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="locations"
                            multiple={true}
                            placeholder="Practice"
                            selection={true}
                            options={props.practice.practiceOptions}
                            onChange={onChangeLocation}
                            value={state.filters.practices}
                        />
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="region"
                            placeholder="Region"
                            selection={true}
                            options={props.practice.regionOptions}
                            onChange={onChangeRegion}
                            value={state.filters.region}
                        />
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="brands"
                            placeholder="Brands"
                            selection={true}
                            multiple={true}
                            options={brandsOptions()}
                            onChange={onChangeBrands}
                            value={state.filters.brands}
                        />

                    </div>

                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    <div className={'dateFilers'}>
                        <Button onClick={prevMonth} attached='left'><Icon name={'chevron left'}/></Button>
                        <Button className="mr20" onClick={nextMonth} attached='right'>
                            <Icon name={'chevron right'}/>
                        </Button>
                        <h4 className="displayInline">{myDate(state.filters.startDate, 'MMM YYYY')}</h4>
                    </div>
                    <div className="table-MDHMwriteOffReport">
                        <DynamicHtmlTable
                            key={0}
                            className={'adjustment'}
                            tableHead={state.TableHead}
                            heading={""}
                            tableData={state.TableData}
                            onHeadClick={handleSort}
                            sortedColumn={state.column}
                            direction={state.direction}
                        />
                    </div>
                </div>
            </div>

            {popupState.modal && <MDHwriteoffPopUp data={popupState} handleClose={handlePopUpClose}/>}
        </Template>
    );

}

function mapStateToProps(state: RootState) {
    return {
        practice: state.practice,
        adjustments: state.adjustments,
        report: state.report,
        patients: state.patients
    };
}

export default connect(mapStateToProps)(MDHwriteOffReport);

