// Add {hide: true} in object to hide any menu from menu bar
import { MainMenu } from "./constants";

export const menusArray: Array<MainMenu> = [
  {
    pageId: 1,
    name: "Patient Care Report",
    customIcon: "iconDentist",
    class: "innerDropdown",
    icon: "file alternate outline",
    title: "PATIENT CARE REPORT",
    partner: "PCR",
    role: [],
    subMenus: [
      {
        pageId: 21,
        name: "PCR By Brand Roll Up",
        icon: "file alternate outline",
        title: "PCR By Brand Roll Up",
        role: [],
      },
      {
        pageId: 12,
        name: "PCR By Region Roll Up",
        icon: "file alternate outline",
        title: "PCR By Region Roll Up",
        role: [],
      },
      {
        pageId: 2,
        name: "PCR By Dr Practice",
        icon: "file alternate outline",
        title: "PCR By Dr Practice",
        role: [],
      },
      {
        pageId: 3,
        name: "PCR Splashboard",
        icon: "file alternate outline",
        title: "PCR Splashboard",
        role: [],
      },
      {
        pageId: 4,
        name: "PCR Outlook",
        icon: "file alternate outline",
        title: "PCR Outlook",
        role: [],
      },
      {
        pageId: 5,
        name: "PCR Age",
        icon: "file alternate outline",
        title: "PCR Age",
        role: [],
      },
      {
        pageId: 6,
        name: "PCR Insurance",
        icon: "file alternate outline",
        title: "PCR Insurance",
        role: [],
      },
      {
        pageId: 7,
        name: "PCR Cpv",
        icon: "file alternate outline",
        title: "PCR Cpv",
        role: [],
      },
      {
        pageId: 8,
        name: "PCR Age Mix",
        icon: "file alternate outline",
        title: "PCR Age Mix",
        role: [],
      },
      {
        pageId: 9,
        name: "PCR Insurance Mix",
        icon: "file alternate outline",
        title: "PCR Insurance Mix",
        role: [],
      },
      {
        pageId: 10,
        name: "PCR Provider Cpv",
        icon: "file alternate outline",
        title: "PCR Provider Cpv",
        role: [],
      },
      {
        pageId:11,
        name: "PCR Goal By Workday",
        icon: "file alternate outline",
        title: "PCR Goal By Workday",
        role: [],
      },      
    ],
  },
  {
    pageId: 6,
    name: "Clinical Scheduling Report",
    class: "innerDropdown",
    icon: "file alternate outline",
    title: "CLINICAL SCHEDULING REPORT",
    partner: "CSR",
    role: [],
    subMenus: [],
  },
  {
    pageId: 7,
    name: "Adjustment",
    class: "innerDropdown",
    icon: "adjust",
    title: "ADJUSTMENT",
    partner: "ADJUSTMENT",
    role: [],
    subMenus: [
      {
        pageId: 8,
        name: "Adjustment Setup",
        icon: "setting",
        title: "Adjustment Setup",
        role: [],
      },
      {
        pageId: 9,
        name: "Adjustment Acknowledgement",
        icon: "info circle",
        title: "Adjustment Acknowledgement",
        role: [],
      },
    ],
  },
  {
    pageId: 11,
    name: "Five9",
    class: "innerDropdown",
    icon: "call square",
    title: "FIVE9",
    partner: "FIVE9",
    role: [],
    subMenus: [
      {
        pageId: 12,
        name: "five9list",
        icon: "file alternate outline",
        title: "DCRM <==> FIVE9",
        role: [],
      },
    ],
  },
  {
    pageId: 13,
    name: "MDM",
    class: "innerDropdown",
    icon: "database",
    title: "MDM",
    partner: "MDM",
    role: [],
    subMenus: [],
  },
  {
    pageId: 14,
    name: "Usage",
    class: "innerDropdown",
    icon: "pie chart",
    title: "Usage",
    partner: "Usage",
    role: [],
    subMenus: [],
  },
  {
    pageId: 17,
    name: "SporeService",
    class: "innerDropdown",
    icon: "users",
    title: "Spore",
    partner: 'SPORE',
    role: [],
    subMenus: [
      
    ]
  },
  {
    pageId: 18,
    name: "SporeNotifications",
    class: "innerDropdown",
    icon: "user",
    title: "Spore Notifications",
    partner: 'Spore Notifications',
    role: [],
    subMenus: [
     
    ]
  },
  {
    pageId: 19,
    name: "Educator",
    class: "innerDropdown",
    icon: "adjust",
    title: "Educator",
    partner: "Educator",
    role: [],
    subMenus: [
      {
        pageId: 20,
        name: "Educator Notifications",
        icon: "setting",
        title: "Educator Notifications",
        role: [],
      }
    ],
  },
];
