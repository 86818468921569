import request from "superagent";
import * as localforage from "localforage";

export interface IRequestParams {
	method: string;
	url: string;
	queryParameters?: { [key: string]: string | boolean | number | Date | undefined };
	body?: Object;
	accessToken?: string;
	basic?: string;
}

export abstract class ApiService {
    protected async executeRequest<T>(params: IRequestParams) {
        let authData: any = (await localforage.getItem("persist:root")) || {};
        const authObject = JSON.parse(authData) || {};
        const authDetails = JSON.parse(authObject.auth) || {};
        const authorization = "Bearer " + (authDetails.accessToken || params.accessToken);

        return new Promise<T>((resolve, reject) => {
            let req = request(params.method, params.url).set("Content-Type", "application/json");
            params.queryParameters && req.query(params.queryParameters);
            authorization && req.set("Authorization", authorization);
            params.body && req.send(params.body);

            req.end((error: any, response: any) => {
                if (response && response.ok) {
                    return resolve(response.body);
                }
                return reject((response && response.body) || error);
            });
        });
    }
}

export class Api extends ApiService {
	baseApiUrl: string;

	constructor(url: string) {
		super();
		this.baseApiUrl = url;
	}

	public async get(url: string, data: any, query: any, accessToken?: string) {
		const requestParams: IRequestParams = {
			method: "GET",
			url: `${this.baseApiUrl}/${url}`,
			body: data,
			queryParameters: query,
            accessToken: accessToken
		};
		return this.executeRequest(requestParams);
	}

	public async post(url: string, data: any, query: any) {
		const requestParams: IRequestParams = {
			method: "POST",
			url: `${this.baseApiUrl}/${url}`,
			body: data,
			queryParameters: query
		};
		return this.executeRequest(requestParams);
	}

	public async put(url: string, data: any, query: any) {
		const requestParams: IRequestParams = {
			method: "PUT",
			url: `${this.baseApiUrl}/${url}`,
			body: data,
			queryParameters: query
		};
		return this.executeRequest(requestParams);
	}

	public async patch(url: string, data: any, query: any) {
		const requestParams: IRequestParams = {
			method: "PATCH",
			url: `${this.baseApiUrl}/${url}`,
			body: data,
			queryParameters: query
		};
		return this.executeRequest(requestParams);
	}

	public async delete(url: string, data: any) {
		const requestParams: IRequestParams = {
			method: "DELETE",
			url: `${this.baseApiUrl}/${url}`,
			body: data
		};
		return this.executeRequest(requestParams);
	}

}
