import React, {useEffect, useState} from "react";
import Template from "../Template";
import {RootState} from "../../model";
import {connect} from "react-redux";
import {Button, Card, Dimmer, Dropdown, Header, Icon, Loader, Modal, Table, Input} from "semantic-ui-react";
import {useActions} from "../../redux/actions";
import * as issuesActions from "../../redux/actions/downTimeTracker";
import * as practiceActions from "../../redux/actions/practice";
import CreateIssuesForm from "./CreateIssue";
import ViewNotes from "./ViewNotes";
import moment from "moment";
import { applicationList, brands, downTimeTrackerStatusList } from "../../constants";
import { formatMinutes } from "../Common";
import _ from "lodash";

type EditCreateIssueFormModalProps = {
    saveForm: any;
    item: any;
    action: any;
    content: any;
    closeForm: any;
    practices: any,
};

const initialFilters: any = {
    practice: null,
    status: null,
    application: null,
    brand: null, 
    assignee: null   
};

const EditCreateIssueFormModal = (props: EditCreateIssueFormModalProps) => {
    return (
        <Modal
            closeIcon
            open={true}
            onClose={props.closeForm}
        >
            <Header content={props.content}/>
            <Modal.Content>
                <CreateIssuesForm
                    action={props.action}
                    saveForm={props.saveForm}
                    closeForm={props.closeForm}
                    item={props.item}
                    practicesList={props.practices}
                />
            </Modal.Content>
        </Modal>
    )
}

const IssueList = (props: any) => {
    const issuesAction = useActions(issuesActions);
    const practiceAction = useActions(practiceActions);
    const [filters, setFilters] = useState(initialFilters);
    
    const [state, setState] = useState({
        issuesList: [] as any[],
        openIssuesCount: 0,
        createForm: false,
        editForm: false,
        editItem: {},
        loading :true,
        orderBy: '',
        order: true,
        hideColumn: {
            status: false,
            application: false,
            downTimeStart: false,
            brands: false,
            assignee: false,
            jiraStoryNumber: false,
            practices: false,
            whatIsDown: false,
            reasonForBeingDown: false,
            whatToDoInMeanTime: false,
            projectResolutionDateAndTime: false,
            notes: false,
            timeDown: false,
            rootCause: false,
            preventation: false,
            errorSource: false,
            supportTicketNumber: false,
            howWasItReported: false,
            notificationGroup: false
        }
    });    

    useEffect(() => {
        initialiseFilters();
        loadData();
    }, []);

    const initialiseFilters = () => {
        if (!props.practices.length) {
            practiceAction.fetchPracticesOptions();
        }
    }

    const loadData = async () => {
        const result: any = await issuesAction.fetchIssuesList();
        const openIssuesCount: number = result.filter((r: { status: string; }) => r.status == 'Open').length;
        setState({
            ...state,
            issuesList: result,
            openIssuesCount: openIssuesCount,
            createForm: false,
            editForm: false,
            loading :false
        });

        if(filters['status'] != 'Closed'){
            
            filters['status'] = 'Open';
            setFilters({...filters});
        }
    }
    const {
        createForm,
        editForm,
        editItem
    } = state

    const onFiltersChange = (e: any, element: any) => {
        const keyword = element.name;
        const value = element.value;
        filters[keyword] = value;
        setFilters({...filters});
    }

    const filterIssues = (item: any) => {
        const byBrand = () => {
            if (filters.brand==null||filters.brand=='all') return true;
            return item['brands'].toLowerCase().includes(filters.brand.toLowerCase());
        }
        const byApplication = () => {
            if (filters.application==null||filters.application=='all') return true;
            return item['application'].toLowerCase().includes(filters.application.toLowerCase());
        }
        const byPractice = () => {
            if (filters.practice==null||filters.practice=='all') return true;
            return item['practices'].toLowerCase().includes(filters.practice.toLowerCase());
        }
        const byStatus = () => {
            if (filters.status==null||filters.status=='all') return true;
            return item['status'].toLowerCase().includes(filters.status.toLowerCase());
        }
        const byAssignee = () => {
            if (filters.assignee==null||filters.assignee=='') return true;
            return item['assignee'].toLowerCase().includes(filters.assignee.toLowerCase());
        }
        return byBrand() && byApplication() && byPractice() && byStatus() && byAssignee();
    }

    const getColumnOptions = () => {
        const obj = {
            status: 'Status',
            application: 'Application',
            downTimeStart: 'Down Time Start',
            brands: 'Brands',
            assignee: 'Assignee',
            jiraStoryNumber: 'Jira Story Number',
            practices: 'Practices',
            whatIsDown: 'What Is Down',
            reasonForBeingDown: 'Reason For Being Down',
            whatToDoInMeanTime: 'What To Do In Mean Time',
            projectResolutionDateAndTime: 'Project Resolution Date And Time',
            notes: 'Notes',
            timeDown: 'Time Down',
            rootCause: 'Root Cause',
            preventation: 'Preventation',
            errorSource: 'Error Source',
            supportTicketNumber: 'Support Ticket Number',
            howWasItReported: 'How Was It Reported',
            notificationGroup: 'Notification Group'
        }

        return Object.entries(obj).map(([key, value])=>{return { key, text: value, value: key }});
    }

    const getSortRows = (sortColumn: string, orderLocal: boolean) => {
        const orderUpdated = !orderLocal;
        let data: any;
        
        if (sortColumn === 'application') {
            data = _.orderBy(issuesList, item => item.application.toLowerCase(), [orderLocal ? 'asc' : 'desc'])
            return setState({...state, issuesList: data, orderBy: sortColumn, order: orderUpdated});
        }
        if (sortColumn === 'assignee') {
            data = _.orderBy(issuesList, item => item.assignee === null ? '' : item.assignee.toLowerCase(), [orderLocal ? 'asc' : 'desc'])
            return setState({...state, issuesList: data, orderBy: sortColumn, order: orderUpdated});
        }
        if (sortColumn === 'brands') {
            data = _.orderBy(issuesList, item => item.brands.toLowerCase(), [orderLocal ? 'asc' : 'desc'])
            return setState({...state, issuesList: data, orderBy: sortColumn, order: orderUpdated});
        }
        if (sortColumn === 'downTimeStart') {
            data = _.orderBy(issuesList, item => item.downTimeStart.toLowerCase(), [orderLocal ? 'asc' : 'desc'])
            return setState({...state, issuesList: data, orderBy: sortColumn, order: orderUpdated});
        }
        if (sortColumn === 'jiraStoryNumber') {
            data = _.orderBy(issuesList, item => item.jiraStoryNumber === null ? '' : item.jiraStoryNumber.toLowerCase(), [orderLocal ? 'asc' : 'desc'])
            return setState({...state, issuesList: data, orderBy: sortColumn, order: orderUpdated});
        }
        if (sortColumn === 'supportTicketNumber') {
            data = _.orderBy(issuesList, item => item.supportTicketNumber === null ? '' : item.supportTicketNumber.toLowerCase(), [orderLocal ? 'asc' : 'desc'])
            return setState({...state, issuesList: data, orderBy: sortColumn, order: orderUpdated});
        }
        if (sortColumn === 'status') {
            data = _.orderBy(issuesList, item => item.status.toLowerCase(), [orderLocal ? 'asc' : 'desc'])
            return setState({...state, issuesList: data, orderBy: sortColumn, order: orderUpdated});
        }
        if (sortColumn === 'timeDown') {
            data = _.orderBy(issuesList, item => item.timeDown.toLowerCase(), [orderLocal ? 'asc' : 'desc'])
            return setState({...state, issuesList: data, orderBy: sortColumn, order: orderUpdated});
        }

        data = _.orderBy(issuesList, [sortColumn], [orderLocal ? 'asc' : 'desc'])
        return setState({...state, issuesList: data, orderBy: sortColumn, order: orderUpdated});
    };

    const closeForm = () => {
        if (editForm) {
            setState({...state, editForm: false})
        }
        if (createForm) {
            setState({...state, createForm: false})
        }
    }

    const submitForm = async (payload: any, action: any) => {
        setState({
            ...state,
            loading :true
        });
        await issuesAction.saveForm(payload, action);
        await loadData();
    }

    const sendNotification = async (data: any) => {
        setState({
            ...state,
            loading :true
        });
        await issuesAction.sendNotification(data);
        await loadData();
    }    

    const onChangeHideColumn = (e: any, element: any) => {

        let hideColumn = state.hideColumn;
        Object.keys(hideColumn).forEach(key=>{

            let fieldName = key as keyof typeof hideColumn;
            hideColumn[fieldName] = element.value.includes(key);
        });
        setState({...state, hideColumn: hideColumn});
    }

    const isAdmin = props.user.role.includes("admin");
    const {openIssuesCount, order, orderBy, issuesList} = state;
    const {practices} = props;
    const {practice,status,application,brand,assignee} = filters;
    const direction = order ? 'sort down' : 'sort up';
    const isThereOpenIssues: boolean = openIssuesCount > 0;
    const columnOptions = getColumnOptions();

    return (
        <Template activeLink="down-time-tracker">
            <Card>
                <Card.Content>
                    <h2>
                        List of App Status Tracker issues  
                        ({ isThereOpenIssues ? openIssuesCount : 0})
                                         
                    </h2>
                    <div className='topFilters'>
                        <Dropdown
                            style={{minWidth: '200px'}}
                            className='float-left'
                            placeholder='Hide Column'
                            selection
                            multiple
                            search
                            name="hideColumn"
                            options={columnOptions}
                            onChange={onChangeHideColumn}
                        />
                        <Input placeholder='Assignee'
                            defaultValue={assignee || ''}
                            onChange={onFiltersChange}
                            name="assignee"
                        />
                        <Dropdown
                            placeholder="Select Status"
                            search={true}
                            selection={true}
                            options={downTimeTrackerStatusList}
                            onChange={onFiltersChange}
                            defaultValue={status || 'Open'}
                            name="status"
                        />
                        <Dropdown
                            placeholder="Select Application"
                            search={true}
                            selection={true}
                            options={applicationList}
                            onChange={onFiltersChange}
                            defaultValue={application || ''}
                            name="application"
                        />
                        <Dropdown
                            placeholder="Select Brand"
                            search={true}
                            selection={true}
                            options={brands}
                            onChange={onFiltersChange}
                            defaultValue={brand || ''}
                            name="brand"
                        />
                        <Dropdown
                            placeholder="Select Practice"
                            search={true}
                            selection={true}
                            options={practices}
                            onChange={onFiltersChange}
                            defaultValue={practice || ''}
                            name="practice"
                        />
                        {
                            isAdmin &&
                            <Button
                                className="float-right"
                                primary={true}
                                onClick={() => setState({...state, createForm: true})}
                            >
                                Create New
                            </Button>
                        }
                      
                    </div>
                </Card.Content>
            </Card>
            {createForm && (<EditCreateIssueFormModal
                content="Create Issue"
                action={createForm ? "createForm" : ""}
                saveForm={submitForm}
                closeForm={closeForm}
                item={{}}
                practices={practices}
            />)}
            {editForm && (<EditCreateIssueFormModal
                content="Edit Issue"
                action={editForm ? "editForm" : ""}
                saveForm={submitForm}
                closeForm={closeForm}
                item={editItem}
                practices={practices}
            />)}
            <div className="ui card">
                <div className="content pb0 mb10" style={{maxHeight: "75vh", overflowY: "auto"}}>
                    <Table className={'table table-striped table-hover my-0 FreezeTable'}>
                    <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell content="Actions"/>
                                    {!state.hideColumn.status &&
                                        <Table.HeaderCell
                                            className={`cursorPointer ${orderBy === 'status' ? 'active' : ''}`}
                                            onClick={() => getSortRows('status', order)} 
                                        >
                                            <div>
                                                Status
                                                <Icon className={orderBy === 'status' ? direction : 'sort'}/>
                                            </div>
                                        </Table.HeaderCell>
                                    }
                                    {!state.hideColumn.application &&
                                        <Table.HeaderCell
                                            className={`cursorPointer ${orderBy === 'application' ? 'active' : ''}`}
                                            onClick={() => getSortRows('application', order)} 
                                        >
                                            <div>
                                                Application
                                                <Icon className={orderBy === 'application' ? direction : 'sort'}/>
                                            </div>
                                        </Table.HeaderCell>
                                    }
                                    {!state.hideColumn.downTimeStart &&
                                        <Table.HeaderCell
                                            className={`cursorPointer ${orderBy === 'downTimeStart' ? 'active' : ''}`}
                                            onClick={() => getSortRows('downTimeStart', order)} 
                                        >
                                            <div>
                                                Down Time Start
                                                <Icon className={orderBy === 'downTimeStart' ? direction : 'sort'}/>
                                            </div>
                                        </Table.HeaderCell>
                                    }
                                    {!state.hideColumn.brands &&
                                        <Table.HeaderCell
                                            className={`cursorPointer ${orderBy === 'brands' ? 'active' : ''}`}
                                            onClick={() => getSortRows('brands', order)} 
                                        >
                                            <div>
                                                Brands
                                                <Icon className={orderBy === 'brands' ? direction : 'sort'}/>
                                            </div>
                                        </Table.HeaderCell>
                                    }
                                    {!state.hideColumn.assignee &&
                                        <Table.HeaderCell
                                            className={`cursorPointer ${orderBy === 'assignee' ? 'active' : ''}`}
                                            onClick={() => getSortRows('assignee', order)} 
                                        >
                                            <div>
                                                Assignee
                                                <Icon className={orderBy === 'assignee' ? direction : 'sort'}/>
                                            </div>
                                        </Table.HeaderCell>
                                    }
                                    {!state.hideColumn.jiraStoryNumber &&
                                        <Table.HeaderCell
                                            className={`cursorPointer ${orderBy === 'jiraStoryNumber' ? 'active' : ''}`}
                                            onClick={() => getSortRows('jiraStoryNumber', order)} 
                                        >
                                            <div>
                                                Jira Story Number
                                                <Icon className={orderBy === 'jiraStoryNumber' ? direction : 'sort'}/>
                                            </div>
                                        </Table.HeaderCell>
                                    }
                                    {!state.hideColumn.practices &&
                                        <Table.HeaderCell content="Practices"/>
                                    }
                                    {!state.hideColumn.whatIsDown &&
                                        <Table.HeaderCell content="What Is Down"/>
                                    }
                                    {!state.hideColumn.reasonForBeingDown &&
                                        <Table.HeaderCell content="Reason For Being Down"/>
                                    }
                                    {!state.hideColumn.whatToDoInMeanTime &&
                                        <Table.HeaderCell content="What to do in mean time"/>
                                    }
                                    {!state.hideColumn.projectResolutionDateAndTime &&
                                        <Table.HeaderCell content="Project Resolution Date and Time"/>
                                    }
                                    {!state.hideColumn.notes &&
                                        <Table.HeaderCell content="Notes"/>
                                    }
                                    {!state.hideColumn.timeDown &&
                                        <Table.HeaderCell
                                            className={`cursorPointer ${orderBy === 'timeDown' ? 'active' : ''}`}
                                            onClick={() => getSortRows('timeDown', order)} 
                                        >
                                            <div>
                                                Time Down
                                                <Icon className={orderBy === 'timeDown' ? direction : 'sort'}/>
                                            </div>
                                        </Table.HeaderCell>
                                    }
                                    {!state.hideColumn.rootCause &&
                                        <Table.HeaderCell content="Root Cause"/>
                                    }
                                    {!state.hideColumn.preventation &&
                                        <Table.HeaderCell content="Prevention"/>
                                    }
                                    {!state.hideColumn.errorSource &&
                                        <Table.HeaderCell content="Error Source"/>
                                    }
                                    {!state.hideColumn.supportTicketNumber &&
                                        <Table.HeaderCell
                                            className={`cursorPointer ${orderBy === 'supportTicketNumber' ? 'active' : ''}`}
                                            onClick={() => getSortRows('supportTicketNumber', order)} 
                                        >
                                            <div>
                                                Support Ticket Number
                                                <Icon className={orderBy === 'supportTicketNumber' ? direction : 'sort'}/>
                                            </div>
                                        </Table.HeaderCell>
                                    }
                                    {!state.hideColumn.howWasItReported &&
                                        <Table.HeaderCell content="How was it reported	"/>
                                    }
                                    {!state.hideColumn.notificationGroup &&
                                        <Table.HeaderCell content="Notification Group"/>
                                    }
                                </Table.Row>
                            </Table.Header>
                        <Table.Body>
                        {state.loading && <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        }
                            {
                                issuesList.length > 0 ?
                                issuesList.filter(filterIssues).map((data: any, key: any) =>                                    
                                        <Table.Row key={'data_' + key}>
                                            {
                                                <Table.Cell>
                                                    {
                                                        isAdmin &&
                                                        <Button
                                                            onClick={() => setState({
                                                                ...state,
                                                                editForm: true,
                                                                editItem: {...data}
                                                            })}
                                                        >
                                                            <Icon name="edit"/>
                                                            Edit
                                                        </Button>
                                                    }
                                                    
                                                    {
                                                        isAdmin &&
                                                        <Button
                                                            onClick={() => sendNotification(data)}
                                                            primary={true}
                                                        >
                                                            <Icon
                                                                name='play'
                                                            />
                                                            Send Notification
                                                        </Button>
                                                    }
                                                </Table.Cell>
                                            }
                                            {!state.hideColumn.status &&
                                                <Table.Cell>{data.status}</Table.Cell>
                                            }
                                            {!state.hideColumn.application &&
                                                <Table.Cell>{data.application}</Table.Cell>
                                            }
                                            {!state.hideColumn.downTimeStart &&
                                                <Table.Cell>{moment(data.downTimeStart).format('D MMMM YYYY HH:mm')}</Table.Cell>
                                            }
                                            {!state.hideColumn.brands &&
                                                <Table.Cell>{data.brands}</Table.Cell>
                                            }
                                            {!state.hideColumn.assignee &&
                                                <Table.Cell>{data.assignee}</Table.Cell>
                                            }
                                            {!state.hideColumn.jiraStoryNumber &&
                                                <Table.Cell>{data.jiraStoryNumber}</Table.Cell>
                                            }
                                            {!state.hideColumn.practices &&
                                                <Table.Cell>{data.practices}</Table.Cell>
                                            }
                                            {!state.hideColumn.whatIsDown &&
                                                <Table.Cell>{data.whatIsDown}</Table.Cell>
                                            }
                                            {!state.hideColumn.reasonForBeingDown &&
                                                <Table.Cell>{data.reasonForBeingDown}</Table.Cell>
                                            }
                                            {!state.hideColumn.whatToDoInMeanTime &&
                                                <Table.Cell>{data.whatToDoMeanTime}</Table.Cell>
                                            }
                                            {!state.hideColumn.projectResolutionDateAndTime &&
                                                <Table.Cell>{data.resolutionDateTime!==null?moment(data.resolutionDateTime).format('D MMMM YYYY HH:mm'):""}</Table.Cell>
                                            }
                                            {!state.hideColumn.notes &&
                                                <Table.Cell><ViewNotes item={data} user={props.user}/></Table.Cell>
                                            }
                                            {!state.hideColumn.projectResolutionDateAndTime &&
                                                <Table.Cell>{data.resolutionDateTime!==null?formatMinutes(moment.duration(moment(data.resolutionDateTime).diff(moment(data.downTimeStart))).asMinutes()):
                                                    data.timeDown}</Table.Cell>
                                            }
                                            {!state.hideColumn.rootCause &&
                                                <Table.Cell>{data.rootCause}</Table.Cell>
                                            }
                                            {!state.hideColumn.preventation &&
                                                <Table.Cell>{data.prevention}</Table.Cell>
                                            }
                                            {!state.hideColumn.errorSource &&
                                                <Table.Cell>{data.errorSource}</Table.Cell>
                                            }
                                            {!state.hideColumn.supportTicketNumber &&
                                                <Table.Cell>{data.supportTicketNumber}</Table.Cell>
                                            }
                                            {!state.hideColumn.howWasItReported &&
                                                <Table.Cell>{data.howWasItReported}</Table.Cell>
                                            }
                                            {!state.hideColumn.notificationGroup &&
                                                <Table.Cell>{data.notificationGroup}</Table.Cell>
                                            }
                                        </Table.Row>
                                    ) :
                                    <Table.Row>
                                        <Table.Cell colSpan={5} className="text-center" content="No Data"/>
                                    </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        practices: state.practice.practiceOptions,
        user: state.auth.user
    };
}

export default connect(mapStateToProps)(IssueList);
