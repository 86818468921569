import React, { useEffect, useState } from 'react';
import { AccordionTitle, AccordionContent, Icon, Grid, GridRow, GridColumn, Loader, Progress, Label } from 'semantic-ui-react'
import * as reportActions from "../../../redux/actions/report";
import * as HealthStatusActions from "../../../redux/actions/healthStatus";
import { useActions } from '../../../redux/actions';
import moment from 'moment';

function OrthoMountDateHealth() {
    const initialState = { loading: false, mountDate: 'Fetching details ...', status: false, panel: '' };
    const reportAction = useActions(reportActions);
    const [state, setState] = useState(initialState);
    const healthStatusActions = useActions(HealthStatusActions);
    const { mountDate, loading, panel, status } = state;
    const prefix = 'mountDate'

    useEffect(() => {
        setState({ ...state, loading: true });
        reportAction.getMountDate().then((response: any) => {
            const date = moment(response[0].backup_finish_date).utc().format('YYYY-MM-DD');
            const status = checkMountDate(date);
            healthStatusActions.addToCount({ success: status ? 1 : 0, pending: 0, failed: !status ? 1 : 0 }).then();
            setState({ ...state, status: status, mountDate: date, loading: false })
        }).catch(() => {
            setState({ ...state, status: status, loading: false })
        });
    }, [])

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }

    const checkMountDate = (date: string) => {
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'days').startOf('day');
        return (moment(date).isSame(yesterday, 'day') || moment(date).isSame(today, 'day'));
    }

    return (
        <>
            <AccordionTitle active={state.panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Ortho Mount Date</span>
                            {loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <Progress color={status ? 'green' : 'red'} size='medium' className='red-bg mb0' percent={100} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === prefix}>
                <h1 className='text-center'>
                    <Label size='massive' color={status ? 'green' : 'red'} content={mountDate} />
                </h1>
            </AccordionContent>
        </>
    );
}

export default OrthoMountDateHealth;