import * as React from 'react';

export interface PMDCardHeaderProps {
  value: any,
  description: any,
  image: any
}

export interface PMDCardHeaderState {
  value: any,
  description: any,
  image: any
}

class PMDCardHeader extends React.Component<PMDCardHeaderProps,PMDCardHeaderState> {  
  constructor(props: PMDCardHeaderProps) {
    super(props);
    this.state={
      value : props.value,
      description: props.description,
      image: props.image
    }
  }

  componentWillReceiveProps(nextProps: PMDCardHeaderProps) {
    this.setState({
      value: nextProps.value,
      description: nextProps.description,
      image: nextProps.image
    })
  }

  render() {
    return (
      <div className="content production">                        
          <div className="header">
          <div className="ui top left attached label production_left_label">
          <p className="left floated production"><img className="ui top left attached spaced image" src={this.props.image}/>{this.props.value}</p>
          </div>
          <div className="ui top right attached label production_right_label">{this.props.description}</div>
          </div>
      </div>
)
  }
}

export default PMDCardHeader;