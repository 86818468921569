import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../model";

import HelpMark from "../HelpMark";
import Template from "../Template";
import { Button, Icon, Input, Table } from "semantic-ui-react";

import * as ukgActions from "../../redux/actions/UKGServices";
import { useActions } from "../../redux/actions";
import * as issuesActions from "../../redux/actions/downTimeTracker";
import Loading from "../../Loading";
import { numberFormat } from "../numberFormat";
import { nonCurrencyNumberFormat } from "../nonCurrencyNumberFormat";
import { removeUndefinedObjects } from "../../utils/common";

import _ from 'lodash';

interface Props {
    user: any
}

function Render(props: Props) {
    const [payStatements, setPayStatements] = useState({
        "account_id": "",
        "status": false,
        "pay_statements": [],
        "employee_name": ""
    });
    const [isLoading, setIsLoading] = useState(false);
    const [earnings, setEarnings] = useState([]);
    const [issuesState, setIssuesState] = useState({ appIsDown: false });
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState(true);
    const [orderBy, setOrderBy] = useState('');

    const issuesAction = useActions(issuesActions);
    const ukgAction = useActions(ukgActions);

    useEffect(() => {
        checkIssues();
    }, []);

    const checkIssues = async () => {
        setIsLoading(true);
        const result: any = await issuesAction.fetchOpenIssuesList(["Admin-UKG-PayStatements"]);

        setIsLoading(false);
        setIssuesState({
            ...issuesState,
            appIsDown: result.length > 0 ? true : false
        });
    }

    const getSortRows = (sortColumn: string, orderLocal: boolean) => {
        const orderUpdated = !orderLocal;

        const sortFn = (item: any) => {
          switch (sortColumn) {
            case 'id':
              return item.id;
            case 'base_rate':
              return item.base_rate;
            case 'hours':
                return item.hours;
            case 'ee_amount':
                return item.ee_amount;
            case 'pay_date':
              return new Date(item.pay_date.toLowerCase());
            case 'date_range_from':
              return new Date(item.date_range_from.toLowerCase());
            case 'date_range_to':
              return new Date(item.date_range_to.toLowerCase());

            default:
              return item[sortColumn];
          }
        };

    
        const sortedData: any = _.orderBy(earnings, sortFn, [orderLocal ? 'asc' : 'desc']);

        setOrder(orderUpdated);
        setOrderBy(sortColumn);
        setEarnings(sortedData);
    };

    const onClickSearch = () => {

        setIsLoading(true);

        ukgAction.getEmployeePayStatements(searchValue)
            .then((results: any) => {
                setPayStatements(results);
                
                let data: any = [];
                if(results != null && results.pay_statements.length > 0){

                    results.pay_statements.forEach((payStatement: any) => {

                        if(payStatement.hasOwnProperty("earnings")){
                            payStatement.earnings.forEach((payroll: any) => {
                                data.push({
                                    id: payroll.id,
                                    base_rate: payroll.base_rate,
                                    hours: payroll.hours,
                                    ee_amount: payroll.ee_amount,
                                    pay_date: payStatement.pay_date,
                                    date_range_from: payroll.date_range_from,
                                    date_range_to: payroll.date_range_to
                                });
                            });
                        }
                    });
                }

                setEarnings(data);
                setIsLoading(false);
            })
            .catch((error: any) => {

                setEarnings([]);
                setPayStatements({
                    "account_id": "",
                    "status": false,
                    "pay_statements": [],
                    "employee_name": ""
                });
                setIsLoading(false);
            });
    }

    const sendCSV = () => {
        setIsLoading(true);
        const payStatementsData = payStatements?.pay_statements || [];
        const payStatementsTableData = removeUndefinedObjects(payStatementsData.flatMap((payStatement: any) =>
            payStatement.earnings?.map((payroll: any) => ({
                id: payroll.id || '',
                base_rate: nonCurrencyNumberFormat(payroll.base_rate || 0),
                hours: nonCurrencyNumberFormat(payroll.hours || 0),
                ee_amount: numberFormat(payroll.ee_amount || 0),
                pay_date: payStatement.pay_date || '',
                date_range_from: payroll.date_range_from || '',
                date_range_to: payroll.date_range_to || ''
            }))
        ));
        const totalEeAmount = payStatementsTableData?.reduce(
            (total: any, row: any) => {
                const eeAmount = row?.ee_amount ? parseFloat(row.ee_amount.replace(/[$,]/g, "")) : 0;
                return isNaN(eeAmount) ? NaN : total + eeAmount;
            },
            0
        );

        const totalHours = nonCurrencyNumberFormat(payStatementsTableData?.reduce(
            (total: any, row: any) => {
                const hours = row?.hours ? parseFloat(row.hours.replace(/[$,]/g, "")) : 0;
                return isNaN(hours) ? NaN : total + hours;
            },
            0
        ));
        const accountId = payStatements?.account_id ?? ".";
        const employeeName = payStatements?.employee_name ?? ".";
        
        ukgAction.sendMailPayStatement({ data: payStatementsTableData, accountId: accountId || ".", employeeName: employeeName || ".", sum: numberFormat(totalEeAmount), totalHours });
        setIsLoading(false);
    }

    const getPayrollDetailsTableBody = () => {
        if (earnings.length > 0) {
            return earnings.map((payroll: any) => (
                <Table.Row key={payroll.id || 0}>
                    <Table.Cell>{`${payroll.id || ''}`}</Table.Cell>
                    <Table.Cell>{`${nonCurrencyNumberFormat(payroll.base_rate || 0)}`}</Table.Cell>
                    <Table.Cell>{`${nonCurrencyNumberFormat(payroll.hours || 0)}`}</Table.Cell>
                    <Table.Cell>{numberFormat(payroll.ee_amount || 0)}</Table.Cell>
                    <Table.Cell>{payroll.pay_date || ''}</Table.Cell>
                    <Table.Cell>{payroll.date_range_from || ''}</Table.Cell>
                    <Table.Cell>{payroll.date_range_to || ''}</Table.Cell>
                </Table.Row>
            ));
        } else {
            return (
                <Table.Row>
                    <Table.Cell colSpan={7}>No records found</Table.Cell>
                </Table.Row>
            );
        }
    };

    const enableSendEmail = () => {
        const payStatementsData = payStatements?.pay_statements || [];

        if (payStatementsData.length > 0) {
            return (
                <Button onClick={() => sendCSV()} inverted color="orange">
                    Send Mail
                </Button>
            );
        }
    }

    const direction = order ? 'sort down' : 'sort up';
    return (
        <Template activeLink='payStatements'>
            {
                isLoading
                && <Loading />
            }

            <div className="ui card">
                {props.user && (props.user.role && props.user.role.indexOf('ukg') >= 0) ?
                    <div className="content pb0">
                        <h2 className="float-left mr10"> Pay Statements List<HelpMark pageId="97" appIsDown={{ status: issuesState.appIsDown, appsDown: ["Admin-UKG-PayStatements"] }} />
                        </h2>
                        <div className="topFilters flex items-end flex-wrap">
                            <Input
                                icon={<Icon name="search" />}
                                placeholder="Employee ID..."
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <Button
                                primary={true}
                                onClick={onClickSearch}
                            >
                                Search
                            </Button>
                        </div>
                    </div>
                    : ""}
            </div>

            <div className="ui card">
                {props.user && (props.user.role && props.user.role.indexOf('ukg') >= 0) ?
                    <div className="content">
                        <Table className="table table-striped table-hover my-0 FreezeTable sortable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'id' ? 'active' : ''}`}
                                        onClick={() => getSortRows('id', order)} 
                                    >
                                        <div>
                                            ID
                                            <Icon className={orderBy === 'id' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'base_rate' ? 'active' : ''}`}
                                        onClick={() => getSortRows('base_rate', order)} 
                                    >
                                        <div>
                                            Base Rate
                                            <Icon className={orderBy === 'base_rate' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'hours' ? 'active' : ''}`}
                                        onClick={() => getSortRows('hours', order)} 
                                    >
                                        <div>
                                            Hours
                                            <Icon className={orderBy === 'hours' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'ee_amount' ? 'active' : ''}`}
                                        onClick={() => getSortRows('ee_amount', order)} 
                                    >
                                        <div>
                                            EE Amount(Gross)
                                            <Icon className={orderBy === 'ee_amount' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'pay_date' ? 'active' : ''}`}
                                        onClick={() => getSortRows('pay_date', order)} 
                                    >
                                        <div>
                                            Pay Date
                                            <Icon className={orderBy === 'pay_date' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'date_range_from' ? 'active' : ''}`}
                                        onClick={() => getSortRows('date_range_from', order)} 
                                    >
                                        <div>
                                            Period Start Date
                                            <Icon className={orderBy === 'date_range_from' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'date_range_to' ? 'active' : ''}`}
                                        onClick={() => getSortRows('date_range_to', order)} 
                                    >
                                        <div>
                                            Period End Date
                                            <Icon className={orderBy === 'date_range_to' ? direction : 'sort'}/>
                                        </div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    getPayrollDetailsTableBody()
                                }
                            </Table.Body>
                        </Table>
                        {enableSendEmail()}
                    </div>
                    : ""}
            </div>
        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user
    };
}

export default connect(mapStateToProps)(Render);