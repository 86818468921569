import React, {useEffect, useState} from "react";
import Template from "../Template";
import {RootState} from "../../model";
import {connect} from "react-redux";
import {Button, Card, Header, Icon, Modal, Table} from "semantic-ui-react";
import {useActions} from "../../redux/actions";
import * as lambdasActions from "../../redux/actions/lambdas"
import CreateLambdasForm from "./CreateLambda";
import ViewLogs from "./ViewLogs";


type EditCreateLambdaFormModalProps = {
    saveForm: any;
    item: any;
    action: any;
    content: any;
    closeForm: any;
};
const EditCreateLambdaFormModal = (props: EditCreateLambdaFormModalProps) => {
    return (
        <Modal
            open={true}
            onClose={props.closeForm}
            centered={true}
            size='tiny'
        >
            <Header content={props.content}/>
            <Modal.Content>
                <CreateLambdasForm
                    action={props.action}
                    saveForm={props.saveForm}
                    closeForm={props.closeForm}
                    item={props.item}
                />
            </Modal.Content>
        </Modal>
    )
}

const LambdaList = (props: any) => {
    const lambdasAction = useActions(lambdasActions);
    const [state, setState] = useState({
        lambdasList: [],
        awsLambdasList: [],
        createForm: false,
        editForm: false,
        editItem: {}
    })
    const [reRun, setReRun] = useState(true);
    const [listAll, setListAll] = useState(false);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const result: any = await lambdasAction.fetchLambdasList();
        const awsLamdas: any = await lambdasAction.fetchLambdasListFromAWS();
        setState({
            ...state,
            lambdasList: result,
            awsLambdasList: awsLamdas,
            createForm: false,
            editForm: false,
        });
    }
    const {
        createForm,
        editForm,
        editItem
    } = state

    let displayData: any = [];

    const closeForm = () => {
        if (editForm) {
            setState({...state, editForm: false})
        }
        if (createForm) {
            setState({...state, createForm: false})
        }
    }

    const submitForm = async (payload: any, action: any) => {
        await lambdasAction.saveForm(payload, action);
        await loadData();
    }

    const invokeFunction = async (name: string) => {
        await lambdasAction.invoke({name});
        await loadData();
    }

    const isAdmin = props.user.role.includes("admin")
    let totalLambdas = 0;

    if (reRun) {
        totalLambdas = state.lambdasList.length;
        displayData = state.lambdasList;
    }
    if (listAll) {
        totalLambdas = state.awsLambdasList.length
        displayData = state.awsLambdasList;
    }
    console.log('data',state.lambdasList)

    return (
        <Template activeLink="lambda-list">
            <Card>
                <Card.Content>
                    <h2>
                        List of Lambdas ({totalLambdas})
                        <div className="float-right ml15">
                            <Button
                                className={`${reRun ? 'active' : ''} btn-rerun-lambda`}
                                onClick={() => {
                                    setReRun(!reRun)
                                    setListAll(!listAll)
                                }}
                            >
                                Re-run
                            </Button>
                            <span className={'or-seperator'}>OR</span>
                            <Button
                                className={`${listAll ? 'active' : ''} btn-list-all-lambdas`}
                                onClick={() => {
                                    setReRun(!reRun)
                                    setListAll(!listAll)
                                }}
                            >
                                List All
                            </Button>
                        </div>
                        {
                            isAdmin &&
                            <Button
                                className="float-right"
                                primary={true}
                                onClick={() => setState({...state, createForm: true})}
                            >
                                Create New
                            </Button>
                        }

                    </h2>
                </Card.Content>
            </Card>
            {createForm && (<EditCreateLambdaFormModal
                content="Create Lambda"
                action={createForm ? "createForm" : ""}
                saveForm={submitForm}
                closeForm={closeForm}
                item={{}}
            />)}
            {editForm && (<EditCreateLambdaFormModal
                content="Edit Lambda"
                action={editForm ? "editForm" : ""}
                saveForm={submitForm}
                closeForm={closeForm}
                item={editItem}
            />)}
            <div className="ui card">
                <div className="content pb0 mb10" style={{maxHeight: "75vh", overflowY: "auto"}}>
                    <Table className={'table table-striped table-hover my-0 FreezeTable'}>
                        <Table.Header>
                            <Table.Row>
                                {listAll && <Table.HeaderCell content="No."/>}
                                <Table.HeaderCell content="Name"/>
                                <Table.HeaderCell content="Description"/>
                                {reRun &&<Table.HeaderCell content="Status"/>}
                                {reRun && <Table.HeaderCell content="Options"/>}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                displayData.length > 0 ?
                                    displayData.map((data: any, key: any) =>
                                        <Table.Row key={'data_' + key}>
                                            {listAll && <Table.Cell width={1}>{key + 1}</Table.Cell>}
                                            {
                                                reRun && <Table.Cell>{data.name}</Table.Cell>
                                            }
                                            {
                                                listAll && <Table.Cell>{data.FunctionName}</Table.Cell>
                                            }
                                            {
                                                reRun && <Table.Cell>{data.description}</Table.Cell>
                                            }
                                            {
                                                listAll && <Table.Cell>{data.Description}</Table.Cell>
                                            }
                                            {
                                                reRun &&
                                                <Table.Cell>
                                                    <b>{!data.status ? "Inactive" : data.status == -1 ? 'Running' : 'Active'}</b>
                                                </Table.Cell>
                                            }
                                            {
                                                reRun && <Table.Cell>
                                                    {
                                                        isAdmin &&
                                                        <Button
                                                            onClick={() => setState({
                                                                ...state,
                                                                editForm: true,
                                                                editItem: {...data}
                                                            })}
                                                        >
                                                            <Icon name="edit"/>
                                                            Edit
                                                        </Button>
                                                    }
                                                    <ViewLogs item={data}/>
                                                    {
                                                        isAdmin &&
                                                        <Button
                                                            onClick={() => invokeFunction(data.name)}
                                                            primary={true}
                                                            disabled={data.status == -1}
                                                        >
                                                            <Icon
                                                                loading={data.status == -1}
                                                                name={data.status == -1 ? 'spinner' : 'play'}
                                                            />
                                                            {data.status == -1 ? '  Running' : '  Run Function'}
                                                        </Button>
                                                    }

                                                    {data.status == -1 && <i className="ml-2"><small>Check again later. Lambda Function is running in the background</small></i>}
                                                </Table.Cell>
                                            }
                                        </Table.Row>
                                    ) :
                                    <Table.Row>
                                        <Table.Cell colSpan={5} className="text-center" content="No Data"/>
                                    </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user
    };
}

export default connect(mapStateToProps)(LambdaList);
