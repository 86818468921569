import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';

import DynamicHtmlTableMail from '../../components/DynamicHtmlTableMail';
import { exportPcrCSV } from '../../components/ExportCsv';
import FilterSection from '../../components/FilterSection';
import ReportMain from '../../components/ReportMain';
import { sendPCREmail } from '../../components/sendEmail';
import Template from '../../components/Template';
import { reportInitialState, reportPageNames } from '../../constants';
import { RootState } from '../../model';
import { ApplyFilter, OnChangeFilter } from '../../model/common';
import { ReportProps, SendEmail } from '../../model/report';
import { useActions } from '../../redux/actions';
import * as appActions from '../../redux/actions/app';
import * as reportActions from '../../redux/actions/report';
import { getFilteredData } from './Filters/Common';
import RenderHtml from './RenderHtml';

function ByBrandRollUp(props: ReportProps) {
	const reportAction = useActions(reportActions);
	const appAction = useActions(appActions);
	const [state, setStates] = useState(reportInitialState);

	const data = state.data, page = reportPageNames.byBrandRollUp;

	const getPraticesRecords = async() => {
		const propVal = !!props.report.reportNewData ? props.report.reportNewData : props.reportTN.reportTimeAndName[0];
		if(propVal) {
			setStates(state => {
				const [,data] = getFilteredData(state.filters, props, page, 'byPcrBrandRollUp');
				return { ...state, data};
			});
		}
		return await reportAction.filterByValue(reportInitialState.filters);
	};

	useEffect(() => { getPraticesRecords()},  [props]);

	const onChangeFilter:OnChangeFilter = async (filters, callApi=false) => {
		applyFilter(filters);
	};

	const applyFilter:ApplyFilter = (filters) => {
		const [selectedPractices, data] = getFilteredData(filters, props, page);
		appAction.UpdatePracticesOptions(selectedPractices)
		setStates({...state, filters, data, selectedPractices});
	};

	const sendEmail:SendEmail = (dataArray) => {
		if(data && data.tableData && Object.keys(data.tableData).length) {
			const pdf = Object.keys(data.tableData).map( key => (
				ReactDOMServer.renderToString(
					<DynamicHtmlTableMail
						key={key}
						className={`by-splashboard ${key}`}
						tableHead={data ? data.tableHead : []}
						heading={data ? data.tableData[key].heading : ""}
						tableData={data ? data.tableData[key].tableData : []}
					/>)
			));
			reportAction.sendReportData({ filters: state.filters, page: page, data: dataArray, pdf: pdf.join("") });
		}
	};

	return (
		<Template activeLink={`pcr-${page}`}>
			<ReportMain>
				<FilterSection page={page} heading="PCR By Brand Roll Up" filters={state.filters} onChangeFilter={onChangeFilter} exportCSV={()=>exportPcrCSV(data, page)} sendEmail={()=>sendPCREmail(data, page, sendEmail)} />
				<RenderHtml data={data} page={page} />
			</ReportMain>
		</Template>
	);

}


function mapStateToProps(state: RootState) {
	return {
		report: state.report,
		practice: state.practice,
		reportTN: state.reportTimeAndName
	};
}

export default connect(mapStateToProps)(ByBrandRollUp);
