import React, { useState, useEffect } from 'react'
import momentTz from 'moment-timezone';
import moment from 'moment';
import { formatMinutes } from './Common';
interface PMDTimerPros {
  targetTimeMilliseconds: any
}

const PMDTimer: React.FC<PMDTimerPros> = (props) => {
  const [timeRemaining, setTimeRemaining] = useState('');

  const countdownToTime = (timeMilliseconds: number) => {
    const countdownTimer = setTimeout(() => {
      const twoHoursLater = moment(timeMilliseconds).add(2,'hours');      

      const timeZone = 'America/Chicago';
      const currentMomentInTimeZone = momentTz().tz(timeZone);
      const currentMoment = moment(currentMomentInTimeZone.valueOf());

      const valueOf1 = currentMoment.valueOf();
      const valueOf2 = twoHoursLater.valueOf();

      const diffInMilliseconds = valueOf2 - valueOf1;
      const diffDuration = moment.duration(diffInMilliseconds);

      const hours = Math.floor(diffDuration.asHours());
      const minutes = Math.floor(diffDuration.asMinutes()) % 60;
      const seconds = Math.floor(diffDuration.asSeconds()) % 60;

      setTimeRemaining(` ${hours}h ${minutes}m ${seconds} s`);
      
      if (diffInMilliseconds <= 0) {
        clearTimeout(countdownTimer);
        setTimeRemaining(` 0s`);
        return;
      }
    }, 1000);
  }

  useEffect(() => {
    props.targetTimeMilliseconds&&countdownToTime(props.targetTimeMilliseconds);
  }, [timeRemaining,props.targetTimeMilliseconds])

  return <>
    {timeRemaining}
  </>
}

export default PMDTimer