import React, {useEffect, useState} from 'react';
import Template from "./Template";
import * as patientValidationActions from "../redux/actions/patients";
import * as practiceActions from "../redux/actions/practice";
import DynamicHtmlTable from "./DynamicHtmlTable";
import {useActions} from "../redux/actions";
import moment from "moment";
import {RootState} from "../model";
import {connect} from "react-redux";
import {Button, Dropdown, Icon, Input, Modal} from "semantic-ui-react";
import {aptStatusOptions, brands, enabledCarrierList} from "../constants";
import ErrorReportModel from "./ErrorReportModel";
import MDHMconfirmation from "./MDHMconfirmation";
import _ from "lodash";
import {DropDownOptions, Regions, UdaPracticeInfo} from "../model/practice";
import * as userSetupActions from "../redux/actions/userSettings";
import {SetupFields} from "../enums/userSetupField";
import {SetupAppName} from "../enums/userSetupAppName";
import HelpMark from "./HelpMark";


const filterMoment = moment();
const filterformat = 'YYYY-MM-DD';

function PatientValidationErrorReport(props: any) {
    const patientValidationAction = useActions(patientValidationActions);
    const practiceAction = useActions(practiceActions);
    const userSetup = useActions(userSetupActions);
    const initialState: any = {
        filters: {
            locations: [],
            date: filterMoment.format(filterformat),
            insurance: [],
            keyword: '',
            practices: [],
            aptStatus: 'all',
            region: [],
            brands: []
        },
        TableData: [[{title: 'No Records Found'}]],
        TableHead: [{title: ''}],
        column: -1,
        direction: 'sort'
    };
    const [state, setStates] = useState(initialState);
    const [pdfState, pdfSetState] = useState({pdfModal: false, pdfLinks: '', pdfLinksNotFound: false, pdfActions: ''});
    const [showErrorModal, setShowErrorModal] = useState({isShow: false, patientData: []})
    const [mdhmModal, mdhmModalSet] = useState({type: '', show: false, patNum: null, practice: '', date: ''});
    const insuranceCategoryOptions: Array<object> = [];
    enabledCarrierList && enabledCarrierList.forEach((item: any, index: number) => {
        insuranceCategoryOptions.push(
            {text: item, value: item, key: index}
        )
    })


    useEffect(() => {
        const promiseArr = new Array();
        promiseArr.push(practiceAction.fetchRegions(refineRegionsArray));
        practiceAction.fetchPracticeUda();
        if (!props.patients.carrierIdentity.length) {
            patientValidationAction.getCarrierIdentity();
        }
        if (!props.practice.practiceNames.length) {
            practiceAction.fetchPractices(refinePracticeArray);
        } else if (Object.keys(props.patients.patientValidationErrorReport).length === 0) {
            const filters = {
                locations: props.practice.practiceNames,
                date: filterMoment.format(filterformat),
                insurance: [],
                keyword: '',
                practices: ['all'],
                aptStatus: 'all',
                region: [],
                brands: []
            }
            patientValidationAction.getErrorReport(filters)
        }

        if (Object.keys(props.patients.patientValidationErrorReport).length) {
            const filters = {
                locations: state.filters.locations.length ? state.filters.locations : props.practice.practiceNames,
                date: state.filters.date,
                insurance: state.filters.insurance,
                keyword: state.filters.keyword,
                practices: state.filters.practices,
                aptStatus: state.filters.aptStatus,
                region: state.filters.region,
                brands: state.filters.brands
            }
            let userParams = {field: SetupFields.Practice, appId: SetupAppName.CDP_MY};
            userSetup.getSetup(userParams).then((defaultPractice: any) => {
                let defaultPractices = defaultPractice && defaultPractice.value ? defaultPractice.value.split(',') : ['all']
                let practices = [];
                if (!defaultPractices.includes('all')) {
                    defaultPractices.forEach((defPractice: any) => {
                        if (props.practice.practiceNamesMap[defPractice]) {
                            let practiceData: any = props.practice.practiceNamesMap[defPractice]
                            practices.push(practiceData.practice)
                        }
                    })
                } else practices = defaultPractices
                filters.practices = practices
                getPatientValidationData(filters);
            })

        }
    }, [props.patients.patientValidationErrorReport, props.practice.practiceNames]);

    const refineRegionsArray = (regionList: Regions[]) => {
        let regionListOptions: DropDownOptions[] = [{key: '0', value: 0, text: 'Select Region'}];
        if (regionList.length) {
            regionList.forEach(item => {
                regionListOptions.push({text: item.name, value: item.id, key: item.id})
            });
        }
        return regionListOptions;
    };

    const refinePracticeArray = (response: any) => {
        let practiceNames: Array<string> = [];
        let practiceInfo: any = {};
        let practiceOptions = [{key: 'none', value: 'all', text: 'All Practices', lowerName: ''}];
        if (response) {
            response.forEach((item: any) => {
                practiceOptions.push({
                    text: item.practice,
                    value: item.practice,
                    key: item.id.toString(),
                    lowerName: item.practice.toLowerCase()
                });
                practiceNames.push(item.practice);
                practiceInfo[item.practice.toLowerCase()] = item;
            })
        }
        return {practiceNames: practiceNames, practiceOptions: practiceOptions, practiceInfo: practiceInfo}
    };

    const getPatientValidationData = (filters: any) => {
        const TableHead = TableHeadData();
        const TableData = TableErrorData(filters);
        setStates({...state, TableData, TableHead, filters})
    }

    const TableHeadData = () => {
        let TableHead = new Array();
        TableHead[0] = {title: 'Practice'};
        TableHead[1] = {title: '# of Images'};
        TableHead[2] = {title: 'Unresolved Errors'};
        TableHead[3] = {title: 'Resolved Errors'};
        return TableHead;
    }

    const TableErrorData = (filters: any) => {
        const errorReport = props.patients.patientValidationErrorReport;
        const carrierList = props.patients.carrierIdentity;
        let practiceList = props.practice.practiceNames;
        if (filters.practices.length >= 1 && !filters.practices.includes('all')) {
            practiceList = filters.practices;
        }
        let selectedPractices: string[] = [];
        const tempBrandFilterPractices: any = [];
        filters.brands.forEach((brand: any) => {
            const res = props.practice.udaPracticeInfo.filter(
                (item: UdaPracticeInfo) => {
                    if (item.brand === brand) {
                        return true
                    }
                }
            )
            tempBrandFilterPractices.push(
                ...res.map((prac: any) => {
                    return prac.practiceName
                })
            )
        });

        selectedPractices = Array.from(new Set([...selectedPractices, ...tempBrandFilterPractices])).sort();
        if (selectedPractices.length > 0)
            practiceList = practiceList.filter((item: any) => selectedPractices.includes(item));
        let TableData = new Array();
        let selectedCarrierList: any = [];
        filters.insurance.forEach((value: string) => {
            let dataArr = carrierList.filter((carrier: any) => carrier.modifyCarrierName.trim() === value.trim());
            selectedCarrierList = selectedCarrierList.concat(dataArr);
        });
        let imageCount = 0, openCount = 0, resolvedCount = 0;
        let allOpenErrorPatientData: any = [];
        let allResolveErrorPatientData: any = [];
        practiceList.forEach((practice: string) => {
            const practiceErrorData = errorReport[practice];
            if (practiceErrorData == null) {
                return;
            }
            let patientData = practiceErrorData.patientData;
            if (filters.aptStatus !== 'all') {
                patientData = practiceErrorData.patientData.filter((item: any) =>
                    item.APT_Status.toLowerCase() === filters.aptStatus.toLowerCase());
            }
            let openErrorPatientData: any = [];
            let resolveErrorPatientData: any = [];
            patientData.forEach((row: any) => {
                if (row.openMDHM) {
                    let item = row.openMDHM;
                    item.SubscriberID = row.SubscriberID;
                    item.Apt_Date = row.Apt_Date;
                    item.insurance = row.CarrierName;
                    item.patientStatus = row.patientStatus;
                    openErrorPatientData.push(item);
                    allOpenErrorPatientData.push(item);
                }
                if (row.resolvedMDHM) {
                    let item = row.resolvedMDHM;
                    item.SubscriberID = row.SubscriberID;
                    item.Apt_Date = row.Apt_Date;
                    item.insurance = row.CarrierName;
                    item.patientStatus = row.patientStatus;
                    resolveErrorPatientData.push(item);
                    allResolveErrorPatientData.push(item);
                }
            });
            const open = openErrorPatientData.length;
            const resolved = resolveErrorPatientData.length;
            const openError = open
                ?
                <a onClick={() => setShowErrorModal({isShow: true, patientData: openErrorPatientData})}>{`${open}`}</a>
                : '0';
            const resolvedError = resolved
                ? <a onClick={() => setShowErrorModal({
                    isShow: true,
                    patientData: resolveErrorPatientData
                })}>{`${resolved}`}</a>
                : '0';

            let isExist;
            if (selectedCarrierList.length) {
                isExist = patientData.find((row: any) =>
                    selectedCarrierList.some((item: any) =>
                        row.CarrierName === item.carrierName || row.CarrierName === item.modifyCarrierName))
                if (isExist) {
                    TableData.push([
                        {title: practice},
                        {title: patientData.length},
                        {title: openError},
                        {title: resolvedError}
                    ])
                }
            } else {
                TableData.push([
                    {title: practice},
                    {title: patientData.length},
                    {title: openError},
                    {title: resolvedError}
                ])
            }
            imageCount = imageCount + patientData.length;
            openCount = openCount + open;
            resolvedCount = resolvedCount + resolved
        })
        const totalOpen = openCount
            ? <a onClick={() => setShowErrorModal({
                isShow: true,
                patientData: allOpenErrorPatientData
            })}>{`${openCount}`}</a>
            : '0';
        const totalResolved = resolvedCount
            ? <a onClick={() => setShowErrorModal({
                isShow: true,
                patientData: allResolveErrorPatientData
            })}>{`${resolvedCount}`}</a>
            : '0';
        TableData.push([
            {title: 'Total'},
            {title: imageCount},
            {title: totalOpen},
            {title: totalResolved}
        ])
        return TableData;
    }

    const prevDay = () => {
        const filters: any = {...state.filters};
        filters.date = moment(filters.date).subtract(1, 'month').format(filterformat);
        const dataFilter = Object.assign({}, filters);
        dataFilter.locations = props.practice.practiceNames;
        dataFilter.practices = ['all']
        dataFilter.insurance = []
        patientValidationAction.getErrorReport(dataFilter);
        setStates({...state, filters: filters})
    }

    const nextDay = () => {
        const filters: any = {...state.filters};
        filters.date = moment(filters.date).add(1, 'month').format(filterformat);
        const dataFilter = Object.assign({}, filters);
        dataFilter.locations = props.practice.practiceNames;
        dataFilter.practices = ['all']
        dataFilter.insurance = []
        patientValidationAction.getErrorReport(dataFilter);
        setStates({...state, filters: filters})
    }

    const myDate = (date: string, format: string) => {
        return moment(date).format(format);
    };

    const onChangeLocation = (e: any, element: any) => {
        const values = element.value;
        const practiceNames = props.practice.practiceNames;
        let locations = values;
        let practices = values
        if (values.length >= 0 && values.indexOf('all') === values.length - 1) {
            locations = practiceNames;
            practices = ['all'];
        } else if (values.length > 1 && values.indexOf('all') === 0) {
            values.splice(0, 1);
            locations = values;
            practices = values;
        }
        const filters = {
            locations: locations,
            practices: practices,
            insurance: state.filters.insurance,
            keyword: '',
            aptStatus: state.filters.aptStatus,
            region: state.filters.region,
            brands: state.filters.brands
        }
        getPatientValidationData(filters);
    };

    const onChangeInsurance = (e: any, element: any) => {
        const filters = {
            locations: state.filters.locations,
            practices: state.filters.practices,
            insurance: element.value,
            keyword: '',
            date: state.filters.date,
            aptStatus: state.filters.aptStatus,
            region: state.filters.region,
            brands: state.filters.brands
        }
        getPatientValidationData(filters);
    }

    const onChangeRegion = (e: any, element: any) => {
        const values: any = [];
        element.value.forEach((item: any) => {
            const info: any = props.practice.regionPractices[item];
            info && info.forEach((inf: any) => {
                if (props.practice.practiceOptions.filter((a: any) => a.lowerName == inf).length > 0)
                    values.push(props.practice.practiceOptions.filter((a: any) => a.lowerName == inf)[0].text)
            });
        });
        const filters = {
            locations: state.filters.locations,
            practices: values,
            insurance: state.filters.insurance,
            keyword: '',
            date: state.filters.date,
            aptStatus: state.filters.aptStatus,
            region: element.value,
            brands: state.filters.brands
        }
        getPatientValidationData(filters);
    }

    const onChangeBrand = (e: any, element: any) => {

        const filters = {
            locations: state.filters.locations,
            practices: state.filters.practices,
            insurance: state.filters.insurance,
            keyword: '',
            date: state.filters.date,
            aptStatus: state.filters.aptStatus,
            region: state.filters.region,
            brands: element.value
        }
        getPatientValidationData(filters);
    }

    const onChange = (e: any, data: any) => {
        setStates({...state, filters: {...state.filters, keyword: data.value.trim()}})
    }

    const brandsOptions = () => {
        const brandOptions: string[] = []
        const brandsObject: { [key: string]: string[] } = {}
        brands.forEach((brand) => {
            const res = props.practice.udaPracticeInfo.filter(
                (item: UdaPracticeInfo) => {
                    if (item.brand === brand.value) {
                        return true
                    }
                }
            ).map((item: any) => item.practiceName)
            brandsObject[brand.value] = [...res.map((i: any) => i.toLocaleLowerCase())]
        })
        if (state.filters.region) {
            const tempSelectedPracsPerRegion: { [key: string]: string[] } = {}
            state.filters.region.forEach((item: any) => {
                if (props.practice.regionPractices[item]) {
                    tempSelectedPracsPerRegion[item] =
                        props.practice.regionPractices[item] as []

                }
            })
            Object.keys(tempSelectedPracsPerRegion).forEach(region => {
                Object.keys(brandsObject).forEach(brand => {
                    const commonPracs = _.intersection(brandsObject[brand], tempSelectedPracsPerRegion[region])
                    if (commonPracs.length > 0) {
                        brandOptions.push(brand)
                    }
                })
            })
            return (brandOptions.length > 0) ? brandOptions.map(b => {
                return {
                    value: b,
                    key: b,
                    text: b
                }
            }) : brands
        }
    }

    const onSearchKeyword = () => {
        patientValidationAction.getErrorReport(state.filters)
    }

    const handleSort = (clickedColumn: number) => () => {
        let {column, TableData, direction, TableHead} = state;
        if (column !== clickedColumn) {
            column = clickedColumn;
            //TableData =  _.sortBy(TableData, o => o[clickedColumn].title);
            TableData = _.sortBy(TableData, o => {
                return o[clickedColumn].title.props ? o[clickedColumn].title.props.children[1] : o[clickedColumn].title;
            });
            direction = 'sort ascending';
            setStates({...state, TableData: TableData.slice(), column, direction});
            return;
        }
        TableData = TableData.reverse();
        direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
        setStates({...state, TableData: TableData.slice(), direction});
    };

    const handlePdfModal = () => {
        pdfSetState({...pdfState, pdfModal: false, pdfLinks: '', pdfLinksNotFound: false});
    }

    const getPDFfiles = (obj: any) => {
        const pdfModal = true;
        patientValidationAction.getPdfFile(obj).then((res: any) => {
            if (res && res.url) {
                const pdfLinks = res.url;
                pdfSetState({...pdfState, pdfModal: pdfModal, pdfLinks: pdfLinks});
            } else {
                pdfSetState({...pdfState, pdfModal: pdfModal, pdfLinksNotFound: true});
            }
        })
    }

    const onChangeAptStatus = (e: any, element: any) => {
        const filters = {...state.filters};
        filters.aptStatus = element.value
        getPatientValidationData(filters)
    }

    return (
        <Template activeLink='patient-validation-status-report'>
            <Modal
                open={pdfState.pdfModal}
                onClose={handlePdfModal}
                aria-labelledby="ModalHeader"
                centered={true}
                closeIcon={true}
                size={'large'}
            >
                <Modal.Content scrolling={true}>
                    {pdfState.pdfLinksNotFound ?
                        <h4>No files found for specific member on this day. Please click on RUN button to generate files
                            and then view it after execution. </h4>
                        :
                        <div className={'textCenter'}>
                            {pdfState.pdfActions}
                            <iframe title="PDF files" src={pdfState.pdfLinks} height={'500px'} width={'100%'}/>
                        </div>

                    }
                </Modal.Content>
            </Modal>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10"> Patient Validation Error Report <HelpMark pageId='4'/></h2>
                    <div className={'topFilters'}>
                        <Input
                            className='mr10 mb15'
                            name="keyword"
                            placeholder="PatNum or SubID"
                            onBlur={onSearchKeyword}
                            icon={{name: 'search', link: true}}
                            onChange={onChange}
                            value={state.filters.keyword}
                        />

                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="aptStatus"
                            placeholder="APT Status"
                            selection={true}
                            options={aptStatusOptions}
                            onChange={onChangeAptStatus}
                            value={state.filters.aptStatus}
                        />

                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="locations"
                            multiple={true}
                            placeholder="Practice"
                            selection={true}
                            options={props.practice.practiceOptions}
                            onChange={onChangeLocation}
                            value={state.filters.practices ? state.filters.practices : []}
                        />
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="insCategory"
                            multiple={true}
                            placeholder="Ins Category"
                            selection={true}
                            options={insuranceCategoryOptions}
                            onChange={onChangeInsurance}
                            value={state.filters.insurance.length ? state.filters.insurance : []}
                        />
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="region"
                            placeholder="Region"
                            selection={true}
                            multiple={true}
                            options={props.practice.regionOptions}
                            onChange={onChangeRegion}
                            value={state.filters.region}
                        />
                        <Dropdown
                            search={true}
                            className='mr10 mb15'
                            name="brands"
                            placeholder="Brands"
                            selection={true}
                            multiple={true}
                            options={brandsOptions()}
                            onChange={onChangeBrand}
                            value={state.filters.brands}
                        />
                    </div>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    <div className={'dateFilers'}>
                        {!state.filters.keyword &&
                            <Button onClick={prevDay} attached='left'><Icon name={'chevron left'}/></Button>}
                        {!state.filters.keyword && <Button className="mr20" onClick={nextDay} attached='right'>
                            <Icon name={'chevron right'}/>
                        </Button>}
                        {!state.filters.keyword &&
                            <h4 className="displayInline">{myDate(state.filters.date, 'MMMM-YYYY')}</h4>}
                    </div>
                    <div className="table-adjAcknowledgement mt10">
                        <DynamicHtmlTable
                            key={0}
                            className={'adjustment'}
                            tableHead={state.TableHead}
                            heading={""}
                            sortedColumn={state.column}
                            onHeadClick={handleSort}
                            direction={state.direction}
                            tableData={state.TableData}
                            excludeSorting={[]}
                        />
                    </div>
                </div>
            </div>

            {showErrorModal.isShow && <ErrorReportModel
                open={showErrorModal.isShow}
                patientData={showErrorModal.patientData}
                handleClose={() => setShowErrorModal({isShow: false, patientData: []})}
                showMDHM={(obj: any) => mdhmModalSet(obj)}
                showPDFFile={(obj: any) => getPDFfiles(obj)}
            />}

            {mdhmModal.show && <MDHMconfirmation
                mdhmModal={mdhmModal}
                mdhmModalSetData={mdhmModalSet}
                isPTValidation={false}
            />}

        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        practice: state.practice,
        patients: state.patients,
    };
}

export default connect(mapStateToProps)(PatientValidationErrorReport)