import React, { useEffect, useState } from "react";
import { useActions } from "../../redux/actions";
import * as Actions from "../../redux/actions/users";
import Template from ".././Template";
import { Button, Dimmer, Dropdown, Icon, Input, Loader, Pagination, Popup, Table } from "semantic-ui-react";
import * as practiceActions from "../../redux/actions/practice";

import EditUser from "./EditUser";
import { headers, permissionsOptions } from "./constants";
import { omitPractices } from "../../constants";
import Loading from "../../Loading";

const maxRows = 12;

const UserManagement = () => {
    const initialState = {
        users: [] as any[],
        filteredUsers: [] as any[],
        practices: { options: [], all: [] },
        activePage: 1,
        loader: false,
        view: false,
        user: {} as any,
        permissions: [] as any[],
        udrRoles: [] as any[],
        syncLoading: false,
        orderBy: '',
        order: true
    }
    const [state, setState] = useState(initialState);
    const usersActions = useActions(Actions);
    const practiceAction = useActions(practiceActions);


    useEffect(() => {
        const loadData = async () => {
            setState({ ...state, loader: true });
            const result = await Promise.all([await usersActions.getUsers(), await practiceAction.getPracticesList()]);
            const users: any[] = result[0];
            result[1] = result[1].filter((res: any) => {
                if (!omitPractices.includes(res.practice))
                    return res
            });
            let practiceOptions = result[1].map((res: any) => {
                return { text: res.practice, value: res.id.toString(), key: res.practiceabbr }
            });
            practiceOptions.unshift({ text: "All", value: "All", key: "All" });

            setState({ ...state, loader: false, practices: { all: result[1], options: practiceOptions }, users: users, filteredUsers: users });
        }
        loadData();
    }, []);

    const refresh = async () => {
        const users = await usersActions.getUsers();
        setState({ ...state, view: false, users: users, filteredUsers: users })
    }

    const search = (keyword: string) => {
        const { users } = state;
        const data = (keyword && keyword.length) ? users.filter(user => user.username?.toLowerCase().includes(keyword) || user.firstname?.toLowerCase().includes(keyword) ||
            user.lastname?.toLowerCase().includes(keyword) || user.email?.toLowerCase().includes(keyword)) : users;
        setState({ ...state, filteredUsers: data });
    }

    const getPaginated = () => {
        const { filteredUsers, activePage } = state;
        const start = (activePage - 1) * maxRows;
        const end = start + maxRows;
        return filteredUsers.slice(start, end);
    };

    const onPermissionSelect = (selected: any) => {
        const data = selected.length === 0 ? state.users : state.users.filter(user => selected.some((perms: string) => user.permissions.split(',').includes(perms)));
        setState({ ...state, filteredUsers: data });
    }

    const onPracticeSelect = (selected: any) => {
        const data = selected.length === 0 ? state.users : state.users.filter(user => selected.some((prac: string) => user.practices.split(';').includes(prac)));
        setState({ ...state, filteredUsers: data });
    }

    const deleteUser = (user:any) => {
           usersActions.deleteUser({ user: user });
        refresh();
    }

    const syncUkgs = async () => {
        setState({...state, syncLoading: true});
        await usersActions.syncUkgs();
        setState({...state, syncLoading: false});
    }


    const getSortRows = async (sortColumn: string, order: boolean) => {
        let { filteredUsers } = state;
        order ? filteredUsers.sort((a, b) => a[sortColumn].localeCompare(b[sortColumn])) : filteredUsers.sort((a, b) => b[sortColumn].localeCompare(a[sortColumn]));
        setState({ ...state, order: !order, orderBy: sortColumn, filteredUsers: filteredUsers });
    };

    const getTable = () => {
        const { filteredUsers, activePage, orderBy, order,practices } = state;
        const direction = order ? 'sort down' : 'sort up';
        return <>
            <div className="FreezeTable">
                <Table className="ui table table-striped table-hover celled unstackable no-wrap">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            {headers.map((header, index) => <Table.HeaderCell
                                style={{ color: "#d16f26" }}
                                className={`cursorPointer ${orderBy === header.label ? 'active' : ''}`}
                                key={`${header.name}_`}
                                onClick={() => getSortRows(header.name, order)}
                            >
                                <div>{header.label} <Icon className={orderBy === header.label ? direction : 'sort'} /></div>
                            </Table.HeaderCell>)
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {getPaginated().map((user: any, key: number) =>
                            <Table.Row style={{ cursor: 'pointer' }} key={key}>
                                <Table.Cell>
                                    <Button.Group compact size="mini" >
                                        <Popup
                                            trigger={
                                                <Button color="red" basic icon>
                                                    <Icon name='trash alternate outline' />
                                                </Button>
                                            }
                                            content={<Button size="mini" onClick={() => deleteUser(user)} compact color='green' content='Confirm delete' />}
                                            on='click'
                                        />
                                        <Button primary icon onClick={() => viewUser(true, user)} >
                                            <Icon name='edit outline' />
                                        </Button>
                                    </Button.Group>
                                </Table.Cell>
                                <Table.Cell>{user.username} {user.ukgId && <b>({user.ukgId})</b> || ''}</Table.Cell>
                                <Table.Cell>{user.admin}</Table.Cell>
                                <Table.Cell>{user.firstname} {user.lastname}</Table.Cell>
                                <Table.Cell className="ellipsis">{user.email}</Table.Cell>
                                <Table.Cell className="ellipsis">{user.permissions}</Table.Cell>
                                <Table.Cell className="ellipsis">{
                                    practices.options.filter((practice:any) => user.defaultLocationId?.split(';').some((locationId:any) => locationId === practice.value)).map((a:any)=>a.text)
                                    }</Table.Cell>
                                <Table.Cell>{user.manager}</Table.Cell>
                                <Table.Cell>{user.regionalmanager}</Table.Cell>
                                <Table.Cell>{user.specialmanager}</Table.Cell>
                                <Table.Cell>{user.queue}</Table.Cell>
                                <Table.Cell>{user.statuses?.split()}</Table.Cell>
                                <Table.Cell>{user.types?.split()}</Table.Cell>
                                <Table.Cell>{user.lastLogin}</Table.Cell>
                            </Table.Row >
                        )}
                    </Table.Body>
                </Table>
            </div>

            <Pagination onPageChange={(_e, page: any) => setState({ ...state, activePage: page.activePage })}
                activePage={activePage} totalPages={Math.ceil(filteredUsers.length / maxRows)} />
        </>
    }

    const viewUser = (view: boolean, user?: any) => {
        setState({ ...state, view: view, user: user })
    }

    return (
        <Template activeLink="users">
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">User Management</h2>
                    <div className="topFilters">
                        <Input className="mr10" icon='search' placeholder='Search users...' onChange={(e, data) => search(data.value)} />
                        <Dropdown className="mr10" placeholder='Filter by Permissions' multiple selection search
                            onChange={(_e, data) => onPermissionSelect(data.value)}
                            options={permissionsOptions} />
                        <Dropdown className="mr10" placeholder='Select practice(s)' multiple selection search
                            onChange={(_e, data) => onPracticeSelect(data.value)}
                            options={state.practices.options} />
                    </div>
                </div>
            </div>

            {getTable()}
            <br/>
            <Button primary={true} content="Sync UKGs" loading={state.syncLoading} icon="sync" labelPosition="left" onClick={() => syncUkgs()}/>

            {state.view && <EditUser
                onClose={(view: boolean) => viewUser(view)}
                onSave={() => refresh()}
                view={state.view}
                user={state.user}
                practices={state.practices}

            />}

            {state.loader && <Dimmer active inverted>
                <Loading />
            </Dimmer>}

        </Template>
    );
};


export default UserManagement
