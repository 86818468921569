const toCurrency = (value: number) => `$${(+(value || 0)).toFixed(2)}`;

export const HEADERS = [
    {
        name: 'Practice Id',
        value: 'practiceId'
    },
    {
        name: "Practice",
        value: "practice"
    },
    {
        name: "Date",
        value: "date",
        input: "date",
        edit:true
    },
    {
        name: "First Visit Count",
        value: "fv_pt_count",
        edit:true,
        type:'count'
    },
    {
        name: "Recare Count",
        value: "rc_pt_count",
        edit:true,
        type:'count'
    },
    {
        name: "Treatment Count",
        value: "tx_pt_count",
        edit:true,
        type:'count'
    },
    {
        name: "Total Count",
        value: "ttl_pt_count",
        edit:true,
    },
    {
        name: "First Visit Care",
        value: "fv_care",
        edit:true,
        type:'care'
    },
    {
        name: "Recare",
        value: "rc_care",
        edit:true,
        type:'care',
        formatter: toCurrency
    },
    {
        name: "Treatment Care",
        value: "tx_care",
        edit:true,
        type:'care',
        formatter: toCurrency
    },
    {
        name: "Total Care",
        value: "ttl_care",
        edit:true,
        formatter: toCurrency
    }
]
