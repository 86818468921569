import React, {useState} from "react";
import DataForm, { DataFormFieldType } from './formInputs'
import {Modal} from "semantic-ui-react";
import { SitesProps } from "../model/awsHostedSites";


const EditWebsites: React.FC<SitesProps> = (props: SitesProps) => {
    const { initialValue , onCancel, practices, onSaveData } = props
    const [showError, setShowError] = useState(false)

    const typeOptions = [
        {
            key: 0,
            value: 'Wordpress',
            text: 'Wordpress',
        },
        {
            key: 1,
            value: 'HTML',
            text: 'HTML',
        }
    ]

    const wordfenceOptions = [
        {
            key: 0,
            value: true,
            text: 'Installed',
        },
        {
            key: 1,
            value: false,
            text: 'Not Installed',
        }
    ]

    function showErrorModal(){
        return (
            <Modal
                open={true}
                className='modalCenter'
                closeIcon={true}
                onClose={() => setShowError(false)}
            >
                <Modal.Content>
                    <p className="text-danger">Please Select a Practice</p>
                </Modal.Content>
            </Modal>
        )
    }
    return (
        <>
            {
                showError && showErrorModal()
            }
            <DataForm
                initialValues={initialValue && initialValue}
                fieldGroups={[
                    {
                        fields: [
                            {label: 'Practice', name: 'practice', type: DataFormFieldType.DropDown, options: practices, multiple: true, required: true},
                            {label: 'TYPE', name: 'type', type: DataFormFieldType.DropDown, options: typeOptions, required: true},
                            {label: 'WordFence Installed?', name: 'wordFenceInstalled', type: DataFormFieldType.DropDown, options: wordfenceOptions, required: true},

                        ]
                    },
                    {
                        fields: [
                            {label: 'Live Site (Optional)', name: 'liveSite', type: DataFormFieldType.Text},
                            {label: 'Who\'s Managing Content', name: 'contentManager', type: DataFormFieldType.Text, required: true},
                        ]
                    },
                    {
                        fields: [
                            {label: 'Who\'s Managing Website', name: 'siteManager', type: DataFormFieldType.Text, required: true},
                            {label: 'Who\'s Hosting Live', name: 'liveHosting', type: DataFormFieldType.Text, required: true},
                            {label: 'Live\'s IP Address', name: 'liveIpAddress', type: DataFormFieldType.Text, required: true},
                        ]
                    },
                    {
                        fields: [
                            {label: 'Beta Website', name: 'betaSite', type: DataFormFieldType.Text, required: true},
                            {label: 'Who\'s Hosting Beta', name: 'betaHosting', type: DataFormFieldType.Text, required: true},
                            {label: 'Beta\'s IP Address', name: 'betaIpAddress', type: DataFormFieldType.Text, required: true},
                        ]
                    },
                    {
                        fields: [
                            {label: 'Roadmap', name: 'roadMap', type: DataFormFieldType.TextArea, required: false},
                        ]
                    }
                ]}
                onSubmit={async (values:any) => {
                    if (values.hasOwnProperty('practice')) {
                        await onSaveData(values)
                        onCancel();
                    }
                }}
            />
        </>
    )
}
export default EditWebsites