import moment from 'moment'
import React from 'react'
import {
    TableRow,
    TableHeaderCell,
    TableHeader,
    TableCell,
    TableBody,
    Table,
    Label,
} from 'semantic-ui-react'

interface props {
    records: any
}
const CronsHealthTable = (props: props) => {
    return (
        <>
            <Table striped compact='very'>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell>Last run date</TableHeaderCell>
                        <TableHeaderCell>Message</TableHeaderCell>
                        <TableHeaderCell></TableHeaderCell>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {
                        props.records.map((logs: any, index: number) =>
                            <TableRow key={index}>
                                <TableCell>{logs.title}</TableCell>
                                <TableCell>{moment(logs.createdAt).format('ll')}</TableCell>
                                <TableCell>{logs.message}</TableCell>
                                <TableCell width={3}>{logs.status === 1 ?
                                    <Label style={{width:'100%'}} size='massive' color='green' horizontal></Label> :
                                    <Label style={{width:'100%'}} size='massive' color='red' horizontal></Label>}
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </>

    )
}

export default CronsHealthTable