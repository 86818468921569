import React, {useEffect, useState} from "react";
import {Button, Dimmer, Loader, Modal} from "semantic-ui-react";
import AddWebsites from "../../../../components/AddWebsites";
import {SitesProps} from "../../../../model/awsHostedSites";
import * as practiceActions from "../../../../redux/actions/practice";
import {useActions} from "../../../../redux/actions";

const formProps: SitesProps = {
    onSaveData: Function,
    practices: [],
    initialValue: {},
    onCancel: () => { void(0) }
}
function AddSiteModal(props: any) {
    const [state, setState] = useState({
        practices: [],
        options: [],
        loading: false
    })
    const practiceAction = useActions(practiceActions)
    const setPractices = (res: any) => {
        let practiceList: any = [{key: 0, text: 'CORP', value: 'CORP'}];
        setState({...state, loading: false})
        setState({...state, practices: res})

        console.log(res)

        if (res && res.length) {
            res.forEach((item: any) => {
                practiceList.push({
                    key: item.id,
                    value: item.practice,
                    text: item.practice
                })
            })
            setState({...state, options: practiceList})
        }
    }

    useEffect(() => {
        setState({...state, loading: true})
        practiceAction.fetchWebsitesPractices(setPractices, '')
    }, [])


    return(
        <>
            {
                (Boolean(state.practices.length && state.loading)) &&
                <Dimmer className={'aws-dimmer'} active inverted>
                    <Loader inverted>Loading</Loader>
                </Dimmer>
            }

            <Modal className='modalCenter aws-modal' size="large" onClose={() => props.showAddSitesModalHandler()} open={true}>
                <Modal.Header>
                    <div className={'poppins-black'}>Create New Website Entry</div>
                    <div className="close_modal_link">
                        <a href="javascript:void(0)" onClick={() => props.showAddSitesModalHandler()}>
                            X
                        </a>
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <AddWebsites
                        practices={state.options}
                        initialValue={formProps.initialValue}
                        onCancel={() => props.showAddSitesModalHandler()}
                        onSaveData={props.onSubmitHandler}
                    />
                </Modal.Content>
            </Modal>
        </>

    )
}

export default AddSiteModal