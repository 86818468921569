import { ActionType } from "../../model/actionTypes";
import {WriteOffList} from "../../model/writeOffList";
import {errorHandler} from "./auth";


export const saveWriteOffCodes = (obj: WriteOffList) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("write-off-codes/create", obj);
        dispatch({ type: ActionType.CREATE_WRITE_OFF_LIST_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const updateWriteOffCodes = (obj:WriteOffList) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("write-off-codes/update", obj);
        dispatch({ type: ActionType.UPDATE_WRITE_OFF_LIST_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const deleteWriteOffCodes = (obj: WriteOffList) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("write-off-codes/delete", obj);
        dispatch({ type: ActionType.DELETE_WRITE_OFF_LIST_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const getWriteOffCodes = (id:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        id=id||'all';
        const resp =  await api.get("write-off-codes/"+id);
        dispatch({ type: ActionType.FETCH_WRITE_OFF_LIST_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
