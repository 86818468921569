import React, {  useState, useEffect } from "react";
import {
  Grid,
  Modal,
  Header,
  Table,
  Button,
  Icon
} from "semantic-ui-react";
import Template from "./Template";
import { RootState } from "../model";
import { connect } from "react-redux";
import * as NotificationsActions from "../redux/actions/sporeNotifications";
import { useActions } from "../redux/actions";
import { NotificationTableResult } from "../model/notifications";
import Form from "./Form";
import * as PracticeActions from "../redux/actions/practice";
import HelpMark from "./HelpMark";

type EditCreateCampgainModalProps = {
  saveForm: any;
  item: any;
  dropDownOptions: any;
  cancel:any;
  action:any;
  closeForm:any;
  content:any
};
const EditCreateCampgainModal=(props:EditCreateCampgainModalProps)=>{
  return (
    <Modal
        open={true}
        onClose={props.closeForm}
        centered={true}
        size='tiny'
            >
              <Header content={props.content}/>
              <Modal.Content>
                <Form
                action={props.action}
                saveForm={props.saveForm}
                closeForm={props.closeForm}
                item={props.item}
                cancel={props.cancel}
                dropDownOptions={props.dropDownOptions}
                />
              </Modal.Content>
            </Modal>
  )
}
interface TableNotificationsProps {
  notifications: NotificationTableResult[];
  pageStates: {
    createForm: false;
    editForm: false;
  }
}

type notificationScema={
  createdAt: string
id?: string
is_deleted: boolean
practice: string
om: number
rm: number
others: string
status: boolean
updatedAt: string
mgrLastName: string
regMgrLastName: string
mgrEmail: string
regMgrEmail: string
}
const TableNotifications = (props: TableNotificationsProps) => {
  const [editForm, setEditForm] = useState(false);
  const [createForm, setCreate] = useState(false);
  const [practiceDropDown, setPracticeDropDown] = useState([]);
  const sporeNotifications = useActions(NotificationsActions);
  const practiceActions = useActions(PracticeActions);
  const [notificationEdited, setEditNotification] = useState<notificationScema>();
  const style = {
    width: "50%",
    padding: 10,
  };
  useEffect(() => {
    let id = { payload: 0, all: "all" };
    sporeNotifications.fetchTableResult(id);
    practiceActions.fetchPractices(refinePracticeArray)
      .then((practiceOptions: any) => {
        setPracticeDropDown(practiceOptions);
      });
  }, []);

  const refinePracticeArray = (response: any) => {
    let practiceOptions = [{key: 0, value: 0, text: 'Select Practice'}];
    if (response) {
      response.forEach((item: any) => {
        practiceOptions.push({text: item.practice, value: item.practice, key: item.id.toString()});
      })
    }
    return practiceOptions;
  };


  const closeForm = (action: any) => {
    if (action === "editForm") {
      setEditForm(false);
    } else if (action === "createForm") {
      setCreate(false);
    }
  };

  return (
    <>
      <Template activeLink="SPORE NOTIFICATIONS">
        <Grid>
          <Grid.Row>
            <div className="ui card">
              <div className="content pb0">
                <h2 className="float-left mr10 mb10">SPORE NOTIFICATIONS<HelpMark pageId="50"/></h2>
                <Button primary color="blue" onClick={() => setCreate(true)}>
              Create New
            </Button>
              </div>
            </div>

          </Grid.Row>
          <Grid.Row>
          {createForm && (<EditCreateCampgainModal 
            content="Campaign Creation"
             action={createForm ? "createForm" : ""}
             saveForm={sporeNotifications.saveForm}
             closeForm={closeForm}
             item={{}}
             cancel={setCreate}
             dropDownOptions={practiceDropDown}
          />)}
            {editForm && (<EditCreateCampgainModal 
                content="Edit"
                action={editForm ? "editForm" : ""}
                saveForm={sporeNotifications.saveForm}
                item={notificationEdited}
                cancel={setEditForm}
                closeForm={closeForm}
                dropDownOptions={practiceDropDown}
            />)}
            <Table singleLine className="ui celled table unstackable">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ color: "#d16f26" }}>
                    ACTIVE/INACTIVE
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ color: "#d16f26" }}>
                    PRACTICE
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ color: "#d16f26" }}>
                    Practice Manager
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ color: "#d16f26" }}>
                    Regional Manager
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ color: "#d16f26" }}>
                    Dist Manager
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ color: "#d16f26" }}>
                    OTHERS
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ color: "#d16f26" }}>
                    EDIT
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ color: "#d16f26" }}>
                    DELETE
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {props.notifications &&
                  props.notifications.map((result,index) => (
                    <Table.Row key={result.id+`${index} ${Math.random()}`}>
                      <Table.Cell>
                        <input
                          type="checkbox"
                          readOnly={true}
                          checked={result.status}
                          value={result.status ? 0 : 1}
                        />
                      </Table.Cell>
                      <Table.Cell>{result.practice}</Table.Cell>
                      <Table.Cell>

                        {result.om > 0 ? <Icon color='green' name={'checkmark'}/> : <Icon color='red' name={'close'}/>}
                      </Table.Cell>
                      <Table.Cell>
                        {result.rm > 0 ? <Icon color='green' name={'checkmark'}/> : <Icon color='red' name={'close'}/>}
                      </Table.Cell>
                      <Table.Cell>
                        {result.distMgr > 0 ? <Icon color='green' name={'checkmark'}/> : <Icon color='red' name={'close'}/> }
                      </Table.Cell>
                      <Table.Cell>{result.others}</Table.Cell>
                      <Table.Cell>
                        <Button
                          color="blue"
                          size="small"
                          onClick={() => {
                            setEditNotification(result);
                            setEditForm(!editForm);
                          }}
                        >
                          Edit
                        </Button>
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          color="blue"
                          size="small"
                          onClick={() => {
                            sporeNotifications.deleteRecord(result.id);
                          }}
                        >
                          Delete
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </Template>
    </>
  );
};

function mapStateToProps(state: RootState) {
  return {
    notifications: state.sporeNotification.notifications,
    pageStates: state.sporeNotification.pageStates,
  };
}

export default connect(mapStateToProps)(TableNotifications);
