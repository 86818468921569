import React, {useEffect, useState} from "react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as ReportActions from "../redux/actions/report"
import {useActions} from "../redux/actions";
import {Button, Grid, Input, Table} from "semantic-ui-react";
import HelpMark from "./HelpMark";


interface rampThresholdDefaultsProps {
    rampThresholdDefaults: any
}

const initialState: any = {
    loading: false,
    rampThresholdDefaults: [],
    cronStatus: 'All',
    isEdit: false,
    initialValues: {},
    orderBy: null,
    order: true
}

const RampThresholdDefaults = (props: rampThresholdDefaultsProps) => {
    const [state, setStates] = useState(initialState);
    const reportActions = useActions(ReportActions);


    useEffect(() => {
        reportActions.getRampThresholdDefaults()
            .then((response: any) => {
                setStates({...state, rampThresholdDefaults: JSON.parse(response.reduced_practice_threshold)});
            });
    }, []);

    const handleOptions = (value: any, key: React.ReactText) => {
        let {rampThresholdDefaults} = state;
        rampThresholdDefaults[key] = value;
        setStates({...state, rampThresholdDefaults});
    };

    const toggleEdit = () => {
        const {isEdit} = state;
        const newValue = !isEdit
        setStates({...state, isEdit: newValue})
    }

    const save = () => {
        const data = {
            reduced_practice_threshold: JSON.stringify(state.rampThresholdDefaults),
        }
        reportActions.updateRampThresholdDefaults(data)
    }


    const {isEdit} = state;
    let table: JSX.Element[] = []

    if (state && state.rampThresholdDefaults) {
        const jsonObject = state.rampThresholdDefaults

        if (Object.keys(jsonObject)) {
            Object.keys(jsonObject).forEach((threshold, index) => {
                const value = jsonObject[threshold]
                table.push(
                    <Table.Row key={'fgf' + index}>
                        <Table.Cell>
                            {threshold}
                        </Table.Cell>
                        <Table.Cell>
                            <div className="form-group">
                                <Input
                                    type="text"
                                    name={'threshold' + index}
                                    value={value}
                                    disabled={!isEdit}
                                    onChange={(e) => handleOptions(e.target.value, index)}
                                />
                            </div>
                        </Table.Cell>
                    </Table.Row>
                )
            })
        }
    }

    return (
        <Template activeLink="ramp-threshold-defaults">
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">Ramp Threshold Defaults <HelpMark pageId='81'/></h2>
                    <div className="topFilters">
                        {!isEdit ?
                            <Button primary={true} onClick={() => toggleEdit()}>{'Edit'}</Button> :
                            <span>
                                    <Button secondary={true} onClick={() => toggleEdit()}>{'Cancel'}</Button>
                                    <Button primary={true} onClick={() => save()}>{'Save'}</Button>
                                </span>
                        }
                    </div>
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    <div className="FreezeTable">
                        <Table className="ui table table-striped celled table-hover unstackable">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>
                                        <div>Patient Visits</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Ramp Threshold</div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {table}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        rampThresholdDefaults: state.report.rampThresholdDefaults,
    };
}

export default connect(mapStateToProps)(RampThresholdDefaults);
