import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Template from "./Template";
import {User} from "../model/user";
import {RootState} from "../model";
import {Practice} from "../model/practice";
import {useActions} from "../redux/actions";
import * as spotActions from "../redux/actions/spot";
import * as practiceActions from "../redux/actions/practice";
import {Button, Dropdown, Input, Pagination, Table} from "semantic-ui-react";
import {calculateAge, convertToCSV, setPractices} from "../utils/common";
import * as issuesActions from "../redux/actions/downTimeTracker";
import HelpMark from "./HelpMark";
import moment from "moment/moment";

interface Props {
    auth: User,
    practice: Practice,
    spotPatients : any
}

var appIsDown: boolean = false;
var myCDPApplicationList: any[] = ['SPOT-Patients'];

function SpotPatients(props: Props) {
    const spotAction = useActions(spotActions);
    const practiceAction = useActions(practiceActions);
    const issuesAction = useActions(issuesActions);
    const initialFilters: any = {
        page: 1,
        pageSize: 100,
        paginate: true,
        practice: '',
        procCodes : '',
    };
    const initialState: any = {
        practiceOpts: [],
    };
    const [loader, setLoader] = useState(false);
    const [filters, setFilters] = useState(initialFilters);
    const [state, setState] = useState(initialState);
    useEffect(() => {
        checkIssues();
        practiceAction.fetchUdaPractices().then((practices : any) => {
        const practiceOpts = setPractices(practices)
            setState({...state, practiceOpts: practiceOpts})
        })
        //loadData();
        
    }, []);

    const checkIssues = async() => {
        var result = await issuesAction.fetchOpenIssuesList(myCDPApplicationList);
        appIsDown = result.length > 0 ? true :false;
	}

    const loadData = async (filters: any) => {
        if(!filters.practice){
            alert('Please select any practice to continue.')
            return true;
        }
        setLoader(true)
        await spotAction.getSpotPatients(filters)
        setLoader(false)
    }

    const handleSearch = () => {
        
        const filtersToUse = JSON.parse(JSON.stringify(filters));
        filtersToUse.page = 1;

        setFilters({...filters, page: 1});

        loadData(filtersToUse);
    }

    const onClickPage = (page: any) => {
        
        const filtersToUse = JSON.parse(JSON.stringify(filters));
        filtersToUse.page = page.activePage;

        setFilters({...filters, page: page.activePage});

        loadData(filtersToUse);
    }

    const getTableData = () => {
        const { spotPatients } = props;

        const tableRows: any = [];
        const tableHeader = [
            'Practice', 'PatNum', 'Patient FName', 'Patient LName', 'Age', 'Email', 'Proc Codes',
            'Proc Date', 'Last Visit', 'Next Appt', 'Home Phone', 'Work Phone', 'Wireless Phone'
        ];

        if(!spotPatients.rows || !spotPatients.rows.length){

            tableRows.push(
                <Table.Row className={'mt20'} key={0}>
                    <Table.Cell colSpan={10} className={'text-center'}> <b>Please select your search criteria to filter the records!</b></Table.Cell>
                </Table.Row>
            );

        } else {
            
            spotPatients.rows.map((item: any, key: number) => {
                const age = item.BirthDate ? calculateAge(item.BirthDate) : '';
                tableRows.push(
                    <Table.Row key={item.PatNum}>
                        <Table.Cell>{filters.practice.toUpperCase()}</Table.Cell>
                        <Table.Cell>{item.PatNum}</Table.Cell>
                        <Table.Cell>{item.FName}</Table.Cell>
                        <Table.Cell>{item.LName}</Table.Cell>
                        <Table.Cell>{age}</Table.Cell>
                        <Table.Cell>{item.Email}</Table.Cell>
                        <Table.Cell>{item.proccode}</Table.Cell>
                        <Table.Cell>{moment(item.ProcDate).format('YYYY-MM-DD')}</Table.Cell>
                        <Table.Cell>{moment(item.LastVisit).format('YYYY-MM-DD')}</Table.Cell>
                        <Table.Cell>{item.Next_Apt ? moment(item.Next_Apt).format('YYYY-MM-DD') : ''}</Table.Cell>
                        <Table.Cell>{item.HmPhone}</Table.Cell>
                        <Table.Cell>{item.WkPhone}</Table.Cell>
                        <Table.Cell>{item.WirelessPhone}</Table.Cell>
                    </Table.Row>
                )
            });
        }

        return <>
            <div className="FreezeTable">
                <Table className="ui table table-striped table-hover celled unstackable no-wrap">
                    <Table.Header>
                        <Table.Row>
                            {tableHeader.map((header, index) => <Table.HeaderCell
                               style={{ color: "#d16f26" }}
                               key={`${header}_`} 
                               >
                                    <div>{header}</div>
                               </Table.HeaderCell>)
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {tableRows}
                    </Table.Body>
                </Table>
            </div>

            <Pagination
                onPageChange={(_e, page: any) => onClickPage(page)}
                activePage={filters.page}
                totalPages={Math.ceil(props.spotPatients.count/filters.pageSize)}
            />
        </>;
    }

    const handleDownload = async () => {

        const filtersToUse = JSON.parse(JSON.stringify(filters));
        filtersToUse.paginate = false;

        const result = await spotAction.getSpotPatients(filtersToUse);
        const data : any = [];

        result.rows && result.rows.forEach((item: any) => {
            const age = item.BirthDate ? calculateAge(item.BirthDate) : '';
            data.push({
                Practice : filters.practice.toUpperCase(),
                PatNum : item.PatNum,
                FName : item.FName,
                LName : item.LName,
                Age : age,
                Email : item.Email,
                procCode : item.proccode,
                procDate : moment(item.ProcDate).format('YYYY-MM-DD'),
                LastVisit : moment(item.LastVisit).format('YYYY-MM-DD'),
                Next_Apt : item.Next_Apt ? moment(item.Next_Apt).format('YYYY-MM-DD'):'',
                HmPhone : item.HmPhone,
                WkPhone : item.WkPhone,
                WirelessPhone : item.WirelessPhone
            })
        })
        const csvContent = convertToCSV(data);
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'spotPatients.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    return (
        <Template activeLink='usage-spot'>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">SPOT Patients ({props.spotPatients && props.spotPatients.count})<HelpMark pageId='0' appIsDown={{status:appIsDown, appsDown:myCDPApplicationList}} /></h2>

                    <div className="topFilters">
                        {props.spotPatients.rows &&
                            props.spotPatients.rows.length ?
                            <Button primary={true} onClick={() => handleDownload()}>Download</Button> : ''}
                        <Dropdown
                            placeholder='Select Practice'
                            options={state.practiceOpts}
                            selection={true}
                            search={true}
                            value={filters.practice}
                            onChange={(e, values) => setFilters({...filters, practice : values.value})}
                            name="practice"
                        />

                        <Input
                            placeholder='Proc Codes with comma'
                            defaultValue={filters.procCodes}
                            onBlur={(e: any) => setFilters({...filters, procCodes: e.target.value && e.target.value.toUpperCase()})}
                        />

                        <Button primary={true} onClick={() => handleSearch()}>Search</Button>

                    </div>
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    {!loader && getTableData()}
                </div>
            </div>

        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
        practice: state.practice,
        spotPatients : state.spot.spotPatients
    };
}

export default connect(mapStateToProps)(SpotPatients)