import { Report } from "../../model/report";
import { App } from "../../model";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";
const initialState:any = {
	oral : [],
};
export const budget = createReducer(initialState, {
	[ActionType.LOAD_ORAL_BUDGET](state: any, action: Action<any>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.LOAD_PCR_BUDGET](state: any, action: Action<any>) {
		return Object.assign({...state}, action.payload);
	}

});
