import moment from "moment";

export const getMonthsOption = () => {
  const months = [{ key: 0, value: '', text: 'Select Month' }];
  for(let i = 0; i < 4; i++){
    const value = moment().subtract(i, "month").startOf("month").format('YYYY-MM-DD');
    const monthName =  moment().subtract(i, "month").startOf("month").format('MMMM');
    months.push({ key: moment(value).month()+1, value: value, text: `${monthName}-${moment(value).year()}`});
  }

  months.sort((a,b) => {
    const dateA:Date = new Date(a.value), dateB:Date = new Date(b.value);
    return dateA > dateB ? 1 : -1;
  });
  return months;
};

/**
 * Get Report Name 
 * @param type 
 */
export const getTypeOfReport = (type: string) => {
  let value: string = '';
  switch (type) {
    case "pcr-by-dr-practice":
      value = 'byPracticeReport'
      break;
    
    case "pcr-splashboard":
      value = 'bySplashBoardReport'
      break;

    case "pcr-age":
      value = 'byAgeReport'
      break;

    case "pcr-age-mix":
      value = 'byAgeMixReport'
      break;

    case "pcr-provider-cpv":
      value = 'byProviderCPVReport'
      break;

    case "pcr-insurance":
      value = 'byPCRInsuranceReport'
      break;

    case "pcr-insurance-mix":
      value = 'byPCRInsuranceMixReport'
      break;

    case "pcr-outlook":
      value = 'byOutlookReport'
      break;

    case "pcr-cpv":
      value = 'byPcrCpvReport'
      break;

    case "pcr-by-region-roll-up":
      value = 'byPcrRegionRollUp'
      break;
    
    case "pcr-goal-by-workday": 
      value = 'byBudgetByWorkingReport'
      break;

    case "pcr-by-brand-roll-up":
      value = 'byPcrBrandRollUp'
      break;
  }
  return value;
};

export const formatMinutes=(minutes: number): string => {
  const oneDayInMinutes = 24 * 60;
  const oneHourInMinutes = 60;

  const days = Math.floor(minutes / oneDayInMinutes);
  const hours = Math.floor((minutes % oneDayInMinutes) / oneHourInMinutes);
  const remainingMinutes = (minutes % oneHourInMinutes).toFixed(0);

  let result = '';
  if (days > 0) {
    result += `${days} day${days === 1 ? '' : 's'}, `;
  }
  if (hours > 0) {
    result += `${hours} hour${hours === 1 ? '' : 's'}, `;
  }
  result += `${remainingMinutes} minute${remainingMinutes === '1' ? '' : 's'}`;

  return result;
}
