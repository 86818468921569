import React, {useEffect, useState} from 'react';
import Template from "../../components/Template";
import {Button, Dropdown, Grid, Icon, Popup, Table} from "semantic-ui-react";

import {RootState} from "../../model";
import {User} from "../../model/user";
import {connect} from "react-redux";
import ErrorModal from "./ErrorModal";
import AddSiteModal from "./AddSiteModal";
import RoadmapModal from './RoadmapModal';
import SingleRoadmapModal from './SingleRoadmapModal';
import DeleteSiteModal from "./DeleteSiteModal";
import EditSiteModal from "./EditSiteModal";
import ShowLighthouseScores from "./ShowLighthouseScores";
import {SitesDetails} from "../../model/awsHostedSites";
import * as sitesActions from "../../redux/actions/awsHostedSites";
import * as awsHostedSitesActions from "../../redux/actions/awsHostedSites";
import * as practiceActions from "../../redux/actions/practice";
import {useActions} from "../../redux/actions";
interface Props {
    auth: User
}

const initState: any = {
    lighthouseResults: [],
    actualData: [],
    status: {},
    totalSites: 0,
    notScannedToday: 0,
}
function AWSWebsites(props: Props) {
    const awsHostedSitesAction = useActions(awsHostedSitesActions);
    const practiceAction = useActions(practiceActions)
    const sitesAction = useActions(sitesActions)

    const [practices, setPractices] = useState([])
    const [state, setState] = useState(initState);
    const [showError, setShowError] = useState(false);
    const [showLighthouseScores, setShowLighthouseScores] = useState(false);
    const [showAddSites, setShowAddSites] = useState(false);
    const [showRoadMapModal, setShowRoadMapModal] = useState(false);
    const [showIndividualRoadMapModal, setShowIndividualRoadMapModal] = useState(false);
    const [scanErrorMessage, setScanErrorMessage] = useState('');
    const [errorSiteTitle, setErrorSiteTitle] = useState('');
    const [showDeletePopupModal, setShowDeletePopupModal] = useState(false);
    const [showEditPopupModal, setShowEditPopupModal] = useState(false);
    const [selectedSiteID, setSelectedSiteID] = useState(null)
    const [selectedSiteData, setSelectedSiteData] = useState({})

    useEffect(() => {
        awsHostedSitesAction.getHostedSites().then((hostedSites: any) => {
            const removedDuplicates = hostedSites.reduce((accumulator: any, current: any) => {
                if (!accumulator.find((item: any) => item.practice === current.practice)){
                    accumulator.push(current)
                }
                return accumulator;
            }, [])
            awsHostedSitesAction.getLighthouseScores().then((lighthouseResults: any) => {
                setState({...state,
                    actualData: removedDuplicates,
                    lighthouseResults: lighthouseResults,
                    totalSites: removedDuplicates.length,
                })
            })
        })
        practiceAction.fetchWebsitesPractices(setPracticesList, '')
    }, []);

    const setPracticesList = (res: any) => {
        let practiceList: any = [{id: 0, practice: 'CORP', brand: 'CDP'}];
        if (Object.keys(res).length) {
            res.forEach((item: any) => {
                practiceList.push({
                    id: item.id,
                    practice: item.practice,
                    brand: item.brand
                })
            })
            setPractices(practiceList)
        }
    }

    const showErrorModal = (error: string, siteTitle: string) => {
        setScanErrorMessage(error);
        setErrorSiteTitle(siteTitle);
        setShowError(!showError);
    }
    const showAddSitesModal = () => {
        setShowAddSites(!showAddSites);
    }
    const toggleRoadMapModal = () => {
        setShowRoadMapModal(!showRoadMapModal);
    }

    const toggleIndividualRoadmapModal = (payload: any) => {
        setSelectedSiteData(payload);
        setShowIndividualRoadMapModal(!showIndividualRoadMapModal);
    }

    const showLighthouseScoresModal = (payload: any) => {
        setSelectedSiteData(payload)
        setShowLighthouseScores(!showLighthouseScores);
    }

    const showDeleteSitesModal = (id: any) => {
        setSelectedSiteID(id)
        setShowDeletePopupModal(!showDeletePopupModal);
    }
    const showEditSiteModal = (payload: any) => {
        setSelectedSiteData(payload)
        setShowEditPopupModal(!showEditPopupModal);
    }

    const deleteSiteHandler = () => {
        awsHostedSitesAction.deleteSite(selectedSiteID).then((res: any) => {
            awsHostedSitesAction.getHostedSites().then((results: any) => {
                const removedDuplicates = results.reduce((accumulator: any, current: any) => {
                    if (!accumulator.find((item: any) => item.practice === current.practice)){
                        accumulator.push(current)
                    }
                    return accumulator;
                }, [])
                setState({...state,
                    actualData: removedDuplicates,
                    lighthouseResults: removedDuplicates,
                    totalSites: removedDuplicates.length,
                })
                setShowDeletePopupModal(!showDeletePopupModal)
            })
        })
    }

    const calculatePercentageScore = (score: any) => {
        if (!score || score <= 0) {
            return 'Error';
        }
        return Math.round(score  * 100)
    }

    const displayScoreInformation = (score: any, siteUrl: string, errorMsg: string) => {
        const num = score;

        if (num <= 0) {
            score = 'Error'
        }

        if (score !== 'Error') {
            return score
        }
        return(<>
            <a
                className="cursorPointer link primaryUnderline red m0"
                onClick={() => showErrorModal(errorMsg, siteUrl)}
                style={{margin: '0'}}
            >
                {score}
            </a>
        </>)
    }
    const tableData = () => {
        let { actualData } = state;
        let rows: React.ReactElement[] = [];
        let rowCount = 0;
        function scoreClass(score: any) {
            if (score < 50 || score === 'Error') return 'red';
            if(score >= 50 && score < 90) return 'orange'
            if(score >= 90 && score <= 100) return 'green'
        }

        if(actualData) {
            for (let i = 0; i < actualData.length; i++) {
                rowCount++
                rows.push (
                    <Table.Row key={i}>
                        <Table.Cell width={1} >{getBrand(actualData[i].practice.split(',')[0])}</Table.Cell>
                        <Table.Cell>
                            <Popup
                                className='cursorPointer'
                                content={actualData[i].practice}
                                key={actualData[i].id + '-popup'}
                                header={'Practices'}
                                trigger={
                                    <b>{actualData[i].practice.split(',')[0]}</b>}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <a className='cursorPointer' target='_blank' href={"https://" + actualData[i].liveSite}>{actualData[i].liveSite}</a>
                        </Table.Cell>
                        <Table.Cell> 
                                    <input type='checkbox' checked />
                        </Table.Cell>
                        <Table.Cell>
                            <Icon onClick={() => showEditSiteModal(actualData[i])} className='mr10' name='edit' />
                            <Icon onClick={() => showDeleteSitesModal(actualData[i].id)} className='mr10' name='delete' />

                            <span className="icon-text">
                                <Icon onClick={() => showLighthouseScoresModal(actualData[i])} className='mr20 cursorPointer' name='eye' />
                                View
                            </span>

                            <span className="icon-text">
                                <Icon onClick={() => toggleIndividualRoadmapModal(actualData[i])} className='mr20 cursorPointer' name='chart line' />
                                View Roadmap 
                            </span>
                        </Table.Cell>
                    </Table.Row>
                )
            }
        }
        else {
            rows.push(
                <Table.Row key={1}>
                    <Table.Cell >No Data</Table.Cell>
                </Table.Row>
            )
        }
        return rows;
    }

    const showScanResultsDetails = () => {
        let elements: React.ReactElement[] = [];
        return elements;
    }

    const getBrand = (practice: string) => {
        let brand = '';

        practice = practice?.trim();
        
        practices.forEach((prac: any) => {
            if (prac?.practice?.toLocaleLowerCase() === practice?.toLocaleLowerCase()) {
                brand = prac.brand
            }
        })

        return brand || ''
    }

    const onSubmit = (values: SitesDetails) => {
        setShowEditPopupModal(false)
        sitesAction.saveSite(values).then((res: any) => {
            awsHostedSitesAction.getHostedSites().then((results: any) => {
                const removedDuplicates = results.reduce((accumulator: any, current: any) => {
                    if (!accumulator.find((item: any) => item.practice === current.practice)){
                        accumulator.push(current)
                    }
                    return accumulator;
                }, [])
                setState({...state,
                    actualData: removedDuplicates,
                    lighthouseResults: removedDuplicates,
                    totalSites: removedDuplicates.length,
                })
            })
        })
    }

    return (
        <Template activeLink='aws websites'>
            {showLighthouseScores && <ShowLighthouseScores lighthouseResults={state.lighthouseResults} selectedSiteData={selectedSiteData} showLighthouseScoresModalHandler={showLighthouseScoresModal} />}
            {showError && <ErrorModal showErrorModalHandler={showErrorModal} error={scanErrorMessage} siteTitle={errorSiteTitle}/>}
            {showAddSites && <AddSiteModal onSubmitHandler={onSubmit} showAddSitesModalHandler={showAddSitesModal} />}
            {showDeletePopupModal && <DeleteSiteModal id={selectedSiteID} deleteSiteHandler={deleteSiteHandler} showDeleteSitesModalHandler={showDeleteSitesModal} />}
            {showEditPopupModal && <EditSiteModal data={selectedSiteData} onSubmitHandler={onSubmit} showEditSiteModalHandler={showEditSiteModal} />}
            {showRoadMapModal && <RoadmapModal onRoadmapModalClose={toggleRoadMapModal} />}
            {showIndividualRoadMapModal && <SingleRoadmapModal selectedSiteData={selectedSiteData} onIndividualRoadmapModalClose={toggleIndividualRoadmapModal} />}
            <Grid.Row>
                <div className="ui card">
                    <div className="content pb0 mb10">
                        <h2 className="float-left mr10">List of AWS Hosted Sites</h2>
                        <div className="topFilters">
                            <Button primary={false} onClick={toggleRoadMapModal}>View Sites Roadmap</Button>
                            <Button primary={true} onClick={showAddSitesModal}>New Website</Button>
                        </div>
                    </div>
                </div>
            </Grid.Row>

            <Grid.Row>
                <div className='content pb0 mb20 flex'>{showScanResultsDetails()}</div>
            </Grid.Row>

            <Grid.Row>
                <div className="ui card">
                    <div className="content pb0 mb10" style={{maxHeight: "75vh", overflowY: "auto"}}>
                        <Table singleLine className="ui celled table unstackable cronListTable table-striped">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={1}>
                                        Brand
                                    </Table.HeaderCell>
                                    <Table.HeaderCell >
                                        Practice
                                    </Table.HeaderCell>
                                    <Table.HeaderCell >
                                        Live Website
                                    </Table.HeaderCell>
                                    <Table.HeaderCell >
                                        Status
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={4}>
                                        Action
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    tableData()
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </Grid.Row>
        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(AWSWebsites)