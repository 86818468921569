import React, { SyntheticEvent, useEffect, useState } from 'react';
import _ from "lodash"
import Template from '../components/Template';
import { RootState } from "../model";
import { connect } from 'react-redux';
import { Button, Dropdown, DropdownItemProps, Icon, Input, Dimmer, Loader, Pagination, Form } from "semantic-ui-react";
import * as actions from "../redux/actions/mdm";
import { useActions } from "../redux/actions";
import { MDMHeader } from "../constants";
import { MDM, MDM_Options } from "../model/mdm";
import PopUpModal from "../components/PopUpModal";
import AddInfo from "../components/AddInfo";
import HelpMark from "../components/HelpMark";
import { getPager } from "../redux/actions/spot";
import Loading from '../Loading';

interface Props {
  mdmInfo: any,
  dataSourceOptions: [],
  reportOptions: [],
  pages: [],
  dataSource: [],
  reports: []
}

interface resProps {
  count: number
  rows: any
}

function MDMInfo(props: Props) {
  const limit = 20;
  const initialState = {
    mdmInfo: [],
    pageOption: [{ key: 0, text: 'Select Page', value: 0 }],
    report: 0,
    page: 0,
    search: null,
    order: true,
    orderBy: null
  }
  const initialFormState: MDM = {
    field: '', hexCode: '', description: '', formula: '', code: '', dataSource: '0', report: '0', page: '0', fileLink: '', short_desc: ''
  };
  const [formState, setFormStates] = useState({ showForm: false, id: 0, pagesDropdown: [{ text: 'Select Page', value: 0, key: 0 }] });
  const [initialValue, setFormInitialStates] = useState(initialFormState);
  const [state, setState] = useState(initialState);
  const [deletePopUpState, setDeletePopUpState] = useState({ showPopUp: false, id: 0 });
  const [infoPopUpState, setInfoPopUpState] = useState({ showInfoPopUp: false, info: { header: '', message: '' }, action: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [paginationState, setPagination] = useState({
    pager: {} as any
  });
  const [values, updateData] = useState({ page: 1, limit, offset: 0 });
  const mdmActions = useActions(actions);
  const [editDataSourcePageReportState, setEditDataSourcePageReportState] = useState({
    dataSourcePageReport: { id: 0, name: "", reportId: 0 }
  });

  const getMDMinfo = () => {
    mdmActions.fetchMDMInfo(values).then((result: resProps) => {
      if (!!result && !!result.rows && result.rows.length > 0) {
        setState({ ...state, mdmInfo: result.rows })
        const pager = getPager(result.count, +values.page, +values.limit);
        setPagination({ ...paginationState, pager });
      }
      setIsLoading(false);
    });
  }

  const getSearchMDMinfo = async (predicate:any) => {
    await mdmActions.fetchSearchMDMInfo(predicate).then((result: any) => {
      setState({ ...state, search: predicate, mdmInfo: result });
      setIsLoading(false);
    });
  }

  useEffect(() => {
    mdmActions.fetchMDMDataSource(refineDataSourceArray);
    mdmActions.fetchMDMReports(refineReportArray);
    mdmActions.fetchMDMPages(refinePageArray);
  }, []);

  useEffect(() => {
    getMDMinfo();
  }, [values])

  const refinePageArray = (response: MDM_Options[]) => {
    let pages: Array<any> = [];
    let pageOptions: any = [{ text: 'Select Page', value: 0, key: 0 }];
    if (response) {
      response.forEach((item) => {
        pageOptions.push({ text: item.name, value: item.id, key: item.id });
        pages.push(item);
      })
    }
    setState({ ...state, pageOption: pageOptions });
    return { pages: pages, pageOptions: pageOptions }
  };

  const refineReportArray = (response: MDM_Options[]) => {
    let reports: Array<any> = [];
    let reportOptions: DropdownItemProps = [];
    if (response) {
      response.forEach((item) => {
        reportOptions.push({ text: item.name, value: item.id, key: item.id });
        reports.push(item);
      })
    }
    return { reports: reports, reportOptions: reportOptions }
  };

  const refineDataSourceArray = (response: MDM_Options[]) => {
    let dataSource: Array<any> = [];
    let dataSourceOptions: DropdownItemProps = [];
    if (response) {
      response.forEach((item) => {
        dataSourceOptions.push({ text: item.name, value: item.id, key: item.id });
        dataSource.push(item);
      })
    }
    return { dataSource: dataSource, dataSourceOptions: dataSourceOptions }
  };

  const onCancel = () => {
    setFormStates({ showForm: false, id: 0, pagesDropdown: [{ text: 'Select Page', value: 0, key: 0 }] });
    setFormInitialStates(initialFormState);
  };

  const getTableHeadings = () => {
    const { order, orderBy } = state;
    const direction: string = order ? 'up' : 'down';
    let returnArray: React.ReactElement[] = [];

    if (MDMHeader.length) {
      MDMHeader.forEach((item: { header: string, sortBy: string | null }, key: number) => {
        if (item.sortBy) {
          let sortIcon = <Icon name='sort' color='orange' />;
          if (orderBy === item.sortBy) {
            if (direction === 'up') {
              sortIcon = <Icon name='sort up' color='orange' />
            } else {
              sortIcon = <Icon name='sort down' color='orange' />
            }
          }
          returnArray.push(
            <th
              className={`cursorPointer ${orderBy === item ? 'active' : ''}`}
              onClick={() => handleSort(`${item.sortBy}`, order)}
              key={key}
              style={{ color: '#d16f26' }}
            >
              {item.header}
              {sortIcon}
            </th>);
          return;
        }
        returnArray.push(
          <th
            key={key}
            style={{ color: '#d16f26', minWidth: '62px' }}
          >
            {item.header}
          </th>);
      })
    }
    return (<thead>
      <tr>{returnArray}</tr>
    </thead>)
  };

  const handleSort = (sortColumn: any, order: boolean) => {
    const mdmInfo: any = state.mdmInfo;
    const mdm: any = _.orderBy(mdmInfo, [sortColumn], [order ? 'asc' : 'desc'])
    setState({ ...state, mdmInfo: mdm, order: !order, orderBy: sortColumn });
  };

  const deleteInfo = () => {
    const { id } = deletePopUpState;
    mdmActions.deleteMDMInfo(id).
      then((result: any) => {
        setState({ ...state, mdmInfo: result })
      });
    setDeletePopUpState({ showPopUp: false, id: 0 });

  };

  const showInfoPopUp = (data: any, column: string) => {
    const message = data[`${column}`];
    setInfoPopUpState({ showInfoPopUp: true, info: { header: column.toUpperCase(), message: message }, action: 'read' })
  };

  const getTableRows = () => {
    const mdmInfo: any = state.mdmInfo;
    if (mdmInfo) {
      let reportRows: React.ReactElement[] = [];
      if (mdmInfo.length === 0) {
        reportRows.push(<tr>
          <td colSpan={10}>No record found</td>
        </tr>)
      }
      mdmInfo.forEach((item: any) => {
        const dataSource: any = props.dataSource.find((data: any) => parseInt(item.dataSource) === data.id);
        let dataSourceName = '';
        if (dataSource) {
          dataSourceName = dataSource.name;
        }
        const report: any = props.reports.find((data: any) => parseInt(item.report) === data.id);
        let reportName = '';
        if (report) {
          reportName = report.name;
        }
        const pages: any = props.pages.find((data: any) => parseInt(item.page) === data.id);
        let pageName = '';
        if (pages) {
          pageName = pages.name;
        }
        reportRows.push(<tr key={item.id}>
          <td>
            <Icon
              name="edit"
              className="mr10"
              onClick={() => {
                const reportId = parseInt(item.report);
                let dropDownOptions = [{ text: 'Select Page', value: 0, key: 0 }];
                const pagesDropdown = props.pages.filter((data: any) => parseInt(data.reportId) === reportId);
                if (pagesDropdown.length) {
                  pagesDropdown.forEach((options: any) => {
                    dropDownOptions.push({ text: options.name, value: parseInt(options.id), key: parseInt(options.id) })
                  })
                }
                setFormStates({ showForm: true, id: item.id, pagesDropdown: dropDownOptions });
                setFormInitialStates(item)
              }
              }
            />
            <Icon
              name="trash alternate"
              onClick={() => setDeletePopUpState({ showPopUp: true, id: item.id })}
            />
          </td>
          <td>
            {item.field.slice(0, 30)}
            {item.field.length > 30 && <a className="linkReadMore" onClick={() => showInfoPopUp(item, 'field')}> read more</a>}
          </td>
          <td>
            {item.hexCode}
          </td>
          <td>
            {item.short_desc && item.short_desc.slice(0, 30)}
            {item.short_desc && item.short_desc.length > 30 && <a className="linkReadMore" onClick={() => showInfoPopUp(item, 'short_desc')}> read more</a>}
          </td>
          <td>
            {item.formula.slice(0, 20)}
            {item.formula.length > 20 && <a className="linkReadMore" onClick={() => showInfoPopUp(item, 'formula')}> read more</a>}
          </td>
          <td>
            {item.code.slice(0, 25)}
            {item.code.length > 25 && <a className="linkReadMore" onClick={() => showInfoPopUp(item, 'code')}> read more</a>}
          </td>
          <td>
            {dataSourceName.slice(0, 25)}
            {dataSourceName.length > 25 && <a className="linkReadMore" onClick={() => showInfoPopUp(item, 'dataSource')}> read more</a>}
          </td>
          <td>
            {reportName.slice(0, 25)}
            {reportName.length > 25 && <a className="linkReadMore" onClick={() => showInfoPopUp(item, 'report')}> read more</a>}
          </td>
          <td>
            {pageName.slice(0, 25)}
            {pageName.length > 25 && <a className="linkReadMore" onClick={() => showInfoPopUp(item, 'page')}> read more</a>}
          </td>
        </tr>);
      });
      return <tbody>{reportRows}</tbody>;
    }
  };

  const saveDetails = (values: MDM) => {
    values.dataSource = values.dataSource !== '0' ? values.dataSource : '';
    values.page = values.page !== '0' ? values.page : '';
    values.report = values.report !== '0' ? values.report : '';
    if (formState.id) {
      values.id = formState.id;
      mdmActions.updateMDMInfo(values);
    } else {
      mdmActions.saveMDMInfo(values);
    }
    onCancel()
    getMDMinfo()
  };

  const onChangeFilter = (e: SyntheticEvent, element: any) => {
    const { mdmInfo, pages } = props;
    const { report } = state;
    let data: any = mdmInfo.rows;
    let pagesDropDown: any = [{ text: 'Select Page', value: 0, id: 0 }]
    if (element.name === 'report') {
      if(element.value == -1){
        setState({...state,report:element.value,mdmInfo:data})
        return;
      }
      if (element.value !== 0) {
        let pageList = pages.filter((item: any) => parseInt(item.reportId) == parseInt(element.value));
        pageList.forEach((item: any) => {
          pagesDropDown.push({ text: item.name, value: item.id, id: item.id })
        });
        data = (mdmInfo.rows).filter((item: any) => parseInt(item.report) === parseInt(element.value));
      }
      setState({ ...state, report: element.value, mdmInfo: data, page: 0, pageOption: pagesDropDown, })
    } else {
      if (report > 0) {
        data = mdmInfo.rows.filter((item: any) => parseInt(item.report) === report);
        if (element.value !== 0) {
          data = mdmInfo.rows.filter((item: any) => parseInt(item.report) === report && element.value === parseInt(item.page));
        }
      }
      setState({ ...state, mdmInfo: data, page: element.value })
    }
  };

  const handleInputChange = (e:any) => {
    const predicate = e.target.value;
    setState({ ...state, search: predicate});
  }

  const onSearchChange = async (e: any) => {   
      await getSearchMDMinfo(state.search);
  };

  const showOptionsInfo = (values: string, id: number, name: string, reportId: number) => {
    setInfoPopUpState({ showInfoPopUp: true, info: { header: values, message: '' }, action: 'add' })
    setEditDataSourcePageReportState({ dataSourcePageReport: { id, name, reportId } })
  };
  const deleteOptionsInfo = (id: number, currentField: string) => {
    const header = infoPopUpState.info.header;
    switch (currentField) {
      case 'DataSource':
        mdmActions.deleteDataSource(id);
        break;
      case 'Report':
        mdmActions.deleteReport(id);
        break;
      case 'Page':
        mdmActions.deletePage(id);
        break;
      default:
        break;
    }
  }
  const addOptionsInfo = (data: any) => {
    const header = infoPopUpState.info.header;
    switch (header) {
      case 'Add DataSource':
        mdmActions.saveDataSource(data);
        break;
      case 'Add Report':
        mdmActions.saveReports(data);
        break;
      case 'Add Page':
        mdmActions.savePages(data);
        break;
      default:
        break;
    }
    setInfoPopUpState({ showInfoPopUp: false, info: { header: '', message: '' }, action: '' })
  };

  const setPage = (pageNumber: any) => {
    updateData({ limit, page: pageNumber, offset: limit * (pageNumber - 1) });
  }
  const { pager } = paginationState;

  const getReportOptions =()=> {
    return  [{text: 'All', value: -1, key: 1},...props.reportOptions]
  }
  return (
    <>
      {isLoading && <Dimmer active={true}>
        <Loading />
      </Dimmer>}

      <Template activeLink="MDM">
        {infoPopUpState.showInfoPopUp && <PopUpModal
          headerText={infoPopUpState.info.header}
          message={infoPopUpState.info.message}
          size='small'
          showLabel={false}
          hidePopUpModal={() => setInfoPopUpState({ showInfoPopUp: false, info: { header: '', message: '' }, action: '' })}
          action={infoPopUpState.action}
          addPopupHandler={(values: any) => addOptionsInfo(values)}
          options={props.reportOptions}
          pages={props.pages}
          reports={props.reports}
          dataSource={props.dataSource}
          deleteOptionsHander={(id: number, currentField: string) => deleteOptionsInfo(id, currentField)}
          editDataSourcePageReportState={editDataSourcePageReportState.dataSourcePageReport}
        />}
        <div className="ui card">
          <div className="content pb0">
            <h2 className="float-left mr10 mb10">Master Data Management <HelpMark pageId="45" /></h2>
            <div className="topFilters">
              <Button
                className="mb15 mr10"
                primary={true}
                onClick={() => setFormStates({ showForm: true, id: 0, pagesDropdown: [] })}
              > Add
              </Button>
              <Dropdown
                search={true}
                className='mr10 mb15'
                name="report"
                placeholder="Report"
                selection={true}
                options={getReportOptions()}
                onChange={onChangeFilter}
                value={state.report}
              />
              <Dropdown
                search={true}
                className='mb15 mr10'
                name="page"
                placeholder="Page"
                selection={true}
                options={state.pageOption}
                onChange={onChangeFilter}
                value={state.page}
              />
              
              <Form onSubmit={onSearchChange}>
                <Form.Input className="mb10"
                 name="search"
                 placeholder='Search' 
                 value={state.search}
                 action="Search"
                 onChange={handleInputChange} />
              </Form>
            </div>
          </div>
        </div>

        {formState.showForm &&
          <AddInfo
            initialValue={initialValue}
            onCancel={onCancel}
            saveDetails={saveDetails}
            dataSourceOptions={props.dataSourceOptions}
            reportOptions={props.reportOptions}
            pages={props.pages}
            pageOptions={formState.pagesDropdown}
            addInfo={(value: string, id: number, name: string, reportId: number) => showOptionsInfo(value, id, name, reportId)}
            reports={props.reports}
            dataSource={props.dataSource}
            deleteDataSourcePageReportHandler={(id: number, currentField: string) => deleteOptionsInfo(id, currentField)}
          />
        }


        <div className="ui card">
          <div className="content">
            <div className="table-responsive FreezeTable">
              <table className="ui celled table unstackable">
                {getTableHeadings()}
                {getTableRows()}
              </table>
            </div>
          </div>
          <Pagination
            defaultActivePage={pager.currentPage}
            totalPages={pager.totalPages}
            activePage={pager.currentPage}
            onPageChange={(a, b) => setPage(b.activePage)}
          />
        </div>

        {deletePopUpState.showPopUp &&
          <PopUpModal
            headerText="Delete Selected Record"
            message="Are you sure you want to delete?"
            size='mini'
            action='delete'
            showLabel={true}
            hidePopUpModal={() => setDeletePopUpState({ showPopUp: false, id: 0 })}
            deletePopUpHandler={deleteInfo}
          />
        }
      </Template>
    </>
  )
}

function mapStateToProps(state: RootState) {
  return {
    mdmInfo: state.mdm.mdm_info,
    dataSourceOptions: state.mdm.dataSourceOptions,
    reportOptions: state.mdm.reportOptions,
    pages: state.mdm.pages,
    dataSource: state.mdm.dataSource,
    reports: state.mdm.reports
  };
}

export default connect(mapStateToProps)(MDMInfo);