import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const createRegion = (data: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp = await api.post(`regions/create`, data);
        dispatch({type: ActionType.CREATE_REGION_SUCCESS, payload: false});
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp
    } catch (err) {
        dispatch({type: ActionType.CREATE_REGION_FAIL, payload: false});
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const getRegions = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const results = await api.get(`regions/get`);
        dispatch({type: ActionType.GET_REGIONS_SUCCESS, payload: false});
        dispatch({ type: ActionType.LOADER, payload: false });
        return results;
    } catch (err) {
        dispatch({type: ActionType.GET_REGIONS_FAIL, payload: false});
        dispatch({ type: ActionType.LOADER, payload: false });
        dispatch(errorHandler(err))
        return [];
    }
};


export const deleteRegions = (id:number) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.delete(`regions/delete/${id}`);
        dispatch({ type: ActionType.DELETE_REGION_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.DELETE_REGION_FAIL, payload: false });
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const updateRegions = (data:object) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.put("regions/update", data);
        dispatch({ type: ActionType.UPDATE_REGION_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.UPDATE_REGION_FAIL, payload: false });
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};