import { Action, ActionType } from "../../model/actionTypes";
import { MDM_Info } from "../../model/mdm";
import createReducer from "./createReducer";


const initialState:any = {
  mdm_info: [],
  dataSource: [],
  pages: [],
  reports: [],
  dataSourceOptions: [],
  reportOptions: [],
  pageOptions: []
};

export const mdm = createReducer(initialState, {
  [ActionType.LOAD_MDM_INFO_SUCCESS](state: MDM_Info, action: Action<MDM_Info>) {
    return Object.assign({...state}, action.payload);
  },
  [ActionType.LOAD_MDM_DATASOURCE](state: MDM_Info, action: Action<MDM_Info>) {
    return Object.assign({...state}, action.payload);
  },
  [ActionType.LOAD_MDM_REPORTS](state: MDM_Info, action: Action<MDM_Info>) {
    return Object.assign({...state}, action.payload);
  },
  [ActionType.LOAD_MDM_PAGES](state: MDM_Info, action: Action<MDM_Info>) {
    return Object.assign({...state}, action.payload);
  },
  [ActionType.LOAD_MDM_HELP_REPORT_SUCCESS](state: MDM_Info, action: Action<MDM_Info>) {
    return Object.assign({...state}, action.payload);
  },
  [ActionType.FETCH_PS_BOARD_INFO](state: MDM_Info, action: Action<MDM_Info>) {
    return Object.assign({...state}, action.payload);
  },
});