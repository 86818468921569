import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RootState, sucessMsg} from "../../../model";
import {Account} from "../../../model/user";
import {Practice, PracticeList} from "../../../model/practice";
import Template from "../../../components/Template";
import * as patientCountCareActions from "../../../redux/actions/patientCountCare";
import * as practiceActions from "../../../redux/actions/practice";
import * as ReportActions from '../../../redux/actions/report';
import {useActions} from "../../../redux/actions";
import moment from "moment";
import DisplayTable from "./DisplayTable";
import FiltersAndOptions from "./FiltersAndOptions";
import {Dimmer, Loader, Message, Pagination} from "semantic-ui-react";
import {FilterOptions, PCR_KEYS} from '../../../constants';
import HelpMark from "../../../components/HelpMark";

interface Props {
    user: Account,
    practice: Practice
    message: sucessMsg
}

function Index(props: Props) {
    const {message} = props
    const patientCountCareAction = useActions(patientCountCareActions) as typeof patientCountCareActions;
    const practiceAction = useActions(practiceActions) as typeof practiceActions;
    const reportActions = useActions(ReportActions);
    const [filters, setFilters] = useState({
        date: "",
        practiceId: ""
    });
    const [defaultFilters, setDefaultFilters] = useState({
        date: "",
        practiceId: ""
    });
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    useEffect(() => {
        loadFilteredData();
        loadAllData();
    }, [filters, defaultFilters]);

    const [allPracticeData, setAllPracticeData] = useState({
        allData: [] as any[],
        allPractices: [] as PracticeList[]
    });

    const [state, setStates] = useState({
        data: [] as any[],
        allData: [] as any[],
        paginationState: {page: 1, size: 0, limit: 10} as any,
        practices: [] as PracticeList[]
    });

    const loadData = async (filterOptions: any, loadAll: boolean) => {
        const query = {
            ...filterOptions, ...{
                limit: state.paginationState.limit,
                offset: state.paginationState.limit * (state.paginationState.page - 1)
            }
        };
        const results: any = await Promise.all([
            practiceAction.getPracticesList(),
            patientCountCareAction.getBulk(query),
        ]);
        if (loadAll) {
            await setAllData(results);
        } else {
            await setData(results);
        }

    }

    const loadFilteredData = async () => {
        setLoader(true);
        await loadData(filters, false);
    }

    const loadAllData = async () => {
        setLoader2(true)
        await loadData(defaultFilters, true);
    }

    const dataByDate = (results: any) => {
        return (results[1]['rows'] as any).map((d: any) => {
            const obj = {...d};
            obj.practice = d.Practice && d.Practice.practice;
            obj.date = moment(obj.date).format('YYYY-MM-DD');
            return obj;
        }) || [];
    }

    const getPracticeOptions = (results: any) => {
        return results.map((d: any) => {
            return {practice: d.Practice.practice, id: d.practiceId}
        })
    }

    const setAllData = async (results: any) => {
        const allData: any = dataByDate(results);
        const practiceOptions: any = getPracticeOptions(allData);
        const allPractices = results[0].filter((res: any) => practiceOptions.some((item: any) => item.practice == res.practice))
        setAllPracticeData({...allPracticeData, allData, allPractices})
        setLoader2(false)
    }

    const setData = async (results: any) => {
        const data: any = dataByDate(results);
        const practiceOptions: any = getPracticeOptions(data);
        const filteredPracticeOption = results[0].filter((res: any) => practiceOptions.some((item: any) => item.practice == res.practice))
        setStates({
            ...state,
            data,
            paginationState: {
                page: state.paginationState.page,
                limit: state.paginationState.limit,
                size: results[1]['count']
            },
            practices: filteredPracticeOption || []
        });
        setLoader(false)
    }

    const saveBulk = async (data: any[]) => {
        await patientCountCareAction.saveBulk(data);
        await loadFilteredData();
    }
    const handleDateChange = (date: any) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        setFilters(date ? {...filters, date: formattedDate} : {...filters, date: ""});
    };
    const onPracticeChange = (data: any) => {
        setFilters(data != "All" ? {...filters, practiceId: data} : {...filters, practiceId: ""});
    }

    const onFilterChange = (payload: any) => {
        const {filter, filterValue} = payload;
        setStates({...state, paginationState: {page: 1, size: 0, limit: 10}})

        switch (filter) {
            case FilterOptions.DateFilter:
                handleDateChange(filterValue);
                break;
            case FilterOptions.PracticeFilter:
                onPracticeChange(filterValue);
                break;
            default:
                break;
        }
    }

    const setPage = async (page: any) => {
        const results: any = await Promise.all([
            practiceAction.getPracticesList(),
            patientCountCareAction.getBulk({
                limit: state.paginationState.limit,
                offset: ((page.activePage - 1) * state.paginationState.limit)
            })
        ]);
        state.paginationState.page = page.activePage;
        await setData(results);
    }

    const onRefreshPage = async () => {
        await reportActions.reRunPCR(PCR_KEYS);
    }

    const filterProp = {
        date: filters.date,
        data: state.data,
        allData: allPracticeData.allData,
        practices: allPracticeData.allPractices,
        save: saveBulk,
        onFilterChange: onFilterChange,
        onRefreshPCR: onRefreshPage
    };

    return (
        <Template activeLink="patient-count-care-uploader">

            {message.successMsg && <Message
                onDismiss={() => patientCountCareAction.resetMessage()}
                color='green'
                content={message.successMsg}
            />}
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left">Patient Count & Care<HelpMark pageId=''/></h2>
                    <div className="topFilters">
                        <FiltersAndOptions filterProps={filterProp}/>
                    </div>
                </div>
            </div>
            <div className="ui card">
                <div className="content">
                    <DisplayTable date={filters.date} data={state.data} allData={allPracticeData.allData}
                                  autoSave={true}/>
                    <Pagination
                        defaultActivePage={state.paginationState.page}
                        totalPages={Math.ceil(state.paginationState.size / state.paginationState.limit)}
                        activePage={state.paginationState.page}
                        onPageChange={(a, b) => setPage(b)}
                    />
                </div>
            </div>
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        message: state.message
    }
}

export default connect(mapStateToProps)(Index)
