// Add {hide: true} in object to hide any menu from menu bar
import {MainMenu} from "./constants";

export const menuptvArray: Array<MainMenu> = [
    {
    pageId: 1,
    name: "Patient Validation",
    class: "innerDropdown",
    icon: "user secret",
    title: "Patient Validation",
    partner: 'Patient Validation',
    role: [],
    subMenus: []
  },
    {
    pageId: 2,
    name: "Patient Report",
    class: "innerDropdown",
    icon: "file alternate outline",
    title: "Patient Report",
    partner: 'Patient Report',
    role: [],
    subMenus: [
      {
        pageId: 5,
        name: "Patient Status Report",
        icon: 'file alternate outline',
        title: "Patient Status Report",
        role: []
      },
      {
        pageId: 6,
        name: "Patient Action Report",
        icon: "file alternate outline",
        title: "Patient Action Report",
        role: [],
      },
      {
        pageId: 4,
        name: "Patient Error Report",
        icon: "file alternate outline",
        title: "Patient Error Report",
        role: []
      },
      ]
  },
    {
        pageId: 7,
        name: "MDHM WO Reports",
        class: "innerDropdown",
        icon: "file alternate outline",
        title: "MDHM WO Reports",
        partner: 'Patient Report',
        role: [],
        subMenus: [
            {
                pageId: 8,
                name: "MDHM WriteOff Report",
                icon: 'file alternate outline',
                title: "MDHM WO Current View",
                role: []
            },
            {
                pageId: 9,
                name: "MDHM WO $History Report",
                icon: 'file alternate outline',
                title: "MDHM WO $ History",
                role: []
            },
            {
                pageId: 10,
                name: "MDHM WO History Report",
                icon: 'file alternate outline',
                title: "MDHM WO # History",
                role: []
            },
            {
                pageId: 11,
                name: "MDHM WO $by Weekday Report",
                icon: 'file alternate outline',
                title: "MDHM WO $ by Weekday",
                role: []
            },
            {
                pageId: 12,
                name: "MDHM  Usage vs WO Report",
                icon: 'file alternate outline',
                title: "MDHM  Usage vs WO",
                role: []
            }
        ]
    },
    {
        pageId: 13,
        name: "Patient Practice Logs",
        class: "innerDropdown",
        icon: "file alternate outline",
        title: "Patient Practice Logs",
        partner: 'Patient Practice Logs',
        role: [],
        subMenus: [
            {
                pageId: 14,
                name: "Patient Validation Practice Logs Report",
                icon: 'file alternate outline',
                title: "Patient Validation Practice Logs Report",
                role: []
            }
        ],
    },
    {
        pageId: 3,
        name: "Usage PTV",
        class: "innerDropdown",
        icon: "pie chart",
        title: "Usage",
        partner: 'Usage',
        role: [],
        subMenus: []
    }
];
