import * as React from "react";
import {useState} from "react";
import HelpPageModal from "./HelpPageModal";
import helpIcon from '../styles/images/help-icon.png';
import downTimeIcon from '../styles/images/downtime-icon.svg';
import DownTimeIssuesPopUp from "./appdowntimetracker/downTimeIssuesPopUp";

interface Props {
	pageId?: string,
	appIsDown?:{status:boolean,appsDown:string[]}
}

function HelpMark(props: Props) {


	const initialState : any = {
		helpPageModal : false,
		downTimeIssuesPopUpModal : false
	}

	const [state, setStates] = useState(initialState);

	const openHelpPage = () => {
		setStates({ helpPageModal: true });
	};

	const hideHelpPageModal = () => {
		setStates({ helpPageModal: false });
	};

	const openDownTimeTrackerPopUpModal = () => {
		setStates({ downTimeIssuesPopUpModal: true });
	}

	const hideDownTimeTrackerPopUpModal = () => {
		setStates({ downTimeIssuesPopUpModal: false });
	};

	return (
		<div className="helpMark">
			{ props.pageId &&
				<span onClick={() => openHelpPage()}><img alt="help" className="cursorPointer" src={helpIcon} /></span>
			}
			{ state.helpPageModal && props.pageId &&
				<HelpPageModal hideHelpPageModal={hideHelpPageModal} pageId={props.pageId} />
			}


			{ props.appIsDown&&props.appIsDown.status &&
				<span onClick={() => openDownTimeTrackerPopUpModal()}><img alt="help" className="cursorPointer" src={downTimeIcon} /></span>
			}
			
			{ state.downTimeIssuesPopUpModal &&
				<DownTimeIssuesPopUp hideModal={hideDownTimeTrackerPopUpModal} applicationList={props.appIsDown ? props.appIsDown.appsDown: [""]} />
			}
		</div>
	);
}

export default HelpMark;
