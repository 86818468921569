import { Action, ActionType } from "../../model/actionTypes";
import { SporeNotification} from "../../model/notifications";
import createReducer from "./createReducer";


const initialState = {  
    notifications: [],
    pageStates: { createForm: false, editForm: false },
    getEditForm: null,
    omrm: [],
    user_practices:[]
  };
export const sporeNotification = createReducer(initialState, {
  [ActionType.LOAD_SPORE_NOTIFICATION_TABLE](state: SporeNotification, action: Action<SporeNotification>) {
    return Object.assign({...state}, action.payload);
  },
  [ActionType.LOAD_SPORE_NOTIFICATION_OMRM](state: SporeNotification, action: Action<SporeNotification>) {
    return Object.assign({...state}, action.payload);
  },
  [ActionType.SPORE_NOTIFICATION_SAVE_STATES](state: SporeNotification, action: Action<SporeNotification>) {
    return Object.assign({...state}, action.payload);
  },
  [ActionType.LOAD_SPORENOTIFICATION_USER_PRACTICES](state: SporeNotification, action: Action<SporeNotification>) {
    return Object.assign({...state}, action.payload);
  }
 
});