import React, {useEffect, useState} from "react";
import {Button, Dimmer, Loader, Modal} from "semantic-ui-react";
import AddWebsites from "../../components/AddWebsites";
import {SitesProps} from "../../model/awsHostedSites";
import * as practiceActions from "../../redux/actions/practice";
import {useActions} from "../../redux/actions";

const formProps: SitesProps = {
    onSaveData: Function,
    practices: [],
    initialValue: {},
    onCancel: () => { void(0) }
}
function AddSiteModal(props: any) {
    const [state, setState] = useState({
        practices: [],
        options: [],
        loading: false
    })
    const practiceAction = useActions(practiceActions)
    const setPractices = (res: any) => {
        let practiceList: any = [{key: 0, text: 'CORP', value: 'CORP'}];
        setState({...state, loading: false})
        setState({...state, practices: res})



        if (res && res.length) {
            res.forEach((item: any) => {
                practiceList.push({
                    key: item.id,
                    value: item.practice,
                    text: item.practice
                })
            })
            setState({...state, options: practiceList})
        }
    }

    useEffect(() => {
        setState({...state, loading: true})
        practiceAction.fetchWebsitesPractices(setPractices, '')
    }, [])


    return(
        <>
            <Modal className='modalCenter' size="large" onClose={() => props.showAddSitesModalHandler()} open={true}>
                {
                    state.loading &&
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                }
                <Modal.Header>
                    <b>WEBSITES</b> <a className="ui orange label">Add New</a>
                    <div className="ui top right attached label production_right_label">
                        <Button onClick={() => props.showAddSitesModalHandler()} inverted>
                            Close
                        </Button>
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <AddWebsites
                        practices={state.options}
                        initialValue={formProps.initialValue}
                        onCancel={() => props.showAddSitesModalHandler()}
                        onSaveData={props.onSubmitHandler}
                    />
                </Modal.Content>
            </Modal>
        </>

    )
}

export default AddSiteModal