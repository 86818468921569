import moment from "moment";
import { ActionType } from "../../model/actionTypes";
import {PracticeAdminList} from "../../model/practiceAdmin";
import {removeNullProperties} from "../../utils/common";
import {errorHandler} from "./auth";
export const fetchPractices = () => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp:PracticeAdminList[]  =  await api.get("practicesAdmin/getPractices");
        dispatch({ type: ActionType.FETCH_PRACTICES_ADMIN_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const updatePractice = (params: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const response = await api.post(`practicesAdmin/updatePracticeData`, params);
        dispatch({ type: ActionType.FETCH_PRACTICES_ADMIN_SUCCESS, payload: response });
        dispatch({ type: ActionType.LOADER, payload: false });
        return response;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
}

export const createPractice = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try	{
        dispatch({type: ActionType.LOADER, payload: true});
        const response = await api.post(`practicesAdmin/createPractice`, payload);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(error))
    }
}

export const saveOpenDentalConfig = (payload: any) => async (dispatch: Function, getState: Function, api: any) => {
    try	{
        dispatch({type: ActionType.LOADER, payload: true});
        const response = await api.post(`practicesAdmin/openDentalConfig`, payload);
        dispatch({type: ActionType.LOADER, payload: false});
        return response;
    } catch (error) {
        dispatch({type: ActionType.LOADER, payload: false});
        dispatch(errorHandler(error))
    }
}