import React, {useEffect, useState} from 'react';
import Template from '../../components/Template';
import {useActions} from "../../redux/actions";
import * as reportActions from "../../redux/actions/report";
import {Practice} from "../../model/practice";
import { Report} from "../../model/report";
import {App, RootState} from "../../model";
import {connect} from 'react-redux';
import {Dropdown, Icon} from "semantic-ui-react";
import {getMonthsOption} from "../../components/Common";
import NewOSReport from "../../components/NewOSReport"
import {OnChange} from "../../model/common";
import moment from "moment";
import {Button } from "semantic-ui-react";
import {exportOpenSurgeryReport,htmlOpenSurgeryReport} from "../../components/ExportCsv";

interface Props {
    report: Report,
    practice: Practice,
    app: App,
}

function OpenSurgeryPCRReport(props: Props) {

    const initialState = {
        report: {}
    };
    const queryParams :any= new URLSearchParams(window.location.search);
    const currentYear = queryParams.get("year")?queryParams.get("year"):moment().format('YYYY');
    const currentMonth = queryParams.get("month")?queryParams.get("month"):moment().month()+1;
    const reportAction = useActions(reportActions);
    const [state, setState] = useState(initialState);
    const [excel, setExcel] = useState(true);
    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);
    const [newOsReport, setNewOsReport] = useState(true);

    const OSPCRReport: {[key: string]: Array<{}>} = props.report.oSPCRData;
    useEffect(() => {
        const url = `${month}/${year}/all`;
        reportAction.fetchOSPCRData(url).then((report: any) => {
            setState({...state, report});
        })
    }, []);


    const onChangeFilter: OnChange = async (e, element) => {

        const yearMonth = element.value;
        let month:number;
        let year:string;
        let practice:string;
        const parts = yearMonth.split('-', 3);
        if (yearMonth) {
            month = parts[1];
            year = parts[0];
            practice = 'all';
        } else {
            month = moment().month();
            year = moment().format('YYYY');
            practice = 'all';
        }
        const url = `${month}/${year}/${practice}`;
        setMonth(month);
        setYear(year);
        reportAction.fetchOSPCRData(url);
    };

    const getOSCareReport = () => {
            const insertSpaces = (s:string) => {
                    s = s.replace(/([a-z])([A-Z])/g, '$1 $2');
                    s = s.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
                    return s;
                }
            const capitalizeFirstLetter = (s:string) => {
                    return s.charAt(0).toUpperCase() + s.slice(1);
                }
            const numberWithCommas = (x: string) => {
                    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            const monthAllData:Array<{}> = OSPCRReport.monthData;
            const yearAllData:Array<{}> = OSPCRReport.yearData;
            let newStroHtml:any = '';
           let printDateValue:any = moment().format('MMMM YYYY');
            // month Records
            if(monthAllData && monthAllData.length > 0) {
                    const objMonth: any = {};
                    monthAllData.map((s: any) => (Object.assign(objMonth, {[s.practiceAbbr]: s.month})));
                    let dataObj: any = {};

                    let practiceName: string[] = [];
                    let dataKeys: string[] = [];
                    let printDateValue:any = '';
                    monthAllData.forEach((item: any, key: number) => {
                        const data: any = key == 0 ? dataKeys = Object.keys(item.month) : '';
                        practiceName.push(item.practiceAbbr);
                        printDateValue = item.reportDate;
                        dataKeys = dataKeys.filter(item => item !== 'scheduleApp')
                    });

                    let headeoMonth = '';
                    if (monthAllData && monthAllData.length) {
                        monthAllData.forEach((item: any, key: number) => {
                            headeoMonth += `<th class="header-os" >${item.practice}`;
                        });
                    }

                    newStroHtml += `<div class="ui card"><div class="content">`;
                    newStroHtml += `<h3>Current Month: ${printDateValue}</h3>`;
                    dataKeys.forEach((key) => {
                        let obj = {};
                        let monthObj = {};
                        monthAllData.forEach((item: any) => {
                            const monthData = item.month[key];
                            Object.keys(monthData).forEach((value) => {
                                Object.assign(monthObj, {[`${item.practiceAbbr}_${value}`]: monthData[value],});
                                obj = Object.assign(obj, {[value]: monthObj})
                            });
                        });
                        Object.assign(dataObj, {[key]: obj})
                    });

                    Object.keys(dataObj).forEach((key) => {
                        const keyObj = dataObj[key];
                        newStroHtml += `<h3>This table shows the ${capitalizeFirstLetter(insertSpaces(key))} per practice</h3>` +
                            `<div class="table-responsive"><table class="ui celled single line table unstackable tableStyle tableOpenSurgery "><thead><th colspan="1"><div class="first-th">&nbsp;</div></th>`;
                        newStroHtml += headeoMonth;
                        newStroHtml += `</th><th class="header-os">Grand Total</th></tr></thead>`;
                        newStroHtml += `<tbody>`;


                        Object.keys(keyObj).forEach((value) => {
                            let newArr: any = [];
                            newStroHtml += `<tr><td>${capitalizeFirstLetter(insertSpaces(value))}</td>`;

                            let monthgrandTotal: any = 0;
                            let count:any = 0;
                            practiceName.forEach((practice) => {
                                let numseVar: string;
                                if (value == 'percentCompleted' || value == 'showRateHistorical' || value == 'showRateCurrentMonth') {
                                    numseVar = parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(1) + '%';
                                    monthgrandTotal = monthgrandTotal + parseInt(keyObj[value][`${practice}_${value}`]);

                                } else if (value == 'nextWorkDay' || value == 'lastWorkDay') {
                                    numseVar = keyObj[value][`${practice}_${value}`];
                                    monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                }else if (value == 'currentMonthBudCpv' || value == 'deltaCpv' ||
                                    value == 'actCpv' || value == 'goalCpv' || value =='splyActCpv') {
                                    numseVar = '$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0)) + '';
                                    const checkNum:any = parseFloat(keyObj[value][`${practice}_${value}`])
                                    if(checkNum !== 0){
                                        count++;
                                        monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                    }
                                } else if ((value).toLowerCase().includes("care") || (value).toLowerCase().includes("cpv")) {
                                    numseVar = '$' + numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))
                                        + '';
                                    monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                } else {
                                    monthgrandTotal = monthgrandTotal + parseFloat(keyObj[value][`${practice}_${value}`]);
                                    numseVar = `${numberWithCommas(parseFloat(keyObj[value][`${practice}_${value}`]).toFixed(0))}`;


                                }
                                newStroHtml += `<td>${numseVar}</td>`;
                            });

                            if (value == 'percentCompleted' || value == 'showRateHistorical' || value == 'showRateCurrentMonth') {
                                const percent: any = (monthgrandTotal / practiceName.length);
                                newStroHtml += `<td>${parseFloat(percent).toFixed(1) + '%'}</td></tr>`;
                            } else if (value == 'currentMonthBudCpv' || value == 'deltaCpv' ||
                                value == 'actCpv' || value == 'goalCpv' || value =='splyActCpv') {
                                const val: any = (monthgrandTotal / count);
                                newStroHtml += `<td>${'$' + numberWithCommas(parseFloat(val ? val : 0).toFixed(0))}</td></tr>`;
                            }else if (value == 'nextWorkDay' || value == 'lastWorkDay') {
                                newArr.push('');
                                newStroHtml += `<td>&nbsp;</td></tr>`;
                            } else if ((value).toLowerCase().includes("care") || (value).toLowerCase().includes("cpv")) {
                                const price: string = `$${numberWithCommas(parseFloat(monthgrandTotal).toFixed(0))}`;
                                newStroHtml += `<td>${price}</td></tr>`;
                            } else {
                                const num: string = `${parseFloat(monthgrandTotal).toFixed(0)}`;
                                newStroHtml += `<td>${num}</td></tr>`;
                            }

                        });
                        newStroHtml += `</tbody></table></div>`;
                    });
                    newStroHtml += `</div></div>`;
                }else {
                newStroHtml += `<div class="ui card"><div class="content">`;
                newStroHtml +=  `<h1>Current Month: ${printDateValue}</h1>`;
                newStroHtml += `<div class="table-responsive">
                                <table class="ui celled single line table unstackable tableStyle"><thead>
                                <th colspan="1" class="header-os-inner"><div class="first-th">&nbsp;</div></th>`;
                newStroHtml += `<tbody>`;
                newStroHtml += `<tr><td colSpan={10}>No record found</td></tr>`;
                newStroHtml += `</tbody></table></div></div></div>`;
            }

            // year Records
            if(yearAllData && yearAllData.length > 0){
                const objYear: any = {};
                yearAllData.map((s: any) => (Object.assign(objYear, {[s.practiceAbbr]: s.year})));
                let dataObjYear: any = {};
                let practiceNameYear: string[] = [];
                let dataKeysYear: string[] = [];
                yearAllData.forEach((item: any, key: number) => {
                    const data: any = key == 0 ? dataKeysYear = Object.keys(item.year) : '';
                    practiceNameYear.push(item.practiceAbbr);
                    dataKeysYear = dataKeysYear.filter(item => item !== 'scheduleApp' && item !== 'newYReport')
                });
                let headerYear = '';
                if (yearAllData && yearAllData.length) {
                    yearAllData.forEach((item: any, key: number) => {
                        headerYear += `<th class="header-os">${item.practice}`;
                    });
                }
                newStroHtml +=`<div class="ui card"><div class="content">`;
                newStroHtml +=`<h3>Current Year:${year}</h3>`;
                dataKeysYear.forEach((key) => {
                    let objYear = {};
                    let yearObj = {};
                    yearAllData.forEach((item: any) => {
                        const yearData = item.year[key];
                        Object.keys(yearData).forEach((value) => {
                            Object.assign(yearObj, {[`${item.practiceAbbr}_${value}`]: yearData[value],});
                            objYear = Object.assign(objYear, {[value]: yearObj})
                        });
                    });
                    Object.assign(dataObjYear, {[key]: objYear})
                });
                Object.keys(dataObjYear).forEach((key) => {
                    const keyObjYear = dataObjYear[key];
                    newStroHtml += `<h3>This table shows the YTD Report per practice</h3>` +
                        `<div class="table-responsive"><table class="ui celled single line table unstackable tableStyle tableOpenSurgery"><thead><th colspan="1" class="header-os-inner"><div class="first-th">&nbsp;</div></th>`;
                    newStroHtml += headerYear;
                    newStroHtml += `</th><th class="header-os">Grand Total</th></tr></thead>`;
                    newStroHtml += `<tbody>`;
                    Object.keys(keyObjYear).forEach((value) => {
                        newStroHtml += `<tr><td>${capitalizeFirstLetter(insertSpaces(value))}</td>`;
                        let yearGrandTotal: any = 0;
                        let yearCount : any = 0;
                        practiceNameYear.forEach((practice) => {
                            let numseVarYear: string;
                            if (value == 'percentCompleted' || value == 'showRateHistorical' || value == 'showRateCurrentMonth') {
                                numseVarYear = parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(1) + '%';
                                yearGrandTotal = yearGrandTotal + parseInt(keyObjYear[value][`${practice}_${value}`]);
                            } else if (value == 'nextWorkDay' || value == 'lastWorkDay') {
                                numseVarYear = keyObjYear[value][`${practice}_${value}`];
                                yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                            } else if (value == 'currentMonthBudCpv' || value == 'deltaCpv' ||
                                value == 'actCpv' || value == 'goalCpv' || value =='splyActCpv') {
                                numseVarYear = '$' + numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0)) + '';
                                const checkNum:any = parseFloat(keyObjYear[value][`${practice}_${value}`])
                                if(checkNum !== 0){
                                    yearCount++;
                                    yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                }
                            } else if ((value).toLowerCase().includes("care") || (value).toLowerCase().includes("cpv")) {
                                numseVarYear = '$' + numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0)) + '';
                                yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                            } else {
                                yearGrandTotal = yearGrandTotal + parseFloat(keyObjYear[value][`${practice}_${value}`]);
                                numseVarYear = `${numberWithCommas(parseFloat(keyObjYear[value][`${practice}_${value}`]).toFixed(0))}`;
                            }
                            newStroHtml += `<td>${numseVarYear}</td>`;
                        });

                        if (value == 'percentCompleted' || value == 'showRateHistorical' || value == 'showRateCurrentMonth') {
                            const percent: any = (yearGrandTotal / practiceNameYear.length);
                            newStroHtml += `<td>${parseFloat(percent).toFixed(1) + '%'}</td></tr>`;
                        } else if (value == 'nextWorkDay' || value == 'lastWorkDay') {
                            newStroHtml += `<td>&nbsp;</td></tr>`;
                        } else if (value == 'currentMonthBudCpv' || value == 'deltaCpv' ||
                            value == 'actCpv' || value == 'budCpv' || value =='splyActCpv') {
                            const val: any = (yearGrandTotal / yearCount);
                            newStroHtml += `<td>${'$' + numberWithCommas(parseFloat(val ? val : 0).toFixed(0))}</td></tr>`;
                        } else if ((value).toLowerCase().includes("care") || (value).toLowerCase().includes("cpv")) {
                            const price: string = `$${numberWithCommas(parseFloat(yearGrandTotal).toFixed(0))}`;
                            newStroHtml += `<td>${price}</td></tr>`;
                        } else {
                            const num: string = `${parseFloat(yearGrandTotal).toFixed(0)}`;
                            newStroHtml += `<td>${num}</td></tr>`;
                        }
                    });
                    newStroHtml += `</tbody></table></div>`;
                });
                newStroHtml += `</div></div>`;
            }else{
                newStroHtml += `<h1>Current Year:${year}</h1>`;
                newStroHtml += `<div class="ui card"><div class="content">`;
                newStroHtml += `<div class="table-responsive "><table class="ui celled single line table unstackable tableStyle" ><thead><th colspan="1" class="header-os-inner"><div class="first-th">&nbsp;</div></th>`;
                newStroHtml += `<tbody>`;
                newStroHtml += `<tr><td colSpan={10}>No record found</td></tr>`;
                newStroHtml += `</tbody></table></div>`;
                newStroHtml += `</div></div>`;
            }
            return(newStroHtml);
    };


    const sendEmail = (data:any, month:any, year:any, excel:any) => {
        const csvDataReturn :any = exportOpenSurgeryReport(data, month, year, !excel);
        const pdfDataReturn :any = htmlOpenSurgeryReport(data, month, year);
        reportAction.sendMailOSR({data: csvDataReturn, pdf: pdfDataReturn});
        setExcel(true);
    };
    const flipContent = (status:any) => {
        setNewOsReport(status);
    }


    return (
        <Template activeLink="Oral Surgery Patient Care Report">
               {newOsReport ? '' : <div className="ui "><div className="content pb0">
                   <Button className="float-left mb10" onClick={() =>flipContent(true)}>Back to OS PCR new report</Button>
               </div></div>}
                {newOsReport ? '' : <div className="ui card"><div className="content pb0">
                    <h2 className="float-left mr10 mb10">Oral Surgery Patient Care Report</h2>
                    <div className="topFilters">
                        <a className="link mr30" onClick={() => sendEmail( OSPCRReport, month, year, excel)}><Icon name="send"/> Send</a>
                        <a className="link" onClick={() => exportOpenSurgeryReport(OSPCRReport, month, year, excel)}><Icon
                            name="file alternate"/>Export</a>
                        <Dropdown search className='mr10 mb15' name="month" placeholder="Select Month" selection
                                  options={getMonthsOption()} onChange={onChangeFilter}
                        />
                    </div>
                </div></div>}
            {newOsReport ? <NewOSReport flipContent={flipContent}/> : <div dangerouslySetInnerHTML={{ __html: getOSCareReport() }} />}
        </Template>
    )
}

function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app,
    };
}

export default connect(mapStateToProps)(OpenSurgeryPCRReport);