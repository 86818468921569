import React, { useEffect, useState } from 'react';
import { AccordionTitle, AccordionContent, Accordion, Icon, Label, Grid, GridRow, GridColumn, Button, ButtonGroup, Loader, Progress } from 'semantic-ui-react'
import * as HealthStatusActions from "../../redux/actions/healthStatus";
import { useActions } from '../../redux/actions';
import CronsHealthTable from './CronsHealthTable';
import ProgressBar from './ProgressBar';

function CronsHealth() {
    const initialState = { loading: false, data: {} as any, panel: '', records: [] };
    const healthStatusActions = useActions(HealthStatusActions);
    const [state, setState] = useState(initialState);
    const { data, loading, panel, records } = state;

    useEffect(() => {
        setState({ ...state, loading: true })
            healthStatusActions.getCronsHealthStatus('').then((data: any) => {
            healthStatusActions.addToCount({ success: data.succeeded, failed: data.failed, pending: 0 }).then();
            setState({ ...state, data: data, records: data.records.all, loading: false });
        })
    }, [])

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }

    const viewRecords = (selected: string) => {
        setState({ ...state, records: data.records[selected] });
    }

    return (
        <>
            <AccordionTitle active={panel === 'crons'} onClick={() => onSelect('crons')}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Crons</span>
                            {loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={data.failed} pending={0} success={data.succeeded} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === 'crons'}>
                {!loading && <Grid>
                    <GridRow>
                        <GridColumn width={16}>
                            <ButtonGroup >
                                <Button onClick={() => viewRecords('succeeded')} icon='folder outline' color='green' content={`Passed ${(data.succeeded)}`} />
                                <Button onClick={() => viewRecords('failed')} labelPosition='right' icon='folder outline' color='red' content={`Failed ${(data.failed)}`} />
                            </ButtonGroup>
                            {records && <CronsHealthTable records={records} />}
                        </GridColumn>
                    </GridRow>
                </Grid>}
            </AccordionContent>
        </>
    );
}

export default CronsHealth;