import React from 'react';

interface PerformanceChartProps {
  performanceValue: number;
  unit?: string;
}

function getPerformanceColorClassName(value: number): string {
    if (value < 0 || value > 100) {
        return 'invalid-value'; // Handle values outside the range
      }
    
      const segmentSize = 20; // 100 / 5 color classes = 20
      const classIndex = Math.floor(value / segmentSize);
    
      return `performance-color-${classIndex}`;
}

function getColorBasedOnPerformance(value: number): string {
  if (value < 0 || value > 100) {
    return 'gray'; // Handle values outside the range
  }

  const segmentSize = 20;
  const classIndex = Math.floor(value / segmentSize);
  const colorMap: { [key: string]: string } = {
    'performance-color-0': 'red',
    'performance-color-1': 'orange',
    'performance-color-2': 'yellow',
    'performance-color-3': 'yellowgreen',
    'performance-color-4': 'green',
  };

  return colorMap[`performance-color-${classIndex}`] || 'black';  
}


const PerformanceChart: React.FC<PerformanceChartProps> = ({ performanceValue, unit }) => {
  const color = getColorBasedOnPerformance(performanceValue);

  return (
    <div className="performance-chart">
      <span className="performance-text">{performanceValue}{`${unit ? unit : '%'}`}</span>
      <div className="performance-color" style={{ backgroundColor: color }}>
        {/* No content needed here */}
      </div>
    </div>
  );
};
export default PerformanceChart;