import React from 'react'
import {Modal, Table} from 'semantic-ui-react'
import {CronStatusTxt} from "../constants";
import moment from "moment";

interface Props {
    details: any
    onClose: Function
}

const CronDetails = (props: Props) => {
    const {details} = props;

    return (
        <Modal
            closeIcon={true}
            size={'fullscreen'}
            centered={true}
            open={true}
            onClose={() => props.onClose()}
            style={{maxHeight: '80vh', overflowY: 'auto', right: '0em'}}
        >
            <Modal.Header>{details.title}</Modal.Header>
            <Modal.Content>
                <Table className="ui table table-striped celled table-hover unstackable">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <div>
                                    Start Time
                                </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>
                                    End Time
                                </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>
                                    Status
                                </div>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <div>
                                    Result
                                </div>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {details && details.logs && details.logs.length ?
                            details.logs.map((item: any, index: any) => {
                                return (
                                    <Table.Row key={index}>
                                        <Table.Cell>{item.startTime}</Table.Cell>
                                        <Table.Cell>{item.endTime}</Table.Cell>
                                        <Table.Cell>{CronStatusTxt[item.status]}</Table.Cell>
                                        <Table.Cell className={'whiteSpace'}>{item.message}</Table.Cell>
                                    </Table.Row>
                                )
                            })
                            :
                            <Table.Row key={0}>
                                <Table.Cell>No record found</Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                </Table>
            </Modal.Content>
        </Modal>
    )
}

export default CronDetails
