import React, {useEffect, useState} from "react";
import Template from "./Template";
import {RootState} from "../model";
import {connect} from "react-redux";
import * as ReportActions from "../redux/actions/writeOffList"
import {useActions} from "../redux/actions";
import {Button, Confirm, Icon, Search, Table} from "semantic-ui-react";
import _ from "lodash";
import WriteOffListForm from "./WriteOffListForm";
import HelpMark from "./HelpMark";


interface WriteOffListProps {
    writeOffList: any
}

const initialState: any = {
    loading: false,
    cronStatus: 'All',
    writeOffList: [],
    isEdit: false,
    initialValues: {adjustmentsCode: '', removeFromDrPay: '', id: 0},
    orderBy: null,
    order: true,
    search: ""
}

const WriteOffList = (props: WriteOffListProps) => {
    const reportActions = useActions(ReportActions);
    const [state, setStates] = useState(initialState);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deletingItem, setDeletingItem] = useState(null);

    useEffect(() => {
        reportActions.getWriteOffCodes()
            .then((res: any) => {
                setStates({...state, writeOffList: res});
            });
    }, []);

    const onSearch = (e: any, element: any) => {
        const obj = {...state}
        obj.search = element.value;
        setStates(obj);
    };
    const getSortRows = (sortColumn: string, order: string) => {
        const {writeOffList} = state;
        const orderUpdated = !order;
        const adjustmentCodesSort = _.orderBy(writeOffList, [sortColumn], [order ? 'asc' : 'desc'])
        setStates({...state, writeOffList: adjustmentCodesSort, orderBy: sortColumn, order: orderUpdated});
    };


    const edit = (obj: any) => {
        const initialValues = {
            ...obj
        };
        setStates({...state, isEdit: true, initialValues});
    };

    const cancelForm = () => {
        setStates({...state, isEdit: false, initialValues: {title: '', description: '', id: 0}});
    };

    const updateWriteOffCodes = (data: any) => {
        const func = data.id ? "updateWriteOffCodes" : 'saveWriteOffCodes'
        reportActions[func](data).then((data: any) => {
            reportActions.getWriteOffCodes()
            .then((res: any) => {
                setStates({
                    ...state,
                    writeOffList: res,
                    isEdit: false,
                    initialValues: {title: '', description: '', id: 0}
                });
            });
        })
    };

    const deleteWriteOffCode = (item: any) => {
        setDeletingItem(item);
        setShowDeleteConfirmation(true);
    };

    const deleteConfirmed = (item: any) => {
        reportActions.deleteWriteOffCodes(item).then(() => {
            reportActions.getWriteOffCodes()
            .then((res: any) => {
                setStates({
                    ...state,
                    writeOffList: res,
                    isEdit: false,
                    initialValues: {title: '', description: '', id: 0}
                });
            });
        });
        setShowDeleteConfirmation(false);
    };

    const {writeOffList, search, orderBy, order, isEdit} = state;
    const direction = order ? 'sort down' : 'sort up';
    return (
        <Template activeLink="crons-list">
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">UDA Write Off Codes <HelpMark pageId='0'/></h2>
                    <div className="topFilters flex items-end flex-wrap">
                        <Button
                            primary onClick={() => edit({adjustmentsCode: '', removeFromDrPay: '', id: 0})}>Create
                            New </Button>
                        <div>
                            <Search
                                placeholder='Search...'
                                onSearchChange={onSearch}
                                name={'search'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isEdit &&
                <>
                    <WriteOffListForm
                        cancelForm={() => cancelForm()}
                        saveForm={(data: any) => updateWriteOffCodes(data)}
                        initialValues={state.initialValues}
                    />
                </>
            }
            <div className="ui card">
                <div className="content">
                    <div className="FreezeTable">
                        <Table className="ui table table-striped table-hover celled unstackable no-wrap">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'adjustmentsCode' ? 'active' : ''}`}
                                        onClick={() => getSortRows('adjustmentsCode', order)}
                                    >
                                        <div>WRITE OFF CODE
                                            <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/></div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>PROCEDURE CODE</div>
                                    </Table.HeaderCell>

                                    <Table.HeaderCell
                                        className={`cursorPointer ${orderBy === 'adjustmentsCode' ? 'active' : ''}`}
                                        onClick={() => getSortRows('adjustmentsCode', order)}
                                    >
                                        <div>WRITE OFF DESCRIPTION
                                            <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/></div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>ADD TO DR PAY</div>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <div>Action</div>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {writeOffList.length &&
                                    writeOffList.filter((item: any, index: any) => {
                                        if (!search) {
                                            return true
                                        } else {
                                            const values = Object.values(item).join(" ")
                                            return values.toLowerCase().includes(search.toLowerCase())
                                        }
                                    })
                                        .map((item: any, index: any) => {
                                            return (
                                                <Table.Row key={index}>
                                                    <Table.Cell>{item.writeoffCode}</Table.Cell>
                                                    <Table.Cell>{item.procedureCode}</Table.Cell>
                                                    <Table.Cell>{item.writeoffDesc}</Table.Cell>
                                                    <Table.Cell
                                                        className={''}>{item.addToDrPay ? "1" : '0'}</Table.Cell>
                                                    <Table.Cell className={''}>
                                                        <Icon className="cursorPointer" name="edit"
                                                              onClick={() => edit(item)}/>
                                                        <Icon className="cursorPointer ml10" name="trash alternate"
                                                              onClick={() => deleteWriteOffCode(item)}/>
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
            {
                showDeleteConfirmation &&
                <Confirm
                    open={showDeleteConfirmation}
                    onCancel={() => setShowDeleteConfirmation(false)}
                    onConfirm={() => deleteConfirmed(deletingItem)}
                    header="Confirm Delete"
                    content="Are you sure you want to delete this item?"
                    cancelButton="Cancel"
                    confirmButton="Delete"
                    confirmButtonClass="danger-confirm-button"
                />
            }
        </Template>
    );
};

function mapStateToProps(state: RootState) {
    return {
        writeOffList: state.writeOffList,
    };
}

export default connect(mapStateToProps)(WriteOffList);
