import { Report } from "../../model/report";
import { App } from "../../model";
import { Action, ActionType } from "../../model/actionTypes";
import createReducer from "./createReducer";

const initialState:Report = {
	reportData: null,
	reportNewData: [],
	reportPerPracticeData: null,
	reportDataLastMonths: null,
	scheduleData: null,
	budgetData: null,
	budgetDataPerPractice: null,
	aptData:null,
    clinicalScheduleData: {},
	oSPCRData: {},
	oSPCRRollUpData:{},
	patientValidationData:{},
	loginVia: '',
	mdhWriteOffReport : {},
	mdhWriteOffDReport:{},
	progresscsv: null,
	apiList : [],
	cronList : [],
	mcoList : [],
	shiftData: null,
	rampThresholdDefaults : [],
	HygienistBracketSystem : [],
	drAppPermissionsMatrix : [],
	orthoWorkDayMTD: {},
	orthoWorkDayYTD: {},
	orthoChangeCodes: [],
	orthoUDRUserData: {},
	orthoUDROrthoDataYTD: {},
	orthoUDROrthoDataMTD: {},
	scheduleDataRawMTD:[],
	scheduleDataRawYTD:[],
	loaderMTD: false,
	loaderYTD: false,
	mountDateData:[],
	loaderStatusCodes: false,
	pTData: null,
	orthoYTDKeys:[],
	orthoMTDKeys:[],
	patientLogReport:[],
	totalRecords: 0,
};
export const report = createReducer(initialState, {
	[ActionType.FETCH_REPORT](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_REPORT_NEW_DATA](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_REPORT_PER_PRACTICE](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_REPORT_LAST_MONTHS](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_SCHEDULE](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_BUDGET](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_BUDGET_PER_PRACTICE](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
    [ActionType.FETCH_CLINICAL_SCHEDULE](state: Report, action: Action<Report>) {
        return Object.assign({...state}, action.payload);
    },
	[ActionType.FETCH_CLINICAL_SCHEDULE_COUNT](state: Report, action: Action<App>) {
        return Object.assign({...state}, { totalRecords: action.payload });
    },
    [ActionType.SEND_REPORT](state: Report, action: Action<Report>) {
        return Object.assign({...state}, action.payload);
    },
	[ActionType.FETCH_OPEN_SURGERY](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_APT](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.SAVE_USAGE_PTV](state: Number, action: Action<App>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.UPDATE_OS_BUDGET](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_PATIENT_VALIDATION_REPORT](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.MDHM_WRITE_OFF_REPORT](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.MDHM_WRITE_OFF_D_REPORT](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.PMD_PROGRESSBAR](state: Report, action: Action<Report>) {
        return Object.assign({...state}, action.payload);
    },
	[ActionType.LOAD_API_LIST](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_SHIFT](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.LOAD_CRONS_LIST](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.LOAD_BRACKET_SYSTEM](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.LOAD_RAMP_THRESHOLD_DEFAULTS](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.LOAD_DR_APP_PERMISSIONS_MATRIX](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_ORTHO_PCR_MTD](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_ORTHO_PCR_YTD](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_ORTHO_PCR_CODES](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.LOADER_STATUS_CODES](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_ORTHO_PCR_UDR_USER_DATA](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_ORTHO_PCR_UDR_ORTHO_YTD_DATA](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_ORTHO_PCR_UDR_ORTHO_MTD_DATA](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.LOADER_MTD](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.LOADER_YTD](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_MOUNT_DATE](state:App,action:Action<App>){
		return Object.assign({...state},action.payload)
	},
	[ActionType.FETCH_PT](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_ORTHO_PCR_YTD_KEYS](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_ORTHO_PCR_MTD_KEYS](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},
	[ActionType.FETCH_PATIENT_LOGS_REPORT](state: Report, action: Action<Report>) {
		return Object.assign({...state}, action.payload);
	},

});
