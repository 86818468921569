import {ActionType} from "../../model/actionTypes";
import {errorHandler} from "./auth";

export const createSetup = (userSetup: any) => async (dispatch: Function, getState: Function, api: any) => {
        dispatch({type: ActionType.LOADER, payload: true});
        try {
            const resp = await api.post(`userSetup`, userSetup);
            dispatch({type: ActionType.LOADER, payload: false});
            return resp
        } catch (err) {
            dispatch({type: ActionType.LOADER, payload: false});
            return dispatch(errorHandler(err));
        }
}

export const createOrUpdateSetup = (userSetup: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        const resp = await api.post(`userSetup/create`, userSetup);
        dispatch({type: ActionType.LOADER, payload: false});
        return resp
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
}

export const getSetup = (params?: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
         params.userId = getState().auth.user.id;
        let resp = await api.get(`userSetup/${JSON.stringify(params)}`);
        if (!resp) {
            resp = {
                value: 'all',
                userId: params.userId
            }
        }
        dispatch({ type: ActionType.PATIENT_VALIDATION_DEFAULT_PRACTICES, payload: {defaultPractice: resp} });
        dispatch({type: ActionType.LOADER, payload: false});
        return resp
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
}

export const getUserSetup = (params?: any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({type: ActionType.LOADER, payload: true});
    try {
        let resp = await api.get(`userSetup/${JSON.stringify(params)}`);
        if (!resp) {
            resp = {
                value: 'all',
                userId: params.userId
            }
        }
        dispatch({ type: ActionType.PATIENT_VALIDATION_DEFAULT_PRACTICES, payload: {defaultPractice: resp} });
        dispatch({type: ActionType.LOADER, payload: false});
        return resp
    } catch (err) {
        dispatch({type: ActionType.LOADER, payload: false});
        return dispatch(errorHandler(err));
    }
}