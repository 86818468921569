import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Template from "./Template";
import {User} from "../model/user";
import {RootState} from "../model";
import {useActions} from "../redux/actions";
import * as spotActions from "../redux/actions/spot";
import * as practiceActions from "../redux/actions/practice";
import {Button, Dropdown, Header, Modal, Table} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {resultDropDown} from "../container/Spot/constants";
import {brands} from "../constants";
import SpotWorkDetails from "./SpotWorkDetails";
import * as reportActions from "../redux/actions/report";

// Set the start of the week to Monday
moment.updateLocale('en', {
    week: {
        dow: 1 // Monday is the first day of the week
    }
});


interface Props {
    auth: User,
    workReport : any,
    loader : any,
    regionPracticeList : any,
    allPracticeList : any,
    workReportDetails : any
}

function SpotWork(props: Props) {
    const spotAction = useActions(spotActions);
    const practiceAction = useActions(practiceActions);
    const reportAction = useActions(reportActions);
    const initialFilters: any = {
        practice: [],
        regions: [],
        results : [],
        brands : ['3CD', 'FSD', 'OTD', 'SMD'],
        interval : 'days',
        startDate: new Date().setDate(new Date().getDate() - 7),
        endDate : new Date()
    };
    const initialState: any = {
        regionOpts : [],
        practiceOpts: [],
        practiceList : [],
        spotDetailsModal : false,
        detailsLocation : ''
    };
    const [filters, setFilters] = useState(initialFilters);
    const [state, setState] = useState(initialState);
    const [renderTable, setRenderTable] = useState(false);
    const {loader, workReport, allPracticeList, regionPracticeList, workReportDetails } = props;

    useEffect(() => {
        Promise.all([
            practiceAction.fetchRegions(),
            practiceAction.getAllPractices()
        ]).then((result: any) => {
            const [ regions, practices ] = result;
            const regionOpts  = [{key: 'all', value: '', text: 'Select Region'}];
            regions && regions.forEach((reg:any) => {
                regionOpts.push({
                    key: reg.id,
                    value: reg.id,
                    text: reg.name
                })
            })

            const practiceOpts  = [{key: 'all', value: '', text: 'Select Practice'}];
            const practiceList : any = [];
            practices && practices.forEach((prac:any) => {
                practiceOpts.push({
                    key: prac.id,
                    value: prac.practice,
                    text: prac.practice
                })
                practiceList.push(prac.practice);
            })
            setState({...state, practiceOpts: practiceOpts, practiceList: practiceList, regionOpts: regionOpts})
            loadData();
        })

    }, []);

    const loadData = async () => {
        const {startDate, endDate, interval, practice, results, regions, brands} = filters;
        const start = moment(startDate);
        const end = moment(endDate);
        const diff = end.diff(start, 'days');
           if(diff > 7 && interval == 'days' ){
               alert('Number of days are more than 7, Please change interval as Weeks');
               return false;
           }
            if(diff > 70 && interval == 'weeks' ){
                alert('Number of days are more than 70, Please change interval as Months');
                return false;
            }
            if(diff > 368){
                alert('Sorry, you are not allowed to select date range more than an year, Please change dates');
                return false;
            }

        await spotAction.getSpotWork({
            startDate : start.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate : end.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            interval,
            practice,
            results,
            regions,
            brands
        })
        setRenderTable(true);
    }

    const handleSendEmail = () => {
            const data = getTableData('csv')
            reportAction.sendEmailWithCsv({title: 'SPOT Work Report', csvData: data });
    }

    const sendDetailsEmail = () => {
        const data :any = [];
        workReportDetails && workReportDetails.forEach((item : any, key: number)  => {
            data.push({
                Practice: item.practice.toUpperCase(),
                PatNum: item.patnum,
                Date :item.createdAt ? item.createdAt.split('T')[0]  : '',
                Result:item.Result,
                User : item.firstname +' '+ item.lastname
            })
        });
        reportAction.sendEmailWithCsv({title: 'SPOT Work Report Details', csvData: data });
        closeSpotDetails();
    }


    const closeSpotDetails = () => {
        setState({...state, spotDetailsModal : false});
    }

    const handleDetails = async (practice: string, date :  string) => {
        await spotAction.getSpotWorkDetails({practice, date})
        setState({...state, spotDetailsModal : true});
    }

    const handleDateChange = (date: any, type: string) => {
        setRenderTable(false);
        filters[type] = date;
        setFilters({...filters });
    };

    const generateTableHeaders = (start: any, end: any, interval: any) =>  {
        const format = interval ==='months' ? 'YYYY-MM' : 'YYYY-MM-DD';
        const headers = ['Practice'];
        if(!renderTable){
            headers.push('Waiting for criteria selection...')
            return headers;
        }
        let currentDate = moment(start).startOf(interval);
        const endDate = moment(end).endOf(interval);

        while (currentDate.isSameOrBefore(endDate, interval)) {
            headers.push(currentDate.format(format));
            currentDate.add(1, interval);
        }

        return headers;
    }
    

    const getTableData = (responseType: 'table' | 'csv') => {
        const {startDate, endDate, interval, practice, regions, brands} = filters;
        const { practiceList } = state;

        const dataRows: any[] = [];
        const totalRow: any = {};
        const tableHeader = generateTableHeaders(startDate, endDate, interval);
        let filteredList = practiceList;

        if (brands.length) {
            const practiceNames = allPracticeList.filter((item: any) => {
              if (item.UdaPracticeInfo) {
                return brands.includes(item.UdaPracticeInfo.brand);
              }
              return false;
            });
            filteredList = practiceNames.map((item: { practice: string }) => item.practice);
          }

        if (regions.length) {
            const regionPractices = regions.flatMap((region: number) => regionPracticeList && regionPracticeList[region] || []);
            filteredList = filteredList.filter((practice: any) => regionPractices.includes(practice));
        }

        if (practice.length) {
            filteredList = filteredList.filter((item: any) => practice.includes(item));
        }

        filteredList && filteredList.forEach((prac: any) => {
            const item = workReport && workReport[prac];
            const row: any = { practice: prac };

            tableHeader.forEach((head: string, i: number) => {
                if (i > 0) { //ignore practice title column
                    const count = item && item[head] ? item[head] : 0;
                    if (totalRow[head] === undefined) {
                        totalRow[head] = 0;
                    }
                    totalRow[head] += count > 0 ? count : 0;
                    row[head] = count;
                }
            });

            dataRows.push(row);
        });

        const generateTable = () => {
            const tableRows: any[] = dataRows.map((row, key) => {
                const cells = [<Table.Cell key={-1}>{row.practice}</Table.Cell>];
                tableHeader.forEach((head, i) => {
                    if (i > 0) {
                        cells.push(
                            <Table.Cell
                                key={i}
                                className={row[head] > 0 ? 'pcr_tbl_numbers_clickable' : ''}
                                onClick={() => row[head] > 0 ? handleDetails(row.practice, head) : ''}
                            >
                                {row[head]}
                            </Table.Cell>
                        );
                    }
                });
                return <Table.Row key={key}>{cells}</Table.Row>;
            });

            const totalCells: any = [<Table.Cell key={-1}><b>Total</b></Table.Cell>];
            tableHeader.forEach((head, i) => {
                if (i > 0) {
                    totalCells.push(<Table.Cell key={i}><b>{totalRow[head]}</b></Table.Cell>);
                }
            });
            tableRows.push(<Table.Row key={-1}>{totalCells}</Table.Row>);

            return (
                <div className="FreezeTable">
                    <Table className="ui table table-striped table-hover celled unstackable no-wrap">
                        <Table.Header>
                            <Table.Row>
                                {tableHeader.map((header, index) => (
                                    <Table.HeaderCell style={{ color: "#d16f26" }} key={index}>
                                        <div>{header}</div>
                                    </Table.HeaderCell>
                                ))}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {tableRows}
                        </Table.Body>
                    </Table>
                </div>
            );
        };

        const generateCSV = () => {
            const csvArray: { [key: string]: string | number }[] = [];
            dataRows.forEach(row => {
                const csvRow: { [key: string]: string | number } = {};
                csvRow["Practice"] = row.practice;
                tableHeader.forEach((head, i) => {
                    if (i > 0) {
                        csvRow[head] = row[head];
                    }
                });
                csvArray.push(csvRow);
            });

            const totalCsvRow: { [key: string]: string | number } = { Practice: 'Total' };
            tableHeader.forEach((head, i) => {
                if (i > 0) {
                    totalCsvRow[head] = totalRow[head];
                }
            });
            csvArray.push(totalCsvRow);

            return csvArray;
        };

        return responseType === 'table' ? generateTable() : generateCSV();
    };






    return (
        <Template activeLink='spot-patient-retention'>
            <div className="ui card">
                <div className="content pb0">
                    <h2 className="float-left mr10">SPOT Work Report</h2>

                    <div className="topFilters">
                        <Button positive={true} onClick={() => handleSendEmail()}>
                            Send Email
                        </Button>

                        <Dropdown
                            placeholder='Select Region'
                            options={state.regionOpts}
                            selection={true}
                            search={true}
                            multiple={true}
                            value={filters.regions}
                            onChange={(_: any, {value}: any) => {
                                setFilters({...filters, regions: value})
                            }}
                            name="regions"
                        />


                        <Dropdown
                            placeholder='Select Practice'
                            options={state.practiceOpts}
                            selection={true}
                            search={true}
                            multiple={true}
                            value={filters.practice}
                            onChange={(_:any,{value}:any) => setFilters({...filters, practice : value})}
                            name="practice"
                        />
                        <Dropdown
                            placeholder='Select Result'
                            options={resultDropDown}
                            selection={true}
                            search={true}
                            multiple={true}
                            value={filters.results}
                            onChange={(_: any, {value}: any) => {
                                setRenderTable(false);
                                setFilters({...filters, results: value})
                            }}
                            name="results"
                        />

                        <Dropdown
                            placeholder='Select Brand'
                            options={brands}
                            selection={true}
                            multiple={true}
                            search={true}
                            value={filters.brands}
                            onChange={(_: any, {value}: any) => {
                                setRenderTable(false);
                                setFilters({...filters, brands: value})
                            }}
                            name="brands"
                        />

                        <Dropdown
                            placeholder='Select Interval'
                            options={[
                                {key: 'days', value: 'days', text: 'Days'},
                                {key: 'weeks', value: 'weeks', text: 'Weeks'},
                                {key: 'months', value: 'months', text: 'Months'}
                            ]}
                            selection={true}
                            value={filters.interval}
                            onChange={(_: any, {value}: any) => {
                                setRenderTable(false);
                                setFilters({...filters, interval: value})
                            }}
                            name="interval"
                        />
                        <div className="datePicker-holder displayInlineBlock">
                            <DatePicker
                                selected={filters.startDate}
                                maxDate={filters.endDate}
                                onChange={(date) => handleDateChange(date, 'startDate')}
                            />
                        </div>
                        <div className="datePicker-holder displayInlineBlock">
                            <DatePicker
                                selected={filters.endDate}
                                minDate={filters.startDate}
                                onChange={(date) => handleDateChange(date, 'endDate')}
                            />
                        </div>

                        <Button onClick={() => loadData()}> Submit </Button>

                    </div>
                </div>
            </div>

            <div className="ui card">
                <div className="content">
                    {!loader && getTableData('table')}

                </div>
            </div>
            {state.spotDetailsModal &&
                <SpotWorkDetails
                    workReportDetails={workReportDetails}
                    handleClose={closeSpotDetails}
                    sendDetailsEmail={sendDetailsEmail}
                />}

        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        auth: state.auth,
        workReport : state.spot.spotWorkReport,
        workReportDetails : state.spot.spotWorkReportDetails,
        loader : state.app.loader,
        regionPracticeList : state.practice.regionPracticeList,
        allPracticeList : state.practice.allPracticeList
    };
}

export default connect(mapStateToProps)(SpotWork)