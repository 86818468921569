import { Dropdown, Modal, Table} from "semantic-ui-react";
import React from "react";
import {LOCKHISTORYHEADERS} from "./constants";

interface Props {
    onClose: Function
    onChangeProvider: any,
    profiles:[],
    profilesLockDetails:[]
}

const ProviderLockedHistory = (props: Props) => {
    return (
        <Modal open={true} closeIcon={true} size={'fullscreen'} centered={true} onClose={() => props.onClose()} style={{height: '60vh', overflowY: 'auto', right: '0em'}}>
            <Modal.Content >
                <div className="ui card">
                    <div className="content pb0">
                        <h2 className="float-left mr10">Provider Lock History</h2>
                        <div className="topFilters">
                            <Dropdown
                                search={true}
                                name="provider"
                                placeholder="Select Provider"
                                selection={true}
                                options={props.profilesLockDetails}
                                onChange={(event, data) => {
                                    props.onChangeProvider(data.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className="content" style={{width: '100%', overflowX: 'auto'}}>
                        <Table className="ui table table-striped table-hover celled unstackable no-wrap">
                            <Table.Header>
                                <Table.Row>
                                    {
                                        LOCKHISTORYHEADERS.map((header, key) =>
                                            <Table.HeaderCell
                                                key={key}
                                            >  <div>{header.label}</div>
                                            </Table.HeaderCell>)
                                    }
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                { props.profiles && props.profiles.map((data: any, key: number) =>
                                <Table.Row key={key}>
                                    <Table.Cell>{data.timestamp}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.consultPrice}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.contractName}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.dailyRatePay}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.dailyRateProd}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.debondBonus}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.dotBonus}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.denovoMonthlyBasePay}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.denovoMonthlyWorkDays}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.monthlyBasePay}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.monthlyBaseProduction}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.orthoDayRate}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.payTimeOffDays}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.payTimeOffDays}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.prodAdjWo}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.sdContract}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.sdStartBonus}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.startBonus}</Table.Cell>
                                    <Table.Cell>{data.data.UdaDrLists[0]?.travelStipend}</Table.Cell>
                                </Table.Row>)}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </Modal.Content>
        </Modal>);
}

export default ProviderLockedHistory
