import React from "react";
import {Button, Modal} from "semantic-ui-react";
import {RootState} from "../model";
import {connect} from "react-redux";

function OsModel (props: any) {

    const getPracticeBar = props.practiceAbbr;
    const OSPCRReport: {[key: string]: Array<{}>} = props.report.oSPCRData;
    const monthAllData:Array<{}> = OSPCRReport.monthData;
    const yearAllData:Array<{}> = OSPCRReport.yearData;
    const filterMonthData = monthAllData.filter((data: any) =>
        data.practiceAbbr === getPracticeBar);
    const filterYearData = yearAllData.filter((data: any) =>
        data.practiceAbbr === getPracticeBar);
    const numberWithCommas = (x: string) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    let monthData:any = [];
    filterMonthData.forEach((item: any, key: number) => {
        monthData = item.monthAllData;
    })
    let yearData:any = [];
    filterYearData.forEach((item: any, key: number) => {
        yearData = item.AllYearData;
    });

    const getTableHeadings = () => {
        let returnArray: React.ReactElement[] = [];

        if (monthData && monthData.length) {
            monthData.forEach((item: any, key: number) => {
                returnArray.push(
                    <th key={key} className="header-os-inner">{item.practice}
                    </th>);
            });
        }
        return (<thead>
        <tr>
            <th colSpan={1} className="header-os-inner">
                <div className="first-th">&nbsp;</div>
            </th>
            {returnArray}
        </tr>
        </thead>)
    }
    const finalData = monthData.map((item:any, i:any) => Object.assign({}, item, yearData[0]));
    const getTableRows = () => {
        let returnArrayDaysOpen: React.ReactElement[] = [];
        let returnArrayPtSchedule: React.ReactElement[] = [];
        let returnArrayActCare: React.ReactElement[] = [];
        let returnArrayDeltaCare: React.ReactElement[] = [];
        let returnArrayActCpvCare: React.ReactElement[] = [];
        let returnArrayBudCare: React.ReactElement[] = [];
        let returnArrayActPtVisits: React.ReactElement[] = [];
        let returnArrayBudPt: React.ReactElement[] = [];
        let returnArrayDeltaPTVisits: React.ReactElement[] = [];
        let returnArrayBudCareY: React.ReactElement[] = [];
        let returnArrayActCareY: React.ReactElement[] = [];
        let returnArrayDeltaCareY: React.ReactElement[] = [];

        finalData.map((item: any, key: number) => {
              returnArrayDaysOpen.push(<td key={key} className="header-os">{item.date}</td>);
              returnArrayBudCare.push(<td key={key} className="header-os">
                  ${numberWithCommas(parseFloat(item.careGoal).toFixed(0))}</td>);
              returnArrayActCare.push(<td key={key}  className="header-os">
                  ${numberWithCommas(parseFloat(item.careActual).toFixed(0))}</td>);
              returnArrayDeltaCare.push(<td key={key} className="header-os">
                  ${numberWithCommas(parseFloat(item.careDelta).toFixed(0))}</td>);
              returnArrayActCpvCare.push(<td key={key} className="header-os">
                  ${numberWithCommas(parseFloat(item.cpv).toFixed(0))}</td>);
              returnArrayActPtVisits.push(<td key={key}  className="header-os">{item.ptActual}</td>);
              returnArrayBudPt.push(<td key={key}  className="header-os-inner">{item.ptGoal}</td>);
              returnArrayDeltaPTVisits.push(<td key={key}  className="header-os">{item.ptDelta}</td>);
              returnArrayPtSchedule.push(<td key={key}  className="header-os">{item.ptScheduled}</td>);
              returnArrayBudCareY.push(<td key={key} className="header-os">
                {numberWithCommas(parseFloat(item.careGoalYTD).toFixed(0))}</td>);
              returnArrayActCareY.push(<td key={key} className="header-os">
                {numberWithCommas(parseFloat(item.careActualYTD).toFixed(0))}</td>);
              returnArrayDeltaCareY.push(<td key={key}  className="header-os">
                {numberWithCommas(parseFloat(item.careDeltaYTD).toFixed(0))}</td>);
         });

         return (<tbody>
                    <tr><td className="header-os"  >Date</td>{returnArrayDaysOpen}</tr>
                    <tr><td className="header-os"  >Care Goal</td>{returnArrayBudCare}</tr>
                    <tr><td className="header-os"  >Care Actual</td>{returnArrayActCare}</tr>
                    <tr><td className="header-os"  >Care Delta</td>{returnArrayDeltaCare}</tr>
                    <tr><td className="header-os"  >CPV</td>{returnArrayActCpvCare}</tr>
                    <tr><td className="header-os"  >PT Goal</td>{returnArrayActPtVisits}</tr>
                    <tr><td className="header-os"  >PT Actual</td>{returnArrayBudPt}</tr>
                    <tr><td className="header-os"  >PT Delta</td>{returnArrayDeltaPTVisits}</tr>
                    <tr><td className="header-os"  >PT Scheduled</td>{returnArrayPtSchedule}</tr>
                    <tr><td className="header-os"  >Care Goal YTD</td>{returnArrayBudCareY}</tr>
                    <tr><td className="header-os"  >Care Actual YTD</td>{returnArrayActCareY}</tr>
                    <tr><td className="header-os"  >Care Delta YTD</td>{returnArrayDeltaCareY}</tr>
            </tbody>);

    }


    return (
        <Modal className={'modalCenter'}
               onClose={() => props.handleClose()}
               open={true}
        >
            <Modal.Header>New OS PCR report  practice data <Button
                className="float-right"
                content="close"
                primary={true}
                onClick={() => props.handleClose()}
            /></Modal.Header>
            <Modal.Content >
                <Modal.Description>
                    <div>
                        <div className="table-responsive">
                            <table className="ui celled single line table unstackable tableStyle tableOpenSurgery ">
                                {getTableHeadings()}
                                {getTableRows()}
                            </table>
                        </div>

                    </div>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="OK"
                    primary={true}
                    onClick={() => props.handleClose()}
                />
            </Modal.Actions>
        </Modal>
    );
}


function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app,
    };
}
export default connect(mapStateToProps)(OsModel);
