import { ActionType } from "../../model/actionTypes";
import {ImplantCost} from "../../model/implantCostList";
import {errorHandler} from "./auth";
import {WriteOffList} from "../../model/writeOffList";

export const saveImplantCost = (obj: ImplantCost) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("implant-costs/createImplantCost", obj);
        dispatch({ type: ActionType.SAVE_IMPLANT_COST_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const updateImplantCost = (obj:ImplantCost) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("implant-costs/update", obj);
        dispatch({ type: ActionType.UPDATE_IMPLANT_COST_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const getImplantCosts= (id:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        id=id||'all';
        const resp =  await api.get("implant-costs/"+id);
        dispatch({ type: ActionType.FETCH_IMPLANT_COST_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};