import React, {Component, useEffect, useState} from 'react';
import {Table, Input, Modal, Select, Label, Grid, Button, Divider, Popup, Icon, Dropdown} from 'semantic-ui-react'
import {useActions} from "../../redux/actions";
import * as ProfileActions from "../../redux/actions/provider";
import * as PracticeActions from "../../redux/actions/practice";

interface ProviderDayRateModalProps {
    openProviderDayRateModal: boolean
    userId: number
    DocData: any,
    showOpenProviderDayRateModal: any,
    initialValues: any
}

class DayRate {
    practice!: string;
    practiceId!: string;
    prodAdjWo!: string;
    payOverBase!: string;
    dayRate!: string;
    dailyRateProduction!: string;
    monthlyBasePay!: string;
    monthlyBaseProduction!: string;
    monthlyBaseWorkDays!: string;
    dailyRatePay!: string
}
const ProviderDayRateModal = (props: ProviderDayRateModalProps) => {
    const dayRates : Array<DayRate> = []
    const dayRateObj: DayRate = {} as DayRate
    const practices: any[] = [];
    const practiceRateToDelete: any = {};
    const [state, setState] = useState({
        dayRates: dayRates,
        contractName: props.initialValues.contractName,
        addRow: false,
        dayRateObj: dayRateObj,
        practices: practices,
        deleteModalOpen: false,
        practiceRateToDelete: practiceRateToDelete
    });
    const providerActions = useActions(ProfileActions);
    const practiceActions = useActions(PracticeActions);

    useEffect(() => {
        const fetchData = async () => {
            const {userId, } = props
            const { contractName } = state
            const results: any = await Promise.all([
                providerActions.getProviderDayRate(userId),
                practiceActions.getAllPractices(),
            ]);
            const options: any = []
            results[1].forEach((item: any) => {
                let option = {
                    value: item.practice,
                    text: item.practice,
                    key: item.id
                };
                options.push(option);
            });
            setState(prevState => ({
                ...prevState,
                dayRates: results[0],
                contractName: contractName,
                practices: options
            }));
        }
        fetchData();
    }, []);

    const saveRate = () => {
        const {dayRates} = state;
        const {userId,DocData} = props;
        Promise.all([
            providerActions.updateProviderDayRates(dayRates, userId,DocData.id)])
            .then(() => {
                console.log("saveRate::updateProviderDayRates:: success")
                alert('Provider day rate(s) successfully updated.');
            }).catch(error => {
            console.log("saveRate::updateProviderDayRates::", error)
            alert('Provider day rate(s) update failed.');
        });
    };
    const getTableHeader = () => {
        const {contractName} = state;
        let fieldName = '';
        let practiceName = 'Practice';
        let fieldName2 = '';
        let fieldName3 = '';
        let fieldName4 = '';
        let fieldName5 = '';
        let fieldName6 = '';
        if (contractName === 'Greater Of') {
            fieldName = 'Greater Of Day Rate';
            fieldName2 = 'ProdAdjWO %';
            fieldName3 = 'Pay Over Base %';
            return (
                <Table.Row>
                    <Table.HeaderCell> {practiceName}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName2}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName3}</Table.HeaderCell>
                    <Table.HeaderCell> Action </Table.HeaderCell>
                </Table.Row>
            )
        } else if (contractName === 'GOX' || contractName === 'GOZ') {
            fieldName = `${contractName} Day Rate`;
            fieldName2 = `ProdAdjWO %`;
            fieldName3 = 'Pay Over Base %';
            fieldName4 = "Daily Rate Production";
            return (
                <Table.Row>
                    <Table.HeaderCell> {practiceName}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName2}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName3}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName4}</Table.HeaderCell>
                    <Table.HeaderCell> Action </Table.HeaderCell>
                </Table.Row>
            )
        } else if (contractName === 'Sum Of') {
            fieldName2 = `ProdAdjWO %`;
            fieldName3 = 'Pay Over Base %';
            fieldName4 = "Monthly Base Pay";
            fieldName5 = "Monthly Base Production";
            fieldName6 = "Monthly Base Work Days";
            return (
                <Table.Row>
                    <Table.HeaderCell> {practiceName}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName2}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName3}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName4}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName5}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName6}</Table.HeaderCell>
                    <Table.HeaderCell> Action </Table.HeaderCell>
                </Table.Row>
            )
        } else {
            fieldName2 = 'ProdAdjWO %';
            fieldName3 = "Pay Over Base %";
            return (
                <Table.Row>
                    <Table.HeaderCell> {practiceName}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName2}</Table.HeaderCell>
                    <Table.HeaderCell> {fieldName3}</Table.HeaderCell>
                    <Table.HeaderCell> Action </Table.HeaderCell>
                </Table.Row>
            )
        }
    }

    const getTableRows = () => {
        const {contractName, addRow, dayRates} = state;
        const {practices} = state;
        if (contractName === 'Greater Of') {
            if (addRow) {
                dayRates.unshift(state.dayRateObj);
            }
            if (dayRates.length) {
                let i = 0;
                return dayRates.map((data: {
                    practice: string | number | boolean | (string | number | boolean)[] | undefined;
                    dayRate: any;
                    prodAdjWo: any;
                    payOverBase: any;
                }, key: any) => {
                    i++;
                    if (addRow && data.practice === '') {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>
                                    <Dropdown
                                        name="practice"
                                        placeholder='Practice'
                                        fluid={true}
                                        search={true}
                                        selection
                                        options={practices}
                                        value={data.practice}
                                        onChange={(e, value) => handleOptionsWithPractice(e,value, key)}
                                    />
                                </Table.Cell>
                                <Table.Cell><Input type="text" name="dayRate" value={data.dayRate}
                                                   onChange={(e) => handleOptionsWithDayRate(e, key)}/></Table.Cell>
                                <Table.Cell><Input type="text" name="prodAdjWo" value={data.prodAdjWo}
                                                   onChange={(e) => handleOptionsWithAll(e, key)}/></Table.Cell>
                                <Table.Cell><Input type="text" name="payOverBase" value={data.payOverBase}
                                                   onChange={(e) => handleOptionsWithOverBase(e, key)}/></Table.Cell>
                                <Table.Cell>
                                    <Popup secondary inverted content={`Delete the rate for ${data.practice} practice.`}
                                           trigger={
                                               <Button primary icon
                                                       onClick={() => toggleDeletePracticeRateModalView(data)}>
                                                   <Icon name="trash alternate outline" className="mr10"/>
                                               </Button>
                                           } />
                                </Table.Cell>
                            </Table.Row>
                        )
                    } else {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>
                                    <Dropdown
                                        name="practice"
                                        placeholder='Practice'
                                        fluid={true}
                                        search={true}
                                        selection
                                        options={practices}
                                        value={data.practice}
                                        onChange={(e, value) => handleOptionsWithPractice(e,value, key)}
                                    />
                                </Table.Cell>
                                <Table.Cell><Input type="text" name="dayRate" value={data.dayRate}
                                                   onChange={(e) => handleOptionsWithDayRate(e, key)}/></Table.Cell>
                                <Table.Cell><Input type="text" name="prodAdjWo" value={data.prodAdjWo}
                                                   onChange={(e) => handleOptionsWithAll(e, key)}/></Table.Cell>
                                <Table.Cell><Input type="text" name="payOverBase" value={data.payOverBase}
                                                   onChange={(e) => handleOptionsWithOverBase(e, key)}/></Table.Cell>
                                <Table.Cell>
                                    <Popup secondary inverted content={`Delete the rate for ${data.practice} practice.`}
                                           trigger={
                                               <Button primary icon
                                                       onClick={() => toggleDeletePracticeRateModalView(data)}>
                                                   <Icon name="trash alternate outline" className="mr10"/>
                                               </Button>
                                           } />
                                </Table.Cell>
                            </Table.Row>
                        )
                    }

                })
            }
        } else if (contractName === 'GOX' || contractName === 'GOZ') {
            if (addRow) {
                dayRates.unshift(state.dayRateObj);
            }
            if (dayRates.length) {
                let i = 0;
                return dayRates.map((data, key) => {
                    i++;
                    if (addRow && data.practice === '') {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>
                                    <Dropdown
                                        name="practice"
                                        placeholder='Practice'
                                        fluid={true}
                                        search={true}
                                        selection
                                        options={state.practices}
                                        value={data.practice}
                                        onChange={(e, value) => handleOptionsWithPractice(e,value, key)}
                                    />
                                </Table.Cell>
                                <Table.Cell><Input type="text" name="dayRate" value={data.dayRate}
                                                   onChange={(e) => handleOptionsWithDayRate(e, key)}/></Table.Cell>
                                <Table.Cell><Input type="text" name="prodAdjWo" value={data.prodAdjWo}
                                                   onChange={(e) => handleOptionsWithAll(e, key)}/></Table.Cell>
                                <Table.Cell><Input type="text" name="payOverBase" value={data.payOverBase}
                                                   onChange={(e) => handleOptionsWithOverBase(e, key)}/></Table.Cell>
                                <Table.Cell><Input type="text" name="dailyRatePay" value={data.dailyRateProduction}
                                                   onChange={(e) => handleOptionsWithDailyRateProduction(e, key)}/></Table.Cell>
                                <Table.Cell>
                                    <Popup secondary inverted content={`Delete the rate for ${data.practice} practice.`}
                                           trigger={
                                               <Button primary icon
                                                       onClick={() => toggleDeletePracticeRateModalView(data)}>
                                                   <Icon name="trash alternate outline" className="mr10"/>
                                               </Button>
                                           } />
                                </Table.Cell>
                            </Table.Row>
                        )
                    } else {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>
                                    <Dropdown
                                        name="practice"
                                        placeholder='Practice'
                                        fluid={true}
                                        search={true}
                                        selection
                                        options={state.practices}
                                        value={data.practice}
                                        onChange={(e, value) => handleOptionsWithPractice(e,value, key)}
                                    />
                                </Table.Cell>
                                <Table.Cell><Input type="text" name="dayRate" value={data.dayRate}
                                                   onChange={(e) => handleOptionsWithDayRate(e, key)}/></Table.Cell>
                                <Table.Cell><Input type="text" name="prodAdjWo" value={data.prodAdjWo}
                                                   onChange={(e) => handleOptionsWithAll(e, key)}/></Table.Cell>
                                <Table.Cell><Input type="text" name="payOverBase" value={data.payOverBase}
                                                   onChange={(e) => handleOptionsWithOverBase(e, key)}/></Table.Cell>
                                <Table.Cell><Input type="text" name="dailyRatePay" value={data.dailyRateProduction}
                                                   onChange={(e) => handleOptionsWithDailyRateProduction(e, key)}/></Table.Cell>
                                <Table.Cell>
                                    <Popup secondary inverted content={`Delete the rate for ${data.practice} practice.`}
                                           trigger={
                                               <Button primary icon
                                                       onClick={() => toggleDeletePracticeRateModalView(data)}>
                                                   <Icon name="trash alternate outline" className="mr10"/>
                                               </Button>
                                           } />
                                </Table.Cell>
                            </Table.Row>
                        )
                    }
                });
            }
        } else if (contractName === 'Sum Of') {
            if (addRow) {
                dayRates.unshift(state.dayRateObj);
            }
            if (dayRates.length) {
                let i = 0;
                return dayRates.map((data, key) => {
                    i++;
                    if (addRow && data.practice === '') {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>
                                    <Dropdown
                                        name="practice"
                                        placeholder='Practice'
                                        fluid={true}
                                        search={true}
                                        selection
                                        options={state.practices}
                                        value={data.practice}
                                        onChange={(e, value) => handleOptionsWithPractice(e,value, key)}
                                    />
                                </Table.Cell>
                                <Table.Cell><Input style={{width: `80px`}} type="text" name="prodAdjWo"
                                                   value={data.prodAdjWo}
                                                   onChange={(e) => handleOptionsWithAll(e, key)}/></Table.Cell>
                                <Table.Cell><Input style={{width: `80px`}} type="text" name="payOverBase"
                                                   value={data.payOverBase}
                                                   onChange={(e) => handleOptionsWithOverBase(e, key)}/></Table.Cell>
                                <Table.Cell><Input style={{width: `80px`}} type="text" name="monthlyBasePay"
                                                   value={data.monthlyBasePay}
                                                   onChange={(e) => handleOptionsWithMonthlyBasePay(e, key)}/></Table.Cell>
                                <Table.Cell><Input style={{width: `80px`}} type="text" name="monthlyBaseProduction"
                                                   value={data.monthlyBaseProduction}
                                                   onChange={(e) => handleOptionsWithMonthlyBaseProduction(e, key)}/></Table.Cell>
                                <Table.Cell><Input style={{width: `80px`}} type="text" name="monthlyBaseWorkDays"
                                                   value={data.monthlyBaseWorkDays}
                                                   onChange={(e) => handleOptionsWithMonthlyBaseWorkDays(e, key)}/></Table.Cell>
                                <Table.Cell>
                                    <Popup secondary inverted content={`Delete the rate for ${data.practice} practice.`}
                                           trigger={
                                               <Button primary icon
                                                       onClick={() => toggleDeletePracticeRateModalView(data)}>
                                                   <Icon name="trash alternate outline" className="mr10"/>
                                               </Button>
                                           } />
                                </Table.Cell>
                            </Table.Row>
                        );
                    } else {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>
                                    <Dropdown
                                        name="practice"
                                        placeholder='Practice'
                                        fluid={true}
                                        search={true}
                                        selection
                                        options={state.practices}
                                        value={data.practice}
                                        onChange={(e, value) => handleOptionsWithPractice(e,value, key)}
                                    />
                                </Table.Cell>
                                <Table.Cell><Input style={{width: `80px`}} type="text" name="prodAdjWo"
                                                   value={data.prodAdjWo}
                                                   onChange={(e) => handleOptionsWithAll(e, key)}/></Table.Cell>
                                <Table.Cell><Input style={{width: `80px`}} type="text" name="payOverBase"
                                                   value={data.payOverBase}
                                                   onChange={(e) => handleOptionsWithOverBase(e, key)}/></Table.Cell>
                                <Table.Cell><Input style={{width: `80px`}} type="text" name="monthlyBasePay"
                                                   value={data.monthlyBasePay}
                                                   onChange={(e) => handleOptionsWithMonthlyBasePay(e, key)}/></Table.Cell>
                                <Table.Cell><Input style={{width: `80px`}} type="text" name="monthlyBaseProduction"
                                                   value={data.monthlyBaseProduction}
                                                   onChange={(e) => handleOptionsWithMonthlyBaseProduction(e, key)}/></Table.Cell>
                                <Table.Cell><Input style={{width: `80px`}} type="text" name="monthlyBaseWorkDays"
                                                   value={data.monthlyBaseWorkDays}
                                                   onChange={(e) => handleOptionsWithMonthlyBaseWorkDays(e, key)}/></Table.Cell>
                                <Table.Cell>
                                    <Popup secondary inverted content={`Delete the rate for ${data.practice} practice.`}
                                           trigger={
                                               <Button primary icon
                                                       onClick={() => toggleDeletePracticeRateModalView(data)}>
                                                   <Icon name="trash alternate outline" className="mr10"/>
                                               </Button>
                                           } />
                                </Table.Cell>
                            </Table.Row>
                        )
                    }
                });
            }
        } else {
            if (addRow) {
                dayRates.unshift(state.dayRateObj);
            }
            if (dayRates.length) {
                let i = 0;
                return dayRates.map((data, key) => {
                    i++;
                    if (addRow && data.practice === '') {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>
                                    <Dropdown
                                        name="practice"
                                        placeholder='Practice'
                                        fluid={true}
                                        search={true}
                                        selection
                                        options={state.practices}
                                        value={data.practice}
                                        onChange={(e, value) => handleOptionsWithPractice(e,value, key)}
                                    />

                                </Table.Cell>
                                <Table.Cell><Input type="text" name="prodAdjWo" value={data.prodAdjWo}
                                                   onChange={(e) => handleOptionsWithAll(e, key)}/></Table.Cell>
                                <Table.Cell><Input type="text" name="payOverBase" value={data.payOverBase}
                                                   onChange={(e) => handleOptionsWithOverBase(e, key)}/></Table.Cell>
                                <Table.Cell>
                                    <Popup secondary inverted content={`Delete the rate for ${data.practice} practice.`}
                                           trigger={
                                               <Button primary icon
                                                       onClick={() => toggleDeletePracticeRateModalView(data)}>
                                                   <Icon name="trash alternate outline" className="mr10"/>
                                               </Button>
                                           } />
                                </Table.Cell>
                            </Table.Row>
                        )
                    } else {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>
                                    <Dropdown
                                        name="practice"
                                        placeholder='Practice'
                                        fluid={true}
                                        search={true}
                                        selection
                                        options={state.practices}
                                        value={data.practice}
                                        onChange={(e, value) => handleOptionsWithPractice(e,value, key)}
                                    />
                                </Table.Cell>
                                <Table.Cell><Input type="text" name="prodAdjWo" value={data.prodAdjWo}
                                                   onChange={(e) => handleOptionsWithAll(e, key)}/></Table.Cell>
                                <Table.Cell><Input type="text" name="payOverBase" value={data.payOverBase}
                                                   onChange={(e) => handleOptionsWithOverBase(e, key)}/></Table.Cell>
                                <Table.Cell>
                                    <Popup secondary inverted content={`Delete the rate for ${data.practice} practice.`}
                                           trigger={
                                               <Button primary icon
                                                       onClick={() => toggleDeletePracticeRateModalView(data)}>
                                                   <Icon name="trash alternate outline" className="mr10"/>
                                               </Button>
                                           } />
                                </Table.Cell>
                            </Table.Row>
                        )
                    }
                });
            }
        }

    }

    /** @param {string} value */
    const handleOptionsWithAll = (e: any, a: any) => {
        handleOptions(e, a);
    };


    /** @param {string} value */
    const handleOptionsWithOverBase = (e: any, a: any) => {
        handleOptionsOverBase(e, a);
    };

    /** @param {string} value */
    const handleOptionsWithMonthlyBasePay = (e: any, a: any) => {
        handleOptionsMonthlyBasePay(e, a);
    };

    /** @param {string} value */
    const handleOptionsWithMonthlyBaseProduction = (e: any, a: any) => {
        handleOptionsMonthlyBaseProduction(e, a);
    };

    /** @param {string} value */
    const handleOptionsWithMonthlyBaseWorkDays = (e: any, a: any) => {
        handleOptionsMonthlyBaseWorkDays(e, a);
    };

    /** @param {string} value */
    const handleOptionsWithDayRate = (e: any, a: any) => {
        handleOptionsDayRate(e, a);
    };

    /** @param {string} value */
    const handleOptionsWithDailyRateProduction = (e: any, a: any) => {
        handleOptionsDailyRateProduction(e, a);
    };

    /** @param {string} value */
    const handleOptionsWithPractice = (e: any, data: any, key: any) => {
        handleOptionsPractice(e, data, key);
    };

    const handleOptions = (e: any, key: any) => {
        let {dayRates} = state;
        dayRates[key].prodAdjWo = e.target.value;
        setState({...state, dayRates, addRow: false});
    };

    const handleOptionsOverBase = (e: any, key: any) => {
        let {dayRates} = state;
        dayRates[key].payOverBase = e.target.value;
        setState({...state, dayRates,addRow: false});
    };

    const handleOptionsMonthlyBasePay = (e: any, key: any) => {
        let {dayRates} = state;
        dayRates[key].monthlyBasePay = e.target.value;
        setState({...state, dayRates,addRow: false});
    };

    const handleOptionsMonthlyBaseProduction = (e: any, key: any) => {
        let {dayRates} = state;
        dayRates[key].monthlyBaseProduction = e.target.value;
        setState({...state, dayRates,addRow: false});
    };

    const handleOptionsMonthlyBaseWorkDays = (e: any, key: any) => {
        let {dayRates} = state;
        dayRates[key].monthlyBaseWorkDays = e.target.value;
        setState({...state, dayRates,addRow: false});
    };

    const handleOptionsDayRate = (e: any, key: any) => {
        let {dayRates} = state;
        dayRates[key].dayRate = e.target.value;
        setState({...state, dayRates,addRow:false});
    };

    const handleOptionsDailyRateProduction = (e: any, key: any) => {
        let {dayRates} = state;
        dayRates[key].dailyRateProduction = e.target.value;
        setState({...state, dayRates,addRow: false});
    };

    const handleOptionsPractice = (e: any,data: any, key: any) => {
        let {dayRates} = state;
        dayRates[key].practice = data.value;
        dayRates[key].practiceId = state.practices.filter(p => p.value == data.value)[0].key;
        setState({...state, dayRates, addRow: false});
    }

    const toggleDeletePracticeRateModalView = (data: object) => {
        setState({...state, deleteModalOpen: !state.deleteModalOpen, practiceRateToDelete: data });
    }

    const deletePracticeRate = async (data: any) => {
        const request = {
            id: data.id,
            userId: data.userId
        }
        const response = await providerActions.deleteProviderDayRate(request);
        if(!response){
            alert('Provider Day Rate deleted successfully')
        }else{
            alert('Failed to delete Provider Day Rate.')
        }
        setState({...state, practiceRateToDelete: {}, deleteModalOpen: false})
        props.showOpenProviderDayRateModal(false);
    }

    const addRowRates = () => {
        setState({...state, 
            dayRateObj: {
                practiceId:'',
                practice: '',
                prodAdjWo: '',
                payOverBase: '',
                dayRate: '',
                dailyRateProduction: '',
                monthlyBasePay: '',
                monthlyBaseProduction: '',
                monthlyBaseWorkDays: '',
                dailyRatePay: ''
            },
            addRow: true
        })
    };

    return (<>
        <Modal
            open={props.openProviderDayRateModal}
            size='large'
            centered
            closeIcon
            onClose={() => props.showOpenProviderDayRateModal(false)}
        >
            <Modal.Header>
                <Modal.Content id='ModalHeader'
                               style={{
                                   display: "flex",
                                   justifyContent: "center",
                                   alignItems: "center",
                               }}>
                    CDP Day Rates for  &nbsp; <Label color='grey'>
                    Dr &nbsp;&nbsp;{props.DocData.firstName || ''} {" " + props.DocData.lastName || ''}
                </Label>  &nbsp;&nbsp;

                    <Modal.Content id='ModalHeader'>
                        | &nbsp;&nbsp;<span>  Status:</span> <Label
                        color={props.DocData.status ? 'teal' : 'red'}>{props.DocData.status ? 'Active' : 'DeActive'}</Label>
                    </Modal.Content>

                </Modal.Content>
            </Modal.Header>
            <Modal.Content style={{maxHeight: '500px', overflowY: 'scroll'}}>

                <Table compact={true} className="customTableStyles" size="small" striped={true}>
                    <Table.Header>
                        {getTableHeader()}
                    </Table.Header>
                    <Table.Body>
                        {getTableRows()}
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Add Rate"
                    secondary
                    labelPosition='right'
                    icon='plus'
                    onClick={() => addRowRates()}
                    negative
                />
                <Button
                    content="Close"
                    secondary
                    labelPosition='right'
                    icon='close'
                    onClick={() => props.showOpenProviderDayRateModal(false)}
                    negative
                />
                <Button
                    content="Save Rates"
                    primary
                    labelPosition='right'
                    icon='checkmark'
                    onClick={saveRate}
                    positive
                />
            </Modal.Actions>
        </Modal>

        <Modal size='small' open={state.deleteModalOpen} closeIcon onClose={() => toggleDeletePracticeRateModalView({})}>
            <Modal.Header>Delete Provider Profile.</Modal.Header>
            <Modal.Content>
                <p>{`Are you sure you want to delete ${state.practiceRateToDelete ? state.practiceRateToDelete.practice : ''} practice rate?`}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button secondary negative onClick={() => toggleDeletePracticeRateModalView({})}>
                    No, Cancel
                </Button>
                <Button primary positive onClick={() => deletePracticeRate(state.practiceRateToDelete)}>
                    Yes, Proceed
                </Button>
            </Modal.Actions>
        </Modal>
    </>)

}

export default ProviderDayRateModal;

