import React, {useState} from "react";
import {Button, Checkbox, Dropdown, Form, Label, Modal} from "semantic-ui-react";
import {ODallowedApis} from "../constants";

function PracticeAdminODconfig(props: any) {
    const {data} = props;
    const {practiceId, configData, isReachable} = data

    const initialState: any = {
        isAllowed: configData && configData.allowCommunication ? configData.allowCommunication : false,
        allowedApis: configData && configData.allowedApis ? configData.allowedApis : [],
        defNum: configData && configData.defNum ? configData.defNum : null,

    }
    const [state, setState] = useState(initialState);

    const onChange = (value: any, name: string) => {
        setState((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    }

    const onChangeCheckbox = (e: any, value: any) => {
        setState({...state, isAllowed: value.checked})
    }

    const handleSubmit = () => {
        const obj = {
            practiceId: practiceId,
            allowCommunication: state.isAllowed,
            allowedApis: state.allowedApis,
            defNum : state.defNum
        }
        props.handleClose();
        props.handleSave(obj);
    }
    return (
        <Modal
            onClose={() => props.handleClose()}
            open={true}
            trigger={<Button>Show Modal</Button>}
        >
            <Modal.Header>
                <div>
                    <span>Open Dental Configuration</span>
                </div>
                <div className="ui top right attached label open_dental_connection_right_label">
                    <Label
                        color={isReachable ? 'green' : 'red'}
                        circular
                        style={{marginRight: '10px'}}
                    >
                        {isReachable ? 'Open Dental connection is Online' : 'Open Dental connection is Offline'}
                    </Label>
                </div>
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form>
                        <Form.Field>
                            <Checkbox
                                label='Communication Allowed'
                                onChange={onChangeCheckbox}
                                checked={state.isAllowed}
                                toggle={true}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Allowed APIs</label>
                            <Dropdown
                                search={true}
                                className='mr10 mb15'
                                name="allowedApis"
                                placeholder="Select APIs"
                                selection={true}
                                multiple={true}
                                options={ODallowedApis}
                                onChange={(e, {value}) => onChange(value, 'allowedApis')}
                                value={state.allowedApis}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>DefNum (For Folder Name Reference)</label>
                            <input
                                name={'defNum'}
                                placeholder='DefNum'
                                value={state.defNum}
                                onChange={(e: any) => onChange(e.target.value, 'defNum')}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.handleClose()}>Cancel</Button>
                <Button onClick={() => handleSubmit()} positive={true}>
                    Save
                </Button>
            </Modal.Actions>
        </Modal>
    );
}

export default PracticeAdminODconfig;
