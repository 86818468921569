import React, { useEffect, useState } from 'react';
import { AccordionTitle, AccordionContent, Icon, Grid, GridRow, GridColumn, Loader } from 'semantic-ui-react'
import { useActions } from '../../../redux/actions';
import * as HealthStatusActions from "../../../redux/actions/healthStatus";
import AwsDbMigrationTable from './AwsDbMigrationTable';
import ProgressBar from '../ProgressBar';

function AwsDatabaseMigration() {
    const initialState = { loading: false, tasks: [] as any[], panel: '', counts: { success: 0, failed: 0, pending: 0 } };
    const healthStatusActions = useActions(HealthStatusActions);
    const [state, setState] = useState(initialState);
    const { tasks, loading, panel, counts } = state;
    const prefix = 'AWSDatabase'

    useEffect(() => {
        setState({...state, loading: true})
        healthStatusActions.get('health-status/aws-migration')
            .then((tasks: any[]) => {
                const failed = tasks.filter((task:any) => task.derivedStatus === "Failed").length;
                const stats = { success: tasks.length - failed, failed: failed, pending: 0 };
                healthStatusActions.addToCount(stats).then();
                setState({ ...state, tasks: tasks , counts: stats });
            }).catch(() => setState({ ...state, loading: false }));
    }, []);

    const onSelect = (selected: string) => {
        if (panel === selected)
            setState({ ...state, panel: '' });
        else
            setState({ ...state, panel: selected });
    }

    return (
        <>
            <AccordionTitle active={state.panel === prefix} onClick={() => onSelect(prefix)}>
                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Database migration</span>
                            {loading && <Loader active size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={counts.failed} pending={counts.pending} success={counts.success} />
                        </GridColumn>
                    </GridRow>
                </Grid>
            </AccordionTitle>
            <AccordionContent active={panel === prefix}>
                {!loading && <AwsDbMigrationTable tasks={tasks} />}
            </AccordionContent>
        </>
    );
}

export default AwsDatabaseMigration;