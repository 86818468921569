import { ActionType } from "../../model/actionTypes";

import { errorHandler } from "./auth";

export const fetchPractices = () => async (
  dispatch: Function,
  getState: Function,
  api: any
) => {
  dispatch({ type: ActionType.LOADER, payload: true });
  try {
    const resp = await api.get(`spore/practices`);
    dispatch({
      type: ActionType.LOAD_SPORE_USER_PRACTICES,
      payload: { user_practices: resp },
    });
    dispatch({ type: ActionType.LOADER, payload: false });
    return resp;
  } catch (error) {
    dispatch({ type: ActionType.LOADER, payload: false });
    return dispatch(errorHandler(error));
  }
};



export const fetchTableResult=(filters:any)=> 
    async (dispatch:Function, getState:Function,api:any) =>  {
      const url = filters ? `spore/${JSON.stringify(filters)}` : `spore/all`
      dispatch({ type: ActionType.LOADER,payload:true })

      try{
          const resp=await api.get(url)
          dispatch({
              type:ActionType.LOAD_SPORE_TABLE_RESULT,
              payload:{tableResult:resp}
          })
          dispatch({ type: ActionType.LOADER, payload: false });
          return resp;
      }catch(error){
          dispatch({type: ActionType.LOADER,payload:false});
          return dispatch(errorHandler(error))
        }
}