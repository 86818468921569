import React, {useState} from "react";
import {Button, Icon, Label, Modal, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import {RootState} from "../../model";
import ReactDOMServer from "react-dom/server";

function ReferralGoalDetailsModal(props: any) {
    const {practiceData} = props;
    const [state, setStates] = useState({
        sort: 0,
        ascending: true
    });
    const {data, ageReq} = practiceData[practiceData.code];
    const filtered = data.filter((d: any) => d.DOS_Age >= ageReq);

    const headers = ['Practice', 'Patient Number', 'DOS Age', 'Procedure Codes', 'Care', 'Date Of Service', 'Doctor']
    const dataRows = filtered.map((log: any) => [
            {value: practiceData.practice.practice},
            {value: log.PatNum},
            {value: log.DOS_Age},
            {
                value: log.ProcCode,
                text: log.ProcCode.split(',')
                    .map((code: string, i: number) => <b className="mr-10" key={code + i}> {code} </b>)
            },
            {value: log.Care, text: '$' + log.Care.toFixed(2)},
            {value: log.ProcDate},
            {value: log.Dr_Name}
        ]
    ).sort((a: any[], b: any[]) => {
        const sort = Number.isInteger(a[state.sort].value)
            ? a[state.sort].value - b[state.sort].value
            : a[state.sort].value.toString().localeCompare(b[state.sort].value.toString());
        return sort * (state.ascending ? -1 : 1)
    });


    const getItem = (attr: string) => {
        return practiceData && practiceData[practiceData.code + attr];
    }

    const sendEmail = () => {
        const tableHeader = `${headers.map(header => header.toUpperCase()).join(',')}`;
        const body = filtered
            .map((log: any) => `${practiceData.practice.practice},${log.PatNum},${log.DOS_Age},${log.ProcCode.split(',').join('  ')},${log.Care},${log.ProcDate},${log.Dr_Name}`)
            .join('\n');
        const csv = `${tableHeader}\n${body}`;
        const pdf = ReactDOMServer.renderToString(<div>
            <h3 style={{textAlign: "center"}}>Speciality PCO</h3>
            <br/>
            {tableData}
        </div>);
        props.sendMail({csv, pdf});
    }

    const tableData = <Table>
        <Table.Header>
            <Table.Row>
                {headers.map((header, i) => <Table.HeaderCell
                    className='cursorPointer'
                    onClick={() => setStates({sort: i, ascending: state.sort == i && !state.ascending})}
                    key={'header_modal_' + i}
                >
                    <span style={{whiteSpace: 'nowrap'}}>
                        <Icon name='sort' color={state.sort == i ? 'orange' : 'grey'}/>
                        {header}
                    </span>
                </Table.HeaderCell>)}
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {
                dataRows && dataRows.map((row: any, key: number) =>
                    <Table.Row key={'modalRow' + key}>
                        {
                            row.map((value: any, i: number) => <Table.Cell
                                key={i + 'column' + key}>{value.text || value.value}</Table.Cell>)
                        }
                    </Table.Row>)
            }
        </Table.Body>
    </Table>


    return (
        <Modal className='modalCenter' size="large" onClose={() => props.handleClose()} open={true}>
            <Modal.Header>
                {practiceData.practice.practice}
                <Label className="ml15">Patient Count: <b>{practiceData.data.length}</b></Label>
                <Label className="ml15">{practiceData.code.toUpperCase()} Patient
                    Count: <b>{getItem('Count')}</b></Label>
                <Label className="ml15" color="blue">{practiceData.code.toUpperCase()} Referral Goal
                    ({getItem('GoalPerc')}) &nbsp;:&nbsp; <b>{getItem('Goal')}</b></Label>
                <Label className="ml15" color="orange">
                    {practiceData.code.toUpperCase()} Referrals: <b>{getItem('Referrals')}</b></Label>
            </Modal.Header>
            {
                data && <Modal.Content scrolling={true}>
                    {tableData}
                </Modal.Content>
            }
            <Modal.Actions>
              {dataRows.length > 0 ?  <Button
                    content="Send Email"
                    primary={true}
                    onClick={() => sendEmail()}
                /> : ''}
                <Button
                    content="Close"
                    onClick={() => props.handleClose()}
                />
            </Modal.Actions>
        </Modal>
    );
}


function mapStateToProps(state: RootState) {
    return {
        report: state.report,
        practice: state.practice,
        app: state.app
    };
}

export default connect(mapStateToProps)(ReferralGoalDetailsModal);
