import { Action, ActionType } from "../../model/actionTypes";
import { Regions } from "../../model/regions";
import createReducer from "./createReducer";



const initialState:any={
    regionsList:[]
}
export const regions = createReducer(initialState, {
    [ActionType.GET_REGIONS_SUCCESS](state: any, action: Action<Regions>) {
        return Object.assign({...state}, action.payload);
    },
    [ActionType.CREATE_BRACKET_SYSTEM](state: any, action: Action<Regions>) {
        return Object.assign({...state}, action.payload);
    },

});