import React, { useEffect, useState } from 'react'
import { AccordionContent, AccordionTitle, Grid, GridColumn, GridRow, Icon, Label, Loader, Progress, Table, TableBody, TableCell, TableRow } from 'semantic-ui-react';
import { categories, colorMap } from './healthStatusUtils';
import * as PracticesActions from '../../redux/actions/practice';
import { useActions } from '../../redux/actions';
import _ from 'lodash';
import ProgressBar from './ProgressBar';
import { HealthScore } from '../../model/healthScore';

interface Props {
    data: any,
    loading: boolean
    overalCount: HealthScore
}

const DentaHubChartByMco = ({ data, loading , overalCount }: Props) => {
    useEffect(() => { getData() }, [data]);
    const initialState = { panel: '', data: [] as any, practices: [], selected: { mco: '', practice: '' }, masterCount: 0 };
    const [state, setState] = useState(initialState);
    const practicesActions = useActions(PracticesActions);
    const { selected } = state;

    const onSelect = (selected: string) => {
        if (state.panel === selected) {
            setState({ ...state, panel: '' });
            return;
        }
        setState({ ...state, panel: selected });
    }

    const getData = async () => {
         const practices = await practicesActions.getDentaHubPractices();
        setState({ ...state, data: data.groupedData, practices});
    }

    const onTileSelect = (mco: string, practice: string) => {
        const { selected } = state;
        if (!practice && selected.mco === mco) {
            setState({ ...state, selected: { ...selected, mco: '' } });
        } else if (selected.mco === mco && selected.practice === practice) {
            setState({ ...state, selected: { ...selected, practice: '' } });
        } else {
            setState({ ...state, selected: { mco, practice } });
        }
    }

    const setColor = (mco: string, index: number) => {
        const { data } = state;
        if (data.length === 0 || data[index][mco].length === 0) return 0;
        const mcoData: any[] = data[index][mco] || [];
        let count = 0;
        mcoData.forEach(item => {
            if (item.status === 'no') count++
        })
        return (mcoData.length - count) * 100 / (mcoData.length || 1);
    }

    const setPracticeColor = (mco: string, index: number, practice: any) => {
        const { data } = state;
        if (data.length === 0 || data[index][mco].length === 0) return 0;
        const mcoData: any[] = data[index][mco] || [];
        let count = 0;
        const practiceData = mcoData.filter((o: any) => (o.practice === practice.practice ||
            o.practice === practice.practiceDBName || o.practice === practice.practiceabbr));

        practiceData.forEach(item => {
            if (item.status === 'no') count++
        })
        return (practiceData.length - count) * 100 / (practiceData.length || 1);
    }

    const setInnerColor = (mco: string, index: number, practice: any, scraper: string) => {
        const { data } = state;
        if (data.length === 0 || data[index][mco].length === 0) return 'grey';
        const mcoData: any[] = data[index][mco];
        const mcoItem = mcoData.filter((o: any) => o.practice === practice.practice ||
            o.practice === practice.practiceDBName || o.practice === practice.practiceabbr).find(f => f.scraper.toLocaleLowerCase() === scraper);
        return colorMap(mcoItem ? mcoItem['status'] : null);
    }

    return (
        <>
            <AccordionTitle active={state.panel === 'dentaHubMco'} onClick={() => onSelect('dentaHubMco')}>

                <Grid>
                    <GridRow>
                        <GridColumn width={13} >
                            <Icon name='dropdown' />
                            <span style={{ fontSize: '15px', marginRight: '30px' }}>Insurance (Mco)</span>
                            {loading && <Loader size='tiny' inline='centered' />}
                        </GridColumn>
                        <GridColumn width={3}>
                            <ProgressBar failed={overalCount.failed} pending={overalCount.pending} success={overalCount.success}/>
                        </GridColumn>
                    </GridRow>
                </Grid>

            </AccordionTitle>
            <AccordionContent active={state.panel === 'dentaHubMco'}>
                {
                    categories.map((mco, index) =>
                        <Table fixed key={index} >
                            <TableBody>
                                <TableRow onClick={() => onTileSelect(mco.name, '')} style={{ cursor: 'pointer' }}>

                                    <TableCell><span style={{ marginLeft: '20px' }}><Icon name='dropdown' />{ mco.label }</span></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        <Progress color='green' size='medium' className='red-bg mb0' percent={setColor(mco.label, index)} />
                                    </TableCell>
                                </TableRow>
                                {
                                    selected.mco === mco.name && state.practices.map((practice: any , key: number) => (
                                        <TableRow style={{ cursor: 'pointer', backgroundColor: key % 2 !== 0 ? '#eaeaea' : 'white' }} key={practice.id}>
                                            <TableCell colspan='6'>
                                                <Grid>
                                                    <GridRow onClick={() => onTileSelect(mco.name, practice.practice)}>
                                                        <GridColumn width={14}>
                                                            <span style={{ fontSize: '15px', marginLeft: '60px' }}><Icon name='dropdown' />{practice.practice}</span>
                                                        </GridColumn>
                                                        <GridColumn textAlign='right' width={2}>
                                                            <Progress color='green' size='medium' className='red-bg mb0' percent={setPracticeColor(mco.label, index, practice)} />
                                                        </GridColumn>
                                                    </GridRow>
                                                    {
                                                        selected.practice === practice.practice && mco.subcategories.map(sub =>
                                                            <GridRow key={sub.name}>
                                                                <GridColumn width={13}>
                                                                    <span style={{ fontSize: '15px', marginLeft: '80px' }}>{_.capitalize(sub.label)}</span>
                                                                </GridColumn>
                                                                <GridColumn textAlign='right' width={3}>
                                                                    <Label size='massive' style={{ width: '40%' }} color={setInnerColor(mco.label, index, practice, sub.label.toLowerCase())} horizontal></Label>
                                                                </GridColumn>
                                                            </GridRow>
                                                        )
                                                    }
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    )
                }
            </AccordionContent>
        </>
    )
}

export default DentaHubChartByMco
