import { ActionType } from "../../model/actionTypes";
import {AdjustmentCodes} from "../../model/adjustmentCodes";
import {errorHandler} from "./auth";


export const saveAdjustmentCodes = (obj: AdjustmentCodes) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("adjustment-codes/create", obj);
        dispatch({ type: ActionType.CREATE_ADJUSTMENT_CODES_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
export const updateAdjustmentCodes = (obj:AdjustmentCodes) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("adjustment-codes/update", obj);
        dispatch({ type: ActionType.UPDATE_ADJUSTMENT_CODES_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const deleteAdjustmentCodes = (obj:AdjustmentCodes) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp =  await api.post("adjustment-codes/delete", obj);
        dispatch({ type: ActionType.DELETE_ADJUSTMENT_CODE_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};

export const getAdjustmentCodes = (id:any) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        id=id||'all';
        const resp =  await api.get("adjustment-codes/"+id);
        dispatch({ type: ActionType.FETCH_ADJUSTMENT_CODES_SUCCESS, payload: resp });
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return dispatch(errorHandler(err));
    }
};
