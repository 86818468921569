import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import HelpMark from "./HelpMark";
import Template from './Template';

import * as Actions from '../redux/actions/universalUsageReport';
import * as PracticeActions from '../redux/actions/practice';

import {Button, Dimmer, Grid, Input, Loader, Pagination, Popup, Segment} from 'semantic-ui-react';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useActions} from '../redux/actions';
import { RootState } from "../model";


function EmployeeUsageReport(props: any) {

    const initialState = {
        data: [],
        direction: 'sort',
        allowEdit: !!(props.user && props.user.isAdmin),
        order: true,
        column: null,
        startDate: new Date(moment().startOf('month').toString()),
        endDate: new Date(moment().endOf('month').toString()),
        searchKeyword: '',
        tempData: [],
        TableData: [[{title: 'No Records Found'}]],
        showHidden: false,
        actualData: [],
        userSearch: '',
        practices: []

    }
    let timeout: any = 0;
    const [state, setState] = useState(initialState);
    const [loader, setLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const UniversalUsageReportActions = useActions(Actions);
    const PracticeAction = useActions(PracticeActions);

    useEffect(() => fetchData(), [state.startDate, state.endDate])
    const fetchData = () => {
        setCurrentPage(1);
        UniversalUsageReportActions.getEmployeeUsageReport(
                moment(state.startDate).format('YYYY-MM-DD'), 
                moment(state.endDate).format('YYYY-MM-DD'), 
                userSearch
            )
            .then((data: any) => {
                setState({...state, data: data, tempData: data});
                const actualData = data;
                const TableData = pushTableData(data, state.showHidden);

                PracticeAction.fetchPractice('All').then((res: any) => {
                    setState({...state, TableData, actualData, practices: res});
                });
        })
    }
    const handleSort = (clickedColumn: any) => {
        let {column, TableData, direction} = state;
        if (column !== clickedColumn) {
            column = clickedColumn;
            TableData = _.sortBy(TableData, o => o[clickedColumn]);
            direction = 'sort ascending';
            setState({...state, TableData: TableData.slice(), column, direction});
            return;
        }
        TableData = TableData.reverse();
        direction = direction === 'sort ascending' ? 'sort descending' : 'sort ascending';
        setState({...state, TableData: TableData.slice(), direction, order: !order});
    }
    const handleStartDateChange = (date: any) => {
        setState({...state, startDate: date})


    }
    const handleEndDateChange = (date: any) => {
        setState({...state, endDate: date})
    }
    const onSearchChange = (e: any) => {
        const searchKeyword = e.target.value;
        if (timeout) clearTimeout(timeout);
        setCurrentPage(1);
        timeout = setTimeout(() => {


            if (searchKeyword.toLowerCase() === '') {
                const TableData = pushTableData(state.actualData, state.showHidden);
                setState({...state, searchKeyword: searchKeyword, TableData: TableData});
            } else {
                const data = state.actualData && state.actualData.filter((element: any) => {
                    return (element.visitedUrl.toLowerCase().includes(searchKeyword.toLowerCase())
                    )
                });
                const TableData = pushTableData(data, state.showHidden);
                setState({...state, searchKeyword: searchKeyword, TableData: TableData});
            }


            clearTimeout(timeout);
            setLoader(false)
        }, 500);
        setLoader(true)

    };

    function onSearchEnter(arg0: string) {
        setState({...state, userSearch: arg0});
        fetchData();
    }

    const printOutPracticeName = (practice: string) => {
        const { practices } : any = state

        let pract = '';
        if (practice && /^[A-Z]+$/.test(practice) && practice.length === 6) {
            for (let i = 0; i < practices.length; i++) {
                if (practices[i].practiceabbr === practice) {
                    pract = practices[i].practice
                }
            }
        }
        else {
            pract = practice && practice !=="0"? practice.charAt(0).toUpperCase() + practice.substring(1) : ''
        }
        return pract
    }

    const pushTableData = (res: any, showHidden: any = false) => {
        const TableData = new Array();
        res && res.map((item: any, key: any) => {
            TableData.push([
                <tr key={key}>
                    <td>
                        {item.userId}
                    </td>
                    <td>{item.visitedUrl}</td>
                    <td>{
                        <Popup
                            content={item.defualtPracticeId}
                            key={key + 'popup'}
                            header={'Practice'}
                            trigger={
                                <b>{printOutPracticeName(item.defualtPracticeId)}</b>}
                        />
                    } </td>
                    <td>{item.username} </td>
                    <td>{item.firstname} </td>
                    <td>{item.lastname} </td>
                    <td>{item.accessDate} </td>
                    <td>{item.count} </td>


                </tr>
            ])
        })
        return TableData;
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const {order, column, TableData} = state;
    let {userSearch} = state;
    const direction = order ? 'down' : 'up';

    const itemsPerPage = 10;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = TableData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(TableData.length / itemsPerPage);

    return(
        <Template activeLink="employee-usage-report">
            <div className="ui card">
                    <div className="content pb0">
                        <h2 className="float-left">Employee Usage Report</h2>
                        <div className="topFilters flex items-end flex-wrap">
                            <div className="filter d-InlineBlock float-left labelTop">
                                <label className="labelStyle displayBlock">Start Date</label>
                                <div className="datePicker">
                                    <DatePicker
                                        className="ui input"
                                        selected={state.startDate}
                                        onChange={(date) => handleStartDateChange(date)}
                                    />
                                </div>
                            </div>

                            <div className="filter d-InlineBlock float-left labelTop">
                                <label className="labelStyle displayBlock">End Date</label>
                                <div className="datePicker">
                                    <DatePicker
                                        className="ui input"
                                        selected={state.endDate}
                                        onChange={(date) => handleEndDateChange(date)}
                                    />
                                </div>
                            </div>
                            <Input
                                icon='search'
                                placeholder="Search Visited Page"
                                onChange={onSearchChange}
                            />

                            <Input
                                placeholder="Search User"
                                defaultValue={userSearch}
                                onKeyUp={(event: any) => {
                                    userSearch = event.target.value;
                                    if (event.keyCode === 13) {
                                        onSearchEnter(event.target.value as string);
                                    }
                                }}
                            />

                            <Button
                                primary
                                onClick={() => {
                                    onSearchEnter(userSearch)
                                }}
                            > Search
                            </Button>

                        </div>
                    </div>
                </div>
                <div className="ui card">
                    <div className="content">
                        <div className="FreezeTable">
                        <table className="ui table table-striped celled table-hover unstackable">
                            <thead>
                            <tr>
                                <th className={`cursorPointer  ${column === 'userId' ? 'active' : ''}`}
                                    onClick={() => handleSort('userId')}>
                                    <div>UserID <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer ${column === 'visitedUrl' ? 'active' : ''}`}
                                    onClick={() => handleSort('visitedUrl')}>
                                    <div>Visited Page <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer ${column === 'defualtPracticeId' ? 'active' : ''}`}
                                    onClick={() => handleSort('defualtPracticeId')}>
                                    <div>Default Practice <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer  ${column === 'username' ? 'active' : ''}`}
                                    onClick={() => handleSort('username')}>
                                    <div>User Name <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer  ${column === 'firstname' ? 'active' : ''}`}
                                    onClick={() => handleSort('firstname')}>
                                    <div>FirstName <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer ${column === 'lastname' ? 'active' : ''}`}
                                    onClick={() => handleSort('lastname')}>
                                    <div>LastName <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer  ${column === 'accessDate' ? 'active' : ''}`}
                                    onClick={() => handleSort('accessDate')}>
                                    <div>Access Date <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>
                                <th className={`cursorPointer  ${column === 'count' ? 'active' : ''}`}
                                    onClick={() => handleSort('count')}>
                                    <div>Count <i className={`sort amount ${direction} icon ml-05`}/></div>
                                </th>


                            </tr>
                            </thead>

                            <tbody>
                            {
                                <Dimmer as={Segment} active={loader}>
                                    <Loader active>Loading</Loader>
                                </Dimmer>}
                            {
                                currentItems.length === 1 && currentItems[0][0].title === 'No Records Found' ? <tr>
                                    <td>No Records</td>
                                </tr> : currentItems.map((item: any, key: any) => {
                                    return item
                                })
                            }
                            </tbody>
                        </table>
                        </div>
                        <Grid>
                            <Grid.Column width={16} textAlign="right">
                                <Pagination
                                    activePage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={(_e, data: any) => handlePageChange(data.activePage)}
                                />
                            </Grid.Column>
                        </Grid>
                    </div>
                </div>
        </Template>
    );
}

function mapStateToProps(state: RootState) {
    return {
        user: state.auth.user,
        alert: state.alert,
        accessToken: state.auth.accessToken
    };
}

export default connect(mapStateToProps)(EmployeeUsageReport);