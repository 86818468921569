import { ActionType } from "../../model/actionTypes";
import { errorHandler } from "./auth";

export const getUsers = () => async (dispatch: Function, getState: Function, api: any) => {

    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.get("users");
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return [];
    }
};


export const getOdUserList = (paramsObj :boolean) => async (dispatch: Function, getState: Function, api: any) => {

    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const params = JSON.stringify(paramsObj);
        const resp = await api.get("users/getOdUserList?params="+params);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return [];
    }
};

export const getOdUserGroups = () => async (dispatch: Function, getState: Function, api: any) => {

    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.get("users/getOdGroups");
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return [];
    }
};

export const createOpenDentalUser = (odUser: any) =>  async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.post(`users/odUser`, odUser);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        dispatch({ type: ActionType.ALERT, payload: err });
        return {};
    }
}

export const updateOpenDentalUser = (odUser: any) =>  async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.put(`users/odUser`, odUser);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        dispatch({ type: ActionType.ALERT, payload: err });
        return {};
    }
}

export const updateUser = (user: any) => async (dispatch: Function, getState: Function, api: any) => {

    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.put(`users`, user);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return {};
    }
};

export const updateUserInfo = (user: any) => async (dispatch: Function, getState: Function, api: any) => {

    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.put(`users/info`, user);
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return {};
    }
};

export const deleteUser = (user: any) => async (dispatch: Function, getState: Function, api: any) => {

    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.delete(`users`, user);;
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return {};
    }
};

export const syncUkgs = (email?: string) => async (dispatch: Function, getState: Function, api: any) => {
    dispatch({ type: ActionType.LOADER, payload: true });
    try {
        const resp = await api.post(`users/syncUkgs`, {email});
        dispatch({ type: ActionType.LOADER, payload: false });
        return resp;
    } catch (err) {
        dispatch({ type: ActionType.LOADER, payload: false });
        return {};
    }
};
