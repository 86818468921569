import React, {useCallback, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {RootState} from "../../../model";
import {useActions} from "../../../redux/actions";
import * as spotActions from "../../../redux/actions/spot";
import {Card, Header, Popup, Table} from "semantic-ui-react";
interface Props {
    patientId: any,
    practice: any,
}

function PatientTreatList(props: Props) {
    const spotAction = useActions(spotActions);
    const [state, setStates] = useState({
        treatmentList: [] as any,
    })
    const contextRef=useRef(null)
    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        const query: any = {location: props.practice, PatNum: props.patientId};
        const result: any[] = await spotAction.fetchTreatmentList(query);
        setStates({treatmentList: result})
    }
    const trimString = (str: any, len: number, addDots: boolean) => {
        return (
            (str && str.length > len) ?
                (addDots !== false ?
                    str.substring(0, len - 3) :
                    str.substring(0, len - 3) + '...') :
                str);
    }
    const capitalizeFirstLetter = (s:string) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }
    return (

        <Card>
            <Card.Content>
                <Card.Header>
                    <h3>PATIENT TREATMENT LIST</h3>
                </Card.Header>
            </Card.Content>
            <Card.Content>
                <div className="table-responsive limit-height">
                    <Table striped={true}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>PatNum</Table.HeaderCell>
                                <Table.HeaderCell>ProcDate</Table.HeaderCell>
                                <Table.HeaderCell>Production</Table.HeaderCell>
                                <Table.HeaderCell>Proc Code Category</Table.HeaderCell>
                                <Table.HeaderCell>Dr. Note</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <tbody>
                        {
                            state.treatmentList.length && state.treatmentList.map((item: any, key: any) =>
                                <tr key={key}>
                                    <td>{item.PatNum}</td>
                                    <td>{item.ProcDate || ''}</td>
                                    <td>{(item.Production) ? parseFloat(item.Production).toFixed(2) : item.Production}</td>
                                    <td>
                                        {item.Proc_Category ?
                                            <div>
                                                <Popup 
                                                    trigger={
                                                        <a data-for={`Procedures${key}`} data-tip={`Procedures${key}`}
                                                        style={{
                                                            color:"#D16F26"
                                                        }}
                                                        >
                                                        {trimString(item.Proc_Category, 15, false)}
                                                    </a>
                                                    }
                                                    header={"Procedure Code Category"}
                                                    position='right center'
                                     
                                                    content={
                                                        <p>{item.Proc_Category}</p>
                                                    }
                                                />
                                            </div> : ''
                                        }
                                    </td>
                                    <td >
                                        {
                                            item.grpnote ?
                                                <div>
                                                <Popup 
                                                    header={"Dr Note"}
                                                    key={`${item.PatNum}+${item.grpnote}`}
                                                    trigger={
                                                        <a data-for={`treatmentList${key}`} data-tip={`treatmentList${key}`}
                                                        style={{
                                                            color:"#D16F26"
                                                        }}
                                                        >
                                                        {capitalizeFirstLetter(trimString(item.grpnote, 15, false).toLowerCase())}
                                                    </a>
                                                    }
                                                    wide       
                                                    hoverable   
                                                    position='top center'                          
                                                    on={['hover', 'click']}                                                                          >
                                                            <PopupContent>
                                                                <Header as="h4">Dr Note</Header>
                                                                {item.grpnote}
                                                            </PopupContent>
                                                </Popup>
                                                </div>
                                                 : ''
                                        }
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </Card.Content>
        </Card>
    )
}

function mapStateToProps(state: RootState) {
    return {user: state.auth.user}
}


function PopupContent(props:any){
    return (
        <div className='popup-content'>
                {props.children}
        </div>
    )
}


export default connect(mapStateToProps)(PatientTreatList)