import {pcrTableResults} from "./ExportCsv";
import {SendPCREmail} from "../model/report";

export const sendPCREmail:SendPCREmail = (data, page, callBack) => {
    if(!data) {
        alert("Data is not available");
        return false;
    }

    let csvData = pcrTableResults(data, page);
    callBack(csvData);
};