import moment from "moment";

export const PMDActions = async ( action: any, type: string, practiceName?: any, props?: any, params?: any) => {
    try{

        if (type === 'pmdS3Data') {
            return await action.fetchPMDS3Data(practiceName.toLowerCase()).then((data: any) => {
                return data;
            })
        }

    } catch (e) {
        console.log('Error', e);
        return e;
    }
}