import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Grid, Icon, Message, Search, Table } from 'semantic-ui-react';

import { AdminImpDataSaveUpdMsg, AdminImpDelMsg } from '../constants';
import { RootState } from '../model';
import { useActions } from '../redux/actions';
import * as ReportActions from '../redux/actions/implantCostList';
import { implantCostList } from '../redux/reducers/implantCostList';
import ImplantCostListForm from './ImplantCostForm';
import Template from './Template';
import HelpMark from "./HelpMark";


interface ImplantCostListProps {
    implantCost : any
}

const initialState:any = {
    loading: false,
    cronStatus: 'All',
    implantCostList:[],
    isEdit: false,
    isDelete:false,
    initialValues: {
    code: '',
    description: '',
    office_fee: '',
    id: 0},
    orderBy: null,
    order: true,
    search:""
}
const ImplantCostList= (props: ImplantCostListProps) => {
    const reportActions = useActions(ReportActions);
    const [state, setStates] = useState(initialState);
    const [addedMsg, setAddedMsg] = useState(false);
    const [deletedMsg, setDeletedMsg] = useState(false);

    useEffect(() => {
        reportActions.getImplantCosts()
            .then((res: any) => {
                setStates({...state, implantCostList : res});
            });
    }, []);

const edit = (obj:any,isDelete:boolean) => {
        const initialValues = {
            ...obj
        };
        setStates({...state, isEdit: true, initialValues, isDelete: isDelete});
    };
    const getSortRows = (sortColumn:string, order:string) => {
        const { implantCostList } = state;
        const orderUpdated = !order;
        const implantCostListCodesSort =  _.orderBy(implantCostList, [sortColumn],[order ? 'asc' : 'desc'])
        setStates({...state, implantCostList: implantCostListCodesSort, orderBy:sortColumn, order: orderUpdated });
    };
    const cancelForm = () => {
        setStates({...state, isEdit: false, initialValues: {code: '',
        description: '',
        office_fee: '',
        id: 0} });
    };
    const onSearch=(e: any, element: any) => {
        const obj={...state}
        obj.search =element.value;
        setStates(obj);
    };
    const updateImplantCost = (data:any) => {
        cancelForm();
        const {id} = data;
        const func = id?"updateImplantCost": 'saveImplantCost';
        reportActions[func](data).then((data:any)=> {
            if(data){
                console.log(data);
                reportActions.getImplantCosts()
                    .then((res: any) => {
                        id? setDeletedMsg(true) : setAddedMsg(true);
                        setStates({...state, implantCostList : res,isEdit: false, initialValues: {code: '',
                        description: '',
                        office_fee: '',
                        id: 0}});
                    });
            }

        })
    };
    const { implantCostList, search ,orderBy, order, isEdit } = state;
    const direction = order ? 'sort down' : 'sort up';
    return (
        <Template activeLink="crons-list">
            {(!!addedMsg || !!deletedMsg) && 
            <Message 
                color='green'
                onDismiss={() => {setAddedMsg(false); setDeletedMsg(false);}}
                content={state.isDelete ? AdminImpDelMsg : AdminImpDataSaveUpdMsg}
            />}
            <Grid>
                <Grid.Row>
                    <div className="ui card">
                        <div className="content pb0 mb10">
                            <h2 className="float-left mr10">Implant Cost Description <HelpMark pageId='85'/></h2>
                            <div className={'topFilters'}>
                                <Grid.Column width={6}>
                                    <Search
                                        placeholder='Search...'
                                        onSearchChange={onSearch}
                                        name={'search'}
                                    />

                                </Grid.Column>
                            </div>

                        </div>
                    </div>

                </Grid.Row>
                <Grid.Row>
                    <Button
                        color="blue"
                        size="small" onClick={()=>edit({
                            code: '',
                            description:'',
                            office_fee:'',
                            id:0},false)}>Create New </Button>
                </Grid.Row>
                {isEdit &&
                <Grid.Row>
                    <ImplantCostListForm
                        cancelForm={() => cancelForm()}
                        saveForm={(data: any) => updateImplantCost(data)}
                        initialValues={state.initialValues}
                        isDelete={state.isDelete}
                    />
                </Grid.Row>
                }
                <Grid.Row>
                    <Table singleLine className="ui celled table unstackable cronListTable">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell style={{ color: "#d16f26" }}
                                                  className={`cursorPointer ${orderBy === 'code' ? 'active' : ''}`}
                                                  onClick={() => getSortRows('code', order)}
                                >
                                    CODE
                                    <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/>
                                </Table.HeaderCell>

                                <Table.HeaderCell style={{ color: "#d16f26" }}
                                                  className={`cursorPointer ${orderBy === 'description' ? 'active' : ''}`}
                                                  onClick={() => getSortRows('description', order)}
                                >
                                    DESCRIPTION
                                    <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/>
                                </Table.HeaderCell>
                                
                                <Table.HeaderCell style={{ color: "#d16f26" }}
                                                  className={`cursorPointer ${orderBy === 'office_fee' ? 'active' : ''}`}
                                                  onClick={() => getSortRows('office_fee', order)}
                                >
                                    COST
                                    <Icon className={orderBy === 'cronTime' ? direction : 'sort'}/>
                                </Table.HeaderCell>

                                <Table.HeaderCell style={{ color: "#d16f26" }}>
                                    ACTION
                                </Table.HeaderCell>
                                <Table.HeaderCell style={{ color: "#d16f26" }}>
                                    ACTION
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {implantCostList.length &&
                            implantCostList.filter((item: any, index: any)=>{
                                if(!search) {
                                    return true
                                }else{
                                    const values =Object.values(item).join(" ")
                                    return values.toLowerCase().includes(search.toLowerCase())
                                }
                            })
                                .map((item: any, index: any) => {
                                    return(
                                        <Table.Row key={index}>
                                            <Table.Cell>{item.code}</Table.Cell>
                                            <Table.Cell>{item.description}</Table.Cell>
                                            <Table.Cell>{item.office_fee}</Table.Cell>
                                            <Table.Cell className={''}>
                                                <Button
                                                    color="blue"
                                                    size="small" onClick={() => edit(item,false)}>
                                                    Edit
                                                </Button>
                                            </Table.Cell>
                                            <Table.Cell >

                                                <Button
                                                    color="blue"
                                                    size="small" onClick={() => edit(item,true)}>
                                                    Delete
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    )
                                })}
                        </Table.Body>
                    </Table>
                </Grid.Row>
            </Grid>
        </Template>
    );

}


function mapStateToProps(state: RootState) {
    return {
        implantCostList: state.implantCostList,
    };
}

export default connect(mapStateToProps)(ImplantCostList);
