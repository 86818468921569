import React, {useEffect, useRef, useState} from "react";
import {Card, Dropdown, Icon, Pagination, Table} from "semantic-ui-react";
import moment from "moment";
import {SporeTableResult} from "../model/spore";
import HelpMark from "./HelpMark";
import {useActions} from "../redux/actions";
import * as userSetupActions from "../redux/actions/userSettings";
import {SetupFields} from "../enums/userSetupField";
import {SetupAppName} from "../enums/userSetupAppName";
import * as practiceActions from "../redux/actions/practice";
import {brands} from "../constants";
import _ from "lodash";

type TableProps = {
    practices: [],
    fetchTableResults: Function,
    tableResults: Array<SporeTableResult>
}

const getYears = () => {
    let years = [];
    const currentYear = new Date().getFullYear() + 1;
    for (let i = 2016; i < currentYear; i++) {
        years.push({value: `${i}`, title: `${i}`});
    }
    return years;
}

const sporeFilters = {
    status: [
        {value: "", title: "Select Status"},
        {value: "Passed", title: "Passed"},
        {value: "Pending", title: "Pending"},
        {value: "Others", title: "Others"},
    ],
    years: [
        {value: "", title: "Select Year"},
        ...getYears()
    ],
    months: [
        {value: "0", title: "Select Month"},
        {value: "1", title: "Jan"},
        {value: "2", title: "Feb"},
        {value: "3", title: "Mar"},
        {value: "4", title: "Apr"},
        {value: "5", title: "May"},
        {value: "6", title: "Jun"},
        {value: "7", title: "Jul"},
        {value: "8", title: "Aug"},
        {value: "9", title: "Sep"},
        {value: "10", title: "Oct"},
        {value: "11", title: "Nov"},
        {value: "12", title: "Dec"},
    ],
};

function SporeTableMain(props: TableProps) {
    const {practices, fetchTableResults} = props;
    const [yearFilter, setYearFilter] = useState("")
    const [monthFilter, setMonthFilter] = useState(0)
    const [weekFilter, setWeekFilter] = useState("")
    const [activePage, setActivePage] = useState(1);
    const maxRows = 15;
    const filterUpdated = useRef(0)
    const [filter, setFilter] = useState({
        status: "",
        practice: "",  brand: "",
    });
    const [states, setStates] = useState({
        orderBy: null as any,
        order: true,
        orderedData: [] as any
    });

    const [defaultPracticeName, setDefaultPracticeName] = useState(filter.practice);
    const userSetup = useActions(userSetupActions);
    const practiceAction = useActions(practiceActions);
    useEffect(() => {
        practiceAction.fetchPractices(refinePracticeArray)
    }, [filter]);

    const refinePracticeArray = (response: any) => {
        let practiceInfo: any = {};
        if (response) {
            response.forEach((item: any) => {
                practiceInfo[item.practice.toLowerCase()] = item;
            })
        }
        let userParams = { field: SetupFields.Practice, appId: SetupAppName.CDP_MY };
        userSetup.getSetup(userParams).then((dftPractice: any) => {
            if (dftPractice && dftPractice.value && dftPractice.value !== 'all') {
                let defaultPractices: any = dftPractice.value.split(',');
                defaultPractices.forEach((defaultPractice: any) => {
                    if (practiceInfo[defaultPractice]) {
                        if (filterUpdated.current < 1) {
                            filter.practice = practiceInfo[defaultPractice].practice
                        }
                        setDefaultPracticeName(filter.practice);
                        fetchTableResults(filter)
                        filterUpdated.current = filterUpdated.current + 1;
                        return;
                    }
                    else {
                        fetchTableResults('')
                        return;
                    }
                })
            }
        })
        return { practiceInfo: practiceInfo}
    };

    const practiceFilterOptions = () => practices.map((val: any) => {
        return {text: val.practice, value: val.practice, key: val.practice}
    })
    const statusFilterOptions = () =>
        sporeFilters.status.map((val) => {
            return {text: val.title, key: val.title, value: val.value};
        });
    const yearsFilterOptions = () =>
        sporeFilters.years.map((val) => {
            return {text: val.title, key: val.title, value: val.value};
        });
    const monthsFilterOptions = () =>
        sporeFilters.months.map((val) => {
            return {text: val.title, key: val.title, value: val.value};
        });
    const weeksFilterOptions = () =>
        weeksFilter()
            .map((val) => {
                return {text: val.title, key: val.title, value: val.value};
            });

    const weeksFilter = () => {
        let weeksArray = [{title: "Select Week", value: ""}];
        // Get sundays of selected year and month
        if (yearFilter !== "" && monthFilter !== 0) {
            const startDate = moment([yearFilter, monthFilter - 1]);
            const endDate = moment(startDate).endOf("month");
            let result = [];
            let current = startDate.clone();
            while (current.day(7).isBefore(endDate)) {
                weeksArray.push({
                    title: current.clone().format("ddd MMM DD YYYY"),
                    value: current.clone().format("YYYY-MM-DD"),
                });
            }
        } else {
            const startDate = moment().startOf("week");
            let weekDate = "";
            weekDate = moment(startDate).format("ddd MMM DD YYYY");
            weeksArray.push({
                title: weekDate,
                value: moment(startDate).format("YYYY-MM-DD"),
            });
            // Get last 4 weeks sunday dates
            for (let i = 1; i < 4; i++) {
                weekDate = (moment(startDate).day(-(7 * i)) as unknown) as string;
                weekDate = moment(weekDate).format("ddd MMM DD YYYY");
                weeksArray.push({
                    title: weekDate,
                    value: moment(moment(startDate).day(-(7 * i))).format("YYYY-MM-DD"),
                });
            }
        }
        return weeksArray;
    };

    const sortByDate = (results: Array<SporeTableResult>): Array<SporeTableResult> => {
        return results.sort((a, b) => {
            const d1 = new Date(a.testDate)
            const d2 = new Date(b.testDate)
            return d2.getTime() - d1.getTime()
        })
    }
    const applyYearFilter = (currFilter: string, results: Array<SporeTableResult>): Array<SporeTableResult> => {
        return results.filter((item) => new Date(item.testDate).getFullYear() === parseInt(currFilter))
    }
    const applyMonthFilter = (currFilter: number, results: Array<SporeTableResult>): Array<SporeTableResult> => {
        return results.filter((item) => new Date(item.testDate).getMonth() === monthFilter - 1)
    }
    const applyPracticeFilter = (currFilter: string, results: Array<SporeTableResult>): Array<SporeTableResult> => {
        return results.filter((item) => item.practice.toLowerCase()  === currFilter.toLowerCase())
    }
    const applyStatusFilter = (currFilter: string, results: Array<SporeTableResult>): Array<SporeTableResult> => {
        return results.filter((item) => item.result.toLowerCase().includes(currFilter.toLowerCase()))
    }
    const applyBrandFilter = (currFilter: string, results: Array<SporeTableResult>): Array<SporeTableResult> => {
        return results.filter((item) => {
            const brand = item.brand ? item.brand.toLowerCase() : ''; // Convert null to an empty string
            return brand.includes(currFilter.toLowerCase());
        });
    }
    const applyWeekFilter = (currFilter: string, results: Array<SporeTableResult>): Array<SporeTableResult> => {
        const curreDate = new Date(currFilter)
        return results.filter((item) => new Date(item.testDate).getMonth() === curreDate.getMonth() && new Date(item.testDate).getDate() >= curreDate.getDate() && new Date(item.testDate).getDate() - curreDate.getDate() < 7)
    }
    const getTableResult = (currentResults: Array<SporeTableResult>): Array<SporeTableResult> => {
        let filteredResults = currentResults;
        if(filter.practice !== '') {
            filteredResults = applyPracticeFilter(filter.practice, filteredResults)
        }
        if(filter.status !== '') {
            filteredResults = applyStatusFilter(filter.status, filteredResults)
        }
        if( filter.brand && filter.brand !== '') {
            filteredResults = applyBrandFilter(filter.brand, filteredResults)
        }
        if (yearFilter !== "") {
            filteredResults = applyYearFilter(yearFilter, filteredResults)
        }
        if (monthFilter !== 0) {
            filteredResults = applyMonthFilter(monthFilter, filteredResults)
        }
        if (weekFilter !== "") {
            filteredResults = applyWeekFilter(weekFilter, filteredResults)
        }
        
        return filteredResults;
    }
    const setPracticeFilter = ( practiceName: any) => {
        setFilter({...filter, practice: practiceName})
        filterUpdated.current = filterUpdated.current + 1;
        setDefaultPracticeName(practiceName)
        setActivePage(1);
    }
    const setStatusFilter = ( status: any) => {
        setFilter({...filter, status: status})
        filterUpdated.current = filterUpdated.current + 1;
        setActivePage(1);
    }
    const setBrandFilter = ( brand: any) => {
        setFilter({...filter, brand: brand})
        filterUpdated.current = filterUpdated.current + 1;
        setActivePage(1);
    }
    const getStatusColor = (status: string) => {
        if (status.indexOf('Passed') !== -1)
            return {color: '#2cab48'}
        else if (status.indexOf('Pending') !== -1)
            return {color: '#eff542', fontWeight: 900}
        else
            return {color: '#d6230f'}
    }

    const getSortRows = (sortColumn: string, orderLocal: boolean) => {
        const orderUpdated = !orderLocal;
        const data = props.tableResults || [];

        let sort: any;

        sort = _.orderBy(data, (o: any) => {
            return moment(o[sortColumn])
        }, [orderLocal ? 'asc' : 'desc'])

        return setStates({...states, orderedData: sort, orderBy: sortColumn, order: orderUpdated});
    }

    const sortedResults = sortByDate(props.tableResults) || [];

    const start = (activePage - 1) * maxRows;
    const end = start + maxRows;

    const {orderBy, order, orderedData} = states;
    const tableResults = orderBy !== null ? getTableResult(orderedData) : getTableResult(sortedResults);
    const data = tableResults.slice(start, end) || [];

    const direction = order ? 'sort down' : 'sort up';

    return (
        <div>
            <Card>
                <Card.Content>
                    <h2 className="float-left mr10 mb10">SPORE <HelpMark pageId="49"/></h2>
                    <div className="topFilters">
                        <Dropdown
                            placeholder="Practices"
                            selection={true}
                            search={true}
                            className='mr10'
                            value={defaultPracticeName}
                            options={[{value: "", title: "Select Practice"}, ...practiceFilterOptions()]}
                            onChange={
                                (event, data) => {
                                    setStates({...states, orderBy: null, orderedData: []})
                                    setPracticeFilter(data.value as string)
                                }}
                        />
                        <Dropdown
                            placeholder="Status"
                            selection={true}
                            className='mr10'
                            options={[...statusFilterOptions()]}
                            value={filter.status}
                            onChange={(event, data) => {
                                setStates({...states, orderBy: null, orderedData: []})
                                setStatusFilter(data.value as string)
                            }}

                        />
                        <Dropdown
                            placeholder="Brand"
                            selection={true}
                            className='mr10'
                            options={brands}
                            value={filter.brand}
                            onChange={(event, data) => {
                                setStates({...states, orderBy: null, orderedData: []})
                                setBrandFilter(data.value as string)
                            }}

                        />
                        <Dropdown
                            placeholder="Year"
                            selection={true}
                            className='mr10'
                            options={[...yearsFilterOptions()]}
                            onChange={(event, data) => {
                                setStates({...states, orderBy: null, orderedData: []})
                                setYearFilter(data.value as string)
                            }}
                        />
                        <Dropdown
                            placeholder="Month"
                            selection={true}
                            className='mr10'
                            options={[...monthsFilterOptions()]}
                            onChange={(event, data) => {
                                setStates({...states, orderBy: null, orderedData: []})
                                setMonthFilter(parseInt(data.value as string))
                            }}
                        />
                        <Dropdown
                            placeholder="Week"
                            selection={true}
                            className='mr10'
                            options={[...weeksFilterOptions()]}
                            onChange={(event, data) => {
                                setStates({...states, orderBy: null, orderedData: []})
                                setWeekFilter(data.value as string)
                            }}
                        />
                    </div>
                </Card.Content>
            </Card>

            <Table striped={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            TEST NO.
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            PRACTICE
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className={`cursorPointer ${orderBy === 'testDate' ? 'active' : ''}`}
                            onClick={() => getSortRows('testDate', order)}
                        >
                            TEST DATE
                            <Icon className={orderBy === 'testDate' ? direction : 'sort'} />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            SERIAL NO.
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            RESULT/STATUS
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className={`cursorPointer ${orderBy === 'receivedDate' ? 'active' : ''}`}
                            onClick={() => getSortRows('receivedDate', order)}
                        >
                            RECEIVED
                            <Icon className={orderBy === 'receivedDate' ? direction : 'sort'} />
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            className={`cursorPointer ${orderBy === 'resultDate' ? 'active' : ''}`}
                            onClick={() => getSortRows('resultDate', order)}
                        >
                            RESULT DATE
                            <Icon className={orderBy === 'resultDate' ? direction : 'sort'} />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        data.map((result: any, key: any) => (
                        <Table.Row key={result.id}>
                            <Table.Cell>{result.testNumber}</Table.Cell>
                            <Table.Cell>{result.practice}</Table.Cell>
                            <Table.Cell>
                                {moment.utc(result.testDate).format("MM/DD/YYYY")}
                            </Table.Cell>
                            <Table.Cell>{result.serialNumber}</Table.Cell>
                            <Table.Cell style={getStatusColor(result.result)}>{result.result.trim()}</Table.Cell>
                            <Table.Cell>
                                {moment.utc(result.receivedDate).format("MM/DD/YYYY")}
                            </Table.Cell>
                            <Table.Cell>
                                {moment.utc(result.resultDate).format("MM/DD/YYYY")}
                            </Table.Cell>
                        </Table.Row>
                    ))
                    }
                </Table.Body>
            </Table>
            <Pagination
                onPageChange={(e, data) => {
                    setActivePage(data.activePage as number)
                }}
                defaultActivePage={activePage}
                totalPages={Math.ceil(tableResults.length / maxRows)}
            />

            {/*<table className="ui celled table unstackable">*/}
            {/*    <thead>*/}
            {/*    <tr>*/}
            {/*        <th style={{color: '#d16f26'}}>TEST NO.</th>*/}
            {/*        <th style={{color: '#d16f26'}}>PRACTICE</th>*/}
            {/*        <th style={{color: '#d16f26'}}>TEST DATE</th>*/}
            {/*        <th style={{color: '#d16f26'}}>SERIAL NO.</th>*/}
            {/*        <th style={{color: '#d16f26'}}>RESULT/STATUS</th>*/}
            {/*        <th style={{color: '#d16f26'}}>RECEIVED</th>*/}
            {/*        <th style={{color: '#d16f26'}}>RESULT DATE</th>*/}
            {/*    </tr>*/}
            {/*    </thead>*/}
            {/*    <tbody>*/}
            {/*    {tableResults &&*/}
            {/*    getTableResult(sortByDate(tableResults)).map((result, key) => (*/}
            {/*        <tr key={result.id}>*/}
            {/*            <td>{result.testNumber}</td>*/}
            {/*            <td>{result.practice}</td>*/}
            {/*            <td>*/}
            {/*                {moment.utc(result.testDate).format("MM/DD/YYYY")}*/}
            {/*            </td>*/}
            {/*            <td>{result.serialNumber}</td>*/}
            {/*            <td style={getStatusColor(result.result)}>{result.result.trim()}</td>*/}
            {/*            <td>*/}
            {/*                {moment.utc(result.receivedDate).format("MM/DD/YYYY")}*/}
            {/*            </td>*/}
            {/*            <td>*/}
            {/*                {moment.utc(result.resultDate).format("MM/DD/YYYY")}*/}
            {/*            </td>*/}
            {/*        </tr>*/}
            {/*    ))}*/}
            {/*    </tbody>*/}
            {/*</table>*/}
        </div>
    );
}


export default SporeTableMain;
