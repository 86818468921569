import React from 'react'
import {Button, Modal} from "semantic-ui-react";
interface ProcedureCodePopupPros {
    hideProcedureCodePopup : Function,
    excludeProceduresCodeStr : any,
    handleProcedureCodeSubmit : Function,
    procedureCopePracticeName : string,
    handleProcCodeChange: Function
}

const ProcedureCodePopup:React.FC<ProcedureCodePopupPros>=(props)=>{
    return(
        <Modal
            onClose={() => props.hideProcedureCodePopup()}
            open={true}
        >
            <Modal.Header>
                ProcedureCodes List to exclude from query
            </Modal.Header>
            <Modal.Content>
                <label>ProcedureCodes (comma separated list)</label>
                <textarea
                    rows={5}
                    cols={75}
                    defaultValue={props.excludeProceduresCodeStr}
                    onBlur={(e) => props.handleProcCodeChange(e)}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button secondary={true} onClick={() => props.hideProcedureCodePopup()}>
                    Cancel
                </Button>
                <Button primary={true} onClick={()=> props.handleProcedureCodeSubmit(props.procedureCopePracticeName)}>
                    Submit
                </Button>
            </Modal.Actions>
        </Modal>
    )
}


export default ProcedureCodePopup

