import React, {useState} from "react";
import {Button, Icon, Label, Modal, Table} from "semantic-ui-react";

function ErrorModal(props: any) {

    return(
        <Modal className='modalCenter' size="large" onClose={() => props.showErrorModalHandler()} open={true}>
            <Modal.Header>
                {props.siteTitle}
            </Modal.Header>
            <Modal.Content scrolling={true}>
                {props.error}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Close"
                    onClick={() => props.showErrorModalHandler()}
                />
            </Modal.Actions>
        </Modal>
    )


}

export default ErrorModal